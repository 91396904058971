/**
 * @description
 * This is component that displays mobile version of DescriptionPopup
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@bit/first-scope.common-button';
import { selectLanguageStrings } from '../../../../../../../app/state/userSlice';
import { ReactComponent as CrossIcon } from '../../../../../../../assets/crossIcon.svg';
import { CloseButton } from '../../../../../../../components/closeButton/CloseButton';
import { InputModal } from '../../../../../../../components/inputModal/InputModal';
import { DraggableModal } from '../../../../../../../components/draggableModal/DraggableModal';
import { ReactComponent as Alert } from '../../../../../../../assets/Alert_circle.svg';
import { media } from '../../../../../../../styles/media';
import { colorFetch } from '../../../../../../../styles/utils';
import { usePrevious } from '@cthings.co/utils';

const Wrapper = styled(DraggableModal)`
  display: none;
  max-height: 650px;
  height: max-content;
  z-index: 999999999;
  ${media.tablet`
    width: 100%;
    display: block;
  `}
` as any; // @TODO fix type. Check and fix implementation

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled(Text)`
  font-size: 18px;
`;

const Hint = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0 12px 0;
`;

const HintTitle = styled(Text)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${colorFetch('gray1_5')};
  margin-left: 8px;
` as any; // @TODO fix type

const ShowOnTheMapButton = styled(CommonButton)`
  min-height: 40px;
  margin-top: 12px;
`;

const ZoneDataModal = styled.div``;

export const MobileView = ({
  value,
  closeModal,
  setSavedZones,
  handleScrollToTop,
  locations,
}: {
  value: any;
  closeModal: () => void;
  locations: any;
  setSavedZones?: (val: any) => void;
  handleScrollToTop?: (val: string) => void;
}) => {
  const [isOpenModal, setIsOpenModal] = useState(true);

  const { name, coordinates, description, id } = value;
  const prevId = usePrevious(id);

  const languageStrings = useSelector(selectLanguageStrings);

  const fieldsArr = [
    {
      fieldName: languageStrings ? languageStrings.labelPositionX : 'Position X',
      value: Math.floor(coordinates?.center?.x),
    },
    {
      fieldName: languageStrings ? languageStrings.labelPositionY : 'Position Y',
      value: Math.floor(coordinates?.center?.y),
    },
    { fieldName: languageStrings ? languageStrings.labelWidth : 'Width', value: coordinates?.width },
    { fieldName: languageStrings ? languageStrings.labelHeight : 'Height', value: coordinates?.height },
    { fieldName: languageStrings ? languageStrings.labelDescription : 'Description', value: description },
  ];

  useEffect(() => {
    prevId && id !== prevId && setIsOpenModal(true);
  }, [id, prevId]);

  return (
    <Wrapper
      isDraggable={true}
      withoutNav={true}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      onBlur={() => {
        setIsOpenModal(false);
      }}
    >
      <Container
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <ZoneDataModal>
          <TitleWrapper>
            <Title type={TextType.TITLE_PRIMARY}>{name}</Title>
            <CloseButton onClick={closeModal}>
              <CrossIcon />
            </CloseButton>
          </TitleWrapper>
          {fieldsArr.map((item: any, index: number) => {
            const { fieldName, value } = item;
            return (
              <InputModal
                value={value}
                onChange={() => {}}
                name={fieldName}
                placeholder={''}
                disabled
                rows={index === 4 ? 2 : 1}
              />
            );
          })}
          <Hint>
            <Alert />
            <HintTitle>
              {languageStrings ? languageStrings.hintTitle : 'Zone editing is available only in the desktop version'}
            </HintTitle>
          </Hint>
          <ShowOnTheMapButton
            onClick={() => {
              setIsOpenModal(false);
              setSavedZones && setSavedZones(locations);
              handleScrollToTop && handleScrollToTop(id);
            }}
            width={'100%'}
          >
            {languageStrings ? languageStrings.showOnTheMapButton : 'Show on the map'}
          </ShowOnTheMapButton>
        </ZoneDataModal>
      </Container>
    </Wrapper>
  );
};
