import { Text } from '@bit/first-scope.text';
import styled from 'styled-components';
import { ReactComponent as ArrowImage } from '../../../../assets/SmallArrow.svg';
import { media } from '../../../../styles/media';
import { NavLink } from '../../../../utils/react-router-dom-abstraction';
import { borderRadiusFetch, colorFetch } from '../../../../styles/utils';

type ItemWrapperType = {
  isHidden?: boolean;
};

export const ItemWrapper = styled.div<ItemWrapperType>`
  box-sizing: border-box;
  position: relative;
  padding-left: 18px;
  margin-bottom: 20px;
  &:nth-child(n + 9) {
    display: ${({ isHidden }: any) => (isHidden ? 'none' : 'block')};
  }
  &:last-child {
    margin-bottom: 0;
  }
  ${media.tablet`
    display: flex;
    &.active {
      background-color: ${colorFetch('gray6')};
    }
    padding: 10px 16px;
    margin: 0;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 12px;
      right: 12px;
      height: 0.5px;
      background-color: ${colorFetch('gray3')};
    }
  `}
`;

export const LineForModal = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colorFetch('gray4')};
  margin: 0 0 15px 30px;
  &.modal {
    margin-left: 0;
  }
  &.mobile {
    width: 100%;
    height: 0;
    margin: 0;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 12px;
      right: 12px;
      height: 1px;
      background-color: ${colorFetch('gray3')};
    }
  }
`;

export const StyledNavLink = styled(NavLink)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-decoration: none;
  & > svg,
  & > svg > path {
    width: 20px;
    height: 20px;
    stroke: ${colorFetch('black1')};
    transition: 0.3s;
  }
  &:hover {
    & > svg,
    & > svg > path {
      stroke: ${colorFetch('primary')};
    }
    ${media.tablet`
      & > svg,
      & > svg > path {
        stroke: ${colorFetch('black1')};
      }
    `}
  }
  &.active {
    position: relative;
    & div span {
      color: ${colorFetch('primary')};
    }
    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: -18px;
      bottom: 0;
      width: 2px;
      background-color: ${colorFetch('primary')};
      border-radius: 0 ${borderRadiusFetch('additional4')} ${borderRadiusFetch('additional4')} 0;
      ${media.tablet`
        display: none;
      `}
    }
    & > svg,
    & > svg > path {
      stroke: ${colorFetch('primary')};
    }
  }
`;

export const TextWrapperManage = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1px;
  flex: 0;
  overflow: hidden;
  transition: 0.3s;
  &.open {
    flex: 1;
  }
`;

type ManageListProps = {
  height: string;
};

export const ManageList = styled.div<ManageListProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 0;
  width: 100%;
  transition: all 0.3s ease;
  &.open {
    height: ${({ height }) => height};
  }
`;

export const StyledManageNavlink = styled(NavLink)`
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  margin: 0 0 15px 30px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${colorFetch('gray1')};
  text-decoration: none;
  transition: 0.3s;
  word-wrap: break-word;
  &:first-child {
    margin: 10px 0 15px 30px;
  }
  &:last-child {
    margin: 0 0 0 30px;
  }
  &.active {
    font-weight: 600;
  }
  &:hover {
    color: ${colorFetch('primary')};
    ${media.tablet`
      color: ${colorFetch('gray1')};
    `}
  }
  ${media.tablet`
    width: 100%;
    font-weight: 500;
    &.active {
      font-weight: 500;
      color: ${colorFetch('primary')};
    }
  `}
`;

export const ManageModal = styled.div`
  box-sizing: border-box;
  padding: 12px 15px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 20px), -50%);
  background-color: ${colorFetch('pureWhite')};
  border-radius: 8px;
  box-shadow: 0 0 2px rgb(40 41 61 / 4%), 0 4px 8px rgb(96 97 112 / 16%);
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease;
  &.open {
    min-width: 135px;
    opacity: 1;
    pointer-events: auto;
  }
`;

export const StyledText = styled(Text)`
  margin-left: 10px;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    ${media.tablet`
      color: ${colorFetch('black1')};
    `}
  }
`;

export const StyledManageArrowImage = styled(ArrowImage)`
  transform: rotate(0deg);
  transition: all 0.3s ease;
  &.open {
    transform: rotate(-180deg);
  }
  ${media.tablet`
    transform: rotate(-90deg);
    &.open {
      transform: rotate(-270deg);
    }
    &.back_button {
      transform: rotate(-270deg);
    }
  `}
`;

export const TextWrapper = styled.div`
  box-sizing: border-box;
  flex: 0;
  overflow: hidden;
  transition: 0.3s;
  &.open {
    flex: 1;
  }
`;
