/**
 * @description
 * This is an advanced class that covers
 * all the functionalities cthings for api requests
 * and operating with data in local storage
 * Highly customisable.
 *
 */

import axios, { AxiosResponse } from 'axios';
import ls from './ls';
import { store } from '../app/store';
import { startLoader, stopLoader } from '../app/state/appSlice';
import { LsValueType } from '../enums/LsValueType';
import { requestPush } from '../app/state/routeSlice';
import oauthRedirect from '../features/authentication/oauthRedirect';
import { View } from '../routes/routeInterfaces';
import { LoaderOptions } from '../features/placeholderComponent/types';

export enum ApiCallType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}
export class HttpService {
  static source = axios.CancelToken.source();
  static token = ls.get(LsValueType.token);
  static locale = ls.get(LsValueType.locale);

  static getLanguage() {
    return ls.get(LsValueType.locale);
  }

  static setLanguage(locale: string) {
    ls.set(LsValueType.locale, locale);
    HttpService.locale = locale;
  }

  static removeLanguage() {
    ls.remove(LsValueType.locale);
    HttpService.locale = null;
  }

  static getToken() {
    return ls.get(LsValueType.token);
  }

  static setToken(token: string) {
    ls.set(LsValueType.token, token);
    HttpService.token = token;
  }

  static removeToken() {
    ls.remove(LsValueType.token);
    HttpService.token = null;
  }

  static get(url: string, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'get',
      ...options,
    });
  }

  static post(url: string, data: any, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'post',
      data,
      ...options,
    });
  }

  static put(url: string, data: any, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'put',
      data,
      ...options,
    });
  }
  static patch(url: string, data: any, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'patch',
      data,
      ...options,
    });
  }

  static delete(url: string, data: any, options?: any) {
    return HttpService.makeRequest({
      url,
      method: 'delete',
      data,
      ...options,
    });
  }

  static makeRequest(config: any) {
    //let newConfig = config;
    let location: any;
    let setPlaceholder: any;
    let noAuth: any = false;

    if (config.location) {
      location = config.location;
      delete config.location;
      store.dispatch(startLoader(location));
      //store.dispatch({ type: SpinnerAction.START, location });
    }

    if (config.setPlaceholder) {
      setPlaceholder = config.setPlaceholder;
      delete config.setPlaceholder;
      setPlaceholder(false);
    }

    if (config.noAuth) {
      noAuth = config.noAuth;
      delete config.noAuth;
    }

    const cancelToken = this.source.token;
    const token = HttpService.getToken();
    const locale = HttpService.getLanguage();

    const localeOptions = locale ? { 'Accept-Language': locale } : {};

    if (token) {
      config.headers = Object.assign(config.headers || {}, {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        // 'x-api-key': 'n02wIwHelV3Xd9H9YSSwL6kuoJ0ZQnFy7Y3yuh69',
        ...localeOptions,
      });
    }

    return axios
      .request({ ...config, cancelToken })
      .then((res) => {
        // if (res.headers.Identity) {
        //   HttpService.setToken(res.headers.Identity);
        // }
        store.dispatch(stopLoader(location));
        setPlaceholder && setPlaceholder(true);
        return res;
      })
      .catch((e) => {
        store.dispatch(stopLoader(location));
        switch (e.message.slice(-3)) {
          case '401':
            if (ls.get(LsValueType.token) && Math.floor(Date.now() / 1000) < ls.get(LsValueType.exp)) {
              store.dispatch(requestPush(View.ERROR_401));
            } else {
              ls.remove(LsValueType.token);
              !noAuth && oauthRedirect();
            }
            break;
          // case '403':
          //   store.dispatch(requestPush(View.ERROR_403));
          //   break;
          case '404':
            store.dispatch(requestPush(View.ERROR_404));
            break;
          default:
            break;
        }
        setPlaceholder && setPlaceholder(true);
        throw e;
      });
  }
}

const httpRequestMatch = {
  [ApiCallType.GET]: { call: HttpService.get },
  [ApiCallType.POST]: { call: HttpService.post },
  [ApiCallType.PATCH]: { call: HttpService.patch },
  [ApiCallType.PUT]: { call: HttpService.put },
  [ApiCallType.DELETE]: { call: HttpService.delete },
};

export interface HttpRequest {
  call: (url: string, body?: Object, options?: Object) => Promise<AxiosResponse<any>>;
}

export const getHttpRequest = (callType: ApiCallType): HttpRequest & { withoutBody: boolean } => ({
  ...httpRequestMatch[callType],
  withoutBody: callType === ApiCallType.GET,
});

export interface HttpServiceGenerationOptions {
  url: string;
  body?: Object;
  loaderOptions: LoaderOptions;
  callType: ApiCallType;
}

export const getHttpService = ({ callType, url, body = {}, loaderOptions }: HttpServiceGenerationOptions) => {
  const httpService = getHttpRequest(callType);
  const { call: httpServiceCall, withoutBody } = httpService;
  const optionsWithBody = [url, body, loaderOptions] as const;
  const optionsWithoutBody = [url, loaderOptions] as const;
  return withoutBody ? () => httpServiceCall(...optionsWithoutBody) : () => httpServiceCall(...optionsWithBody);
};
