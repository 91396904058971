/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for placeholder if 'no data' of universal table require
 * Highly customisable.
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@cthings.co/typography';
import { ReactComponent as NoDataImage } from '../../../../assets/No_Data.svg';
import { useTheme } from '@cthings.co/styles';
import { colorFetch } from '../../../../styles/utils';

const Wrapper = styled.div`
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorFetch('pureWhite')};
`;

export const NoDataPlaceholder = ({ ...props }: { className?: string; languageStrings?: any }) => {
  const theme = useTheme();

  return (
    <Wrapper {...props}>
      <NoDataImage />
      <Text theme={theme} type={TextType.TEXT_14_GRAY}>
        {props.languageStrings ? props.languageStrings.labelNoData : 'No data'}
      </Text>
    </Wrapper>
  );
};
