// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import React from 'react';
import { FC } from 'react';
import { TextType } from '@bit/first-scope.text';
import { View } from '../../../../../../routes/routeInterfaces';
import { useCustomHistory } from '../../../../../../utils/react-router-dom-abstraction';
import { CommonButton } from '../../../../../../components/commonButton/CommonButton';
import { Text } from '@bit/first-scope.text';
import { ReactComponent as Box } from '../../../../../../assets/box‎.svg';
import { useParams } from 'react-router-dom';
import { ReactComponent as LocationSlash } from '../../../../../../assets/LocationSlash.svg';
import moment from 'moment';

export const Button = styled(CommonButton)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperWithoutNeverHad = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

export const ItemStyledText = styled(Text)`
  font-weight: ${({ fontWeight }: any) => fontWeight};
  font-size: 13px;
  line-height: 24px;
  word-break: break-word;
  text-align: ${({ textAlign }: any) => textAlign};
`;

export const PackageDataWrapper = styled.div`
  margin: 10px 0;
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${({ separator }: any) => (separator ? '15px' : '5px')};
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 7px;
    height: 1px;
    background-color: ${({ separatorColor }: any) => separatorColor};
    display: ${({ separator }: any) => (separator ? 'block' : 'none')};
  }
`;

export const StyledTitleText = styled(Text)`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const BoxIcon = styled(Box)`
  & > path {
    stroke: ${({ color }: any) => color};
  }
`;

export const DetailsModalWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: ${({ isOpen }: any) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  min-width: 250px;
  width: 250px;
  height: 100%;
  border: 1px solid ${({ borderColor }: any) => borderColor};
  background-color: ${({ backgroundColor }: any) => backgroundColor};
  border-radius: ${({ borderRadius }: any) => borderRadius};
  box-sizing: border-box;
  transition: transform 0.3s linear;
  margin: 0;
`;

export const NeverHadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white1;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: gray4;
`;

export const NeverHadText = styled(Text)`
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  padding: 6px 0;
`;

export const LocationSlashIcon = styled(LocationSlash)`
  margin-right: 5px;
  & > path {
    stroke: ${({ color }: any) => color};
  }
`;

interface PackageDetailModalType {
  data: any;
  languageStrings?: any;
  color: string;
  isNeverHadLocation: boolean;
}

export const PackageDetailModal: FC<PackageDetailModalType> = ({
  data,
  color,
  languageStrings,
  isNeverHadLocation = true,
}) => {
  let { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const customHistory = useCustomHistory();
  const { gray1, gray2, gray4, white, black, primary } = theme.colors;

  const setDate = (date: string) => {
    let result = '';

    const incomingDate = new Date(date).getTime();
    const currentDate = new Date().getTime();
    const timeDifferenceMin = (currentDate - incomingDate) / 60000;

    if (timeDifferenceMin > 60) {
      result = moment(date).format('DD.MM.YYYY, HH:mm');
    } else if (timeDifferenceMin >= 1) {
      result = `${Math.floor(timeDifferenceMin)} ${languageStrings ? languageStrings.minAgo : 'min ago'}`;
    } else if (timeDifferenceMin < 1) {
      result = `${languageStrings ? languageStrings.justNow : 'Just now'}`;
    }

    return result;
  };

  const PackageModalData = [
    { name: languageStrings.labeldeviceID, value: data[2].value || 'N/A', separator: false },
    {
      name: languageStrings.labelLastUpdate,
      value: data[3].value ? setDate(data[3].value) : 'N/A',
      separator: true,
      color: color,
    },
    { name: languageStrings.labelIndexID, value: data[4].value || 'N/A', separator: false },
    { name: languageStrings.externalLabelIndexDescription, value: data[5].value || 'N/A', separator: false },
    { name: languageStrings.labelProductionID, value: data[6].value || 'N/A', separator: true },
    { name: languageStrings.labelQuantity, value: data[7].value || 'N/A', separator: false },
    { name: languageStrings.labelStage, value: data[8].value || 'N/A', separator: false },
    { name: languageStrings.labelProcess, value: data[9].value || 'N/A', separator: false },
    { name: languageStrings.labelZone, value: data[10].value || 'N/A', separator: false },
    { name: languageStrings.labelStatus, value: data[11].value || 'N/A', separator: false },
  ];

  const buttons = [
    {
      height: '26px',
      width: isNeverHadLocation && data?.[13]?.value ? '98px' : '100%',
      backgroundColor: white,
      backgroundColorHover: primary,
      color: black,
      hoverColor: white,
      borderColor: primary,
      borderColorHover: primary,
      value: languageStrings.packageButton,
      onClick: () => {
        customHistory.push(View.MANAGE_ORDERS_DETAILS, {
          pathParams: { id: id, componentsOffset: 0, packagesOffset: data?.[14]?.value ?? 0, eventsOffset: 0 },
          state: { id: data?.[0]?.value, offset: data?.[14]?.value ?? 0 },
        });
      },
    },
    {
      height: '26px',
      width: '98px',
      backgroundColor: white,
      backgroundColorHover: primary,
      color: black,
      hoverColor: white,
      borderColor: primary,
      borderColorHover: primary,
      value: languageStrings.deviceButton,
      onClick: () => {
        return data?.[12]?.value
          ? customHistory.push(View.USER_MANAGE_DEVICES_DETAILS_TRACKER, {
              pathParams: { id: data?.[13]?.value, buildingId: 'buildingId', offset: 0 },
            })
          : () => {};
      },
    },
  ];

  return (
    <ContentWrapper>
      <WrapperWithoutNeverHad>
        <TitleWrapper>
          <BoxIcon color={color} />
          <StyledTitleText type={TextType.TEXT_14_GRAY} color={black}>
            {data[1]?.value}
          </StyledTitleText>
        </TitleWrapper>
        <PackageDataWrapper>
          {PackageModalData.map((item: any) => {
            return (
              <RowWrapper separator={item.separator} separatorColor={gray4} key={item?.key}>
                <ItemStyledText fontWeight={400} color={gray1}>
                  {item.name}
                </ItemStyledText>
                <ItemStyledText
                  fontWeight={500}
                  textAlign={'right'}
                  color={item.color && item.value !== 'N/A' ? item.color : gray1}
                >
                  {item?.value}
                </ItemStyledText>
              </RowWrapper>
            );
          })}
        </PackageDataWrapper>
        <ButtonsWrapper>
          {buttons.map((button: any, index: number) => {
            const { value } = button;
            return isNeverHadLocation && !!data?.[13]?.value ? (
              <Button key={index} {...button}>
                {value}
              </Button>
            ) : languageStrings.packageButton === value ? (
              <Button key={index} {...button}>
                {value}
              </Button>
            ) : null;
          })}
        </ButtonsWrapper>
      </WrapperWithoutNeverHad>
      {isNeverHadLocation ? null : (
        <NeverHadWrapper>
          <LocationSlashIcon color={gray4} />
          <NeverHadText color={gray2}>{languageStrings?.neverHadLocation}</NeverHadText>
        </NeverHadWrapper>
      )}
    </ContentWrapper>
  );
};
