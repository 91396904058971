import React, { Dispatch, SetStateAction } from 'react';
// @ts-ignore
import { useTheme } from '@xstyled/styled-components';
import { ItemCount, ScrolledList, ScrolledWrapper, StyledItem, StyledText } from '../style/styles';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../app/state/userSlice';
import { useStyleContext } from '../../../../../../styles/style.context';

export interface AllAdressProps {
  setActiveAdress: Dispatch<SetStateAction<number>>;
  setActiveLevel: Dispatch<SetStateAction<number>>;
  setShowAllAddresses: React.Dispatch<React.SetStateAction<boolean>>;
  adressData: any[];
  activeItem: number;
  setExplorerRequestBody: (value: any) => any;
  setIsLoadingImageMap: (value: any) => any;
  handleClearWebsocketData: () => any;
  setShowAllLevels: (value: boolean) => any;
}

export const AllAdress: React.FC<AllAdressProps> = ({
  adressData,
  setActiveAdress,
  setActiveLevel,
  activeItem,
  setShowAllAddresses,
  setExplorerRequestBody,
  setIsLoadingImageMap,
  handleClearWebsocketData,
  setShowAllLevels,
}) => {
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);
  const { gray1, gray2, gray3, gray4, gray9, primary } = theme.colors;
  const [{ mediaType }] = useStyleContext();

  const setLocation = (floorPlan_id: any, building_id: string, imageLink: string, indexActiveAdress: number) => {
    setExplorerRequestBody((values: any) => {
      if (values?.building_id !== building_id && values?.building_id !== '') {
        setIsLoadingImageMap(true);
        handleClearWebsocketData();
      }
      return { ...values, floorplan_id: floorPlan_id, building_id, linkImage: imageLink };
    });

    setShowAllAddresses(true);
    setShowAllLevels(false);
    setActiveLevel(0);
    setActiveAdress(indexActiveAdress);
  };

  return (
    <ScrolledWrapper gray4={gray4}>
      <ScrolledList gray1={gray1} gray2={gray2} gray3={gray3}>
        {adressData?.length > 0 ? (
          adressData.map((item, index: number) => (
            <StyledItem
              key={item?.building?.id}
              primary={primary}
              className={index === activeItem ? 'active' : ''}
              onClick={() =>
                setLocation(
                  item?.floors?.[0]?.wpas_ref_floor_id,
                  item?.building?.wpas_ref_building_id,
                  item?.floors?.[0]?.floor_image_url,
                  index,
                )
              }
            >
              <StyledText
                gray1={gray1}
                onClick={() =>
                  setLocation(
                    item?.floors?.[0]?.wpas_ref_floor_id,
                    item?.building?.wpas_ref_building_id,
                    item?.floors?.[0]?.floor_image_url,
                    index,
                  )
                }
              >
                {item?.building?.name}
                <StyledText gray9={gray9} fontWeight={'400'} className={index === activeItem ? 'active' : ''}>
                  {!mediaType.monitorS
                    ? `, ${item?.building?.address?.city} ${item?.building?.address?.line1} ${item?.building?.address?.line2}`
                    : null}
                </StyledText>
              </StyledText>
              <ItemCount>{`${languageStrings.labelItems.toLowerCase()}: ${
                item?.building?.items_count ? item?.building?.items_count : 'N/A'
              }`}</ItemCount>
            </StyledItem>
          ))
        ) : (
          <StyledText gray1={gray1}>{'N/A'}</StyledText>
        )}
      </ScrolledList>
    </ScrolledWrapper>
  );
};
