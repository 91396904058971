/**
 * @description
 * This is component for card of dashboard
 * all the functionalities cthings require for DashboardCard
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/media';
import { Text, TextType } from '@bit/first-scope.text';
import { withLoader } from '../../features/placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../placeholders/typePlaceholders/placeholdersType';
import { DashboardImage } from './components/dashboardImage/DashboardImage';
import { Point } from './components/point/Point';
import { DashboardItemState } from '../../enums/Dashboard';
import { DashboardRedirecTypes } from './types';
import { useCustomHistory, useHistory } from '../../utils/react-router-dom-abstraction';
import { View } from '../../routes/routeInterfaces';
import { stringifyUrl } from 'query-string';
import { injectPathParams, PATHS } from '../../routes/paths';
import { useTheme } from '@cthings.co/styles';
import { borderRadiusFetch, colorFetch, shadowFetch } from '../../styles/utils';

type ExtraWrapperProps = {
  margin?: string;
  isClickableCard?: boolean;
};

const ExtraWrapper = styled.div<ExtraWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 278px;
  height: 120px;
  box-sizing: border-box;
  box-shadow: ${shadowFetch('additionalShadow2')};
  background-color: ${colorFetch('pureWhite')};
  border-radius: ${borderRadiusFetch('additional16')};
  border: 1px solid;
  border-color: ${({ isClickableCard }) => (isClickableCard ? colorFetch('secondary3') : 'transparent')};
  user-select: none;
  margin: ${({ margin }) => margin || '0'};
  padding: 20px 23px 16px;
  cursor: ${({ isClickableCard }) => (isClickableCard ? 'pointer' : 'auto')};
  ${media.tablet`
    width: 100%; 
  `};
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const TrackersWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperText = styled(Text)`
  font-size: 35px;
  line-height: 38px;
`;

const Line = styled.div`
  width: 1px;
  height: 44px;
  background-color: ${colorFetch('gray4')};
  margin: 0 22px;
  margin-top: -2px;
  align-self: center;
`;

type DataType = {
  data: {
    title: string;
    index: string;
    icon_type: string;
    value: number;
    first_item: { title: string; value: number };
    second_item: { title: string; value: number };
    state: string;
  };
  redirect_params: {
    redirect_table: { key: DashboardRedirecTypes; table_name: string };
    filter_params: any[];
  } | null;
};

enum TypeOfCard {
  COMPLEX_CARD = 'COMPLEX_CARD',
  CARD = 'CARD',
}

export interface DashboardCardProps {
  type: TypeOfCard;
  dashboardData: DataType;
  margin?: string;
}

const DashboardCardPlain: FC<DashboardCardProps> = ({ type, dashboardData, margin, ...props }) => {
  const theme = useTheme();
  const { black1, gray2, orange1, red1, primary, secondary3, secondary4 } = theme.colors;

  const customHistory = useCustomHistory();
  const history = useHistory();

  const { data, redirect_params } = dashboardData;
  const { index, title, value, first_item, second_item, icon_type, state } = data;

  const valueArr = [first_item, second_item];
  const isClickableCard = !!redirect_params;

  const color =
    state === DashboardItemState.SUCCESS
      ? secondary4
      : state === DashboardItemState.WARNING
      ? orange1
      : state === DashboardItemState.CRITICAL
      ? red1
      : primary;

  const redirect = (type: DashboardRedirecTypes) => {
    const keysArray: any = redirect_params?.filter_params.map((item: any) => {
      return { [item.filter_name]: item.value };
    });

    const joinedKeys = keysArray && Object.assign({}, ...keysArray);

    if (type === DashboardRedirecTypes.DEVICES_FILTERED) {
      history.push(stringifyUrl({ url: injectPathParams(PATHS.MANAGE_DEVICES, { offset: 0 }), query: joinedKeys }));
    } else if (type === DashboardRedirecTypes.DEVICES) {
      customHistory.push(View.MANAGE_DEVICES, {
        pathParams: { offset: 0 },
      });
    } else if (type === DashboardRedirecTypes.INVENTORIES_FILTERED) {
      history.push(stringifyUrl({ url: injectPathParams(PATHS.MANAGE_INVENTORIES, { offset: 0 }), query: joinedKeys }));
    } else if (type === DashboardRedirecTypes.INVENTORIES) {
      customHistory.push(View.MANAGE_INVENTORIES, {
        pathParams: { offset: 0 },
      });
    } else if (type === DashboardRedirecTypes.ORDERS) {
      customHistory.push(View.MANAGE_ORDERS, {
        pathParams: { offset: 0 },
      });
    } else if (type === DashboardRedirecTypes.PACKAGES) {
      customHistory.push(View.MANAGE_PACKAGES);
    } else if (type === DashboardRedirecTypes.PACKAGES_FILTERED) {
      history.push(stringifyUrl({ url: injectPathParams(PATHS.MANAGE_PACKAGES, { offset: 0 }), query: joinedKeys }));
    }
  };

  const onCardClickHandle = () => {
    if (!isClickableCard) {
      return;
    } else {
      redirect(redirect_params?.redirect_table.key);
    }
  };

  return (
    <ExtraWrapper onClick={onCardClickHandle} margin={margin} isClickableCard={isClickableCard} {...props}>
      <TitleWrapper>
        {index && <Point value={index} />}
        <Text type={TextType.TITLE_H5} color={gray2}>
          {title}
        </Text>
      </TitleWrapper>
      <Wrapper>
        {type === TypeOfCard.COMPLEX_CARD ? (
          <TrackersWrapper>
            {valueArr.map((item: any, index: number) => {
              const { title, value } = item;
              return (
                <>
                  <ValueWrapper>
                    <Text type={TextType.TEXT_10_GRAY} color={gray2}>
                      {title}
                    </Text>
                    <WrapperText type={TextType.PROBLEMS_BUTTON_TEXT} color={black1}>
                      {value ? value : 'N/A'}
                    </WrapperText>
                  </ValueWrapper>
                  {index === 0 && <Line />}
                </>
              );
            })}
          </TrackersWrapper>
        ) : (
          <WrapperText type={TextType.PROBLEMS_BUTTON_TEXT} color={black1}>
            {value && !isNaN(value) ? value : 'N/A'}
          </WrapperText>
        )}
        <DashboardImage type={icon_type} iconColor={color} />
      </Wrapper>
    </ExtraWrapper>
  );
};

export const DashboardCard = withLoader(undefined, PlaceholderType.CARD)(DashboardCardPlain);
