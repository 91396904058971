/**
 * @description
 * This is an advanced function that covers
 * long taps in all project
 *
 */

import { useCallback, useRef, useState } from 'react';

export const useLongTap = (
  action: (e: any, ...props: any[]) => void,
  onClick: (e: any, ...props: any[]) => void,
  duration: number,
  condition: boolean,
) => {
  const timerId = useRef(null as any);
  const [reached, setReached] = useState(false);

  const onTouchStart = useCallback(
    (e: any, ...props: any[]) => {
      setReached(false);
      const handleAction = (e: any, ...props: any[]) => {
        action && action(e, ...props);
      };
      timerId.current && clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        if (condition) {
          setReached(true);
          handleAction(e, ...props);
        }
      }, duration);
    },
    [timerId, duration, action, condition],
  );
  const onTouchEnd = useCallback(
    (e: any, ...props: any[]) => {
      timerId.current && clearTimeout(timerId.current);
      !reached && onClick(e, ...props);
    },
    [timerId, reached, onClick],
  );

  const onTouchMove = useCallback(
    (e: any, ...props: any[]) => {
      !reached && setReached(true);
    },
    [reached],
  );

  const clearTouch = useCallback(
    (e: any, ...props: any[]) => {
      !reached && setReached(true);
    },
    [reached],
  );

  return [onTouchStart, onTouchEnd, clearTouch] as const;
};

export const useDragEnd = (
  actionStart: (e: any, ...props: any[]) => void,
  actionEnd: (e: any, ...props: any[]) => void,
  /*onClick: (e: any, ...props: any[]) => void,*/
  duration: number,
  condition: boolean,
) => {
  const timerId = useRef(null as any);
  const [reached, setReached] = useState(false);

  const onDragStart = useCallback(
    (e: any, ...props: any[]) => {
      setReached(false);
      actionStart && actionStart(e, ...props);
      timerId.current && clearTimeout(timerId.current);
      timerId.current = setTimeout(() => {
        if (condition) {
          setReached(true);
        }
      }, duration);
    },
    [timerId, duration, condition, actionStart],
  );
  const onDragEnd = useCallback(
    (e: any, ...props: any[]) => {
      const handleAction = (e: any, ...props: any[]) => {
        actionEnd && actionEnd(e, ...props);
      };
      timerId.current && clearTimeout(timerId.current);
      /*!reached && onClick(e, ...props);*/
      reached && handleAction(e, ...props);
    },
    [timerId, reached, actionEnd /*, onClick*/],
  );

  return [onDragStart, onDragEnd] as const;
};
