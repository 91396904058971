/**
 * @description
 * component for common use
 * used to switch filter
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '../../styles/media';
import { SwipeSwitch } from '../swipeSwitch/SwipeSwitch';
import { useTheme } from '@cthings.co/styles';
import { colorFetch } from '../../styles/utils';

type WrapperProps = {
  maxWidth?: string;
  isActive: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  max-width: ${({ maxWidth }) => maxWidth || '185px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  width: 100%;
  height: 31px;
  box-sizing: border-box;
  background-color: ${({ isActive }) => (isActive ? 'pureWhite' : 'transparent')};
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out;
  ${media.desktop`
    margin-top: 10px;
  `}
`;

type ZonesTextProps = {
  isActive: boolean;
};

const ZonesText = styled(Text)<ZonesTextProps>`
  font-weight: 500;
  color: ${({ isActive, theme }) => (isActive ? colorFetch('gray1')({ theme }) : colorFetch('gray2')({ theme }))};
`;

/* switch to enable zones */
export const SwipeSwitchFilter = ({
  isActive,
  name,
  handleStateSwitch,
  disabled,
  maxWidth,
}: {
  isActive: boolean;
  name: string;
  handleStateSwitch: () => void;
  disabled?: boolean;
  maxWidth?: string;
}) => {
  const theme = useTheme();
  const { primary, gray3 } = theme.colors;

  return (
    <Wrapper isActive={isActive} max-width={maxWidth}>
      <ZonesText type={TextType.TEXT_12_BLACK} isActive={isActive}>
        {name}
      </ZonesText>
      <SwipeSwitch
        stateSwitch={isActive}
        handleStateSwitch={handleStateSwitch}
        height={20}
        width={35}
        onColor={primary}
        offColor={gray3}
        disabled={disabled}
      />
    </Wrapper>
  );
};
