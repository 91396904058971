import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styles';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/userSlice';
//images
import { ReactComponent as LogOutImage } from '../../../assets/Logout.svg';
import {
  Line,
  LogoutButton,
  StyledLogoutText,
  TextWrapper,
  UserName,
  UserNameWrapper,
  UserRole,
  UserRoleWrapper,
  WrapperLogout,
} from './styled';

export interface LogoutItemTypes {
  userRole: string | undefined;
  userName: string | undefined;
  onLogout: any;
  isOpen: boolean;
}

export const LogoutItem: FC<LogoutItemTypes> = ({ userRole, userName, onLogout, isOpen }) => {
  const theme = useTheme();
  const language = useSelector(selectLanguageStrings);

  const { black1, gray2 } = theme.colors;

  const getInitials = (fullName: string) => {
    let names = fullName.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return (
    <WrapperLogout>
      {userName && (
        <UserNameWrapper className={isOpen ? 'open' : ''}>
          <UserName type={TextType.TEXT_12_BLACK} color={black1}>
            {!isOpen ? getInitials(userName) : userName}
          </UserName>
        </UserNameWrapper>
      )}
      {isOpen && (
        <UserRoleWrapper className={isOpen ? 'open' : ''}>
          <UserRole type={TextType.TEXT_12_BLACK} color={gray2}>
            {userRole}
          </UserRole>
        </UserRoleWrapper>
      )}
      <Line />
      <LogoutButton onClick={onLogout}>
        <LogOutImage />
        <TextWrapper className={isOpen ? 'open' : ''}>
          <StyledLogoutText type={TextType.TEXT_12_BLACK} color={black1}>
            {language ? language.logoutButton : 'Logout'}
          </StyledLogoutText>
        </TextWrapper>
      </LogoutButton>
    </WrapperLogout>
  );
};
