import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Loader } from './components/Loader';
import { ButtonIconType, Icons } from './components/Icons';
import { media } from '../../styles/media';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';
import { useTheme } from '@cthings.co/styles';

type ButtonProps = {
  height?: string;
  width?: string;
  backgroundColor?: string;
  backgroundColorHover?: string;
  color?: string;
  hoverColor?: string;
  borderColor?: string;
  borderColorHover?: string;
  iconPositionRight?: boolean;
};

const Button = styled.button<ButtonProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: ${({ iconPositionRight }) => (iconPositionRight ? 'row-reverse' : 'row')};
  align-items: center;
  background-color: ${({ backgroundColor, theme }) => backgroundColor || colorFetch('primary')({ theme })};
  color: ${({ color, theme }) => color || colorFetch('pureWhite')({ theme })};
  border: 1px solid;
  border-color: ${({ borderColor, theme }) => borderColor || colorFetch('primary')({ theme })};
  border-radius: ${borderRadiusFetch('primary')};
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;

  & > svg {
    flex-shrink: 0;
    & > path {
      stroke: ${({ color }) => color};
    }
  }

  &:hover {
    background-color: ${({ backgroundColorHover, theme }) =>
      backgroundColorHover || colorFetch('secondary2')({ theme })};

    border-color: ${({ borderColorHover, theme }) => borderColorHover || colorFetch('secondary2')({ theme })};
    color: ${({ hoverColor, theme }) => hoverColor || colorFetch('pureWhite')({ theme })};
    & .loader > div > div:before {
      background-color: ${({ hoverColor, theme }) => hoverColor || colorFetch('pureWhite')({ theme })};
    }
  }

  ${media.desktop`
    &:hover {
      background-color: ${({ backgroundColor }: any) => backgroundColor};
      border-color: ${({ borderColor }: any) => borderColor};
      color: ${({ color }: any) => color};
        & .loader > div > div:before {
          background-color: ${({ color }: any) => color}
        }
      } 
  `}
`;

export interface CommonButtonWithIconProps {
  onClick: any;

  children?: ReactNode;
  height?: string;
  width?: string;
  backgroundColor?: string;
  backgroundColorHover?: string;
  color?: string;
  hoverColor?: string;
  borderColor?: string;
  borderColorHover?: string;
  noFocus?: boolean;
  className?: string;
  iconType?: ButtonIconType;
  iconPositionRight?: boolean;
  isLoadingInProcess?: boolean;
  iconWithoutText?: boolean;
  disabled?: boolean;
}

export const CommonButtonWithIcon: FC<CommonButtonWithIconProps> = ({
  children,
  onClick,
  noFocus,
  height,
  width,
  backgroundColor,
  backgroundColorHover,
  color,
  hoverColor,
  borderColor,
  borderColorHover,
  iconType,
  iconPositionRight,
  isLoadingInProcess,
  iconWithoutText,
  disabled,
  ...props
}) => {
  const theme = useTheme();
  const { pureWhite } = theme.colors;

  const handleMouseDown = (e: any) => {
    noFocus && e.preventDefault();
  };
  return (
    <Button
      onClick={onClick}
      onMouseDown={handleMouseDown}
      width={width}
      height={height}
      color={color}
      hoverColor={hoverColor}
      backgroundColor={backgroundColor}
      backgroundColorHover={backgroundColorHover}
      borderColor={borderColor}
      borderColorHover={borderColorHover}
      iconPositionRight={iconPositionRight}
      disabled={disabled}
      {...props}
    >
      {isLoadingInProcess ? (
        <Loader className="loader" color={color ? color : pureWhite} />
      ) : (
        <Icons type={iconType} color={color} iconWithoutText={iconWithoutText} />
      )}
      {children}
    </Button>
  );
};

CommonButtonWithIcon.defaultProps = {
  width: '230px',
  height: '32px',
};
