/**
 * @description
 * Forms clusters of filtered elements
 *
 */

import { useEffect, useState } from 'react';
import { getPixelDistance } from '../../utils/distance';
import { usePrevious } from '../../utils/usePrevious';
import { ClusterItem, ClusterPoint, Point } from './types';

export const useClustering = (points: Point[], scale: number, clusterRadius: number, updateDate: any = null) => {
  const [filteredClusters, setFilteredClusters] = useState(points);
  const prevScale: number = usePrevious(scale);
  const prevLength: number = usePrevious(points.length);
  useEffect(() => {
    if (
      (points.length !== prevLength || (scale && (scale === 1 || Math.pow(scale - prevScale, 2) > 0.005))) &&
      clusterRadius
    ) {
      setFilteredClusters(getClusteredData(points, scale, clusterRadius));
    }
    // @TODO Artem S check pls this array of triggers, in my opinion it is the source of the problem
  }, [points.length, scale, clusterRadius, updateDate]);

  return { filteredClusters };
};

const getPointIds = (point: Point | ClusterPoint) =>
  (point as ClusterPoint).ids?.length > 0 ? (point as ClusterPoint).ids : [point.name];

const getClusteredData: any = (points: (Point | ClusterPoint)[], scale: number, clusterRadius: number) => {
  let smallestDistance: number;
  let smallestDistanceRow = 0;
  let minX: any, maxX: any, minY: any, maxY: any;

  const enrichedPoints = points.map((outer_point: Point | ClusterPoint, outer_index: number) => {
    const distances = points.map((point: Point | ClusterPoint, index: number) => {
      if (index < outer_index) {
        return { location: [index, outer_index], ids: getPointIds(point), options: point.options };
      }

      const pixelDistance = getPixelDistance(outer_point.pixel_position, point.pixel_position);
      /*if (pixelDistance === 0 && index !== outer_index) {
         return { distance: 0.1, ids: getPointIds(point), options: point.options };
       }*/
      if (pixelDistance !== 0 && (!smallestDistance || pixelDistance < smallestDistance)) {
        smallestDistance = pixelDistance;
        smallestDistanceRow = outer_index;
      }
      return { distance: pixelDistance, ids: getPointIds(point), options: point.options };
    });

    return {
      isCluster: false,
      ...outer_point,
      distances,
      options: outer_point.options,
      ids: getPointIds(outer_point),
    };
  });

  const clusterItems = enrichedPoints[smallestDistanceRow]
    ? enrichedPoints[smallestDistanceRow].distances.reduce(
        (accumulator: ClusterItem[], currentItem: any, currentIndex: number) => {
          const { location, distance, ids, options } = currentItem;
          const currentDistance = !location ? distance : enrichedPoints[location[0]].distances[location[1]].distance;
          let items: ClusterItem[] = [];
          if (currentDistance < clusterRadius / scale) {
            items = [{ _index: currentIndex, ids, options }];
            const { pixel_position } = enrichedPoints[smallestDistanceRow];
            if (!minX || pixel_position.x < minX) {
              minX = pixel_position.x;
            }
            if (!maxX || pixel_position.x > maxX) {
              maxX = pixel_position.x;
            }
            if (!minY || pixel_position.y < minY) {
              minY = pixel_position.y;
            }
            if (!maxY || pixel_position.y > maxY) {
              maxY = pixel_position.y;
            }
          }
          return [...accumulator, ...items];
        },
        [],
      )
    : [];

  const clusterProps = clusterItems.reduce(
    (acc: any, newEl: ClusterItem) => ({
      options: {
        hasNodes: newEl.options.hasNodes || acc.options.hasNodes,
        hasOffline: newEl.options.hasOffline || acc.options.hasOffline,
        hasTrackers: newEl.options.hasTrackers || acc.options.hasTrackers,
      },
      ids: [...acc.ids, ...newEl.ids],
    }),
    { ids: [], options: { hasNodes: false, hasOffline: false, hasTrackers: false } },
  );

  const pixel_position = { x: (maxX + minX) / 2, y: (maxY + minY) / 2 };

  const newPoints = [
    { pixel_position, ids: clusterProps.ids, isCluster: true, options: clusterProps.options },
    ...enrichedPoints.filter(
      (p: any, index) => clusterItems.findIndex((item: ClusterItem) => item._index === index) === -1,
    ),
  ];

  if (clusterItems.length > 1) {
    return getClusteredData(newPoints, scale, clusterRadius);
  } else {
    return enrichedPoints;
  }
};
