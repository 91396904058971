import React, { FC } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { TooltipPlace } from '../../../../components/tooltip/types';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/userSlice';
import { colorFetch } from '../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';
import Tooltip from '../../../../components/tooltip';

const StyledText = styled(Text)`
  display: block;
  word-wrap: break-word;
  & > span {
    color: ${colorFetch('gray1')};
    width: 100%;
    &.normal {
      font-weight: normal;
    }
  }
` as typeof Text;

const TooltipWrapper = styled.div`
  width: max-content;

  & > div.__react_component_tooltip {
    max-width: 150px !important;
    width: 100% !important;
    white-space: break-spaces !important;
    text-align: center;
  }
`;

interface TextRowProps {
  isTooltip: boolean;
  textTooltip: string;
  index: any;
  getTextDisplay: any;
  value: any;
}

export const TextRow: FC<TextRowProps> = ({ isTooltip, textTooltip, index, getTextDisplay, value }) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  const language = useSelector(selectLanguageStrings);

  const getTextRoleTooltip = (typeId: number) => {
    const roleTypeMessageList = [
      { message: language?.textTooltipUser ?? 'User', id: 0 },
      { message: language?.textTooltipAdmin ?? 'Admin', id: 1 },
      { message: language?.textTooltipDataEntry ?? 'Data Entry', id: 2 },
      { message: language?.textTooltipCustomerFacing ?? 'Customer Facing', id: 3 },
      { message: language?.textTooltipQuality ?? 'Quality', id: 4 },
      { message: language?.textTooltipMaintenance ?? 'Maintenance', id: 5 },
      { message: language?.textTooltipSteelCoils ?? 'Steel Coils', id: 6 },
      { message: language?.textTooltipInternalAdmin ?? 'Internal Admin', id: 7 },
    ];

    return roleTypeMessageList[typeId]?.message;
  };

  return isTooltip ? (
    <TooltipWrapper>
      <Tooltip id={'d'} text={getTextRoleTooltip(value?.id) ?? ''} place={TooltipPlace.TOP}>
        <StyledText key={index} type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
          {getTextDisplay
            ? getTextDisplay(value)
            : value
            ? value.id === undefined && value.name === undefined
              ? value
              : value.name
            : 'N/A'}
        </StyledText>
      </Tooltip>
    </TooltipWrapper>
  ) : (
    <StyledText key={index} type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
      {getTextDisplay
        ? getTextDisplay(value)
        : value
        ? value.id === undefined && value.name === undefined
          ? value
          : value.name
        : 'N/A'}
    </StyledText>
  );
};
