/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings placeholder for maps require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as Map } from '../assets/map.svg';

const StyledMap = styled(Map)`
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
export interface ComponentProps {
  // type: PlaceholderType;
}

export const PlaceholderMap: FC<ComponentProps> = () => {
  return <StyledMap width="100%" height="100%" preserveAspectRatio="xMidYMid slice" />;
};
