import React, { useEffect, useRef, useState } from 'react';
import { DropdownMultiChoice } from '../dropdownMultiChoice/DropdownMultiChoice';

interface OptionProp {
  id: string;
  isSelected: boolean;
  key: string;
  name: string;
}

export interface DataProps {
  key?: string;
  name: string;
  type?: string;
  options: OptionProp[];
  defaultValue?: any;
  isDisabled?: boolean;
}

export const CustomDropdown = ({
  data,
  setValues,
  setIsClearItems,
  isClearItems,
  languageStrings,
  selectId,
  setSelectId,
  isSelected,
  setSavedData,
}: {
  data: DataProps;
  isClearItems?: boolean;
  setIsClearItems: () => void;
  setValues: (values: any) => void;
  languageStrings: any;
  selectId: number;
  setSelectId: (id: number | null) => void;
  isSelected: boolean;
  setSavedData: (values: any) => void;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [items, setItems] = useState<any>(data?.options);
  const [value, setValue] = useState<any>();
  const checkIndex = (id: string) => {
    setItems((currentItems: any) => {
      const index = currentItems.findIndex(({ id: itemId }: any) => itemId === id);
      if (index !== -1) {
        const { isSelected } = currentItems[index];
        if (!isSelected) {
          const updatedItem = { ...currentItems[index], isSelected: !isSelected };
          const updatedCurrentItems = currentItems.map((item: any) => ({ ...item }));
          const result = [...updatedCurrentItems.slice(0, index), updatedItem, ...updatedCurrentItems.slice(index + 1)];
          setValue([updatedItem]);
          return result;
        }
        const updatedItem = { ...currentItems[index], isSelected: !isSelected };
        const result = [...currentItems.slice(0, index), updatedItem, ...currentItems.slice(index + 1)];
        const selectedParameters = result.filter((index: any) => index.isSelected);
        setValue(selectedParameters);
        return result;
      } else {
        const selectedParameters = currentItems.filter((index: any) => index.isSelected);
        setValue(selectedParameters);
        return currentItems;
      }
    });
  };

  const selectRemoveIndex = () => {
    setItems((currentItems: any) => currentItems.map((currentItem: any) => ({ ...currentItem, isSelected: false })));
  };

  const handleClearItems = () => {
    const updatedItems = items.map((item: any) => {
      return { ...item, isSelected: false };
    });
    setItems(updatedItems);
  };

  useEffect(() => {
    if (isClearItems) handleClearItems();
    setIsClearItems();
  }, [isClearItems]);

  useEffect(() => {
    const filteredList = items.filter((item: any) => {
      if (item.isSelected) return item;
    });

    setSavedData({ ...data, options: [...items] });
    filteredList && setValues({ values: [...filteredList], key: data?.key });
  }, [value, items]);

  return (
    <DropdownMultiChoice
      parameters={data?.defaultValue || items}
      options={{
        placeholder: 'Choose component',
        title: data.name,
        isValidatingStarted: false,
        isError: false,
        isOpenSelectList: isSelected,
        popUpScrollTo: 244,
        popUpScrollToWithCustomPeriod: 288,
        isDisabled: data?.isDisabled,
        wrapperRef,
        disabledPlaceholder: 'Choose component',
        selectedIndexOfPeriod: 1,
      }}
      functions={{
        handleCheckedItem: (id: string) => checkIndex(id),
        onButtonClick: () => {
          selectRemoveIndex();
        },
        setIsOpenSelectList: (value: any) => {
          setSelectId(value ? selectId : null);
        },
        setIsScrollableModal: () => true,
      }}
      isEditInProcess={true}
      languageStrings={languageStrings}
      selectedParameters={value}
    />
  );
};
