import React from 'react';
import styled from 'styled-components';
import { ReactComponent as NoDataIcon } from '../../../../../assets/No_Data.svg';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';

const Wrapper = styled.div`
  width: 100%;
  min-height: 192px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(NoDataIcon)`
  margin-right: 6px;
`;

export const RowPlaceholder = () => {
  const language = useSelector(selectLanguageStrings);

  return (
    <Wrapper>
      <StyledIcon />
      <Text type={TextType.TEXT_14_GRAY} weight={'400'} lineHeight={'22px'}>
        {language ? language.externalPlaceholderNoData : 'No documents yet'}
      </Text>
    </Wrapper>
  );
};
