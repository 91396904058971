/**
 * @description
 * This is component for table in order details
 * all the functionalities cthings require for order table
 * good customisable.
 *
 */

import { Text, TextType } from '@bit/first-scope.text';
import styled from 'styled-components';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { isClasp } from '../../consts';
import { media } from '../../styles/media';
import { getRequiredDateFormat } from '../../utils/date/date-format';
import { ContentTable } from './contentTable/ContentTable';

const Wrapper = styled.div`
  min-width: 380px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${media.tablet`
    min-width: 288px;
  `}
`;

export interface HeaderLabelsProps {
  name: string;
  isSorted: boolean;
}

export interface OrderTableProps {
  data: any;
  title: string;
  headerLabels: HeaderLabelsProps[];
  headerGridTemplate?: any;
}

export const OrderTableInfo: FC<OrderTableProps> = ({ data, title, headerLabels, headerGridTemplate }) => {
  const language = useSelector(selectLanguageStrings);
  const { created_at, updated_at, shipping_deadline, released_at, order_type, completion, customer_name } = data;

  const arrWithOrderType = [
    {
      property: language ? language.labelСreatedAt : 'Created at',
      value: created_at ? getRequiredDateFormat(created_at, 'DD.MM.YYYY') : 'N/A',
    },
    {
      property: language ? language.labelLastUpdate : 'Last update',
      value: updated_at ? getRequiredDateFormat(updated_at, 'DD.MM.YYYY, HH:mm') : 'N/A',
    },
    {
      property: language ? language.labelShippingDeadline : 'Shipping deadline',
      value: shipping_deadline ? getRequiredDateFormat(shipping_deadline, 'DD.MM.YYYY') : 'N/A',
    },
    {
      property: language ? language.labelReleasedAt : 'Released at',
      value: released_at ? getRequiredDateFormat(released_at, 'DD.MM.YYYY') : 'N/A',
    },
    { property: language ? language.labelCustomer : 'Customer', value: customer_name || 'N/A' },
    { property: language ? language.labelOrderType : 'Order Type', value: order_type },
    {
      property: language ? language.labelCompletion : 'Completion',
      value: completion ? `${completion.value}%` : 'N/A',
    },
  ];

  const arrWithoutOrderType = [...arrWithOrderType.slice(0, 5), ...arrWithOrderType.slice(5 + 1)];

  const arr = isClasp ? arrWithOrderType : arrWithoutOrderType;

  return (
    <Wrapper>
      <Text type={TextType.SEMITITLE}>{title}</Text>
      <ContentTable
        placeholderProps={{
          keyword: 'OrderInfoTable',
          style: { width: '100%', marginTop: '15px' },
          placeholderOptions: { countRows: 4, withoutPagination: true },
        }}
        headerLabels={headerLabels}
        headerGridTemplate={headerGridTemplate}
        arr={arr}
        completionStatus={completion && completion.status}
        updated_at={updated_at}
        shipping_deadline={shipping_deadline}
      />
    </Wrapper>
  );
};
