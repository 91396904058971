/**
 * @description
 * This is component mobile wrapper for buttons
 * all the functionalities cthings for mobile wrapper require
 * Highly customisable.
 *
 */

import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { media } from '../../styles/media';
import { colorFetch, shadowFetch } from '../../styles/utils';

type WrrapperProps = {
  zIndex?: string;
};

// @TODO fix media types
const Wrapper = styled.div<WrrapperProps>`
  display: none;
  ${media.tablet`
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 74px;
    box-sizing: border-box;
    background-color: ${colorFetch('pureWhite')};
    box-shadow: ${shadowFetch('additionalShadow9')};
    padding: 0 20px;
    position: fixed;
    bottom: 0;
    z-index: ${({ zIndex }: any) => zIndex || '100'};
    border-top: 1px solid;
    border-color: ${colorFetch('gray3')};
  `}
`;

export const MobilePortalContainer = ({
  children,
  zIndex,
  ...props
}: {
  children: any;
  zIndex?: string;
  className?: string;
}) => {
  return createPortal(
    <Wrapper zIndex={zIndex} {...props}>
      {children}
    </Wrapper>,
    document.getElementById('mobile_container') || document.body,
  );
};
