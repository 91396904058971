export enum FileNameType {
  PRIVACY_POLICY = 'privacy_policy',
  TERMS_OF_SERVICE = 'terms_of_service',
  PLATFORM_ARCHITECTURE = 'platform_architecture',
  SMART_INDUSTRIAL_TRACKERS_SPECIFICATION = 'smart_industrial_trackers_specification',
  CLASP_RELEASE_NOTE_2_0_0 = 'CLASP_Release_Note_2_0_0',
  CLASP_RELEASE_NOTE_2_1_0 = 'CLASP_Release_Note_2_1_0',
  CLASP_RELEASE_NOTE_2_2_0 = 'CLASP_Release_Note_2_2_0',
  CLASP_RELEASE_NOTE_3_1_0 = 'CLASP_Release_Note_3_1_0',
  CLASP_RELEASE_NOTE_3_3_0 = 'CLASP_Release_Note_3_3_0',
  CLASP_RELEASE_NOTE_3_4_0 = 'CLASP_Release_Note_3_4_0',
  CLASP_RELEASE_NOTE_3_5_0 = 'CLASP_Release_Note_3_5_0',
  CLASP_RELEASE_NOTE_3_6_0 = 'CLASP_Release_Note_3_6_0',
  CLASP_RELEASE_NOTE_3_7_0 = 'CLASP_Release_Note_3_7_0',
}
