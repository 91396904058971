import { DashboardItemIconType, DashboardItemState, DashboardItemType } from '../enums/Dashboard';

// @NOTE this data is simply used to render placeholders for dashboard
export const defaultData = {
  upper_section: [
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Online',
        value: 12,
        icon_type: DashboardItemIconType.ONLINE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
      onlyPlaceholder: true,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Online',
        value: 12,
        icon_type: DashboardItemIconType.ONLINE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
      onlyPlaceholder: true,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Online',
        value: 12,
        icon_type: DashboardItemIconType.ONLINE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
      onlyPlaceholder: true,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Online',
        value: 12,
        icon_type: DashboardItemIconType.ONLINE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
      onlyPlaceholder: true,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Online',
        value: 12,
        icon_type: DashboardItemIconType.ONLINE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
      onlyPlaceholder: true,
    },
    {
      tile_type: DashboardItemType.CARD,
      data: {
        title: 'Online',
        value: 12,
        icon_type: DashboardItemIconType.ONLINE,
        state: DashboardItemState.NORMAL,
      },
      width: 1,
      onlyPlaceholder: true,
    },
  ],
  bottom_section: [],
};
