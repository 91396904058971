import React from 'react';
import { Navigate } from 'react-router-dom';
import { CampusMapInsight, Dashboard, Insights } from '../containers/common';
import { Documents } from '../containers/common/documents/Documents';
import { DocumentationNFC } from '../containers/common/documentationNFC/DocumentationNFC';
import { ProblemsDetected } from '../containers/common/problemsDetected/ProblemsDetected';
import { SearchSystem } from '../containers/common/smartSearch/SearchSystem';
import { ErrorPage, ErrorPageType } from '../containers/errorPage/ErrorPage';
import { ComponentsPage } from '../containers/management/componentsPage/ComponentsPage';
import { DeviceDetails } from '../containers/management/deviceDetails/DeviceDetails';
import { Devices } from '../containers/management/devices/Devices';
import { Inventories } from '../containers/management/inventories/Inventories';
import { OrderDetails } from '../containers/management/orderDetails/OrdersDetails';
import { Orders } from '../containers/management/orders/Orders';
import { Processes } from '../containers/management/processes/Processes';
import { Stages } from '../containers/management/stages/Stages';
import { Users } from '../containers/management/users/Users';
import { Zones } from '../containers/management/zones/Zones';
import { ZonesDetails } from '../containers/management/zonesDetails/ZonesDetails';
import { DeviceDetailsType } from '../enums/View';
import withAuth from '../features/authentication/withAuth';
import Errors from '../layouts/errors/Errors';
import { ManagementMobile, ManagementMobileProtected } from '../layouts/managementMobile/ManagementMobile';
import User from '../layouts/user/User';
import { injectPathParams, PATHS } from './paths';
import UserSwitcher from './UserSwitcher';
import { DocumentsPreview } from '../containers/common/documentsPreview/DocumentsPreview';
import { DocumentPreviewPasswordPage } from '../components/documentPreviewPasswordPage/DocumentPreviewPasswordPage';
import { DocsPage } from '../containers/common/documentsPreview/docsPage/DocsPage';
import { CertificatesPage } from '../containers/common/documentsPreview/certificatesPage/CertificatesPage';
import { FloorMapDetails } from '../containers/common/floorMapDetails/FloorMapDetails';
import { SmartSearch } from '../containers/common/smartSearch/SmartSearch';
import { Packages } from '../containers/management/packages/Packages';

export const routes = [
  {
    path: PATHS.USER,
    render: ({ ...props }: any) => <User {...props} />,
    routes: [
      { path: PATHS.USER_SEARCH, component: <SearchSystem /> },
      {
        path: PATHS.USER_PROBLEMS,
        component: <ProblemsDetected />,
      },
      { path: PATHS.USER_DASHBOARD, component: <Dashboard /> },
      {
        path: PATHS.USER_DASHBOARD_REDIRECT,
        component: <Navigate to={injectPathParams(PATHS.USER_DASHBOARD, { type: 'summary' })} />,
      },
    ],
  },
  {
    path: PATHS.USER_SITES_REDIRECT,
    render: ({ ...props }: any) => <ManagementMobileProtected {...props} />,
    routes: [
      {
        path: PATHS.USER_INSIGHTS_DETAILS,
        component: <CampusMapInsight />,
      },
      {
        path: PATHS.USER_FLOOR_MAP_DETAILS,
        component: <FloorMapDetails />,
      },
      {
        path: PATHS.USER_INSIGHTS,
        component: <Insights />,
      },

      { path: PATHS.MANAGE_ZONES_DETAILS, component: <ZonesDetails /> },

      { path: PATHS.MANAGE_ZONES, component: <Zones /> },

      {
        path: PATHS.USER_SITES_REDIRECT,
        component: <Navigate to={injectPathParams(PATHS.USER_INSIGHTS, {})} />,
      },
    ],
  },
  {
    path: PATHS.USER_INVENTORIES_REDIRECT,
    render: ({ ...props }: any) => <ManagementMobileProtected {...props} />,
    routes: [{ path: PATHS.MANAGE_INVENTORIES, component: <Inventories /> }],
  },
  {
    path: PATHS.USER_USERS_REDIRECT,
    render: ({ ...props }: any) => <ManagementMobileProtected {...props} />,
    routes: [{ path: PATHS.MANAGE_USERS, component: <Users /> }],
  },
  {
    path: PATHS.USER_PACKAGES_REDIRECT,
    render: ({ ...props }: any) => <ManagementMobileProtected {...props} />,
    routes: [{ path: PATHS.MANAGE_PACKAGES, component: <Packages /> }],
  },
  {
    path: PATHS.USER_DEVICES_REDIRECT,
    render: ({ ...props }: any) => <ManagementMobileProtected {...props} />,
    routes: [
      {
        path: PATHS.USER_MANAGE_DEVICES_DETAILS_NODE,
        component: <DeviceDetails type={DeviceDetailsType.NODE} />,
      },
      {
        path: PATHS.USER_MANAGE_DEVICES_DETAILS_TRACKER,
        component: <DeviceDetails type={DeviceDetailsType.TRACKER} />,
      },
      {
        path: PATHS.MANAGE_DEVICES_DETAILS,
        component: <DeviceDetails type={DeviceDetailsType.NODE} />,
      },
      { path: PATHS.MANAGE_DEVICES, component: <Devices /> },
    ],
  },
  {
    path: PATHS.USER_OPERATIONS_REDIRECT,
    render: ({ ...props }: any) => <ManagementMobileProtected {...props} />,
    routes: [
      {
        path: PATHS.MANAGE_STAGES,
        component: <Stages />,
      },
      {
        path: PATHS.MANAGE_PROCESSES,
        component: <Processes />,
      },

      {
        path: PATHS.USER_OPERATIONS_REDIRECT,
        component: <Navigate to={injectPathParams(PATHS.MANAGE_STAGES, { offset: '0' })} />,
      },
    ],
  },
  {
    path: PATHS.USER_SERVICES_REDIRECT,
    render: ({ ...props }: any) => <ManagementMobileProtected {...props} />,
    routes: [
      {
        path: PATHS.MANAGE_ORDERS_DETAILS,
        component: <OrderDetails />,
      },
      {
        path: PATHS.MANAGE_ORDERS,
        component: <Orders />,
      },
      {
        path: PATHS.MANAGE_COMPONENTS,
        component: <ComponentsPage />,
      },

      {
        path: PATHS.USER_SERVICES_REDIRECT,
        component: <Navigate to={injectPathParams(PATHS.MANAGE_STAGES, { offset: '0' })} />,
      },
    ],
  },
  {
    path: PATHS.MANAGE,
    render: ({ ...props }: any) => <ManagementMobileProtected {...props} />,
    routes: [
      {
        path: PATHS.MANAGE_DEVICES_DETAILS,
        component: <DeviceDetails type={DeviceDetailsType.NODE} />,
      },
      { path: PATHS.MANAGE_DEVICES, component: <Devices /> },

      { path: PATHS.MANAGE_USERS, component: <Users /> },
      {
        path: PATHS.MANAGE_ORDERS_DETAILS,
        component: <OrderDetails />,
      },
      { path: PATHS.MANAGE_ORDERS, component: <Orders /> },
      { path: PATHS.MANAGE_STAGES, component: <Stages /> },
      { path: PATHS.MANAGE_PROCESSES, component: <Processes /> },
      { path: PATHS.MANAGE_COMPONENTS, component: <ComponentsPage /> },
      { path: PATHS.MANAGE_ZONES_DETAILS, component: <ZonesDetails /> },
      { path: PATHS.MANAGE_ZONES, component: <Zones /> },
      { path: PATHS.MANAGE_INVENTORIES, component: <Inventories /> },
    ],
  },
  {
    path: PATHS.ERROR,
    render: ({ ...props }: any) => <Errors {...props} />,
    routes: [
      { path: PATHS.ERROR_403, component: <ErrorPage type={ErrorPageType.ERROR_403} /> },
      { path: PATHS.ERROR_500, component: <ErrorPage type={ErrorPageType.ERROR_500} /> },
      { path: PATHS.ERROR_404, component: <ErrorPage type={ErrorPageType.ERROR_404} /> },
      { path: PATHS.ERROR_401, component: <ErrorPage type={ErrorPageType.ERROR_401} /> },
    ],
  },
  {
    path: PATHS.DOCUMENTS_REDIRECT,
    render: ({ ...props }: any) => <ManagementMobileProtected {...props} />,
    routes: [
      { path: PATHS.USER_DOCUMENTATION_NFC, component: <DocumentationNFC /> },
      { path: PATHS.USER_DOCUMENTS, component: <Documents /> },
      { path: PATHS.DOCUMENTS_REDIRECT, component: <Navigate to={PATHS.USER_DOCUMENTS} /> },
    ],
  },
  {
    path: PATHS.DOCUMENTS_PREVIEW,
    render: ({ ...props }: any) => <ManagementMobile {...props} />,
    routes: [{ path: PATHS.DOCUMENTS_PREVIEW, component: <DocumentsPreview /> }],
  },
  {
    path: PATHS.DOCUMENTS_PREVIEW_PASSWORD,
    render: ({ ...props }: any) => <ManagementMobile {...props} />,
    routes: [
      {
        path: PATHS.DOCUMENTS_PREVIEW_PASSWORD,
        component: <DocumentPreviewPasswordPage />,
      },
    ],
  },
  {
    path: PATHS.DOCUMENTS_PREVIEW_DOCS,
    render: ({ ...props }: any) => <ManagementMobile {...props} />,
    routes: [{ path: PATHS.DOCUMENTS_PREVIEW_DOCS, component: <DocsPage /> }],
  },
  {
    path: PATHS.DOCUMENTS_PREVIEW_CERTIFICATES,
    render: ({ ...props }: any) => <ManagementMobile {...props} />,
    routes: [
      {
        path: PATHS.DOCUMENTS_PREVIEW_CERTIFICATES,
        component: <CertificatesPage />,
      },
    ],
  },
  {
    path: PATHS.REDIRECT,
    component: <UserSwitcher />,
  },
];
