import React from 'react';
import styled from 'styled-components';
import { media } from '../../../../../styles/media';
import { Text, TextType } from '@bit/first-scope.text';
import { InlineActionType, UniversalTable, withTableContextProvider } from '@cthings.co/universal-table';
import { API_URL } from '../../../../../consts';
import { View } from '../../../../../routes/routeInterfaces';
import { useMediaType } from '../../../../../styles/style.context';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../../app/state/userSlice';
import { HttpService } from '@cthings.co/utils';
import { useQueryParams } from '../../../../../utils/useQueryParams';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 46px;

  ${media.desktop`
    max-width: 1024px;
    margin-top: 30px;
  `}

  div.Head__Wrapper-rzpbra-0 {
    ${media.tablet`
    display: flex!important;
  `}
  }
`;

const WrapperText = styled(Text)`
  margin-bottom: 10px;
`;

const StageDistributionPlain = ({
  id,
  pathParams,
  languageStrings,
}: {
  id: string;
  pathParams: any;
  languageStrings: any;
}) => {
  const theme = useTheme();
  const language = useSelector(selectLanguage);
  const queryParams = useQueryParams() || {};

  const offsetKey = 'offset';
  const offset = pathParams[offsetKey];
  const keyword = 'StageDistributionTable';
  const { narrowMonitor, tablet } = useMediaType();

  return (
    <Wrapper>
      <WrapperText type={TextType.SEMITITLE}>{languageStrings.stageDistributionLabel}</WrapperText>
      <UniversalTable
        mobileHeadless
        placeholderProps={{
          keyword,
          placeholderOptions: { countRows: 2 },
          conditionalStyle: { height: '100%', overflow: 'hidden', borderRadius: '8px' },
        }}
        updateItems={() => {}}
        theme={theme}
        language={language.shortName}
        HttpService={HttpService}
        keyword={keyword}
        offsetKey={offsetKey}
        offset={+offset}
        pathParams={queryParams}
        tablePath={View.MANAGE_ORDERS_DETAILS}
        mainApiUrl={`${API_URL}/v1/order/details/${id}/components/stages`}
        inlineActionSet={[]}
        apiTriggers={[language.shortName]}
        columnStructureTriggers={[narrowMonitor, tablet]}
        isPaginationEnabled={false}
        actionSectionGridWidth={''}
        presetMapping={{
          textValue: {
            SIMPLE: (value: any) => value || ' ',
            NAME: (value: any) => value?.name || ' ',
            ITEMS: (value: any) => value?.total_items || ' ',
          },
        }}
        columnStructure={[
          {
            fieldName: 'index',
            placeholder: '',
            label: languageStrings.indexPlaceholder,
            defaultValue: '',
            width: '2fr',
            excludedFromView: tablet,
            getTextDisplay: { preset: 'SIMPLE' },
          },
          {
            fieldName: 'description_1',
            placeholder: '',
            label: `${languageStrings.description} 1`,
            defaultValue: '',
            width: '2fr',
            excludedFromView: narrowMonitor,
            getTextDisplay: { preset: 'SIMPLE' },
          },
          {
            fieldName: 'process',
            placeholder: '',
            label: languageStrings.labelProcess,
            defaultValue: '',
            width: '2fr',
            excludedFromView: narrowMonitor,
            getTextDisplay: { preset: 'NAME' },
          },
          {
            fieldName: 'total_packed',
            placeholder: '',
            label: languageStrings.totalLabel,
            defaultValue: '',
            width: '1fr',
            excludedFromView: narrowMonitor,
            getTextDisplay: { preset: 'SIMPLE' },
          },
          {
            fieldName: 'stage',
            placeholder: '',
            label: languageStrings.labelStage,
            defaultValue: '',
            width: '2fr',
            getTextDisplay: { preset: 'NAME' },
          },
          {
            fieldName: 'stage',
            placeholder: '',
            label: languageStrings.countLabel,
            defaultValue: '',
            width: '2fr',
            getTextDisplay: { preset: 'ITEMS' },
          },
        ]}
      />
    </Wrapper>
  );
};
export const StageDistribution = withTableContextProvider(StageDistributionPlain);
