import React, { useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useWindowSize } from '../../../../styles/style.context';
import { SelectListPopup } from './components/selectListPopup/SelectListPopup';
import { Loader } from '../../../commonButton/components/Loader';
import { InputWrapper } from '../../../../containers/common/documentationNFC/components/dropdownMultiChoice/components/inputWrapper/InputWrapper';
import { ReactComponent as ArrowIcon } from '../../../../assets/arrow_right.svg';

const FieldWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: ${(props: any) => (!props.isEditInProcess && !props.title ? 'none' : '1px solid')};
  border-color: ${(props: any) =>
    props.isOpenSelectList
      ? props.theme.colors.primary
      : props.isError
      ? props.theme.colors.red3
      : props.isDisabled
      ? props.theme.colors.gray5
      : props.theme.colors.gray4};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  background-color: ${(props: any) =>
    props.isDisabled
      ? props.theme.colors.white1
      : props.isOpenSelectList
      ? props.theme.colors.white1
      : props.theme.colors.white};
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: ${(props: any) => (!props.isEditInProcess && !props.title ? 'auto' : 'pointer')};
  pointer-events: ${(props: any) => ((!props.isEditInProcess && !props.title) || props.isDisabled ? 'none' : 'auto')};
`;

const Field = styled.div`
  width: 100%;
  height: ${(props: any) => (props.fieldHeight < 116 ? 'max-content' : '100%')};
  max-height: 112px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  overflow-y: ${(props: any) => (props.fieldHeight < 116 ? 'hidden' : 'scroll')};
  justify-content: space-between;
  align-items: center;
  &::-webkit-scrollbar {
    width: 4px;
    background: gray4;
    opacity: 1;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray2;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
    position: relative;
    top: 4px;
    right: 4px;
  }
`;

const FieldText = styled(Text)`
  transition: none;
`;

const StyledLoader = styled(Loader)`
  right: 12px;
  top: 50%;
  position: absolute;
`;

const StyledIcon = styled(ArrowIcon)`
  transform: ${(props: any) => (props.isOpen ? 'rotate(90deg)' : 'rotate(270deg)')};
`;

const NumerIconField = styled.div`
  display: flex;
  align-items: center;
`;

export interface DropdownMultiChoiceOptions {
  placeholder: string;
  disabledPlaceholder?: string;
  title: string;
  isValidatingStarted?: boolean;
  isError?: boolean;
  isOpenSelectList?: boolean;
  isSelectAll?: boolean;
  selectedIndexOfPeriod: number;
  popUpScrollTo: number;
  popUpScrollToWithCustomPeriod: number;
  wrapperRef: any;
  isDisabled?: boolean;
  paramsIsUploading?: boolean;
}
export interface DropdownMultiChoiceFunctions {
  handleCheckedItem: (id: string) => void;
  onButtonClick: () => void;
  setIsOpenSelectList: (value?: boolean) => void;
  setIsScrollableModal: any;
  handleTransformName?: (name: any) => string;
}

export const DropdownMultiChoice = ({
  parameters,
  selectedParameters,
  options,
  functions,
  isEditInProcess,
  languageStrings,
  ...props
}: {
  parameters: any[];
  selectedParameters: any[];
  options: DropdownMultiChoiceOptions;
  functions: DropdownMultiChoiceFunctions;
  isEditInProcess?: boolean;
  languageStrings?: any;
  className?: string;
}) => {
  const theme = useTheme();
  const { gray1, red3, primary } = theme.colors;

  const {
    disabledPlaceholder,
    title,
    isValidatingStarted,
    isError,
    isOpenSelectList,
    isSelectAll,
    selectedIndexOfPeriod,
    popUpScrollTo,
    popUpScrollToWithCustomPeriod,
    wrapperRef,
    isDisabled,
    paramsIsUploading,
  } = options;
  const {
    handleCheckedItem,
    onButtonClick,
    setIsOpenSelectList,
    setIsScrollableModal,
    handleTransformName,
  } = functions;

  const [fieldHeight, setFieldHeight] = useState(40);

  const popupRef = useRef<any>(null);
  const screenWidth = useWindowSize()[0];
  const isShowMessage =
    screenWidth > 550 ? isValidatingStarted && isError : !isOpenSelectList && isValidatingStarted && isError;

  const isAllValuesSelected = parameters.every((item: any) => {
    return item.isSelected;
  });
  const selectedValues = parameters.filter((item: any) => {
    return item.isSelected;
  });

  const openSelectList = () => {
    setIsOpenSelectList(true);
    setIsScrollableModal(true);
  };

  const closeSelectList = () => {
    setIsOpenSelectList(false);
  };

  const outsideHandler = useCallback(
    (e: any) => {
      if (e) {
        closeSelectList();
      }
    },
    [isOpenSelectList],
  );

  const fieldWrapperOnClick = (e: any) => {
    e.stopPropagation();
    !isDisabled && (isOpenSelectList ? closeSelectList() : openSelectList());
  };

  useEffect(() => {
    window.addEventListener('click', outsideHandler);
    return () => {
      window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  useEffect(() => {
    setFieldHeight(fieldHeight);
  }, [fieldHeight]);

  useEffect(() => {
    if (isDisabled) {
      setIsOpenSelectList(false);
    }
  }, [isDisabled]);

  return (
    <InputWrapper isEditInProcess={isEditInProcess} {...props}>
      <FieldWrapper
        ref={(el: any) => {
          if (el) {
            setFieldHeight((val) => (val = el.getBoundingClientRect().height));
          }
        }}
        theme={theme}
        isOpenSelectList={isOpenSelectList}
        isError={isError && isValidatingStarted}
        onClick={fieldWrapperOnClick}
        title={title}
        isFieldScrollable={fieldHeight > 115}
        isEditInProcess={isEditInProcess}
        isDisabled={isDisabled}
      >
        <Field fieldHeight={fieldHeight}>
          <FieldText type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
            {!isEditInProcess && !title ? 'N/A' : isDisabled ? disabledPlaceholder : title}
          </FieldText>
          <NumerIconField>
            <Text type={TextType.TEXT_14_GRAY} color={gray1} margin={'0 6px 0 0'}>
              {isAllValuesSelected ? languageStrings.labelAll : selectedValues.length}
            </Text>
            <StyledIcon isOpen={isOpenSelectList} />
          </NumerIconField>
        </Field>
        {isOpenSelectList && !isDisabled && (
          <SelectListPopup
            popupRef={popupRef}
            parameterList={parameters}
            handleCheckedItem={handleCheckedItem}
            onButtonClick={onButtonClick}
            fieldHeight={fieldHeight}
            languageStrings={languageStrings}
            isSelectAll={isSelectAll}
            isOpenSelectList={isOpenSelectList}
            wrapperRef={wrapperRef}
            selectedIndexOfPeriod={selectedIndexOfPeriod}
            scrollTo={popUpScrollTo}
            scrollToWithCustomPeriod={popUpScrollToWithCustomPeriod}
            handleTransformName={handleTransformName}
            title={title}
          />
        )}
        {paramsIsUploading && <StyledLoader color={primary} />}
      </FieldWrapper>
      {isShowMessage && (
        <Text type={TextType.TEXT_12_GRAY} color={red3} margin={'4px 0 0 12px'}>
          {title
            ? `${languageStrings.generateReportModalShowMessage || 'You should choose'} ${title.toLocaleLowerCase()}`
            : ``}
        </Text>
      )}
    </InputWrapper>
  );
};
