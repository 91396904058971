/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for connection status require
 * Highly customisable.
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TableCustomComponentState } from '../../features/universalTable/types';
import { Select } from '../select/Select';
import { media } from '../../styles/media';
import { Text, TextType } from '@bit/first-scope.text';
import { useCustomHistory } from '../../utils/react-router-dom-abstraction';
import { View } from '../../routes/routeInterfaces';
import { useGetDeviceUnattached } from '../../api/manageInventories';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div``;

const StyledSelect = styled(Select)`
  width: 230px;
  & > div {
    min-height: 40px;
  }
  & > div:nth-child(2) {
    top: 44px;
  }
  ${media.tablet`
    width: 100%;
  `}
`;

const StyledText = styled(Text)`
  &.link {
    text-decoration: underline;
    cursor: pointer;
  }
` as any; // TODO fix type

export const Tracker = ({
  setValue,
  state,
  value,
  values,
  label,
  defaultSelectValue,
  disabled,
  language,
}: {
  setValue: any;
  state: TableCustomComponentState | undefined;
  value: any;
  values?: any;
  defaultSelectValue: string;
  label?: string;
  disabled?: boolean;
  language?: any;
}) => {
  const theme = useTheme();
  const { gray1, gray2 } = theme.colors;
  const [savedValue, setSavedValue] = useState(value);
  const customHistory = useCustomHistory();
  const unattachedDevice = useGetDeviceUnattached();

  const checkIsValueInUnattached = (value: any) => {
    let isValueInList = false;
    unattachedDevice.forEach((device: any) => {
      if (device?.id === value?.id) {
        isValueInList = true;
      }
    });
    return isValueInList;
  };

  const isNotIncludedInUnattached = !checkIsValueInUnattached(value);

  useEffect(() => {
    !!value && isNotIncludedInUnattached && setSavedValue(value);
  }, [!!value]);

  return (
    <>
      {state === TableCustomComponentState.VIEW_MODAL || state === TableCustomComponentState.EDIT ? (
        <Wrapper>
          <StyledSelect
            value={value}
            label={label}
            values={[...unattachedDevice, savedValue]}
            onChange={setValue}
            defaultSelectValue={defaultSelectValue}
            disabled={disabled}
          />
        </Wrapper>
      ) : (
        <>
          {value ? (
            <StyledText
              type={TextType.TEXT_14_GRAY}
              color={gray1}
              weight={'400'}
              className={'link'}
              onClick={() => {
                const buildingId = value?.location?.wpas_ref_building_id;
                return value.id
                  ? customHistory.push(View.USER_MANAGE_DEVICES_DETAILS_TRACKER, {
                      pathParams: { id: value.id, buildingId: buildingId, offset: 0 },
                    })
                  : null;
              }}
            >
              {value?.name}
            </StyledText>
          ) : (
            <StyledText type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'}>
              {language ? language.notAttachedPlaceholder : 'Not attached'}
            </StyledText>
          )}
        </>
      )}
    </>
  );
};
