import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as UploadIcon } from '../../assets/uploadIcon.svg';
import { Text, TextType } from '@bit/first-scope.text';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { media } from '../../styles/media';
import { colorFetch } from '../../styles/utils';

type ButtonWrapperProps = {
  color?: string;
  colorHover?: string;
};

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 6px;
  border-color: ${({ color, theme }) => color || colorFetch('primary')({ theme })};
  box-sizing: border-box;
  transition: 0.3s ease;
  padding: 7px 25px;
  cursor: pointer;
  & > svg > path {
    transition: stroke 0.3s ease;
    stroke: ${({ color, theme }) => color || colorFetch('primary')({ theme })};
  }
  &:hover > svg > path {
    stroke: ${({ colorHover, theme }) => colorHover || colorFetch('secondary2')({ theme })};
  }
  &:hover {
    border-color: ${({ colorHover, theme }) => colorHover || colorFetch('secondary2')({ theme })};
    & > span {
      color: ${({ colorHover, theme }) => colorHover || colorFetch('secondary2')({ theme })};
    }
  }
  ${media.phone`
    max-width: 32px;
    height: 32px;
    padding: 5px;
  `}
`;

type ExportButtonTextProps = {
  color?: string;
};

const ExportButtonText = styled(Text)<ExportButtonTextProps>`
  color: ${({ color, theme }) => color || colorFetch('primary')({ theme })};
  font-size: 13px;
  margin-left: 3px;
  ${media.phone`
    display: none;
  `}
`;

export interface ExportButtonProps {
  color?: string;
  colorHover?: string;
  onClick: () => void;
}

export const ExportButton: FC<ExportButtonProps> = ({ color, colorHover, onClick, ...props }) => {
  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <ButtonWrapper onClick={onClick} color={color} colorHover={colorHover} {...props}>
      <UploadIcon />
      <ExportButtonText color={color} type={TextType.CHIPS_WHITE}>
        {languageStrings ? languageStrings.exportButton : 'Export'}
      </ExportButtonText>
    </ButtonWrapper>
  );
};
