/**
 * @description
 * This is component for one cluster.
 *
 */

import React, { FC, useEffect } from 'react';
import { View } from '../../../../routes/routeInterfaces';
import { LocationPoint } from '../locationPoint/LocationPoint';

export interface ClusterEntityProps {
  scale: number;
  pointData: any;
  dimensionRatio: number;
  containerDimensions: { height: number; width: any };
  imageDimensions: { height: number; width: any };
  withPulsation?: boolean;
  setPopupInfo: (val: any) => void;
  history: any;
  id: string;
  zoomIn: any;
}

export const ClusterEntity: FC<ClusterEntityProps> = ({
  scale,
  pointData,
  dimensionRatio,
  containerDimensions,
  imageDimensions,
  withPulsation,
  setPopupInfo,
  history,
  zoomIn,
  id,
}) => {
  const ratioCoordX = pointData.pixel_position.x / imageDimensions.width;
  const ratioCoordY = pointData.pixel_position.y / imageDimensions.height;
  const imageHeight = (containerDimensions.width * imageDimensions.height) / imageDimensions.width;
  const imageWidth = (containerDimensions.height * imageDimensions.width) / imageDimensions.height;
  const left =
    dimensionRatio < 1
      ? ratioCoordX * imageWidth + (containerDimensions.width - imageWidth) / 2
      : ratioCoordX * containerDimensions.width;
  const top =
    dimensionRatio < 1
      ? ratioCoordY * containerDimensions.height
      : ratioCoordY * imageHeight + (containerDimensions.height - imageHeight) / 2;

  const handleClickPoint = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    history.replace(View.USER_INSIGHTS_DETAILS, {
      pathParams: { id, name: pointData.name },
    });
    setPopupInfo((el: any) => {
      return {
        ...el,
        isOpen: true,
      };
    });
  };

  //it needs to load data after page refresh
  useEffect(() => {
    withPulsation &&
      setPopupInfo((el: any) => {
        return {
          ...el,
          top,
          left,
        };
      });
  }, [withPulsation]);

  return (
    <LocationPoint
      isCluster={pointData.isCluster}
      scale={1 / scale}
      top={top}
      left={left}
      onClick={handleClickPoint}
      options={pointData?.options}
      count={pointData?.ids?.length}
      withPulsation={withPulsation}
      zoomIn={zoomIn}
    />
  );
};
