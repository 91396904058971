import React, { createContext, useContext, ReactNode, useReducer } from 'react';
import { NavigationState, NavigationContextProviderType, NavigationAction, NavigationActionType } from './types';

const DEFAULT_NAVIGATION = {
  secondaryMenuItems: [],
} as NavigationState;

const NavigationContext = createContext([
  DEFAULT_NAVIGATION,
  null as any,
  null as any,
] as NavigationContextProviderType);

const reducer = (state: NavigationState, action: NavigationAction) => {
  switch (action.type) {
    case NavigationActionType.APPEND_SECONDARY_ITEMS:
      const list = action.payload?.list || [];
      return {
        secondaryMenuItems: [...state.secondaryMenuItems, ...list],
      };
    case NavigationActionType.CLEAR_SECONDARY_ITEMS:
      return {
        secondaryMenuItems: [],
      };

    default:
      throw new Error();
  }
};

const useNavigationContextCreator = (): NavigationContextProviderType => {
  const [navigation, dispatch] = useReducer(reducer, DEFAULT_NAVIGATION);
  const appendSecondaryItems = (list: any[]) => {
    dispatch({ type: NavigationActionType.APPEND_SECONDARY_ITEMS, payload: { list } });
  };
  const clearSecondaryItems = () => {
    dispatch({ type: NavigationActionType.CLEAR_SECONDARY_ITEMS });
  };
  const functions = { appendSecondaryItems, clearSecondaryItems };
  return [navigation, dispatch, functions];
};

export const NavigationContextProvider = ({ children }: { children: ReactNode }) => {
  const provider = useNavigationContextCreator();
  return <NavigationContext.Provider value={provider}>{children}</NavigationContext.Provider>;
};

export const useNavigationContext = (): NavigationContextProviderType => {
  const service = useContext(NavigationContext);

  if (!service) {
    throw new Error('Navigation Context is unavailable');
  }

  return service;
};

export const withNavigationContext = (Component: any) => {
  return function WithNavigationContext(props: any) {
    const service = useNavigationContext();

    return <Component {...props} navigationContext={service} />;
  };
};
