import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { media } from '../../../../../styles/media';
import { CommonButton, ButtonIconType } from '@bit/first-scope.common-button';
import { ReactComponent as SlidersIcon } from '../../../../../assets/sliders.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { Text, TextType } from '@bit/first-scope.text';
import { useStyleContext } from '../../../../../styles/style.context';
import { useHistory } from '../../../../../utils/react-router-dom-abstraction';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${media.tablet`
    width: 100%;
  `}
  ${media.phone`
    justify-content: space-between;
  `}
`;

const BackButton = styled(CommonButton)``;

const TitleWrapper = styled.h1`
  margin: 0 0 0 15px;
  padding: 0;
  & > span:not(:first-child) {
    margin-left: 7px;
  }
  ${media.tabletS`
    display: flex;
        & > span {
        font-size: 20px;
  }
  `}
  ${media.phone`
    font-size: 20px;
    margin: 0;
  `}
  ${media.phoneS`
    line-height: 18px;
    & > span {
      font-size: 18px;
      line-height: 18px;
  }
  `}
  ${media.phoneXS`
    line-height: 16px;
    & > span {
      font-size: 16px;
      line-height: 16px;
  }
  `}
`;

const SliderButton = styled(CommonButton)`
  display: none;
  ${media.phone`
    display: flex;
  `}
`;

export const Title = () => {
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);
  const [style] = useStyleContext();
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Wrapper>
      <BackButton
        iconType={ButtonIconType.ARROW_LEFT}
        onClick={handleBack}
        height="32px"
        width="32px"
        backgroundColor={theme.colors.primary}
        borderColor={theme.colors.primary}
        iconWithoutText
      />
      <TitleWrapper>
        <Text type={!style.mediaType.phone ? TextType.TITLE_PRIMARY : TextType.TITLE_H6BOLD}>
          {languageStrings ? languageStrings.titleOfAssetsPage : 'Assets'}
        </Text>
        <Text type={!style.mediaType.phone ? TextType.TITLE_SECONDARY : TextType.TITLE_H6MOBILE}>
          {(languageStrings ? languageStrings.labelIn : 'in ') + 'Live view'}
        </Text>
      </TitleWrapper>
      <SliderButton
        onClick={() => {}}
        height="32px"
        width="32px"
        backgroundColor={theme.colors.white}
        borderColor={theme.colors.primary}
        hoverColor={theme.colors.white}
      >
        <SlidersIcon />
      </SliderButton>
    </Wrapper>
  );
};
