const getIsNodes = (state: any) => state.nodes.length > 0;

const getIsAllDeviceSelect = (state: any) => {
  const deviceSelectState = state;
  let isAllDeviceSelect = true;
  for (const typeDevice in deviceSelectState) {
    if (!deviceSelectState[typeDevice].isSelected) {
      isAllDeviceSelect = false;
    }
  }
  return isAllDeviceSelect;
};

const getIsAllEntitySelect = (state: any) => {
  const entitySelectState = state;
  let isAllEntitySelect = true;
  for (const entityDevice in entitySelectState) {
    if (!entitySelectState[entityDevice].isSelected) {
      isAllEntitySelect = false;
    }
  }
  return isAllEntitySelect;
};

const getIsAllAttachSelect = (state: any) => {
  const attachSelectState = state;
  let isAllAttachSelect = true;
  for (const typeAttach in attachSelectState) {
    if (!attachSelectState[typeAttach].isSelected) {
      isAllAttachSelect = false;
    }
  }
  return isAllAttachSelect;
};

export { getIsNodes, getIsAllDeviceSelect, getIsAllAttachSelect, getIsAllEntitySelect };
