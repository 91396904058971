import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const initialState = {
  data: {
    no_of_nodes: 0,
    no_of_trackers: 0,
    total_devices_online: 0,
    total_devices_offline: 0,
    dynamic_tiles: [],
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const { setDashboardData } = dashboardSlice.actions;

export const selectDashboardData = (state: RootState) => {
  return state.dashboard.data;
};

export default dashboardSlice.reducer;
