/**
 * @description
 * This is component that contain list of filters for Inventories
 */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as ArrowIcon } from '../../assets/arrow_right.svg';
import { Modal } from './components/modal/Modal';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';
import { useTheme } from '@cthings.co/styles';

type WrapperProps = {
  maxWidth?: string;
};

const Wrapper = styled.div<WrapperProps>`
  max-width: ${({ maxWidth }) => maxWidth || '160px'};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px;
  margin-bottom: 10px;
  position: relative;
`;

type FieldWrapperProps = {
  minHeight?: string;
  isOpenModal: boolean;
  checkedCount: number;
  isCheckedAll: boolean;
  isPopupDescription?: boolean;
};

const FieldWrapper = styled.div<FieldWrapperProps>`
  width: 100%;
  height: max-content;
  min-height: ${({ minHeight }) => minHeight || '31px'};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 7px;
  border: 1px solid;
  border-color: ${({ isOpenModal, theme }) =>
    isOpenModal ? colorFetch('primary')({ theme }) : colorFetch('gray3')({ theme })};
  border-radius: ${borderRadiusFetch('primary')};

  background-color: ${({ isOpenModal, checkedCount, isCheckedAll, theme }) =>
    isOpenModal || checkedCount > 0 || isCheckedAll ? colorFetch('pureWhite')({ theme }) : 'transparent'};
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: ${({ isPopupDescription }) => (isPopupDescription ? 'auto' : 'pointer')};
  &.disabled {
    cursor: auto;
    opacity: 0.4;
  }
`;

const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectExtraWrapper = styled.div`
  display: flex;
  align-items: center;
  & > span {
    margin-right: 10px;
  }
`;

type CheckedCountProps = {
  fontSizeSelectNameCount?: string;
};

const CheckedCount = styled(Text)<CheckedCountProps>`
  font-size: ${({ fontSizeSelectNameCount }) => fontSizeSelectNameCount || '12px'};
`;

type ArrowRightProps = {
  isOpenModal: boolean;
};

const ArrowRight = styled(ArrowIcon)<ArrowRightProps>`
  transform: ${({ isOpenModal }) => isOpenModal && 'rotate(-180deg)'};
  transition: all 0.3s ease-in-out;
`;

type SelectNameProps = {
  fontSizeSelectNameCount?: string;
};

const SelectName = styled(Text)<SelectNameProps>`
  font-size: ${({ fontSizeSelectNameCount }) => fontSizeSelectNameCount || '12px'};
`;

export const SelectWithModal = ({
  name,
  maxWidth,
  minHeight,
  isDisabled,
  isPopupDescription,
  checkedCount = 0,
  fontSizeCount,
  parameterList,
  handleCheckedItem,
  handleSelectAll,
  isSearchActive = false,
  isDynamicData = false,
  isCheckedAll,
  handleClearAll,
  closeModal,
  handleDone,
  isSelectAllFirstModal,
}: {
  name?: string;
  maxWidth?: string;
  isDisabled?: boolean;
  isPopupDescription?: boolean;
  checkedCount?: number;
  minHeight?: string;
  fontSizeCount?: string;
  parameterList: any;
  handleCheckedItem: any;
  handleSelectAll: any;
  isSearchActive?: boolean;
  isDynamicData?: boolean;
  isCheckedAll: boolean;
  handleClearAll: any;
  closeModal: any;
  handleDone: any;
  isSelectAllFirstModal?: boolean;
}) => {
  const theme = useTheme();
  const { black1, gray1, gray2 } = theme.colors;

  const [fieldHeight, setFieldHeight] = useState(40);
  const language = useSelector(selectLanguageStrings);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = (e: any) => {
    e.stopPropagation();
    setIsOpenModal(false);
    closeModal();
  };

  const handleDoneModal = (e: any) => {
    e.stopPropagation();
    handleDone();
    setIsOpenModal(false);
  };

  return (
    <Wrapper maxWidth={maxWidth}>
      <FieldWrapper
        className={isDisabled && !isPopupDescription ? 'disabled' : ''}
        ref={(el: any) => {
          if (!el) return;
          setFieldHeight(el.getBoundingClientRect().height);
        }}
        theme={theme}
        isOpenModal={isOpenModal}
        checkedCount={checkedCount}
        isCheckedAll={isCheckedAll}
        onClick={isDisabled ? () => {} : openModal}
        isPopupDescription={isPopupDescription}
        minHeight={minHeight}
      >
        <SelectWrapper>
          <SelectName
            type={TextType.TEXT_12_BLACK}
            color={isOpenModal || (!isCheckedAll && checkedCount && checkedCount > 0) ? gray1 : gray2}
            fontSizeSelectNameCount={fontSizeCount}
          >
            {name}
          </SelectName>
          <SelectExtraWrapper>
            <CheckedCount
              type={TextType.TEXT_12_BLACK}
              color={isOpenModal || (!isCheckedAll && checkedCount && checkedCount > 0) ? black1 : gray1}
              fontSizeSelectNameCount={fontSizeCount}
            >
              {isSelectAllFirstModal ? (language ? language.labelAll : 'All') : checkedCount === 0 ? '0' : checkedCount}
            </CheckedCount>
            <ArrowRight isOpenModal={isOpenModal} />
          </SelectExtraWrapper>
        </SelectWrapper>
        {isOpenModal && (
          <Modal
            parameterList={parameterList}
            isOpenModal={isOpenModal}
            closeModal={handleCloseModal}
            name={name}
            handleCheckedItem={handleCheckedItem}
            handleSelectAll={handleSelectAll}
            isSearchActive={isSearchActive}
            isDynamicData={isDynamicData}
            isCheckedAll={isCheckedAll}
            handleClearAll={handleClearAll}
            handleDone={handleDoneModal}
            languageStrings={language}
          />
        )}
      </FieldWrapper>
    </Wrapper>
  );
};
