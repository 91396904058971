/**
 * @description
 * This is an advanced component that covers
 * all the features that cthings delete modals require.
 * Highly customisable.
 *
 */

import React from 'react';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@bit/first-scope.common-button';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { media } from '../../../../../../../../styles/media';
import { selectLanguageStrings } from '../../../../../../../../app/state/userSlice';
import { colorFetch } from '../../../../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > .title {
    font-weight: 700;
    margin-bottom: 10px;
  }
`;

const Title = styled(Text)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

const SemiTitle = styled.p`
  color: ${colorFetch('gray1')};
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${media.phone`
    flex-direction: column-reverse;
  `}

  & > .cancel {
    margin-right: 16px;
    ${media.phone`
      margin: 10px 0 0 0;
  `}
  }
`;

type ButtonProps = {
  isBlocked?: boolean;
};

// @TODO fix media types
const Button = styled(CommonButton)<ButtonProps>`
  width: 104px;
  ${media.tablet`
      width: 100%;  
      height: 40px; 
      pointer-events: ${({ isBlocked }: any) => isBlocked && 'none'};
  `};
  ${media.phone`
    width: 100%;
  `}
`;

export const InformationModal = ({
  title,
  semiTitle,
  isOpenModal,
  handleOnClose,
  ...props
}: {
  title: string;
  semiTitle: string;
  isOpenModal?: boolean;
  handleOnClose: (value: React.SetStateAction<boolean>) => void;
  className?: string;
}) => {
  const theme = useTheme();
  const { pureWhite, black1, gray1, primary } = theme.colors;

  const language = useSelector(selectLanguageStrings);

  return (
    <Wrapper {...props}>
      <Title className="title" color={black1} type={TextType.TEXT_14_BLACK}>
        {title}
      </Title>
      <SemiTitle className="semiTitle" color={gray1}>
        {semiTitle}
      </SemiTitle>
      <ButtonWrapper>
        <Button
          backgroundColor={pureWhite}
          backgroundColorHover={primary}
          borderColor={primary}
          borderColorHover={primary}
          onClick={handleOnClose}
          color={black1}
        >
          {language ? language.gotItButton : 'Got it'}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};
