import React from 'react';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { Stages } from '../../../../../components/processesAddModal/components/Stages';
import { StagesView } from '../stagesView/StagesView';

export const StagesComponent = ({
  value,
  state,
  setValue,
  dataStages,
  saveToggle,
}: {
  value: any;
  state?: TableCustomComponentState;
  setValue?: any;
  dataStages?: any;
  saveToggle?: boolean;
}) => {
  return (
    <>
      {state === TableCustomComponentState.VIEW ? (
        <StagesView value={value} />
      ) : state === TableCustomComponentState.VIEW_MODAL ? (
        <Stages value={value} isStatic stagesList={dataStages} />
      ) : state === TableCustomComponentState.EDIT ? (
        <Stages value={value} setValue={setValue} stagesList={dataStages} />
      ) : (
        <StagesView value={value} />
      )}
    </>
  );
};
