import React from 'react';

export const UploadIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.3125 15.4375V18.6875C20.3125 19.1185 20.1413 19.5318 19.8365 19.8365C19.5318 20.1413 19.1185 20.3125 18.6875 20.3125H7.3125C6.88152 20.3125 6.4682 20.1413 6.16345 19.8365C5.8587 19.5318 5.6875 19.1185 5.6875 18.6875V15.4375"
        stroke={color ? color : 'white'}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.0625 9.75L13 5.6875L8.9375 9.75"
        stroke={color ? color : 'white'}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 5.6875V15.4375"
        stroke={color ? color : 'white'}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
