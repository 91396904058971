import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../../../../../../components/newModals/Modal';
import { ModalContainer } from '../../../../../../../components/newModals/ModalContainer';
import { media } from '../../../../../../../styles/media';
import { DeletePopup } from '../../../../../../../components/deletePopup/DeletePopup';
import { InformationModal } from './InformationModal/InformationModal';
import { ZonesActionAttempts } from '../../../../../zonesDetails/types';

type PopupContainerProps = {
  maxWidth: string | null;
};

const PopupContainer = styled(ModalContainer)<PopupContainerProps>`
  max-width: ${({ maxWidth }) => maxWidth ?? '385px'};
  ${media.phone`
    max-width: auto !important;
  `}
`;

export const ModalView = ({
  languageStrings,
  attempts,
  clearAttempts,
  handleDeleteOnClick,
  handleCloseEdit,
  intersectingZonesCount,
}: {
  languageStrings: any;
  intersectingZonesCount: number;
  attempts: ZonesActionAttempts;
  clearAttempts: () => void;
  handleDeleteOnClick: () => void;
  handleCloseEdit: () => void;
}) => {
  const closeModal = () => {
    clearAttempts();
  };

  const handleConfirmCloseEditView = () => {
    handleCloseEdit();
    closeModal();
  };

  const handleDelete = () => {
    handleDeleteOnClick();
    closeModal();
  };

  const discardChangesMessage = languageStrings
    ? languageStrings.areYouSureMessage
    : 'Are you sure you want to discard all changes?';

  const overlappingZonesMessage = languageStrings
    ? languageStrings.overlappingZonesMessage
    : 'Changes cannot be saved due to overlapping zones, marked in red. Please correct the positions of those zones.';

  const unsavedChangesMessage = languageStrings ? languageStrings.unsavedChanges : 'Unsaved changes';
  const intersectingOverflowingZone = languageStrings
    ? languageStrings.intersectingOverflowingZone
    : 'Intersecting zones';

  const modalTitle = attempts.delete
    ? languageStrings
      ? languageStrings.confirmDelete
      : 'Are you sure?'
    : attempts.cancel
    ? unsavedChangesMessage
    : intersectingOverflowingZone;

  const modalSemiTitle = attempts.delete
    ? languageStrings
      ? languageStrings.deleteZone
      : 'The zone will be permanently deleted.'
    : attempts.cancel
    ? discardChangesMessage
    : overlappingZonesMessage;

  const zoneIntersection = attempts.save && intersectingZonesCount > 0;
  const modalCondition = attempts.delete || attempts.cancel || zoneIntersection;

  return (
    <Modal isOpenModal={modalCondition}>
      <PopupContainer padding="24px" maxWidth={intersectingZonesCount > 0 ? '480px' : null}>
        {0 ? (
          <InformationModal title={modalTitle} semiTitle={modalSemiTitle} handleOnClose={closeModal} />
        ) : (
          <DeletePopup
            title={modalTitle}
            semiTitle={modalSemiTitle}
            isOpenModal
            handleOnClose={closeModal}
            handleOnDelete={attempts.delete ? handleDelete : handleConfirmCloseEditView}
          />
        )}
      </PopupContainer>
    </Modal>
  );
};
