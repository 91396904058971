import React from 'react';
import styled from 'styled-components';
import { FactoryIcon } from '../factoryIcon/FactoryIcon';
import { CPUIcon } from '../cPUIcon/CPUIcon';
import { OnlineIcon } from '../onlineIcon/OnlineIcon';
import { OfflineIcon } from '../offlineIcon/OfflineIcon';
import { HouseIcon } from '../houseIcon/HouseIcon';
import { RefreshIcon } from '../refreshIcon/RefreshIcon';
import { ShippingIcon } from '../shippingIcon/ShippingIcon';
import { InventoryIcon } from '../inventoryIcon/InventoryIcon';
import { SiteIcon } from '../siteIcon/SiteIcon';
import { PackageIcon } from '../packageIcon/PackageIcon';
import { OrderIcon } from '../orderIcon/OrderIcon';
import { useTheme } from '@cthings.co/styles';

export enum IconType {
  WAREHOUSE = 'WAREHOUSE',
  LOADING = 'LOADING',
  DEVICE = 'DEVICE',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  SHIPPING = 'SHIPPING',
  PRODUCTION = 'PRODUCTION',
  INVENTORY = 'INVENTORY',
  SITE = 'SITE',
  PACKAGE = 'PACKAGE',
  ORDER = 'ORDER',
}

type WrapperProps = {
  type: IconType | string;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  padding-bottom: ${({ type }) => (type === IconType.DEVICE ? '4px' : '0px')};
  & > svg {
    width: 52px;
    height: 52px;
  }
`;

export const DashboardImage = ({ type, iconColor }: { type: IconType | string; iconColor: string }) => {
  const theme = useTheme();
  const { primary } = theme.colors;

  return (
    <Wrapper type={type}>
      {type === IconType.DEVICE && <CPUIcon color={iconColor ? iconColor : primary} />}
      {type === IconType.ONLINE && <OnlineIcon color={iconColor ? iconColor : primary} />}
      {type === IconType.OFFLINE && <OfflineIcon color={iconColor ? iconColor : primary} />}
      {type === IconType.PRODUCTION && <FactoryIcon color={iconColor ? iconColor : primary} />}
      {type === IconType.WAREHOUSE && <HouseIcon color={iconColor ? iconColor : primary} />}
      {type === IconType.LOADING && <RefreshIcon color={iconColor ? iconColor : primary} />}
      {type === IconType.SHIPPING && <ShippingIcon color={iconColor ? iconColor : primary} />}
      {type === IconType.INVENTORY && <InventoryIcon color={iconColor ? iconColor : primary} />}
      {type === IconType.SITE && <SiteIcon color={iconColor ? iconColor : primary} />}
      {type === IconType.PACKAGE && <PackageIcon color={iconColor ? iconColor : primary} />}
      {type === IconType.ORDER && <OrderIcon color={iconColor ? iconColor : primary} />}
    </Wrapper>
  );
};
