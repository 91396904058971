import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '../../../../../../../styles/media';
import { useTheme } from '@cthings.co/styles';

type WrapperProps = {
  isEditInProcess?: boolean;
};

// @TODO fix media types
const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: '0 22px 0 0';
  position: relative;
  &.last {
    margin: 0;
  }
  ${media.phone`
    margin: ${({ title, isEditInProcess }: any) => (title && isEditInProcess ? '0 0 10px 0' : '0')};
  `}
`;

export const InputWrapper = ({
  children,
  title,
  wrapperRef,
  isEditInProcess,
  ...props
}: {
  children?: ReactNode;
  title?: string;
  wrapperRef?: any;
  isEditInProcess?: boolean;
  className?: string;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  return (
    <Wrapper title={title} ref={wrapperRef} isEditInProcess={isEditInProcess} {...props}>
      {title && (
        <Text type={TextType.TEXT_12_BLACK} color={gray1} margin={'0 0 4px 8px'}>
          {title}
        </Text>
      )}
      {children}
    </Wrapper>
  );
};
