/**
 * @description
 * component for displaying point location
 *
 */

import React, { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { Area, Point, PointWrapper, NameOfZone, Zone } from '../../styled';
import { useDragEnd } from '../../../../utils/longTap';
import Tooltip, { useTooltip } from '../../../../components/tooltip/Tooltip';
import { DragDropLayer } from '../dragDropLayer/DragDropLayer';
import { hexToRGBA, useTheme } from '@cthings.co/styles';

// @NOTE brilliant solution from https://stackoverflow.com/a/58705306
function getTextWidth(text: string, textNode?: HTMLElement) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = textNode ? getComputedStyle(textNode).font : getComputedStyle(document.body).font;
  }

  return context ? context.measureText(text).width : 0;
}

export interface LocationPointProps {
  top: number;
  left: number;
  scale: number;
  onClick: any;
  handleDragEnd: any;
  innerOffset: any;
  handleDragStart: any;
  width: number;
  height: number;
  activeId: string | null;
  pointData: any;
  ratio: number;
  isDraggable?: boolean;
  withPoint?: boolean;
  scaleZoom: number;
}

export const LocationPoint: FC<LocationPointProps> = ({
  top,
  left,
  scale,
  onClick,
  handleDragEnd,
  handleDragStart,
  innerOffset,
  width,
  height,
  activeId,
  pointData,
  ratio,
  isDraggable,
  withPoint = false,
  scaleZoom,
}) => {
  const { id, name, isFaulty, active } = pointData;

  const activeOffset = id === activeId ? 6.5 : 5;

  const activeInnerOffset = id === activeId ? 5 : 4.5;

  const theme = useTheme();
  const { green1, green2, blue2, red1, red2, red4 } = theme.colors;

  const colors = isFaulty
    ? { dotColor: red2, borderColor: red1, backgroundColor: hexToRGBA(red4, 0.3) }
    : active
    ? { dotColor: green1, borderColor: hexToRGBA(green2, 0.5), backgroundColor: hexToRGBA(green2, 0.3) }
    : { dotColor: blue2, borderColor: hexToRGBA(blue2, 0.5), backgroundColor: hexToRGBA(blue2, 0.2) };

  const { dotColor, borderColor, backgroundColor } = colors;

  const [node, setNode] = useState<any>();
  const [nameNode, setNameNode] = useState<any>();

  const ref = useCallback((node) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  const zoneNameRef = useCallback((node) => {
    if (node !== null) {
      setNameNode(node);
    }
  }, []);

  const actualWidth = width * ratio * scaleZoom;
  const actualHeight = height * ratio * scaleZoom;

  const [[tooltipId]] = useTooltip();
  const [isTooltipDisabled, setIsTooltipDisabled] = useState(false);

  const handleDragStartFinal = () => {
    isDraggable && setIsTooltipDisabled(true);
    handleDragStart();
  };

  const handleDragEndFinal = (dragData: any, dragElem: any, x: number, y: number) => {
    setIsTooltipDisabled(false);
    handleDragEnd(dragData, dragElem, x, y, node);
  };

  const [onDragStart, onDragEnd] = useDragEnd(handleDragStartFinal, handleDragEndFinal, 300, true);

  const zoneTitleSize = useMemo(() => {
    return getTextWidth(name, nameNode);
  }, [name, nameNode]);

  const handleClick = (e: SyntheticEvent<HTMLDivElement>) => {
    onClick(e, left);
  };

  const handleTouchStart = (e: SyntheticEvent<HTMLDivElement>) => {
    onClick(e);
  };

  return (
    <PointWrapper
      top={`${top}px`}
      left={`${left}px`}
      transform={`scale(${scale}) translateX(-${activeOffset}px) translateY(-${activeOffset}px)`}
      ghostTransform={`translateX(${-left - innerOffset.x + 8}px) translateY(${-top - innerOffset.y + 8}px)`}
      onClick={handleClick}
      isActive={id === activeId}
      onTouchStart={handleTouchStart}
    >
      <DragDropLayer isDraggable={!!isDraggable} onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Tooltip width="unset" id={tooltipId} text={name} disabled={isTooltipDisabled}>
          {withPoint ? (
            <Zone ref={ref} isActive={id === activeId}>
              {actualWidth > zoneTitleSize && (
                <NameOfZone ref={zoneNameRef} color={dotColor}>
                  {name}
                </NameOfZone>
              )}
              {actualWidth <= zoneTitleSize && <Point backgroundColor={dotColor} isActive={id === activeId} />}

              <Area
                width={`${actualWidth}px`}
                height={`${actualHeight}px`}
                top={`${-actualHeight / 2 + activeInnerOffset}px`}
                left={`${-actualWidth / 2 + activeInnerOffset}px`}
                backgroundColor={backgroundColor}
                borderColor={borderColor}
                isActive={id === activeId}
              />
            </Zone>
          ) : (
            <Area
              width={`${actualWidth}px`}
              height={`${actualHeight}px`}
              top={`${-actualHeight / 2 + activeInnerOffset}px`}
              left={`${-actualWidth / 2 + activeInnerOffset}px`}
              backgroundColor={backgroundColor}
              borderColor={borderColor}
              isActive={id === activeId}
            />
          )}
        </Tooltip>
      </DragDropLayer>
    </PointWrapper>
  );
};
