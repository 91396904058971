import React from 'react';

export const MassageIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 5H16C18.2091 5 20 6.79086 20 9V13C20 15.2091 18.2091 17 16 17H12L8 20V17C5.79086 17 4 15.2091 4 13V9C4 6.79086 5.79086 5 8 5Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M12 8V11" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 14H12.0075" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
