import { css } from '@xstyled/styled-components';
import { sizes } from './types';
const comparatorArray = Object.keys(sizes).sort((first, second) => {
    if (sizes[first] < sizes[second]) {
        return -1;
    }
    else if (sizes[first] > sizes[second]) {
        return 1;
    }
    else {
        return 0;
    }
});
export const comparatorFunction = (first, second) => {
    let equals = false;
    for (let i = 0; i < comparatorArray.length; i++) {
        const currentIndex = comparatorArray[i];
        if (first[currentIndex] && second[currentIndex]) {
            equals = true;
            break;
        }
        else if (first[currentIndex] !== second[currentIndex]) {
            break;
        }
    }
    return equals;
};
export const media = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css `
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
    return acc;
}, {});
export const inlineMedia = (breakpoint) => (...styles) => {
    return css `
    @media (min-width: ${breakpoint / 16}em) {
      ${css(...styles)};
    }
  `;
};
export const getMediaType = () => {
    return Object.keys(sizes).reduce((acc, label) => {
        acc[label] = window.matchMedia(`(max-width: ${sizes[label]}px)`).matches;
        return acc;
    }, {});
};
