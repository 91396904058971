/**
 * @description
 * This is component is orders table
 * all the functionalities cthings require for orders table
 * good customisable.
 *
 */

import styled from 'styled-components';
import React, { FC } from 'react';
import { withLoader } from '../../../features/placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../../placeholders/typePlaceholders/placeholdersType';
import { TableHeader } from '../components/tableHeader/TableHeader';
import { TableRow } from '../components/tableRow/TableRow';
import { borderRadiusFetch, colorFetch } from '../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const TableWrapper = styled.div`
  width: 100%;
  border-radius: ${borderRadiusFetch('primary')};
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  & > div:nth-child(1) {
    height: 56px;
  }
`;

type StyledTableRowProps = {
  completion: number;
};

const StyledTableRow = styled(TableRow)<StyledTableRowProps>`
  & > span:nth-child(2) {
    color: ${({ completion, theme }) =>
      completion === 0
        ? colorFetch('secondary4')({ theme })
        : completion === 1
        ? colorFetch('primary')({ theme })
        : completion === 2
        ? colorFetch('red1')({ theme })
        : ''};
  }
`;

export interface HeaderLabelsProps {
  name: string;
  isSorted: boolean;
}

export interface ComponentProps {
  headerLabels: any;
  headerGridTemplate: any;
  arr: any;
  completionStatus: any;
  valueGridTemplate?: any;
  updated_at?: any;
  shipping_deadline?: any;
  gridTemplate?: any;
}

const ContentTablePlain: FC<ComponentProps> = ({
  headerLabels,
  headerGridTemplate,
  valueGridTemplate,
  arr,
  completionStatus,
  updated_at,
  shipping_deadline,
  gridTemplate,
}) => {
  const theme = useTheme();

  return (
    <TableWrapper theme={theme}>
      <TableHeader
        labels={headerLabels}
        gridTemplateDesktop={headerGridTemplate.desktop}
        gridTemplateTablet={headerGridTemplate.tablet}
        gridTemplatePhone={headerGridTemplate.phone}
      />
      {arr &&
        arr.map((item: any, index: number) => {
          const { value, property } = item;

          return index === arr.length - 1 ? (
            <StyledTableRow
              key={index}
              property={property}
              value={value}
              isLast
              completion={completionStatus}
              gridTemplate={gridTemplate}
              theme={theme}
              gridTemplateDesktop={valueGridTemplate?.desktop}
              gridTemplateTablet={valueGridTemplate?.tablet}
              gridTemplatePhone={valueGridTemplate?.phone}
            />
          ) : (
            <TableRow
              key={index}
              property={property}
              value={value}
              isFirst={index === 0}
              gridTemplate={gridTemplate}
              gridTemplateDesktop={valueGridTemplate?.desktop}
              gridTemplateTablet={valueGridTemplate?.tablet}
              gridTemplatePhone={valueGridTemplate?.phone}
            />
          );
        })}
    </TableWrapper>
  );
};

export const ContentTable = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(ContentTablePlain);
