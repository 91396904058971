import React from 'react';
import styled from 'styled-components';
import { media } from '../../../../../../../styles/media';
import { Select } from '../../../../../../../components/select/Select';

const StyledSelect = styled(Select)`
  width: 230px;
  & > span {
    margin: 0 0 2px 6px;
  }
  & > div {
    min-height: 40px;
  }
  ${media.tablet`
      width: 100%;   
  `};
`;

export const LevelFunctional = ({
  value,
  selectList,
  fieldName,
  placeholder,
  handleSelectChange,
  isDisabled,
}: {
  value: any;
  selectList?: any[];
  fieldName?: string;
  placeholder?: string;
  handleSelectChange?: (value: any, fieldName: any) => void;
  isDisabled?: boolean;
}) => {
  return (
    <StyledSelect
      value={value}
      disabled={isDisabled}
      // @TODO check implementation
      values={selectList}
      onChange={(value: any) => handleSelectChange && handleSelectChange(value, fieldName)}
      placeholder={placeholder}
      positionTop={'48px'}
    />
  );
};
