import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import Map from '../../../../../assets/Map.jpeg';

export const colors = ['#541690', '#FF4949', '#FF9300', '#D22779', '#52734D', '#3EC70B'];

const StyledAssetMap = styled.div`
  height: calc(100vh - 310px);
  overflow: hidden;
  position: relative;
  border: ${(props: any) => `1px solid ${props.theme.colors.gray3}`};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

export const PlaceholderFloor = () => {
  const theme = useTheme();

  return (
    <StyledAssetMap theme={theme}>
      <svg
        style={{ height: '100%', width: '100%', background: '#E4E4EB' }}
        viewBox="0 0 1042 557"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_5941_396867" fill="white">
          <path d="M0 0H1042V549C1042 553.418 1038.42 557 1034 557H8.00001C3.58173 557 0 553.418 0 549V0Z" />
        </mask>
        <path
          d="M0 0H1042V549C1042 553.418 1038.42 557 1034 557H8.00001C3.58173 557 0 553.418 0 549V0Z"
          fill="#E4E4EB"
        />
        <mask
          id="mask0_5941_396867"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="474"
          y="399"
          width="45"
          height="90"
        >
          <rect x="474.271" y="399.604" width="44.4604" height="89.2201" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_5941_396867)">
          <path d="M650.357 404.959L438.631 548.539" stroke="#F2F2F5" />
          <path d="M600.248 370.979L388.521 514.558" stroke="#F2F2F5" />
          <path d="M647.008 402.688L435.281 546.267" stroke="#F2F2F5" />
          <path d="M596.898 368.707L385.172 512.287" stroke="#F2F2F5" />
          <path d="M643.658 400.416L431.931 543.996" stroke="#F2F2F5" />
          <path d="M593.549 366.434L381.822 510.014" stroke="#F2F2F5" />
          <path d="M640.307 398.145L428.58 541.724" stroke="#F2F2F5" />
          <path d="M590.197 364.162L378.47 507.742" stroke="#F2F2F5" />
          <path d="M636.957 395.873L425.23 539.453" stroke="#F2F2F5" />
          <path d="M586.848 361.891L375.121 505.471" stroke="#F2F2F5" />
          <path d="M633.607 393.602L421.881 537.182" stroke="#F2F2F5" />
          <path d="M583.498 359.619L371.771 503.199" stroke="#F2F2F5" />
          <path d="M630.258 391.33L418.531 534.91" stroke="#F2F2F5" />
          <path d="M580.148 357.348L368.422 500.928" stroke="#F2F2F5" />
          <path d="M626.908 389.057L415.181 532.637" stroke="#F2F2F5" />
          <path d="M576.799 355.076L365.072 498.656" stroke="#F2F2F5" />
          <path d="M623.557 386.785L411.83 530.365" stroke="#F2F2F5" />
          <path d="M573.447 352.805L361.72 496.385" stroke="#F2F2F5" />
          <path d="M620.209 384.514L408.482 528.094" stroke="#F2F2F5" />
          <path d="M570.1 350.533L358.373 494.113" stroke="#F2F2F5" />
          <path d="M616.857 382.242L405.131 525.822" stroke="#F2F2F5" />
          <path d="M566.748 348.262L355.021 491.842" stroke="#F2F2F5" />
          <path d="M613.508 379.971L401.781 523.551" stroke="#F2F2F5" />
          <path d="M563.398 345.988L351.672 489.568" stroke="#F2F2F5" />
          <path d="M610.158 377.699L398.431 521.279" stroke="#F2F2F5" />
          <path d="M560.047 343.717L348.32 487.297" stroke="#F2F2F5" />
          <path d="M606.807 375.428L395.08 519.008" stroke="#F2F2F5" />
          <path d="M556.699 341.445L344.972 485.025" stroke="#F2F2F5" />
          <path d="M603.459 373.156L391.732 516.736" stroke="#F2F2F5" />
          <path d="M553.348 339.174L341.621 482.754" stroke="#F2F2F5" />
        </g>
        <path
          d="M474.725 488.824V399.604H518.731V488.824"
          stroke="#F2F2F5"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <mask
          id="mask1_5941_396867"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="324"
          y="67"
          width="46"
          height="20"
        >
          <path
            d="M324.559 85.4116V82.4121V68.0293C324.559 67.477 325.006 67.0293 325.559 67.0293H368.926C369.479 67.0293 369.926 67.477 369.926 68.0293V73.413C369.926 73.9653 369.479 74.413 368.926 74.413H355.955C355.403 74.413 354.955 74.8607 354.955 75.413V85.4116C354.955 85.9639 354.507 86.4116 353.955 86.4116H325.559C325.006 86.4116 324.559 85.9639 324.559 85.4116Z"
            fill="#8F90A6"
          />
        </mask>
        <g mask="url(#mask1_5941_396867)">
          <path d="M502.006 37.6191L290.279 181.199" stroke="#F2F2F5" />
          <path d="M451.896 3.63867L240.17 147.219" stroke="#F2F2F5" />
          <path d="M498.656 35.3477L286.929 178.928" stroke="#F2F2F5" />
          <path d="M448.545 1.36719L236.818 144.947" stroke="#F2F2F5" />
          <path d="M495.307 33.0762L283.58 176.656" stroke="#F2F2F5" />
          <path d="M445.195 -0.90625L233.468 142.674" stroke="#F2F2F5" />
          <path d="M491.955 30.8047L280.228 174.385" stroke="#F2F2F5" />
          <path d="M441.846 -3.17773L230.119 140.402" stroke="#F2F2F5" />
          <path d="M488.605 28.5332L276.879 172.113" stroke="#F2F2F5" />
          <path d="M438.496 -5.44922L226.769 138.131" stroke="#F2F2F5" />
          <path d="M485.256 26.2617L273.529 169.842" stroke="#F2F2F5" />
          <path d="M435.146 -7.7207L223.42 135.859" stroke="#F2F2F5" />
          <path d="M481.906 23.9902L270.179 167.57" stroke="#F2F2F5" />
          <path d="M431.797 -9.99219L220.07 133.588" stroke="#F2F2F5" />
          <path d="M478.557 21.7168L266.83 165.297" stroke="#F2F2F5" />
          <path d="M428.445 -12.2637L216.718 131.316" stroke="#F2F2F5" />
          <path d="M475.207 19.4453L263.48 163.025" stroke="#F2F2F5" />
          <path d="M425.098 -14.5352L213.371 129.045" stroke="#F2F2F5" />
          <path d="M471.855 17.1738L260.129 160.754" stroke="#F2F2F5" />
          <path d="M421.746 -16.8066L210.019 126.773" stroke="#F2F2F5" />
          <path d="M468.506 14.9023L256.779 158.482" stroke="#F2F2F5" />
          <path d="M418.396 -19.0781L206.67 124.502" stroke="#F2F2F5" />
          <path d="M465.156 12.6309L253.429 156.211" stroke="#F2F2F5" />
          <path d="M415.047 -21.3516L203.32 122.228" stroke="#F2F2F5" />
          <path d="M461.807 10.3594L250.08 153.939" stroke="#F2F2F5" />
          <path d="M411.697 -23.623L199.97 119.957" stroke="#F2F2F5" />
          <path d="M458.457 8.08789L246.73 151.668" stroke="#F2F2F5" />
          <path d="M408.348 -25.8945L196.621 117.685" stroke="#F2F2F5" />
          <path d="M455.107 5.81445L243.381 149.394" stroke="#F2F2F5" />
          <path d="M404.996 -28.166L193.269 115.414" stroke="#F2F2F5" />
        </g>
        <path
          d="M324.559 85.4116V82.4121V68.0293C324.559 67.477 325.006 67.0293 325.559 67.0293H368.926C369.479 67.0293 369.926 67.477 369.926 68.0293V73.413C369.926 73.9653 369.479 74.413 368.926 74.413H355.955C355.403 74.413 354.955 74.8607 354.955 75.413V85.4116C354.955 85.9639 354.507 86.4116 353.955 86.4116H325.559C325.006 86.4116 324.559 85.9639 324.559 85.4116Z"
          stroke="#F2F2F5"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <mask
          id="mask2_5941_396867"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="620"
          y="67"
          width="49"
          height="20"
        >
          <path
            d="M620.355 72.4901V68.0293C620.355 67.477 620.803 67.0293 621.355 67.0293H667.899C668.451 67.0293 668.899 67.477 668.899 68.0293V72.4901C668.899 73.0423 668.451 73.4901 667.899 73.4901H654.928C654.375 73.4901 653.928 73.9378 653.928 74.4901V85.4116C653.928 85.9639 653.48 86.4116 652.928 86.4116H635.873C635.321 86.4116 634.873 85.9639 634.873 85.4116V74.4901C634.873 73.9378 634.425 73.4901 633.873 73.4901H621.355C620.803 73.4901 620.355 73.0424 620.355 72.4901Z"
            fill="#8F90A6"
          />
        </mask>
        <g mask="url(#mask2_5941_396867)">
          <path d="M798.826 37.623L587.099 181.203" stroke="#F2F2F5" />
          <path d="M748.717 3.64062L536.99 147.221" stroke="#F2F2F5" />
          <path d="M795.477 35.3496L583.75 178.93" stroke="#F2F2F5" />
          <path d="M745.367 1.36914L533.64 144.949" stroke="#F2F2F5" />
          <path d="M792.129 33.0781L580.402 176.658" stroke="#F2F2F5" />
          <path d="M742.018 -0.904297L530.291 142.676" stroke="#F2F2F5" />
          <path d="M788.777 30.8066L577.05 174.387" stroke="#F2F2F5" />
          <path d="M738.668 -3.17578L526.941 140.404" stroke="#F2F2F5" />
          <path d="M785.428 28.5352L573.701 172.115" stroke="#F2F2F5" />
          <path d="M735.318 -5.44727L523.591 138.133" stroke="#F2F2F5" />
          <path d="M782.078 26.2617L570.351 169.842" stroke="#F2F2F5" />
          <path d="M731.969 -7.71875L520.242 135.861" stroke="#F2F2F5" />
          <path d="M778.729 23.9902L567.002 167.57" stroke="#F2F2F5" />
          <path d="M728.617 -9.99023L516.89 133.59" stroke="#F2F2F5" />
          <path d="M775.379 21.7188L563.652 165.299" stroke="#F2F2F5" />
          <path d="M725.268 -12.2617L513.541 131.318" stroke="#F2F2F5" />
          <path d="M772.027 19.4473L560.3 163.027" stroke="#F2F2F5" />
          <path d="M721.918 -14.5332L510.191 129.047" stroke="#F2F2F5" />
          <path d="M768.678 17.1758L556.951 160.756" stroke="#F2F2F5" />
          <path d="M718.568 -16.8066L506.841 126.773" stroke="#F2F2F5" />
          <path d="M765.328 14.9043L553.601 158.484" stroke="#F2F2F5" />
          <path d="M715.219 -19.0781L503.492 124.502" stroke="#F2F2F5" />
          <path d="M761.979 12.6328L550.252 156.213" stroke="#F2F2F5" />
          <path d="M711.867 -21.3496L500.14 122.23" stroke="#F2F2F5" />
          <path d="M758.627 10.3594L546.9 153.939" stroke="#F2F2F5" />
          <path d="M708.518 -23.6211L496.791 119.959" stroke="#F2F2F5" />
          <path d="M755.277 8.08789L543.55 151.668" stroke="#F2F2F5" />
          <path d="M705.168 -25.8926L493.441 117.687" stroke="#F2F2F5" />
          <path d="M751.928 5.81641L540.201 149.396" stroke="#F2F2F5" />
          <path d="M701.818 -28.1641L490.091 115.416" stroke="#F2F2F5" />
        </g>
        <path
          d="M620.355 72.4901V68.0293C620.355 67.477 620.803 67.0293 621.355 67.0293H667.899C668.451 67.0293 668.899 67.477 668.899 68.0293V72.4901C668.899 73.0423 668.451 73.4901 667.899 73.4901H654.928C654.375 73.4901 653.928 73.9378 653.928 74.4901V85.4116C653.928 85.9639 653.48 86.4116 652.928 86.4116H635.873C635.321 86.4116 634.873 85.9639 634.873 85.4116V74.4901C634.873 73.9378 634.425 73.4901 633.873 73.4901H621.355C620.803 73.4901 620.355 73.0424 620.355 72.4901Z"
          stroke="#F2F2F5"
          strokeWidth="2"
        />
        <mask
          id="mask3_5941_396867"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="926"
          y="67"
          width="46"
          height="20"
        >
          <path
            d="M971.502 85.4116V82.4121V68.0293C971.502 67.477 971.054 67.0293 970.502 67.0293H927.134C926.582 67.0293 926.134 67.477 926.134 68.0293V73.413C926.134 73.9653 926.582 74.413 927.134 74.413H940.106C940.658 74.413 941.106 74.8607 941.106 75.413V85.4116C941.106 85.9639 941.553 86.4116 942.106 86.4116H970.502C971.054 86.4116 971.502 85.9639 971.502 85.4116Z"
            fill="#8F90A6"
          />
        </mask>
        <g mask="url(#mask3_5941_396867)">
          <path d="M1102.79 37.6211L891.064 181.201" stroke="#F2F2F5" />
          <path d="M1052.68 3.63867L840.957 147.219" stroke="#F2F2F5" />
          <path d="M1099.44 35.3477L887.716 178.928" stroke="#F2F2F5" />
          <path d="M1049.33 1.36719L837.605 144.947" stroke="#F2F2F5" />
          <path d="M1096.09 33.0762L884.367 176.656" stroke="#F2F2F5" />
          <path d="M1045.98 -0.904297L834.257 142.676" stroke="#F2F2F5" />
          <path d="M1092.74 30.8047L881.017 174.385" stroke="#F2F2F5" />
          <path d="M1042.63 -3.17578L830.908 140.404" stroke="#F2F2F5" />
          <path d="M1089.4 28.5332L877.67 172.113" stroke="#F2F2F5" />
          <path d="M1039.29 -5.44922L827.558 138.131" stroke="#F2F2F5" />
          <path d="M1086.04 26.2617L874.318 169.842" stroke="#F2F2F5" />
          <path d="M1035.93 -7.7207L824.207 135.859" stroke="#F2F2F5" />
          <path d="M1082.69 23.9902L870.966 167.57" stroke="#F2F2F5" />
          <path d="M1032.58 -9.99219L820.857 133.588" stroke="#F2F2F5" />
          <path d="M1079.35 21.7168L867.619 165.297" stroke="#F2F2F5" />
          <path d="M1029.23 -12.2637L817.507 131.316" stroke="#F2F2F5" />
          <path d="M1075.99 19.4453L864.267 163.025" stroke="#F2F2F5" />
          <path d="M1025.88 -14.5352L814.158 129.045" stroke="#F2F2F5" />
          <path d="M1072.64 17.1738L860.916 160.754" stroke="#F2F2F5" />
          <path d="M1022.53 -16.8066L810.806 126.773" stroke="#F2F2F5" />
          <path d="M1069.29 14.9023L857.568 158.482" stroke="#F2F2F5" />
          <path d="M1019.19 -19.0781L807.459 124.502" stroke="#F2F2F5" />
          <path d="M1065.95 12.6309L854.218 156.211" stroke="#F2F2F5" />
          <path d="M1015.84 -21.3516L804.109 122.228" stroke="#F2F2F5" />
          <path d="M1062.59 10.3594L850.867 153.939" stroke="#F2F2F5" />
          <path d="M1012.49 -23.623L800.759 119.957" stroke="#F2F2F5" />
          <path d="M1059.24 8.08789L847.517 151.668" stroke="#F2F2F5" />
          <path d="M1009.13 -25.8945L797.408 117.685" stroke="#F2F2F5" />
          <path d="M1055.9 5.81445L844.17 149.394" stroke="#F2F2F5" />
          <path d="M1005.79 -28.166L794.058 115.414" stroke="#F2F2F5" />
        </g>
        <path
          d="M971.502 85.4116V82.4121V68.0293C971.502 67.477 971.054 67.0293 970.502 67.0293H927.134C926.582 67.0293 926.134 67.477 926.134 68.0293V73.413C926.134 73.9653 926.582 74.413 927.134 74.413H940.106C940.658 74.413 941.106 74.8607 941.106 75.413V85.4116C941.106 85.9639 941.553 86.4116 942.106 86.4116H970.502C971.054 86.4116 971.502 85.9639 971.502 85.4116Z"
          stroke="#F2F2F5"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M718.115 490.055L959.943 490.055" stroke="#F2F2F5" strokeWidth="2" stroke-linecap="round" />
        <path
          d="M887.117 413.141V489.747"
          stroke="#F2F2F5"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M995.094 413.141H926.29M760.543 413.141H901.29"
          stroke="#F2F2F5"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M752.732 489.748L755.617 454.222M741.488 286.08H833.585M876.23 286.08H994.559M770.524 286.08L758.02 427.299"
          stroke="#F2F2F5"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M46.9062 251.315H56.8872V237.47H90.0056M133.105 237.47H143.086M190.722 237.47H143.086M233.368 237.47H292.346M334.538 237.47H344.972V86.7192M143.086 83.0273V237.47"
          stroke="#F2F2F5"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M244.258 83.0273V237.163"
          stroke="#F2F2F5"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M642.586 489.44H47C46.4477 489.44 46 488.992 46 488.44V83.4111C46 82.8589 46.4477 82.4111 47 82.4111H323.558C324.11 82.4111 324.558 81.9634 324.558 81.4111V67.7207C324.558 67.1684 325.006 66.7207 325.558 66.7207H970.955C971.508 66.7207 971.955 67.1684 971.955 67.7207V85.103C971.955 85.6553 972.403 86.103 972.955 86.103H994.093C994.645 86.103 995.093 86.5507 995.093 87.103V117.682M995.093 490.279V185.471"
          stroke="#F2F2F5"
          strokeWidth="3"
          stroke-linecap="round"
        />
        <path
          d="M337.262 489.439V286.387H578.618M619.903 286.387H657.558V359.916L533.25 364.839L569.091 286.387"
          stroke="#F2F2F5"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M132.539 315.76H100.809V434.029H283.501V315.76H162.828"
          stroke="#F2F2F5"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <mask
          id="mask4_5941_396867"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="455"
          y="222"
          width="25"
          height="17"
        >
          <path
            d="M455.615 237.026V234.821V223.49C455.615 222.938 456.063 222.49 456.615 222.49H478.98C479.532 222.49 479.98 222.938 479.98 223.49V237.026C479.98 237.579 479.532 238.026 478.98 238.026H456.615C456.063 238.026 455.615 237.579 455.615 237.026Z"
            fill="#8F90A6"
          />
        </mask>
        <g mask="url(#mask4_5941_396867)">
          <path d="M597.852 198.916L428.139 314.004" stroke="#F2F2F5" />
          <path d="M557.686 171.678L387.973 286.766" stroke="#F2F2F5" />
          <path d="M595.166 197.096L425.453 312.184" stroke="#F2F2F5" />
          <path d="M555 169.857L385.287 284.946" stroke="#F2F2F5" />
          <path d="M592.48 195.275L422.768 310.364" stroke="#F2F2F5" />
          <path d="M552.314 168.037L382.602 283.126" stroke="#F2F2F5" />
          <path d="M589.795 193.453L420.082 308.542" stroke="#F2F2F5" />
          <path d="M549.629 166.215L379.916 281.303" stroke="#F2F2F5" />
          <path d="M587.109 191.633L417.397 306.721" stroke="#F2F2F5" />
          <path d="M546.945 164.395L377.233 279.483" stroke="#F2F2F5" />
          <path d="M584.426 189.812L414.713 304.901" stroke="#F2F2F5" />
          <path d="M544.26 162.574L374.547 277.663" stroke="#F2F2F5" />
          <path d="M581.74 187.99L412.028 303.079" stroke="#F2F2F5" />
          <path d="M541.574 160.752L371.862 275.84" stroke="#F2F2F5" />
          <path d="M579.055 186.17L409.342 301.258" stroke="#F2F2F5" />
          <path d="M538.889 158.932L369.176 274.02" stroke="#F2F2F5" />
          <path d="M576.369 184.35L406.657 299.438" stroke="#F2F2F5" />
          <path d="M536.205 157.111L366.492 272.2" stroke="#F2F2F5" />
          <path d="M573.684 182.527L403.971 297.616" stroke="#F2F2F5" />
          <path d="M533.52 155.289L363.807 270.378" stroke="#F2F2F5" />
          <path d="M571 180.707L401.287 295.795" stroke="#F2F2F5" />
          <path d="M530.834 153.469L361.121 268.557" stroke="#F2F2F5" />
          <path d="M568.314 178.887L398.602 293.975" stroke="#F2F2F5" />
          <path d="M528.148 151.648L358.436 266.737" stroke="#F2F2F5" />
          <path d="M565.629 177.064L395.916 292.153" stroke="#F2F2F5" />
          <path d="M525.463 149.826L355.75 264.915" stroke="#F2F2F5" />
          <path d="M562.943 175.244L393.231 290.333" stroke="#F2F2F5" />
          <path d="M522.777 148.006L353.065 263.094" stroke="#F2F2F5" />
          <path d="M560.26 173.424L390.547 288.512" stroke="#F2F2F5" />
          <path d="M520.094 146.186L350.381 261.274" stroke="#F2F2F5" />
        </g>
        <path
          d="M455.615 237.026V234.821V223.49C455.615 222.938 456.063 222.49 456.615 222.49H478.98C479.532 222.49 479.98 222.938 479.98 223.49V237.026C479.98 237.579 479.532 238.026 478.98 238.026H456.615C456.063 238.026 455.615 237.579 455.615 237.026Z"
          stroke="#F2F2F5"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <mask
          id="mask5_5941_396867"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="590"
          y="222"
          width="25"
          height="17"
        >
          <path
            d="M590.23 237.026V234.821V223.49C590.23 222.938 590.678 222.49 591.23 222.49H613.595C614.147 222.49 614.595 222.938 614.595 223.49V237.026C614.595 237.579 614.147 238.026 613.595 238.026H591.23C590.678 238.026 590.23 237.579 590.23 237.026Z"
            fill="#8F90A6"
          />
        </mask>
        <g mask="url(#mask5_5941_396867)">
          <path d="M732.467 198.916L562.754 314.004" stroke="#F2F2F5" />
          <path d="M692.301 171.678L522.588 286.766" stroke="#F2F2F5" />
          <path d="M729.781 197.096L560.069 312.184" stroke="#F2F2F5" />
          <path d="M689.615 169.857L519.903 284.946" stroke="#F2F2F5" />
          <path d="M727.096 195.275L557.383 310.364" stroke="#F2F2F5" />
          <path d="M686.93 168.037L517.217 283.126" stroke="#F2F2F5" />
          <path d="M724.41 193.453L554.698 308.542" stroke="#F2F2F5" />
          <path d="M684.244 166.215L514.532 281.303" stroke="#F2F2F5" />
          <path d="M721.725 191.633L552.012 306.721" stroke="#F2F2F5" />
          <path d="M681.561 164.395L511.848 279.483" stroke="#F2F2F5" />
          <path d="M719.041 189.812L549.328 304.901" stroke="#F2F2F5" />
          <path d="M678.875 162.574L509.162 277.663" stroke="#F2F2F5" />
          <path d="M716.355 187.99L546.643 303.079" stroke="#F2F2F5" />
          <path d="M676.189 160.752L506.477 275.84" stroke="#F2F2F5" />
          <path d="M713.67 186.17L543.957 301.258" stroke="#F2F2F5" />
          <path d="M673.504 158.932L503.791 274.02" stroke="#F2F2F5" />
          <path d="M710.984 184.35L541.272 299.438" stroke="#F2F2F5" />
          <path d="M670.82 157.111L501.108 272.2" stroke="#F2F2F5" />
          <path d="M708.299 182.527L538.586 297.616" stroke="#F2F2F5" />
          <path d="M668.135 155.289L498.422 270.378" stroke="#F2F2F5" />
          <path d="M705.615 180.707L535.903 295.795" stroke="#F2F2F5" />
          <path d="M665.449 153.469L495.737 268.557" stroke="#F2F2F5" />
          <path d="M702.93 178.887L533.217 293.975" stroke="#F2F2F5" />
          <path d="M662.764 151.648L493.051 266.737" stroke="#F2F2F5" />
          <path d="M700.244 177.064L530.532 292.153" stroke="#F2F2F5" />
          <path d="M660.078 149.826L490.366 264.915" stroke="#F2F2F5" />
          <path d="M697.559 175.244L527.846 290.333" stroke="#F2F2F5" />
          <path d="M657.393 148.006L487.68 263.094" stroke="#F2F2F5" />
          <path d="M694.875 173.424L525.162 288.512" stroke="#F2F2F5" />
          <path d="M654.709 146.186L484.996 261.274" stroke="#F2F2F5" />
        </g>
        <path
          d="M590.23 237.026V234.821V223.49C590.23 222.938 590.678 222.49 591.23 222.49H613.595C614.147 222.49 614.595 222.938 614.595 223.49V237.026C614.595 237.579 614.147 238.026 613.595 238.026H591.23C590.678 238.026 590.23 237.579 590.23 237.026Z"
          stroke="#F2F2F5"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <mask
          id="mask6_5941_396867"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="724"
          y="222"
          width="26"
          height="17"
        >
          <path
            d="M724.846 237.026V234.821V223.49C724.846 222.938 725.293 222.49 725.846 222.49H748.21C748.763 222.49 749.21 222.938 749.21 223.49V237.026C749.21 237.579 748.763 238.026 748.21 238.026H725.846C725.293 238.026 724.846 237.579 724.846 237.026Z"
            fill="#8F90A6"
          />
        </mask>
        <g mask="url(#mask6_5941_396867)">
          <path d="M867.082 198.916L697.369 314.004" stroke="#F2F2F5" />
          <path d="M826.916 171.678L657.203 286.766" stroke="#F2F2F5" />
          <path d="M864.396 197.096L694.684 312.184" stroke="#F2F2F5" />
          <path d="M824.23 169.857L654.518 284.946" stroke="#F2F2F5" />
          <path d="M861.711 195.275L691.998 310.364" stroke="#F2F2F5" />
          <path d="M821.545 168.037L651.832 283.126" stroke="#F2F2F5" />
          <path d="M859.025 193.453L689.313 308.542" stroke="#F2F2F5" />
          <path d="M818.859 166.215L649.147 281.303" stroke="#F2F2F5" />
          <path d="M856.34 191.633L686.627 306.721" stroke="#F2F2F5" />
          <path d="M816.176 164.395L646.463 279.483" stroke="#F2F2F5" />
          <path d="M853.656 189.812L683.944 304.901" stroke="#F2F2F5" />
          <path d="M813.49 162.574L643.778 277.663" stroke="#F2F2F5" />
          <path d="M850.971 187.99L681.258 303.079" stroke="#F2F2F5" />
          <path d="M810.805 160.752L641.092 275.84" stroke="#F2F2F5" />
          <path d="M848.285 186.17L678.573 301.258" stroke="#F2F2F5" />
          <path d="M808.119 158.932L638.407 274.02" stroke="#F2F2F5" />
          <path d="M845.6 184.35L675.887 299.438" stroke="#F2F2F5" />
          <path d="M805.436 157.111L635.723 272.2" stroke="#F2F2F5" />
          <path d="M842.914 182.527L673.201 297.616" stroke="#F2F2F5" />
          <path d="M802.75 155.289L633.037 270.378" stroke="#F2F2F5" />
          <path d="M840.23 180.707L670.518 295.795" stroke="#F2F2F5" />
          <path d="M800.064 153.469L630.352 268.557" stroke="#F2F2F5" />
          <path d="M837.545 178.887L667.832 293.975" stroke="#F2F2F5" />
          <path d="M797.379 151.648L627.666 266.737" stroke="#F2F2F5" />
          <path d="M834.859 177.064L665.147 292.153" stroke="#F2F2F5" />
          <path d="M794.693 149.826L624.981 264.915" stroke="#F2F2F5" />
          <path d="M832.174 175.244L662.461 290.333" stroke="#F2F2F5" />
          <path d="M792.008 148.006L622.295 263.094" stroke="#F2F2F5" />
          <path d="M829.49 173.424L659.778 288.512" stroke="#F2F2F5" />
          <path d="M789.324 146.186L619.612 261.274" stroke="#F2F2F5" />
        </g>
        <path
          d="M724.846 237.026V234.821V223.49C724.846 222.938 725.293 222.49 725.846 222.49H748.21C748.763 222.49 749.21 222.938 749.21 223.49V237.026C749.21 237.579 748.763 238.026 748.21 238.026H725.846C725.293 238.026 724.846 237.579 724.846 237.026Z"
          stroke="#F2F2F5"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <mask
          id="mask7_5941_396867"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="859"
          y="222"
          width="25"
          height="17"
        >
          <path
            d="M859.461 237.026V234.821V223.49C859.461 222.938 859.909 222.49 860.461 222.49H882.826C883.378 222.49 883.826 222.938 883.826 223.49V237.026C883.826 237.579 883.378 238.026 882.826 238.026H860.461C859.909 238.026 859.461 237.579 859.461 237.026Z"
            fill="#8F90A6"
          />
        </mask>
        <g mask="url(#mask7_5941_396867)">
          <path d="M1001.7 198.916L831.985 314.004" stroke="#F2F2F5" />
          <path d="M961.531 171.678L791.819 286.766" stroke="#F2F2F5" />
          <path d="M999.012 197.096L829.299 312.184" stroke="#F2F2F5" />
          <path d="M958.846 169.857L789.133 284.946" stroke="#F2F2F5" />
          <path d="M996.326 195.275L826.614 310.364" stroke="#F2F2F5" />
          <path d="M956.16 168.037L786.448 283.126" stroke="#F2F2F5" />
          <path d="M993.641 193.453L823.928 308.542" stroke="#F2F2F5" />
          <path d="M953.475 166.215L783.762 281.303" stroke="#F2F2F5" />
          <path d="M990.955 191.633L821.242 306.721" stroke="#F2F2F5" />
          <path d="M950.791 164.395L781.078 279.483" stroke="#F2F2F5" />
          <path d="M988.271 189.812L818.559 304.901" stroke="#F2F2F5" />
          <path d="M948.105 162.574L778.393 277.663" stroke="#F2F2F5" />
          <path d="M985.586 187.99L815.873 303.079" stroke="#F2F2F5" />
          <path d="M945.42 160.752L775.707 275.84" stroke="#F2F2F5" />
          <path d="M982.9 186.17L813.188 301.258" stroke="#F2F2F5" />
          <path d="M942.734 158.932L773.022 274.02" stroke="#F2F2F5" />
          <path d="M980.215 184.35L810.502 299.438" stroke="#F2F2F5" />
          <path d="M940.051 157.111L770.338 272.2" stroke="#F2F2F5" />
          <path d="M977.529 182.527L807.817 297.616" stroke="#F2F2F5" />
          <path d="M937.365 155.289L767.653 270.378" stroke="#F2F2F5" />
          <path d="M974.846 180.707L805.133 295.795" stroke="#F2F2F5" />
          <path d="M934.68 153.469L764.967 268.557" stroke="#F2F2F5" />
          <path d="M972.16 178.887L802.448 293.975" stroke="#F2F2F5" />
          <path d="M931.994 151.648L762.282 266.737" stroke="#F2F2F5" />
          <path d="M969.475 177.064L799.762 292.153" stroke="#F2F2F5" />
          <path d="M929.309 149.826L759.596 264.915" stroke="#F2F2F5" />
          <path d="M966.789 175.244L797.076 290.333" stroke="#F2F2F5" />
          <path d="M926.623 148.006L756.91 263.094" stroke="#F2F2F5" />
          <path d="M964.105 173.424L794.393 288.512" stroke="#F2F2F5" />
          <path d="M923.939 146.186L754.227 261.274" stroke="#F2F2F5" />
        </g>
        <path
          d="M859.461 237.026V234.821V223.49C859.461 222.938 859.909 222.49 860.461 222.49H882.826C883.378 222.49 883.826 222.938 883.826 223.49V237.026C883.826 237.579 883.378 238.026 882.826 238.026H860.461C859.909 238.026 859.461 237.579 859.461 237.026Z"
          stroke="#F2F2F5"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </StyledAssetMap>
  );
};
