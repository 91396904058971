/**
 * @description
 * This is component that is used in SearchBar for entering data to search it
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as LocationImage } from '../../../../assets/location.svg';
import { borderRadiusFetch, colorFetch } from '../../../../styles/utils';
import { CloseButton } from '../../../closeButton/CloseButton';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 327px;
  height: 40px;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${colorFetch('primary')};
  border: none;
  border-radius: ${borderRadiusFetch('primary')};
  outline: none;
  padding: 6px 32px 6px 40px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('pureWhite')};

  ::placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    color: ${colorFetch('pureWhite')};
    opacity: 0.6;
    letter-spacing: 0.2px;
  }
`;

const LocationImageWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 6px;
  left: 7px;

  & > svg {
    width: 28px;
    height: 28px;
  }

  & > svg > path {
    stroke: ${colorFetch('pureWhite')};
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export interface SearchInputProps {
  action: any;
  value: string;
  onChange: any;
  className?: string;
  languageStrings?: any;
}

export const SearchInput: FC<SearchInputProps> = ({ action, value, onChange, languageStrings, ...props }) => {
  const theme = useTheme();
  const { pureWhite } = theme.colors;

  return (
    <Wrapper {...props}>
      <LocationImageWrapper theme={theme}>
        <LocationImage />
      </LocationImageWrapper>
      <Input
        placeholder={languageStrings ? languageStrings.inputPlaceHolderBar : 'Choose a place...'}
        value={value}
        onChange={onChange}
        theme={theme}
      />
      <CloseButtonWrapper>
        <CloseButton onClick={action} color={pureWhite} hoverColor={pureWhite} />
      </CloseButtonWrapper>
    </Wrapper>
  );
};

SearchInput.defaultProps = {};
