import { Text } from '@bit/first-scope.text';
import styled from 'styled-components';
import { ReactComponent as ArrowImage } from '../../../assets/SmallArrow.svg';
import { media } from '../../../styles/media';
import { borderRadiusFetch, colorFetch } from '../../../styles/utils';

export const TextWrapper = styled.div`
  box-sizing: border-box;
  flex: 0;
  overflow: hidden;
  transition: 0.3s;
  &.open {
    flex: 1;
  }
`;

export const StyledLangArrowImage = styled(ArrowImage)`
  transition: all 0.3s ease;
  &.open {
    transform: rotate(-180deg);
  }
  ${media.tablet`
    transform: rotate(-90deg);
    &.open {
      transform: rotate(-270deg);
    }
  `}
`;

type WrapperLanguagesProps = {
  isSmallHeightViewport: boolean;
};

// @TODO fix media types
export const WrapperLanguages = styled.div<WrapperLanguagesProps>`
  position: relative;
  box-sizing: border-box;
  padding-left: 18px;
  ${media.phoneS`
    margin-bottom: ${({ isSmallHeightViewport }: any) => (isSmallHeightViewport ? '0px' : '30px 0 30px 0')};
  `}
  ${media.tablet`
    padding: 0 16px;
  `}
`;

export const SelectedLang = styled.div`
  box-sizing: border-box;
  transform: translateY(100%);
  width: 100%;
  height: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  &.openNav {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const SelectedLangSec = styled.div`
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
  &.openNav {
    opacity: 0;
  }
`;

export const ListLanguages = styled.div`
  box-sizing: border-box;
  width: 0;
  padding: 12px 15px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 20px), calc(-50% + 12px));
  background-color: ${colorFetch('pureWhite')};
  border-radius: 8px;
  box-shadow: 0 0 2px rgb(40 41 61 / 4%), 0 4px 8px rgb(96 97 112 / 16%);
  opacity: 0;
  transition: all 0.3s ease;
  pointer-events: none;
  overflow: hidden;
  &.open {
    width: 110px;
    opacity: 1;
    pointer-events: auto;
  }
`;

export const ItemLang = styled.div`
  cursor: pointer;
  color: ${colorFetch('gray1')};
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 14px;
  transition: all 0.3s ease;
  &:hover {
    font-weight: 600;
  }
  &.selected {
    font-weight: 600;
  }
  &.last {
    margin: 0;
  }
`;

export const WrapperLogout = styled.div`
  box-sizing: border-box;
  padding-left: 18px;
  margin-bottom: 10px;
  /* min-height: 75px; */
  ${media.tablet`
    padding: 10px 16px 20px;
    margin: 0;
  `}
`;

export const UserNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  overflow: hidden;
  background-color: ${colorFetch('gray5')};
  border-radius: ${borderRadiusFetch('additional6')};
  padding: 6px;
  margin-left: -4px;
  &.open {
    justify-content: flex-start;
    width: 100%;
    height: auto;
    background-color: transparent;
    padding: 0;
    margin-left: 0;
  }
  ${media.tablet`
    margin-left: 4px;
  `}
`;

export const UserName = styled(Text)`
  white-space: nowrap;
`;

export const UserRole = styled(Text)`
  white-space: nowrap;
`;

type StyledLangTextProps = {
  isOpenNav?: boolean;
};

export const StyledLangText = styled(Text)<StyledLangTextProps>`
  display: ${({ isOpenNav }: any) => (isOpenNav ? 'block' : 'none')};
  ${media.tablet`
    display: flex;
  `}
`;

export const UserRoleWrapper = styled.div`
  box-sizing: border-box;
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease;
  &.open {
    width: 100%;
  }
`;

export const Line = styled.div`
  box-sizing: border-box;
  margin: 15px 0;
  height: 1px;
  width: 100%;
  background-color: ${colorFetch('gray4')};
  transition: width 0.3s ease;
`;

export const LogoutButton = styled.button`
  box-sizing: border-box;
  border: none;
  outline: transparent;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const StyledLogoutText = styled(Text)`
  margin-left: 10px;
`;
