import styled from 'styled-components';
import { media } from '../../../styles/media';
import { Text } from '@bit/first-scope.text';
import { colorFetch } from '../../../styles/utils';

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  ${media.tablet`
    padding: 60px 0 10px 0;
  `}
`;

export const StyledTextLink = styled(Text)`
  text-decoration: ${({ withUnderline }: any) => (withUnderline ? 'underline' : 'none')};
  cursor: pointer;
` as any; // @TODO fix type

export const Title = styled(Text)`
  color: ${colorFetch('black1')};
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  margin: 0 0 0 14px;

  ${media.phone`
    width: 100%;
    text-align: center;
    margin: 0 32px 0 0;
    line-height: 24px;
  `}
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 32px 0;

  ${media.phone`
    margin: 32px 0 32px 16px;
  `}
`;
