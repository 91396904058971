import { useEffect, useState } from 'react';
import { API_URL } from '../consts';
import { ErrorEventType } from '../types/error';
import { HttpService } from '../utils/http';
import { handleUncoveredNotification } from '../utils/handleUncoveredNotification';

export const uploadOrder = (data: any, addNotification: Function, language: any) => {
  const url = `${API_URL}/v1/orders/file`;
  const bodyFormData = new FormData();
  bodyFormData.append('file', data);
  (async () => {
    try {
      await HttpService.post(url, bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const message = 'The order is being processed!';
      addNotification({
        message: message,
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      handleUncoveredNotification(error, addNotification, language);
    }
  })();
};

export const createOrder = (
  data: any,
  handleLocalCreate: Function,
  handleAddNotification: Function,
  language: Object,
  setBlockButtons: Function,
) => {
  const url = `${API_URL}/v1/orders/`;
  setBlockButtons(true);

  (async () => {
    try {
      let responseData = (await HttpService.post(url, data)).data;
      handleLocalCreate(!!responseData.payload);
      handleAddNotification({
        message: 'Saved successfully',
        type: 'success',
      });
      setBlockButtons(false);
    } catch (e) {
      const error = e as ErrorEventType;
      handleUncoveredNotification(error, handleAddNotification, language);
      setBlockButtons(false);
      handleLocalCreate(false);
    }
  })();
};

export const useGetComponents = () => {
  const [localComponents, setLocalComponents] = useState([]);
  useEffect(() => {
    const url = `${API_URL}/v1/components/`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data.payload;
        setLocalComponents(responseData);
      } catch (e) {
        //
      }
    })();
  }, []);
  return localComponents;
};

export const useGetOrders = () => {
  const [localOrders, setLocalOrders] = useState();
  useEffect(() => {
    const url = `${API_URL}/v1/orders/?limit=0`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data.payload;
        const result = responseData.map((item: any) => {
          const order = { ...item, name: item.order_id };
          delete order.order_id;
          return order;
        });
        setLocalOrders(result);
      } catch (e) {
        //
      }
    })();
  }, []);
  return localOrders;
};
