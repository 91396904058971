import React, { SyntheticEvent } from 'react';
import { Input, StyledCheckbox, Wrapper } from './styles';

const getClassNames = (disabled: boolean, checked: boolean) => {
  const rawClassNames = [disabled ? 'disabled' : '', checked ? 'checked' : ''] as const;
  const classNames = rawClassNames.reduce(
    (className, currentClassName) => (currentClassName ? [...className, currentClassName] : [...className]),
    [] as string[],
  );
  return classNames.join(' ');
};

export interface CheckBoxProps {
  disabled?: boolean;
  value: boolean;
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
}

export const CheckBox: React.FC<CheckBoxProps> = ({ disabled, value, onChange }) => {
  const className = getClassNames(!!disabled, value);

  return (
    <Wrapper>
      <Input disabled={disabled} className={className} type="checkbox" value={value as any /* @TODO fix type */} />
      <StyledCheckbox className={className} onClick={onChange as any /* @TODO fix type */} />
    </Wrapper>
  );
};
