import styled from 'styled-components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/userSlice';
import { useLanguagePicker } from '../../../../languages/useLanguagePicker';
import { media } from '../../../../styles/media';
import { useBlockBodyScroll } from '../../../../utils/useBlockBodyScroll';
import { ReactComponent as ClaspIcon } from '../../../../assets/clasp_icon.svg';
import { ItemFirstMobileMenu, MenuItemType } from './ItemFirstMobileMenu';
import { useNavigationContext } from '../navigation.context';
import { ItemWrapper, MobileItemLang, StyledManageArrowImage, StyledManageNavlink } from './styled';
import { useWindowSize } from '../../../../styles/style.context';
import { colorFetch } from '../../../../styles/utils';

const Header = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BurgerButton = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  & div {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: ${colorFetch('black1')};
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
  }
  & .first {
    transform: translateY(calc(-50% - 6px));
  }
  & .third {
    width: 70%;
    transform: translateY(calc(-50% + 6px));
  }
  &.open {
    & .second {
      opacity: 0;
    }
    & .first {
      transform: rotate(45deg) translate(0, 0);
    }
    & .third {
      width: 18px;
      transform: rotate(-45deg) translate(0, 0);
    }
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: ${colorFetch('black1')};
    opacity: 0;
    transition: opacity 0.3s;
  }
  &.open {
    pointer-events: auto;
    &:after {
      opacity: 0.4;
    }
  }
`;

const FirstMobileMenu = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 270px;
  background-color: ${colorFetch('pureWhite')};
  transform: translateX(-100%);
  transition: transform 0.3s;
  box-shadow: 0px -4px 25px rgba(0, 0, 0, 0.1);
  &.open {
    transform: translateX(0);
  }
  ${media.tabletNavigation`
    width: 100%;
  `}
`;

const SecondMobileMenu = styled.div`
  position: absolute;
  top: 0;
  left: 270px;
  bottom: 0;
  width: 270px;
  display: flex;
  flex-direction: column;
  background-color: ${colorFetch('pureWhite')};
  transform: translateX(-200%);
  transition: 0.3s;
  z-index: 1;
  &.open {
    transform: translateX(0);
    transition: 0.3s;
  }
  ${media.tabletNavigation`
    z-index: 10;
    box-shadow: 0px -4px 25px rgba(0, 0, 0, 0.1);
    width: 100%;
    left: 0;
    transform: translateX(-100%);
  `}
`;

const BackButton = styled.button`
  position: relative;
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
  padding: 10px 16px;
  margin: 0;
  height: 45px;
  display: flex;
  align-items: center;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 12px;
    right: 12px;
    height: 0.5px;
    background-color: ${colorFetch('gray3')};
  }
`;

const BackText = styled.span`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colorFetch('gray2')};
  margin-left: 10px;
`;

export const MobileNavigation = ({ menuItemArray, userStatus, userName, onLogout, withLanguages }: any) => {
  const heightView = useWindowSize()[1];
  const isSmallHeightViewport = 650 >= heightView;

  const [{ secondaryMenuItems }, , { clearSecondaryItems }] = useNavigationContext();

  const [isOpenFirstMenu, setIsOpenFirstMenu] = useState(false);

  const { changeLanguageHandler, languageIndex } = useLanguagePicker();

  const language = useSelector(selectLanguageStrings);

  const handleCloseFirstMenu = () => {
    setIsOpenFirstMenu(!isOpenFirstMenu);
    clearSecondaryItems();
  };

  const handleBurgerButton = () => {
    setIsOpenFirstMenu(!isOpenFirstMenu);
    clearSecondaryItems();
    handleCloseSecondMenu();
  };

  const handleCloseSecondMenu = () => {
    clearSecondaryItems();
  };

  const handleCloseFirstAndSecondMenu = () => {
    setIsOpenFirstMenu(false);
    clearSecondaryItems();
  };

  useBlockBodyScroll(isOpenFirstMenu);

  return (
    <>
      <Header>
        <BurgerButton className={isOpenFirstMenu ? 'open' : ''} onClick={handleBurgerButton}>
          <div className="first" />
          <div className="second" />
          <div className="third" />
        </BurgerButton>
        <ClaspIcon />
      </Header>
      <Modal className={isOpenFirstMenu ? 'open' : ''}>
        <FirstMobileMenu className={isOpenFirstMenu ? 'open' : ''}>
          {menuItemArray.map((item: any, index: number) => {
            const { value, route, type, childRoutes, pathForActive, isAccessView } = item;
            return isAccessView ? (
              <ItemFirstMobileMenu
                key={index}
                type={type}
                value={value}
                to={route}
                tooltipId={''}
                childRoutes={childRoutes ? childRoutes : null}
                pathForActive={pathForActive ? pathForActive : null}
                handleCloseFirstMenu={handleCloseFirstMenu}
                isOpen
                isSmallHeightViewport={isSmallHeightViewport}
              />
            ) : null;
          })}
          <div style={{ flex: 1, minHeight: '5px' }}></div>
          {withLanguages && (
            <ItemFirstMobileMenu
              type={MenuItemType.LANGUAGES}
              isOpen={true}
              handleCloseFirstMenu={handleCloseFirstMenu}
              isSmallHeightViewport={isSmallHeightViewport}
            />
          )}
          <ItemFirstMobileMenu
            type={MenuItemType.LOGOUT}
            isOpen={true}
            userRole={userStatus}
            userName={userName}
            onLogout={onLogout}
            handleCloseFirstMenu={handleCloseFirstMenu}
          />
        </FirstMobileMenu>
        {secondaryMenuItems.length > 0 ? (
          <SecondMobileMenu className={secondaryMenuItems.length > 0 ? 'open' : ''}>
            <BackButton onClick={handleCloseSecondMenu}>
              <StyledManageArrowImage className={'back_button'} />
              <BackText>{language ? language.backButton : 'Back'}</BackText>
            </BackButton>
            {secondaryMenuItems &&
              secondaryMenuItems.map((item, i) => {
                const { name, to, view } = item;
                return to ? (
                  <ItemWrapper>
                    <StyledManageNavlink
                      style={{ marginLeft: 0, marginTop: 0 }}
                      to={to}
                      onClick={handleCloseFirstAndSecondMenu}
                    >
                      {name}
                    </StyledManageNavlink>
                  </ItemWrapper>
                ) : view === 'LINE' ? null : (
                  <ItemWrapper
                    onClick={() => {
                      changeLanguageHandler(secondaryMenuItems[i].id!);
                      clearSecondaryItems();
                      setIsOpenFirstMenu(!isOpenFirstMenu);
                    }}
                  >
                    <MobileItemLang className={i === languageIndex ? 'active' : ''}>{name}</MobileItemLang>
                  </ItemWrapper>
                );
              })}
          </SecondMobileMenu>
        ) : null}
      </Modal>
    </>
  );
};
