import { useEffect, useMemo, useState } from 'react';
import { API_URL, NEW_ID } from '../consts';
import { ZoneNotificationType } from '../containers/management/zonesDetails/types';
import { useApiMutation } from '../features/apiBuilder/useApiBuilder';
import { useLoaderOptions } from '../features/placeholderComponent/loaderFunctions';
import { ErrorEventType, ErrorEventTypeExtended } from '../types/error';
import { HttpService } from '../utils/http';

export const useManageZonesDetails = (
  floor_id: string,
  setPlaceholder?: any,
  stopPlaceholder?: any,
  height?: number,
  width?: number,
  activeId?: string | null,
  isUpdateZones?: boolean,
  setIsUpdateZones?: (state: boolean) => void,
) => {
  const [localZonesDetails, setLocalZonesDetails] = useState<any>([]);

  const scaledData = useMemo(() => {
    let scaledState = [];
    if (localZonesDetails && localZonesDetails.length > 0 && height && width) {
      scaledState = localZonesDetails.map((el: any) => {
        const { location, coordinates: baseCoords, associated_stages } = el;
        const { floor } = location;
        const { center, width: baseWidth, height: baseHeight } = baseCoords;
        const ratio = width / floor.image_width;
        const coordinates = {
          width: baseWidth,
          height: baseHeight,
          scaled_center: {
            x: center.x * ratio,
            y: center.y * ratio,
          },
          center,
        };
        //const associatedStages = { list: stagesList, selected_stages: associated_stages };
        return { ...el, active: el.id === activeId, associated_stages: associated_stages, coordinates };
      });
    }
    return scaledState;
  }, [height, width, localZonesDetails, activeId, isUpdateZones]);

  const trigger = [floor_id] as const;
  /* eslint-disable react-hooks/exhaustive-deps */
  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);
  useEffect(() => {
    const url = `${API_URL}/v1/zones/floor/${floor_id}?limit=100&offset=0`;
    (async () => {
      if (floor_id) {
        try {
          let responseData = (await HttpService.get(url, loaderOptions)).data.payload;
          setLocalZonesDetails(responseData);
        } catch (e) {
          //
        }
      }
    })();
  }, [...trigger, loaderOptions, isUpdateZones]);

  useEffect(() => {
    setIsUpdateZones && setIsUpdateZones(false);
  }, [isUpdateZones]);

  return [scaledData, localZonesDetails, setLocalZonesDetails] as const;
};

export const updateZone = (
  id: string,
  data: any,
  options?: {
    localUpdate?: (id: string, data: any) => void;
    triggerNotification?: (success: boolean, message?: string) => void;
    handleRevert?: () => void;
  },
) => {
  if (id.includes(NEW_ID)) {
    const url = `${API_URL}/v1/zones/`;
    (async () => {
      try {
        let { payload } = (await HttpService.post(url, data)).data;
        const { id, ...newData } = payload;
        options?.triggerNotification && options?.triggerNotification(true);
        options?.localUpdate && options?.localUpdate(id, newData);
      } catch (e) {
        const error = e as ErrorEventType;
        const errorMessage = error?.response?.data?.detail;
        /* @ts-ignore */
        options?.triggerNotification && options?.triggerNotification(false, errorMessage);
      }
    })();
  } else {
    const url = `${API_URL}/v1/zones/${id}`;
    (async () => {
      try {
        await HttpService.patch(url, data);
        options?.triggerNotification && options?.triggerNotification(true);
      } catch (e) {
        const error = e as ErrorEventType;
        const errorMessage = error?.response?.data?.detail;
        /* @ts-ignore */
        options?.triggerNotification && options?.triggerNotification(false, errorMessage);
        options?.handleRevert && options?.handleRevert();
      }
    })();
  }
};

const floorZonesUpdateKeywords = ['FLOOR_ZONES_UPDATE'];

export const useFloorZonesUpdate = (
  id: string, // @NOTE floor id
  options: {
    triggerNotification?: (notificationType: ZoneNotificationType, message?: string) => void;
  } = {},
) => {
  const { triggerNotification } = options;

  const [mutation] = useApiMutation({
    url: `${API_URL}/v1/zones/floor/${id}/save`,
    keywords: floorZonesUpdateKeywords,
    handleSuccess: () => {
      triggerNotification && triggerNotification(ZoneNotificationType.SUCCESS);
    },
    handleError: (e) => {
      const error = e as ErrorEventTypeExtended;
      options?.triggerNotification &&
        options?.triggerNotification(ZoneNotificationType.ERROR_INTERSECTION, error?.response?.data?.detail);
    },
  });

  return mutation;
};
