import React from 'react';

export const HouseIcon = ({ color }: { color: string }) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4">
        <path
          d="M13.3616 43.9995V21.7446H38.6391V44.5277"
          stroke={color}
          stroke-width="2.7"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M13.3613 27.0564H38.6389"
          stroke={color}
          stroke-width="2.7"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M13.3613 32.8867H38.6389"
          stroke={color}
          stroke-width="2.7"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
      </g>
      <path
        d="M47.1886 44.5277V13.2633L26.0001 7.47217L4.81152 13.2633V44.5277H47.1886Z"
        stroke={color}
        stroke-width="2.7"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
    </svg>
  );
};
