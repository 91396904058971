/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for add button require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/Add.svg';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';

type ButtonProps = {
  width: string;
  height: string;
  backgroundColor?: string;
  borderColor?: string;
  colorHover?: string;
  backgroundColorHover?: string;
  borderColorHover?: string;
  marginButton?: string;
};

const Button = styled.button<ButtonProps>`
  max-width: ${({ width }) => width};
  width: 100%;
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ backgroundColor, theme }) => backgroundColor || colorFetch('pureWhite')({ theme })};
  border-radius: ${borderRadiusFetch('primary')};
  border: 1px solid;
  border-color: ${({ borderColor, theme }) => borderColor || colorFetch('primary')({ theme })};
  transition: all 0.3s linear;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  margin: ${({ marginButton }) => marginButton};
  & > span {
    color: ${({ color, theme }) => color || colorFetch('primary')({ theme })};
  }
  & > svg > path {
    stroke: ${({ color, theme }) => color || colorFetch('primary')({ theme })};
  }
  &:hover {
    background-color: ${({ backgroundColorHover, theme }) => backgroundColorHover || colorFetch('primary')({ theme })};
    border-color: ${({ borderColorHover, theme }) => borderColorHover || colorFetch('primary')({ theme })};
    & > span {
      color: ${({ colorHover, theme }) => colorHover || colorFetch('pureWhite')({ theme })};
    }
    & > svg > path {
      stroke: ${({ colorHover, theme }) => colorHover || colorFetch('pureWhite')({ theme })};
    }
  }
`;

type TitleProps = {
  fontSize?: string;
};

const Title = styled.span<TitleProps>`
  max-width: calc(100% - 14px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 2px;
  font-size: ${({ fontSize }) => fontSize};
`;

export interface AddButtonProps {
  onClick: (...props: any[]) => void;
  title: string;
  width: string;
  height: string;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  colorHover?: string;
  backgroundColorHover?: string;
  borderColorHover?: string;
  fontSize?: string;
  marginButton?: string;
}

export const AddButton: FC<AddButtonProps> = ({
  onClick,
  title,
  width,
  height,
  color,
  backgroundColor,
  borderColor,
  colorHover,
  backgroundColorHover,
  borderColorHover,
  fontSize,
  marginButton,
}) => {
  return (
    <Button
      onClick={onClick}
      width={width}
      height={height}
      color={color}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      colorHover={colorHover}
      backgroundColorHover={backgroundColorHover}
      borderColorHover={borderColorHover}
      marginButton={marginButton}
    >
      <AddIcon />
      <Title fontSize={fontSize}>{title}</Title>
    </Button>
  );
};
