/**
 * @description
 * This is component for cheap
 * all the functionalities cthings require for cheap
 * Highly customisable.
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CloseButton } from '@bit/first-scope.close-button';
import { useTheme } from '@cthings.co/styles';
import { borderRadiusFetch } from '../../../../styles/utils';

type WrapperProps = {
  backgroundColor: string;
};

const Wrapper = styled.div<WrapperProps>`
  width: max-content;
  height: 22px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 4px 8px;
  border-radius: ${borderRadiusFetch('primary')};
  margin: 6px 6px 0 0;
`;

export const Cheap = ({
  value,
  closeOnClick,
  isDisabled,
  isPopupDescription,
}: {
  value: string;
  closeOnClick: () => void;
  isDisabled?: boolean;
  isPopupDescription?: boolean;
}) => {
  const theme = useTheme();
  const { gray1, gray2, gray5, pureWhite } = theme.colors;

  return (
    <Wrapper
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      backgroundColor={isPopupDescription ? gray5 : isDisabled ? pureWhite : gray5}
    >
      <Text type={TextType.TEXT_12_GRAY} color={gray2} margin={'0 6px 0 0'}>
        {value}
      </Text>
      {!isDisabled && (
        <CloseButton onClick={closeOnClick} color={gray1} width={'12px'} height={'12px'} strokeWidth={'2px'} />
      )}
    </Wrapper>
  );
};
