import React from 'react';

export const OfflineIcon = ({ color }: { color: string }) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.2266 23.9634C38.0013 24.8295 39.6608 25.914 41.1666 27.1917"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8333 27.192C14.0444 24.5071 17.9093 22.7205 22.0349 22.0137"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        d="M23.2048 10.9421C27.8467 10.568 32.5164 11.1331 36.9351 12.6035C41.3538 14.0739 45.4309 16.4196 48.9232 19.5004"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        d="M3.07666 19.5003C6.08219 16.8436 9.52834 14.7319 13.26 13.2603"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        d="M18.4817 34.9052C20.6813 33.3425 23.3126 32.5029 26.0109 32.5029C28.7091 32.5029 31.3404 33.3425 33.54 34.9052"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M26 43.3335H26.025" stroke={color} stroke-width="8.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M2.16675 2.16675L49.8334 49.8334"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
