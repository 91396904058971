import React, { FC } from 'react';
import styled from 'styled-components';
import { TableCustomComponentState } from '../../../../../../features/universalTable/types';
import { colorFetch } from '../../../../../../styles/utils';

const Text = styled.span`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${colorFetch('gray1')};
  width: 100%;

  &.modal {
    padding: 8px 12px;
  }
`;

const InProgress = styled.span`
  color: ${colorFetch('orange1')};
`;

const ReadyToShip = styled.span`
  color: ${colorFetch('green1')};
`;

const Total = styled.span`
  font-weight: 700;
`;

interface typeValue {
  in_process: string;
  ready_to_ship: string;
  total: string;
}
interface QuantityProps {
  value: typeValue;
  state?: TableCustomComponentState;
  setValue?: (value: typeValue) => void;
}

export const Quantity: FC<QuantityProps> = ({ value, state }) => (
  <>
    {state === TableCustomComponentState.VIEW ? (
      <Text>
        <ReadyToShip>{value.ready_to_ship}</ReadyToShip> / <InProgress>{value.in_process}</InProgress> /
        <Total>{value.total}</Total>
      </Text>
    ) : state === TableCustomComponentState.VIEW_MODAL ? (
      <Text className={'modal'}>
        <ReadyToShip>{value.ready_to_ship}</ReadyToShip> / <InProgress>{value.in_process}</InProgress> /
        <Total>{value.total}</Total>
      </Text>
    ) : (
      <Text>
        <ReadyToShip>{value.ready_to_ship}</ReadyToShip> / <InProgress>{value.in_process}</InProgress> /
        <Total>{value.total}</Total>
      </Text>
    )}
  </>
);
