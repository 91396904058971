import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../../../../styles/media';
import { Text, TextType } from '@bit/first-scope.text';
// @ts-ignore
import { v4 as uuidv4, v1 as uuidv1 } from 'uuid';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { ItemMetaDocuments } from './ItemMetaDocuments';
import { useTableContext } from '../../../../../features/universalTable/context';
import { RowPlaceholder } from './RowPlaceholder';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { borderRadiusFetch, colorFetch } from '../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const WrapperMetaDataContainer = styled.div``;

type MetaDataContainerProps = {
  state?: TableCustomComponentState;
};

// styles for metaDocuments block
const MetaDataContainer = styled.div<MetaDataContainerProps>`
  width: 480px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadiusFetch('primary')};
  border: ${({ state }) => (state === TableCustomComponentState.EDIT ? '1px solid' : 'none')};
  border-color: ${colorFetch('gray3')};
  overflow: hidden;
  transition: all 0.2s linear;
  ${media.tablet`
    max-width: 100%;
    width: 100%;
  `}
  ${media.phone`
    height: 100%;
  `}
  &.disabled {
    background-color: ${colorFetch('gray5')};
  }
`;

const WrapperTitleMetaDocuments = styled.div`
  padding: 12px 20px;
  display: grid;
  grid-template-columns: 3fr 1fr 20px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-family: Poppins;
  border-bottom: 1px solid;
  color: ${colorFetch('gray1')};
  border-color: ${colorFetch('gray3')};
`;

type MetaDataHistoryProps = {
  isFilePicked: boolean;
};

const MetaDataHistory = styled.div<MetaDataHistoryProps>`
  box-sizing: border-box;
  overflow: overlay;
  flex: 1;
  padding: ${({ isFilePicked }) => (isFilePicked ? '2px 4px 2px 20px' : '0')};
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: 9px;
    width: 4px;
  }
`;

type MetaDataContentProps = {
  height: number;
  isActive: boolean;
};

const MetaDataContent = styled.div<MetaDataContentProps>`
  height: ${({ height }) => `${height}px`};
  box-sizing: border-box;
  padding: 0 14px 0 0;
  display: flex;
  flex-direction: column-reverse;
  max-height: 218px;
  overflow: ${({ isActive }) => (isActive ? 'auto' : 'hidden')};
  transition: all 0.3s linear;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
    padding-left: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: 9px;
    width: 4px;
  }
`;

const UploadInput = styled.input`
  display: none;
  &::-webkit-file-upload-button {
    display: none;
  }
`;

const Label = styled.label`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 12px;
  background-color: ${colorFetch('gray5')};
  color: ${colorFetch('gray2')};
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: Poppins;
  border-top: 1px solid;
  border-color: ${colorFetch('gray3')};
  transition: all 0.3s linear;
  &:hover {
    background-color: ${colorFetch('gray4')};
  }
  &:active {
    background-color: ${colorFetch('gray4')};
  }
  &.disabled {
    background-color: ${colorFetch('gray4')};
    cursor: initial;
  }
` as any; // @TODO fix type

export interface MetaDocumentsComponentProps {
  values: any;
  state?: TableCustomComponentState;
  setValue: (value: any) => void;
  isActive?: boolean;
}

export const MetaDocuments: FC<MetaDocumentsComponentProps> = ({ values, state, setValue, isActive }) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const [{ isEditInProcess, isAddInProcess }] = useTableContext();
  const timestampPart = uuidv1();
  const randomPart = uuidv4();
  const isEdit = state === TableCustomComponentState.EDIT;
  const [deleteItem, setDeleteItem] = useState(false);
  const language = useSelector(selectLanguageStrings);

  const bytesToSize = (bytes: any) => {
    const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb'];
    if (bytes === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)) as any, 10);
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  };

  const initialObject = {} as any;
  // @TODO need improve it later.
  const preparedData = !!values?.oldItems
    ? values?.oldItems?.concat(values?.newItems).map((item: any, i: number) => {
        const key = item.name;
        initialObject[key] = bytesToSize(item.size);
        const itemDocument = { [item.name]: bytesToSize(item.size) };
        return itemDocument;
      })
    : values.length
    ? values.map((item: any, i: number) => {
        const key = item.name;
        initialObject[key] = bytesToSize(item.size);
        const itemDocument = { [item.name]: bytesToSize(item.size) };
        return itemDocument;
      })
    : [];

  const initialValues = !!initialObject
    ? Object.keys(initialObject).map((key, index) => {
        return { key, value: initialObject[key], inner_index: index + timestampPart + randomPart };
      })
    : [];
  const [metaData, setMetaData] = useState<any>(initialValues);
  const [filesArray, setFilesArray] = useState<any>({
    oldItems: values.oldItems ? values.oldItems : values,
    newItems: [],
  });

  const handleChangeUloadInput = (e: any) => {
    const newItems = filesArray?.newItems || [];
    for (let file of e.target.files) {
      newItems.push(file);
    }
    setFilesArray((val: any) => ({ ...val, newItems }));

    const fileName = e?.target?.files[0]?.name;
    const fileSize = e?.target?.files[0]?.size;
    fileSize &&
      setMetaData((meta: any[]) => [
        {
          key: fileName,
          value: bytesToSize(fileSize),
          inner_index: meta.length + timestampPart + randomPart,
        },
        ...meta,
      ]);
  };

  const handleDeleteRowMetaData = useCallback(
    (index: number) => {
      const deletedItem = metaData?.filter((item: any, itemIndex: any) => {
        if (itemIndex === index) {
          return item;
        }
      });
      const newDeletedItem = filesArray?.newItems?.filter((newItem: any) => {
        if (deletedItem[0].key === newItem.name) return true;
      });
      const newMeta = [...metaData.slice(0, index), ...metaData.slice(index + 1)];
      const newFilesArray =
        state === TableCustomComponentState.EDIT
          ? newDeletedItem?.length
            ? [...filesArray?.newItems?.reverse().slice(0, index), ...filesArray?.newItems?.slice(index + 1)]
            : [...filesArray?.oldItems?.slice(0, index - metaData.length), ...filesArray?.oldItems?.slice(index + 1)]
          : [...metaData.slice(0, index), ...metaData.slice(index + 1)];
      setFilesArray &&
        setFilesArray(
          state === TableCustomComponentState.EDIT
            ? newDeletedItem?.length
              ? { oldItems: [...filesArray?.oldItems], newItems: newFilesArray }
              : { oldItems: newFilesArray, newItems: [...filesArray?.newItems] }
            : newFilesArray,
        );
      if (isEditInProcess || isAddInProcess) {
        setMetaData(newMeta);
      }
    },
    [setValue, metaData],
  );

  useEffect(() => {
    setValue(filesArray);
  }, [metaData]);

  useEffect(() => {
    if (!isEdit && filesArray.newItems) {
      setMetaData(initialValues);
    }
  }, [isEdit]);

  return (
    <WrapperMetaDataContainer>
      <MetaDataContainer state={state}>
        <WrapperTitleMetaDocuments>
          <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'}>
            {language ? language.externalDocumentName : 'Name'}
          </Text>
          <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'400'}>
            {language ? language.externalDocumentSize : 'Size'}
          </Text>
        </WrapperTitleMetaDocuments>
        <MetaDataHistory isFilePicked={!!metaData.length}>
          {metaData.length > 0 ? (
            <MetaDataContent height={metaData.length * 55} isActive={isEdit}>
              {metaData.map((el: any, index: number) => (
                <ItemMetaDocuments
                  key={index}
                  isFirst={index === 0}
                  propertyMetaData={el.key}
                  setPropertyMetaData={() => {}}
                  valueMetaData={el.value}
                  handleDeleteRowMetaData={() => handleDeleteRowMetaData(index)}
                  setValueMetaData={() => {}}
                  state={state}
                />
              ))}
            </MetaDataContent>
          ) : (
            <RowPlaceholder />
          )}
        </MetaDataHistory>
        {state === TableCustomComponentState.EDIT && (
          <div>
            <Label for={'files'}>{language ? language.externalAddDocuments : '+ Add documents'}</Label>
            <UploadInput type={'file'} onChange={handleChangeUloadInput} id={'files'} />
          </div>
        )}
      </MetaDataContainer>
    </WrapperMetaDataContainer>
  );
};
