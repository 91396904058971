/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for Inventories page
 * Highly customisable.
 *
 */

import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { deleteInventoriesGlobal, updateInventories, useGetTypes } from '../../../api/manageInventories';
import { selectAccess, selectLanguageStrings } from '../../../app/state/userSlice';
import { ReactComponent as LocationImage } from '../../../assets/locationGray.svg';
import { API_URL } from '../../../consts';
import { EXTRA_KEY, useTableFunctions } from '../../../features/universalTable/context';
import {
  ActionType,
  InlineActionType,
  RowItemType,
  TableCustomComponentState,
} from '../../../features/universalTable/types';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import { View } from '../../../routes/routeInterfaces';
import { media } from '../../../styles/media';
import { useMediaType } from '../../../styles/style.context';
import { useCustomHistory } from '../../../utils/react-router-dom-abstraction';
import { ManageTitle } from '../components/manageTitle/ManageTitle';
import { MetaDataComponent } from '../processes/components/metaDataComponent/MetaDataComponent';
import { useInventoryTypes } from './useInventoryTypes';
import { Tracker } from '../../../components/tracker/Tracker';
import { useQueryParams } from '../../../utils/useQueryParams';
import { stringifyUrl } from 'query-string';
import { colorFetch } from '../../../styles/utils';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  ${media.tablet`
    padding: 60px 0 10px 0;
  `}
`;

const LocationButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LocationButton = styled(LocationImage)`
  width: 24px;
  height: 24px;
  margin-right: 14px;
  cursor: pointer;
  & > path {
    stroke: ${colorFetch('gray2')};
    transition: stroke 0.3s ease;
  }
  &:hover > path {
    stroke: ${colorFetch('gray3')};
  }
`;

export interface InventoriesProps {}

export const Inventories: React.FC<InventoriesProps> = () => {
  const { startAddProcess, setSearchValue, getSearchValue, resetTable } = useTableFunctions();
  const accessData = useSelector(selectAccess)?.management?.inventory;
  const { actual: searchValue } = getSearchValue();
  const mediaType = useMediaType();
  const [types, setTypes] = useGetTypes();
  const params = useParams();
  const keyword = 'InventoriesPropsTable';
  const offsetKey = 'offset';

  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const queryParams = useQueryParams() || {};
  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };
  const queryTransformed = stringifyUrl({ url: '', query: queryParams }) + '&';
  const { state }: any = useLocation();
  const customHistory = useCustomHistory();

  const { handleCreateInventoryType, handleDeleteInventoryType, handleUpdateInventoryType } = useInventoryTypes(
    setTypes,
  );

  const languageStrings = useSelector(selectLanguageStrings);
  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  return (
    <Wrapper>
      <ManageTitle
        keyword={keyword}
        title={languageStrings ? languageStrings.titleOfInventories : 'Inventories'}
        addButtonValue={languageStrings ? languageStrings.addButton : 'Add'}
        searchInputValue={searchValue}
        addButtonOnClick={startAddProcess}
        accessData={accessData}
        handleChangeSearchInputValue={setSearchValue}
      />
      <UniversalTable
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !mediaType.tablet, countRows: mediaType.phone ? 6 : 12 },
        }}
        offset={+offset}
        offsetKey={offsetKey}
        tablePath={View.MANAGE_INVENTORIES}
        updateItems={updateInventories}
        deleteItems={deleteInventoriesGlobal}
        allowDelete
        accessData={accessData}
        additionalKeywords={['ManageLayoutSearch']}
        actionSet={[{ type: ActionType.EDIT }]}
        isLocationButton
        inlineActionSet={[
          {
            type: InlineActionType.CUSTOM,
            component: (data: any) => {
              return data?.tracker ? (
                <LocationButtonWrapper>
                  <LocationButton />
                </LocationButtonWrapper>
              ) : null;
            },
            callback: (data: any) => {
              return data?.tracker
                ? customHistory.push(View.USER_INSIGHTS_DETAILS, {
                    pathParams: { id: data?.tracker?.location?.wpas_ref_building_id, name: data?.tracker?.name },
                  })
                : null;
            },
          },
          {
            type: InlineActionType.DETAILS,
          },
        ]}
        idItemForViewModal={state?.state?.id}
        mainApiUrl={
          queryParams.zone || queryParams.inventory_type
            ? `${API_URL}/v1/inventories/filters${queryTransformed}`
            : `${API_URL}/v1/inventories/`
        }
        withoutQuestionMarkBeforeLimit={!!queryParams.zone || !!queryParams.inventory_type}
        keyword={keyword}
        sortingData={sortingData}
        sortingKeys={sortingKeys}
        pathParams={params}
        queryParams={queryParams}
        pageSize={mediaType.phone ? 6 : 18}
        isExport={accessData?.view}
        isAccess={accessData?.edit}
        columnStructure={[
          {
            fieldName: 'name',
            placeholder: languageStrings ? languageStrings.enterNamePlaceholder : 'Enter name',
            label: languageStrings ? languageStrings.labelName : 'Name',
            defaultValue: '',
          },
          {
            fieldName: 'inventory_type',
            type: RowItemType.JOINED_SELECT,
            placeholder: languageStrings ? languageStrings.chooseTypePlaceholder : 'Choose type',
            label: languageStrings ? languageStrings.labelType : 'Type',
            getTextDisplay: (type: any) => type?.name,
            defaultValue: { name: '', id: '' },
            selectItems: types,
            createItem: handleCreateInventoryType,
            updateItem: handleUpdateInventoryType,
            deleteItem: handleDeleteInventoryType,
            isCreatePlaceholderSearchBar: true,
          },
          {
            fieldName: 'tracker',
            placeholder: languageStrings ? languageStrings.enterLocationPlaceholder : 'Enter location',
            label: languageStrings ? languageStrings.labelLocation : 'Location',
            getTextDisplay: (tracker: any) =>
              tracker?.location ? `${tracker?.location?.address?.city}, ${tracker?.location?.address?.country}` : 'N/A',
            excludedFromAdd: true,
            defaultValue: '',
            disabled: true,
          },
          {
            fieldName: 'tracker',
            placeholder: languageStrings ? languageStrings.enterZonePlaceholder : 'Enter zone',
            label: languageStrings ? languageStrings.labelZone : 'Zone',
            getTextDisplay: (tracker: any) => tracker?.location?.zone || 'N/A',
            excludedFromAdd: true,
            defaultValue: '',
            disabled: true,
          },
          {
            fieldName: 'tracker',
            placeholder: '',
            label: languageStrings ? languageStrings.labelAttachedTo : 'Attached to',
            defaultValue: { name: '', id: '' },
            type: RowItemType.CUSTOM,
            pathToSelectItems: [`${EXTRA_KEY}available`, 'devices'],
            customComponentHasSelectView: true,
            conditionForValidationInCustom: 'not valid',
            component: (value: any, state?: TableCustomComponentState, setValue?: any, values?: any) => {
              return (
                <Tracker
                  state={state}
                  setValue={setValue}
                  value={value}
                  values={values}
                  defaultSelectValue={languageStrings ? languageStrings.notAttachedPlaceholder : 'Not attached'}
                  disabled={state === TableCustomComponentState.VIEW_MODAL}
                  language={languageStrings}
                />
              );
            },
          },
          {
            fieldName: 'metadata',
            placeholder: '',
            label: languageStrings ? languageStrings.labelMetadata : 'Metadata',
            defaultValue: {},
            modalColumns: 2,
            type: RowItemType.CUSTOM,
            excludedFromView: true,
            // hide title component for View_Modal in mobile version
            excludedTitleFromMobileViewEmptyData: true,
            conditionForValidationInCustom: 'not valid',
            component: (value: any, state?: TableCustomComponentState, setValue?: any) => (
              <MetaDataComponent value={value} state={state} setValue={setValue} />
            ),
          },
        ]}
      />
    </Wrapper>
  );
};
