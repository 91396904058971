import React from 'react';
import ReactDOM from 'react-dom';

import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { routes } from './routes';

import { BrowserRouter } from 'react-router-dom';
import { StyleContextProvider as UniversalStyleContextProvider, themeClasp } from '@cthings.co/styles';
import { LoaderContextProvider as UniversalLoaderContextProvider } from '@cthings.co/core';
import { NotifierProvider as UniversalNotifierProvider } from '@cthings.co/universal-table';
import {
  NavigationContextProvider as UniversalNavigationContextProvider,
  TooltipContextProvider as UniversalTooltipContextProvider,
} from '@cthings.co/utils';
import './styles/global.css';
// @ts-ignore
import { TooltipContextProvider } from './components/tooltip/context';
import { StyleContextProvider } from './styles/style.context';
import { LoaderContextProvider } from './features/placeholderComponent/loader.context';
import { NotifierProvider } from './features/notifier2';
import { Helmet } from 'react-helmet';
import { isClasp } from './consts';
import appleTouchIcon from './styles/favicons/clasp/apple-touch-icon.png';
import favicon32 from './styles/favicons/clasp/favicon-32x32.png';
import favicon16 from './styles/favicons/clasp/favicon-16x16.png';
import maskIcon from './styles/favicons/clasp/safari-pinned-tab.svg';
//@ts-ignore
import manifest from './styles/favicons/clasp/site.webmanifest';

import appleTouchIconValmont from './styles/favicons/valmont/apple-touch-icon.png';
import favicon32Valmont from './styles/favicons/valmont/favicon-32x32.png';
import favicon16Valmont from './styles/favicons/valmont/favicon-16x16.png';

//@ts-ignore
import manifestValmont from './styles/favicons/valmont/site.webmanifest';
import { NavigationContextProvider } from './features/navigation/components/navigation.context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GlobalNotification } from './components/globalNotification/GlobalNotification';
import { renderRoutes } from './utils/renderRoutes';

const queryClient = new QueryClient();

const ClaspMeta = () => {
  return (
    <>
      {isClasp ? (
        <Helmet>
          <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
          <link rel="mask-icon" href={maskIcon} color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#00aba9" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="description" content="Clasp device platform" />
          <link rel="manifest" href={manifest} />
          <title>Clasp</title>
        </Helmet>
      ) : (
        <Helmet>
          <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIconValmont} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32Valmont} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16Valmont} />
          <meta name="msapplication-TileColor" content="#333333" />
          <meta name="theme-color" content="#2e303a"></meta>
          <meta name="description" content="Valmont device platform" />
          <link rel="manifest" href={manifestValmont} />
          <title>Valmont</title>
        </Helmet>
      )}
    </>
  );
};

ReactDOM.render(
  <UniversalStyleContextProvider theme={themeClasp}>
    <BrowserRouter>
      <UniversalNavigationContextProvider>
        <UniversalTooltipContextProvider>
          <TooltipContextProvider>
            <UniversalLoaderContextProvider>
              <LoaderContextProvider>
                <UniversalNotifierProvider>
                  <StyleContextProvider>
                    <NavigationContextProvider>
                      <Provider store={store}>
                        <NotifierProvider>
                          <QueryClientProvider client={queryClient}>
                            <>
                              <GlobalNotification />
                              {renderRoutes(routes as any, 0)}
                            </>
                          </QueryClientProvider>
                        </NotifierProvider>
                        <ClaspMeta />
                      </Provider>
                    </NavigationContextProvider>
                  </StyleContextProvider>
                </UniversalNotifierProvider>
              </LoaderContextProvider>
            </UniversalLoaderContextProvider>
          </TooltipContextProvider>
        </UniversalTooltipContextProvider>
      </UniversalNavigationContextProvider>
    </BrowserRouter>
  </UniversalStyleContextProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
