/**
 * @description
 * This is an advanced component that covers
 * all the features that cthings details buttons require.
 * Highly customisable.
 *
 */

import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: transparent;
  background-color: transparent;
  border-radius: ${borderRadiusFetch('primary')};
  padding: 4px 18px;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colorFetch('gray2')};
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.2px;
  user-select: none;
  transition: all 0.3s linear;
  max-width: 100px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  &:hover {
    background-color: ${colorFetch('gray2')};
    color: ${colorFetch('pureWhite')};
    border-color: ${colorFetch('gray2')};
  }
`;

const ButtonText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export interface DeleteButtonProps {
  onClick: any;
  className?: string;
  children?: ReactNode;
}

export const DetailsButton: FC<DeleteButtonProps> = ({ onClick, children, ...props }) => {
  const language = useSelector(selectLanguageStrings);

  return (
    <Button onClick={onClick} {...props}>
      <ButtonText>{children || language ? language.detailsButton : 'Details'}</ButtonText>
    </Button>
  );
};
