import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { Text, TextType } from '@bit/first-scope.text';
import { useCustomHistory } from '../../../../../utils/react-router-dom-abstraction';
import { View } from '../../../../../routes/routeInterfaces';
import { DropdownMultiChoice } from '../dropdownMultiChoice/DropdownMultiChoice';
import { useExternalLinks } from '../../../../../api/externalDocuments';
import { useTheme } from '@cthings.co/styles';

const StyledText = styled(Text)`
  &.link {
    text-decoration: underline;
    cursor: pointer;
  }
` as any; // @TODO fix type

export const LinksMultiSelect = ({
  setValue,
  stateIndex,
  value = [],
  language,
}: {
  setValue: any;
  stateIndex: TableCustomComponentState | undefined;
  value: any;
  language?: any;
}) => {
  const theme = useTheme();
  const { gray1, gray2 } = theme.colors;
  const customHistory = useCustomHistory();
  const [localDocuments, setLocalDocuments] = useState([]);
  const links = useExternalLinks();
  const selectedIdsFromBackend = value ? value.map((index: any) => index?.id) : [];
  const [selectedIndexIds, setSelectedIndexIds] = useState<any[]>(selectedIdsFromBackend);
  const [isIndexListOpen, setIsIndexListOpen] = useState(false);
  const wrapperRef = useRef<any>(null);
  const noIndexSelected = selectedIndexIds.length === 0;

  useEffect(() => {
    if (links) {
      setLocalDocuments(links);
    }
  }, [links]);

  const checkIndex = (id: string) => {
    setSelectedIndexIds((selectedIds) => {
      if (selectedIds.includes(id)) {
        const index = selectedIds.indexOf(id);
        const result = [...selectedIds.slice(0, index), ...selectedIds.slice(index + 1, selectedIds.length)];
        setValue(result);
        return result;
      } else {
        const result = Array.from(new Set([...selectedIds, id]));
        setValue(result);
        return result;
      }
    });
  };

  const selectAllIndex = () => {
    setSelectedIndexIds(
      localDocuments.length === selectedIndexIds.length ? [] : localDocuments.map((index: any) => index.id),
    );
  };

  const handleRemoveSelectedIndex = (id: string) => {
    setSelectedIndexIds((selectedIds) => {
      const index = selectedIds.indexOf(id);
      return [...selectedIds.slice(0, index), ...selectedIds.slice(index + 1, selectedIds.length)];
    });
  };

  const statefulIndex = localDocuments.map((index: any) => ({
    ...index,
    isSelected: selectedIndexIds.findIndex((selectedId) => selectedId === index.id) !== -1,
  }));

  return (
    <>
      {stateIndex === TableCustomComponentState.VIEW_MODAL || stateIndex === TableCustomComponentState.EDIT ? (
        <DropdownMultiChoice
          parameters={statefulIndex}
          options={{
            placeholder: language.multipleSelectLinksPlaceholder || 'Choose link(s)',
            title: 'Links',
            isValidatingStarted: false,
            isError: noIndexSelected,
            isOpenSelectList: isIndexListOpen,
            popUpScrollTo: 244,
            popUpScrollToWithCustomPeriod: 288,
            wrapperRef,
            isDisabled: !(stateIndex === TableCustomComponentState.EDIT),
            disabledPlaceholder: language.multipleSelectLinksPlaceholder || 'Choose link(s)',
          }}
          handleCheckedItem={(id: string) => {
            checkIndex(id);
          }}
          handleRemoveSelectedItem={({ id }: { id: string }) => {
            handleRemoveSelectedIndex(id);
          }}
          handleSelectAll={() => {
            selectAllIndex();
          }}
          setIsOpenSelectList={(value: any) => {
            setIsIndexListOpen(!!value);
          }}
          isEditInProcess={stateIndex === TableCustomComponentState.EDIT}
          languageStrings={{}}
          setValue={setValue}
        />
      ) : (
        <>
          {value ? (
            <StyledText
              type={TextType.TEXT_14_GRAY}
              color={gray1}
              weight={'400'}
              className={'link'}
              onClick={() => {
                customHistory.push(View.MANAGE_COMPONENTS, {
                  pathParams: { offset: 0 },
                  state: { id: value?.id },
                });
              }}
            >
              {value?.name}
            </StyledText>
          ) : (
            <StyledText type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'}>
              {'N/A'}
            </StyledText>
          )}
        </>
      )}
    </>
  );
};
