import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../../../../../../components/modal/Modal';
import { TextType, Text } from '@bit/first-scope.text';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { CloseWhiteRoundButton } from '../../../../../../components/closeWhiteRoundButton/CloseWhiteRoundButton';
import { media } from '../../../../../../styles/media';
import { useMediaType } from '../../../../../../styles/style.context';
import { Select } from '../../../../../../components/select/Select';
import { CommonButton } from '../../../../../../components/commonButton/CommonButton';
import { InputModal } from '../../../../../../components/inputModal/InputModal';
import { useBlockBodyScroll } from '../../../../../../utils/useBlockBodyScroll';
import { hexToRGBA, useTheme } from '@cthings.co/styles';
import { isClasp, isTablet } from '../../../../../../consts';
import { displayViewType } from '../../../../../../features/universalTable/components/modalSystem/ModalSystem';
import ls from '../../../../../../utils/ls';

const WrapperModal = styled(Modal)`
  ${media.tablet`
    & > div {
      width: 100%;
      padding: 0;
    }
  `}

  &.tablet {
    align-items: start;
    justify-content: start;
    padding: 40px;

    & > div {
      width: auto;
    }
  }
`;

const Wrapper = styled(ModalContainer)`
  align-items: flex-start;
  padding: 24px;
  ${media.tablet`
    width: 100%;
    padding: 24px 16px;
    border-radius: 16px 16px 0 0;
  `}
`;

const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
`;

const StyledText = styled(Text)`
  font-size: 18px;
  margin-bottom: 14px;
  ${media.tablet`
    margin-bottom: 4px;
  `}
`;

const ItemsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  ${media.phone`
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  `}
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSelect = styled(Select)`
  width: 230px;
  margin: 0 0 12px 0;
  & > div {
    min-height: 40px;
  }
  ${media.tablet`
      width: 100%;   
  `};
`;

const CancelButton = styled(CommonButton)`
  width: 104px;
  height: 32px;
  margin: 10px 0 0 15px;
  ${media.tablet`
      width: 100%;
      margin: 4px 0;
  `}
`;

const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  ${media.tablet`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
    margin-top: 8px;
  `}
  ${media.phone`
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  `}
`;

const StyledInput = styled(InputModal)`
  width: 230px;
  ${media.tablet`
      width: 100%;
      margin-bottom: 4px;
  `}
`;

const ViewportSwitcher = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
`;

export enum RowItemType {
  SELECT = 'SELECT',
  ADDRESS = 'ADDRESS',
  CUSTOM = 'CUSTOM',
  INPUT = 'INPUT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  INPUT_PHONE_NUMBER = 'INPUT_PHONE_NUMBER',
  JOINED_SELECT = 'JOINED_SELECT',
}

export const AttachInventoryModal = ({
  attachedToInventory,
  inventoryArr,
  handleAttachInventoryApi,
  handleCloseInventoriesModal,
  dataAttachInventory,
  languageStrings,
  validationIsTarted,
}: {
  attachedToInventory: boolean;
  inventoryArr: any;
  handleAttachInventoryApi: any;
  handleCloseInventoriesModal: any;
  dataAttachInventory: any;
  validationIsTarted: boolean;
  languageStrings?: any;
}) => {
  const mediaType = useMediaType();
  const theme = useTheme();
  const { pureWhite, pureBlack, primary } = theme.colors;
  const prepBlack = hexToRGBA(pureBlack, 0.6);
  const isValmontTabletView = !isClasp && isTablet;
  const getDisplayView = ls.get('displayView');
  const [displayView, setDisplayView] = useState(getDisplayView || displayViewType.DESKTOP_VIEW);

  useBlockBodyScroll(attachedToInventory);

  const changeDisplayViewHandle = useCallback(() => {
    if (displayView === displayViewType.DESKTOP_VIEW) {
      setDisplayView(displayViewType.TABLET_VIEW);
      ls.set('displayView', displayViewType.TABLET_VIEW);
    } else {
      setDisplayView(displayViewType.DESKTOP_VIEW);
      ls.set('displayView', displayViewType.DESKTOP_VIEW);
    }
  }, [displayView]);

  useEffect(() => {
    !!getDisplayView && setDisplayView(getDisplayView);
  }, [getDisplayView]);

  useEffect(() => {
    if (!isValmontTabletView) {
      ls.remove('displayView');
      setDisplayView(displayViewType.DESKTOP_VIEW);
    }
  }, [isValmontTabletView]);

  return (
    <>
      <WrapperModal
        isOpenModal={attachedToInventory}
        className={displayView === displayViewType.TABLET_VIEW ? 'tablet' : ''}
      >
        <Wrapper width="max-content">
          <WrapperTitle>
            <StyledText type={TextType.TITLE_PRIMARY}>{languageStrings.AttachedToInventoryLabel}</StyledText>
            {mediaType.tablet && !isValmontTabletView && (
              <CloseWhiteRoundButton onClick={handleCloseInventoriesModal} />
            )}
            {isValmontTabletView && (
              <ViewportSwitcher
                // @ts-ignore
                onClick={changeDisplayViewHandle}
                type={TextType.TEXT_14_GRAY}
                color={theme.colors.primary}
              >
                {displayView === displayViewType.DESKTOP_VIEW
                  ? languageStrings.tabletView
                  : languageStrings.desktopView}
              </ViewportSwitcher>
            )}
          </WrapperTitle>
          <ItemsWrapper>
            {inventoryArr.map((item: any, index: number) => {
              const { name, fieldName, value, disabled, type, onChange, values, placeholder, isError } = item;

              if (type === RowItemType.JOINED_SELECT) {
                return (
                  <SelectWrapper>
                    <StyledSelect
                      value={value}
                      label={name}
                      disabled={disabled}
                      placeholder={placeholder}
                      values={values}
                      onChange={(value: any) => onChange(value, fieldName)}
                      isError={isError}
                      startedValidation={validationIsTarted}
                      positionTop={mediaType.tablet ? '-284px' : '62px'}
                    />
                  </SelectWrapper>
                );
              }
              if (type === RowItemType.INPUT || type === RowItemType.INPUT_NUMBER) {
                return (
                  <StyledInput
                    key={index}
                    name={name}
                    value={value}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={disabled ? () => {} : onChange}
                    type={type === RowItemType.INPUT ? 'text' : 'number'}
                    isError={isError}
                    fromAttach
                  />
                );
              }
            })}
          </ItemsWrapper>
          <WrapperButtons>
            <CancelButton
              onClick={handleCloseInventoriesModal}
              backgroundColor={pureWhite}
              borderColor={primary}
              color={prepBlack}
            >
              {languageStrings ? languageStrings.cancelButton : 'Cancel'}
            </CancelButton>
            <CancelButton onClick={handleAttachInventoryApi} backgroundColor={primary} borderColor={primary}>
              {languageStrings ? languageStrings.attachButton : 'Attach'}
            </CancelButton>
          </WrapperButtons>
        </Wrapper>
      </WrapperModal>
    </>
  );
};
