import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DeleteButton } from '../../../../../../../components/deleteButton/DeleteButton';
import { RowItemType } from '../../../../../../../features/universalTable/types';
import { InputModal } from '../../../../../../../components/inputModal/InputModal';
import { Select } from '../../../../../../../components/select/Select';
import { useGetComponents } from '../../../../../../../api/manageOrders';
import { FieldsType, isValidField, onlyPositiveNumber } from '../../../../../../../utils/validation';
import { throttle } from '../../../../../../../utils/throttling';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../app/state/userSlice';
import { colorFetch } from '../../../../../../../styles/utils';

const WrapperComments = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  /* overflow: auto; */
`;

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, 1fr) 20px;
  align-items: center;
  padding: 0 22px;
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray3')};
`;

type StyledSelectProps = {
  isEdit?: boolean;
  isValue?: boolean;
  top?: number;
  left?: number;
  displayElem: boolean;
};

const StyledSelect = styled(Select)<StyledSelectProps>`
  margin: 0;
  width: 100%;
  max-height: ${({ isEdit }) => (isEdit ? '72' : '112')}px;
  font-family: 'Poppins', sans-serif;
  font-weight: ${({ isValue }) => (isValue ? '500' : 'normal')};
  font-size: 14px;
  line-height: 24px;
  background-color: transparent;
  border: none;
  outline: transparent;
  resize: none;
  box-sizing: border-box;
  color: ${colorFetch('gray1')};
  position: relative;
  & > .borderless {
    padding: 6px 25px 6px 0;
    margin-top: 4px;
    max-width: 160px;
  }
  & > div:nth-child(2) {
    position: fixed;
    top: ${({ top }) => (top ? top : 0)}px;
    left: ${({ left }) => (left ? left : 0)}px;
    transition: ${({ opened }) => (opened ? 'top 0.4s ease' : 'top 0.4s ease')};
    max-height: 240px;
    width: 180px;
    box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04);
    display: ${({ displayElem, opened }) => (displayElem && opened ? 'block' : 'none')};
    & > div {
      width: 100%;
      & ul {
        max-height: 240px;
      }
    }
  }
  &.red {
    & > div {
      & > span {
        color: ${colorFetch('red3')} !important;
        -webkit-text-fill-color: ${colorFetch('red3')};
      }
    }
  }
`;

type StyledInputProps = {
  isEdit?: boolean;
  isValue?: boolean;
};

const StyledInput = styled(InputModal)<StyledInputProps>`
  margin: 0;
  width: 100%;
  max-height: ${({ isEdit }) => (isEdit ? '72' : '112')}px;
  overflow: overlay;
  font-family: 'Poppins', sans-serif;
  font-weight: ${({ isValue }) => (isValue ? '500' : 'normal')};
  font-size: 14px;
  line-height: 24px;
  background-color: transparent;
  border: none;
  outline: transparent;
  resize: none;
  box-sizing: border-box;
  color: ${colorFetch('gray1')};
  & > div {
    & > input {
      border: none;
      padding: 8px 15px 8px 0;
      &.disabled {
        background-color: transparent !important;
        &::placeholder {
          -webkit-text-fill-color: ${colorFetch('gray2')};
        }
      }
    }
  }
  &::placeholder {
    color: ${colorFetch('gray2')};
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
  }
  &.red {
    & > div {
      & > input {
        color: ${colorFetch('red3')} !important;
        &.disabled {
          color: ${colorFetch('red3')} !important;
          &::placeholder {
            -webkit-text-fill-color: ${colorFetch('red3')};
          }
        }
        &::placeholder {
          color: ${colorFetch('red3')} !important;
        }
      }
    }
  }
`;

export interface ItemComponentProps {
  component: {
    component_id: string;
    components_list: { id: string; name: string; index: string }[];
    component_value: { id: string; name: string; index: string };
    index: string;
    productionId: string;
    quantity: number;
  };
  setComponents: (el: any) => void;
  handleDeleteRowComponent: any;
  mainNode: any;
  mainNodeDimenstions: { height: number; top: number };
  setIsComponentFieldsValid: (value: boolean) => void;
  setIsEditingInProcess: (value: boolean) => void;
  setAreAllComponentFieldsValid: (value: boolean) => void;
  isEditingInProcess: boolean;
  validatingIsStarted: boolean;
}

export const ItemComponent: FC<ItemComponentProps> = ({
  component,
  setComponents,
  handleDeleteRowComponent,
  mainNodeDimenstions,
  mainNode,
  setIsComponentFieldsValid,
  setIsEditingInProcess,
  setAreAllComponentFieldsValid,
  isEditingInProcess,
  validatingIsStarted,
}) => {
  const languageStrings = useSelector(selectLanguageStrings);

  const requiredFieldText = languageStrings?.requiredFieldPlaceholder || 'Required field...';

  const [node, setNode] = useState<HTMLElement | null>(null);
  const [top, setTop] = useState(0);
  const [opened, setOpened] = useState(false);
  const [displayElem, setDisplayElem] = useState(true);

  const componentList: any[] = useGetComponents();

  useEffect(() => {
    setComponents((components: any) =>
      components.map((elem: any) =>
        elem.component_id === component.component_id ? { ...elem, component_list: componentList } : elem,
      ),
    );
  }, [component.component_id, componentList]);

  const setName = (value: any) => {
    setIsEditingInProcess(true);
    setComponents((components: any) =>
      components.map((elem: any) =>
        elem.component_id === component.component_id ? { ...elem, component_value: value, index: value.index } : elem,
      ),
    );
  };

  const setProductionID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditingInProcess(true);
    const newProductionID = e.currentTarget.value;
    setComponents((components: any) =>
      components.map((elem: any) =>
        elem.component_id === component.component_id ? { ...elem, productionId: newProductionID } : elem,
      ),
    );
  };

  const setQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditingInProcess(true);
    const newQuantity = e.currentTarget.value;
    setComponents((components: any) =>
      components.map((elem: any) =>
        elem.component_id === component.component_id ? { ...elem, quantity: newQuantity } : elem,
      ),
    );
  };

  const componentField: any = [
    {
      type: RowItemType.JOINED_SELECT,
      key: 'name',
      placeholder:
        validatingIsStarted && !component.component_value.name
          ? requiredFieldText
          : languageStrings?.manualAddOrderComponentsNamePlaceholder || 'Choose name',
      values: componentList.map((item: any) => {
        const { id, name, index } = item;
        return { id, name, index };
      }),
      value: component.component_value ?? '',
      onChange: setName,
      isFieldEmpty: !isValidField(component.component_value.name, FieldsType.SELECT),
    },
    {
      type: RowItemType.INPUT,
      key: 'index',
      placeholder:
        validatingIsStarted && !component.index
          ? requiredFieldText
          : languageStrings?.manualAddOrderComponentsIndexPlaceholder || 'First choose name',
      value: component.index ?? '',
      disabled: true,
      isFieldEmpty: !isValidField(component.index, FieldsType.TEXT),
    },
    {
      type: RowItemType.INPUT,
      key: 'productionId',
      placeholder:
        validatingIsStarted && !component.productionId
          ? requiredFieldText
          : languageStrings?.manualAddOrderComponentsProductionIDPlaceholder || ' Enter Production ID',
      value: component.productionId ?? '',
      onChange: setProductionID,
      isFieldEmpty: !isValidField(component.productionId, FieldsType.TEXT),
    },
    {
      type: RowItemType.INPUT_NUMBER,
      key: 'quantity',
      placeholder:
        validatingIsStarted && !component.quantity
          ? requiredFieldText
          : languageStrings?.manualAddOrderComponentsQuantityPlaceholder || 'Enter quantity',
      value: component.quantity ?? '',
      onChange: setQuantity,
      isFieldEmpty: !isValidField(component.quantity + '', FieldsType.NUMBER, onlyPositiveNumber),
    },
  ];

  const isAllFieldsValid = !componentField.filter((item: any) => item.isFieldEmpty).length;

  useEffect(() => {
    setAreAllComponentFieldsValid(isAllFieldsValid);
    setIsComponentFieldsValid(isAllFieldsValid);
  }, [isAllFieldsValid]);

  const itemContainerRef = useCallback((node: HTMLElement | null) => {
    if (node) {
      setNode(node);
    }
  }, []);

  const itemDimensions = useMemo(() => {
    return node
      ? { height: node.getBoundingClientRect().height - 5, left: node.getBoundingClientRect().left }
      : { height: 0, left: 0 };
  }, [node]);

  const setTopOffset = useCallback(
    throttle((e: Event) => {
      if (node) {
        const currentTop = node.getBoundingClientRect().top;
        const visibleBottom =
          mainNodeDimenstions.height + mainNodeDimenstions.top > currentTop - itemDimensions.height - 5;
        const visibleTop = currentTop - itemDimensions.height - 5 > 0;
        setTop(currentTop);
        setDisplayElem(visibleBottom && visibleTop);
      }
    }, 200),
    [node, mainNodeDimenstions.height, mainNodeDimenstions.top, itemDimensions.height],
  );

  useEffect(() => {
    if (mainNode && opened) {
      mainNode.addEventListener('scroll', setTopOffset);
    }
    return () => {
      if (mainNode && opened) {
        mainNode.removeEventListener('scroll', setTopOffset);
      }
    };
  }, [setTopOffset, opened]);

  const handleClickSelect = () => {
    node && setTop(node.getBoundingClientRect().top);
  };

  const handleOpeningListener = (val: boolean) => setOpened(val);

  const renderComponentsData = (item: any, index: number) => {
    const { value, disabled, type, onChange, values, placeholder, isPendingRequest, isFieldEmpty } = item;

    const renderInput = (type: string) => (
      <StyledInput
        className={isEditingInProcess && isFieldEmpty && 'red'}
        key={index}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={disabled ? () => {} : onChange}
        type={type}
      />
    );

    const data: any = {
      [RowItemType.JOINED_SELECT]: (() => (
        <StyledSelect
          className={isEditingInProcess && isFieldEmpty && 'red'}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          values={values}
          onChange={(value: any) => onChange(value)}
          handleClick={handleClickSelect}
          openingListener={handleOpeningListener}
          isPendingRequest={isPendingRequest}
          borderLess
          isShowSearch={true}
          displayElem={displayElem}
          top={top + itemDimensions.height}
          left={itemDimensions.left}
          opened={opened}
        />
      ))(),
      [RowItemType.INPUT]: (() => renderInput('text'))(),
      [RowItemType.INPUT_NUMBER]: (() => renderInput('number'))(),
    };

    return data[type];
  };

  return (
    <WrapperComments>
      <Container ref={itemContainerRef}>
        {componentField.map((item: any, index: number) => renderComponentsData(item, index))}

        <DeleteButton className="rightIcon" onClick={() => handleDeleteRowComponent(component.component_id)} />
      </Container>
    </WrapperComments>
  );
};
