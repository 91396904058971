import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { TextareaAutosize } from '@material-ui/core';
import { DeleteButton } from '../../../../../components/deleteButton/DeleteButton';
import { isStringConsistOfSpaces } from '../../../../../utils/validation';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { Modal } from '../../../../../components/newModals/Modal';
import { ModalContainer } from '../../../../../components/newModals/ModalContainer';
import { media } from '../../../../../styles/media';
import { DeletePopup } from '../../../../../components/deletePopup/DeletePopup';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { colorFetch } from '../../../../../styles/utils';

const WrapperComments = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

type ContainerProps = {
  isFirst?: boolean;
};

const Container = styled.div<ContainerProps>`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 3fr 1fr 20px;
  padding: 13px 0px;
  border-bottom: ${({ isFirst }) => (isFirst ? 'none' : '1px solid')};
  border-color: ${colorFetch('gray3')};
`;

type CommentTextareaProps = {
  isValue?: boolean;
  isEdit?: boolean;
};

const CommentTextarea = styled(TextareaAutosize)<CommentTextareaProps>`
  margin: 0;
  width: 100%;
  max-height: ${({ isEdit }) => (isEdit ? '72' : '30')}px;
  font-family: 'Poppins', sans-serif;
  font-weight: ${({ isValue }) => (isValue ? '500' : 'normal')};
  font-size: 14px;
  line-height: 24px;
  background-color: transparent;
  border: none;
  outline: transparent;
  resize: none;
  box-sizing: border-box;
  color: ${colorFetch('gray1')};
  font-weight: 400;
  text-overflow: ellipsis;
  &::placeholder {
    color: ${colorFetch('gray2')};
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
  }
  &.red {
    &::placeholder {
      color: ${colorFetch('red3')};
    }
  }
`;

const DeletePopupContainer = styled(ModalContainer)`
  max-width: 384px;
  ${media.tablet`
    max-width: 100%;
  `}
`;

export interface ItemMetaDocumentsProps {
  handleDeleteRowMetaData: any;
  propertyMetaData: any;
  setPropertyMetaData: any;
  valueMetaData: any;
  setValueMetaData: any;
  state?: TableCustomComponentState;
  isFirst?: boolean;
  isStartAddingAnotherRow?: boolean;
}

export const ItemMetaDocuments: FC<ItemMetaDocumentsProps> = ({
  handleDeleteRowMetaData,
  propertyMetaData,
  setPropertyMetaData,
  setValueMetaData,
  valueMetaData,
  isFirst,
  isStartAddingAnotherRow,
  state,
}) => {
  const language = useSelector(selectLanguageStrings);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const openDeleteModal = () => setIsOpenDeleteModal(true);

  const closeDeleteModal = () => setIsOpenDeleteModal(false);

  const handleDeleteRow = () => {
    handleDeleteRowMetaData();
    setIsOpenDeleteModal(false);
  };

  const textHandler = (text: string) => {
    let finishedText = text;
    if (text.length >= 25) {
      finishedText = text.slice(0, 25) + '...';
    }
    return finishedText;
  };

  const getClassName = (metaData: string, isStartAddingAnotherRow: boolean | undefined) =>
    isStringConsistOfSpaces(metaData) || (metaData?.length === 0 && isStartAddingAnotherRow) ? 'red' : '';

  const getPlaceholder = (metaData: string, isStartAddingAnotherRow: boolean | undefined) =>
    isStringConsistOfSpaces(metaData) || (metaData?.length === 0 && isStartAddingAnotherRow)
      ? 'Required field...'
      : 'Enter property';
  return (
    <WrapperComments>
      <Container isFirst={isFirst}>
        <CommentTextarea
          rows={1}
          className={getClassName(propertyMetaData, isStartAddingAnotherRow)}
          value={textHandler(propertyMetaData)}
          placeholder={getPlaceholder(propertyMetaData, isStartAddingAnotherRow)}
          onChange={setPropertyMetaData}
        />
        <CommentTextarea
          rows={1}
          className={getClassName(valueMetaData, isStartAddingAnotherRow)}
          value={textHandler(valueMetaData)}
          placeholder={getPlaceholder(valueMetaData, isStartAddingAnotherRow)}
          onChange={setValueMetaData}
          isValue
        />
        {state === TableCustomComponentState.EDIT && <DeleteButton className="rightIcon" onClick={openDeleteModal} />}
      </Container>
      {isOpenDeleteModal && (
        <Modal isOpenModal={isOpenDeleteModal}>
          <DeletePopupContainer padding="24px">
            <DeletePopup
              title={language ? language.externalMetaDocumentsTitle : 'Are you sure?'}
              semiTitle={
                language ? language.externalMetaDocumentsSemiTitle : 'The document will be permanently deleted.'
              }
              isOpenModal
              handleOnClose={closeDeleteModal}
              handleOnDelete={handleDeleteRow}
            />
          </DeletePopupContainer>
        </Modal>
      )}
    </WrapperComments>
  );
};
