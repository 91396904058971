import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { SmartSearchState } from './smartSearchInterfaces';

export const initialState: SmartSearchState = {
  list: [],
};

export const smartSearchSlice = createSlice({
  name: 'smartSearch',
  initialState,
  reducers: {
    setSmartSearchList: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
  },
});

export const { setSmartSearchList } = smartSearchSlice.actions;

export const selectSmartSearchList = (state: RootState) => {
  return state.smartSearch.list;
};

export default smartSearchSlice.reducer;
