/**
 * @description
 * Typing for functions of clustering
 *
 */

import { PixelPoint } from '../../utils/distance';

export enum OnlineStatusType {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export interface ClusterOptions {
  hasNodes: boolean;
  hasTrackers: boolean;
  hasOffline: boolean;
}
export interface Point {
  name?: string;
  id?: string;
  positioning_role?: number;
  base_role?: number;
  pixel_position: PixelPoint;
  base_role_name?: string;
  positioning_role_name?: string;
  online_status?: OnlineStatusType;
  options?: ClusterOptions;
}

export interface ClusterItem {
  _index: number;
  ids: string[];
  options: ClusterOptions;
}

export interface ClusterProperties {
  isCluster: boolean;
  ids: string[];
  name?: string;
  id?: string;
  positioning_role?: number;
  base_role?: number;
  base_role_name?: string;
  positioning_role_name?: string;
  online_status?: OnlineStatusType;
  options?: ClusterOptions;
}

export interface ClusterPoint {
  isCluster: boolean;
  ids: string[];
  name?: string;
  id?: string;
  positioning_role?: number;
  base_role?: number;
  base_role_name?: string;
  positioning_role_name?: string;
  pixel_position: PixelPoint;
  distances?: any;
  online_status?: OnlineStatusType;
  options?: ClusterOptions;
}
