/**
 * @description
 * This is component that display row of search popup on desctops screens
 * and contain information of searchable item
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { BadgesType } from '../../../components/badges/Badges';
import { Badges } from '../../badges/Badges';
import { InsightBadges } from '../../insightBadges/InsightBadges';
import { Text, TextType } from '@bit/first-scope.text';
import { AddButton } from '../../../components/addButton/AddButton';
import { useMediaType } from '../../../styles/style.context';
import { useCustomHistory } from '../../../utils/react-router-dom-abstraction';
import { View } from '../../../routes/routeInterfaces';
import { borderRadiusFetch, colorFetch } from '../../../styles/utils';
import { useTheme } from '@cthings.co/styles';
import { useSelector } from 'react-redux';
import { selectAccess } from '../../../app/state/userSlice';

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 18px;
  cursor: pointer;
  &:hover > span {
    color: ${colorFetch('primary')};
  }
`;

const LevelLabel = styled(Badges)`
  background-color: transparent;
  & > span {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: ${colorFetch('gray2')};
  }
`;

const BadgesWrapper = styled.div`
  display: flex;
`;

const MapCheap = styled(Text)`
  font-weight: 700;
  color: ${colorFetch('gray2')};
  padding: 2px 10px;
  border: 1px solid;
  border-color: ${colorFetch('gray4')};
  border-radius: ${borderRadiusFetch('additional6')};
  margin-right: 7px;
`;

interface PropsType {
  text: string;
  textBadges: string;
  type: BadgesType;
  isInsightModal: boolean;
  isZonesDetails?: boolean;
  onClick?: (type: string, id: string, offset: number, role?: string, buildingId?: string) => void;
  leadToMap?: boolean;
  leadToAddPackage?: boolean;
  orders_id?: string;
  languageStrings?: any;
}

export const ModalRow: FC<PropsType> = ({
  text,
  textBadges,
  type,
  onClick,
  isInsightModal,
  isZonesDetails,
  orders_id,
  leadToMap,
  leadToAddPackage,
  languageStrings,
}) => {
  const theme = useTheme();
  const { black1 } = theme.colors;
  const mediaType = useMediaType();
  const customHistory = useCustomHistory();
  const accessOrdersData = useSelector(selectAccess).management.orders;

  return (
    <Container onClick={onClick as any /* @TODO fix type */}>
      <Text type={TextType.TEXT_14_BLACK} color={black1}>
        {text}
      </Text>
      {isInsightModal ? (
        <InsightBadges content={textBadges} type={type}></InsightBadges>
      ) : isZonesDetails ? (
        <LevelLabel content={textBadges} type={type} />
      ) : (
        <BadgesWrapper>
          {leadToMap && <MapCheap type={TextType.TEXT_10_GRAY}>MAP</MapCheap>}
          {leadToAddPackage && accessOrdersData.edit && (
            <AddButton
              onClick={(e: any) => {
                e.stopPropagation();
                const to = View.MANAGE_ORDERS_DETAILS;
                to &&
                  customHistory.push(to, {
                    pathParams: { id: orders_id, componentsOffset: 0, packagesOffset: 0, eventsOffset: 0 },
                    // value for display addModal for PackageInfo table from global search
                    state: { isAddModalFromGlobalSearch: true },
                  });
              }}
              title={
                mediaType.tablet
                  ? languageStrings
                    ? languageStrings.addButton
                    : 'Add'
                  : languageStrings
                  ? languageStrings.addButton
                  : 'Add package'
              }
              width={mediaType.tablet ? '72px' : '120px'}
              height="20px"
              marginButton={'0 7px 0 0'}
            />
          )}
          <Badges content={textBadges} type={type}></Badges>
        </BadgesWrapper>
      )}
    </Container>
  );
};
