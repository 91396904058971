import { CombinedRoutes, View } from './routeInterfaces';

//const

const mapping = Object.freeze({
  [View.USER]: { path: '/user' },

  [View.USER_SEARCH]: { path: '/user/search' },
  [View.USER_SITES_REDIRECT]: { path: '/sites' },
  [View.USER_INSIGHTS]: { path: '/sites/insights/:lon/:lat/:zoom' },
  [View.USER_INSIGHTS_DETAILS]: { path: '/sites/insights_details/:id/:name' },
  [View.USER_FLOOR_MAP_DETAILS]: { path: '/sites/floor_map_details/:id' },
  [View.MANAGE_ZONES]: { path: '/sites/zones/:offset' },
  [View.MANAGE_ZONES_DETAILS]: { path: '/sites/zones_details/:building_id/:floor/:zone_id/:offset' },
  [View.USER_DASHBOARD]: { path: '/user/dashboard/:type' },
  [View.USER_DASHBOARD_REDIRECT]: { path: '/user/dashboard' },
  [View.USER_PROBLEMS]: { path: '/user/problems' },

  [View.REDIRECT]: { path: '/' },
  [View.MANAGE]: { path: '/manage' },
  [View.USER_INVENTORIES_REDIRECT]: { path: '/i-manage' },
  [View.MANAGE_INVENTORIES]: { path: '/i-manage/inventories/:offset' },
  [View.USER_DEVICES_REDIRECT]: { path: '/d-manage' },
  [View.MANAGE_DEVICES]: { path: '/d-manage/devices/:offset' },
  [View.MANAGE_DEVICES_DETAILS]: { path: '/d-manage/devices/details/:id' },
  [View.USER_MANAGE_DEVICES_DETAILS_NODE]: {
    path: '/d-manage/devices_details/:buildingId/:id/node/:offset',
    access: [],
  },
  [View.USER_MANAGE_DEVICES_DETAILS_TRACKER]: {
    path: '/d-manage/devices_details/:buildingId/:id/tracker/:offset',
    access: [],
  },
  [View.USER_USERS_REDIRECT]: { path: '/u-manage' },
  [View.MANAGE_USERS]: { path: '/u-manage/users/:offset' },
  [View.USER_SERVICES_REDIRECT]: { path: '/services' },
  [View.MANAGE_COMPONENTS]: { path: '/services/components/:offset' },
  [View.MANAGE_ORDERS]: { path: '/services/orders/:offset' },
  [View.MANAGE_ORDERS_DETAILS]: {
    path: '/services/orders_details/:id/:componentsOffset/:packagesOffset/:eventsOffset',
  },
  [View.USER_OPERATIONS_REDIRECT]: { path: '/operations' },
  [View.MANAGE_STAGES]: { path: '/operations/stages/:offset' },
  [View.MANAGE_PROCESSES]: { path: '/operations/processes/:offset' },
  [View.USER_PACKAGES_REDIRECT]: { path: '/p-manage' },
  [View.MANAGE_PACKAGES]: { path: '/p-manage/packages' },
  [View.DOCUMENTS_REDIRECT]: { path: '/document-directory', access: [] },
  [View.USER_DOCUMENTATION_NFC]: { path: '/document-directory/nfc/:offset', access: [] },
  [View.USER_DOCUMENTS]: { path: '/document-directory/default', access: [] },
  [View.USER_SETTINGS]: { path: '/user/settings', access: [] },
  [View.DOCUMENTS_PREVIEW]: { path: '/document-preview/:id', access: [] },
  [View.DOCUMENTS_PREVIEW_PASSWORD]: { path: '/document-preview-password/:id', access: [] },
  [View.DOCUMENTS_PREVIEW_DOCS]: { path: '/document-preview-docs/:id/:lang', access: [] },
  [View.DOCUMENTS_PREVIEW_CERTIFICATES]: { path: '/document-preview-certificates/:id/:lang', access: [] },
  [View.ERROR]: { path: '/error', access: [] },
  [View.ERROR_403]: { path: '/error/403', access: [] },
  [View.ERROR_500]: { path: '/error/500', access: [] },
  [View.ERROR_404]: { path: '/error/404', access: [] },
  [View.ERROR_401]: { path: '/error/401', access: [] },
}) as CombinedRoutes;

export const getPath = (view: typeof View[keyof typeof View]) => (mapping[view] ? mapping[view].path : '');

export const getAccess = (view: typeof View[keyof typeof View]) => (mapping[view] ? mapping[view].access : false);

export const PATHS = Object.keys(View)
  .map((key) => ({
    [key]: getPath((View as any)[key]),
  }))
  .reduce(
    (obj, item) => ({
      ...obj,
      [Object.keys(item)[0]]: item[Object.keys(item)[0]],
    }),
    {},
  ) as CombinedRoutes;

export const injectPathParams = (route: string, params: any) => {
  let newRoute = route;
  if (params['*']) {
    delete params['*'];
  }
  Object.keys(params).forEach(function (key, index) {
    const regex = new RegExp(`:${key}`, 'g');
    newRoute = newRoute.replace(regex, params[key]);
  });
  return newRoute as any;
};

export const injectQueryParams = (route: string, params: any) => {
  let newRoute = route;
  Object.keys(params).forEach(function (key, index) {
    const regex = new RegExp(`:${key}`, 'g');
    newRoute = newRoute.replace(regex, params[key]);
  });
  return newRoute as any;
};

export default mapping;
