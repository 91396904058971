import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { media } from '../../../../styles/media';
import { Text, TextType } from '@bit/first-scope.text';
import { Filters } from '../components/filters/Filters';
import { FiltersProvider } from './filterContext/сontext';
import { View } from '../../../../routes/routeInterfaces';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { SearchBarType } from '../../../../enums/SearchBarType';
import { SearchBar } from '../../../../components/searchBar/SearchBar';
import { getRequiredDateFormat } from '../../../../utils/date/date-format';
import { MobileFiltersProvider } from './mobileFilterContext/mobileContext';
import { ReactComponent as SearchIcon } from '../../../../assets/Search.svg';
import { SelectLevel } from '../../../../components/selectLevel/SelectLevel';
import { CommonButton, ButtonIconType } from '@bit/first-scope.common-button';
import { ReactComponent as SlidersIcon } from '../../../../assets/sliders.svg';
import { useStyleContext, useWindowSize } from '../../../../styles/style.context';
import { useCustomHistory, useHistory } from '../../../../utils/react-router-dom-abstraction';
import { ReactComponent as LocationIcon } from '../../../../assets/location-secondary.svg';
import { FloorplanViewport } from '../../../../features/floorplanViewport/floorplanViewport';
import { useSelector } from 'react-redux';
import { selectLanguage, selectLanguageStrings } from '../../../../app/state/userSlice';
import { isClasp } from '../../../../consts';
import { borderRadiusFetch, colorFetch } from '../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';
import { PATHS } from '../../../../routes';
import { injectPathParams } from '../../../../routes/paths';
import { stringifyUrl } from 'query-string';
import { useQueryParams } from '../../../../utils/useQueryParams';
// @ts-ignore
import _ from 'underscore';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  overflow: hidden;
  position: relative;
  ${media.tablet`
    padding: 0;
    overflow: hidden;
  `}
`;

const ExtraWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${media.tablet`
    padding: 34px 15px 16px;
    border-bottom: 1px solid;
    border-color: ${colorFetch('gray3')};
  `}
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
  flex-wrap: wrap;
  ${media.tablet`
    justify-content: center;
    margin-bottom: 10px;
  `}
  ${media.phone`
    justify-content: space-between;
  `}
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  ${media.tablet`
    width: 100%;
  `}
  ${media.phone`
    justify-content: space-between;
  `}
`;

const Title = styled.h1`
  margin: 0 0 0 15px;
  padding: 0;
  & > span:not(:first-child) {
    margin-left: 7px;
  }
  ${media.tabletS`
    display: flex;
        & > span {
        font-size: 20px;
  }
  `}
  ${media.phone`
    font-size: 20px;
    margin: 0;
  `}
  ${media.phoneS`
    line-height: 18px;
    & > span {
      font-size: 18px;
      line-height: 18px;
  }
  `}
  ${media.phoneXS`
    line-height: 16px;
    & > span {
      font-size: 16px;
      line-height: 16px;
  }
  `}
`;

const LastUpdatedBlock = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray2')};
  ${media.tablet`
    display: none;
  `}
`;

const Time = styled.span`
  font-weight: 500;
`;

const SearchBarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${media.tablet`
    flex-direction: column-reverse;
    align-items: center;
  `}
`;

const InputWrapper = styled.div`
  display: flex;
  ${media.tablet`
    width: 100%;
    justify-content: space-between;
    gap: 5px;
  `}
`;

const GeoWrapper = styled.div`
  display: flex;
  align-items: center;
  ${media.tablet`
    margin: 0 0 15px 0;
    & > svg {
      display: none;
    }
  `}
`;

const BackButton = styled(CommonButton)``;

const StyledSelectLevel = styled(SelectLevel)`
  ${media.tablet`
      width: calc(100% - 50px);
      margin-left: 0;
  `};
` as any; // @TODO fix type

const SliderButton = styled(CommonButton)`
  display: none;
  ${media.phone`
    display: flex;
  `}
`;

const ShowSearchButton = styled.button`
  display: none;
  ${media.tablet`
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: ${colorFetch('pureWhite')};
    border: 1px solid;
    border-color: ${colorFetch('gray3')};
    border-radius: ${borderRadiusFetch('primary')};
    & > svg > path {
      stroke: ${colorFetch('primary')};
    }
  `}
`;

type StyledSearchBarProps = {
  searchIsShown: boolean;
};

// @TODO fix media types
const StyledSearchBar = styled(SearchBar)<StyledSearchBarProps>`
  ${media.tablet`
    display: ${({ searchIsShown }: any) => (searchIsShown ? 'inline-block' : 'none')};
    width: 100%;
    & > div > div {
      width: 100%;
    }
  `}
` as any; // @TODO fix type

const ZonesButtonMobile = styled(CommonButton)`
  display: none;
  ${media.phone`
  max-width: 70px;
  height: 40px;
  margin-left: auto;
  display: block;
  `}
`;

export interface ContentProps {
  data: any;
  pathParams: any;
  dataLocationsLive: any;
  id: string;
  setImageSize: any;
}

export enum TypeOfDeviceParameter {
  ALL = 'ALL',
  NODE = 'NODE',
  TRACKER = 'TRACKER',
  OFFLINE = 'OFFLINE',
}

export const Content = ({ data, pathParams, dataLocationsLive, id, setImageSize, type }: any) => {
  const theme = useTheme();
  const { pureWhite, gray2, primary } = theme.colors;

  const history = useHistory();
  const customHistory = useCustomHistory();
  const [style] = useStyleContext();
  const fullHeight = use100vh();
  const searchState: any = useLocation();
  const searchInputRef = useRef<any>(null);
  const [inputText, setInputText] = useState('');
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [countDevice, setCountDevice] = useState(
    dataLocationsLive ? dataLocationsLive?.buildings[0].floors[currentLevel].nodes : 0,
  );
  const { search } = useLocation();
  const isValmontFilterState = new URLSearchParams(search).get('isValmontFilterState');

  const [searchIsShown, setSearchIsShown] = useState(false);

  const languageStrings = useSelector(selectLanguageStrings);
  const language = useSelector(selectLanguage).shortName;

  const defaultFilterQueryState = isValmontFilterState
    ? {}
    : {
        devices: ['nodes', 'tracker', 'offline'],
        attach: ['attached', 'detached'],
        object_type: ['packages', 'inventories'],
        zones: ['zone'],
      };

  const [queryFilter, setQueryFilter] = useState(defaultFilterQueryState);
  const queryParams = useQueryParams() || {};

  useEffect(() => {
    const toGoToLocation = injectPathParams(PATHS.USER_INSIGHTS_DETAILS, {
      id: pathParams?.id,
      name: pathParams?.name,
    });
    // history.push(`${toGoToLocation}?node&tracker&offline&attach&detached&packages&inventories&zones`);

    const url = stringifyUrl({
      url: toGoToLocation,
      query: _.isEmpty(queryParams) ? queryFilter : (queryParams as any),
    });
    history.push(url);
  }, []);

  const dataLevel = useMemo(() => {
    const countTrackers =
      dataLocationsLive &&
      dataLocationsLive?.buildings[0]?.floors &&
      dataLocationsLive?.buildings[0]?.floors[0]?.nodes.reduce((a: any, el: any): number => {
        return el.base_role === 1 ? ++a : a;
      }, 0);
    return { number: 0, countTrackers: countTrackers };
  }, [dataLocationsLive]);

  const handleChange = (e: any) => {
    setInputText(e.target.value);
  };

  const handleResult = (elem: any, index: number) => {
    setInputText(elem.name);
    customHistory.replace(View.USER_INSIGHTS_DETAILS, {
      pathParams: { id: dataLocationsLive?.buildings[0].id, name: elem.name },
      count: searchState?.state && searchState?.state.count ? searchState.state.count + 1 : 1,
    });
  };

  const handleShowSearchInput = () => {
    setSearchIsShown(true);
  };

  const handleOutsideHandler = useCallback(
    (e: any) => {
      if (
        searchIsShown &&
        !(searchInputRef && searchInputRef.current && (searchInputRef as any).current.contains(e.target))
      ) {
        setSearchIsShown(false);
      }
    },
    [searchIsShown],
  );

  const windowWidth = useWindowSize()[0];

  useEffect(() => {
    if (windowWidth > 768) {
      setSearchIsShown(true);
    } else {
      setSearchIsShown(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    window.addEventListener('click', handleOutsideHandler);
    return () => {
      window.removeEventListener('click', handleOutsideHandler);
    };
  }, [handleOutsideHandler]);

  useEffect(() => {
    return () => {};
  }, [handleOutsideHandler]);

  const searchBarResults =
    dataLocationsLive?.buildings[0]?.floors[dataLevel.number].nodes &&
    dataLocationsLive?.buildings[0]?.floors[dataLevel.number].nodes
      .filter((element: any) => {
        return element?.name?.includes(inputText);
      })
      .map((finalElem: any, index: number) => ({
        ...finalElem,
        label: finalElem.name,
        index,
      }));

  const searchBarPlaceholder = {
    keyword: 'SearchCampusMap',
    placeholderOptions: { withoutLoader: true },
    style: {
      width: windowWidth > 768 ? 'max-content' : '100%',
      display: windowWidth < 769 && searchIsShown ? 'flex' : windowWidth < 769 && !searchIsShown ? 'none' : 'flex',
    },
  };

  const handleZonesButtonMobile = () => {
    customHistory.push(View.MANAGE_ZONES_DETAILS, {
      pathParams: {
        building_id: id,
        floor: data?.floors[0]?.floor_level,
        zone_id: data?.floors[0]?.floor_name,
        offset: 0,
      },
    });
  };

  // @TODO need add useMemo and check response
  const countTracker =
    dataLocationsLive && dataLocationsLive?.buildings[0]
      ? dataLocationsLive?.buildings[0].floors[currentLevel].nodes.filter((item: any) => item.positioning_role === 2)
          .length
      : 'N/A';

  return (
    <FiltersProvider queryFilter={_.isEmpty(queryParams) ? queryFilter : queryParams}>
      <Wrapper>
        <ExtraWrapper>
          <HeaderWrapper>
            <TitleWrapper>
              <BackButton
                iconType={ButtonIconType.ARROW_LEFT}
                onClick={() => {
                  history.goBack();
                }}
                height="32px"
                width="32px"
                backgroundColor={primary}
                borderColor={primary}
                iconWithoutText
              />
              <Title>
                <Text type={!style.mediaType.phone ? TextType.TITLE_PRIMARY : TextType.TITLE_H6BOLD}>
                  {languageStrings ? languageStrings.titleOfAssetsPage : 'Assets'}
                </Text>
                <Text type={!style.mediaType.phone ? TextType.TITLE_SECONDARY : TextType.TITLE_H6MOBILE}>
                  {data ? (languageStrings ? languageStrings.labelIn : 'in ') + data?.building_name : ''}
                </Text>
              </Title>
              <SliderButton
                onClick={() => setShowMobileModal(true)}
                height="32px"
                width="32px"
                backgroundColor={pureWhite}
                borderColor={primary}
                hoverColor={pureWhite}
              >
                <SlidersIcon />
              </SliderButton>
            </TitleWrapper>
            <LastUpdatedBlock>
              {languageStrings ? languageStrings.labelLastUpdatedTime : 'Last updated time: '}
              <Time>
                {dataLocationsLive && dataLocationsLive.last_updated_at !== null
                  ? getRequiredDateFormat(dataLocationsLive.last_updated_at, 'DD.MM.YYYY HH:mm')
                  : 'N/A'}
              </Time>
            </LastUpdatedBlock>
          </HeaderWrapper>
          {isClasp && (
            <SearchBarWrapper>
              <InputWrapper ref={searchInputRef}>
                <StyledSearchBar
                  placeholderProps={searchBarPlaceholder}
                  type={SearchBarType.CLASSIC_VALMONT}
                  countResultOfSearching={2}
                  onChange={handleChange}
                  onResult={handleResult}
                  value={inputText}
                  results={searchBarResults}
                  isInsightModal={true}
                  searchIsShown={searchIsShown}
                  isGlobalSearch={false}
                />
                <StyledSelectLevel
                  currentLevel={currentLevel}
                  setCurrentLevel={setCurrentLevel}
                  countOfElementsOnTheFloor={countTracker}
                  levelArr={dataLocationsLive ? dataLocationsLive?.buildings[0].floors : []}
                  nameOfElements={languageStrings ? languageStrings.labelTracker : 'tracker'}
                  isShown={!searchIsShown}
                  appLanguage={language}
                />
                {!searchIsShown && (
                  <ShowSearchButton theme={theme} onClick={handleShowSearchInput}>
                    <SearchIcon />
                  </ShowSearchButton>
                )}
                <ZonesButtonMobile onClick={handleZonesButtonMobile}>
                  {languageStrings ? languageStrings.labelZones : 'Zones'}
                </ZonesButtonMobile>
              </InputWrapper>
              <GeoWrapper>
                <LocationIcon />
                <Text type={TextType.HELPER_TEXT_1} color={gray2}>
                  {`${data?.address?.city}, ${data?.address?.line1}, ${data?.address?.line2}`}
                </Text>
              </GeoWrapper>
            </SearchBarWrapper>
          )}
          <Filters dataFloorMap={dataLocationsLive} pathParams={pathParams} data={data} />
        </ExtraWrapper>

        <MobileFiltersProvider isValmontFilterState={isValmontFilterState}>
          <FloorplanViewport
            image={{
              src: data?.floors[0]?.floor_image_url,
              height: data?.floors[0]?.floor_image_height,
              width: data?.floors[0]?.floor_image_width,
            }}
            floorData={data}
            locations={dataLocationsLive}
            setImageDimensions={setImageSize}
            height={`${(fullHeight || 0) - (windowWidth > 425 ? 244 : 234)}px`}
            pathParams={pathParams}
            isOpenModal={showMobileModal}
            closeModal={() => setShowMobileModal(false)}
            setCountDevice={setCountDevice}
          />
        </MobileFiltersProvider>
      </Wrapper>
    </FiltersProvider>
  );
};
