export enum CardType {
  DEVICES = 'DEVICES',
  ONLINE_DEVICES = 'ONLINE_DEVICES',
  OFFLINE_DEVICES = 'OFFLINE_DEVICES',
  PRODUCTION = 'PRODUCTION',
  WAREHOUSE_PZ = 'WAREHOUSE_PZ',
  WAREHOUSE_SHIPPING = 'WAREHOUSE_SHIPPING',
  GALVANIZING = 'GALVANIZING',
  WAREHOUSE_GALVA = 'WAREHOUSE_GALVA',
  LOADING = 'LOADING',
  CITIES = 'CITIES',
  DOCUMENTS = 'DOCUMENTS',
}
