import { API_URL } from '../consts';
import { useEffect, useState } from 'react';
import { HttpService } from '../utils/http';

export const useExternalDocuments = (id: string, setIsLoading: (isLoading: boolean) => void) => {
  const [localDocuments, setLocalDocuments] = useState<any>();
  useEffect(() => {
    setIsLoading(true);
    const url = `${API_URL}/v2/documents/${id}`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { payload } = responseData;
        setLocalDocuments(payload);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    })();
  }, []);

  return localDocuments;
};

export const useExternalLinks = () => {
  const [links, setLinks] = useState<any>();

  useEffect(() => {
    const url = `${API_URL}/v2/documents/certificates`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { certificates } = responseData;

        setLinks(certificates);
      } catch (e) {}
    })();
  }, []);

  return links;
};
