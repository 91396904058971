import React from 'react';

export const InventoryIcon = ({ color }: { color: string }) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44 33.9991V17.999C43.9993 17.2975 43.8141 16.6086 43.4631 16.0013C43.1121 15.394 42.6075 14.8897 42 14.539L28 6.53895C27.3919 6.18788 26.7021 6.00305 26 6.00305C25.2978 6.00305 24.608 6.18788 23.9999 6.53895L9.99989 14.539C9.39241 14.8897 8.88784 15.394 8.5368 16.0013C8.18576 16.6086 8.0006 17.2975 7.99988 17.999V33.9991C8.0006 34.7005 8.18576 35.3894 8.5368 35.9967C8.88784 36.604 9.39241 37.1084 9.99989 37.4591L23.9999 45.4591C24.608 45.8102 25.2978 45.995 26 45.995C26.7021 45.995 27.3919 45.8102 28 45.4591L42 37.4591C42.6075 37.1084 43.1121 36.604 43.4631 35.9967C43.8141 35.3894 43.9993 34.7005 44 33.9991Z"
        stroke={color}
        stroke-width="2.76924"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.53992 15.9198L26 26.0198L43.4601 15.9198"
        stroke={color}
        stroke-width="2.76924"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26 46.158V25.9979"
        stroke={color}
        stroke-width="2.76924"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
