import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../../app/state/userSlice';

import { PlaceholderFloor } from '../floorPlaceholder/PlaceholderFloor';
import { PlaceholderNotAvailableLocation } from '../floorPlaceholder/PlaceholderNotAvailableLocation';
import { Map } from './Map';

interface AssetMapSvgType {
  floorPlanImageLink: string;
  isPackagesData: boolean;
  handlePointFromMap: (value: any) => void;
  activeDevice: any;
  explorerRequestBody: any;
  isPackageListLoaded: boolean;
  isLoadingImageMap: boolean;
  setIsLoadingImageMap: (value: boolean) => void;
  webSocketData: any;
  availableLocations: any;
}

export const AssetMapSvg: FC<AssetMapSvgType> = ({
  floorPlanImageLink,
  isPackagesData,
  handlePointFromMap,
  activeDevice,
  explorerRequestBody,
  isPackageListLoaded,
  isLoadingImageMap,
  setIsLoadingImageMap,
  webSocketData,
  availableLocations,
}) => {
  const language = useSelector(selectLanguage);

  return (
    <>
      {!!floorPlanImageLink && isPackagesData ? (
        <Map
          floorPlanImageLink={floorPlanImageLink}
          handlePointFromMap={handlePointFromMap}
          activeDevice={activeDevice}
          explorerRequestBody={explorerRequestBody}
          isLoadingImageMap={isLoadingImageMap}
          setIsLoadingImageMap={setIsLoadingImageMap}
          webSocketData={webSocketData}
        />
      ) : isPackageListLoaded && availableLocations?.length === 0 ? (
        <PlaceholderNotAvailableLocation language={language ? language?.id : null} />
      ) : (
        <PlaceholderFloor />
      )}
    </>
  );
};
