import React from 'react';
import NotifierContextProviderUntyped, { useNotifierFunctions } from './context';
import NotifierUntyped from './Notifier';

const NotifierContextProvider = NotifierContextProviderUntyped as any;
const Notifier = NotifierUntyped as any;

export const NotifierProvider = ({ children }: { children: any }) => {
  return (
    <NotifierContextProvider>
      {children}
      <Notifier />
    </NotifierContextProvider>
  );
};

export { useNotifierFunctions };
