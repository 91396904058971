export const handleUncoveredNotification = (error: any, handleAddNotification: any, language: any) => {
  if (error?.response?.status === 422) {
    handleAddNotification({
      message: language
        ? language.thereIsSomethingWrong
        : 'There is something wrong with your request. Please check if the values provided are appropriate.',
      type: 'error',
    });
  } else {
    const messageError = language
      ? language.anUnexpectedErrorNotification
      : 'An unexpected error has occurred. Please, try again';

    handleAddNotification({
      message: error?.response?.data?.detail ?? messageError,
      type: 'error',
    });
  }
};
