/**
 * @description
 * This is component for animation SpinnerRowBounce
 *
 */

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colorFetch } from '../../styles/utils';

const bouncedelay = keyframes`
    0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;

type WrapperProps = {
  backgroundColor?: string;
};

const Wrapper = styled.div<WrapperProps>`
  width: 70px;

  display: flex;
  justify-content: space-between;

  margin: 100px auto 0;
  text-align: center;
  & > div {
    width: 18px;
    height: 18px;
    background-color: ${({ backgroundColor, theme }) => backgroundColor || colorFetch('primary')({ theme })};

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ${bouncedelay} 1.4s infinite ease-in-out both;
    animation: ${bouncedelay} 1.4s infinite ease-in-out both;
  }
`;

const Bounce = styled.div`
  &.bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  &.bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
`;

export const SpinnerRowBounce = ({ className, backgroundColor }: { className?: string; backgroundColor?: string }) => {
  return (
    <Wrapper className={className} backgroundColor={backgroundColor}>
      <Bounce className="bounce1" />
      <Bounce className="bounce2" />
      <Bounce className="bounce3" />
    </Wrapper>
  );
};
