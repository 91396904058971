import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
// @ts-ignore
import { useTheme } from '@xstyled/styled-components';
import { Input, StyledCheckbox, Wrapper } from './styles';

const getClassNames = (disabled: boolean, checked: boolean) => {
  const rawClassNames = [disabled ? 'disabled' : '', checked ? 'checked' : ''] as const;
  const classNames = rawClassNames.reduce(
    (className, currentClassName) => (currentClassName ? [...className, currentClassName] : [...className]),
    [] as string[],
  );
  return classNames.join(' ');
};

export interface CheckBoxProps {
  value: boolean;
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  borderRadius?: string;
  borderColor?: string;
  disabledBorderColor?: string;
  disabledBackground?: string;
  backgroundColor?: string;
  boxShadow?: string;
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  value,
  onChange,
  disabled,
  borderRadius,
  borderColor,
  disabledBorderColor,
  disabledBackground,
  backgroundColor,
  boxShadow,
}) => {
  const theme = useTheme();
  const { primary, gray4, gray12 } = theme.colors;
  const { additional4 } = theme.borderRadius;
  const { primary: primaryShadow } = theme.shadows;

  const className = getClassNames(!!disabled, value);

  const [node, setNode] = useState<any>(null);
  const wrapperRef = useCallback((currentNode) => {
    if (currentNode !== null) {
      setNode(currentNode);
    }
  }, []);

  useEffect(() => {
    if (node) {
      const pseudoFunc = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
      };
      const clickFunc = (e: any) => {
        onChange(e);
      };
      node.addEventListener('touchstart', clickFunc, { passive: false });
      node.addEventListener('touchend', pseudoFunc, { passive: false });
      node.addEventListener('click', clickFunc);
      return () => {
        node.removeEventListener('touchstart', clickFunc);
        node.removeEventListener('touchend', pseudoFunc);
        node.removeEventListener('click', clickFunc);
      };
    }
  }, [node]);

  return (
    <Wrapper ref={wrapperRef}>
      <Input
        disabled={disabled}
        className={className}
        type="checkbox"
        value={value}
        backgroundColor={backgroundColor ?? primary}
        disabledBackground={gray4}
        boxShadow={boxShadow ?? primaryShadow}
      />
      <StyledCheckbox
        className={className}
        borderRadius={borderRadius ?? additional4}
        borderColor={borderColor ?? primary}
        disabledBorderColor={disabledBorderColor ?? gray4}
        disabledBackground={disabledBackground ?? gray12}
      />
    </Wrapper>
  );
};
