/**
 * @description
 * This is component that displays desktop version of DescriptionPopup
 */

import { CommonButton } from '@bit/first-scope.common-button';
import { Text, TextType } from '@bit/first-scope.text';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../app/state/userSlice';
import { ReactComponent as CrossIcon } from '../../../../../../../assets/crossIcon.svg';
import { media } from '../../../../../../../styles/media';
import { Textarea } from '../../../textarea/Textarea';
import { Range } from '../../components/range/Range';
import { borderRadiusFetch, colorFetch } from '../../../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  max-width: 250px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${colorFetch('pureWhite')};
  padding: 14px 18px 18px 18px;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: ${borderRadiusFetch('primary')};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  ${media.tablet`
    display: none;
  `}
`;

type TitleWrapperProps = {
  isEdit?: boolean;
};

const TitleWrapper = styled.div<TitleWrapperProps>`
  display: flex;
  width: ${({ isEdit }) => (isEdit ? '100%' : '90%')};
  overflow: hidden;
  justify-content: space-between;
  align-items: flex-start;
  & > div {
    width: auto !important;
  }
`;

type TitleProps = {
  isError?: boolean;
  isEdit?: boolean;
};

const Title = styled(TextareaAutosize)<TitleProps>`
  color: ${colorFetch('black1')};
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 22px;
  width: 100%;
  border: none;
  resize: none;
  outline: none;
  padding: 0;
  margin-bottom: 12px;
  transition: all 0.3s ease;
  &.edit {
    border: 1px solid;
    border-color: ${({ isError, theme }) => (isError ? colorFetch('red1')({ theme }) : colorFetch('gray3')({ theme }))};
    border-radius: ${borderRadiusFetch('additional6')};
    padding: 8px 12px;
  }

  &:disabled {
    background-color: ${colorFetch('pureWhite')};
  }
  &::placeholder {
    color: ${({ isError, theme }) => (isError ? colorFetch('red1')({ theme }) : colorFetch('gray3')({ theme }))};
    font-weight: 400;
  }
`;

type DescriptionWrapperProps = {
  isEditable?: boolean;
};

const DescriptionWrapper = styled.div<DescriptionWrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${({ isEditable }) => (isEditable ? '10px 0 18px 0' : '6px 0 0 0')};
  &.stages {
    margin: 6px 0 3px 0;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 10px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: 0.3s;
`;

const IntersectingWrapper = styled.div`
  padding: 25px 18px;
  border-top: 1px solid;
  border-color: ${colorFetch('gray3')};
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

const IntersectingTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledIntersectingText = styled(Text)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colorFetch('red1')};
`;

export const DesktopView = ({
  isEdit,
  value,
  height,
  onChange,
  closeModal,
  pixel_ratio,
  pictureSize,
  isRight,
  accessData,
  isEditingStart,
  attemptDelete,
  intersectingZonesCount,
  validatingIsStarted,
}: {
  attemptDelete: () => void;
  closeModal: any;
  value: any;
  height: any;
  onChange: any;
  isEdit?: boolean;
  intersectingZonesCount: number;
  pixel_ratio: number;
  validatingIsStarted: boolean;
  pictureSize: { height: number; width: number };
  isRight?: boolean;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  isEditingStart?: boolean;
}) => {
  const theme = useTheme();
  const { pureWhite, black1, gray1, red1 } = theme.colors;

  const { name, description } = value;

  const languageStrings = useSelector(selectLanguageStrings);

  const titleRef = useRef<HTMLTextAreaElement>(null);

  const [titleIsEdit, setIsTitleEdit] = useState(false);

  const handleChangeWidth = (val: number[]) => {
    onChange((currentVal: any) => ({
      ...currentVal,
      coordinates: { ...currentVal.coordinates, width: val[0] * pixel_ratio },
    }));
  };

  const handleChangeHeight = (val: number[]) => {
    onChange((currentVal: any) => ({
      ...currentVal,
      coordinates: { ...currentVal.coordinates, height: val[0] * pixel_ratio },
    }));
  };

  const handleChangeDescription = (val: string) => {
    onChange((currentVal: any) => ({
      ...currentVal,
      description: val,
    }));
  };

  const handleChangeName = (val: string) => {
    onChange((currentVal: any) => ({
      ...currentVal,
      name: val,
    }));
  };

  const propertiesArray = [
    {
      name: languageStrings ? languageStrings.labelPositionX : 'Position X',
      value: Math.ceil(value?.coordinates?.center?.x / pixel_ratio),
    },
    {
      name: languageStrings ? languageStrings.labelPositionY : 'Position Y',
      value: Math.ceil(value?.coordinates?.center?.y / pixel_ratio),
    },
    {
      name: languageStrings ? languageStrings.labelWidth : 'Width',
      value: Math.ceil(value?.coordinates?.width / pixel_ratio),
      onChange: handleChangeWidth,
      maxValue: Math.ceil(
        value?.coordinates?.center?.x > pictureSize.width / 2
          ? (pictureSize.width - value?.coordinates?.center?.x / 2) / pixel_ratio
          : (pictureSize.width / 2 + value?.coordinates?.center?.x / 2) / pixel_ratio,
      ),
    },
    {
      name: languageStrings ? languageStrings.labelHeight : 'Height',
      value: Math.ceil(value?.coordinates?.height / pixel_ratio),
      onChange: handleChangeHeight,
      maxValue: Math.ceil(
        value?.coordinates?.center?.y > pictureSize.height / 2
          ? (pictureSize.height - value?.coordinates?.center?.y / 2) / pixel_ratio
          : (pictureSize.height / 2 + value?.coordinates?.center?.y / 2) / pixel_ratio,
      ),
    },
  ];

  useEffect(() => {
    if (titleIsEdit && titleRef && titleRef.current) {
      titleRef.current.focus();
    }
  }, [titleIsEdit]);

  return (
    <Wrapper
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {!isEdit && (
        <CloseButton onClick={closeModal}>
          <CrossIcon />
        </CloseButton>
      )}
      <TitleWrapper isEdit={isEdit}>
        <Title
          onChange={(e: any) => {
            handleChangeName(e.target.value);
          }}
          onBlur={() => {
            if (name) {
              setIsTitleEdit(false);
            }
          }}
          value={name}
          placeholder={languageStrings ? languageStrings.enterNamePlaceholder : 'Enter name'}
          disabled={titleIsEdit}
          rowsMax={2}
          ref={titleRef}
          isError={!name && validatingIsStarted}
          className={isEdit && !titleIsEdit ? 'edit' : ''}
          isEdit={isEdit}
        />
      </TitleWrapper>
      {propertiesArray.map((item: any, index: number) => {
        const { name, value: localValue, onChange: onLocalChange, maxValue } = item;
        return (
          <Range
            index={index}
            name={name}
            localValue={localValue}
            onLocalChange={onLocalChange}
            isEdit={isEdit}
            maxVal={maxValue}
          />
        );
      })}
      <DescriptionWrapper isEditable={accessData?.edit}>
        <Text type={TextType.TEXT_12_BLACK} color={gray1} margin={'0 0 0 8px'}>
          {languageStrings ? languageStrings.labelDescription : 'Description'}
        </Text>
        <Textarea
          value={!description && !isEdit ? 'N/A' : description}
          onChange={handleChangeDescription}
          isEdit={isEdit}
          isError={!description && validatingIsStarted}
        />
      </DescriptionWrapper>
      <ButtonsWrapper>
        {isEditingStart && accessData?.delete && (
          <CommonButton
            width={'100%'}
            height={'26px'}
            backgroundColor={pureWhite}
            borderColor={red1}
            backgroundColorHover={red1}
            borderColorHover={red1}
            color={black1}
            onClick={attemptDelete}
          >
            {languageStrings ? languageStrings.deleteButton : 'Delete'}
          </CommonButton>
        )}
      </ButtonsWrapper>
      {isEditingStart && intersectingZonesCount > 0 && (
        <IntersectingWrapper>
          <IntersectingTextWrapper>
            <StyledIntersectingText type={TextType.TEXT_12_BLACK}>
              {languageStrings ? languageStrings.IntersectedOverflownZone : 'Intersected/Overflown'}
            </StyledIntersectingText>
            <StyledIntersectingText type={TextType.TEXT_12_BLACK}>{intersectingZonesCount}</StyledIntersectingText>
          </IntersectingTextWrapper>
        </IntersectingWrapper>
      )}
    </Wrapper>
  );
};
