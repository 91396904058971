/**
 * @description
 * This is component that displays mobile version of DescriptionPopup
 */

import React, { useCallback, useEffect, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Text } from '@bit/first-scope.text';
import { ReactComponent as SearchImage } from '../../../../../../../../assets/Search.svg';
import { CommonButton } from '../../../../../../../../components/commonButton/CommonButton';
import { selectLanguageStrings } from '../../../../../../../../app/state/userSlice';
import { WordHightlight } from '../../../../../../../../components/wordHightlight/WordHightlight';
import { CloseButton } from '../../../../../../../../components/closeButton/CloseButton';
import { PopupPlaceholder } from '../../../../../../../../components/searchBar/components/popupPlaceholder/PopupPlaceholder';
import { DraggableModal } from '../../../../../../../../components/draggableModal/DraggableModal';
import { useBlockBodyScroll } from '../../../../../../../../utils/useBlockBodyScroll';
import { borderRadiusFetch, colorFetch } from '../../../../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled(DraggableModal)``;

type ContainerProps = {
  result: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: ${({ result }) => (!result ? 'center' : 'start')};
`;

const SearchBarModal = styled.div`
  height: 100%;
  position: relative;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  background-color: ${colorFetch('pureWhite')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: ${borderRadiusFetch('primary')};
  padding: 8px 38px;
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('black1')};

  &::placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: ${colorFetch('gray2')};
  }
  -webkit-appearance: caret;
  -moz-appearance: caret;
`;

const InputWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const ExtraInputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const WrapperWithButton = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const ImageWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 4px;
  left: 8px;
  & > svg > path {
    stroke: ${colorFetch('primary')};
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 8px;
`;

const CancelSearchButton = styled(CommonButton)`
  color: ${colorFetch('primary')};
  background: ${colorFetch('pureWhite')};
  border-color: ${colorFetch('primary')};
  margin-left: 10px;
  width: 115px;
  height: 32px;
`;

const ResultsDataWrapper = styled.div``;

const ContainerRows = styled.div`
  max-height: 680px;
  box-sizing: border-box;
`;

const ResultsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const NameOfZone = styled(Text)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('black1')};
` as any; // @TODO fix type

const LevelLabel = styled(NameOfZone)`
  color: ${colorFetch('gray2')};
`;

export const SearchModal = ({ locations, setActiveId }: { locations: any; setActiveId: any }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [modalState, setModalState] = useState(false);
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const [modalNode, setModalNode] = useState<HTMLDivElement | null>(null);
  const theme = useTheme();

  const languageStrings = useSelector(selectLanguageStrings);
  useBlockBodyScroll(modalState);

  const inputRef = useCallback((node) => {
    if (node !== null) {
      setNode(node);
    }
  }, []);

  const handleChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    setInputValue(target.value);
  };

  const handleOnFocus = (e: FocusEvent) => {
    e.preventDefault();
    setIsOpenModal(true);
    if (!isOpenModal && !modalState) {
      node?.blur();
    }
  };

  const handleOnStart = useCallback(
    (e: Event) => {
      if (!modalState && modalNode?.contains(e.target as HTMLDivElement)) {
        setModalState(true);
      }
    },
    [modalNode],
  );

  const handleOnEnd = useCallback((e: Event) => {
    setModalState(false);
  }, []);

  const handleResultOnClick = (elem: any) => {
    setActiveId(elem.id);
    setInputValue('');
  };

  const hightlight = useCallback(
    (str) => {
      return <WordHightlight filter={inputValue} str={str} />;
    },
    [inputValue],
  );

  useEffect(() => {
    window.addEventListener('touchstart', handleOnStart);
    window.addEventListener('touchend', handleOnEnd);
    return () => {
      window.removeEventListener('touchstart', handleOnStart);
      window.removeEventListener('touchend', handleOnEnd);
    };
  }, [handleOnStart, handleOnEnd]);

  useEffect(() => {
    if (node) {
      isOpenModal && node.focus({ preventScroll: true });
    }
  }, [node, isOpenModal]);

  useEffect(() => {
    const results =
      locations &&
      locations
        .filter((element: any) => element.name.toLowerCase().includes(inputValue.toLowerCase()))
        .map((finalElem: any, index: number) => ({
          ...finalElem,
          label: finalElem.name,
          positioning_role_name: `${languageStrings ? languageStrings.labelLevel : 'Level'} ${
            finalElem.location.floor.level
          }`,
          index,
        }));
    setSearchResults(results);
  }, [languageStrings, locations, inputValue]);

  return (
    <Wrapper
      setModalNode={setModalNode}
      isDraggable={true}
      withoutNav={true}
      isSearchModal={true}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      isScroll={!!inputValue && !!searchResults && searchResults.length > 10}
    >
      <WrapperWithButton>
        <ExtraInputWrapper>
          <ImageWrapper>
            <SearchImage />
          </ImageWrapper>
          <SearchInput
            ref={inputRef}
            placeholder={languageStrings ? languageStrings.searchPlaceholder : 'Search...'}
            value={inputValue}
            // @TODO fix handleChange type
            onChange={handleChange as any}
            // @TODO fix handleOnFocus type
            onFocus={handleOnFocus as any}
          />
          {inputValue.length > 0 ? (
            <CloseButtonWrapper>
              <CloseButton
                onClick={() => {
                  setInputValue('');
                }}
                color={theme.colors.gray1}
                hoverColor={theme.colors.gray2}
              />
            </CloseButtonWrapper>
          ) : null}
        </ExtraInputWrapper>
        {isOpenModal && (
          <CancelSearchButton
            onClick={() => {
              setIsOpenModal(false);
              setInputValue('');
            }}
          >
            {languageStrings ? languageStrings.cancelButton : 'Cancel'}
          </CancelSearchButton>
        )}
      </WrapperWithButton>
      <Container
        onClick={(e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        result={searchResults && searchResults.length > 0}
      >
        <SearchBarModal>
          <InputWrapper>
            {inputValue && (
              <ResultsDataWrapper theme={theme}>
                <ContainerRows>
                  {searchResults && searchResults.length > 0 ? (
                    searchResults.map((result: any) => {
                      return (
                        <ResultsWrapper
                          onClick={() => {
                            setIsOpenModal(false);
                            handleResultOnClick(result);
                          }}
                        >
                          <NameOfZone>{hightlight(result.label)}</NameOfZone>
                          <LevelLabel>{result.positioning_role_name}</LevelLabel>
                        </ResultsWrapper>
                      );
                    })
                  ) : (
                    <PopupPlaceholder languageStrings={languageStrings} isSearch />
                  )}
                </ContainerRows>
              </ResultsDataWrapper>
            )}
          </InputWrapper>
        </SearchBarModal>
      </Container>
    </Wrapper>
  );
};
