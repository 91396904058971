import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { ItemWithoutChildMenu } from './ItemWithoutChildMenu';
import { LanguageItem } from '../LanguageItem';
import { LogoutItem } from '../LogoutItem';
import { ItemWithChildMenu } from './ItemWithChildMenu';

export enum MenuItemType {
  SEARCH = 'SEARCH',
  DASHBOARDS = 'DASHBOARDS',
  SITES = 'SITES',
  INVENTORIES = 'INVENTORIES',
  USERS = 'USERS',
  DEVICES = 'DEVICES',
  OPERATIONS = 'OPERATIONS',
  SERVICES = 'SERVICES',
  DOCUMENTS = 'DOCUMENTS',
  SETTINGS = 'SETTINGS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  LANGUAGES = 'LANGUAGES',
  LOGOUT = 'LOGOUT',
  DOCUMENTATION_NFC = 'DOCUMENTATION_NFC',
}

export interface ItemFirstDesktopMenuProps {
  type: MenuItemType;
  to?: any;
  value?: string;
  isOpen: boolean;
  disabled?: boolean;
  tooltipId?: string;
  userRole?: string;
  userName?: string;
  onLogout?: any;
  childRoutes?: any;
  pathForActive?: any;
  appendSecondaryItems?: (val: any[]) => void;
  clearSecondaryItems?: () => void;
  isHidden?: boolean;
}

export const ItemFirstDesktopMenu: FC<ItemFirstDesktopMenuProps> = ({
  type,
  to,
  value,
  isOpen,
  disabled,
  tooltipId,
  userRole,
  userName,
  onLogout,
  childRoutes,
  pathForActive,
  appendSecondaryItems,
  clearSecondaryItems,
  isHidden,
  ...props
}) => {
  const route = window.location.href;

  const [openManageList, setOpenManageList] = useState(false);

  const manageRef = useRef<any>(null);

  const manageOutsideHandler = useCallback(
    (e: any) => {
      if (openManageList && !manageRef.current?.contains(e.target)) {
        setOpenManageList(false);
      }
    },
    [openManageList],
  );

  useEffect(() => {
    window.addEventListener('click', manageOutsideHandler);
    return () => {
      window.removeEventListener('click', manageOutsideHandler);
    };
  }, [manageOutsideHandler]);

  switch (type) {
    case MenuItemType.SEARCH:
    case MenuItemType.INVENTORIES:
    case MenuItemType.USERS:
    case MenuItemType.DEVICES:
    case MenuItemType.DOCUMENTS:
    case MenuItemType.DOCUMENTATION_NFC:
    case MenuItemType.SETTINGS:
      return (
        <ItemWithoutChildMenu
          disabled={disabled}
          tooltipId={tooltipId}
          to={to}
          type={type}
          isOpen={isOpen}
          value={value}
          isActive={route.includes(pathForActive ? pathForActive : to)}
          appendSecondaryItems={appendSecondaryItems}
          clearSecondaryItems={clearSecondaryItems}
          isHidden={isHidden}
          {...props}
        />
      );
    case MenuItemType.DASHBOARDS:
    case MenuItemType.SITES:
    case MenuItemType.OPERATIONS:
    case MenuItemType.SERVICES:
      return (
        <ItemWithChildMenu
          type={type}
          manageRef={manageRef}
          isActive={pathForActive && route.includes(pathForActive)}
          to={to}
          childRoutes={childRoutes}
          isOpen={isOpen}
          tooltipId={tooltipId}
          value={value}
          setOpenManageList={setOpenManageList}
          openManageList={openManageList}
          appendSecondaryItems={appendSecondaryItems}
          clearSecondaryItems={clearSecondaryItems}
        />
      );
    case MenuItemType.NOTIFICATIONS:
      return <></>;
    case MenuItemType.LANGUAGES:
      return (
        <LanguageItem
          isOpen={isOpen}
          appendSecondaryItems={appendSecondaryItems}
          clearSecondaryItems={clearSecondaryItems}
        />
      );
    case MenuItemType.LOGOUT:
      return <LogoutItem userRole={userRole} userName={userName} onLogout={onLogout} isOpen={isOpen} />;
    default:
      return null;
  }
};
