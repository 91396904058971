enum TypeDevice {
  ALL = 'ALL',
  NODE = 'NODE',
  TRACKER = 'TRACKER',
  OFFLINE = 'OFFLINE',
}

enum TypeAttach {
  ATTACHED = 'ATTACHED',
  DETACHED = 'DETACHED',
}

enum TypeEntity {
  PACKAGE = 'PACKAGE',
  INVENTORY = 'INVENTORY',
}

const setInitialStateMobile = (languageStrings: any) => {
  return {
    nodes: [],
    firstModal: {
      firstModalDeviceState: {
        checkedDevicesCount: 3,
        isOpenDeviceList: false,
        devicesFilter: {
          [TypeDevice.NODE]: {
            isSelected: true,
            name: 'Node',
            type: TypeDevice.NODE,
          },

          [TypeDevice.TRACKER]: {
            isSelected: true,
            name: 'Tracker',
            type: TypeDevice.TRACKER,
          },

          [TypeDevice.OFFLINE]: {
            isSelected: true,
            name: 'Offline',
            type: TypeDevice.OFFLINE,
          },
        },
      },
      firstModalAttachState: {
        checkedAttachCount: 2,
        isOpenAttachList: false,
        attachFilter: {
          [TypeAttach.ATTACHED]: {
            isSelected: true,
            name: languageStrings.attached,
            type: TypeAttach.ATTACHED,
          },

          [TypeAttach.DETACHED]: {
            isSelected: true,
            name: languageStrings.detached,
            type: TypeAttach.DETACHED,
          },
        },
      },
      firstModalEntityState: {
        checkedEntityCount: 2,
        isOpenEntityList: false,
        entityFilter: {
          [TypeEntity.PACKAGE]: {
            isSelected: true,
            name: languageStrings.packages,
            type: TypeEntity.PACKAGE,
          },

          [TypeEntity.INVENTORY]: {
            isSelected: true,
            name: languageStrings.inventories,
            type: TypeEntity.INVENTORY,
          },
        },
      },
      firstModalInventoriesState: {
        checkedInventoriesCount: 0,
        isOpenInventoriesList: false,
        inventoriesFilter: [],
      },
      firstModalZonesState: {
        zones: [],
        isZones: true,
      },
    },

    secondModal: {
      secondModalDeviceState: {
        checkedDevicesCount: 3,
        isOpenDeviceList: false,
        devicesFilter: {
          [TypeDevice.NODE]: {
            isSelected: true,
            name: 'Node',
            type: TypeDevice.NODE,
          },

          [TypeDevice.TRACKER]: {
            isSelected: true,
            name: 'Tracker',
            type: TypeDevice.TRACKER,
          },

          [TypeDevice.OFFLINE]: {
            isSelected: true,
            name: 'Offline',
            type: TypeDevice.OFFLINE,
          },
        },
      },
      secondModalAttachState: {
        checkedAttachCount: 2,
        isOpenAttachList: false,
        attachFilter: {
          [TypeAttach.ATTACHED]: {
            isSelected: true,
            name: languageStrings.attached,
            type: TypeAttach.ATTACHED,
          },

          [TypeAttach.DETACHED]: {
            isSelected: true,
            name: languageStrings.detached,
            type: TypeAttach.DETACHED,
          },
        },
      },
      secondModalEntityState: {
        checkedEntityCount: 2,
        isOpenEntityList: false,
        entityFilter: {
          [TypeEntity.PACKAGE]: {
            isSelected: true,
            name: languageStrings.packages,
            type: TypeEntity.PACKAGE,
          },

          [TypeEntity.INVENTORY]: {
            isSelected: true,
            name: languageStrings.inventories,
            type: TypeEntity.INVENTORY,
          },
        },
      },
      secondModalInventoriesState: {
        checkedInventoriesCount: 0,
        isOpenInventoriesList: false,
        inventoriesFilter: [],
      },
    },

    deviceState: {
      checkedDevicesCount: 3,
      isOpenDeviceList: false,
      devicesFilter: {
        [TypeDevice.NODE]: {
          isSelected: true,
          name: 'Node',
          type: TypeDevice.NODE,
        },

        [TypeDevice.TRACKER]: {
          isSelected: true,
          name: 'Tracker',
          type: TypeDevice.TRACKER,
        },

        [TypeDevice.OFFLINE]: {
          isSelected: true,
          name: 'Offline',
          type: TypeDevice.OFFLINE,
        },
      },
    },
    attachState: {
      checkedAttachCount: 2,
      isOpenAttachList: false,
      attachFilter: {
        [TypeAttach.ATTACHED]: {
          isSelected: true,
          name: languageStrings.attached,
          type: TypeAttach.ATTACHED,
        },

        [TypeAttach.DETACHED]: {
          isSelected: true,
          name: languageStrings.detached,
          type: TypeAttach.DETACHED,
        },
      },
    },

    entityState: {
      checkedEntityCount: 2,
      isOpenEntityList: false,
      entityFilter: {
        [TypeEntity.PACKAGE]: {
          isSelected: true,
          name: languageStrings.packages,
          type: TypeEntity.PACKAGE,
        },

        [TypeEntity.INVENTORY]: {
          isSelected: true,
          name: languageStrings.inventories,
          type: TypeEntity.INVENTORY,
        },
      },
    },

    inventoriesState: {
      checkedInventoriesCount: 0,
      isOpenInventoriesList: false,
      inventoriesFilter: [],
    },
    zonesState: {
      zones: [],
      isZones: true,
    },
  };
};

export { TypeDevice, TypeAttach, TypeEntity, setInitialStateMobile };
