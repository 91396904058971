import { View } from '../../routes/routeInterfaces';
import { useViewMatch } from '../../routes/utils';

const exceptionList: string[] = [
  View.DOCUMENTS_PREVIEW,
  View.DOCUMENTS_PREVIEW_PASSWORD,
  View.DOCUMENTS_PREVIEW_CERTIFICATES,
  View.DOCUMENTS_PREVIEW_DOCS,
];

export const useExceptions = () => {
  const matchView = useViewMatch();
  const isExcepted = exceptionList.findIndex((exception: unknown) => matchView(exception as View)) !== -1;

  return isExcepted;
};
