/**
 * @description
 * This is component close icon, this button used in different variant modals.
 *
 */

import React from 'react';
import styled from 'styled-components';
import { CloseButton } from '@bit/first-scope.close-button';
import { useTheme } from '@cthings.co/styles';
import { colorFetch } from '../../../../styles/utils';

const Wrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorFetch('gray5')};
  border-radius: 50%;
`;

export const CloseButtonRound = ({ closeOnClick, ...props }: { closeOnClick: any; className?: string }) => {
  const theme = useTheme();
  const { gray2 } = theme.colors;

  return (
    <Wrapper {...props}>
      <CloseButton width={'20px'} height={'20px'} onClick={closeOnClick} strokeWidth={'2px'} color={gray2} />
    </Wrapper>
  );
};
