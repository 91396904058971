import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { ReactComponent as SearchIcon } from '../../../../../assets/Search_placeholder_icon.svg';
import { Text, TextType } from '@bit/first-scope.text';
import { FileIcon, PlaceholderWrapper, SearchPlaceholderWrapper } from '../../../styledComponents';

export const PopupPlaceholder = ({ languageStrings, isSearch }: { languageStrings?: any; isSearch?: boolean }) => {
  const theme = useTheme();
  const { gray3, gray2 } = theme.colors;

  return (
    <>
      {isSearch ? (
        <SearchPlaceholderWrapper>
          <SearchIcon />
          <Text type={TextType.TEXT_14_GRAY}>{languageStrings.searchBarPlaceholderLabel}</Text>
          <Text type={TextType.TEXT_10_GRAY} color={gray3} weight={'400'}>
            {languageStrings.searchBarPlaceholderTerm}
          </Text>
        </SearchPlaceholderWrapper>
      ) : (
        <PlaceholderWrapper>
          <FileIcon />
          <Text type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'}>
            {languageStrings.labelNoItemsYet}
          </Text>
        </PlaceholderWrapper>
      )}
    </>
  );
};
