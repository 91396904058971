// @ts-ignore

import React from 'react';
import { theme } from '../../../../../styles/theme';

export enum TypeCluster {
  RED = 'RED',
  BLUE = 'BLUE',
  ORANGE = 'ORANGE',

  RED_BLUE = 'RED_BLUE',
  RED_ORANGE = 'RED_ORANGE',
  BLUE_ORANGE = 'BLUE_ORANGE',

  BLUE_ORANGE_RED = 'BLUE_ORANGE_RED',
}

const { blue15, blue16, red7, red9, orange9, orange10 } = theme.colors;

export const ClusterOneColor = (
  backgroundColor: string,
  colorBorder: string,
  xCoordinates: number,
  yCoordinates: number,
  countCluster: number,
) => (
  <g transform={`translate(${xCoordinates}, ${yCoordinates})}`}>
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="-1"
        width="24"
        height="24.1442"
        rx="12"
        transform="matrix(1 8.77671e-08 8.70798e-08 -1 0.744141 25)"
        fill={`${backgroundColor}`}
      />
      <text
        x="50%"
        y="55%"
        dominant-baseline="middle"
        fill="white"
        text-anchor="middle"
        font-size="13px"
        font-weight="500"
      >
        {countCluster}
      </text>
      <rect
        x="1"
        y="-1"
        width="24"
        height="24.1442"
        rx="12"
        transform="matrix(1 8.77671e-08 8.70798e-08 -1 0.744141 25)"
        stroke={`${colorBorder}`}
        stroke-width="2"
      />
    </svg>
  </g>
);

export const ClusterTwoColor = (
  firstBackgroundColor: string,
  firstColorBorder: string,
  secondBackgroundColor: string,
  secondColorBorder: string,
  xCoordinates: number,
  yCoordinates: number,
  countCluster: number,
) => {
  return (
    <g transform={`translate(${xCoordinates}, ${yCoordinates})`}>
      <svg width="27" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="1"
          y="-1"
          width="24"
          height="24.1442"
          rx="12"
          transform="matrix(1 8.77671e-08 8.70798e-08 -1 0.744141 24.3086)"
          fill={`${secondBackgroundColor}`}
        />
        <g clip-path="url(#clip0_3723_328742)">
          <g clip-path="url(#clip1_3723_328742)">
            <circle
              cx="13.7441"
              cy="13.1641"
              r="12"
              fill={`${firstBackgroundColor}`}
              stroke={`${firstColorBorder}`}
              stroke-width="2"
            />
          </g>
          <g clip-path="url(#clip2_3723_328742)">
            <circle
              cx="13.7441"
              cy="13.1641"
              r="12"
              fill={`${secondBackgroundColor}`}
              stroke={`${secondColorBorder}`}
              stroke-width="2"
            />
          </g>
        </g>
        <text
          x="50%"
          y="55%"
          dominant-baseline="middle"
          fill="white"
          text-anchor="middle"
          font-size="13px"
          font-weight="500"
        >
          {countCluster}
        </text>
        <rect
          x="1"
          y="-1"
          width="24"
          height="24.1442"
          rx="12"
          transform="matrix(1 8.77671e-08 8.70798e-08 -1 0.744141 24.3086)"
          stroke={`${secondColorBorder}`}
          stroke-width="2"
        />
        <defs>
          <clipPath id="clip0_3723_328742">
            <rect width="26" height="26.1442" fill="white" transform="translate(0.744141 0.164062)" />
          </clipPath>
          <clipPath id="clip1_3723_328742">
            <rect width="13" height="26" fill="white" transform="translate(13.7441 0.164062)" />
          </clipPath>
          <clipPath id="clip2_3723_328742">
            <rect width="13" height="26" fill="white" transform="matrix(-1 0 0 1 13.7441 0.164062)" />
          </clipPath>
        </defs>
      </svg>
    </g>
  );
};

const ClusterThreeColor = (xCoordinates: number, yCoordinates: number, countCluster: number) => (
  <g transform={`translate(${xCoordinates}, ${yCoordinates})`}>
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="-1"
        width="24"
        height="24.1442"
        rx="12"
        transform="matrix(1 8.77671e-08 8.70798e-08 -1 0.744141 24.6992)"
        fill="#EE445D"
      />
      <mask
        id="mask0_3723_328743"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="6"
        width="14"
        height="21"
      >
        <mask id="path-2-inside-1_3723_328743" fill="white">
          <path d="M2.75574 6.64168C1.51168 8.62049 0.81893 10.8989 0.749863 13.239C0.680796 15.579 1.23795 17.8948 2.3631 19.9443C3.48826 21.9938 5.14014 23.7019 7.14614 24.8901C9.15215 26.0783 11.4387 26.7029 13.7668 26.6988L13.7441 13.6268L2.75574 6.64168Z" />
        </mask>
        <path
          d="M2.75574 6.64168C1.51168 8.62049 0.81893 10.8989 0.749863 13.239C0.680796 15.579 1.23795 17.8948 2.3631 19.9443C3.48826 21.9938 5.14014 23.7019 7.14614 24.8901C9.15215 26.0783 11.4387 26.7029 13.7668 26.6988L13.7441 13.6268L2.75574 6.64168Z"
          fill="#8F90A6"
          stroke="#C7C9D9"
          stroke-width="4"
          mask="url(#path-2-inside-1_3723_328743)"
        />
      </mask>
      <g mask="url(#mask0_3723_328743)">
        <circle cx="13.7441" cy="13.5547" r="12" fill="#EE445D" stroke="#FA8099" stroke-width="2" />
      </g>
      <mask
        id="mask1_3723_328743"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="13"
        y="6"
        width="14"
        height="21"
      >
        <path
          d="M13.7441 26.6989C16.0723 26.6989 18.3577 26.0702 20.3617 24.8785C22.3656 23.6868 24.0145 21.9758 25.1361 19.9243C26.2577 17.8728 26.8109 15.5561 26.7377 13.2162C26.6646 10.8763 25.9679 8.59903 24.7204 6.6224L13.7441 13.6268L13.7441 26.6989Z"
          fill="#F77F00"
        />
      </mask>
      <g mask="url(#mask1_3723_328743)">
        <circle cx="13.7441" cy="13.5547" r="12" fill="#F77F00" stroke="#FFB862" stroke-width="2" />
      </g>
      <mask
        id="mask2_3723_328743"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="0"
        width="24"
        height="14"
      >
        <path
          d="M25.1142 7.2893C24.0191 5.30267 22.428 3.63726 20.4979 2.45721C18.5678 1.27717 16.3651 0.623206 14.1071 0.559784C11.8491 0.496361 9.61362 1.02567 7.62099 2.09553C5.62836 3.1654 3.94735 4.7389 2.74363 6.66097L13.7441 13.6268L25.1142 7.2893Z"
          fill="#F77F00"
        />
      </mask>
      <g mask="url(#mask2_3723_328743)">
        <circle cx="13.7441" cy="13.5547" r="12" fill="#4E71EE" stroke="#6D8DFF" stroke-width="2" />
      </g>
      <text
        x="50%"
        y="55%"
        dominant-baseline="middle"
        fill="white"
        text-anchor="middle"
        font-size="13px"
        font-weight="500"
      >
        {countCluster}
      </text>
      <rect
        x="1"
        y="-1"
        width="24"
        height="24.1442"
        rx="12"
        transform="matrix(1 8.77671e-08 8.70798e-08 -1 0.744141 24.6992)"
        stroke="#6D8DFF"
        stroke-width="2"
      />
    </svg>
  </g>
);

export const getSVGCluster = (type: TypeCluster, xCoordinates: number, yCoordinates: number, countCluster: number) => {
  const setCluster = (type: TypeCluster, xCoordinates: number, yCoordinates: number, countCluster: number) => {
    const clusterTypes = {
      [TypeCluster.RED]: ClusterOneColor(red7, red9, xCoordinates, yCoordinates, countCluster),
      [TypeCluster.BLUE]: ClusterOneColor(blue15, blue16, xCoordinates, yCoordinates, countCluster),
      [TypeCluster.ORANGE]: ClusterOneColor(orange9, orange10, xCoordinates, yCoordinates, countCluster),

      [TypeCluster.RED_BLUE]: ClusterTwoColor(red7, red9, blue15, blue16, xCoordinates, yCoordinates, countCluster),
      [TypeCluster.RED_ORANGE]: ClusterTwoColor(
        red7,
        red9,
        orange9,
        orange10,
        xCoordinates,
        yCoordinates,
        countCluster,
      ),
      [TypeCluster.BLUE_ORANGE]: ClusterTwoColor(
        blue15,
        blue16,
        orange9,
        orange10,
        xCoordinates,
        yCoordinates,
        countCluster,
      ),

      [TypeCluster.BLUE_ORANGE_RED]: ClusterThreeColor(xCoordinates, yCoordinates, countCluster),
    };

    return clusterTypes[type];
  };

  return setCluster(type, xCoordinates, yCoordinates, countCluster);
};

export const getSectorPath = (x: number, y: number, outerDiameter: number, a1: number, a2: number) => {
  const degtorad = Math.PI / 180;
  const halfOuterDiameter = outerDiameter / 2;
  const cr = halfOuterDiameter - 1;
  const cx1 = Math.cos(degtorad * a2) * cr + x;
  const cy1 = -Math.sin(degtorad * a2) * cr + y;
  const cx2 = Math.cos(degtorad * a1) * cr + x;
  const cy2 = -Math.sin(degtorad * a1) * cr + y;

  return `M${x} ${y} ${cx1} ${cy1} A${cr} ${cr} 0 0 1 ${cx2} ${cy2}Z`;
};

const renderPathArray = (
  countSector: number,
  sizeSector: number,
  outerDiameter: number,
  arrayColor: string[],
  scale: number,
) => {
  // <path d={getSectorPath(outerDiameter / 2, outerDiameter / 2, outerDiameter, 180, 360)} fill="#f00" />;

  const arrayPath = new Array(countSector).fill(null);

  const firstDegreesValue = countSector < 2 ? 0 : 45;
  const secondDegreesValue = countSector < 2 ? 360 : firstDegreesValue + sizeSector;

  const result = arrayPath.map((item, i) => {
    return (
      <path
        d={getSectorPath(
          outerDiameter / 2,
          outerDiameter / 2,
          outerDiameter,
          !!i ? sizeSector * i + firstDegreesValue : firstDegreesValue,
          !!i ? sizeSector * (i + 1) + firstDegreesValue : secondDegreesValue,
        )}
        fill={`${arrayColor[i]}`}
        transform={`scale(${1 / scale} ${1 / scale})`}
      />
    );
  });

  return result;
};

export const generateSVG = (
  arrayColor: string[],
  outerDiameter: number,
  countNode: number,
  point: any,
  getX: any,
  getY: any,
  scale: number,
  handleZoom: any,
  keyCluster: any,
  setActiveClusterIndex: any,
  activeClusterIndex: any,
) => {
  const countSector = !!arrayColor.length ? arrayColor.length : 1;
  const sizeSector = !!countSector ? 360 / countSector : 360;

  const getColor = (num: string) => {
    const color: any = {
      blue: '#4E71EE',
      orange: '#FFB862',
      red: ' #EE445D',
    };

    return color[num];
  };

  return (
    <svg
      width={'100%'}
      height={'100%'}
      x={getX(point.pixel_position.x) - outerDiameter / 2 + 1}
      y={getY(point.pixel_position.y) - outerDiameter / 2 + 1}
      onClick={() => {
        handleZoom(true);
        setActiveClusterIndex(keyCluster);
      }}
    >
      {countSector < 2 ? (
        <circle
          cx={17}
          cy={17}
          r={`${outerDiameter / 2 - 1}`}
          fill={`${arrayColor[0]}`}
          transform={`scale(${1 / scale} ${1 / scale})`}
        />
      ) : (
        renderPathArray(countSector, sizeSector, outerDiameter, arrayColor, scale)
      )}
      <text
        x={countSector < 2 ? '17px' : '13px'}
        y={countSector < 2 ? '17px' : '15px'}
        dominant-baseline="middle"
        fill="white"
        text-anchor="middle"
        font-size="13px"
        font-weight="500"
        transform={`scale(${1 / scale} ${1 / scale})`}
      >
        {countNode}
      </text>
      {activeClusterIndex === keyCluster ? (
        <circle
          cx={17}
          cy={17}
          fill={`${arrayColor[0]}`}
          r={20}
          stroke={getColor(point?.last_seen_color)}
          stroke-width="2"
          transform={`scale(${1 / scale} ${1 / scale})`}
        >
          <animate attributeName="r" from="14" to="18" dur="1.5s" begin="0s" repeatCount="indefinite" />
          <animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite" />
        </circle>
      ) : null}
    </svg>
  );
};
