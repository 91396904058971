import React from 'react';

export const AttachIcon = () => {
  return (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M12.9637 10.2866L14.7315 8.51879C15.1184 8.13187 15.5777 7.82496 16.0832 7.61557C16.5887 7.40617 17.1306 7.2984 17.6777 7.2984C18.2249 7.2984 18.7667 7.40617 19.2723 7.61557C19.7778 7.82496 20.2371 8.13187 20.624 8.51879C21.0109 8.9057 21.3178 9.36503 21.5272 9.87055C21.7366 10.3761 21.8444 10.9179 21.8444 11.4651C21.8444 12.0122 21.7366 12.5541 21.5272 13.0596C21.3178 13.5651 21.0109 14.0244 20.624 14.4113L18.8562 16.1791M15.3207 19.7146L13.5529 21.4824C13.166 21.8693 12.7067 22.1762 12.2012 22.3856C11.6957 22.595 11.1538 22.7028 10.6067 22.7028C9.5016 22.7028 8.44179 22.2638 7.66039 21.4824C6.87899 20.701 6.44 19.6412 6.44 18.5361C6.44 17.4311 6.87899 16.3713 7.66039 15.5899L9.42816 13.8221"
          stroke="white"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.7853 17.3574L16.4993 12.6434"
          stroke="white"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" transform="translate(0 15.001) rotate(-45)" />
        </clipPath>
      </defs>
    </svg>
  );
};
