import React, { useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { DocumentCard } from '../../../../components/documentCard/DocumentCard';
import { media } from '../../../../styles/media';
import { getRequiredDateFormat } from '../../../../utils/date/date-format';
import { useGetDocumentsData } from '../../../../api/manageDocuments';
import { Loader, LoaderType } from '../../../../features/loader/Loader';
import { ReactComponent as NoDataIcon } from '../../../../assets/No_Data.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/userSlice';
import { ButtonIconType, CommonButton } from '@bit/first-scope.common-button';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from '../../../../utils/react-router-dom-abstraction';
import { colorFetch } from '../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 45px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 45px 45px 0 0;
  background: ${colorFetch('gray5')};
  overflow: scroll;
  ${media.tablet`
    padding: 0 25px 20px 25px;
  `}
  ${media.phone`
    padding: 0 15px 0 15px;
  `}
`;

const Title = styled(Text)`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
  color: ${colorFetch('black1')};
  margin-left: 10px;
` as any; // @TODO fix type

const ExtraWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 35px;
  gap: 20px;
  ${media.tablet`
    grid-template-columns: 1fr;
  `}
`;

type CardWrapperProps = {
  isEmpty: boolean;
};

const CardWrapper = styled.div<CardWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  height: ${({ isEmpty }) => (isEmpty ? '100%' : 'max-content')};
  gap: 20px;
  padding-bottom: 20px;
  ${media.tablet`
    justify-content: space-between;
    gap: 10px;
  `}
`;

const WrapperEmptyBlock = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(NoDataIcon)`
  margin-right: 6px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 45px;
  ${media.tablet`
    margin-bottom: 30px;
  `}
`;

interface CertificatesProps {}

const addKeyIsLink = (data: any) => {
  const certificates = data?.certificates.map((certificate: Object) => ({ ...certificate, isLink: true }));
  const files = data?.files.map((file: Object) => ({ ...file, isLink: false }));
  return { ...data, files, certificates };
};

export const CertificatesPage: React.FC<CertificatesProps> = () => {
  const language = useSelector(selectLanguageStrings);
  const [isLoading, setIsLoading] = useState(true);
  const pathParams = useParams();
  const dataForPassword = useLocation();
  const password = dataForPassword?.state?.state?.password;
  const certificatesData = useGetDocumentsData(
    pathParams.id || '',
    pathParams.order || '',
    pathParams.lang || '',
    setIsLoading,
    password,
  );
  const modifyCertificates = certificatesData?.files ? addKeyIsLink(certificatesData) : null;
  const certificates = modifyCertificates?.certificates;
  const theme = useTheme();
  const history = useHistory();
  const { primary, secondary2 } = theme.colors;

  return (
    <>
      {isLoading ? (
        <Loader type={LoaderType.CLASSIC} />
      ) : (
        <Wrapper>
          <div>
            <TitleWrapper>
              <CommonButton
                width={'32px'}
                height={'32px'}
                backgroundColor={primary}
                backgroundColorHover={secondary2}
                borderColorHover={secondary2}
                iconType={ButtonIconType.ARROW_LEFT}
                iconWithoutText
                onClick={() => {
                  history.goBack();
                }}
              />
              <Title>{language ? language.externalLabelCertificates : 'Certificates'}</Title>
            </TitleWrapper>
            <ExtraWrapper>
              <CardWrapper isEmpty={!certificates?.length}>
                {certificates && certificates.length ? (
                  <>
                    {certificates.map((item: any, i: number) => (
                      <DocumentCard
                        key={i}
                        downloadUrl={item?.download_url}
                        title={item?.name}
                        date={getRequiredDateFormat(item?.upload_date, 'DD.MM.YYYY')}
                        isRedirectLink={item?.isLink}
                        href={item?.href}
                      />
                    ))}
                  </>
                ) : (
                  <WrapperEmptyBlock>
                    <StyledIcon />
                    <Text type={TextType.TEXT_14_GRAY} weight={'400'} lineHeight={'22px'}>
                      {language ? language.noItemsYet : 'No items yet'}
                    </Text>
                  </WrapperEmptyBlock>
                )}
              </CardWrapper>
            </ExtraWrapper>
          </div>
        </Wrapper>
      )}
    </>
  );
};
