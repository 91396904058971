import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { StagesState } from './manageStagesInterfaces';

const initialState: StagesState = {
  list: [{ id: '', name: '', isSelected: false }],
  selected_stages: [],
  isOpenPopup: false,
};

export const manageStagesSlice = createSlice({
  name: 'manageStages',
  initialState,
  reducers: {
    setStages: (state, action: PayloadAction<any>) => {
      state.list = action.payload.list;
    },
    setSelectedStages: (state, action: PayloadAction<any>) => {
      state.selected_stages = action.payload.list;
    },
    toggleIsSelected: (state, action: PayloadAction<any>) => {
      const index = state.list.findIndex((el: any) => el.id === action.payload.id);
      state.list[index].isSelected = !state.list[index].isSelected;
      state.selected_stages = state.list
        .filter((parameter) => parameter.isSelected === true)
        .map((item: any) => item.id);
    },
    toggleIsOpenPopup: (state) => {
      state.isOpenPopup = !state.isOpenPopup;
    },
  },
});

export const { setStages, setSelectedStages, toggleIsSelected, toggleIsOpenPopup } = manageStagesSlice.actions;

export const SelectStagesList = (state: RootState) => {
  return state.manageStages.list;
};
export const SelectStagesSelectedStages = (state: RootState) => {
  return state.manageStages.selected_stages;
};
export const SelectStagesState = (state: RootState) => {
  return state.manageStages;
};

export default manageStagesSlice.reducer;
