import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@bit/first-scope.common-button';
import { ReactComponent as Lock } from './assets/LockIcon.svg';
import { ReactComponent as EyeSlash } from './assets/EyeSlash.svg';
import { ReactComponent as Eye } from './assets/Eye.svg';
import { media } from '../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { Footer } from '../../containers/common/documentsPreview/components/footer/Footer';
import { InputModal } from '../inputModal/InputModal';
import { useMediaType } from '../../styles/style.context';
import { useNotifierFunctions } from '../../features/notifier2';
import { useLocation, useParams } from 'react-router-dom';
import { getDocumentsDataWithPassword } from '../../api/manageDocuments';
import { useCustomHistory } from '../../utils/react-router-dom-abstraction';
import { useLanguagePicker } from '../../languages/useLanguagePicker';
import { colorFetch } from '../../styles/utils';

const ExtraWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const PasswordWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  ${media.phone`
    height: auto;
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: ${colorFetch('pureWhite')};
  box-shadow: 0px 2px 8px rgba(96, 97, 112, 0.06);
  border-radius: 12px;
  padding: 35px 25px 25px;
`;

const StyledInput = styled(InputModal)`
  margin: 20px 0 0;
  & > div > div > svg > path {
    stroke: ${colorFetch('gray2')};
  }
  & > div > div:nth-child(3) {
    top: 11px;
  }
`;

const StyledText = styled(Text)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${colorFetch('black1')};
`;

const Button = styled(CommonButton)`
  width: 100%;
  height: 40px;
  margin-top: 25px;
`;

const TextHepl = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${colorFetch('gray2')};
  margin-top: 25px;
`;

const TextContactUs = styled.a`
  text-decoration: none;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${colorFetch('primary')};
  &::after {
    content: '';
    position: absolute;
    background-color: ${colorFetch('primary')};
    left: 0;
    bottom: 2px;
    width: 100%;
    height: 1px;
  }
`;

interface DocumentPreviewPasswordPageProps {
  onNextButtonClick?: () => void;
}

export const DocumentPreviewPasswordPage: React.FC<DocumentPreviewPasswordPageProps> = () => {
  const search = useLocation().search;
  const { changeLanguageHandler } = useLanguagePicker();
  const [showPassword, setShowPassword] = useState(false);

  const [inputValue, setInputValue] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const { addNotification } = useNotifierFunctions();
  const pathParams = useParams();
  const lang = new URLSearchParams(search).get('lang');
  const history = useCustomHistory();

  const { tablet } = useMediaType();

  const { tabletNavigation } = useMediaType();

  const language = useSelector(selectLanguageStrings);

  const onAdditionalImageClick = () => {
    setShowPassword(!showPassword);
  };

  const onNextButtonClick = () => {
    getDocumentsDataWithPassword(pathParams.id || '', lang, setIsLoading, inputValue, addNotification, history);
    setInputValue('');
  };

  useEffect(() => {
    changeLanguageHandler(lang ? lang : 'en_gb', true);
  }, [lang]);

  return (
    <ExtraWrapper>
      <PasswordWrapper>
        <Wrapper>
          <StyledText type={TextType.CHIPS_BLACK}>{language.enterPassword}</StyledText>
          <StyledInput
            value={inputValue}
            name={language.password}
            placeholder={language.enterPassword}
            image={<Lock />}
            additionalImage={showPassword ? <Eye /> : <EyeSlash />}
            additionalImageOnClick={onAdditionalImageClick}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInputValue(e.currentTarget.value);
            }}
            type={showPassword ? 'text' : 'password'}
          />
          <Button onClick={onNextButtonClick}>{language.nextButton}</Button>
          <TextHepl type={TextType.TEXT_14_GRAY}>
            {language.havingTrouble + ' '}
            <TextContactUs
              href={'https://www.valmont.pl/valmont-poland/pl/kontakt/dział-handlowy'}
              target="_blank"
              rel={'noopener noreferer'}
            >
              {language.previewContactUs}
            </TextContactUs>
          </TextHepl>
        </Wrapper>
      </PasswordWrapper>
      <Footer
        width={'100%'}
        address={
          !tabletNavigation
            ? '08-110 Siedlce, ul. Majora Henryka Sucharskiego 6'
            : '08-110 Siedlce, ul. mjr H. Sucharskiego 6'
        }
        valmontLink={'www.valmont.pl'}
        className={tablet ? 'passwordPage' : ''}
      />
    </ExtraWrapper>
  );
};
