import React from 'react';
import { useLocation } from 'react-router-dom';
import { SmartSearch } from './SmartSearch';
import { OrderSmartSearch } from './OrderSmartSearch';
import { NewSmartSearch } from '../../../components/newSmartSearch/NewSmartSearch';
import { PATHS } from '../../../routes';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/userSlice';

const SearchType = {
  PACKAGE: 'package',
  ORDER: 'order',
  INVENTORY: 'inventory',
  DEFAULT: 'default',
};

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const SearchSystem = () => {
  const language = useSelector(selectLanguageStrings);

  const breadcrumbsOrder = [
    {
      id: '0',
      path: PATHS.USER_SEARCH,
      name: language.breadcrumbsOrderSearch,
      view: '',
    },
    {
      id: '1',
      path: '',
      name: language.breadcrumbsOrderOrder,
      view: '',
    },
  ];

  const switchToType = {
    [SearchType.PACKAGE]: (() => <div>PACKAGE</div>)(),
    [SearchType.ORDER]: (() => <NewSmartSearch breadcrumbsArray={breadcrumbsOrder} />)(),
    [SearchType.INVENTORY]: (() => <div>INVENTORY</div>)(),
    [SearchType.DEFAULT]: (() => <SmartSearch />)(),
  };

  let query = useQuery();
  const typeSearch = query.get('type') || SearchType.DEFAULT;

  return <>{switchToType[typeSearch as any]}</>;
};
