export enum TypeOfDeviceParameter {
  ALL = 'ALL',
  NODE = 'NODE',
  TRACKER = 'TRACKER',
  OFFLINE = 'OFFLINE',
}

export enum TypeOfAttachParameter {
  ATTACHED = 'ATTACHED',
  DETACHED = 'DETACHED',
}

export enum TypeEntity {
  PACKAGE = 'PACKAGE',
  INVENTORY = 'INVENTORY',
}

export const initialDeviceState = {
  [TypeOfDeviceParameter.NODE]: {
    id: '600abdbc14b425baebfce54d',
    isSelected: true,
    name: 'Node',
    type: TypeOfDeviceParameter.NODE,
  },

  [TypeOfDeviceParameter.TRACKER]: {
    id: '600abdbc14b425baebfce56d',
    isSelected: true,
    name: 'Tracker',
    type: TypeOfDeviceParameter.TRACKER,
  },

  [TypeOfDeviceParameter.OFFLINE]: {
    id: '600abdbc14b425baebfce57d',
    isSelected: true,
    name: 'Offline',
    type: TypeOfDeviceParameter.OFFLINE,
  },
};

export const initialAttachState = {
  [TypeOfAttachParameter.ATTACHED]: {
    id: '600abdbc14b425baebfce58d',
    isSelected: true,
    name: 'Attached',
    type: TypeOfAttachParameter.ATTACHED,
  },

  [TypeOfAttachParameter.DETACHED]: {
    id: '600abdbc14b425baebfce59d',
    isSelected: true,
    name: 'Detached',
    type: TypeOfAttachParameter.DETACHED,
  },
};
