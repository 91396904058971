export enum ObjectType {
  COMPONENTS = 'COMPONENTS',
  INVENTORIES = 'INVENTORIES',
  DEVICES = 'DEVICES',
  ORDERS = 'ORDERS',
  PACKAGES = 'PACKAGES',
}

export enum RoleType {
  TRACKER = 'devices',
  PACKAGES = 'packages',
}
