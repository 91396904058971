import React, { FC, useState, useEffect } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import withAuth from '../../../../../features/authentication/withAuth';
import { TooltipContextProvider } from '../../../../../utils/TooltipContextProvider';
import { useZoomAndPinch } from '../../../../../utils/useZoomAndPinch';
import { media } from '../../../../../styles/media';
import plus from '../../../../../assets/plus.svg';
import minus from '../../../../../assets/minus.svg';
import x from '../../../../../assets/x.svg';
import { generateSVG } from './Cluster';
import { useClustering } from '../../../../../features/clustering/clusteringFloorMapDetails';
import { Loader, LoaderType } from '../../../../../features/loader/Loader';

export enum TypeCluster {
  RED = 'RED',
  BLUE = 'BLUE',
  ORANGE = 'ORANGE',

  RED_BLUE = 'RED_BLUE',
  RED_ORANGE = 'RED_ORANGE',
  BLUE_ORANGE = 'BLUE_ORANGE',

  BLUE_ORANGE_RED = 'BLUE_ORANGE_RED',
}

export const colors = ['#4E71EE'];

const getColor = (num: string) => {
  const color: any = {
    blue: '#4E71EE',
    orange: '#FFB862',
    red: ' #EE445D',
  };

  return color[num];
};

const getColorArray = (arrayKeyColors: string[]) => arrayKeyColors.map((colorKey) => getColor(colorKey));

export enum LsValueType {
  token = 'token',
  exp = 'exp',
  locale = 'locale',
  realTimeConfig = 'realTimeConfig',
  internalMapping = 'internalMapping',
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledAssetMap = styled.div`
  height: calc(100vh - 310px);
  overflow: hidden;
  position: relative;
  border: ${(props: any) => `1px solid ${props.theme.colors.gray3}`};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

const ZoomToolsContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  z-index: 99;
  ${media.phoneXS`
    bottom: 40px;
  `}
`;

const ToolsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 0;
  background-color: white;
  outline: transparent;
  width: 32px;
  height: 32px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 3px 6px rgba(96, 97, 112, 0.16);
  border: none;
  border-radius: ${(props: any) => props.theme.borderRadius.additional6};
  cursor: pointer;
  transition: all 0.3s linear;
  &:nth-child(1) {
    border-bottom: 1px solid;
    border-color: gray4;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:nth-child(2) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:nth-child(3) {
    margin-top: 6px;
  }
  &:hover {
    background-color: gray6;
  }
`;

interface TypeMap {
  floorPlanImageLink: string;
  handlePointFromMap: (value: any) => void;
  activeDevice: any;
  explorerRequestBody: any;
  isLoadingImageMap: boolean;
  setIsLoadingImageMap: (value: boolean) => void;
  webSocketData: any;
}

export const Map: FC<TypeMap> = ({
  floorPlanImageLink,
  handlePointFromMap,
  activeDevice,
  explorerRequestBody,
  isLoadingImageMap,
  setIsLoadingImageMap,
  webSocketData,
}) => {
  const theme = useTheme();
  const [imageLink, setImageLink] = useState(floorPlanImageLink);
  const [activeClusterIndex, setActiveClusterIndex] = useState(null);

  useEffect(() => {
    setImageLink(explorerRequestBody?.linkImage ? explorerRequestBody?.linkImage : floorPlanImageLink);
  }, [explorerRequestBody, floorPlanImageLink]);

  const imageWidth = (webSocketData?.buildings?.[0]?.floors?.[0]?.image_width || 0) as number;
  const imageHeight = (webSocketData?.buildings?.[0]?.floors?.[0]?.image_height || 0) as number;
  const devicesList = webSocketData?.buildings?.[0]?.floors?.[0]?.devices;
  const nodes =
    devicesList?.[0]?.pixel_position?.x === 0 && devicesList?.[0]?.pixel_position?.y === 0 ? [] : devicesList;

  const { svgRef, gRef, imageWrapperRef, getX, getY, isReady, handleZoom, handleRevert, scale } = useZoomAndPinch({
    imageWidth,
    imageHeight,
  });

  const { filteredClusters } = useClustering(nodes ? nodes : [], scale, 25, webSocketData?.last_updated_at);
  const img = document.querySelector('image');
  img?.addEventListener('load', () => setIsLoadingImageMap(false));

  return (
    <TooltipContextProvider>
      <Wrapper>
        <StyledAssetMap theme={theme}>
          {isLoadingImageMap && <Loader style={{ height: '100%', position: 'static' }} type={LoaderType.CLASSIC} />}

          <svg style={{ height: '100%', width: '100%' }} ref={svgRef}>
            <rect fill="#E4E4EB" width="100%" height="100%" y="0" x="0" strokeWidth="0" />
            <g style={{ height: '100%', width: '100%' }} transform="matrix(1 0 0 1 0 0)" ref={gRef}>
              <g style={{ height: '100%', width: '100%' }} fill="gray" ref={imageWrapperRef}>
                <image
                  xlinkHref={imageLink}
                  width="100%"
                  height="100%"
                  x="0"
                  y="0"
                  onLoad={() => setIsLoadingImageMap(false)}
                />
              </g>
              <g>
                {!!filteredClusters.length
                  ? filteredClusters.map((point: any, lineIndex: any) => {
                      return point.isCluster ? (
                        generateSVG(
                          getColorArray(point?.colorOption),
                          27,
                          point?.ids?.length,
                          point,
                          getX,
                          getY,
                          scale,
                          handleZoom,
                          point?.ids?.[0],
                          setActiveClusterIndex,
                          activeClusterIndex,
                        )
                      ) : (
                        <svg
                          width={'100%'}
                          height={'100%'}
                          x={!!getX(point?.pixel_position?.x) ? getX(point?.pixel_position?.x) - (15 * 1) / scale : 0}
                          y={!!getX(point?.pixel_position?.x) ? getY(point?.pixel_position?.y) - (15 * 1) / scale : 0}
                          key={point?.object_id}
                        >
                          <circle
                            id="circle"
                            cx="14"
                            cy="14"
                            r="6"
                            fill={getColor(point?.last_seen_color)}
                            onClick={() => handlePointFromMap(point?.attached_info?.package?.id)}
                            transform={`scale(${1 / scale} ${1 / scale})`}
                          ></circle>

                          {point?.attached_info?.package?.id === activeDevice?.[0]?.value ? (
                            <circle
                              cx="14"
                              cy="14"
                              fill="none"
                              r="10"
                              stroke={getColor(point?.last_seen_color)}
                              stroke-width="2"
                              transform={`scale(${1 / scale} ${1 / scale})`}
                            >
                              <animate
                                attributeName="r"
                                from="7"
                                to="11"
                                dur="1.5s"
                                begin="0s"
                                repeatCount="indefinite"
                              />
                              <animate
                                attributeName="opacity"
                                from="1"
                                to="0"
                                dur="1.5s"
                                begin="0s"
                                repeatCount="indefinite"
                              />
                            </circle>
                          ) : null}
                        </svg>
                      );
                    })
                  : null}
              </g>
            </g>
          </svg>
          <ZoomToolsContainer>
            <ToolsButton
              onClick={() => {
                handleZoom(true);
              }}
            >
              <img src={plus} alt="plus" />
            </ToolsButton>
            <ToolsButton
              onClick={() => {
                handleZoom(false);
              }}
            >
              <img src={minus} alt="minus" />
            </ToolsButton>
            <ToolsButton
              onClick={() => {
                handleRevert();
              }}
            >
              <img src={x} alt="cross" />
            </ToolsButton>
          </ZoomToolsContainer>
        </StyledAssetMap>
      </Wrapper>
    </TooltipContextProvider>
  );
};

export default withAuth(Map);
