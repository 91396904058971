/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings placeholders require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import { PlaceholderCustomTable } from './components/customTablePlaceholder/CustomPlaceholderTable';
import { PlaceholderAddButton } from './components/PlaceholderAddButton';
import { PlaceholderCalendar } from './components/PlaceholderCalendar';
import { PlaceholderCampusMap } from './components/PlaceholderCampusMap';
import { PlaceholderCard } from './components/PlaceholderCard';
import { PlaceholderGraph } from './components/PlaceholderGraph';
import { PlaceholderMap } from './components/PlaceholderMap';
import { PlaceholderSearch } from './components/PlaceholderSearch';
import { PlaceholderType } from './typePlaceholders/placeholdersType';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';

interface optionsType {
  countRows?: number;
  withoutPagination?: boolean;
  borderRadiusTable?: boolean;
}
export interface PlaceholderProps {
  type?: any;
  options?: optionsType;
  keyword?: any;
}

export const Placeholder: FC<PlaceholderProps> = ({ type, options, keyword }) => {
  const language = useSelector(selectLanguageStrings);
  switch (type) {
    /*Valmont*/
    case PlaceholderType.CAMPUS_MAP_INSIGHT:
      return <PlaceholderCampusMap languageStrings={language} />;
    /********/
    /*NID*/
    // case PlaceholderType.INSIGHT_DETAILS:
    //   return <PlaceholderInsightDetails languageStrings={languageStrings} />;
    /********/
    case PlaceholderType.CUSTOM_TABLE:
      return <PlaceholderCustomTable {...options} />;
    case PlaceholderType.CARD:
      return <PlaceholderCard />;
    case PlaceholderType.CALENDAR:
      return <PlaceholderCalendar />;
    case PlaceholderType.GRAPH:
      return <PlaceholderGraph />;
    case PlaceholderType.SEARCH:
      return <PlaceholderSearch />;
    case PlaceholderType.BUTTON:
      return <PlaceholderAddButton languageStrings={language} />;
    case PlaceholderType.MAP:
      return <PlaceholderMap />;
    default:
      return <div />;
  }
};
