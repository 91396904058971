import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { ArrowButtonProps, WrapperProps } from './types';
import { borderRadiusFetch, colorFetch } from '../../../../../../../../../styles/utils';

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 0 0;
  border: 1px solid
    ${({ isFocused, theme }) => (isFocused ? colorFetch('primary')({ theme }) : colorFetch('gray3')({ theme }))};
  border-radius: ${borderRadiusFetch('primary')};
  transition: all 0.3s ease;
`;

export const Textarea = styled(TextareaAutosize)`
  box-sizing: border-box;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 22px;
  color: ${colorFetch('gray1')};
  border: none;
  border-radius: ${borderRadiusFetch('primary')};
  padding: 8px 47px 8px 12px;
  margin: 0;
  outline: none;
  resize: none;
  overflow: hidden;
  &::placeholder {
    color: ${colorFetch('gray2')};
  }
`;

export const ArrowButton = styled.div<ArrowButtonProps>`
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  background-color: ${({ disabled, theme }) =>
    disabled ? colorFetch('gray2')({ theme }) : colorFetch('primary')({ theme })};
  border-radius: 4px;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${({ disabled, theme }) => !disabled && colorFetch('secondary2')({ theme })};
  }
`;

export const LabelBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 11px 12px 3px;
  transition: all 0.3s ease;
`;

export const Label = styled.div`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${colorFetch('primary')};
`;

export const CrossButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover svg > path {
    transition: all 0.3s ease;
    stroke: ${colorFetch('gray1')};
  }
`;
