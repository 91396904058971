/**
 * @description
 * This is component for gradient effect for map
 * all the functionalities cthings require for maps with gradient
 * good customisable.
 *
 */

import styled from 'styled-components';
import React, { FC } from 'react';
import { colorFetch } from '../../styles/utils';

const Wrapper = styled.div`
  width: 100%;
  height: 137px;

  background: linear-gradient(
    180deg,
    rgba(242, 242, 245, 0) 0%,
    ${colorFetch('gray5')} 62.5%,
    ${colorFetch('gray5')} 100%
  );

  position: absolute;
  bottom: 0px;
  z-index: 100;
  pointer-events: none;
`;

export interface OverlayGradientProps {
  className?: string;
}

export const OverlayGradient: FC<OverlayGradientProps> = ({ ...props }) => {
  return <Wrapper {...props}></Wrapper>;
};
