/**
 * @description
 * This is component for table in documents preview
 */

import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/userSlice';
import { ContentTable } from '../../../../components/orderTableInfo/contentTable/ContentTable';
import { media } from '../../../../styles/media';
import { getRequiredDateFormat } from '../../../../utils/date/date-format';

const Wrapper = styled.div`
  max-width: 415px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > div {
    margin-top: 0;
  }
  ${media.tabletS`
    max-width: 100%;
    width: 100%;
  `}
`;

export const DocumentPreviewTable = (data: any) => {
  const language = useSelector(selectLanguageStrings);
  const { attached_component, attached_order } = data?.data;

  // const nameLabel = isClasp
  //   ? language?.externalLabelComponentID || 'Order ID'
  //   : language?.labelDescription || 'Description';

  const arr = [
    {
      property: language?.externalLabelComponentID || 'Order ID',
      value: attached_order?.generic_id,
    },
    {
      property: language?.orderDate || 'Order Date',
      value: getRequiredDateFormat(attached_order?.created_at, 'DD.MM.YYYY'),
    },
    {
      property: language?.externalLabelIndex || 'Index',
      value: attached_component[0]?.index,
    },
    {
      property: language?.externalLabelIndexDescription || 'Index description',
      value: attached_component[0]?.index_desc,
    },

    {
      property: language?.externalLabelQuantity || 'Quantity',
      value: attached_order?.components[0]?.quantity,
    },
  ];

  return (
    <Wrapper>
      <ContentTable
        placeholderProps={{
          keyword: 'DocumentPreviewTable',
          style: { width: '100%' },
          placeholderOptions: { countRows: 4, withoutPagination: true },
        }}
        headerLabels={[
          { name: language ? language.propertyOfTable : 'Property', isSorted: false },
          { name: language ? language.valueOfTable : 'Value', isSorted: false },
        ]}
        headerGridTemplate={{ desktop: '150px 1fr', tablet: '1fr 1fr', phone: '115px 1fr' }}
        valueGridTemplate={{ desktop: '150px 1fr', tablet: '1fr 1fr', phone: '115px 1fr' }}
        arr={arr}
        completionStatus={''}
      />
    </Wrapper>
  );
};
