/**
 * @description
 * This is component for search in select
 * all the functionalities cthings require for SearchInput
 * Highly customisable.
 *
 */

import React from 'react';
import styled from 'styled-components';
import { CloseButton } from '@bit/first-scope.close-button';
import { ReactComponent as SearchIcon } from '../../../../assets/Search.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/userSlice';
import { useTheme } from '@cthings.co/styles';
import { colorFetch } from '../../../../styles/utils';

type WrapperProps = {
  isBorder?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 9px;
  border: ${({ isBorder }) => (isBorder ? '1px solid' : 'none')};
  border-bottom: 1px solid;
  border-radius: ${({ isBorder }) => (isBorder ? '8px' : 'none')};
  border-color: ${colorFetch('gray3')};
  & > svg > path {
    stroke: ${colorFetch('primary')};
  }
`;

const Input = styled.input`
  width: calc(100% - 36px);
  margin-left: 4px;
  border: none;
  padding: none;
  outline: none;
  font-size: 12px;
  line-height: 24px;
  font-weight: normal;
  font-family: 'Poppins';
  color: ${colorFetch('gray1')};
  &::placeholder {
    color: ${colorFetch('gray2')};
  }
`;

export const SearchInput = ({
  value,
  handleOnChange,
  handleClearInput,
  isBorder,
}: {
  value: string;
  handleOnChange: (value: string) => void;
  handleClearInput: () => void;
  isBorder?: boolean;
}) => {
  const theme = useTheme();
  const language = useSelector(selectLanguageStrings);

  return (
    <Wrapper theme={theme} isBorder={isBorder}>
      <SearchIcon />
      <Input
        value={value}
        // @TODO fix handleOnChange type
        onChange={handleOnChange as any}
        placeholder={language ? language.searchPlaceholder : 'Search...'}
      />
      {value && <CloseButton onClick={handleClearInput} width={'16px'} height={'16px'} strokeWidth={'2px'} />}
    </Wrapper>
  );
};
