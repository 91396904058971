import React from 'react';

export const RevokeIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.2998 8.53333C19.3484 9.58232 20.0625 10.9187 20.3517 12.3735C20.6409 13.8283 20.4922 15.3361 19.9245 16.7065C19.3567 18.0768 18.3954 19.248 17.1621 20.072C15.9288 20.896 14.4789 21.3358 12.9956 21.3358C11.5124 21.3358 10.0624 20.896 8.82909 20.072C7.59577 19.248 6.63448 18.0768 6.06675 16.7065C5.49902 15.3361 5.35035 13.8283 5.63954 12.3735C5.92873 10.9187 6.64279 9.58232 7.69144 8.53333"
        stroke={color ? color : 'white'}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 4.66663V13"
        stroke={color ? color : 'white'}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
