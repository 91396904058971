/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings inputs in modals in tables require
 * Highly customisable.
 *
 */

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/media';
import { Text, TextType } from '@bit/first-scope.text';
import { TextareaModal } from '../textareaModal/TextareaModal';
import { ReactComponent as Info } from '../../assets/infoButton.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';
import { useTheme } from '@cthings.co/styles';

type ExtraWrapperProps = {
  width?: string;
  margin?: string;
};

const ExtraWrapper = styled.div<ExtraWrapperProps>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin || '0 0 14px 0'};
`;

type WrapperProps = {
  inputWidth?: string;
};

const Wrapper = styled.div<WrapperProps>`
  width: ${({ inputWidth }) => inputWidth || '100%'};
  height: max-content;
  display: flex;
  position: relative;
`;

type InputElementProps = {
  disabled?: boolean;
  isFocused: boolean;
  isError?: boolean;
  image: boolean;
};

const InputElement = styled.input<InputElementProps>`
  width: 100%;
  height: 40px;
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
  background-color: ${colorFetch('pureWhite')};
  color: ${colorFetch('gray1')};
  &::placeholder {
    font-family: 'Poppins', sans-serif;
    -webkit-safari-color: ${colorFetch('gray2')};
    color: ${colorFetch('gray2')};
  }
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid
    ${({ disabled, isError, isFocused, theme }) =>
      disabled
        ? colorFetch('gray5')({ theme })
        : isError
        ? colorFetch('red1')({ theme })
        : isFocused
        ? colorFetch('primary')({ theme })
        : colorFetch('gray3')({ theme })};

  border-radius: ${borderRadiusFetch('primary')};
  padding: ${({ image }) => (image ? '8px 36px 8px 38px' : '8px 12px')};
  outline: none;
  transition: all 0.3s linear;
  text-overflow: ellipsis;

  &.disabled {
    background-color: ${colorFetch('gray5')} !important;
    -webkit-safari-color: ${colorFetch('gray1')};
    color: ${colorFetch('gray1')} !important;
    -webkit-text-fill-color: ${colorFetch('gray1')};
    &::placeholder {
      -webkit-safari-color: ${colorFetch('gray1')};
      color: ${colorFetch('gray1')};
    }
  }
  opacity: 1; /* required on iOS */
  ${media.tablet`
    -webkit-appearance: caret;
    -moz-appearance: caret; /* mobile firefox too! */
  `}
`;

const ImageWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 10px;
  & > svg > path {
    stroke: ${colorFetch('black1')};
  }
`;

const AdditionalImage = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

const Message = styled(Text)`
  width: 100%;
`;

const TooltipWrapper = styled.div`
  display: flex;
  margin: 0 0 4px 6px;
`;

const TooltipBlock = styled.div`
  position: absolute;
  background: ${colorFetch('black')};
  bottom: calc(100% + 10px);
  min-width: 200px;
  width: 100%;
  text-align: center;
  z-index: 3;
  line-height: 15px;
  padding: 6px;
  border-radius: 12px;
  left: 50%;
  -webkit-transform: translate(-51%, 0);
  -ms-transform: translate(-51%, 0);
  transform: translate(-51%, 0);
  transition: visibility 0s, opacity 0.5s linear;
  visibility: hidden;
  opacity: 0;
  &::after {
    position: absolute;
    content: '';
    border-bottom: 15px solid ${colorFetch('black')};
    border-left: 15px solid transparent;
    bottom: -6px;
    transform: rotate(45deg);
    left: calc(50% - 5px);
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  height: max-content;
  margin-left: 4px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &:hover ${TooltipBlock} {
    visibility: visible;
    opacity: 1;
  }
`;

const InfoIcon = styled(Info)`
  & > path {
    stroke: ${colorFetch('primary')};
  }
  &:hover > path {
    transition: all 0.3s linear;
    stroke: ${colorFetch('secondary2')};
  }
`;

const TooltipText = styled(Text)`
  color: ${colorFetch('pureWhite')};
  font-size: 10px;
  line-height: 15px;
` as any; // @TODO fix type

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: max-content !important;
`;

const HelpText = styled.a`
  color: ${colorFetch('primary')};
  text-decoration: none;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-right: 7px;
`;

export interface InputProps {
  value: string;
  onChange: any;
  name?: string;
  placeholder: string;
  onClick?: any;
  setIsError?: any;
  type?: string;
  image?: any;
  additionalImage?: any;
  additionalImageOnClick?: any;
  disabled?: boolean;
  width?: string;
  margin?: string;
  rows?: number;
  startedValidation?: boolean;
  isError?: boolean;
  fromAttach?: boolean;
  className?: string;
  isTooltip?: boolean;
  tooltipText?: string;
  inputWidth?: string;
  helperTextData?: {
    link: string;
    helperText: string;
  };
}

export const InputModal: FC<InputProps> = ({
  value,
  onChange,
  onClick,
  setIsError,
  name,
  placeholder,
  image,
  type,
  width,
  margin,
  additionalImage,
  additionalImageOnClick,
  rows,
  disabled,
  isError,
  startedValidation,
  fromAttach,
  isTooltip,
  tooltipText,
  inputWidth,
  helperTextData,
  ...props
}) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const languageStrings = useSelector(selectLanguageStrings);
  const { gray1, red2 } = theme.colors;
  const isShowMessage = fromAttach ? !!isError : startedValidation && !!isError && !disabled;

  return (
    <ExtraWrapper margin={margin} {...props}>
      {name && (
        <LabelWrapper>
          <TooltipWrapper>
            <Text type={TextType.TEXT_12_BLACK} color={gray1}>
              {name}
            </Text>

            {tooltipText && (
              <InfoWrapper>
                <InfoIcon theme={theme} />
                <TooltipBlock>
                  <TooltipText>{tooltipText ?? ''}</TooltipText>
                </TooltipBlock>
              </InfoWrapper>
            )}
          </TooltipWrapper>

          {helperTextData && (
            <HelpText href={helperTextData?.link} target="_blank" rel={'noopener noreferer'}>
              {helperTextData?.helperText}
            </HelpText>
          )}
        </LabelWrapper>
      )}
      <Wrapper inputWidth={inputWidth}>
        {image ? <ImageWrapper>{image}</ImageWrapper> : null}
        {rows && rows > 1 ? (
          <TextareaModal
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onClick={onClick}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            isFocused={isFocused}
            isError={startedValidation && isError}
            disabled={disabled}
            isImage={image}
            rows={rows}
          />
        ) : (
          <InputElement
            type={type ? type : 'text'}
            onClick={onClick}
            value={value ?? 'N/A'}
            placeholder={placeholder}
            className={disabled ? 'disabled' : ''}
            disabled={disabled}
            isFocused={isFocused}
            isError={fromAttach ? isError : startedValidation && isError}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            onChange={onChange}
            image={image}
          />
        )}

        {additionalImage ? <AdditionalImage onClick={additionalImageOnClick}>{additionalImage}</AdditionalImage> : null}
      </Wrapper>
      {isShowMessage && (
        <Message type={TextType.TEXT_12_GRAY} color={red2} margin={'4px 0 0 12px'}>
          {`${name} ${languageStrings ? languageStrings.inputErrorText : " doesn't look right"}`}
        </Message>
      )}
    </ExtraWrapper>
  );
};
