/**
 * @description
 * This is component for all filtering floor Plan mobile version.
 *
 */

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '../../../../components/commonButton/CommonButton';
import { media } from '../../../../styles/media';
import { CloseButtonRound } from '../closeButton/CloseButtonRound';
import { Switch } from '../switch/Switch';
import { DeviceFilterMobile } from './components/deviceFilterMobile/DeviceFilterMobile';
import { AttachFilterMobile } from './components/attachFilterMobile/AttachFilterMobile';
import { useMobileFilters } from '../../../../containers/common/campusMapInsight/Content/mobileFilterContext/mobileContext';
import { EntityFilterMobile } from './components/entityFilterMobile/EntityFilterMobile';
import { useSelector } from 'react-redux';
import { selectLanguage, selectLanguageStrings } from '../../../../app/state/userSlice';
import { borderRadiusFetch } from '../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';
import { usePrevious } from '@cthings.co/utils';

const StyledModal = styled(Modal)`
  z-index: 10000000;
  ${media.phone`
  align-items: flex-end;
  & > div {
      width: 100%;
      align-items: flex-end;
    }
  `}
`;

const StyledContainer = styled(ModalContainer)`
  width: 528px;
  align-items: flex-start;
  padding: 24px;
  ${media.phone`
    width: 100%;
    border-radius: 24px 24px 0 0;
    padding: 16px;
  `}
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: none;
  }
  ${media.phone`
  & > div {
    display: flex;
  }
  `}
`;

const ScrolledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${media.phone`
    height: 100%;
    border-radius: ${borderRadiusFetch('primary')};
  `}
`;

const StyledText = styled(Text)`
  font-size: 18px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  & > button:first-child {
    margin-top: 25px;
  }
  & > button:nth-child(2) {
    margin-top: 10px;
  }
  ${media.phone`
    flex-direction: column;
    justify-content: space-between;
  `}
`;

const StyledButton = styled(CommonButton)`
  width: 104px;
  ${media.phone`
    width: 100%;
    height: 40px;
  `}
`;

const WrapperSelect = styled.div`
  margin-top: 30px;
  width: 100%;
`;

export const MobileFilters = ({ isOpenModal, closeModal }: { isOpenModal: boolean; closeModal: any }) => {
  const theme = useTheme();
  const { pureWhite, black1, primary } = theme.colors;

  const { stateMobile, dispatchMobile } = useMobileFilters();
  const { isZones } = stateMobile?.firstModal.firstModalZonesState;
  const language = useSelector(selectLanguage);
  const prevLanguageName = usePrevious(language.name);

  const languageStrings = useSelector(selectLanguageStrings);

  const handleSwitch = () => {
    dispatchMobile({ type: 'SELECT_ZONES' });
  };

  const wrapperRef = useRef<any>(null);

  const closeMainModal = () => {
    dispatchMobile({ type: 'HANDLE_CLOSE_MAIN_MODAL' });
    closeModal(false);
  };

  const handleDone = () => {
    dispatchMobile({ type: 'HANDLE_DONE_MAIN_MODAL' });
    closeMainModal();
  };

  const handleClearAll = () => {
    dispatchMobile({ type: 'SELECT_CLEAR_ALL_ATTACH' });
    dispatchMobile({ type: 'SELECT_CLEAR_ALL_INVENTORIES' });
    dispatchMobile({ type: 'SELECT_CLEAR_ALL_ENTITY' });
    dispatchMobile({ type: 'SELECT_CLEAR_ALL_DEVICE' });
    dispatchMobile({ type: 'SELECT_ZONES' });
    dispatchMobile({ type: 'SELECT_CLEAR_MAIN_MODAL' });
  };

  useEffect(() => {
    prevLanguageName &&
      prevLanguageName !== language.name &&
      dispatchMobile({ type: 'SET_LANGUAGE', payload: languageStrings });
  }, [language.name, prevLanguageName]);

  return (
    <>
      <StyledModal isOpenModal={isOpenModal}>
        <StyledContainer>
          <TitleWrapper>
            <StyledText type={TextType.TITLE_PRIMARY}>
              {languageStrings ? languageStrings.labelSettings : 'Settings'}
            </StyledText>
            <CloseButtonRound closeOnClick={closeMainModal} />
          </TitleWrapper>
          <WrapperSelect>
            <DeviceFilterMobile languageStrings={languageStrings} />
            <AttachFilterMobile languageStrings={languageStrings} />
            <EntityFilterMobile languageStrings={languageStrings} />
          </WrapperSelect>
          <ScrolledWrapper ref={wrapperRef}>
            <Switch
              switchName={languageStrings ? languageStrings.labelZones : 'Zones'}
              stateSwitch={isZones}
              handleStateSwitch={handleSwitch}
              circleColor={theme.colors.gray2}
            />
          </ScrolledWrapper>

          <ButtonWrapper>
            <StyledButton onClick={handleClearAll} width={'104px'} backgroundColor={pureWhite} color={black1}>
              {languageStrings ? languageStrings.clearAllButton : 'Clear all'}
            </StyledButton>
            <StyledButton onClick={handleDone} width={'104px'} backgroundColor={primary} color={pureWhite}>
              {languageStrings ? languageStrings.doneButton : 'Done'}
            </StyledButton>
          </ButtonWrapper>
        </StyledContainer>
      </StyledModal>
    </>
  );
};
