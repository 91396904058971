import React from 'react';
import styled from 'styled-components';
import { Select } from '../../../../../components/select/Select';
import { media } from '../../../../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { useTableContext, useTableFunctions } from '../../../../../features/universalTable/context';
import { useGetOrders } from '../../../../../api/manageOrders';

const Wrapper = styled.div``;

const StyledSelect = styled(Select)`
  width: 230px;
  & > div {
    min-height: 40px;
  }
  & > div:nth-child(2) {
    top: 44px;
  }
  ${media.tablet`
     width: 100%;
   `}
`;

export const ComponentsSelect = ({
  setValue,
  value,
  label,
  disabled,
}: {
  setValue: any;
  value: any;
  label?: string;
  disabled?: boolean;
}) => {
  const language = useSelector(selectLanguageStrings);

  const listOrders = useGetOrders();

  const { getRowEssentials } = useTableFunctions();
  const [{ openId }] = useTableContext();
  const { setItem } = getRowEssentials(openId ?? '');

  const handleOnChange = (value: any) => {
    setValue(value);
    setItem('name', value.name);
  };

  return (
    <Wrapper>
      <StyledSelect
        isPendingRequest={!listOrders}
        value={value}
        label={label}
        values={listOrders ?? []}
        onChange={handleOnChange}
        disabled={disabled}
        placeholder={language ? language.chooseOrderIDPlaceholder : 'Choose Order ID'}
      />
    </Wrapper>
  );
};
