/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings placeholder for table row require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { borderRadiusFetch, colorFetch } from '../../../../../styles/utils';

type BlockProps = {
  width?: string;
};

const Block = styled.div<BlockProps>`
  height: 16px;
  max-width: ${({ width }) => width || '100px'};
  width: 100%;
  border-radius: ${borderRadiusFetch('primary')};
  background-color: ${colorFetch('gray5')};
`;

export interface ComponentProps {
  width: string;
}

export const PlaceholderBlock: FC<ComponentProps> = ({ width }) => {
  return <Block width={width} />;
};
