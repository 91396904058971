import React, { useEffect, useState } from 'react';
// @ts-ignore
import {
  useOrderList,
  getPackageList,
  useProductionIdsList,
  getDeviceDetailsProductionIds,
} from '../../../../../api/manageDevicesDetails';
import { useGetInventories } from '../../../../../api/manageInventories';
import { setInventoriesAttach } from '../../../../../api/manageInventories';
import { useNotifierFunctions } from '../../../../../features/notifier2';
import { AttachToModal } from './attachToModal/AttachToModal';
import { AttachPackageModal } from './attachToPackageModal/AttachPackageModal';
import { AttachInventoryModal } from './attachToInventoryModal/AttachInventoryModal';
import { sizes } from '../../../../../styles/media';
import { FieldsType, isValidField } from '../../../../../utils/validation';
import { useWindowSize } from '../../../../../styles/style.context';

export enum InputType {
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  INPUT = 'INPUT',
}

export enum RowItemType {
  SELECT = 'SELECT',
  ADDRESS = 'ADDRESS',
  CUSTOM = 'CUSTOM',
  INPUT = 'INPUT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  INPUT_PHONE_NUMBER = 'INPUT_PHONE_NUMBER',
  JOINED_SELECT = 'JOINED_SELECT',
}

export interface SelectType {
  name: any;
  id: any;
}

export const AttachModal = ({
  idDevice,
  isOpenModal,
  handleCloseAttachModal,
  data,
  setIsAttach,
  isLoader,
  setIsLoader,
  languageStrings,
}: {
  isOpenModal: boolean;
  handleCloseAttachModal: (props: boolean) => void;
  data: any;
  setIsAttach: any;
  idDevice: string;
  isLoader: boolean;
  setIsLoader: any;
  languageStrings?: any;
}) => {
  const defaultPackageState = {
    name: '',
    component: { id: '', name: '' },
    tracker: { id: '', name: '' },
    zone: '',
    quantity: '',
    stage: { id: '', name: '' },
    process: { id: '', name: '' },
    status: { id: '', name: '' },
    id: '',
  };

  const { addNotification } = useNotifierFunctions();
  const inventoriesList = useGetInventories();
  const listOrders = useOrderList();
  const listProductionIds = useProductionIdsList();
  const [productionList, setProductionList] = useState(listProductionIds);
  const [packages, setPackages] = useState([]);
  const [onePackage, setOnePackage] = useState(defaultPackageState);
  const [isLoaderPackageModal, setIsLoaderPackageModal] = useState(false);
  const [order, setOrder] = useState({ id: '', name: '' });
  const [productionId, setProductionId] = useState<any>({ id: '', name: '' });
  const [dataAttachPackage, setDataAttachPackage] = useState({ trackable_id: onePackage.id });
  const [dataAttachInventory, setDataAttachInventory] = useState({ trackable_id: '', metaData: {} });
  const [inventory, setInventory] = useState({ id: '', name: '' });
  const [inventoryType, setInventoryType] = useState('');
  const [validationIsTarted, setValidationIsStarted] = useState(false);
  const [attachedToPackage, setAttachedToPackage] = useState(false);
  const [attachedToInventory, setAttachedToInventory] = useState(false);
  const [orderId, setOrderId] = useState<any>();
  const [componentIndex, setComponentIndex] = useState<any>(null);
  const [selectedSelect, setSelectedSelect] = useState({
    production: false,
    order: false,
  });

  const [createPackageProdId, setCreatePackageProdId] = useState('');
  const [componentId, setComponentId] = useState('');

  const widthView = useWindowSize()[0];
  const isBigMobileSize = widthView < sizes.tabletNavigation;

  const handleAttachPackageAPI = () => {
    setInventoriesAttach(idDevice, dataAttachPackage, setIsLoader, setIsAttach, addNotification, languageStrings);
    setOnePackage(defaultPackageState);
    setPackages(() => []);
    setOrder({ id: '', name: '' });
    setProductionId({ id: '', name: '' });
    setCreatePackageProdId('');
    setComponentId('');
    handleCloseAttachModal(false);
    setAttachedToPackage(false);
    setComponentIndex(null);
  };

  const handleChangeOrder = (e: any) => {
    setOrder(e);
    setProductionId({ id: '', name: '' });
    setCreatePackageProdId('');
    setIsLoaderPackageModal(true);
    setOnePackage(defaultPackageState);
    setOrderId(e.id);
    getPackageList(e.id, setPackages, setIsLoaderPackageModal);
    setComponentIndex(null);
    setSelectedSelect((prev: any) => ({ ...prev, order: true }));
  };

  const handleChangeProductionId = (e: any) => {
    setProductionId(e);
    setCreatePackageProdId(e?.id);
    setComponentId(e?.component?.id);
    setIsLoaderPackageModal(true);
    setOnePackage(defaultPackageState);
    e.id !== '' && setOrder({ id: e.order_oid, name: e.order_generic_id });
    getPackageList(e.order_oid, setPackages, setIsLoaderPackageModal, e.name);
    setComponentIndex(e.component);
    !selectedSelect.order && setSelectedSelect((prev: any) => ({ ...prev, production: true }));
  };

  const handleResetOrderId = () => {
    setOrder({ name: '', id: '' });
    setProductionId({ id: '', name: '' });
    setCreatePackageProdId('');
    setComponentId('');
    setIsLoaderPackageModal(false);
    setOnePackage(defaultPackageState);
    setOrderId('');
    setComponentIndex(null);
    setSelectedSelect({ production: false, order: false });
    setProductionList(listProductionIds);
    setPackages(() => []);
  };

  const handleResetProdId = () => {
    if (selectedSelect.production) {
      setOrder({ name: '', id: '' });
      setOrderId('');
      setPackages(() => []);
    }
    orderId && getPackageList(orderId, setPackages, setIsLoaderPackageModal);
    setProductionId({ id: '', name: '' });
    setCreatePackageProdId('');
    setComponentId('');
    setIsLoaderPackageModal(false);
    setOnePackage(defaultPackageState);
    setComponentIndex(null);
    setSelectedSelect((prev: any) => ({ ...prev, production: false }));
  };

  const handleChangePackage = (e: any) => {
    setOnePackage(e);
    setProductionId({ name: e?.component?.production_id, id: e?.component?.production_id });
  };

  const handleClosePackageModal = () => {
    handleResetOrderId();
    handleResetProdId();
    setAttachedToPackage(false);
    setValidationIsStarted(false);
  };

  const closeAttachModal = () => {
    handleCloseAttachModal(false);
    setIsAttach(false);
    setOnePackage(defaultPackageState);
    setValidationIsStarted(false);
  };

  const handleAttachInventoryApi = () => {
    setValidationIsStarted(true);
    if (isAllInventoryFieldsValidated) {
      setAttachedToInventory(false);
      handleCloseAttachModal(false);
      setIsLoader(true);
      setInventoriesAttach(idDevice, dataAttachInventory, setIsLoader, setIsAttach, addNotification, languageStrings);
      setValidationIsStarted(false);
      setInventory({ id: '', name: '' });
    }
  };

  const handleChooseInventories = (value: any) => {
    setInventory({ id: value.id, name: value.name });
    setInventoryType(() => value?.inventory_type?.name);
    setDataAttachInventory((prev) => {
      return { ...prev, trackable_id: value.id };
    });
  };

  const handleCloseInventoriesModal = () => {
    setAttachedToInventory(false);
    setDataAttachInventory({ trackable_id: '', metaData: {} });
    setInventoryType('');
    setInventory({ id: '', name: '' });
    setValidationIsStarted(false);
  };

  const mapOrders = (orders: object[]) => {
    if (orders) {
      return orders.map((item: any) => {
        return { name: item.order_id, id: item.id };
      });
    }
  };

  const mapProductionIds = (productionIds: any) => {
    if (productionIds) {
      return productionIds.map((item: any) => {
        return { ...item, name: item.name, id: item.name };
      });
    }
  };

  const packageModalFields = [
    {
      name: languageStrings.productionID,
      placeholder: isBigMobileSize
        ? languageStrings.shortProductionIDPlaceholder
        : languageStrings.productionIDPlaceholder,
      values: mapProductionIds(productionList || []),
      value: productionId,
      disabled: false,
      type: RowItemType.JOINED_SELECT,
      onChange: handleChangeProductionId,
      resetHandle: handleResetProdId,
      isPendingRequest: listProductionIds.length ? false : true,
    },
    {
      name: languageStrings.labelOrder,
      placeholder: languageStrings.chooseOrderPlaceholder,
      values: mapOrders(listOrders || []),
      value: order,
      disabled: false,
      type: RowItemType.JOINED_SELECT,
      onChange: handleChangeOrder,
      resetHandle: handleResetOrderId,
      isPendingRequest: listOrders.length ? false : true,
      isError: !isValidField(order.name, FieldsType.SELECT),
    },
    {
      name: languageStrings.labelPackageName,
      placeholder: packages.length ? languageStrings.selectPackagePlaceholder : 'N/A',
      values: packages.length ? packages : [],
      value: onePackage,
      disabled: packages.length ? false : true,
      type: RowItemType.JOINED_SELECT,
      onChange: handleChangePackage,
      isPendingRequest: false,
      isCreatePlaceholderSearchBar: true,
      isError: !isValidField(onePackage.name, FieldsType.SELECT),
      isPackage: true,
      addButtonLabel: languageStrings.createNewPlVersion,
    },
    {
      name: languageStrings.labelComponent,
      values: [],
      placeholder: languageStrings.filledAutomaticallyPlaceholder,
      value: componentIndex?.name || 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.attachToTrackerLabel,
      placeholder: languageStrings.filledAutomaticallyPlaceholder,
      value: onePackage?.tracker?.name ? onePackage?.tracker?.name : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.labelQuantityInPackage,
      placeholder: languageStrings.filledAutomaticallyPlaceholder,
      value: onePackage?.quantity ? onePackage?.quantity : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.labelStage,
      placeholder: languageStrings.filledAutomaticallyPlaceholder,
      value: onePackage?.stage?.name ? onePackage?.stage?.name : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.labelProcess,
      placeholder: languageStrings.filledAutomaticallyPlaceholder,
      value: onePackage?.process?.name ? onePackage?.process?.name : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.labelZone,
      placeholder: languageStrings.filledAutomaticallyPlaceholder,
      value: onePackage?.zone ? onePackage?.zone : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.labelStatus,
      placeholder: languageStrings.filledAutomaticallyPlaceholder,
      value: onePackage?.status?.name ? onePackage?.status?.name : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
  ];

  const inventoryModalFields = [
    {
      name: languageStrings.labelName,
      placeholder: languageStrings.chooseNamePlaceholder,
      values: inventoriesList || [],
      value: inventory,
      disabled: false,
      type: RowItemType.JOINED_SELECT,
      onChange: handleChooseInventories,
      isPendingRequest: inventoriesList.length ? false : true,
      isError: !isValidField(inventory.name, FieldsType.SELECT),
    },
    {
      name: languageStrings.labelType,
      placeholder: languageStrings.filledAutomaticallyPlaceholder,
      value: inventoryType ? inventoryType : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
  ];

  const isAllFieldsValidated =
    packageModalFields.length === packageModalFields.filter((item: any) => !item.isError).length;
  const isAllInventoryFieldsValidated =
    inventoryModalFields.length === inventoryModalFields.filter((item: any) => !item.isError).length;

  const isAllFieldFilled =
    packageModalFields.length ===
    packageModalFields.filter(
      (item: any) =>
        (item.value && item.value.length > 3) ||
        (item.value && item.value.name && item.value.name.length > 0) ||
        (item.value && item.value > 0),
    ).length;

  useEffect(() => {
    if (isAllFieldFilled) {
      setValidationIsStarted(true);
    } else {
      setValidationIsStarted(false);
    }
  }, [isAllFieldFilled]);

  useEffect(() => {
    setDataAttachPackage((prev) => {
      return { ...prev, trackable_id: onePackage.id };
    });
  }, [onePackage]);

  useEffect(() => {
    setProductionList(listProductionIds);
  }, [isOpenModal]);

  useEffect(() => {
    if (orderId) {
      (async () => {
        try {
          const productionIdsList = await getDeviceDetailsProductionIds(orderId);
          setProductionList(productionIdsList);
        } catch (e) {
          //
        }
      })();
    }
  }, [orderId]);

  useEffect(() => {
    listProductionIds && setProductionList(listProductionIds);
  }, [listProductionIds]);

  return (
    <>
      <AttachToModal
        isOpenModal={isOpenModal}
        closeModal={closeAttachModal}
        setAttachedToPackage={setAttachedToPackage}
        setAttachedToInventory={setAttachedToInventory}
        languageStrings={languageStrings}
      />
      <AttachPackageModal
        componentIndex={componentIndex}
        attachedToPackage={attachedToPackage}
        packageArr={packageModalFields}
        setValidationIsStarted={setValidationIsStarted}
        handleAttachPackageAPI={handleAttachPackageAPI}
        isLoaderPackageModal={isLoaderPackageModal}
        handleClosePackageModal={handleClosePackageModal}
        validationIsTarted={validationIsTarted}
        isAllFieldsValidated={isAllFieldsValidated}
        data={data}
        languageStrings={languageStrings}
        setComponentIndex={setComponentIndex}
        createPackageProdId={createPackageProdId}
        setCreatePackageProdId={setCreatePackageProdId}
        componentId={componentId}
        setComponentId={setComponentId}
        orderId={orderId}
      />
      <AttachInventoryModal
        attachedToInventory={attachedToInventory}
        inventoryArr={inventoryModalFields}
        handleAttachInventoryApi={handleAttachInventoryApi}
        handleCloseInventoriesModal={handleCloseInventoriesModal}
        dataAttachInventory={dataAttachInventory}
        languageStrings={languageStrings}
        validationIsTarted={validationIsTarted}
      />
    </>
  );
};
