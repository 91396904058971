import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import settingsReducer from './state/settingsSlice';
import userReducer from './state/userSlice';
import managementReducer from './state/managementSlice';
import appReducer from './state/appSlice';
import insightReducer from './state/insightSlice';
import dashboardReducer from './state/dashboardSlice';
import usersTableReducer from './state/usersTableSlice';
import ordersTableSlice from './state/ordersTableSlice';
import manageOrderDetailsSlice from './state/manageOrderDetailsSlice';
import manageOrderDetailComponentSlice from './state/manageOrderDetailComponentSlice';
import routesReducer from './state/routeSlice';
import smartsearchReducer from './state/smartSearchSlice';
import manageStagesReducer from './state/manageStagesSlice';
import manageProcessesReducer from './state/manageProcessesSlice';
import filtersSlice from './state/filtersSlice';
// import colorsSliceReducer from './state/colorsSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    settings: settingsReducer,
    user: userReducer,
    management: managementReducer,
    insight: insightReducer,
    dashboard: dashboardReducer,
    usersTable: usersTableReducer,
    orders: ordersTableSlice,
    orderDetails: manageOrderDetailsSlice,
    manageOrderDetailComponent: manageOrderDetailComponentSlice,
    routes: routesReducer,
    smartSearch: smartsearchReducer,
    manageStages: manageStagesReducer,
    manageProcesses: manageProcessesReducer,
    filters: filtersSlice,
    // colors: colorsSliceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
