import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Delete } from '../assets/close.svg';
import { Select } from '../../select/Select';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '../../../styles/media';
import { borderRadiusFetch, colorFetch } from '../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

type StageContainerProps = {
  isStatic: boolean;
  isOpen: boolean;
};

const StageContainer = styled.div<StageContainerProps>`
  width: 230px;
  height: 40px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 38px;
  background-color: ${({ isStatic, theme }) => isStatic && colorFetch('gray5')({ theme })};
  border-radius: ${borderRadiusFetch('primary')};
  border: ${({ isStatic }) => !isStatic && '1px solid'};
  border-color: ${({ isOpen, theme }) => (isOpen ? colorFetch('primary')({ theme }) : colorFetch('gray3')({ theme }))};
  position: relative;
  transition: border-color 0.3s;
  margin-bottom: 12px;
  &.last:after {
    display: none;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    width: 1px;
    height: 12px;
    background-color: ${colorFetch('gray3')};
    display: ${({ isStatic }) => (isStatic ? 'none' : 'block')};
  }
  ${media.tablet`
    width: 100%;
  `}
`;

const StageLeftBlock = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

const NumberStage = styled(Text)`
  transform: translateY(1px);
  position: absolute;
  left: 15px;
  z-index: 1;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  & > div:nth-child(2) > div {
    width: 100%;
  }
  ${media.tablet`
    & > div:nth-child(2) {
      width: calc(100% + 40px);
    }
  `}
`;

const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-left: 1px solid;
  border-color: ${colorFetch('gray3')};
  outline: 0;
  cursor: pointer;
  background-color: ${colorFetch('gray5')};
  border-radius: 0 ${borderRadiusFetch('primary')} ${borderRadiusFetch('primary')} 0;
  transition: all 0.3s linear;
  & > svg {
    flex-shrink: 0;
  }
  &:hover {
    background-color: ${colorFetch('gray4')};
  }
  &:active {
    background-color: ${colorFetch('gray4')};
  }
`;

interface ComponentProps {
  count: number;
  stage: any;
  handleClick: any;
  dataSelect: any[];
  onStageChange: (el: any, count: number) => void;
  onDelete: (id: number) => void;
  isStatic?: boolean;
  className?: string;
}

export const Stage: FC<ComponentProps> = ({
  count,
  stage,
  dataSelect,
  onStageChange,
  onDelete,
  isStatic = false,
  handleClick,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const { primary } = theme.colors;
  // const [selectItem, setSelectItem] = useState<any>(
  //   dataSelect[0] ? { id: dataSelect[0].id, name: dataSelect[0].stage_name } : { id: 'id', name: 'stage_name' },
  // );

  const changeBorder = (value: boolean) => {
    setIsOpen(value);
  };

  return (
    <StageContainer theme={theme} isStatic={isStatic} isOpen={isOpen} {...props}>
      <StageLeftBlock>
        <NumberStage type={TextType.TEXT_14_GRAY} weight={'600'} color={primary}>
          {count}
        </NumberStage>
        <StyledSelect
          value={stage}
          values={dataSelect}
          // @TODO fix type
          onChange={onStageChange as any}
          disabled={isStatic}
          width={'230px'}
          positionTop={'46px'}
          positionLeft={'-1px'}
          paddingInput={'6px 6px 6px 36px'}
          handleClick={handleClick}
          borderLess
          openingListener={changeBorder}
          stageType
        />
      </StageLeftBlock>
      {!isStatic && (
        <DeleteButton onClick={() => onDelete(count)}>
          <Delete />
        </DeleteButton>
      )}
    </StageContainer>
  );
};
