/**
 * @description
 * This is component for Problems Detected page
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '../../../components/commonButton/CommonButton';
import { PATHS } from '../../../routes';
import { ReactComponent as ArrowImage } from '../../../assets/mini_arrow.svg';
import { TableControl } from './components/tableControl/TableControl';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/userSlice';
import { useHistory } from '../../../utils/react-router-dom-abstraction';
import { colorFetch } from '../../../styles/utils';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 45px;
`;

const ButtonInnerContainer = styled.div`
  display: flex;
  align-items: center;
  & > svg > path {
    stroke: ${colorFetch('pureWhite')};
  }
  justify-content: center;
`;

const ButtonText = styled.div`
  margin-left: 10px;
`;

export const ProblemsDetected = () => {
  const history = useHistory();
  const language = useSelector(selectLanguageStrings);

  return (
    <Wrapper>
      <CommonButton
        onClick={() => {
          history.push(PATHS.USER_DASHBOARD);
        }}
        height="30px"
        width="75px"
      >
        <ButtonInnerContainer>
          <ArrowImage />
          <ButtonText>{language ? language.backButton : 'Back'}</ButtonText>
        </ButtonInnerContainer>
      </CommonButton>

      <Text type={TextType.TITLE_H1} margin={'24px 0 54px 0'}>
        {language ? language.titleProblemsDetected : 'Problems Detected'}
      </Text>

      <TableControl />
    </Wrapper>
  );
};
