/**
 * @description
 * This is component for calendar
 * all the functionalities cthings require for calendar
 * Highly customisable.
 *
 */

import React, { FC, useState } from 'react';
// @ts-ignore
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import moment from 'moment';
import { ReactComponent as CalendarImage } from '../../assets/Calendar.svg';
import { ReactComponent as ArrowImage } from '../../assets/Arrow.svg';
import { borderRadiusFetch, colorFetch, shadowFetch } from '../../styles/utils';
import { useTheme } from '@cthings.co/styles';

export enum CalendarType {
  DATE_SELECTION = 'DATE_SELECTION',
  MONTH_SELECTION = 'MONTH_SELECTION',
  DATE_RANGE = 'DATE_RANGE',
  DATE_RANGE_DOUBLE = 'DATE_RANGE_DOUBLE',
}

type WrapperProps = {
  type: string;
};

const Wrapper = styled.div<WrapperProps>`
  width: ${({ type }) =>
    type === CalendarType.DATE_SELECTION || type === CalendarType.MONTH_SELECTION
      ? '210px'
      : type === CalendarType.DATE_RANGE || type === CalendarType.DATE_RANGE_DOUBLE
      ? '330px'
      : ''};
  position: relative;

  .react-datepicker {
    display: flex;

    font-family: Poppins, sans-serif;
    font-weight: normal;
    font-size: 14px;

    line-height: 24px;
    letter-spacing: -0.01px;

    border: none;
    border-radius: ${borderRadiusFetch('primary')};
    box-shadow: ${shadowFetch('additionalShadow1')};
  }

  .react-datepicker-popper {
    transform: initial !important;
    top: 56px !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background-color: ${colorFetch('pureWhite')};

    border: none;
    border-radius: ${borderRadiusFetch('primary')};

    padding: 22px 24px 0px 24px;
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${colorFetch('primary')};

    left: 23px;
    top: 30px;

    outline: none;

    transition: border-right-color 0.3s ease;

    &:hover {
      border-right-color: ${colorFetch('secondary3')};
    }
  }

  .react-datepicker__current-month {
    color: ${colorFetch('black1')};

    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 20px;

    line-height: 28px;

    letter-spacing: -0.02px;

    border-bottom: 1px solid ${colorFetch('gray4')};

    padding: 0px 0px 22px 0px;
    margin: 0px 0px 22px 0px;
  }

  .react-datepicker__navigation--next {
    border-left-color: ${colorFetch('primary')};

    right: 23px;
    top: 30px;

    outline: none;

    transition: border-left-color 0.3s ease;

    &:hover {
      border-left-color: ${colorFetch('secondary3')};
    }
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-around;

    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    & > div {
      color: ${colorFetch('gray1')};
    }
  }

  .react-datepicker__day-name {
    width: initial;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-around;
  }

  .react-datepicker__day {
    width: 36px;
    height: 36px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: ${colorFetch('black1')};

    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    border-radius: ${borderRadiusFetch('round')};

    transition: all 0.3s ease;
    outline: none;
  }

  .react-datepicker__day:hover {
    background-color: ${colorFetch('primary')} !important;
    color: ${colorFetch('pureWhite')};
  }

  .react-datepicker__day:focus {
    background-color: ${colorFetch('primary')} !important;
  }

  .react-datepicker__day--selected {
    background-color: ${colorFetch('primary')} !important;
    color: ${colorFetch('pureWhite')};
  }

  .react-datepicker__day--selected:hover {
    background-color: ${colorFetch('primary')} !important;
    color: ${colorFetch('pureWhite')};
  }

  .react-datepicker__month {
    background-color: ${colorFetch('pureWhite')};
    border-top: ${({ type, theme }) =>
      type === CalendarType.MONTH_SELECTION ? `1px solid ${colorFetch('gray4')({ theme })}` : 'none'};

    border-radius: 0px 0px ${borderRadiusFetch('primary')} ${borderRadiusFetch('primary')};

    padding: ${({ type }) => (type === CalendarType.MONTH_SELECTION ? '19.5px 0px 21px 0px' : '0px 22px 21px 22px')};
    margin: ${({ type }) => (type === CalendarType.MONTH_SELECTION ? '25px 22px 0px 22px' : '0px')};
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__day--in-range {
    background-color: ${colorFetch('secondary4')} !important;
  }

  .react-datepicker__day--in-selecting-range {
    background-color: ${colorFetch('secondary4')};
  }

  .react-datepicker__day--range-start {
    background-color: ${colorFetch('primary')} !important;
    border-radius: ${borderRadiusFetch('round')};
  }

  .react-datepicker__day--range-end {
    background-color: ${colorFetch('primary')} !important;
    color: ${colorFetch('pureWhite')};

    border-radius: ${borderRadiusFetch('round')};
  }

  .react-datepicker__day--keyboard-selected {
    background-color: initial;
  }

  .react-datepicker__month-container {
    width: 330px;
  }

  .react-datepicker__month-wrapper {
    display: flex;
    justify-content: space-around;
  }

  .react-datepicker__month-text {
    width: 88px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid ${colorFetch('gray4')};
    border-radius: ${borderRadiusFetch('primary')};

    margin: 8px 4.5px;

    outline: none;

    transition: all 0.3s ease;

    &:hover {
      background-color: ${colorFetch('primary')};
      color: ${colorFetch('pureWhite')};
    }
  }

  .react-datepicker__month--selected {
    background-color: ${colorFetch('primary')} !important;
    color: ${colorFetch('pureWhite')} !important;
  }
  .react-datepicker__month-text--keyboard-selected {
    background-color: initial;
    color: ${colorFetch('black1')};
  }

  .react-datepicker__input-container > input {
    background-color: ${colorFetch('pureWhite')};

    color: ${colorFetch('black1')};

    border: 1px solid ${colorFetch('primary')};
  }
`;

const CalendarImageWrapper = styled.div`
  display: flex;

  position: absolute;
  z-index: 1;
  top: 10px;
  left: 8px;

  & > svg > path {
    stroke: ${colorFetch('primary')};
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 40px;
  box-sizing: border-box;

  display: flex;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  border: 1px solid ${colorFetch('primary')};
  border-radius: ${borderRadiusFetch('primary')};

  box-shadow: ${shadowFetch('additionalShadow2')};

  padding: 8px 36px;
  outline: none;
  cursor: pointer;
` as typeof DatePicker;

const ArrowImageWrapper = styled.div`
  display: flex;

  position: absolute;
  top: 17.5px;
  right: 17.5px;
  z-index: 10;

  cursor: pointer;

  transform: rotate(270deg);

  & > svg {
    width: 5px;
    height: 10px;
  }

  & > svg > path {
    stroke: ${colorFetch('primary')};
  }
`;

export interface CalendarProps {
  type: string;

  className?: string;
}

export const Calendar: FC<CalendarProps> = ({ type, ...props }) => {
  const theme = useTheme();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const inputValue =
    moment(`${startDate}`).format('DD MMMM yyyy') + ' - ' + moment(`${endDate}`).format('DD MMMM yyyy');

  return (
    <Wrapper type={type} {...props}>
      <CalendarImageWrapper>
        <CalendarImage />
      </CalendarImageWrapper>
      <StyledDatePicker
        selected={startDate}
        onChange={
          type === CalendarType.DATE_SELECTION || type === CalendarType.MONTH_SELECTION
            ? (date: any) => setStartDate(date)
            : type === CalendarType.DATE_RANGE || type === CalendarType.DATE_RANGE_DOUBLE
            ? onChange
            : () => console.log('You should enter type of calendar!')
        }
        startDate={type === CalendarType.DATE_RANGE || type === CalendarType.DATE_RANGE_DOUBLE ? startDate : null}
        endDate={type === CalendarType.DATE_RANGE || type === CalendarType.DATE_RANGE_DOUBLE ? endDate : null}
        selectsRange={type === CalendarType.DATE_RANGE || type === CalendarType.DATE_RANGE_DOUBLE ? true : false}
        formatWeekDay={(nameOfDay: any) => nameOfDay.substr(0, 1)}
        showMonthYearPicker={type === CalendarType.MONTH_SELECTION ? true : false}
        showFullMonthYearPicker={type === CalendarType.MONTH_SELECTION ? true : false}
        value={
          type === CalendarType.DATE_RANGE || type === CalendarType.DATE_RANGE_DOUBLE
            ? endDate
              ? inputValue
              : moment(`${startDate}`).format('DD MMMM yyyy')
            : type === CalendarType.MONTH_SELECTION
            ? moment(`${startDate}`).format('MMMM')
            : type === CalendarType.DATE_SELECTION
            ? moment(`${startDate}`).format('ddd, DD MMM yyyy')
            : ''
        }
        shouldCloseOnSelect={type === CalendarType.DATE_RANGE || type === CalendarType.DATE_RANGE_DOUBLE ? false : true}
        monthsShown={type === CalendarType.DATE_RANGE_DOUBLE ? 2 : 1}
        theme={theme}
      ></StyledDatePicker>
      <ArrowImageWrapper>
        <ArrowImage />
      </ArrowImageWrapper>
    </Wrapper>
  );
};
