import React from 'react';

export const Indicator = ({ batteryLevel }: { batteryLevel: number }) => {
  const colorOfIndicator =
    batteryLevel > 50 ? '#3BB94F' : batteryLevel < 51 && batteryLevel > 16 ? '#FD8D26' : '#F44450';
  return (
    <svg width="30" height="17" viewBox="0 0 30 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      {batteryLevel > 0 && (
        <path
          d="M6 3L6 14L3.71795 14C3.32144 14 3 13.6786 3 13.2821L3 3.71795C3 3.32144 3.32144 3 3.71795 3L6 3Z"
          fill={colorOfIndicator}
        />
      )}
      {batteryLevel > 16 && (
        <rect x="10" y="3" width="11" height="3" transform="rotate(90 10 3)" fill={colorOfIndicator} />
      )}
      {batteryLevel > 33 && (
        <rect x="14" y="3" width="11" height="3" transform="rotate(90 14 3)" fill={colorOfIndicator} />
      )}
      {batteryLevel > 50 && (
        <rect x="18" y="3" width="11" height="3" transform="rotate(90 18 3)" fill={colorOfIndicator} />
      )}
      {batteryLevel > 65 && (
        <rect x="22" y="3" width="11" height="3" transform="rotate(90 22 3)" fill={colorOfIndicator} />
      )}
      {batteryLevel > 82 && (
        <path
          d="M25.2821 3C25.6786 3 26 3.32144 26 3.71795L26 13.2821C26 13.6786 25.6786 14 25.2821 14L23 14L23 3L25.2821 3Z"
          fill={colorOfIndicator}
        />
      )}
      <rect x="28" y="1" width="15" height="27" rx="2.87179" transform="rotate(90 28 1)" stroke="#555770" />
      <rect x="30" y="6" width="5" height="2" rx="1" transform="rotate(90 30 6)" fill="#555770" />
    </svg>
  );
};
