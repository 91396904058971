import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../../../../../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../app/state/userSlice';
import { Text } from '@bit/first-scope.text';
import { RowPlaceholder } from '../rowPlaceholder/RowPlaceholder';
import { ItemComponent } from '../itemComponent/ItemComponent';
import { createId } from '../../../../../../../utils/uuid';
import { ComponentType } from '../../ManualAddSingleOrderModal';
import { borderRadiusFetch, colorFetch } from '../../../../../../../styles/utils';

const WrapperComponentContainer = styled.div`
  width: 100%;
`;

// styles for components block
const ComponentContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadiusFetch('primary')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  /* overflow: hidden; */
  transition: all 0.2s linear;
  ${media.tablet`
    max-width: 100%;
    width: 100%;
  `}
  ${media.phone`
    height: 100%;
  `}
  &.disabled {
    background-color: ${colorFetch('gray5')};
  }
`;

const WrapperTitleComponent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 20px;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-family: Poppins;
  border-bottom: 1px solid;
  color: ${colorFetch('gray1')};
  border-color: ${colorFetch('gray3')};
  padding: 0 22px;
`;

const StyledTitleText = styled(Text)`
  color: ${colorFetch('gray1')};
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
` as any; // @TODO fix typpe

const ComponentHistory = styled.div`
  box-sizing: border-box;
  overflow: scroll;
  flex: 1;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: 9px;
    width: 4px;
  }
`;

const ComponentContent = styled.div`
  height: 180px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  max-height: 219px;
  /* overflow: ${(props: any) => (props.height > 218 ? 'auto' : 'hidden')}; */
  /* overflow: overlay; */
  transition: all 0.3s linear;
  justify-content: flex-end;
`;

const SubmitButton = styled.button`
  width: 100%;
  height: 45px;
  padding: 0;
  margin: 0;
  background-color: ${colorFetch('gray5')};
  color: ${colorFetch('gray2')};
  border: none;
  outline: transparent;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: Poppins;
  border-top: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: 0 0 8px 8px;
  transition: all 0.3s linear;
  &:hover {
    background-color: ${colorFetch('gray4')};
  }
  &:active {
    background-color: ${colorFetch('gray4')};
  }
  &.disabled {
    background-color: ${colorFetch('gray4')};
    cursor: initial;
  }
`;

interface AddComponentProps {
  components: any[];
  setComponents: (setComponents: (value: ComponentType[]) => ComponentType[]) => void;
  setIsComponentFieldsValid: (value: boolean) => void;
  setIsEditingInProcess: (value: boolean) => void;
  setValidatingIsStarted: (value: boolean) => void;
  isEditingInProcess: boolean;
  validatingIsStarted: boolean;
}

export const AddComponent: FC<AddComponentProps> = ({
  components,
  setComponents,
  setIsComponentFieldsValid,
  setIsEditingInProcess,
  setValidatingIsStarted,
  isEditingInProcess,
  validatingIsStarted,
}) => {
  const languageStrings = useSelector(selectLanguageStrings);

  const [areAllComponentFieldsValid, setAreAllComponentFieldsValid] = useState(true);

  const handleAddRow = () => {
    setIsEditingInProcess(true);
    setValidatingIsStarted(false);

    !components.length && setIsEditingInProcess(false);

    components.length && setValidatingIsStarted(true);

    if (areAllComponentFieldsValid) {
      setIsEditingInProcess(false);
      setComponents((value: ComponentType[]) => {
        return [
          ...value,
          {
            component_id: createId(),
            components_list: [],
            component_value: {},
            index: '',
            productionId: '',
            quantity: null,
          },
        ];
      });
      setValidatingIsStarted(false);
    }
  };

  const handleDeleteRowComponent = (id: string) => {
    setComponents((components: ComponentType[]) => components.filter((el: ComponentType) => el.component_id !== id));
    setValidatingIsStarted(false);
    setAreAllComponentFieldsValid(true);
  };

  const [mainNode, setMainNode] = useState<HTMLElement | null>(null);

  const mainContainerRef = useCallback((node: HTMLElement | null) => {
    if (node) {
      setMainNode(node);
    }
  }, []);

  const mainNodeDimenstions = useMemo(() => {
    return mainNode
      ? { height: mainNode.getBoundingClientRect().height, top: mainNode.getBoundingClientRect().top }
      : { height: 0, top: 0 };
  }, [mainNode]);

  return (
    <WrapperComponentContainer>
      <ComponentContainer>
        <WrapperTitleComponent>
          <StyledTitleText>{languageStrings.manualAddOrderComponentsName ?? 'Name'}</StyledTitleText>
          <StyledTitleText>{languageStrings.manualAddOrderComponentsIndex ?? 'Index'}</StyledTitleText>
          <StyledTitleText>{languageStrings.labelProductionID ?? 'Production ID'}</StyledTitleText>
          <StyledTitleText>{languageStrings.manualAddOrderComponentsQuantity ?? 'Quantity'}</StyledTitleText>
        </WrapperTitleComponent>

        <ComponentHistory ref={mainContainerRef}>
          {components.length ? (
            <ComponentContent>
              {components.map(
                (
                  el: {
                    component_id: string;
                    components_list: { id: string; name: string; index: string }[];
                    component_value: { id: string; name: string; index: string };
                    index: string;
                    productionId: string;
                    quantity: number;
                  },
                  index: number,
                ) => (
                  <ItemComponent
                    key={index}
                    component={el}
                    setComponents={setComponents}
                    handleDeleteRowComponent={handleDeleteRowComponent}
                    mainNode={mainNode}
                    mainNodeDimenstions={mainNodeDimenstions}
                    setIsComponentFieldsValid={setIsComponentFieldsValid}
                    setIsEditingInProcess={setIsEditingInProcess}
                    setAreAllComponentFieldsValid={setAreAllComponentFieldsValid}
                    isEditingInProcess={isEditingInProcess}
                    validatingIsStarted={validatingIsStarted}
                  />
                ),
              )}
            </ComponentContent>
          ) : (
            <RowPlaceholder />
          )}
        </ComponentHistory>

        <SubmitButton onClick={handleAddRow}>{languageStrings.addRowButton ?? '+ Add row'}</SubmitButton>
      </ComponentContainer>
    </WrapperComponentContainer>
  );
};
