import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../../../../styles/media';
import { getRequiredDateFormat } from '../../../../../utils/date/date-format';
import { ButtonsContainer } from './components/buttonsContainer/ButtonsContainer';
import { DeviceDetailsType } from '../../../../../enums/View';
import { PlaceholderType } from '../../../../../components/placeholders/typePlaceholders/placeholdersType';
import { withLoader } from '../../../../../features/placeholderComponent/loaderFunctions';
import { useCustomHistory } from '../../../../../utils/react-router-dom-abstraction';
import { View } from '../../../../../routes/routeInterfaces';
import { useMediaType } from '../../../../../styles/style.context';
import { InfoContentTable } from '../secondTableInfo/infoContentTable/InfoContentTable';
import { colorFetch } from '../../../../../styles/utils';

const Wrapper = styled.div`
  min-width: 980px;
  width: 100%;
  display: block;
  flex-direction: column;
  align-items: flex-start;
  ${media.monitor`
    min-width: 588px;
  `}
  ${media.tablet`
    min-width: 288px;
  `}
`;

const StyledInfoContentTable = styled(InfoContentTable)`
  & > div > div:nth-child(7) {
    border-top: 1px solid;
    border-color: ${colorFetch('gray4')};
  }
`;

export enum RedirectItemType {
  LOCATION = 'LOCATION',
  ZONE = 'ZONE',
  ORDER = 'ORDER',
  PACKAGE = 'PACKAGE',
}

const DeviceFirstTableInfoPlain = ({
  setIsAttach,
  isAttach,
  data = [],
  headerLabels,
  headerGridTemplate,
  type,
  handleDeAttachDeviceApi,
  setShowAttachModal,
  languageStrings,
}: {
  setIsAttach: any;
  isAttach: any;
  data: any;
  headerLabels: any;
  headerGridTemplate: any;
  type: any;
  setShowAttachModal: any;
  handleDeAttachDeviceApi: any;
  languageStrings?: any;
}) => {
  const { ts_installed, location, name, mac_id, attached_info } = data;

  const customHistory = useCustomHistory();
  const mediaType = useMediaType();

  const offset = attached_info?.order?.order_details_pagination_offset;

  const arrTracker: any = [
    { type: '', property: languageStrings ? languageStrings.labelName : 'Name', value: `${name} `, isClickable: false },
    {
      type: RedirectItemType.LOCATION,
      property: languageStrings ? languageStrings.labelLocation : 'Location',
      value: location?.address?.city === '' ? 'N/A' : `${location?.address?.city}, ${location?.address?.zip} `,
      deviceName: name,
      locationId: location?.building_id,
      isClickable: true,
    },
    {
      type: RedirectItemType.ZONE,
      property: languageStrings ? languageStrings.labelZone : 'Zone',
      value: location?.zone,
      locationId: location?.building_id,
      zoneId: location?.zone_id,
      isClickable: true,
    },
    {
      type: '',
      property: languageStrings ? languageStrings.labelCoordinates : 'Coordinates',
      value:
        location?.address?.geotag?.lat === '0'
          ? 'N/A'
          : `${location?.address?.geotag?.lat}, ${location?.address?.geotag?.lng}`,
      isClickable: false,
    },
    {
      type: '',
      property: languageStrings ? languageStrings.labelMacAddress : 'MAC address',
      value: `${mac_id}`,
      isClickable: false,
    },
    {
      type: '',
      property: languageStrings ? languageStrings.labelInstalledAt : 'Installed at',
      value: getRequiredDateFormat(ts_installed, 'DD.MM.YYYY [at] HH:mm'),
      isClickable: false,
    },
  ];

  const attachedPackageArray: any = [
    {
      type: RedirectItemType.PACKAGE,
      property: languageStrings ? languageStrings.labelPackage : 'Package',
      value: data?.attached_info?.order?.package_info?.name,
      orderId: data?.attached_info?.order?.id,
      packageId: data?.attached_info?.order?.package_info?.id,
      isClickable: true,
    },
    {
      type: RedirectItemType.ORDER,
      property: languageStrings.labelOrder,
      value: data?.attached_info?.order?.name,
      orderId: data?.attached_info?.order?.id,
      isClickable: true,
    },
    {
      type: '',
      property: languageStrings.productionID,
      value: data?.attached_info?.order?.production_id,
      isClickable: false,
    },
  ];

  const [structureTable, setStructureTable] = useState<any>([]);

  useEffect(() => {
    data?.attached_info?.order
      ? setStructureTable(() => attachedPackageArray.concat(arrTracker))
      : setStructureTable(arrTracker);
  }, [isAttach, data.attached_info, languageStrings]);

  return (
    <Wrapper>
      <StyledInfoContentTable
        type={type}
        headerLabels={headerLabels}
        headerGridTemplate={headerGridTemplate}
        arr={structureTable}
        placeholderProps={{
          keyword: 'OrderInfoTable',
          style: { width: '100%', padding: '3px', borderRadius: '8px', overflow: 'hidden' },
          placeholderOptions: { countRows: 4, withoutPagination: true },
        }}
        offset={offset}
      />
      {mediaType.tablet && (
        <ButtonsContainer
          setShowAttachModal={setShowAttachModal}
          data={data}
          handleDeAttachDeviceApi={handleDeAttachDeviceApi}
          setIsAttach={setIsAttach}
          handleShowOnTheMap={() => {
            customHistory.push(View.USER_INSIGHTS_DETAILS, {
              pathParams: { id: location.building_id, name: name },
            });
          }}
          isAttach={isAttach}
          isAttachDeAttachButton={type === DeviceDetailsType.TRACKER}
        />
      )}
    </Wrapper>
  );
};

export const FirstTableInfo = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(DeviceFirstTableInfoPlain);
