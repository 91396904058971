/**
 * @description
 * This is component for search address
 * all the functionalities cthings require for LocationButtons
 * Highly customisable.
 *
 */

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/media';
import { CloseButton } from '../closeButton/CloseButton';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as LocationImage } from '../../assets/location.svg';
import { ModalSearch } from '../searchBar/styledComponents';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';
import { useTheme } from '@cthings.co/styles';

type ButtonProps = {
  isOpen?: boolean;
};

// @TODO fix media types
const Button = styled.button<ButtonProps>`
  width: ${({ isOpen }) => (isOpen ? '205px' : '43px')};
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${colorFetch('primary')};

  outline: none;

  border: none;
  border-radius: 0 ${borderRadiusFetch('primary')} ${borderRadiusFetch('primary')} 0;
  cursor: pointer;

  padding: 6px 8px 6px 7px;

  transition: all 0.3s ease;
  &:hover {
    background-color: ${colorFetch('secondary2')};
  }

  & > svg {
    width: 28px;
    height: 28px;
    & > path {
      transition: all 0.3s ease;
      stroke: ${colorFetch('pureWhite')};
    }
  }

  &:hover > svg > path {
    transition: fill 0.3s ease;
    fill: ${colorFetch('secondary2')};
  }

  ${media.tablet`
    width: 43px;
    & > svg > path {
      fill: ${({ isOpen, theme }: any) =>
        isOpen ? colorFetch('pureWhite')({ theme }) : colorFetch('primary')({ theme })};
    }

    & > svg > path:nth-child(2n) {
      fill: ${colorFetch('primary')};
    }
  `}
`;

const LocationWrapper = styled.div`
  width: 164px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0px 0px 0px 5px;

  ${media.tablet`
    display: none;
  `}
`;

const InputLocation = styled.input`
  color: ${colorFetch('pureWhite')};
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  width: 100%;
  outline: transparent;
  border: none;
`;

const ModalLocation = styled(ModalSearch)`
  padding: 12px;
`;

const ContainerForText = styled.div``;

const ContainerRows = styled.div`
  max-height: 230px;
  box-sizing: border-box;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 18px;
  /* полоса прокрутки (скроллбар) */
  /* &::-webkit-scrollbar {
    width: 5px;
    background-color: secondary4;
    opacity: 0.6;
  } */

  /* ползунок скроллбара */
  /* &::-webkit-scrollbar-thumb {
    background-color: lighter;
    border-radius: 2px;
  } */
`;

const LocationRow = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-align: left;
`;

export interface LocationButtonProps {
  onOpen: any;
  onClose: any;
  isOpen?: boolean;
  location?: string;
  count?: number;

  className?: string;
}

export const LocationButton: FC<LocationButtonProps> = ({
  onOpen,
  onClose,
  isOpen,
  location,
  count,

  ...props
}) => {
  const theme = useTheme();
  const { pureWhite, secondary3 } = theme.colors;

  const [openModal, setOpenModal] = useState(false);
  const [textLocation, setTextLocation] = useState(location);

  const modalRef = useRef<any>(null);
  const buttonRef = useRef<any>(null);

  const handleClick = () => {
    setOpenModal(true);
  };

  const onClickOutsideHandler = useCallback(
    (event: any) => {
      if (
        openModal &&
        !(
          (buttonRef && buttonRef.current && buttonRef.current.contains(event.target)) ||
          (modalRef && modalRef.current && modalRef.current.contains(event.target))
        )
      ) {
        setOpenModal(false);
      }
    },
    [openModal],
  );

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  return (
    <Button
      onClick={() => {
        onOpen();
      }}
      isOpen={isOpen}
      {...props}
    >
      <LocationImage />
      {isOpen ? (
        <>
          <LocationWrapper>
            <ContainerForText ref={buttonRef} onClick={handleClick}>
              {!openModal ? (
                <Text type={TextType.TEXT_14_WHITE}>{textLocation}</Text>
              ) : (
                <InputLocation
                  value={textLocation}
                  onChange={(e: any) => setTextLocation(e.target.value)}
                  autoFocus={true}
                />
              )}
            </ContainerForText>
            <CloseButton color={pureWhite} hoverColor={secondary3} onClick={() => onClose()} />
          </LocationWrapper>
          {openModal && (
            <ModalLocation ref={modalRef}>
              <ContainerRows>
                <LocationRow>Warszawa, Poland</LocationRow>
                <LocationRow>Warszawa, Poland</LocationRow>
                <LocationRow>Warszawa, Poland</LocationRow>
              </ContainerRows>
            </ModalLocation>
          )}
        </>
      ) : null}
    </Button>
  );
};

LocationButton.defaultProps = {
  location: 'Brest',
};
