import React from 'react';
import styled from 'styled-components';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { Text, TextType } from '@bit/first-scope.text';
import { useCustomHistory } from '../../../../../utils/react-router-dom-abstraction';
import { View } from '../../../../../routes/routeInterfaces';
import { ComponentsSelect } from '../componentsSelect/ComponentsSelect';
import { useTheme } from '@cthings.co/styles';

const StyledText = styled(Text)`
  &.link {
    text-decoration: underline;
    cursor: pointer;
  }
` as any; // @TODO fix type

export const Components = ({
  setValue,
  stateComponent,
  value,
  label,
  disabled,
}: {
  setValue: any;
  stateComponent: TableCustomComponentState | undefined;
  value: any;
  label?: string;
  disabled?: boolean;
  language?: any;
}) => {
  const theme = useTheme();
  const { gray1, gray2 } = theme.colors;
  const customHistory = useCustomHistory();

  const handleLink = () =>
    customHistory.push(View.MANAGE_ORDERS_DETAILS, {
      pathParams: { id: value?.id, componentsOffset: 0, packagesOffset: 0, eventsOffset: 0 },
      state: { id: value?.id },
    });

  return (
    <>
      {stateComponent === TableCustomComponentState.VIEW_MODAL || stateComponent === TableCustomComponentState.EDIT ? (
        <ComponentsSelect setValue={setValue} value={value} label={label} disabled={disabled} />
      ) : (
        <>
          {value ? (
            <StyledText
              type={TextType.TEXT_14_GRAY}
              color={gray1}
              weight={'400'}
              className={'link'}
              onClick={handleLink}
              onTouchStart={handleLink}
            >
              {value?.generic_id}
            </StyledText>
          ) : (
            <StyledText type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'}>
              {'N/A'}
            </StyledText>
          )}
        </>
      )}
    </>
  );
};
