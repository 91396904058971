/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for arrow button require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowImage } from '../../assets/Arrow.svg';
import { colorFetch } from '../../styles/utils';

export enum ArrowButtonType {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

type ButtonProps = {
  type: ArrowButtonType;
};

const Button = styled.div<ButtonProps>`
  width: 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  transform: ${({ type }) => (type === ArrowButtonType.LEFT ? '' : 'rotate(180deg)')};
  & > svg > path {
    stroke: ${colorFetch('gray1')};
    transition: stroke 0.3s ease;
  }
  &:hover > svg > path {
    stroke: ${colorFetch('secondary3')};
  }
`;

export interface ArrowButtonProps {
  type: ArrowButtonType;
  onClick: any;
  className?: string;
  hoverColor?: string;
  color?: string;
}

export const ArrowButton: FC<ArrowButtonProps> = ({ type, onClick, ...props }) => {
  return (
    <Button onClick={onClick} type={type} {...props}>
      <ArrowImage />
    </Button>
  );
};

ArrowButton.defaultProps = {
  type: ArrowButtonType.LEFT,
};
