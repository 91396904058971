import React, { ChangeEvent, RefObject, useCallback, useEffect, useState } from 'react';
import { Comment } from './components/comment/Comment';
import { NoComments } from './components/noComments/NoComments';
import { Input } from './components/input/Input';
import { DeleteModal } from './components/deleteModal/DeleteModal';
import { CommentType } from './components/comment/types';
import { CommentsBlock, RelativeWrapper, Wrapper } from './styles';
import { useBlockBodyScroll } from '../../../../../../../utils/useBlockBodyScroll';
import { TableCustomComponentState } from '../../../../../../../features/universalTable/types';
import { Loader } from '../../../../../../../components/placeholders/Loader';
import { useTableContext } from '../../../../../../../features/universalTable/context';
import { setOrderPackageComment, useOrderPackageDetails } from '../../../../../../../api/manageOrderDetails';

export type CommentsProps = {
  state?: TableCustomComponentState;
  orderId: string;
};

export const Comments: React.FC<CommentsProps> = ({ state, orderId }) => {
  const [currentModalId, setCurrentModalId] = useState('');
  const [editableCommentId, setEditableCommentId] = useState('');
  const [dotsRef, setDotsRef] = useState<RefObject<HTMLInputElement>>();
  const [offsetTop, setOffsetTop] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [sendingDisabled, setSendingDisabled] = useState(true);
  const [startCommentValue, setStartCommentValue] = useState('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deletableCommentId, setDeletableCommentId] = useState('');
  const [{ openId: idPackage }] = useTableContext();
  const isActiveMode = state === TableCustomComponentState.EDIT;
  const [comments, setComments] = useState<CommentType[]>([]);
  const [isLoader, setIsLoader] = useState(true);

  const [packagesDetails] = useOrderPackageDetails(orderId, idPackage);

  useEffect(() => {
    if (packagesDetails) {
      setComments(packagesDetails.remarks);
      setIsLoader(false);
    }
  }, [packagesDetails]);

  const withComments = !!comments.length;

  const onDotsCLick = (id: string, dotsRef: RefObject<HTMLInputElement>) => {
    if (currentModalId === id) {
      setCurrentModalId('');
      setOffsetTop(0);
    } else {
      setCurrentModalId(id);
      setDotsRef(dotsRef);
    }
  };

  const handleClick = useCallback(
    (e: any) => {
      if (currentModalId && !(dotsRef && dotsRef.current && dotsRef.current.contains(e.target))) {
        currentModalId && setCurrentModalId('');
        setOffsetTop(0);
      }
    },
    [currentModalId, dotsRef, currentModalId],
  );

  useEffect(() => {
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  const commentsBlockRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (currentModalId && node !== null && dotsRef && dotsRef.current) {
        const scrollTop = Math.round(node.scrollTop);
        const { offsetHeight, offsetTop } = dotsRef.current;
        setOffsetTop(offsetHeight + offsetTop - scrollTop);
      }
    },
    [currentModalId],
  );

  const onEditClick = (commentText: string, commentId: string) => {
    setEditableCommentId(commentId);
    setInputValue(commentText);
    setStartCommentValue(commentText);
    setEditMode(true);
  };

  const onCloseEdit = () => {
    setInputValue('');
    setEditMode(false);
    setStartCommentValue('');
    setEditableCommentId('');
  };

  const onInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const onSendCommentClick = () => {
    if (editMode) {
      // editComment(inputValue, editableCommentId); TODO it needs to implement after api part will be ready
      setEditableCommentId('');
      setEditMode(false);
    } else {
      idPackage && setOrderPackageComment(orderId, inputValue.trim(), idPackage, setComments, setIsLoader);
    }
    setInputValue('');
    setStartCommentValue('');
  };

  const onDeleteClick = (commentId: string) => {
    setDeletableCommentId(commentId);
    setIsDeleteModalVisible(true);
  };

  const confirmDeleting = () => {
    // deleteComment(deletableCommentId); TODO it needs to implement after api part will be ready
    setIsDeleteModalVisible(false);
    if (editableCommentId === deletableCommentId) {
      setInputValue('');
      setStartCommentValue('');
      setDeletableCommentId('');
      setEditableCommentId('');
      setEditMode(false);
    }
  };

  const cancelDeleting = () => {
    setIsDeleteModalVisible(false);
    setDeletableCommentId('');
  };

  useBlockBodyScroll(isDeleteModalVisible);

  useEffect(() => {
    if (startCommentValue === inputValue) {
      setSendingDisabled(true);
    } else {
      sendingDisabled && setSendingDisabled(false);
    }
  }, [inputValue]);

  return (
    <Wrapper>
      <RelativeWrapper>
        <CommentsBlock
          ref={commentsBlockRef}
          offsetTop={!!offsetTop}
          withComments={withComments}
          isActiveMode={isActiveMode}
        >
          {withComments ? (
            comments.map((data: CommentType, index) => (
              <Comment
                key={index}
                data={data}
                currentModalId={currentModalId}
                offsetTop={offsetTop}
                editMode={editMode}
                editableCommentId={editableCommentId}
                isActiveMode={isActiveMode}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                onDotsCLick={onDotsCLick}
              />
            ))
          ) : (
            <NoComments />
          )}
          {isLoader && <Loader />}
        </CommentsBlock>
      </RelativeWrapper>
      {isActiveMode && (
        <Input
          value={inputValue}
          editMode={editMode}
          onChange={onInputChange}
          closeEdit={onCloseEdit}
          onSendCommentClick={onSendCommentClick}
          sendingDisabled={sendingDisabled}
        />
      )}
      <DeleteModal isVisible={isDeleteModalVisible} onYesClick={confirmDeleting} onNoClick={cancelDeleting} />
    </Wrapper>
  );
};
