import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useWindowSize } from '../../../../../styles/style.context';
import { Cheap } from './components/cheap/Cheap';
import { SelectListPopup } from './components/selectListPopup/SelectListPopup';
import { InputWrapper } from './components/inputWrapper/InputWrapper';
import { borderRadiusFetch, colorFetch } from '../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

type FieldWrapperProps = {
  isFieldScrollable: boolean;
  isEditInProcess?: boolean;
  isOpenSelectList?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
};

const FieldWrapper = styled.div<FieldWrapperProps>`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: ${({ title, isFieldScrollable, isEditInProcess }) =>
    title
      ? '2px 4px 8px 12px'
      : isFieldScrollable
      ? '2px 40px 2px 6px'
      : isEditInProcess
      ? '2px 40px 0 6px'
      : '2px 40px 0 0'};
  border: ${({ isEditInProcess, title }) => (!isEditInProcess && !title ? 'none' : '1px solid')};
  border-color: ${({ isOpenSelectList, isError, isDisabled, theme }) =>
    isOpenSelectList
      ? colorFetch('primary')({ theme })
      : isError
      ? colorFetch('red1')({ theme })
      : isDisabled
      ? colorFetch('gray5')({ theme })
      : colorFetch('gray3')({ theme })};
  border-radius: ${borderRadiusFetch('primary')};
  background-color: ${({ isDisabled, theme }) => (isDisabled ? colorFetch('gray5')({ theme }) : 'transparent')};
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: ${({ isEditInProcess, title }) => (!isEditInProcess && !title ? 'auto' : 'pointer')};
  pointer-events: ${({ isEditInProcess, title, isDisabled }) =>
    (!isEditInProcess && !title) || isDisabled ? 'none' : 'auto'};
`;

type FieldProps = {
  fieldHeight: number;
};

const Field = styled.div<FieldProps>`
  width: 100%;
  height: ${({ fieldHeight }) => (fieldHeight < 116 ? 'max-content' : '100%')};
  max-height: 112px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  overflow-y: ${({ fieldHeight }) => (fieldHeight < 116 ? 'hidden' : 'scroll')};
  &::-webkit-scrollbar {
    width: 4px;
    background: ${colorFetch('gray4')};
    opacity: 1;
    border-radius: ${borderRadiusFetch('primary')};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray2')};
    border-radius: ${borderRadiusFetch('primary')};
    position: relative;
    top: 4px;
    right: 4px;
  }
`;

const FieldText = styled(Text)`
  transition: none;
`;

export interface typeSelectItem {
  id: string;
}

export interface DropdownMultiChoiceOptions {
  placeholder: string;
  disabledPlaceholder?: string;
  title?: string;
  isValidatingStarted?: boolean;
  isError?: boolean;
  isOpenSelectList?: boolean;
  popUpScrollTo: number;
  popUpScrollToWithCustomPeriod: number;
  wrapperRef: any;
  isDisabled?: boolean;
}

const DropdownMultiChoiceRaw = ({
  parameters,
  options,
  handleCheckedItem,
  handleRemoveSelectedItem,
  handleSelectAll,
  setValue,
  setIsOpenSelectList,
  isEditInProcess,
  languageStrings,
  ...props
}: {
  parameters: any[];
  options: DropdownMultiChoiceOptions;
  handleCheckedItem: (item: string) => void;
  handleRemoveSelectedItem: (item: any) => void;
  handleSelectAll: () => void;
  setValue: (selectedParameters: any) => void;
  setIsOpenSelectList: (value?: boolean) => void;
  isEditInProcess?: boolean;
  languageStrings?: any;
  className?: string;
}) => {
  const theme = useTheme();
  const { gray1, gray2, red1 } = theme.colors;

  const {
    placeholder,
    disabledPlaceholder,
    title,
    isValidatingStarted,
    isError,
    isOpenSelectList,
    popUpScrollTo,
    popUpScrollToWithCustomPeriod,
    wrapperRef,
    isDisabled,
  } = options;

  const [fieldHeight, setFieldHeight] = useState(40);

  const selectedParameters = parameters.filter((index: any) => index.isSelected);
  const areAllSelected = selectedParameters.length === parameters.length;

  const fieldRef = useCallback(
    (el) => {
      if (el) {
        setFieldHeight((val) => (val = el.getBoundingClientRect().height));
      }
    },
    [selectedParameters],
  );

  const popupRef = useRef<any>(null);
  const screenWidth = useWindowSize()[0];
  const isShowMessage =
    screenWidth > 550 ? isValidatingStarted && isError : !isOpenSelectList && isValidatingStarted && isError;

  const openSelectList = useCallback(() => {
    setIsOpenSelectList(true);
  }, []);

  const closeSelectList = useCallback(() => {
    setIsOpenSelectList(false);
  }, []);

  const outsideHandler = useCallback(
    (e: any) => {
      if (isOpenSelectList && !(popupRef && popupRef.current && (popupRef as any).current.contains(e.target))) {
        closeSelectList();
      }
    },
    [isOpenSelectList, closeSelectList],
  );

  const fieldWrapperOnClick = (e: any) => {
    e.stopPropagation();
    !isDisabled &&
      (isOpenSelectList
        ? (function () {
            closeSelectList();
          })()
        : openSelectList());
  };

  useEffect(() => {
    window.addEventListener('click', outsideHandler);
    return () => {
      window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  useEffect(() => {
    setFieldHeight(fieldHeight);
  }, [fieldHeight]);

  useEffect(() => {
    if (isDisabled) {
      setIsOpenSelectList(false);
    }
  }, [isDisabled]);

  const isFieldScrollable = fieldHeight > 115;
  const isFieldGrown = fieldHeight > 40;

  return (
    <InputWrapper title={title} isEditInProcess={isEditInProcess} {...props}>
      <FieldWrapper
        ref={fieldRef}
        theme={theme}
        isOpenSelectList={isOpenSelectList}
        isError={isError && isValidatingStarted}
        onClick={fieldWrapperOnClick}
        title={title}
        isFieldScrollable={isFieldScrollable}
        isEditInProcess={isEditInProcess}
        isDisabled={isDisabled}
      >
        <Field fieldHeight={fieldHeight}>
          {selectedParameters && selectedParameters.length > 0 ? (
            selectedParameters.map((item: any, index: number) => {
              const { name } = item;

              return (
                <Cheap
                  key={index}
                  value={name}
                  closeOnClick={() => {
                    handleRemoveSelectedItem(item);
                    const actualSelectedParam = selectedParameters.filter((param) => param?.id !== item?.id);
                    setValue(actualSelectedParam);
                  }}
                  isFieldGrowed={isFieldGrown}
                  isFieldScrollable={isFieldScrollable}
                  isEditInProcess={isEditInProcess}
                  title={title}
                />
              );
            })
          ) : (
            <FieldText
              type={TextType.TEXT_14_GRAY}
              color={!isEditInProcess && !title ? gray1 : gray2}
              weight={!isEditInProcess && !title ? '500' : '400'}
              margin={title ? '6px 0 0 0' : '2px 0 4px 0'}
              lineHeight={'22px'}
            >
              {!isEditInProcess && !title ? 'N/A' : isDisabled ? disabledPlaceholder : placeholder}
            </FieldText>
          )}
        </Field>
        {isOpenSelectList && !isDisabled && (
          <SelectListPopup
            popupRef={popupRef}
            parameterList={parameters}
            handleCheckedItem={handleCheckedItem}
            handleSelectAll={handleSelectAll}
            fieldHeight={fieldHeight}
            languageStrings={languageStrings}
            isSelectAll={areAllSelected}
            isOpenSelectList={isOpenSelectList}
            wrapperRef={wrapperRef}
            scrollTo={popUpScrollTo}
            scrollToWithCustomPeriod={popUpScrollToWithCustomPeriod}
            title={title}
            selectedParameters={selectedParameters}
          />
        )}
      </FieldWrapper>
      {isShowMessage && (
        <Text type={TextType.TEXT_12_GRAY} color={red1} margin={'4px 0 0 12px'}>
          {title
            ? `${languageStrings.generateReportModalShowMessage || 'You should choose'} ${title.toLocaleLowerCase()}`
            : ``}
        </Text>
      )}
    </InputWrapper>
  );
};

export const DropdownMultiChoice = React.memo(DropdownMultiChoiceRaw);
