// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import React from 'react';
import { FC } from 'react';
import { ArrowLeftIcon } from '../ArrowLeftIcon';
import { Text, TextType } from '@bit/first-scope.text';
import { PackageDetailModal } from './PackageDetailModal';

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid ${({ borderColor }: any) => borderColor};
  background-color: transparent;
  gap: 10px;
  padding-left: 10px;
  cursor: pointer;
  & > svg > path {
    stroke: ${({ iconColor }: any) => iconColor};
  }
  & > span {
    font-weight: 400;
  }
`;

export const ContentWrapperExtra = styled.div`
  max-height: calc(100% - 70px);
  height: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
    opacity: 0.6;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray3;
    border-radius: 15px;
  }
`;

export const DetailsModalWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: ${({ isOpen }: any) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  min-width: 250px;
  width: 250px;
  height: 100%;
  border: 1px solid ${({ borderColor }: any) => borderColor};
  background-color: ${({ backgroundColor }: any) => backgroundColor};
  border-radius: ${({ borderRadius }: any) => borderRadius};
  box-sizing: border-box;
  transition: transform 0.3s linear;
  margin: 0;
`;

export enum DeviceType {
  PACKAGE = 'PACKAGE',
  NODE = 'NODE',
  TRACKER = 'TRACKER',
}

interface PackageModalType {
  data: any;
  isOpenModal: boolean;
  closeModal: () => void;
  languageStrings: any;
  color: string;
  isNeverHadLocation: boolean;
}

export const PackageModal: FC<PackageModalType> = ({
  data,
  isOpenModal,
  closeModal,
  color,
  languageStrings,
  isNeverHadLocation,
}) => {
  const theme = useTheme();
  const { gray2, gray3, white } = theme.colors;
  const { additional6 } = theme.borderRadius;

  return (
    <DetailsModalWrapper isOpen={isOpenModal} borderColor={gray3} backgroundColor={white} borderRadius={additional6}>
      <BackButton iconColor={gray2} borderColor={gray3} onClick={closeModal}>
        <ArrowLeftIcon />
        <Text type={TextType.TEXT_14_GRAY}>{languageStrings.backButton}</Text>
      </BackButton>
      <ContentWrapperExtra>
        {/* need add data for isNeverHadLocation */}
        <PackageDetailModal
          color={color}
          data={data}
          languageStrings={languageStrings}
          isNeverHadLocation={isNeverHadLocation}
        />
      </ContentWrapperExtra>
    </DetailsModalWrapper>
  );
};
