import React, { SyntheticEvent } from 'react';
import { Input, StyledCheckbox, Wrapper } from './styles';

const getClassNames = (disabled: boolean, checked: boolean) => {
  const rawClassNames = [disabled ? 'disabled' : '', checked ? 'checked' : ''] as const;
  const classNames = rawClassNames.reduce(
    (className, currentClassName) => (currentClassName ? [...className, currentClassName] : [...className]),
    [] as string[],
  );
  return classNames.join(' ');
};

export interface CheckBoxProps {
  disabled?: boolean;
  value: boolean;
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
}

export const CheckBox: React.FC<CheckBoxProps> = ({ disabled, value, onChange }) => {
  const className = getClassNames(!!disabled, value);

  return (
    <Wrapper>
      <Input
        disabled={disabled}
        className={className}
        type="checkbox"
        // @TODO fix value type
        value={value as any}
      />
      <StyledCheckbox
        className={className}
        // @TODO fix onChange type
        onClick={onChange as any}
      />
    </Wrapper>
  );
};
