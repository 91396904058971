import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../../../../../../../styles/media';
import { CommonButton } from '@bit/first-scope.common-button';
import { InputModal } from '../../../../../../../../components/inputModal/InputModal';
import { Select } from '../../../../../../../../components/select/Select';
import { Text, TextType } from '@bit/first-scope.text';
import { attachToTracker } from '../../../../../../../../api/manageDevicesDetails';
import { useStyleContext } from '../../../../../../../../styles/style.context';
import { isValidField, FieldsType, onlyPositiveNumber } from '../../../../../../../../utils/validation';
import { CloseWhiteRoundButton } from '../../../../../../../../components/closeWhiteRoundButton/CloseWhiteRoundButton';
import { useNotifierFunctions } from '../../../../../../../../features/notifier2';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../../app/state/userSlice';
import { CustomModal } from '../../../../../../../../components/customModal/CustomModal';
import { useDefaultPackageName, useGetStagesList } from '../../../../../../../../api/manageOrderDetails';
import { useTheme } from '@cthings.co/styles';
import { isClasp, isTablet } from '../../../../../../../../consts';
import { displayViewType } from '../../../../../../../../features/universalTable/components/modalSystem/ModalSystem';
import ls from '../../../../../../../../utils/ls';

const StyledModal = styled(CustomModal)`
  & > div > div > div {
    overflow: unset;
  }

  &.tablet {
    align-items: start;
    justify-content: start;
    padding: 40px;

    & > div {
      width: auto;
    }
  }
`;

const StyledInput = styled(InputModal)`
  width: 248px;
  margin: 0 0 7px 0;
  ${media.tabletNavigation`
    width: 100%;
    margin: 0;
  `}
`;

const StyledSelect = styled(Select)`
  & > div {
    min-height: 40px;
  }
  ${media.phone`
    margin: 0;
  `}
`;

const ButttonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  ${media.phone`
    flex-direction: column-reverse;
  `}
`;

const ContentBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 20px;
  margin: 16px 0 24px 0;
  ${media.tabletNavigation`
    grid-template-columns: 1fr;
    display: flex;
    flex-direction:column;
    overflow-y: scroll;
    overflow-x: hidden;
  `}
`;

const ViewportSwitcher = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
`;

const StyledButton = styled(CommonButton)`
  margin-left: 16px;
  ${media.phone`
    margin: 0;
    height: 40px;
    &:last-child {
      margin-bottom: 10px;
    }
  `}
`;

const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

enum RowItemType {
  INPUT = 'INPUT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  JOINED_SELECT = 'JOINED_SELECT',
}

interface AddPackageModalProps {
  setModalClose: (props: boolean) => void;
  orderId: string;
  trackerData: any;
  componentsData: any;
  setPackage: (props: any) => void;
  isOpenModal: boolean;
  componentIndex: any;
  setComponentIndex: (props: string) => void;
  createPackageProdId: string;
  setCreatePackageProdId: (val: string) => void;
  componentId: string;
  setComponentId: (val: string) => void;
}

export const CreatePackageModal: FC<AddPackageModalProps> = ({
  setModalClose,
  trackerData,
  componentsData,
  orderId,
  setPackage,
  isOpenModal,
  componentIndex,
  setComponentIndex,
  createPackageProdId,
  setCreatePackageProdId,
  componentId,
  setComponentId,
}) => {
  const theme = useTheme();
  const { pureWhite, black1 } = theme.colors;

  const [style] = useStyleContext();
  const { addNotification } = useNotifierFunctions();
  const languageStrings = useSelector(selectLanguageStrings);
  const isValmontTabletView = !isClasp && isTablet;
  const getDisplayView = ls.get('displayView');
  const [displayView, setDisplayView] = useState(getDisplayView || displayViewType.DESKTOP_VIEW);

  const [defaultPackageName] = useDefaultPackageName(orderId, createPackageProdId);
  const [getStagesList] = useGetStagesList(orderId, componentId);
  const { stages, process } = getStagesList;

  const defaultData = {
    name: '',
    component: null,
    quantity: '',
    process: null,
    stage: null,
    status: null,
    tracker: null,
  };
  const [validationIsTarted, setValidationIsTarted] = useState(false);
  const [data, setData] = useState<any>(defaultData);

  const handleChange = (value: any, fieldName: string) => {
    setData((val: any) => ({
      ...val,
      [fieldName]: value,
    }));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.value, 'name');
  };

  const handleComponentChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    setCreatePackageProdId(e?.production_id);
    setComponentId(e?.id);
    handleChange(e, 'component');
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.value, 'quantity');
  };

  const handleStageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e, 'stage');
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e, 'status');
  };

  const handleAddPackage = async () => {
    setValidationIsTarted(true);
    if (isAllFieldsValidated) {
      const res = await attachToTracker(orderId, data, addNotification, languageStrings);
      if (!!res) {
        setPackage(res);
        setModalClose(false);
        setValidationIsTarted(false);
        setComponentIndex(res?.component);
      }
    }
  };

  const changeDisplayViewHandle = useCallback(() => {
    if (displayView === displayViewType.DESKTOP_VIEW) {
      setDisplayView(displayViewType.TABLET_VIEW);
      ls.set('displayView', displayViewType.TABLET_VIEW);
    } else {
      setDisplayView(displayViewType.DESKTOP_VIEW);
      ls.set('displayView', displayViewType.DESKTOP_VIEW);
    }
  }, [displayView]);

  const closeModalHandle = () => {
    setCreatePackageProdId('');
    setComponentId('');
    setValidationIsTarted(false);
    setModalClose(false);
    setData(defaultData);
  };

  const createPackageFields = [
    {
      name: languageStrings.namePlaceholder,
      placeholder: languageStrings.enterNamePlaceholder,
      value: data.name ?? 'N/A',
      onChange: handleNameChange,
      disabled: false,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.labelIndex,
      placeholder: languageStrings.indexPlaceholder,
      values: componentsData?.components,
      value: data?.component?.name || componentIndex,
      disabled: false,
      type: RowItemType.JOINED_SELECT,
      onChange: handleComponentChange,
      isPendingRequest: !componentsData?.components.length,
      isError: !isValidField(data?.component?.name || componentIndex?.name, FieldsType.SELECT),
    },
    {
      name: languageStrings.labelAttachedToTracker,
      placeholder: languageStrings.filledAutomaticallyPlaceholder || 'Is filled automatically',
      value: trackerData.name ? trackerData.name : 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.labelQuantity,
      placeholder: languageStrings.enterQuantityPlaceholder,
      value: data.quantity,
      disabled: false,
      type: RowItemType.INPUT_NUMBER,
      onChange: handleQuantityChange,
      isError: !isValidField(data.quantity, FieldsType.NUMBER, onlyPositiveNumber),
    },
    {
      name: languageStrings.labelProcess,
      placeholder: languageStrings.filledAutomaticallyPlaceholder || 'Is filled automatically',
      value: data?.process?.name ?? 'N/A',
      disabled: true,
      type: RowItemType.INPUT,
    },
    {
      name: languageStrings.labelStage,
      placeholder: !componentId ? languageStrings.firstChooseIndexPlaceholder : languageStrings.chooseStagePlaceholder,
      values: stages,
      value: data?.stage?.name,
      disabled: false,
      type: RowItemType.JOINED_SELECT,
      onChange: handleStageChange,
      isPendingRequest: !(componentId && stages?.length),
      isError: !isValidField(data?.stage?.name, FieldsType.SELECT),
    },
    {
      name: languageStrings.labelStatus,
      placeholder: languageStrings.chooseStatusPlaceholder,
      values: componentsData?.status,
      value: data?.status?.name,
      disabled: false,
      type: RowItemType.JOINED_SELECT,
      onChange: handleStatusChange,
      isPendingRequest: !componentsData?.status.length,
      isError: !isValidField(data?.status?.name, FieldsType.SELECT),
    },
  ];

  const isAllFieldsValidated =
    createPackageFields.length === createPackageFields.filter((el: any) => !el.isError).length;

  useEffect(() => {
    isOpenModal &&
      setData((val: any) => ({
        ...val,
        name: createPackageProdId && defaultPackageName.name,
        component: val.component ?? componentIndex,
        process: componentId && process,
        // tracker: { id: trackerData?.id, name: trackerData?.name } ?? null,
      }));
  }, [defaultPackageName.name, process, componentIndex, isOpenModal, createPackageProdId, trackerData, componentId]);

  useEffect(() => {
    return () => setData(defaultData);
  }, [isOpenModal]);

  useEffect(() => {
    !!getDisplayView && setDisplayView(getDisplayView);
  }, [getDisplayView]);

  useEffect(() => {
    if (!isValmontTabletView) {
      ls.remove('displayView');
      setDisplayView(displayViewType.DESKTOP_VIEW);
    }
  }, [isValmontTabletView]);

  return (
    <StyledModal isOpen={isOpenModal} className={displayView === displayViewType.TABLET_VIEW ? 'tablet' : ''}>
      <WrapperTitle>
        <Text type={TextType.TEXT_14_BLACK} fontSize={'18px'} color={black1} weight={'600'}>
          {languageStrings ? languageStrings.addPackageButton : 'Add package'}
        </Text>
        {isValmontTabletView && (
          // @ts-ignore
          <ViewportSwitcher onClick={changeDisplayViewHandle} type={TextType.TEXT_14_GRAY} color={theme.colors.primary}>
            {displayView === displayViewType.DESKTOP_VIEW ? languageStrings.tabletView : languageStrings.desktopView}
          </ViewportSwitcher>
        )}
      </WrapperTitle>
      {style.mediaType.phone && <CloseWhiteRoundButton onClick={() => setModalClose(false)} />}
      <ContentBlock>
        {createPackageFields.map((item: any, index: number) => {
          const {
            name,
            fieldName,
            value,
            disabled,
            type,
            onChange,
            values,
            placeholder,
            isError,
            isPendingRequest,
          } = item;
          return type === RowItemType.INPUT || type === RowItemType.INPUT_NUMBER ? (
            <StyledInput
              key={index}
              name={name}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              onChange={disabled ? () => {} : onChange}
              type={type === RowItemType.INPUT ? 'text' : 'number'}
              isError={isError}
              startedValidation={validationIsTarted}
            />
          ) : type === RowItemType.JOINED_SELECT ? (
            <StyledSelect
              value={value}
              label={name}
              disabled={disabled}
              placeholder={placeholder}
              values={values}
              onChange={(value: any) => {
                onChange(value, fieldName);
              }}
              isError={isError}
              startedValidation={validationIsTarted}
              isPendingRequest={isPendingRequest}
            />
          ) : null;
        })}
      </ContentBlock>
      <ButttonBlock>
        <StyledButton
          width={style.mediaType.phone ? '100%' : '116px'}
          backgroundColor={pureWhite}
          color={black1}
          onClick={closeModalHandle}
        >
          {languageStrings ? languageStrings.cancelButton : 'Cancel'}
        </StyledButton>
        <StyledButton width={style.mediaType.phone ? '100%' : '116px'} onClick={handleAddPackage}>
          {languageStrings ? languageStrings.createButton : 'Create'}
        </StyledButton>
      </ButttonBlock>
    </StyledModal>
  );
};
