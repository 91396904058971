// @ts-ignore
import { isFunction, isObject, isBoolean, isNumber, isString, isArray } from 'underscore';

const FAKE_PREFIX = 'CthingscoFakePrefix_';

const getFakeKey = (key: string) => FAKE_PREFIX + key;

export enum DataType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  ARRAY = 'ARRAY',
  OBJECT = 'OBJECT',
  BOOLEAN = 'BOOLEAN',
  FUNCTION = 'FUNCTION',
  UNDEFINED = 'UNDEFINED',
}

export enum FakeKeys {
  APPLY_TO_ARRAY = 'applyToArray',
  VALUE = 'value',
  FIELD_NAME = 'fieldName',
}

export const getDataType = (data: number | any[] | boolean | string | Object | Function): DataType => {
  if (isArray(data)) return DataType.ARRAY;
  if (isFunction(data)) return DataType.FUNCTION;
  if (isObject(data)) return DataType.OBJECT;
  if (isBoolean(data)) return DataType.BOOLEAN;
  if (isNumber(data)) return DataType.NUMBER;
  if (isString(data)) return DataType.STRING;

  return DataType.UNDEFINED;
};

const applyToArrayKey = getFakeKey(FakeKeys.APPLY_TO_ARRAY);
const valueKey = getFakeKey(FakeKeys.VALUE);
const fieldNameKey = getFakeKey(FakeKeys.FIELD_NAME);

// use this function when want to apply something to all elements of array
export const applyToArray = (data: any) => {
  return [{ [applyToArrayKey]: true, [valueKey]: data }];
};

const prepareObject = (data: any) =>
  Object.keys(data).map((key) => ({
    [fieldNameKey]: key,
    [valueKey]: data[key],
  }));

export const fakeApiExtend = (fakeData: any, targetData: any) => {
  let fakeDataComputed = fakeData;
  const fakeType = getDataType(fakeData);
  const targetType = getDataType(targetData);
  if (fakeType === DataType.FUNCTION) {
    fakeDataComputed = fakeData(targetData);
  }
  if (fakeType !== targetType) {
    return fakeDataComputed;
  } else if ([DataType.NUMBER, DataType.BOOLEAN, DataType.STRING].includes(targetType)) {
    return fakeDataComputed;
  } else if (targetType === DataType.ARRAY) {
    if (fakeDataComputed[0][applyToArrayKey]) {
      return targetData.reduce((acc: any[], current: any) => {
        return [...acc, fakeApiExtend(fakeDataComputed[0][valueKey], current)];
      }, []);
    } else {
      return fakeDataComputed;
    }
  } else if (targetType === DataType.OBJECT) {
    const preparedFakeData = prepareObject(fakeDataComputed);
    const preparedTargetData = prepareObject(targetData);
    return preparedFakeData.reduce(
      (acc: any[], current: any) => {
        const targetIndex = preparedTargetData.findIndex((element) => element[fieldNameKey] === current[fieldNameKey]);
        const keyExists = targetIndex !== -1;
        const additionalObject: Object = keyExists
          ? { [current[fieldNameKey]]: fakeApiExtend(current[valueKey], preparedTargetData[targetIndex][valueKey]) }
          : { [current[fieldNameKey]]: current[valueKey] };
        return { ...acc, ...additionalObject };
      },
      { ...targetData },
    );
  }
};
