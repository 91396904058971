var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { useTheme } from '@xstyled/styled-components';
import { media } from '../../styles/media';
import { getParams } from './textParams';
export var TextType;
(function (TextType) {
    TextType["CHIPS_WHITE"] = "CHIPS_WHITE";
    TextType["CHIPS_BLACK"] = "CHIPS_BLACK";
    TextType["TEXT_14_BLACK"] = "TEXT_14_BLACK";
    TextType["TEXT_14_WHITE"] = "TEXT_14_WHITE";
    TextType["TEXT_14_GRAY"] = "TEXT_14_GRAY";
    TextType["TEXT_14_RED"] = "TEXT_14_RED";
    TextType["TEXT_14_GREEN"] = "TEXT_14_GREEN";
    TextType["PROBLEMS_BUTTON_TEXT"] = "PROBLEMS_BUTTON_TEXT";
    TextType["HELPER_TEXT_1"] = "HELPER_TEXT_1";
    TextType["HELPER_TEXT_2"] = "HELPER_TEXT_2";
    TextType["MENU_ITEM_TEXT_PRIMARY"] = "MENU_ITEM_TEXT_PRIMARY";
    TextType["MENU_ITEM_TEXT_ADDITIONAL"] = "MENU_ITEM_TEXT_ADDITIONAL";
    TextType["TEXT_12_BLACK"] = "TEXT_12_BLACK";
    TextType["TEXT_12_GRAY"] = "TEXT_12_GRAY";
    TextType["TEXT_12_GREEN"] = "TEXT_12_GREEN";
    TextType["TEXT_10_GRAY"] = "TEXT_10_GRAY";
    TextType["TEXT_10_WHITE"] = "TEXT_10_WHITE";
    TextType["TEXT_20_MAP"] = "TEXT_20_MAP";
    TextType["TITLE_PRIMARY"] = "TITLE_PRIMARY";
    TextType["TITLE_SECONDARY"] = "TITLE_SECONDARY";
    TextType["TITLE_ADDITIONAL"] = "TITLE_ADDITIONAL";
    TextType["SEMITITLE"] = "SEMITITLE";
    TextType["TITLE_H1"] = "TITLE_H1";
    TextType["TITLE_H1MOBILE"] = "TITLE_H1MOBILE";
    TextType["TITLE_H3"] = "TITLE_H3";
    TextType["TITLE_H3MOBILE"] = "TITLE_H3MOBILE";
    TextType["TITLE_H4"] = "TITLE_H4";
    TextType["TITLE_H4MOBILE"] = "TITLE_H4MOBILE";
    TextType["TITLE_H5"] = "TITLE_H5";
    TextType["TITLE_H6BOLD"] = "TITLE_H6BOLD";
    TextType["TITLE_H6MOBILE"] = "TITLE_H6MOBILE";
})(TextType || (TextType = {}));
const BaseSpan = styled.span `
  font-family: 'Poppins', sans-serif;
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  color: ${(props) => props.color};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  transition: all 0.3s ease;
`;
const Chips = styled(BaseSpan) ``;
const Text14 = styled(BaseSpan) ``;
const ProblemsButtonText = styled(BaseSpan) `
  ${media.tablet `
    font-size: 45px;
    line-height: 45px;
  `}
`;
const HelperText = styled(BaseSpan) ``;
const MenuItemText = styled(BaseSpan) `
  &:hover {
    color: ${(props) => props.type === TextType.MENU_ITEM_TEXT_PRIMARY ? props.theme.colors.primary : props.theme.colors.gray1};
    font-weight: ${(props) => (props.type === TextType.MENU_ITEM_TEXT_ADDITIONAL ? '600' : '500')};
  }
`;
const Text12 = styled(BaseSpan) ``;
const Text10 = styled(BaseSpan) `
  letter-spacing: 0.2px;
`;
const Title = styled(BaseSpan) `
  user-select: none;
`;
export const Text = (_a) => {
    var { children, type, isHovered, color, margin, weight } = _a, props = __rest(_a, ["children", "type", "isHovered", "color", "margin", "weight"]);
    const theme = useTheme();
    const params = getParams(type, theme, color, weight);
    const values = Object.assign(Object.assign({}, params), { margin });
    const isChips = type === TextType.CHIPS_WHITE || type === TextType.CHIPS_BLACK;
    const isText14 = type === TextType.TEXT_14_BLACK ||
        type === TextType.TEXT_14_WHITE ||
        type === TextType.TEXT_14_GRAY ||
        type === TextType.TEXT_14_RED ||
        type === TextType.TEXT_14_GREEN;
    const isProblemsButton = type === TextType.PROBLEMS_BUTTON_TEXT;
    const isHelperText = type === TextType.HELPER_TEXT_1 || type === TextType.HELPER_TEXT_2;
    const isMenuItem = type === TextType.MENU_ITEM_TEXT_PRIMARY || type === TextType.MENU_ITEM_TEXT_ADDITIONAL;
    const isText12 = type === TextType.TEXT_12_BLACK || type === TextType.TEXT_12_GRAY || type === TextType.TEXT_12_GREEN;
    const isText10 = type === TextType.TEXT_10_GRAY || type === TextType.TEXT_10_WHITE;
    const isTitle = type === TextType.TITLE_PRIMARY ||
        type === TextType.TITLE_SECONDARY ||
        type === TextType.TITLE_ADDITIONAL ||
        type === TextType.TITLE_H1 ||
        type === TextType.TITLE_H1MOBILE ||
        type === TextType.TITLE_H3 ||
        type === TextType.TITLE_H3MOBILE ||
        type === TextType.TITLE_H4 ||
        type === TextType.TITLE_H4MOBILE ||
        type === TextType.TITLE_H5;
    const isNone = !isChips && !isText14 && !isProblemsButton && !isHelperText && !isMenuItem && !isText12 && !isText10 && !isTitle;
    return (React.createElement(React.Fragment, null,
        isChips && (React.createElement(Chips, Object.assign({}, values, props), children)),
        isText14 && (React.createElement(Text14, Object.assign({}, values, props), children)),
        isProblemsButton && (React.createElement(ProblemsButtonText, Object.assign({}, values, props), children)),
        isHelperText && (React.createElement(HelperText, Object.assign({}, values, props), children)),
        isMenuItem && (React.createElement(MenuItemText, Object.assign({}, values, { theme: theme, isHovered: isHovered, type: type }, props), children)),
        isText12 && (React.createElement(Text12, Object.assign({}, values, props), children)),
        isText10 && (React.createElement(Text10, Object.assign({}, values, props), children)),
        isTitle && (React.createElement(Title, Object.assign({}, values, props), children)),
        isNone && (React.createElement(BaseSpan, Object.assign({}, values, props), children))));
};
Text.defaultProps = {};
