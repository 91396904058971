/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for Packages page
 * Highly customisable.
 *
 */

import { Text, TextType } from '@bit/first-scope.text';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/userSlice';
import { API_URL } from '../../../consts';
import { InlineActionType, RowItemType } from '../../../features/universalTable/types';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import { View } from '../../../routes/routeInterfaces';
import { useMediaType } from '../../../styles/style.context';
import { useCustomHistory } from '../../../utils/react-router-dom-abstraction';
import { ButtonIconType, CommonButton } from '@bit/first-scope.common-button';
import { Wrapper, Title, StyledTextLink, TitleRow } from './styled';
import { useQueryParams } from '../../../utils/useQueryParams';
import { stringifyUrl } from 'query-string';
import { useParams } from 'react-router-dom';
import { useTableFunctions } from '../../../features/universalTable/context';
import { useTheme } from '@cthings.co/styles';

export interface PackagesProps {}

export const Packages: FC<PackagesProps> = () => {
  const customHistory = useCustomHistory();
  const { resetTable } = useTableFunctions();
  const keyword = 'PackagesTable';
  const offsetKey = 'offset';
  const theme = useTheme();

  const { gray1, primary, secondary2 } = theme.colors;

  const { tablet, phone } = useMediaType();
  const params = useParams();

  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const queryParams = useQueryParams() || {};
  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };
  const queryTransformed = stringifyUrl({ url: '', query: queryParams }) + '&';

  const languageStrings = useSelector(selectLanguageStrings);

  const handleCustomRowClick = (value: any) => {
    customHistory.push(View.MANAGE_ORDERS_DETAILS, {
      pathParams: { id: value.order.id, componentsOffset: 0, packagesOffset: 0, eventsOffset: 0 },
      state: { id: value.id },
    });
  };

  const handleDiviceDetailsRedirect = (value: any) => {
    const to =
      value.role === 'Tracker' ? View.USER_MANAGE_DEVICES_DETAILS_TRACKER : View.USER_MANAGE_DEVICES_DETAILS_NODE;
    customHistory.push(to, {
      pathParams: { id: value.id, buildingId: value.wpas_ref_building_id, offset: 0 },
    });
  };

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  return (
    <Wrapper>
      <TitleRow>
        <CommonButton
          width={'32px'}
          height={'32px'}
          backgroundColor={primary}
          backgroundColorHover={secondary2}
          borderColorHover={secondary2}
          iconType={ButtonIconType.ARROW_LEFT}
          onClick={() => customHistory.goBack()}
          iconWithoutText
        />
        <Title type={TextType.TEXT_14_GRAY}>{languageStrings.labelPackages}</Title>
      </TitleRow>
      <UniversalTable
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !tablet, countRows: phone ? 6 : 12 },
        }}
        offset={+offset}
        offsetKey={offsetKey}
        tablePath={View.MANAGE_PACKAGES}
        mainApiUrl={queryParams.zone ? `${API_URL}/v2/packages/filters${queryTransformed}` : `${API_URL}/v2/packages/`}
        withoutQuestionMarkBeforeLimit={!!queryParams.zone}
        sortingData={sortingData}
        sortingKeys={sortingKeys}
        pathParams={params}
        queryParams={queryParams}
        keyword={keyword}
        updateItems={() => {}}
        customRowClick={handleCustomRowClick}
        inlineActionSet={[
          {
            type: InlineActionType.DETAILS,
          },
        ]}
        columnStructure={[
          {
            fieldName: 'name',
            placeholder: '',
            label: languageStrings.labelName,
            defaultValue: '',
            excludedFromAdd: true,
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <Text type={TextType.TEXT_14_GRAY} color={gray1} weight={'500'}>
                {value ?? 'N/A'}
              </Text>
            ),
          },
          {
            fieldName: 'order',
            placeholder: '',
            label: languageStrings.labelOrderID,
            defaultValue: '',
            excludedFromAdd: true,
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <Text type={TextType.TEXT_14_GRAY} color={gray1} weight={'500'}>
                {value?.generic_id ?? 'N/A'}
              </Text>
            ),
          },
          {
            fieldName: 'component',
            placeholder: '',
            label: languageStrings.labelProductionID,
            defaultValue: '',
            excludedFromAdd: true,
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <Text type={TextType.TEXT_14_GRAY} color={gray1} weight={'500'}>
                {value?.production_id ?? 'N/A'}
              </Text>
            ),
          },
          {
            fieldName: 'component',
            placeholder: '',
            label: languageStrings.indexPlaceholder,
            defaultValue: '',
            excludedFromAdd: true,
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <Text type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
                {value?.index ?? 'N/A'}
              </Text>
            ),
          },
          {
            fieldName: 'tracker',
            placeholder: '',
            label: languageStrings.clusteredContentTracker,
            defaultValue: '',
            excludedFromAdd: true,
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <StyledTextLink
                onClick={() => (value?.name ? handleDiviceDetailsRedirect(value) : () => {})}
                type={TextType.TEXT_14_GRAY}
                color={gray1}
                weight={'400'}
                withUnderline={!!value?.name}
              >
                {value?.name ?? 'N/A'}
              </StyledTextLink>
            ),
          },
          {
            fieldName: 'location',
            placeholder: '',
            label: languageStrings.labelZone,
            defaultValue: '',
            excludedFromAdd: true,
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <Text type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
                {value?.zone_name ?? 'N/A'}
              </Text>
            ),
          },
        ]}
      />
    </Wrapper>
  );
};
