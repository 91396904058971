import React from 'react';
import styled from 'styled-components';
import { Text } from '@bit/first-scope.text';
import { useNotifierFunctions } from '../../features/notifier2';
import { useMediaType } from '../../styles/style.context';
import { useSelector } from 'react-redux';
import { colorFetch } from '../../styles/utils';
import { selectLanguageStrings } from '../../app/state/userSlice';

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  box-sizing: border-box;
  background: ${colorFetch('gray5')};
  border-radius: 6px;
  padding: 5px 5px 5px 10px;
`;

const CopyButton = styled.button`
  background: ${colorFetch('pureWhite')};
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: 6px;
  color: ${colorFetch('gray1')};
  box-sizing: border-box;
  cursor: pointer;
  padding: 7px 7px;
  min-width: 80px;
  &:hover {
    background-color: ${colorFetch('gray2')};
    border-color: ${colorFetch('gray2')};
    color: ${colorFetch('pureWhite')};
    transition: all 0.3s ease;
  }
`;

const Link = styled(Text)`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
  overflow: hidden;
` as any; // @TODO fix type

export const CopyLinkInput = ({ link }: { link: string }) => {
  const mediaType = useMediaType();
  const { addNotification } = useNotifierFunctions();
  const language = useSelector(selectLanguageStrings);

  const handleCopyText = async (copiedText: string) => {
    await navigator.clipboard.writeText(copiedText);
    addNotification({ message: language ? language.externalCopied : 'Copied to clipboard', type: 'info' });
  };

  const textHandler = (text: string) => (text.length >= 42 ? text.slice(0, mediaType.phone ? 32 : 48) + '...' : text);

  return (
    <Wrapper>
      <Link>{textHandler(link)}</Link>
      <CopyButton onClick={() => handleCopyText(link)}>{language ? language.externalCopyLink : 'Copy'}</CopyButton>
    </Wrapper>
  );
};
