import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowRightIcon } from '../../../assets/arrowRight.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/arrowLeft.svg';
import { ReactComponent as AddIcon } from '../../../assets/addIcon.svg';
import { ReactComponent as MassageIcon } from '../../../assets/message.svg';
import { ReactComponent as AttachIcon } from '../../../assets/attachIcon.svg';
import { ReactComponent as DetachIcon } from '../../../assets/detach.svg';
import { ReactComponent as UploadIcon } from '../../../assets/upload.svg';
import { ReactComponent as ResetIcon } from '../../../assets/reset.svg';
import { ReactComponent as RevokeIcon } from '../../../assets/revoke.svg';
import { ReactComponent as PreviewIcon } from '../../../assets/preview.svg';
import { ReactComponent as LocationIcon } from '../../../assets/location.svg';

export enum ButtonIconType {
  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_RIGHT = 'ARROW_RIGHT',
  ADD = 'ADD',
  MASSAGE = 'MASSAGE',
  ATTACH = 'ATTACH',
  DETACH = 'DETACH',
  UPLOAD = 'UPLOAD',
  RESET = 'RESET',
  REVOKE = 'REVOKE',
  PREVIEW = 'PREVIEW',
  LOCATION = 'LOCATION',
}

type WrapperProps = {
  iconWithoutText?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  & > svg {
    width: 20px;
    height: 20px;
    margin-right: ${({ iconWithoutText }) => (iconWithoutText ? '0' : '4px')};
  }
`;

export const Icons = ({
  type,
  color,
  iconWithoutText,
}: {
  type?: ButtonIconType;
  color?: string;
  iconWithoutText?: boolean;
}) => {
  return (
    <Wrapper iconWithoutText={iconWithoutText}>
      {type === ButtonIconType.ARROW_LEFT && <ArrowLeftIcon />}
      {type === ButtonIconType.ARROW_RIGHT && <ArrowRightIcon />}
      {type === ButtonIconType.ADD && <AddIcon />}
      {type === ButtonIconType.MASSAGE && <MassageIcon />}
      {type === ButtonIconType.ATTACH && <AttachIcon />}
      {type === ButtonIconType.DETACH && <DetachIcon />}
      {type === ButtonIconType.PREVIEW && <PreviewIcon />}
      {type === ButtonIconType.UPLOAD && <UploadIcon color={color} />}
      {type === ButtonIconType.RESET && <ResetIcon color={color} />}
      {type === ButtonIconType.REVOKE && <RevokeIcon color={color} />}
      {type === ButtonIconType.LOCATION && <LocationIcon color={color} />}
    </Wrapper>
  );
};
