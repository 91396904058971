import React from 'react';
import { use100vh } from 'react-div-100vh';
import { useSelector } from 'react-redux';

import { useUserAccess } from '../../api/user';
import { selectLanguage } from '../../app/state/userSlice';
import withAuth from '../../features/authentication/withAuth';
import { renderRoutes } from '../../utils/renderRoutes';

import { Wrapper } from '../elements/wrappers';

const Errors = (props: any) => {
  const languageKey = useSelector(selectLanguage);
  useUserAccess(languageKey?.id);

  const minHeight = use100vh();
  return <Wrapper minHeight={minHeight}>{renderRoutes(props.routes, props.parentPathLength)}</Wrapper>;
};

export default withAuth(Errors);
