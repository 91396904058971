/**
 * @description
 * This is component that displays mobile version of list of zones modal
 */

import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { selectLanguageStrings } from '../../../../../../../../app/state/userSlice';
import { DraggableModal } from '../../../../../../../../components/draggableModal/DraggableModal';
import { CloseButton } from '../../../../../../../../components/closeButton/CloseButton';
import { ReactComponent as Arrow } from '../../../../../../../../assets/big_arrow.svg';
import { MobileView } from '../../mobileView/MobileView';
import { PopupPlaceholder } from '../../../../../../../../components/searchBar/components/popupPlaceholder/PopupPlaceholder';
import { useBlockBodyScroll } from '../../../../../../../../utils/useBlockBodyScroll';
import { colorFetch } from '../../../../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled(DraggableModal)``;
const Container = styled.div`
  max-height: 60vh;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled(Text)`
  font-size: 18px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ZoneWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ZoneName = styled(Text)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
` as any; // @TODO

export const ListOfZonesModal = ({
  isOpenModal,
  setIsOpenModal,
  closeModal,
  closeZoneModal,
  activeData,
  setSavedZones,
  handleScrollToTop,
  locations,
  setActiveId,
}: {
  locations: any;
  isOpenModal: boolean;
  setIsOpenModal: (val: any) => void;
  closeModal: (val: any) => void;
  closeZoneModal: () => void;
  activeData: any;
  setSavedZones: (val: any) => void;
  handleScrollToTop: (val: string) => void;
  setActiveId: (val: any) => void;
}) => {
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);
  useBlockBodyScroll(isOpenModal);

  return (
    <>
      <Wrapper
        isDraggable={true}
        withoutNav={true}
        isSearchModal={false}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        isScroll={locations && locations.length > 10}
      >
        <TitleWrapper>
          <Title type={TextType.TITLE_PRIMARY}>
            {languageStrings ? languageStrings.listOfZonesButton : 'List of zones'}
          </Title>
          <CloseButton onClick={closeModal} color={theme.colors.gray1} hoverColor={theme.colors.gray2} />
        </TitleWrapper>
        <Container
          theme={theme}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ListWrapper>
            {locations && locations.length > 0 ? (
              locations.map((zone: any) => {
                return (
                  <ZoneWrapper
                    onClick={() => {
                      setIsOpenModal(false);
                      setActiveId(zone.id);
                    }}
                  >
                    <ZoneName>{zone.name}</ZoneName>
                    <Arrow />
                  </ZoneWrapper>
                );
              })
            ) : (
              <PopupPlaceholder languageStrings={languageStrings} />
            )}
          </ListWrapper>
        </Container>
      </Wrapper>
      {activeData && (
        <MobileView
          value={activeData}
          closeModal={closeZoneModal}
          setSavedZones={setSavedZones}
          handleScrollToTop={handleScrollToTop}
          locations={locations}
        />
      )}
    </>
  );
};
