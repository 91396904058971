import React, { FC } from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { ActiveOrder } from './activeOrder/ActiveOrder';
import { TableOrderInfo } from './tableOrderInfo/TableOrderInfo';
import { injectPathParams, PATHS } from '../../../../../routes/paths';
import { useHistory } from '../../../../../utils/react-router-dom-abstraction';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 81px;
  margin-top: 35px;
`;

interface OrderTableType {
  data: any;
  packageList: any;
}

export const OrderTable: FC<OrderTableType> = ({ data, packageList }) => {
  const orderId = data.text ? data.text.content[1].value : 'N/A';
  const history = useHistory();

  const handleGoToOrder = () => {
    const to = injectPathParams(PATHS.MANAGE_ORDERS_DETAILS, {
      id: data.text.content[0].value,
      componentsOffset: 0,
      packagesOffset: 0,
      eventsOffset: 0,
    });
    to && history.push(to);
  };

  return (
    <Wrapper>
      <ActiveOrder orderId={orderId} onGoToOrderClick={handleGoToOrder} />
      <TableOrderInfo data={data.text ? data.text.content : []} packageList={packageList} />
    </Wrapper>
  );
};
