import React from 'react';
import styled from 'styled-components';
import { ErrorPageType } from '../../ErrorPage';
import { ReactComponent as Error401Image } from '../../../../assets/error_401.svg';
import { ReactComponent as Error403Image } from '../../../../assets/error_403.svg';
import { ReactComponent as Error404Image } from '../../../../assets/error_404.svg';
import { ReactComponent as Error500Image } from '../../../../assets/error_500.svg';
import { media } from '../../../../styles/media';

const StyledImage500 = styled(Error500Image)`
  position: relative;
  bottom: 44px;
  left: 18px;
  ${media.phone`
    width: 95%;
    left: 0;
  `}
`;
const StyledImage404 = styled(Error404Image)`
  position: relative;
  bottom: 50px;
  left: 38px;
  ${media.phone`
    width: 95%;
    left: 0;
  `}
`;
const StyledImage403 = styled(Error403Image)`
  position: relative;
  bottom: 20px;

  ${media.phone`
    width: 95%;
    left: 0;
  `}
`;

export const ErrorImage = ({ type }: { type: ErrorPageType }) => {
  if (type === ErrorPageType.ERROR_401) {
    return <Error401Image />;
  } else if (type === ErrorPageType.ERROR_403) {
    return <StyledImage403 />;
  } else if (type === ErrorPageType.ERROR_404) {
    return <StyledImage404 />;
  } else if (type === ErrorPageType.ERROR_500) {
    return <StyledImage500 />;
  } else {
    return null;
  }
};
