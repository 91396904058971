import { ProcessesState } from './manageProcessesInterfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

// @TODO Liza WHY DO YOU NEED REDUX HERE??

const initialState: ProcessesState = {
  list: [{ id: '', name: '', isSelected: false }],
  selected_processes: [],
  isOpenPopup: false,
};

export const manageProcessesSlice = createSlice({
  name: 'manageProcesses',
  initialState,
  reducers: {
    setProcesses: (state, action: PayloadAction<any>) => {
      state.list = action.payload.list;
    },
    setSeelectedProcesses: (state, action: PayloadAction<any>) => {
      state.selected_processes = action.payload.list;
    },
    toggleIsSelected: (state, action: PayloadAction<any>) => {
      const index = state.list.findIndex((el: any) => el.id === action.payload.id);
      state.list[index].isSelected = !state.list[index].isSelected;
      state.selected_processes = state.list.filter((el: any) => el.isSelected === true).map((item: any) => item.id);
    },
    toggleIsOpenPopup: (state) => {
      state.isOpenPopup = !state.isOpenPopup;
    },
  },
});

export const {
  setProcesses,
  setSeelectedProcesses,
  toggleIsSelected,
  toggleIsOpenPopup,
} = manageProcessesSlice.actions;

export const SelectProcessesList = (state: RootState) => {
  return state.manageProcesses.list;
};

export const SelectProcessesSelectedStages = (state: RootState) => {
  return state.manageProcesses.selected_processes;
};

export const SelectProcessesState = (state: RootState) => {
  return state.manageProcesses;
};

export default manageProcessesSlice.reducer;
