/**
 * @description
 * This is component for choose parameter in select
 * all the functionalities cthings require for chooseParameterSelect
 * Highly customisable.
 *
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ChooseParameterSelectPopup } from './components/chooseParameterSelectPopup/ChooseParameterSelectPopup';
import { ReactComponent as ArrowIcon } from '../../assets/small_arrow.svg';
import { TypeOfDeviceParameter } from '../../containers/common/campusMapInsight/Content/Content';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { media } from '../../styles/media';
import { useTheme } from '@cthings.co/styles';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';

type WrapperProps = {
  maxWidth?: string;
};

const Wrapper = styled.div<WrapperProps>`
  max-width: ${({ maxWidth }) => maxWidth || '160px'};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px;
  position: relative;
  ${media.desktop`
    margin-top: 10px;
  `}
`;

type FieldWrapperProps = {
  minHeight?: string;
  isOpenSelectList?: boolean;
  checkedCount?: number;
  isSelectAll?: boolean;
  isPopupDescription?: boolean;
};

const FieldWrapper = styled.div<FieldWrapperProps>`
  width: 100%;
  height: max-content;
  min-height: ${({ minHeight }) => minHeight || '31px'};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 7px;
  border: 1px solid;
  border-color: ${({ isOpenSelectList, theme }) =>
    isOpenSelectList ? colorFetch('primary')({ theme }) : colorFetch('gray3')({ theme })};

  border-radius: ${borderRadiusFetch('primary')};
  background-color: ${({ isOpenSelectList, checkedCount, isSelectAll, theme }) =>
    isOpenSelectList || (checkedCount && checkedCount > 0) || isSelectAll
      ? colorFetch('pureWhite')({ theme })
      : 'transparent'};

  transition: all 0.3s ease;
  overflow: hidden;
  cursor: ${({ isPopupDescription }) => (isPopupDescription ? 'auto' : 'pointer')};
  &.disabled {
    cursor: auto;
    opacity: 0.4;
  }
`;

const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectExtraWrapper = styled.div`
  display: flex;
  align-items: center;
  & > span {
    margin-right: 10px;
  }
`;

type ArrowProps = {
  isOpenSelectList?: boolean;
};

const Arrow = styled(ArrowIcon)<ArrowProps>`
  transform: ${({ isOpenSelectList }) => isOpenSelectList && 'rotate(-180deg)'};
  transition: all 0.3s ease-in-out;
`;

type CheckedCountProps = {
  fontSizeSelectNameCount?: string;
};

const CheckedCount = styled(Text)<CheckedCountProps>`
  font-size: ${({ fontSizeSelectNameCount }) => fontSizeSelectNameCount || '12px'};
`;

type SelectNameProps = {
  fontSizeSelectNameCount?: string;
};

const SelectName = styled(Text)<SelectNameProps>`
  font-size: ${({ fontSizeSelectNameCount }) => fontSizeSelectNameCount || '12px'};
`;

export const ChooseParameterSelect = ({
  type,
  name,
  parameters,
  handleCheckedItem,
  handleSelectAll,
  setIsOpenSelectList,
  isSelectAll,
  isOpenSelectList,
  isDisabled,
  isPopupDescription,
  isSearchActive,
  checkedCount,
  maxWidth,
  minHeight,
  fontSizeSelectNameCount,
  maxWidthSelectPopup,
}: {
  type?: TypeOfDeviceParameter;
  name?: string;
  parameters: any[];
  handleCheckedItem: (type?: TypeOfDeviceParameter, id?: string) => void;
  handleSelectAll: () => void;
  setIsOpenSelectList: (value: boolean) => void;
  isSelectAll?: boolean;
  isOpenSelectList?: boolean;
  isDisabled?: boolean;
  isPopupDescription?: boolean;
  isSearchActive?: boolean;
  checkedCount?: number;
  maxWidth?: string;
  minHeight?: string;
  fontSizeSelectNameCount?: string;
  maxWidthSelectPopup?: string;
}) => {
  const theme = useTheme();
  const { gray1, gray2, black1 } = theme.colors;

  const [fieldHeight, setFieldHeight] = useState(40);

  const language = useSelector(selectLanguageStrings);

  const popupRef = useRef<any>(null);

  const openSelectList = useCallback(() => {
    setIsOpenSelectList(true);
  }, []);

  const closeSelectList = useCallback(() => {
    setIsOpenSelectList(false);
  }, []);

  const outsideHandler = useCallback(
    (e: any) => {
      if (!!popupRef) {
        if (isOpenSelectList && !popupRef?.current?.contains(e.target)) {
          closeSelectList();
        }
      }
    },
    [isOpenSelectList],
  );

  useEffect(() => {
    document.addEventListener('click', outsideHandler);
    return () => {
      document.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  const parametersArr = parameters ? Object.keys(parameters).map((key: any) => parameters[key]) : [];

  return (
    <Wrapper maxWidth={maxWidth}>
      <FieldWrapper
        className={isDisabled && !isPopupDescription ? 'disabled' : ''}
        ref={(el: any) => {
          if (!el) return;
          setFieldHeight(el.getBoundingClientRect().height);
        }}
        theme={theme}
        isOpenSelectList={isOpenSelectList}
        checkedCount={checkedCount}
        isSelectAll={isSelectAll}
        onClick={isDisabled ? () => {} : isOpenSelectList ? closeSelectList : openSelectList}
        isPopupDescription={isPopupDescription}
        minHeight={minHeight}
      >
        <SelectWrapper>
          <SelectName
            type={TextType.TEXT_12_BLACK}
            color={isOpenSelectList || (!isSelectAll && checkedCount && checkedCount > 0) ? gray1 : gray2}
            fontSizeSelectNameCount={fontSizeSelectNameCount}
          >
            {name}
          </SelectName>
          <SelectExtraWrapper>
            <CheckedCount
              type={TextType.TEXT_12_BLACK}
              color={isOpenSelectList || (!isSelectAll && checkedCount && checkedCount > 0) ? black1 : gray1}
              fontSizeSelectNameCount={fontSizeSelectNameCount}
            >
              {isSelectAll ? (language ? language.labelAll : 'All') : checkedCount === 0 ? '0' : checkedCount}
            </CheckedCount>
            <Arrow isOpenSelectList={isOpenSelectList} />
          </SelectExtraWrapper>
        </SelectWrapper>
        {isOpenSelectList && (
          <ChooseParameterSelectPopup
            popupRef={popupRef}
            handleCheckedItem={handleCheckedItem}
            handleSelectAll={handleSelectAll}
            parameterList={parametersArr}
            fieldHeight={fieldHeight}
            isSelectAll={isSelectAll}
            isSearchActive={isSearchActive}
            maxWidthSelectPopup={maxWidthSelectPopup}
            language={language}
          />
        )}
      </FieldWrapper>
    </Wrapper>
  );
};
