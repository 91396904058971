import React from 'react';

export const PackageIcon = ({ color }: { color: string }) => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.0022 24.9982V32.3059"
        opacity="0.4"
        stroke={color}
        stroke-width="2.63078"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.0022 24.9982V32.3059"
        opacity="0.4"
        stroke={color}
        stroke-width="2.63078"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.0022 24.9982V32.3059"
        opacity="0.4"
        stroke={color}
        stroke-width="2.63078"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.0022 32.793V45.9469"
        stroke={color}
        stroke-width="2.63078"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.46155 23.0494V35.9445C7.46155 36.2035 7.53055 36.4578 7.66147 36.6813C7.7924 36.9049 7.98053 37.0896 8.20655 37.2165L24.2835 46.2468C24.5023 46.3696 24.7491 46.4342 25.0001 46.4342C25.2511 46.4342 25.4978 46.3696 25.7166 46.2468L41.7936 37.2165C42.0196 37.0896 42.2077 36.9049 42.3387 36.6813C42.4696 36.4578 42.5386 36.2035 42.5386 35.9445V23.0494"
        stroke={color}
        stroke-width="2.63078"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.99988 21.9148V15.2364C5.99988 14.9555 6.07463 14.6796 6.21646 14.4371C6.3583 14.1946 6.5621 13.9942 6.80697 13.8564L24.2237 4.05954C24.4607 3.92623 24.728 3.8562 24.9999 3.8562C25.2719 3.8562 25.5392 3.92623 25.7762 4.05954L43.1929 13.8564C43.4378 13.9942 43.6416 14.1946 43.7834 14.4371C43.9253 14.6796 44 14.9555 44 15.2364V21.9532"
        opacity="0.4"
        stroke={color}
        stroke-width="2.63078"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.7819 14.4345L25.188 24.9979L6.21936 14.4325"
        opacity="0.4"
        stroke={color}
        stroke-width="2.63078"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.7819 22.0771L25.188 32.6405L6.21936 22.0751"
        opacity="0.4"
        stroke={color}
        stroke-width="2.63078"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
