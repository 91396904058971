/**
 * @description
 * This is container for dashboard card
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { useNewDashboards } from '../../../api/dashboard';
import { media } from '../../../styles/media';
import { DashboardCard } from '../../../components/dashboardCard/DashboardCard';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@bit/first-scope.common-button';
import { DistributionGraph } from './components/distributionGraph/DistributionGraph';
import { useWindowSize } from '../../../styles/style.context';
import { ReactComponent as CurrentDateIcon } from '../../../assets/corner-down-left.svg';
import { DashboardItemType } from '../../../enums/Dashboard';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/userSlice';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 45px;
  box-sizing: border-box;
  ${media.tablet`
    padding: 38px 16px 0 16px;
  `}
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
`;

const ToCurrentDateButton = styled(CommonButton)`
  height: 32px;
  font-size: 12px;
  & > svg {
    margin-right: 8px;
    & > path {
      stroke: ${({ color }) => color};
    }
  }
  ${media.tablet`
    display: none;
  `}
`;

const MobileToCurrentDateButton = styled(ToCurrentDateButton)`
  display: none;
  ${media.tablet`
    height: 40px;
    font-size: 15px;
    display: flex;
    margin-bottom: 20px;
  `}
`;

const SemiWrapper = styled.div``;

const GlobalRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  ${media.tablet`
    flex-wrap: wrap-reverse;
    & > div {
      width: 100%;
      margin-bottom: 10px;
    }
  `}
`;

const CardsWrapper = styled.div`
  max-width: 896px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  box-sizing: border-box;
  margin: 0 20px 20px 0;
  ${media.desktop`
    width: max-content;
    grid-template-columns: 1fr 1fr;
  `}
  ${media.tablet`
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 0 0 20px 0;
  `}
  ${media.phone`
    grid-template-columns: 1fr;
  `}
`;

const GraphsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
`;

type Params = {
  type: 'summary' | 'orders' | 'inventories';
};

export const Dashboard = () => {
  const theme = useTheme();
  const { pureWhite } = theme.colors;

  const keywordList = ['DashboardCard', 'CalendarWithPlaceholder', 'DashboardGraph', 'DashboardCardFiller'];
  const [date, setDate] = useState(new Date());
  const currentDate = new Date();

  const windowSize = useWindowSize();

  const params = useParams<Params>();

  const languageStrings = useSelector(selectLanguageStrings);
  const newData: any = useNewDashboards(params.type || 'summary', keywordList);
  const upperSection = newData.upper_section ?? [];

  const bottomSection = newData.bottom_section.map((item: any) => {
    return {
      ...item,
      title: item.data.title,
      data: item.data.graph_data.map((graphItem: any) => ({
        title: graphItem.name,
        distributionProportion: graphItem.value,
      })),
    };
  });

  const graphPlaceholderStyle =
    windowSize[0] > 768 ? { margin: '0 0 20px 0' } : { width: '100%', marginBottom: '20px' };

  const handleSetCurrentDate = () => {
    setDate(currentDate);
  };
  const isShowButton = () => {
    if (
      new Date(currentDate).getDay() === new Date(date).getDay() &&
      new Date(currentDate).getMonth() === new Date(date).getMonth() &&
      new Date(currentDate).getFullYear() === new Date(date).getFullYear() &&
      new Date(currentDate).getDate() === new Date(date).getDate()
    ) {
      return false;
    } else {
      return true;
    }
  };

  const title = {
    summary: languageStrings ? languageStrings.dashboardOverallTitle : 'Overall',
    orders: languageStrings ? languageStrings.dashboardOrdersTitle : 'Orders',
    inventories: languageStrings ? languageStrings.dashboardInventoriesTitle : 'Inventories',
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <Text type={TextType.TITLE_H1}>{title[params.type || 'summary']}</Text>
        {isShowButton() ? (
          <ToCurrentDateButton onClick={handleSetCurrentDate} width={'150px'} color={pureWhite}>
            <CurrentDateIcon />
            {languageStrings ? languageStrings.toCurrentDateButton : 'To current date'}
          </ToCurrentDateButton>
        ) : null}
      </TitleWrapper>
      <SemiWrapper>
        <GlobalRow>
          <CardsWrapper>
            {/* {cardsArr.map((item: any, index: number) => {
              const { type, title, nodes, trackers, count, pointValue, iconColor } = item;
              return (
                <DashboardCard
                  key={index}
                  placeholderProps={{ keyword: 'DashboardCard' }}
                  type={type}
                  title={title}
                  nodes={nodes}
                  trackers={trackers}
                  count={count}
                  iconColor={iconColor}
                  pointValue={pointValue}
                />
              );
            })} */}
            {upperSection.map((item: any, index: number) => {
              const { tile_type } = item;

              return (
                <DashboardCard
                  key={index}
                  type={tile_type}
                  dashboardData={item}
                  placeholderProps={{
                    keyword: 'DashboardCard',
                  }}
                />
              );
            })}
          </CardsWrapper>
          {isShowButton() ? (
            <MobileToCurrentDateButton onClick={handleSetCurrentDate} width={'100%'} height={'32px'} color={pureWhite}>
              <CurrentDateIcon />
              {languageStrings ? languageStrings.toCurrentDateButton : 'To current date'}
            </MobileToCurrentDateButton>
          ) : null}
          {/*<CalendarWithPlaceholder
            type={CalendarType.DATE_SELECTION}
            inline={windowSize[0] > 768}
            startDate={date}
            setStartDate={setDate}
            placeholderProps={windowSize[0] > 769 ? { keyword: 'CalendarWithPlaceholder' } : {}}
            maxDate={currentDate}
          />*/}
        </GlobalRow>
        <GraphsWrapper>
          {bottomSection.map((item: any, index: number) => {
            const { tile_type, data, title } = item;

            return (
              <>
                {tile_type === DashboardItemType.DISTRIBUTION_GRAPH && (
                  <DistributionGraph
                    title={title}
                    data={data}
                    placeholderProps={{
                      keyword: 'DashboardGraph',
                      style: graphPlaceholderStyle,
                    }}
                  />
                )}
              </>
            );
          })}
          {/*<ZonesCapacityGraph
            data={cardsForGraph}
            placeholderProps={{
              keyword: 'DashboardGraph',
              style: graphPlaceholderStyle,
            }}
          />
          <PackagesDistributionGraph
            data={cardsForGraph}
            placeholderProps={{
              keyword: 'DashboardGraph',
              style: graphPlaceholderStyle,
            }}
          />*/}
        </GraphsWrapper>
      </SemiWrapper>
    </Wrapper>
  );
};
