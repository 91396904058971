/**
 * @description
 * This is an advanced class that covers
 * operating with Local Storage
 *
 */

import React, { createContext, useState, useEffect, ReactNode, Dispatch, SetStateAction } from 'react';

export type TooltipRequest = {
  id: string;
};
export type Tooltip = {
  openRequests: TooltipRequest[];
  closeRequests: TooltipRequest[];
};

export type TooltipContextProviderType = [Tooltip, Dispatch<SetStateAction<Tooltip>>];

const DEFAULT_TOOLTIP = {
  openRequests: [],
  closeRequests: [],
} as Tooltip;

const TooltipContext = createContext([DEFAULT_TOOLTIP, null as any] as TooltipContextProviderType);

const useTooltipContextCreator = (): TooltipContextProviderType => {
  const [tooltip, setTooltip] = useState(DEFAULT_TOOLTIP);
  return [tooltip, setTooltip];
};

export const TooltipContextProvider = ({ children }: { children: ReactNode }) => {
  const provider = useTooltipContextCreator();
  const [tooltip, setTooltip] = provider;
  const { openRequests, closeRequests } = tooltip;

  useEffect(() => {
    if (openRequests.length > 0) {
      setTooltip((value) => ({
        ...value,
        openRequests: openRequests.filter((request) => request.id !== openRequests[0].id),
      }));
    }
  }, [openRequests]);

  useEffect(() => {
    if (closeRequests.length > 0) {
      setTooltip((value) => ({
        ...value,
        closeRequests: closeRequests.filter((request) => request.id !== closeRequests[0].id),
      }));
    }
  }, [closeRequests]);

  return <TooltipContext.Provider value={provider}>{children}</TooltipContext.Provider>;
};

export default TooltipContextProvider;
