import { Dispatch } from 'react';

export enum NavigationActionType {
  APPEND_SECONDARY_ITEMS = 'APPEND_SECONDARY_ITEMS',
  CLEAR_SECONDARY_ITEMS = 'CLEAR_SECONDARY_ITEMS',
}

export type NavigationState = {
  secondaryMenuItems: SecondaryItem[];
};

export type NavigationAction = {
  type: NavigationActionType;
  payload?: {
    list?: SecondaryItem[];
  };
};

export type SecondaryItem = {
  name?: string;
  to?: string;
  view?: string;
  id?: string;
};

export type NavigationFunctions = {
  appendSecondaryItems: (list: any) => void;
  clearSecondaryItems: () => void;
};

export type NavigationContextProviderType = [NavigationState, Dispatch<NavigationAction>, NavigationFunctions];
