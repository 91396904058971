/**
 * @description
 * This is component for close button
 * all the functionalities cthings require for close button
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseImage } from '../../assets/Close.svg';
import { colorFetch } from '../../styles/utils';

type ButtonProps = {
  width?: string;
  height?: string;
  strokeWidth?: string;
  color?: string;
  hoverColor?: string;
  margin?: string;
};

const Button = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  background-color: transparent;

  border: none;

  outline: none;
  cursor: pointer;

  margin: ${({ margin }) => margin};

  & > svg {
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || 'auto'};

    stroke-width: ${({ strokeWidth }) => strokeWidth || 'auto'};
  }

  & > svg > path {
    stroke: ${({ color, theme }) => color || colorFetch('gray1')({ theme })};
    transition: stroke 0.3s ease;
  }

  &:hover > svg > path {
    stroke: ${({ hoverColor, theme }) => hoverColor || colorFetch('gray2')({ theme })};
  }
`;

export interface CloseButtonProps {
  onClick: any;

  width?: string;
  height?: string;
  strokeWidth?: string;
  color?: string;
  hoverColor?: string;
  margin?: string;
  className?: string;
}

export const CloseButton: FC<CloseButtonProps> = ({
  width,
  height,
  strokeWidth,
  color,
  hoverColor,
  onClick,
  margin,
  ...props
}) => {
  return (
    <Button
      width={width}
      height={height}
      strokeWidth={strokeWidth}
      color={color}
      hoverColor={hoverColor}
      onClick={onClick}
      margin={margin}
      {...props}
    >
      <CloseImage />
    </Button>
  );
};
