import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../app/state/userSlice';
import { useMediaType, useStyleContext } from '../../../../../../styles/style.context';
import { media } from '../../../../../../styles/media';
import { colorFetch } from '../../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';
import { getRequiredDateFormat } from '../../../../../../utils/date/date-format';
import { SmallLoader } from '../../../../../../features/smallLoader/SmallLoader';

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  background: ${colorFetch('pureWhite')};
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  border-radius: 6px;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  ${media.narrowMonitor`
    grid-template-columns: repeat(3, 1fr);
  `}
  ${media.desktop`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

const TableItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colorFetch('pureWhite')};
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray3')};
  box-sizing: border-box;
  padding: 12px 12px;

  &:first-child {
    border-radius: 6px 0 0;
  }
  &:nth-child(4) {
    border-radius: 0 6px 0 0;
    border-right: none;
  }
  &:nth-child(5) {
    border-radius: 0 0 0 6px;
  }
  &:last-child {
    border-radius: 0 0 6px;
    border-right: none;
  }
  &:nth-child(n + 5) {
    border-bottom: none;
  }

  ${media.narrowMonitor`
    &:nth-child(3) {
      border-radius: 0 6px 0 0;
      border-right:  none;
      border-color: ${colorFetch('gray3')};
     }

    &:nth-child(4) {
      border-radius: 0 0 0 6px;
      border-bottom: none;
      border-right: 1px solid;
      border-color: ${colorFetch('gray3')};
     }
  `}

  ${media.desktop`
    &:nth-child(2) {
      border-radius: 0 6px 0 0;
      border-right: none;
    }
    &:nth-child(3) {
      border-radius: 0 0 0 6px;
      border-right: 1px solid;
      border-bottom: none;
      border-color: ${colorFetch('gray3')};
    }
    &:last-child {
      border-radius: 0 0 6px;
      border-right: none;
    }
  `}
`;

interface DataType {
  name: string;
  value: string;
  color: null | string;
}

export interface TableOrderInfoProps {
  data: DataType[];
  packageList: any;
}

export const TableOrderInfo: FC<TableOrderInfoProps> = ({ data, packageList }) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const [{ mediaType }] = useStyleContext();
  const theme = useTheme();
  const { gray1, blue2 } = theme.colors;
  const { narrowMonitor, desktop } = useMediaType();

  const [dataTable, setDataTable] = useState(data);

  useEffect(() => {
    setDataTable(data);
  }, [data]);

  const reducedArr = dataTable.slice(2).filter((el) => {
    return narrowMonitor && !desktop
      ? el.name !== languageStrings.labelCompletion && el.name !== languageStrings.labelCreatedBy
      : desktop
      ? el.name !== languageStrings.labelCompletion &&
        el.name !== languageStrings.labelOrderCreatedBy &&
        el.name !== languageStrings.labelCustomer &&
        el.name !== languageStrings.orderType
      : el;
  });

  const orderInfoArray = mediaType?.monitorS ? reducedArr.slice(1) : reducedArr;

  const deadlineLabel = !mediaType?.monitorS ? languageStrings.labelShippingDeadline : languageStrings.labelDeadline;

  enum typeTableItem {
    LABEL_CREATED_AT = languageStrings.labelCreatedAt,
    LABEL_SHIPPING_DEADLINE = languageStrings.labelShippingDeadline,
    LABEL_CUSTOMER = languageStrings.labelCustomer,
    LABEL_COMPLETION = languageStrings.labelCompletion,
    LABEL_PACKAGES = languageStrings.labelPackages,
    LABEL_COMPONENTS = languageStrings.labelComponents,
    LABEL_STATUS = languageStrings.labelStatus,
    LABEL_CREATED_BY = languageStrings.labelOrderCreatedBy,
  }

  const getDataForOrderItem = (keyItemTable: string, packageList: number, el: any) => {
    const typeName = {
      [typeTableItem.LABEL_CREATED_AT]: ((el: any) => getRequiredDateFormat(el.value, 'DD.MM.YYYY, HH:mm'))(el),
      [typeTableItem.LABEL_SHIPPING_DEADLINE]: ((el: any) => getRequiredDateFormat(el.value, 'DD.MM.YYYY'))(el),
      [typeTableItem.LABEL_CUSTOMER]: ((el: any) => (el.value ? el.value : 'N/A'))(el),
      [typeTableItem.LABEL_COMPLETION]: ((el: any) => `${el.value ? `${el.value}%` : 'N/A'}`)(el),
      [typeTableItem.LABEL_PACKAGES]: ((el: any) => `${packageList}`)(el),
      [typeTableItem.LABEL_COMPONENTS]: ((el: any) => (el.value ? el.value : 'N/A'))(el),
      [typeTableItem.LABEL_STATUS]: ((el: any) => (el.value ? el.value : 'N/A'))(el),
      [typeTableItem.LABEL_CREATED_BY]: ((el: any) => (el.value ? el.value : 'N/A'))(el),
    };

    const valueTableItem = el.value ? '' : 'N/A';

    return typeName[keyItemTable as any] ? typeName[keyItemTable as any] : valueTableItem;
  };

  return (
    <Wrapper>
      {orderInfoArray.map((el: DataType) => {
        return (
          <TableItem key={el?.name}>
            <Text weight={'400'} lineHeight={'100%'} type={TextType.TEXT_14_BLACK}>
              {el.name === languageStrings.labelShippingDeadline ? deadlineLabel : el.name + ':'}
            </Text>
            <Text
              lineHeight={'100%'}
              weight={'500'}
              color={el.name === languageStrings.labelCompletion ? blue2 : gray1}
              type={TextType.TEXT_14_BLACK}
            >
              {getDataForOrderItem(el?.name, packageList, el)}
            </Text>
          </TableItem>
        );
      })}
    </Wrapper>
  );
};
