/**
 * @description
 * common use utility for validation text fields
 *
 */

export enum FieldsType {
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  CUSTOM = 'CUSTOM',
}

export const isStringConsistOfSpaces = (str: string) => {
  const regex = /^\s+$/;
  return regex.test(str);
};

export const onlyPositiveNumber = /^[1-9]+[0-9]*$/;

export const isValidField = (value: string, type?: FieldsType, condition?: RegExp) => {
  if (type === FieldsType.CUSTOM) {
    if (value && condition && !(condition === ('not valid' as any))) {
      const valueForTest = typeof value === 'object' ? (Object.values(value)[0] as string) : value;
      return condition.test(valueForTest);
    } else if (condition === ('not valid' as any)) {
      return true;
    } else return value && value.length > 0;
  } else if (value && type === FieldsType.EMAIL) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
  } else if (value && type === FieldsType.PHONE_NUMBER) {
    const re = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/im;
    return re.test(value);
  } else if (type === FieldsType.NUMBER) {
    if (condition) {
      return condition.test(value);
    } else {
      const re = /^\d+$/;
      return re.test(value);
    }
  } else if (type === FieldsType.SELECT) {
    if (condition === ('not valid' as any)) {
      return true;
    } else {
      return value && value.length > 0;
    }
  } else if (FieldsType.TEXT) {
    if (condition === ('not valid' as RegExp | undefined | string)) {
      return true;
    } else {
      const re = /^\s+$/;
      return value && value.length > 0 && !re.test(value);
    }
  }
};
