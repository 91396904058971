import { useMutation, useQuery } from 'react-query';
import { useEffect, useRef, useState } from 'react';
import { ApiCallType, getHttpService } from '../../utils/http';
import {
  generateLoaderOptions,
  triggerPlaceholderEmpty,
  useNewLoaderOptions,
} from '../placeholderComponent/loaderFunctions';
import { fakeApiExtend } from './fakeApiEtxendFunctions';
import {
  ApiQueryOptions,
  ApiMutationOptions,
  ApiHookBuilderOptions,
  ApiCallBuilderOptions,
  PreparedApiCallOptions,
  ApiCallOptions,
  ApiGenerateOptions,
  AnyObject,
} from './types';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../app/state/userSlice';

const callApi = async ({
  url,
  loaderOptions,
  body,
  callType,
  transformResponse,
  fakeApiExtender,
  handleSuccess,
}: ApiCallOptions) => {
  const httpServiceCall = getHttpService({ callType, url, body, loaderOptions });

  const prepareData = (data: any) => {
    const transformedData = transformResponse ? transformResponse(data) : data;
    const preparedData = fakeApiExtender ? fakeApiExtend(fakeApiExtender, transformedData) : transformedData;

    return preparedData;
  };

  let response = (await httpServiceCall()).data;
  const preparedData = prepareData(response);
  handleSuccess && handleSuccess(preparedData);

  return preparedData;
};

const usePreparedQuery = ({
  url,
  keywords,
  triggers,
  loaderOptions,
  callType,
  transformResponse,
  fakeApiExtender,
  condition,
  handleSuccess,
  handleError,
  body,
}: ApiHookBuilderOptions & PreparedApiCallOptions) => {
  const { isLoading } = useQuery(
    [...keywords, ...triggers],
    async () =>
      await callApi({
        url,
        loaderOptions,
        body,
        callType,

        transformResponse,
        fakeApiExtender,
      }),
    {
      onSuccess: handleSuccess,
      onError: handleError,
      enabled: condition,
      refetchOnWindowFocus: false,
    },
  );

  return { isLoading };
};

const usePreparedMutation = ({
  url,
  loaderOptions,
  callType,
  transformResponse,
  fakeApiExtender,
  handleSuccess,
  handleError,
}: ApiHookBuilderOptions & PreparedApiCallOptions) => {
  const { mutate } = useMutation(
    async (body: AnyObject) =>
      await callApi({
        url,
        loaderOptions,
        callType,
        transformResponse,
        fakeApiExtender,
        body,
      }),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    },
  );

  return [mutate];
};

const buildApiCall = ({ url, loaderOptions, callType, transformResponse, fakeApiExtender }: ApiCallBuilderOptions) => (
  params: PreparedApiCallOptions = {},
) => {
  const { handleSuccess, handleError, body } = params;

  callApi({
    url,
    loaderOptions,
    body,
    callType,
    handleSuccess,
    // @TODO refactor needed, because handle error in build api call is not working rn
    handleError,
    transformResponse,
    fakeApiExtender,
  });
};

export const useApi = ({
  // use 'value' if you want localData to be outside controlled (good if needed to pair with REDUX)
  value,
  // use 'defaultValue' if you want localData to be uncontrolled
  // ('value' still has higher priority and setting both 'defaultValue' and 'value' will result in 'value' usage)
  defaultValue,
  // use transformResponse function if you need to modify
  // request payload before setting it to local state
  transformResponse,
  url,
  callType = ApiCallType.GET,
  triggers: rawTriggers,
  keywords,
  fakeApiExtender,
  handleError,
  handleSuccess,
  body,
  condition = true,
}: ApiQueryOptions) => {
  const [localData, setLocalData] = useState(value || defaultValue);

  const language = useSelector(selectLanguage);
  const triggers = [...(rawTriggers || []), url, condition, language];
  const loaderOptions = useNewLoaderOptions(keywords, [...triggers]);

  const { current: isControlled } = useRef(!!value);

  useEffect(() => {
    if (isControlled) {
      setLocalData(value);
    }
  }, [value, isControlled]);

  const { isLoading } = usePreparedQuery({
    url,
    keywords,
    triggers,
    loaderOptions,
    callType,
    transformResponse,
    fakeApiExtender,
    condition,
    handleError,
    handleSuccess: (data) => {
      setLocalData(data);
      handleSuccess && handleSuccess(data);
    },
    body,
  });

  return [localData, setLocalData, isLoading] as const;
};

export const useApiMutation = ({
  // use transformResponse function if you need to modify
  // request payload before setting it to local state
  transformResponse,
  url,
  callType = ApiCallType.POST,
  triggers: rawTriggers,
  keywords,
  fakeApiExtender,
  condition = true,
  handleError,
  handleSuccess,
}: ApiMutationOptions) => {
  const language = useSelector(selectLanguage);
  const triggers = [...(rawTriggers || []), url, condition, language];

  const loaderOptions = useNewLoaderOptions(keywords, [...triggers]);

  const [mutation] = usePreparedMutation({
    url,
    keywords,
    triggers,
    loaderOptions,
    callType,
    transformResponse,
    fakeApiExtender,
    condition,
    handleError,
    handleSuccess,
  });

  return [mutation] as const;
};

export const generateApi = ({
  // use transformResponse function if you need to modify
  // request payload before setting it to local state
  transformResponse,
  url,
  callType = ApiCallType.GET,
  setPlaceholder,
  stopPlaceholder,
  fakeApiExtender,
}: ApiGenerateOptions) => {
  const loaderOptions = generateLoaderOptions(
    setPlaceholder || triggerPlaceholderEmpty,
    stopPlaceholder || triggerPlaceholderEmpty,
  );
  return buildApiCall({ url, loaderOptions, callType, transformResponse, fakeApiExtender, keywords: [] });
};
