import styled from 'styled-components';
import { media } from '../../styles/media';
import { Text } from '@bit/first-scope.text';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';

type ImageProps = {
  src: string;
  ratio: number;
};

export const Image = styled.img<ImageProps>`
  display: ${({ src }) => (!!src ? 'block' : 'none')};
  height: ${({ ratio }) => (ratio <= 1 ? '100%' : '')};
  width: ${({ ratio }) => (ratio <= 1 ? '' : '100%')};
`;

type ImageContainerProps = {
  ratio: number;
};

export const ImageContainer = styled.div<ImageContainerProps>`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > .droptarget {
    height: ${({ ratio }) => (ratio <= 1 ? '100%' : '')};
    width: ${({ ratio }) => (ratio <= 1 ? '' : '100%')};
  }
`;

export const ImageOuterContainer = styled.div`
  height: 100%;
  width: 100%;
`;

type MapInteractionProps = {
  height: string;
};

export const MapInteraction = styled.div<MapInteractionProps>`
  height: ${({ height }) => height};
  width: 100%;
  box-sizing: border-box;
  background-color: ${colorFetch('gray4')};
  position: relative;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: 8px;
  padding: 5px;
  & > div > div > div {
    width: 100%;
    height: 100%;
    cursor: default;
  }
  ${media.tablet`
    border: none;
    border-radius: 0;
  `}
`;

type ChooseZoneModalWrapperProps = {
  height: string;
};

export const ChooseZoneModalWrapper = styled.div<ChooseZoneModalWrapperProps>`
  max-width: 250px;
  width: 100%;
  height: ${({ height }) => height};
  box-sizing: border-box;
  background-color: ${colorFetch('gray4')};
  position: relative;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: 8px;
  padding: 5px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${media.tablet`
    display: none;
  `}
`;

export const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ZoomToolsContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 15px;
  display: flex;
  flex-direction: column;
  z-index: 99;
`;

export const ToolsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 0;
  background-color: ${colorFetch('pureWhite')};
  outline: transparent;
  width: 32px;
  height: 32px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 3px 6px rgba(96, 97, 112, 0.16);
  border: none;
  border-radius: ${borderRadiusFetch('additional6')};
  cursor: pointer;
  transition: all 0.3s linear;
  &:nth-child(1) {
    border-bottom: 1px solid;
    border-color: ${colorFetch('gray4')};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:nth-child(2) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:nth-child(3) {
    margin-top: 6px;
  }
  &:hover {
    background-color: ${colorFetch('gray6')};
  }
`;

export const MapLegendLeft = styled.div`
  position: absolute;
  bottom: 0px;
  left: 15px;
  display: flex;
  align-items: center;
  z-index: 99;
  ${media.phone`
    display: none;
  `}
`;

export const MapLegendRight = styled.div`
  position: absolute;
  bottom: 0px;
  right: 75px;
  display: flex;
  align-items: center;
  z-index: 99;
  ${media.desktop`
    display: none;
  `}
  ${media.tablet`
    display: none;
  `}
  ${media.phone`
    display: none;
  `}
`;

type PointWrapperProps = {
  isActive: boolean;
  transform: string;
  top: string;
  left: string;
  ghostTransform: string;
};

export const PointWrapper = styled.div<PointWrapperProps>`
  width: ${({ isActive }) => (isActive ? '20px' : '15px')};
  height: ${({ isActive }) => (isActive ? '20px' : '15px')};
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({ transform }) => transform};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  & > div > .ddcontainerghost {
    transform: ${({ ghostTransform }) => ghostTransform};
  }
`;

type AreaProps = {
  isActive: boolean;
  backgroundColor: string;
  width: string;
  height: string;
  borderColor: string;
  top: string;
  left: string;
};

export const Area = styled.div<AreaProps>`
  border: ${({ isActive }) => (isActive ? '2px solid' : '1px solid')};
  z-index: 1;
  pointer-events: none;
  position: absolute;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-color: ${({ borderColor }) => borderColor};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
`;

type ZoneProps = {
  isActive: boolean;
};

export const Zone = styled.div<ZoneProps>`
  position: relative;
  width: ${({ isActive }) => (isActive ? '14px' : '10px')};
  height: ${({ isActive }) => (isActive ? '14px' : '10px')};
`;

type PointProps = {
  isActive: boolean;
  backgroundColor: string;
};

export const Point = styled.div<PointProps>`
  width: ${({ isActive }) => (isActive ? '14px' : '10px')};
  height: ${({ isActive }) => (isActive ? '14px' : '10px')};
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

// export const NameOfZone = styled.p`
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 13px;
//   line-height: 20px;
//   margin: 0;
//   color: ${(props: any) => props.color};
//   width: max-content;
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
// `;

export const NameOfZone = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  color: ${({ color }) => color};
  width: max-content;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const DragImage = styled.div`
  height: 32px;
  width: 32px;
  background: ${colorFetch('pureWhite')};
  box-shadow: 0px 0px 1.68421px rgba(40, 41, 61, 0.04), 0px 3px 6px rgba(96, 97, 112, 0.16);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const OuterCircle = styled.div`
  border-radius: 50%;
  width: 16px !important;
  height: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  border-color: ${colorFetch('primary')};
`;

export const InnerCircle = styled.div`
  border-radius: 50%;
  width: 4px !important;
  height: 4px !important;

  border: 2px solid;
  border-color: ${colorFetch('primary')};
`;

export const DragContainer = styled.div`
  position: absolute;
  left: 16px;
  bottom: 16px;
  display: flex;
  align-items: center;
`;

export const DragText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin-left: 8px;
  color: ${colorFetch('gray1')};
`;

export const StyledText = styled(Text)`
  text-align: center;
  color: ${colorFetch('gray1')};
  line-height: 22px;
  font-size: 15px;
`;

export const StyledSecondText = styled(Text)`
  font-size: 13px;
`;
