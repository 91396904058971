import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { useSelector } from 'react-redux';
import { media } from '../../../../../../styles/media';
import { CommonButton } from '@bit/first-scope.common-button';
import { Text, TextType } from '@bit/first-scope.text';
import { selectLanguageStrings } from '../../../../../../app/state/userSlice';

export const ExtraWrapper = styled.div`
  width: 250px;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 323px);
  margin-top: 15px;
  margin-right: 10px;
  overflow: hidden;
  border: 1px solid ${({ borderColor }: any) => borderColor};
  background-color: ${({ backgroundColor }: any) => backgroundColor};
  border-radius: ${({ borderRadius }: any) => borderRadius};
  box-sizing: border-box;
`;

const Button = styled(CommonButton)`
  width: 116px;
  margin-top: 30px;
  ${media.tablet`
      width: 100%;  
      height: 40px; 
      pointer-events: ${(props: any) => props.isBlocked && 'none'};
  `};
  ${media.phone`
    width: 100%;
  `}
`;

const WrapperButtonText = styled.div`
  width: 185px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextDescription = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  text-align: center;
  margin-top: 5px;
`;

export const EmptyPackageList = ({ handleClearItems }: { handleClearItems: any }) => {
  const theme = useTheme();
  const language = useSelector(selectLanguageStrings);

  const { gray1, gray3, white } = theme.colors;
  const { additional6 } = theme.borderRadius;

  return (
    <ExtraWrapper borderColor={gray3} backgroundColor={white} borderRadius={additional6}>
      <WrapperButtonText>
        <WrapperText>
          <Text type={TextType.TEXT_14_BLACK}>{language ? language.collectionOrderNoMatches : 'No matches'}</Text>
          <TextDescription type={TextType.TEXT_12_GRAY}>
            {language ? language.collectionOrderTryChanging : ' Try changing or removing some of your filters '}
          </TextDescription>
        </WrapperText>
        <Button
          backgroundColor={white}
          backgroundColorHover={white}
          borderColor={gray3}
          borderColorHover={gray3}
          onClick={handleClearItems}
          color={gray1}
          hoverColor={gray1}
        >
          {language ? language.collectionOrderClearFilters : 'Clear filters'}
        </Button>
      </WrapperButtonText>
    </ExtraWrapper>
  );
};
