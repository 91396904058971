/**
 * @description
 * This is component for visualisation Tracker in floorPlan popupModal.
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@bit/first-scope.common-button';
import { View } from '../../../../../../routes/routeInterfaces';
import { useCustomHistory } from '../../../../../../utils/react-router-dom-abstraction';
import { ContentType } from '../../../../../../components/insightBadges/InsightBadges';
import { borderRadiusFetch, colorFetch } from '../../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

type WrapperProps = {
  isFullInfoView: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: ${({ isFullInfoView }) => (isFullInfoView ? '360px' : '290px')};
  height: 100%;
  box-sizing: border-box;
  padding: ${({ isFullInfoView }) => (isFullInfoView ? '12px 18px' : '12px 18px 8px 18px')};
  transition: width 0.3s ease;
`;

type FieldsWrapperProps = {
  isWithBorder: boolean;
  isHidden: boolean;
};

const FieldsWrapper = styled.div<FieldsWrapperProps>`
  width: 100%;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  flex-direction: column;
  border-bottom: ${({ isWithBorder }) => (isWithBorder ? '1px solid' : 'none')};
  padding: ${({ isWithBorder }) => (isWithBorder ? '0 0 5px 0' : '0')};
  margin: ${({ isWithBorder }) => (isWithBorder ? '0 0 5px 0' : '0')};
  opacity: ${({ isHidden }) => (isHidden ? '0' : '1')};
  transition: opacity 0.3s ease-out;
  border-color: ${colorFetch('gray4')};
`;

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const Button = styled(CommonButton)`
  font-size: 14px;
`;

const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${colorFetch('gray6')};
  padding: 4px 0;
  border-radius: 0 0 ${borderRadiusFetch('primary')} ${borderRadiusFetch('primary')} !important;
  border-top: 1px solid;
  border-color: ${colorFetch('gray4')};
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover {
    background-color: ${colorFetch('gray6')};
    & > span {
      color: ${colorFetch('gray1')};
    }
  }
`;

type TransitionWrapperProps = {
  withTransition: boolean;
  isFullInfoView: boolean;
  width: string;
};

const TransitionWrapper = styled.div<TransitionWrapperProps>`
  width: ${({ withTransition, isFullInfoView, width }) =>
    withTransition ? (isFullInfoView ? width : '0px') : 'max-content'};
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  transition: width 0.5s linear;
`;

const StyledText = styled(Text)`
  white-space: nowrap;
`;

const StyledTextOverflow = styled(Text)`
  max-width: 88px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Tracker = ({
  popupInfo,
  infoArr,
  roleName,
  languageStrings,
}: {
  popupInfo: any;
  infoArr: any;
  roleName: string;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { pureWhite, black1, gray1, primary } = theme.colors;

  const [isFullInfoView, setIsFullInfoView] = useState(false);
  const customHistory = useCustomHistory();

  const { name, order_id, package_id, inventory_id } = popupInfo.content;

  // const findLongestStringInArr = (arr: any[]) => {
  //   let long1 = arr[0];
  //   for (let i = 0; i < arr.length; i++) {
  //     if (arr[i].length > long1.length) {
  //       long1 = arr[i];
  //     }
  //   }
  //   return long1.length;
  // };

  // const theLongestValue = findLongestStringInArr([
  //   name ?? 'N/A',
  //   packageName ?? 'N/A',
  //   order_name ?? 'N/A',
  //   deviceId ?? 'N/A',
  //   lastUpdate ? getRequiredDateFormat(lastUpdate, 'DD.MM.YYYY, HH:mm') : 'N/A',
  //   component_index ?? 'N/A',
  //   production_id ?? 'N/A',
  //   inventoryName ?? 'N/A',
  //   inventoryType ?? 'N/A',
  //   inventory_id ?? 'N/A',
  // ]);

  // const maxWidth = `${theLongestValue * 7 + (108 + 16 + 36)}px`;

  const handleOnClickDetailsButton = () => {
    if (isFullInfoView) {
      setIsFullInfoView(false);
    } else {
      setIsFullInfoView(true);
    }
  };

  const newInfoArr = infoArr ? Object.values(infoArr) : [];

  return (
    <>
      <Wrapper isFullInfoView={isFullInfoView}>
        <Text type={TextType.TEXT_14_BLACK} color={black1} weight={'600'} margin={'0 0 8px 0'}>
          {name}
        </Text>
        {newInfoArr?.map((item: any) => {
          return item?.map((elem: any, index: number) => {
            return (
              <FieldsWrapper
                isWithBorder={(isFullInfoView && roleName === ContentType.TAG_PACKAGE && index <= 1) || index === 0}
                isHidden={!isFullInfoView && index > 1}
              >
                {elem.values.map((val: any) => {
                  return (
                    <ItemWrapper>
                      <StyledText
                        type={TextType.TEXT_12_BLACK}
                        weight={'400'}
                        color={elem.blurred ? gray1 : black1}
                        lineHeight={'24px'}
                        margin={'0 16px 0 0'}
                      >
                        {val.label}
                      </StyledText>
                      <TransitionWrapper
                        withTransition={index === 2}
                        width={`${val.value.toString().length * 9}px`}
                        isFullInfoView={isFullInfoView}
                      >
                        {val.label && val.value.length > 13 && !isFullInfoView ? (
                          <StyledTextOverflow
                            type={TextType.TEXT_12_BLACK}
                            weight={'500'}
                            color={elem.elem ? gray1 : black1}
                            lineHeight={'24px'}
                          >
                            {val.value}
                          </StyledTextOverflow>
                        ) : (
                          <StyledText
                            type={TextType.TEXT_12_BLACK}
                            weight={'500'}
                            color={elem.elem ? gray1 : black1}
                            lineHeight={'24px'}
                          >
                            {val.value}
                          </StyledText>
                        )}
                      </TransitionWrapper>
                    </ItemWrapper>
                  );
                })}
              </FieldsWrapper>
            );
          });
        })}
        {isFullInfoView && (
          <ButtonsWrapper>
            <Button
              width={'calc(50% - 8px)'}
              height={'26px'}
              color={black1}
              backgroundColor={pureWhite}
              backgroundColorHover={primary}
              borderColorHover={primary}
              onClick={() => {
                const to = popupInfo?.content?.package ? View.MANAGE_ORDERS_DETAILS : View.MANAGE_INVENTORIES;
                popupInfo?.content?.package
                  ? to &&
                    customHistory.push(to, {
                      state: { package_id },
                      pathParams: { id: order_id, componentsOffset: 0, packagesOffset: 0, eventsOffset: 0 },
                    })
                  : to &&
                    customHistory.push(to, {
                      state: { id: inventory_id },
                      pathParams: { offset: 0 },
                    });
              }}
            >
              {roleName === ContentType.TAG_PACKAGE
                ? languageStrings
                  ? languageStrings.packageButton
                  : 'Package'
                : languageStrings
                ? languageStrings.inventoriesButton
                : 'Inventory'}
            </Button>

            <Button
              width={'calc(50% - 8px)'}
              height={'26px'}
              onClick={() => {
                const { id, buildingId } = popupInfo.content;
                const to =
                  roleName !== ContentType.UNKNOWN
                    ? View.USER_MANAGE_DEVICES_DETAILS_TRACKER
                    : View.USER_MANAGE_DEVICES_DETAILS_NODE;
                to &&
                  customHistory.push(to, {
                    pathParams: { id: id, buildingId: buildingId, offset: 0 },
                  });
              }}
            >
              {languageStrings ? languageStrings.labelDevice : 'Device'}
            </Button>
          </ButtonsWrapper>
        )}
      </Wrapper>
      <DetailsWrapper theme={theme} onClick={handleOnClickDetailsButton}>
        <Text type={TextType.TEXT_12_GRAY} lineHeight={'24px'}>
          {isFullInfoView
            ? languageStrings
              ? languageStrings.lessDetailsButton
              : 'Less details'
            : languageStrings
            ? languageStrings.moreDetailsButton
            : 'More details'}
        </Text>
      </DetailsWrapper>
    </>
  );
};
