import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { UsersTableState } from './usersTableInterfaces';
import { NEW_ID } from '../../consts';

const initialState: UsersTableState = {
  list: [],
  isSelectAll: false,
  isEditInProcess: false,
  pagination: {
    total_items: 0,
    total_pages: 1,
  },
};

export const usersTableSlice = createSlice({
  name: 'usersTable',
  initialState,
  reducers: {
    setUsersTable: (state, action: PayloadAction<any>) => {
      state.list = action.payload.list;
      state.pagination = action.payload.pagination;
    },
    editManageRow: (state, action: PayloadAction<any>) => {
      state.list[action.payload].isEdit = !state.list[action.payload].isEdit;
    },
    deleteUser: (state, action: PayloadAction<any>) => {
      const id = action.payload.id;
      state.list = state.list.filter((elem: any) => elem.id !== id);
      state.pagination.total_items -= 1;
    },
    deleteCheckedUsers: (state) => {
      const oldListLength = state.list.length;
      state.list = state.list.filter((item: any) => !item.isChecked);
      const newListLength = state.list.length;
      const difference = oldListLength - newListLength;
      state.pagination.total_items -= difference;
    },
    toggleIsChecked: (state, action: PayloadAction<any>) => {
      state.list[action.payload.index].isChecked = !state.list[action.payload.index].isChecked;
    },
    toggleIsCheckedAll: (state) => {
      state.list.forEach((item: any, index: number) => {
        const { isChecked } = item;
        if (!isChecked && !state.isSelectAll) {
          state.list[index].isChecked = true;
        } else if (isChecked === true && state.isSelectAll === true) {
          state.list[index].isChecked = false;
        }
      });
      state.isSelectAll = !state.isSelectAll;
    },
    toggleIsEditInProcessUser: (state) => {
      state.isEditInProcess = !state.isEditInProcess;
    },
    addUser: (state) => {
      state.list.unshift({
        id: NEW_ID,
        name: '',
        email: 'user@example.com',
        phone: '',
        client_id: '',
        role: 0,
        client_name: '',
        role_name: '',
        role_description: '',
        isChecked: false,
        isEdit: true,
      });
    },
    increaseTotalItems: (state) => {
      state.pagination.total_items += 1;
    },
    setField: (state, action: PayloadAction<any>) => {
      state.list[action.payload.index][action.payload.fieldName] = action.payload.value;
    },
    updateUserLocal: (state, action: PayloadAction<any>) => {
      state.list[action.payload.index] = action.payload.value;
    },
  },
});

export const {
  setUsersTable,
  editManageRow,
  deleteUser,
  deleteCheckedUsers,
  toggleIsChecked,
  toggleIsCheckedAll,
  toggleIsEditInProcessUser,
  addUser,
  increaseTotalItems,
  setField,
  updateUserLocal,
} = usersTableSlice.actions;

export const selectUsersTable = (state: RootState) => ({
  list: state.usersTable.list,
  pagination: state.usersTable.pagination,
});

export const selectIsSelectAll = (state: RootState) => {
  return state.usersTable.isSelectAll;
};

export const selectIsEditInProcess = (state: RootState) => {
  return state.usersTable.isEditInProcess;
};

export default usersTableSlice.reducer;
