import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { TextType, Text } from '@bit/first-scope.text';
import { CommonButton } from '@bit/first-scope.common-button';
import { RowItemType } from '../../../../../../../../features/universalTable/types';
import { Select } from '../../../../../../../../components/select/Select';
import { InputModal } from '../../../../../../../../components/inputModal/InputModal';
import { useGetComponents } from '../../../../../../../../api/manageOrders';
import { FieldsType, isValidField, onlyPositiveNumber } from '../../../../../../../../utils/validation';
import { colorFetch } from '../../../../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const StyledModal = styled(Modal)`
  align-items: flex-end;
  & > div {
    width: 100%;
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  box-sizing: border-box;
  align-items: flex-start;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const Title = styled(Text)`
  font-size: 18px;
`;

const Container = styled.div`
  width: 100%;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin: 0;
  margin-bottom: 12px;
  &.red {
    & > div {
      & > span {
        color: ${colorFetch('red3')} !important;
        -webkit-text-fill-color: ${colorFetch('red3')};
      }
    }
  }
`;

const StyledInput = styled(InputModal)`
  width: 100%;
  margin: 0;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 24px;
  }
  &::placeholder {
    color: ${colorFetch('gray2')};
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
  }
  &.red {
    & > div {
      & > input {
        color: ${colorFetch('red3')} !important;
        &.disabled {
          color: ${colorFetch('red3')} !important;
          &::placeholder {
            -webkit-text-fill-color: ${colorFetch('red3')};
          }
        }
        &::placeholder {
          color: ${colorFetch('red3')} !important;
        }
      }
    }
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Button = styled(CommonButton)`
  width: 100%;
  opacity: ${({ disabled }) => (disabled ? '0.1' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  margin-bottom: 10px;
  padding: 11.5px 0;
  &:last-child {
    margin-bottom: 0;
  }
  &.save {
    background-color: ${colorFetch('primary')};
    border-color: ${colorFetch('primary')};
    color: ${colorFetch('pureWhite')};
  }
  &.cancel {
    background-color: ${colorFetch('pureWhite')};
    border-color: ${colorFetch('primary')};
    color: ${colorFetch('black1')};
  }
`;

interface AddModalProps {
  isOpen: boolean;
  setClose: (val: boolean) => void;
  isEditingInProcess: boolean;
  setIsEditingInProcess: (val: boolean) => void;
  setComponents: any;
  components: any[];
  setIsAllFieldsValid: (value: boolean) => void;
  componentsWasTouched: boolean;
  setComponentsWasTouched: (value: boolean) => void;
  quantityWasTouched: boolean;
  setQuantityWasTouched: (value: boolean) => void;
  setIsComponentFieldsValid: (value: boolean) => void;
  languageStrings?: any;
}

export const AddModal: FC<AddModalProps> = ({
  isOpen,
  setClose,
  isEditingInProcess,
  setIsEditingInProcess,
  setComponents,
  components,
  setIsAllFieldsValid,
  componentsWasTouched,
  setComponentsWasTouched,
  quantityWasTouched,
  setQuantityWasTouched,
  setIsComponentFieldsValid,
  languageStrings,
}) => {
  const theme = useTheme();

  const { black1 } = theme.colors;

  const componentList = useGetComponents();

  useEffect(() => {
    setComponents((components: any) =>
      components.map((component: any, index: number) => {
        return { ...component, components_list: componentList };
      }),
    );
  }, [componentList, isOpen]);

  const mapComponents = (components: object[]) => {
    if (components) {
      return components.map((item: any) => {
        return { id: item.id, name: item.name, index: item.index };
      });
    }
  };

  const handleSelectComponent = (value: any) => {
    setComponentsWasTouched(true);
    setIsEditingInProcess(true);

    setComponents((components: any) =>
      components.map((component: any, index: number, arr: any) =>
        component.component_id === arr[arr.length - 1].component_id
          ? { ...component, component_value: value, index: value.index }
          : component,
      ),
    );
  };

  const setProductionIdHandle = (e: any) => {
    setQuantityWasTouched(true);
    setIsEditingInProcess(true);

    const newProductionId = e.currentTarget.value;
    setComponents((components: any) =>
      components.map((component: any, index: number, arr: any) =>
        component.component_id === arr[arr.length - 1].component_id
          ? { ...component, productionId: newProductionId }
          : component,
      ),
    );
  };

  const setQuantityHandle = (e: any) => {
    setQuantityWasTouched(true);
    setIsEditingInProcess(true);

    const newQuantity = e.currentTarget.value;
    setComponents((components: any) =>
      components.map((component: any, index: number, arr: any) =>
        component.component_id === arr[arr.length - 1].component_id
          ? { ...component, quantity: newQuantity }
          : component,
      ),
    );
  };

  const [componentField, setComponentField] = useState<any>([]);

  useEffect(() => {
    setComponentField([
      {
        name: languageStrings.manualAddOrderComponentsName ?? 'Name',
        key: 'name',
        placeholder: languageStrings?.manualAddOrderComponentsNamePlaceholder || 'Choose name',
        values: mapComponents(components[components.length - 1]?.components_list || []),
        value: components[components.length - 1]?.component_value || '',
        type: RowItemType.JOINED_SELECT,
        onChange: handleSelectComponent,
        isFieldEmpty: !isValidField(components[components.length - 1]?.component_value.name, FieldsType.SELECT),
      },
      {
        name: languageStrings.manualAddOrderComponentsIndex ?? 'Index',
        key: 'index',
        placeholder: languageStrings?.manualAddOrderComponentsIndexPlaceholder || 'First choose name',
        value: components[components.length - 1]?.index || '',
        disabled: true,
        type: RowItemType.INPUT,
        isFieldEmpty: !isValidField(components[components.length - 1]?.index, FieldsType.TEXT),
      },
      {
        name: languageStrings.labelProductionID ?? 'Production ID',
        key: 'productionId',
        placeholder: languageStrings?.manualAddOrderComponentsProductionIDPlaceholder || ' Enter Production ID',
        value: components[components.length - 1]?.productionId || '',
        type: RowItemType.INPUT,
        onChange: setProductionIdHandle,
        isFieldEmpty: !isValidField(components[components.length - 1]?.productionId, FieldsType.TEXT),
      },
      {
        name: languageStrings.manualAddOrderComponentsQuantity ?? 'Quantity',
        key: 'quantity',
        placeholder: languageStrings?.manualAddOrderComponentsQuantityPlaceholder || 'Enter quantity',
        value: components[components.length - 1]?.quantity || null,
        type: RowItemType.INPUT_NUMBER,
        onChange: setQuantityHandle,
        isFieldEmpty: !isValidField(components[components.length - 1]?.quantity, FieldsType.NUMBER, onlyPositiveNumber),
      },
    ]);
  }, [components]);

  const isAllFieldsValid = !componentField.filter((item: any) => item.isFieldEmpty).length;

  useEffect(() => {
    setIsAllFieldsValid(isAllFieldsValid);
    setIsComponentFieldsValid(isAllFieldsValid);
  }, [isAllFieldsValid]);

  const handleCancelClick = () => {
    setComponents(components.slice(0, -1));
    setClose(false);
  };

  const handleSaveClick = () => isAllFieldsValid && setClose(false);

  const buttons = [
    {
      value: 'Save',
      onClick: handleSaveClick,
      className: 'save',
    },
    {
      value: 'Cancel',
      onClick: handleCancelClick,
      className: 'cancel',
    },
  ];

  return (
    <StyledModal isOpenModal={isOpen}>
      <StyledModalContainer>
        <Title type={TextType.TEXT_14_BLACK} color={black1} weight={'700'} margin={'0 0 20px 0'}>
          Add
        </Title>

        <Container>
          {componentField.length &&
            componentField.map((item: any, index: number) => {
              const {
                name,
                value,
                disabled,
                type,
                onChange,
                values,
                placeholder,
                isError,
                isPendingRequest,
                isFieldEmpty,
              } = item;

              if (type === RowItemType.JOINED_SELECT) {
                return (
                  <StyledSelect
                    className={componentsWasTouched && isEditingInProcess && isFieldEmpty && 'red'}
                    value={value}
                    label={name}
                    disabled={disabled}
                    placeholder={placeholder}
                    values={values}
                    onChange={(value: any) => onChange(value)}
                    isPendingRequest={isPendingRequest}
                    isShowSearch={false}
                  />
                );
              }
              if (type === RowItemType.INPUT || type === RowItemType.INPUT_NUMBER) {
                return (
                  <StyledInput
                    className={!disabled && quantityWasTouched && isEditingInProcess && isFieldEmpty && 'red'}
                    key={index}
                    name={name}
                    value={value}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={disabled ? () => {} : onChange}
                    type={type === RowItemType.INPUT ? 'text' : 'number'}
                  />
                );
              }
            })}
        </Container>
        <ButtonsWrapper>
          {buttons.map(({ value, onClick, className }: any) => (
            <Button onClick={onClick} className={className}>
              {value}
            </Button>
          ))}
        </ButtonsWrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
