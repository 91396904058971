/**
 * @description
 * This is component that is used for getting description in DescriptionPopup
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { useTheme } from '@cthings.co/styles';
import { borderRadiusFetch, colorFetch } from '../../../../../styles/utils';

const Container = styled.div`
  margin: 4px 0 0 0;
`;

type WrapperProps = {
  isEditingInProcess: boolean;
  isError?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  min-height: 95px;
  box-sizing: border-box;
  display: flex;
  border: 1px solid;
  border-color: ${({ isEditingInProcess, isError, theme }) =>
    isEditingInProcess
      ? colorFetch('primary')({ theme })
      : isError
      ? colorFetch('red1')({ theme })
      : colorFetch('gray3')({ theme })};
  border-radius: ${borderRadiusFetch('primary')};
  background-color: ${colorFetch('pureWhite')};
  color: ${colorFetch('gray1')};
  transition: all 0.3s linear;
`;

const TextField = styled(TextareaAutosize)`
  width: 100%;
  box-sizing: border-box;
  background-color: inherit;
  color: inherit;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  padding: 0 6px 0 6px;
  height: 95px !important;
  margin: 8px 6px;
  outline: none;
  resize: none;
  border: none;
  &::-webkit-scrollbar {
    width: 3px;
    height: 100%;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray2')};
    border-radius: ${borderRadiusFetch('primary')};
  }
`;

export const Textarea = ({
  value,
  onChange,
  isEdit,
  isError,
  ...props
}: {
  value: string;
  onChange: (value: string) => void;
  isEdit?: boolean;
  isError?: boolean;
  className?: string;
}) => {
  const language = useSelector(selectLanguageStrings);
  const [isEditingInProcess, setIsEditingInProcess] = useState(false);
  const theme = useTheme();
  const { red1 } = theme.colors;

  return (
    <Container {...props}>
      <Wrapper className={isEdit ? 'active' : 'disabled'} isEditingInProcess={isEditingInProcess} isError={isError}>
        <TextField
          value={value}
          onChange={(e: any) => {
            onChange(e.target.value);
          }}
          onFocus={() => {
            setIsEditingInProcess(true);
          }}
          onBlur={() => {
            setIsEditingInProcess(false);
          }}
          placeholder={language.enterDescriptionPlaceholder}
          rowsMax={3}
          spellCheck={false}
          disabled={!isEdit}
        />
      </Wrapper>
      {isError && (
        <Text type={TextType.TEXT_12_BLACK} margin={'4px 0 0 12px'} color={red1} weight={'400'}>
          {language ? language.textareaDescriptionErrorText : `Description doesn't look right`}
        </Text>
      )}
    </Container>
  );
};
