import React from 'react';
import { useFilters } from '../../../Content/filterContext/сontext';
import { getIsAllDeviceSelect, getIsNodes, getAllDeviceClear } from '../../../Content/filterContext/selectors';
import { ChooseParameterSelect } from '../../../../../../components/chooseParameterSelect/ChooseParameterSelect';
import { useQueryParams } from '../../../../../../utils/useQueryParams';

export enum typeDevice {
  NODE = 'NODE',
  TRACKER = 'TRACKER',
  OFFLINE = 'OFFLINE',
}

const defaultFilterState = {
  devices: ['nodes', 'tracker', 'offline'],
  attach: ['attached', 'detached'],
  object_type: ['packages', 'inventories'],
  zones: ['zone'],
};

const getStatusDeviceFilter = (queryDeviceParams: string[], typeDevice: string) =>
  queryDeviceParams?.length > 0 ? queryDeviceParams.includes(typeDevice) : false;

const filteredDeviceState = (deviceState: string[], type: string) => {
  return Array.isArray(deviceState) ? deviceState.filter((item) => item !== type) : [];
};

const addDeviceTypeToState = (deviceState: string[], type: string) => {
  const checkedDeviceState = Array.isArray(deviceState) ? [...deviceState, type] : [deviceState, type];
  return !!deviceState ? checkedDeviceState : [type];
};

const setQueryOneDevice = (type: typeDevice, setFilterParam: any, queryParams: any) => {
  const deviceState = queryParams?.devices;

  const stringTypeDevice = {
    [typeDevice.NODE]: 'nodes',
    [typeDevice.TRACKER]: 'tracker',
    [typeDevice.OFFLINE]: 'offline',
  };

  const setTypesDevice = {
    [typeDevice.NODE]: (isNode: boolean) =>
      setFilterParam(
        isNode
          ? { ...queryParams, devices: filteredDeviceState(deviceState, stringTypeDevice[type]) }
          : { ...queryParams, devices: addDeviceTypeToState(deviceState, stringTypeDevice[type]) },
      ),
    [typeDevice.TRACKER]: (isTracker: boolean) =>
      setFilterParam(
        isTracker
          ? { ...queryParams, devices: filteredDeviceState(deviceState, stringTypeDevice[type]) }
          : { ...queryParams, devices: addDeviceTypeToState(deviceState, stringTypeDevice[type]) },
      ),
    [typeDevice.OFFLINE]: (isOffline: boolean) =>
      setFilterParam(
        isOffline
          ? { ...queryParams, devices: filteredDeviceState(deviceState, stringTypeDevice[type]) }
          : { ...queryParams, devices: addDeviceTypeToState(deviceState, stringTypeDevice[type]) },
      ),
  };

  const statusTypeDevice = getStatusDeviceFilter(queryParams?.devices, stringTypeDevice[type]);

  return setTypesDevice[type](statusTypeDevice);
};

const FilterDevice = ({ languageStrings, setFilterParam }: any) => {
  const queryParams = useQueryParams() || {};
  const stateFilterDevices = queryParams?.devices;
  const { state, dispatch } = useFilters();
  const { deviceState } = state;
  const { checkedDevicesCount, isOpenDeviceList, devicesFilter } = deviceState;
  const isSelectAllDevice = getIsAllDeviceSelect(state);
  const isAllDeviceClear = getAllDeviceClear(state);
  const isNodes = getIsNodes(state);

  const handleSelectAllDevices = () => {
    dispatch({
      type: 'SELECT_ALL_DEVICE',
      payload: { isSelectAllDevice: isSelectAllDevice, isClearAll: false },
    });
    setFilterParam(!!stateFilterDevices && stateFilterDevices.length >= 1 ? {} : defaultFilterState);
  };

  const handleSelectOneDevices = (type: any) => {
    dispatch({ type: 'SELECT_ONE_DEVICE', payload: { type: type, isAllDeviceClear: isAllDeviceClear } });
    setQueryOneDevice(type, setFilterParam, queryParams);
  };

  const handleDeviceList = (state: boolean) => {
    dispatch({ type: 'HANDLE_DEVICE_LIST', payload: state });
  };

  return (
    <ChooseParameterSelect
      name={languageStrings ? languageStrings.labelDevices : 'Devices'}
      parameters={devicesFilter}
      //@ts-ignore
      handleCheckedItem={handleSelectOneDevices}
      handleSelectAll={handleSelectAllDevices}
      setIsOpenSelectList={handleDeviceList}
      isSelectAll={isSelectAllDevice}
      isOpenSelectList={isOpenDeviceList}
      isSearchActive={false}
      isDisabled={!isNodes}
      checkedCount={checkedDevicesCount}
      maxWidth={'185px'}
    />
  );
};

export { FilterDevice };
