import React from 'react';
import { ChooseParameterSelect } from '../../../../../../components/chooseParameterSelect/ChooseParameterSelect';
import { useFilters } from '../../../Content/filterContext/сontext';
import { getIsAllAttachSelect, getIsDisabledAttach } from '../../../Content/filterContext/selectors';
import { useQueryParams } from '../../../../../../utils/useQueryParams';

export enum typeAttach {
  ATTACHED = 'ATTACHED',
  DETACHED = 'DETACHED',
}

const defaultFilterState = {
  devices: ['nodes', 'tracker', 'offline'],
  attach: ['attached', 'detached'],
  object_type: ['packages', 'inventories'],
  zones: ['zone'],
};

const getStatusAttachFilter = (queryAttachParams: string[], typeAttach: string) =>
  queryAttachParams?.length > 0 ? queryAttachParams.includes(typeAttach) : false;

const filteredAttachState = (attachState: string[], type: string) => {
  return Array.isArray(attachState) ? attachState.filter((item) => item !== type) : [];
};

const addAttachTypeToState = (attachState: string[], type: string) => {
  const checkedAttachState = Array.isArray(attachState) ? [...attachState, type] : [attachState, type];
  return !!attachState ? checkedAttachState : [type];
};

const setQueryOneAttach = (type: typeAttach, setFilterParam: any, queryParams: any) => {
  const attachState = queryParams?.attach;

  const stringTypeAttach = {
    [typeAttach.ATTACHED]: 'attached',
    [typeAttach.DETACHED]: 'detached',
  };

  const setTypesAttach = {
    [typeAttach.ATTACHED]: (isNode: boolean) =>
      setFilterParam(
        isNode
          ? { ...queryParams, attach: filteredAttachState(attachState, stringTypeAttach[type]) }
          : { ...queryParams, attach: addAttachTypeToState(attachState, stringTypeAttach[type]) },
      ),
    [typeAttach.DETACHED]: (isTracker: boolean) =>
      setFilterParam(
        isTracker
          ? { ...queryParams, attach: filteredAttachState(attachState, stringTypeAttach[type]) }
          : { ...queryParams, attach: addAttachTypeToState(attachState, stringTypeAttach[type]) },
      ),
  };

  const statusTypeAttach = getStatusAttachFilter(queryParams?.attach, stringTypeAttach[type]);

  return setTypesAttach[type](statusTypeAttach);
};

const FilterAttach = ({ languageStrings, setFilterParam }: any) => {
  const queryParams = useQueryParams() || {};
  const stateFilterAttach = queryParams?.attach;
  const { state, dispatch } = useFilters();
  const { attachState } = state;
  const { checkedAttachCount, isOpenAttachList, attachFilter } = attachState;

  const isSelectAllAttach = getIsAllAttachSelect(state);
  const isDisabledAttach = getIsDisabledAttach(state);

  const handleSelectAllAttach = () => {
    dispatch({ type: 'SELECT_ALL_ATTACH', payload: isSelectAllAttach });
    setFilterParam(
      !!stateFilterAttach && stateFilterAttach.length >= 1
        ? { ...queryParams, attach: '' }
        : { ...queryParams, attach: defaultFilterState.attach },
    );
  };

  const handleSelectOneAttach = (typeAttach: typeAttach) => {
    dispatch({ type: 'SELECT_ONE_ATTACH', payload: { typeAttach } });
    setQueryOneAttach(typeAttach, setFilterParam, queryParams);
  };

  const handleAttachList = (state: boolean) => {
    dispatch({ type: 'HANDLE_ATTACH_LIST', payload: state });
  };

  return (
    <ChooseParameterSelect
      name={languageStrings ? languageStrings.labelAttach : 'Attach'}
      parameters={attachFilter}
      //@ts-ignore
      handleCheckedItem={handleSelectOneAttach}
      handleSelectAll={handleSelectAllAttach}
      setIsOpenSelectList={handleAttachList}
      isSelectAll={isSelectAllAttach}
      isOpenSelectList={isOpenAttachList}
      isSearchActive={false}
      isDisabled={isDisabledAttach}
      checkedCount={checkedAttachCount}
      maxWidth={'185px'}
    />
  );
};

export { FilterAttach };
