import React from 'react';
import styled from 'styled-components';
import { colorFetch } from '../../../../../styles/utils';

const Stages = styled.span`
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
`;

export const StagesView = ({ value }: { value: any }) => {
  return (
    <Stages>
      {value && value?.length
        ? value.map((el: any, i: number) => `${el?.name} ${value?.length - 1 === i ? '' : '> '}`)
        : 'N/A'}
    </Stages>
  );
};
