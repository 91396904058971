import React from 'react';
import styled from 'styled-components';
import { media } from '../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { colorFetch } from '../../styles/utils';

const Wrapper = styled.div`
  width: 100%;
  max-width: 515px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: ${colorFetch('gray5')};
  border-radius: 6px;
  ${media.phone`
    flex-direction: column;
    align-content: "space-around";`};
`;

const QrCodeImg = styled.img`
  padding: 20px;
  width: 100px;
  height: 100px;
`;

const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 250px;
  height: 40px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  color: ${colorFetch('gray1')};
  background: ${colorFetch('pureWhite')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: 6px;
  margin-left: 20px;
  text-decoration: none;
  &:hover {
    background-color: ${colorFetch('gray2')};
    border-color: ${colorFetch('gray2')};
    color: ${colorFetch('pureWhite')};
    transition: all 0.3s ease;
  }
  ${media.phone`
    margin: 0 0 20px 0;
    max-width: calc(100% - 40px);
  `};
`;

interface QrCodeSectionProps {
  value?: string;
}

export const QrCodeSection: React.FC<QrCodeSectionProps> = ({ value }) => {
  const language = useSelector(selectLanguageStrings);
  return (
    <Wrapper>
      <QrCodeImg src={value} />
      <DownloadButton href={value}>{language ? language.externalDownloadQR : 'Download QR'}</DownloadButton>
    </Wrapper>
  );
};
