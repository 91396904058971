import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as OnlineImage } from '../../../../../assets/wifi.svg';
import { ReactComponent as OfflineImage } from '../../../../../assets/wifi-off.svg';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { useTheme } from '@cthings.co/styles';

export enum WifiStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

type WrapperProps = {
  isModal: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: max-content;
  display: flex;
  align-items: flex-start;
  margin-left: ${({ isModal }) => (isModal ? '6px' : '0')};
  & > span {
    /** @REVIEW dont customise children like this */
    font-weight: 400;
    margin-left: 4px;
  }
`;

export const Wifi = ({ status, state }: { status: number; state: TableCustomComponentState | undefined }) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const language = useSelector(selectLanguageStrings);

  return (
    <Wrapper isModal={state === TableCustomComponentState.VIEW_MODAL}>
      {status > 0 ? (
        <>
          <OnlineImage />
          <Text type={TextType.TEXT_14_GRAY} color={gray1}>
            {language ? language.labelOnline : 'Online'}
          </Text>
        </>
      ) : (
        <>
          <OfflineImage />
          <Text type={TextType.TEXT_14_GRAY} color={gray1}>
            {language ? language.labelOffline : 'Offline'}
          </Text>
        </>
      )}
    </Wrapper>
  );
};
