/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings select require
 * Highly customisable.
 *
 */

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { SmallLoader } from '../../features/smallLoader/SmallLoader';
import { media } from '../../styles/media';
import { useMediaType } from '../../styles/style.context';
import { usePrevious } from '../../utils/usePrevious';
import { SearchInputSelect } from '../searchInputSelect/SearchInputSelect';
import { CommonButton } from '@bit/first-scope.common-button';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as Arrow } from './assets/arrow.svg';
import { ReactComponent as Delete } from './assets/delete.svg';
import { ReactComponent as Edit } from './assets/edit.svg';
import { ReactComponent as Accept } from './assets/accept.svg';
import { ReactComponent as Close } from './assets/close.svg';
import { ReactComponent as Cross } from './assets/cross.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const SelectBlock = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

type SelectItemProps = {
  paddingInput?: string;
  disabled: boolean;
  height?: string;
  isFocused: boolean;
  isError?: boolean;
  isPendingRequest?: boolean;
};

const SelectItem = styled.div<SelectItemProps>`
  padding: ${({ paddingInput, disabled }) =>
    paddingInput ? paddingInput : disabled ? '8px 12px' : '6px 17px 6px 12px'};
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${({ disabled, theme }) => (disabled ? colorFetch('gray5')({ theme }) : 'transparent')};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  width: 100%;
  height: ${({ height }) => height || 'unset'};
  border: 1px solid
    ${({ disabled, isFocused, isError, theme }) =>
      disabled
        ? colorFetch('gray5')({ theme })
        : isFocused
        ? colorFetch('primary')({ theme })
        : isError
        ? colorFetch('red1')({ theme })
        : colorFetch('gray3')({ theme })};
  &.borderless {
    border: 0;
  }
  display: flex;
  outline: none;
  justify-content: space-between;
  align-items: center;
  cursor: ${({ isPendingRequest }) => (isPendingRequest ? 'auto' : 'pointer')};
  transition: all 0.3s linear;
`;

type TextItemProps = {
  color: string;
};

const TextItem = styled.span<TextItemProps>`
  display: block;
  font-size: 14px;
  line-height: 22px;
  font-family: Poppins, sans-serif;
  color: ${({ color }) => color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  &.stageType {
    ${media.megaMonitor`
      width: 118px;
    `}
    ${media.tablet`
      width: 100%;
    `}
  }
  -webkit-text-fill-color: ${({ color }) => color};
  opacity: 1; /* required on iOS */
  -webkit-appearance: caret;
  -moz-appearance: caret; /* mobile firefox too! */
`;

type ArrowButtonProps = {
  isOpen: boolean;
};

const ArrowButton = styled.button<ArrowButtonProps>`
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s linear;
  & svg {
    transition: all 0.3s;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : '')};
  }
`;

type SelectModalProps = {
  width?: string;
  height?: string;
  left?: string;
  top?: string;
};

const SelectModal = styled.div<SelectModalProps>`
  width: ${({ width }) => width || '100%'};
  max-height: ${({ height }) => height};
  /* max-height: 316px; */
  left: ${({ left }) => left || '0'};
  box-sizing: border-box;
  border-radius: ${borderRadiusFetch('primary')};
  position: absolute;
  z-index: 20;
  top: ${({ top }) => top || '62px'};
`;

const SelectModalContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadiusFetch('primary')};
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  background-color: ${colorFetch('pureWhite')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  &.borderless {
    width: calc(100% - 8px);
  }
`;

const ScrolledWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex: 1;
`;

type ScrolledListProps = {
  isCreateInDropDown: boolean;
};

const ScrolledList = styled.ul<ScrolledListProps>`
  width: 100%;
  max-height: 250px;
  background-color: ${colorFetch('pureWhite')};
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  padding: 6px 6px 6px 0;
  margin: 2px 2px 2px 0;
  border-radius: ${borderRadiusFetch('primary')};
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: 9px;
    width: 4px;
  }
  ${media.tabletNavigation`
      max-height: ${({ isCreateInDropDown }: any) => (isCreateInDropDown ? '160px' : '250px')};
    `}
`;

const SelectModalItem = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 4px 5px 12px;
  cursor: pointer;
  color: ${colorFetch('black1')};
  overflow: hidden;
  transition: all 0.3s linear;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > span {
    flex: 1;
    /* word-break: break-all; */
    hyphens: auto;
  }
  &.selected > span {
    color: ${colorFetch('primary')};
    font-weight: 500;
  }
  &.create {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > span {
      margin: 8px 0 8px;
      flex: 1;
      /* word-wrap: break-word; */
      hyphens: auto;
      /* -moz-hyphens: auto;
      -webkit-hyphens: auto;
      -ms-hyphens: auto; */
    }
    &:hover > span {
      color: ${colorFetch('black1')};
      font-weight: normal;
      cursor: default;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 4px;
      left: 12px;
      height: 1px;
      background-color: ${colorFetch('gray4')};
      opacity: 1;
      transition: opacity 0.3s linear;
    }
    &.single {
      margin: 0;
      & > span {
        margin-top: 0;
      }
      &::after {
        opacity: 0;
      }
    }
  }
  &:hover > span {
    color: ${colorFetch('primary')};
    font-weight: 500;
  }
  &:hover .buttons .edit,
  &:hover .buttons .delete {
    opacity: 1;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const EditInput = styled.input`
  margin-right: 8px;
  padding: 0 6px;
  width: 100%;
  height: 100%;
  border: none;
  padding: none;
  outline: none;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  color: ${colorFetch('black1')};
  border: 1px solid;
  border-color: ${colorFetch('primary')};
  border-radius: ${borderRadiusFetch('primary')};
`;

const Buttons = styled.div`
  width: 45px;
  display: flex;
  & .button {
    transition: all 0.3s linear;
    & path {
      stroke: ${colorFetch('gray2')};
    }
    &:hover path {
      transition: all 0.3s linear;
      stroke: ${colorFetch('gray3')};
    }
  }
  & .edit,
  & .delete {
    opacity: 0;
  }
`;

const StyledAccept = styled(Accept)`
  margin-right: 5px;
`;

const StyledClose = styled(Close)``;

const StyledEdit = styled(Edit)`
  margin-right: 5px;
`;

const StyledDelete = styled(Delete)``;

const StyledButton = styled(CommonButton)`
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colorFetch('black1')};
  background-color: ${colorFetch('pureWhite')};
`;

const Message = styled(Text)`
  width: 100%;
`;

const PlaceholderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  & > span {
    font-size: 14px;
    line-height: 24px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CloseButton = styled.button`
  width: 18px;
  height: 18px;
  outline: transparent;
  border: none;
  background: ${colorFetch('gray4')};
  border-radius: 26px;
  padding: 0;
  margin-right: 7px;
  cursor: pointer;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: max-content !important;
`;

const HelpText = styled.a`
  color: ${colorFetch('primary')};
  text-decoration: none;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-right: 7px;
`;

interface valueType {
  id: any;
  name: string;
}

export interface ComponentProps {
  value: valueType;
  values: any[];
  disabled?: boolean;
  onChange: (value: any) => any;
  className?: any;
  label?: any;
  width?: any;
  height?: any;
  placeholder?: string;
  positionLeft?: any;
  paddingInput?: any;
  positionTop?: any;
  handleClick?: any;
  borderLess?: boolean;
  openingListener?: (isOpen: boolean) => void;
  stageType?: boolean;
  banAutoFocus?: boolean;
  startedValidation?: boolean;
  isError?: boolean;
  fromAttach?: boolean;
  isPendingRequest?: boolean;
  isShowSearch?: boolean;
  defaultSelectValue?: string;
  updateItem?: Function;
  deleteItem?: Function;
  createItem?: Function;
  triggerColumnStructure?: () => void;
  setSearchValue?: any;
  opened?: boolean;
  isCreatePlaceholderSearchBar?: boolean;
  addButtonLabel?: string;
  isCreateInDropDown?: boolean;
  isResetButton?: boolean;
  helperTextData?: { link: string; helperText: string };
}

export const Select: FC<ComponentProps> = ({
  value,
  values,
  disabled = false,
  onChange,
  label,
  width,
  height,
  placeholder,
  positionLeft,
  paddingInput,
  positionTop,
  handleClick,
  borderLess,
  openingListener,
  stageType,
  banAutoFocus,
  startedValidation,
  isError,
  fromAttach,
  isPendingRequest,
  isShowSearch = true,
  defaultSelectValue,
  updateItem,
  deleteItem,
  createItem,
  triggerColumnStructure,
  setSearchValue,
  opened,
  addButtonLabel,
  isCreatePlaceholderSearchBar,
  isCreateInDropDown = false,
  isResetButton,
  helperTextData,
  ...props
}) => {
  const theme = useTheme();
  const { black1, gray1, gray2, red2, primary } = theme.colors;

  const { tablet } = useMediaType();
  const withCreating = !!updateItem && !!deleteItem && !!createItem;
  const withAdd = !!createItem;
  const withCreate = withCreating || withAdd;

  const [isOpen, setIsOpen] = useState(false);
  const prevIsOpen = usePrevious(isOpen);
  const [selectedIndex, setSelectedIndex] = useState<number>(
    values?.findIndex((item: any) => value && item?.id === value?.id),
  );

  const language = useSelector(selectLanguageStrings);
  const [searchInputValue, setSearchInputValue] = useState('');
  const isShowMessage = fromAttach ? !!isError : startedValidation && !!isError && !disabled;

  const heightOfPopup = values ? `${values.length * 34}px` : 'max-content';

  const buttonRef = useRef<any>(null);
  const selectRef = useRef<any>(null);

  const outsideHandler = useCallback(
    (e: any) => {
      // @TODO check this behavior in all occurencies
      if (isOpen /*&& !buttonRef.current?.contains(e.target)*/) {
        setIsOpen(false);
        setIsEditing(null);
      }
    },
    [isOpen],
  );

  const filterParameterList = (flag: string, items: any) => {
    return (
      items &&
      items.filter((item: any) => {
        return item?.name?.length > 0 && item?.name?.toLowerCase()?.indexOf(flag.toLowerCase()) !== -1;
      })
    );
  };

  const handleChange = (index: any, value: valueType) => {
    setIsOpen(false);
    setSelectedIndex(index);
    onChange(value);
  };

  const handleInutOnChange = (e: any) => {
    setSearchInputValue(e.target.value);
    setSearchValue && setSearchValue(e.target.value);
  };

  const handleClearInput = () => {
    setSearchInputValue('');
  };

  useEffect(() => {
    if (stageType) {
      onChange(value);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', outsideHandler);
    return () => {
      window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  useEffect(() => {
    isOpen && isOpen !== prevIsOpen && handleClick && handleClick();
  }, [isOpen]);

  useEffect(() => {
    setSelectedIndex(values?.findIndex((item: any) => value && item?.id === value?.id));
  }, [value, values]);

  useEffect(() => {
    openingListener && openingListener(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && selectRef && tablet) {
      selectRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [isOpen, selectRef]);

  const selectValue = typeof value === 'string' ? value : values && selectedIndex !== -1 && values[selectedIndex]?.name;

  const [isEditing, setIsEditing] = useState(null);
  const [valueInEdit, setValueInEdit] = useState('');

  const handleCreate = (name: string) => {
    handleClearInput();
    createItem && createItem({ body: { name }, callback: onChange });
  };

  const handleUpdate = (name: string, id: string) => {
    updateItem && updateItem({ body: { name, id }, callback: triggerColumnStructure });
  };

  const handleDelete = (id: string) => {
    deleteItem && deleteItem({ body: { id } });
  };

  const handleResetSelect = () => {
    // onChange(null);
    onChange({ name: '', id: '' });
    setSelectedIndex(-1);
  };

  return (
    <SelectBlock {...props}>
      {label && (
        <LabelWrapper>
          <Text type={TextType.TEXT_12_BLACK} color={gray1} margin="0 0 4px 6px">
            {label}
          </Text>
          {helperTextData && (
            <HelpText href={helperTextData?.link} target="_blank" rel={'noopener noreferer'}>
              {helperTextData?.helperText}
            </HelpText>
          )}
        </LabelWrapper>
      )}
      <SelectItem
        ref={selectRef}
        disabled={disabled}
        isError={isShowMessage}
        className={!!borderLess ? 'borderless' : ''}
        isFocused={isOpen}
        height={height}
        onClick={
          isPendingRequest
            ? () => {}
            : () => {
                setIsOpen(!isOpen);
                !isOpen && handleClearInput();
              }
        }
        paddingInput={paddingInput}
        isPendingRequest={isPendingRequest}
      >
        <TextItem
          color={disabled ? gray1 : selectValue ? gray1 : defaultSelectValue ? gray1 : gray2}
          className={stageType ? 'stageType' : ''}
        >
          {selectValue ? selectValue : defaultSelectValue ?? placeholder}
        </TextItem>

        {!disabled && isPendingRequest ? (
          <SmallLoader style={{ width: '24px', height: '24px' }} />
        ) : (
          <ButtonsWrapper>
            {(!disabled && !!defaultSelectValue && selectValue) || (isResetButton && selectValue && !disabled) ? (
              <CloseButton onClick={handleResetSelect}>
                <Cross />
              </CloseButton>
            ) : (
              <></>
            )}
            {!disabled && (
              <ArrowButton isOpen={isOpen} ref={buttonRef}>
                <Arrow />
              </ArrowButton>
            )}
          </ButtonsWrapper>
        )}
      </SelectItem>
      {isOpen && (
        <SelectModal
          theme={theme}
          width={width}
          height={values && values?.length > 5 ? heightOfPopup : ''}
          left={positionLeft}
          top={positionTop}
        >
          <SelectModalContainer theme={theme} className={!!borderLess ? 'borderless' : ''}>
            {isShowSearch && (
              <SearchInputSelect
                value={searchInputValue}
                handleOnChange={handleInutOnChange}
                handleClearInput={handleClearInput}
                setIsEditing={setIsEditing}
                languageStrings={language}
                isFocused={isOpen && !tablet}
                banAutoFocus={banAutoFocus}
                isCreatePlaceholderSearchBar={isCreatePlaceholderSearchBar}
              />
            )}
            <ScrolledWrapper>
              <ScrolledList theme={theme} isCreateInDropDown={isCreateInDropDown}>
                {(!withCreating && filterParameterList(searchInputValue, values).length) || withCreate ? (
                  <>
                    {filterParameterList(searchInputValue, values).map((el: any, i: number) => (
                      <SelectModalItem
                        key={el.id}
                        // @TODO check and fix
                        className={
                          (selectedIndex !== -1 && values[selectedIndex].id === el.id && 'selected') ||
                          (el.name === value && 'selected') ||
                          ''
                        }
                        onClick={(e: any) => {
                          e.stopPropagation();
                          isEditing !== el.id && handleChange(i, el);
                        }}
                        // onClick={() => isEditing !== i && console.log('HEll', isEditing, i)}
                        // onBlur={() => setIsEditing(-1)}
                      >
                        {withCreating && isEditing === el.id ? (
                          <EditInput
                            theme={theme}
                            value={valueInEdit}
                            onClick={(e: any) => e.stopPropagation()}
                            onChange={(e: any) => setValueInEdit(e.currentTarget.value)}
                            autoFocus
                          />
                        ) : (
                          <Text type={TextType.TEXT_14_BLACK} color={black1} weight={'400'}>
                            {el.name}
                          </Text>
                        )}
                        {withCreating && (
                          <Buttons className={`buttons`}>
                            {isEditing === el.id ? (
                              <>
                                <StyledAccept
                                  className={'button'}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    handleUpdate(valueInEdit, el.id);
                                    setIsEditing(null);
                                  }}
                                />
                                <StyledClose
                                  className={'button'}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    setIsEditing(null);
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <StyledEdit
                                  className={'button edit'}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    setIsEditing(el.id);
                                    setValueInEdit(el.name);
                                  }}
                                />
                                {!(selectedIndex !== -1 && values[selectedIndex].id === el.id) && (
                                  <StyledDelete
                                    className={'button delete'}
                                    onClick={(e: any) => {
                                      handleDelete(el.id);
                                      e.stopPropagation();
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </Buttons>
                        )}
                      </SelectModalItem>
                    ))}
                  </>
                ) : (
                  <PlaceholderWrapper>
                    <Text type={TextType.TEXT_12_BLACK} color={gray2} weight={'400'} margin={'0 0 0 10px'}>
                      {language ? language.emptyPlaceholder : 'Data is empty'}
                    </Text>
                  </PlaceholderWrapper>
                )}
              </ScrolledList>
            </ScrolledWrapper>
            {(searchInputValue || isCreateInDropDown) && withCreate && (
              <SelectModalItem
                className={`create ${filterParameterList(searchInputValue, values).length === 0 && 'single'}`}
              >
                <Text type={TextType.TEXT_14_BLACK} color={black1} weight={'400'}>
                  {searchInputValue}
                </Text>
                <StyledButton
                  borderColor={primary}
                  borderColorHover={primary}
                  onClick={() => handleCreate(searchInputValue)}
                >
                  {addButtonLabel || language.createNewButton}
                </StyledButton>
              </SelectModalItem>
            )}
          </SelectModalContainer>
        </SelectModal>
      )}
      {isShowMessage && (
        <Message type={TextType.TEXT_12_GRAY} color={red2} margin={'4px 0 0 12px'}>
          {`${label} ${language ? language.inputErrorText : 'doesnt look right'}`}
        </Message>
      )}
    </SelectBlock>
  );
};
