/**
 * @description
 * This is component for popup choose parameter in select
 * all the functionalities cthings require for ChooseParameterSelectPopup
 * Highly customisable.
 *
 */

import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styles';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as NodeIndicator } from '../../../../assets/NodeIndicator.svg';
import { ReactComponent as OfflineIndicator } from '../../../../assets/OfflineIndicator.svg';
import { ReactComponent as TrackerIndicator } from '../../../../assets/TrackerIndicator.svg';
import { TypeOfDeviceParameter } from '../../../../containers/common/campusMapInsight/Content/Content';
import { borderRadiusFetch, colorFetch, shadowFetch } from '../../../../styles/utils';
import { SearchInput } from '../searchInput/SearchInput';
import { CheckBox } from './checkbox/Checkbox';

type WrapperProps = {
  maxWidthSelectPopup?: string;
  fieldHeight: number;
};

const Wrapper = styled.div<WrapperProps>`
  max-width: ${({ maxWidthSelectPopup }) => maxWidthSelectPopup || '160px'};
  width: 100%;
  box-sizing: border-box;
  background-color: ${colorFetch('pureWhite')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  box-shadow: ${shadowFetch('additionalShadow4')};
  border-radius: ${borderRadiusFetch('primary')};
  position: absolute;
  top: ${({ fieldHeight }) => `${fieldHeight + 8}px`};
  left: 0;
  z-index: 2;
  cursor: auto;
`;

const ParameterListWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 12px 4px 0 12px;
`;

const ParameterItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 12px;
  }
  & > div {
    margin-right: 8px;
  }
  & > svg {
    margin-left: 4px;
  }
`;

export const ChooseParameterSelectPopup = ({
  popupRef,
  parameterList,
  handleCheckedItem,
  handleSelectAll,
  fieldHeight,
  isSelectAll,
  isSearchActive,
  maxWidthSelectPopup,
  language,
}: {
  popupRef: any;
  parameterList: any[];
  handleCheckedItem: (type?: TypeOfDeviceParameter) => void;
  handleSelectAll: () => void;
  fieldHeight: number;
  isSelectAll?: boolean;
  isSearchActive?: boolean;
  maxWidthSelectPopup?: string;
  language?: any;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const [searchInputValue, setSearchInputValue] = useState('');

  const handleInputOnChange = (e: any) => {
    setSearchInputValue(e.target.value);
  };
  const handleClearInput = () => {
    setSearchInputValue('');
  };

  const filterParameterList = (flag: string) => {
    if (parameterList && parameterList.length) {
      const filteredValue: any = parameterList.filter((item: any) => {
        return item?.name?.toLowerCase().indexOf(flag.toLowerCase()) !== -1;
      });
      return filteredValue;
    } else {
      return [];
    }
  };

  const getIndicator = useCallback((type: TypeOfDeviceParameter) => {
    const getIndicator: any = {
      [TypeOfDeviceParameter.NODE]: <NodeIndicator />,
      [TypeOfDeviceParameter.TRACKER]: <TrackerIndicator />,
      [TypeOfDeviceParameter.OFFLINE]: <OfflineIndicator />,
    };
    return getIndicator[type];
  }, []);

  const itemsList = [
    { isSelected: isSelectAll, name: language ? language.labelAll : 'All', type: null, handleToggle: handleSelectAll },
    ...filterParameterList(searchInputValue).map((item: any) => ({
      ...item,
      handleToggle: () => handleCheckedItem(item.type),
    })),
  ];

  return (
    <Wrapper
      ref={popupRef}
      theme={theme}
      fieldHeight={fieldHeight}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      maxWidthSelectPopup={maxWidthSelectPopup}
    >
      {isSearchActive && (
        <SearchInput
          value={searchInputValue}
          handleOnChange={handleInputOnChange}
          handleClearInput={handleClearInput}
        />
      )}
      <ParameterListWrapper>
        {itemsList.map((item: any, index: number) => {
          const { name, isSelected, type, handleToggle } = item;

          return (
            <ParameterItem key={index}>
              <CheckBox value={isSelected} disabled={false} onChange={handleToggle} />
              <Text type={TextType.TEXT_12_GRAY} color={gray1}>
                {name}
              </Text>
              {type ? getIndicator(type) : null}
            </ParameterItem>
          );
        })}
      </ParameterListWrapper>
    </Wrapper>
  );
};
