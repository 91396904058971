import { useState, useEffect } from 'react';
import { TEST_GLOBAL_NOTIFICATION_SOCKET_URL } from '../consts';
import { io, Socket } from 'socket.io-client';
const defaultData = {
  message: '',
  severity: 1,
};

export const useGlobalNotification = (addNotification: Function) => {
  const [notificationMessage, setNotificationMessage] = useState<typeof defaultData>();
  const [connected, setConnected] = useState(false);

  const [socket, setSocket] = useState<Socket | null>(null);

  const resetData = () => {
    setNotificationMessage(defaultData);
  };

  useEffect(() => {
    const newSocket = io(TEST_GLOBAL_NOTIFICATION_SOCKET_URL, { transports: ['websocket'] });
    setSocket(newSocket);
    return () => {
      newSocket.close();
    };
  }, [setSocket]);

  useEffect(() => {
    if (socket) {
      socket?.on('connect', () => {
        console.log('connected1');
        setConnected(true);
      });
      socket?.on('disconnect', () => {
        console.log('disconnected');
        setConnected(true);
      });

      socket?.on('push_data', (val: any) => {
        const messageNotification = JSON.parse(val);

        // SOCKET IS WORKING WITH FAKE DATA
        setNotificationMessage(messageNotification);
      });

      return () => {
        socket?.disconnect();
        setConnected(false);
      };
    }
  }, [socket]);

  useEffect(() => {
    if (socket && connected && notificationMessage?.message) {
      console.log('PING');
      addNotification({
        message: notificationMessage.message,
        type: notificationMessage.severity === 1 ? 'success' : 'error',
      });

      socket.emit('push_data', notificationMessage);
      resetData();
    }
  }, [connected, notificationMessage?.message]);

  return;
};
