// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { PackageModal } from './packageModal/PackageModal';
import { EmptyPackageList } from './emptyPackageList/EmptyPackageList';
import { setFilters } from '../../../../../app/state/filtersSlice';
import { Text } from '@bit/first-scope.text';
import { ReactComponent as SearchImage } from '../../../../../assets/Search.svg';
import { ReactComponent as Box } from '../../../../../assets/box‎.svg';
import { ReactComponent as LocationSlash } from '../../../../../assets/LocationSlash.svg';
import { Loader, LoaderType } from '../../../../../features/loader/Loader';

export const BoxIcon = styled(Box)`
  & > path {
    stroke: ${({ color }: any) => color};
  }
`;

export const LocationSlashIcon = styled(LocationSlash)`
  & > path {
    stroke: ${({ color }: any) => color};
  }
`;

export const ExtraWrapper = styled.div`
  min-width: 250px;
  width: 250px;
  min-height: calc(100vh - 323px);
  margin-top: 15px;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  &:hover > span {
    color: ${({ hoverColor }: any) => hoverColor};
  }
`;

export const SearchIcon = styled(SearchImage)`
  position: absolute;
  top: 40%;
  left: 10px;
  transform: translateY(-40%);
`;

export const ListWrapper = styled.div`
  max-height: calc(100vh - 380px);
  height: 100%;
  overflow-x: auto;
  padding: 7px 15px;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
    opacity: 0.6;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray3;
    border-radius: 15px;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid ${({ borderColor }: any) => borderColor};
  border-radius: ${({ borderRadius }: any) => borderRadius} ${({ borderRadius }: any) => borderRadius} 0 0;
  padding: 8px 38px;
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: black;

  &::placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: gray2;
  }
  -webkit-appearance: caret;
  -moz-appearance: caret;
`;

export const SearchWrapper = styled.div`
  position: relative;
`;

export const StyledText = styled(Text)`
  max-width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-left: 12px;
`;

export const Wrapper = styled.div`
  min-width: 250px;
  width: 250px;
  height: 100%;
  border: 1px solid ${({ borderColor }: any) => borderColor};
  background-color: ${({ backgroundColor }: any) => backgroundColor};
  border-radius: ${({ borderRadius }: any) => borderRadius};
  box-sizing: border-box;
`;

export const WrapperTextLocation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const getIndexActiveAddressAndLevel = (locations: any, floorId: any) => {
  let idActiveLevels = 0;
  let idActiveAddress = 0;

  locations.forEach((location: any, id: number) => {
    const locationFloors = location?.floors;

    const isFloorId = (element: any) => {
      return element?.wpas_ref_floor_id === floorId;
    };

    const result = locationFloors.findIndex(isFloorId);
    if (result !== -1) {
      idActiveLevels = result;
      idActiveAddress = id;
    }
  });

  return { idActiveAddress, idActiveLevels };
};

interface OrderModeModalPropsType {
  data: any;
  isClearFilterItems: boolean;
  setIsClearFilterItems: any;
  setValuesFilter: any;
  setExplorerRequestBody: any;
  isPackageListLoader: boolean;
  isOpenDetailsModal: boolean;
  setIsOpenDetailsModal: (data: any) => void;
  activeDevice: any;
  setActiveDevice: (data: any) => void;
  setIsLoadingImageMap: (data: any) => void;
  explorerRequestBody: any;
  handleClearWebsocketData: () => void;
  locations: any[];
  setActiveAddress: (value: number) => void;
  setActiveLevel: (value: number) => void;
}

export const PackagesList: FC<OrderModeModalPropsType> = ({
  data,
  setIsClearFilterItems,
  setExplorerRequestBody,
  isPackageListLoader,
  isOpenDetailsModal,
  setIsOpenDetailsModal,
  activeDevice,
  setActiveDevice,
  setIsLoadingImageMap,
  explorerRequestBody,
  handleClearWebsocketData,
  locations,
  setActiveAddress,
  setActiveLevel,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { gray1, gray3, white, blue2, red2, orange2, primary } = theme.colors;
  const { additional6 } = theme.borderRadius;

  const languageStrings = useSelector(selectLanguageStrings);

  const [inputValue, setInputValue] = useState('');
  const [dataArray, setDataArray] = useState<any>();

  const setColor = (num: string) => {
    const color: any = {
      blue: blue2,
      orange: orange2,
      red: red2,
    };

    return color[num];
  };

  const handleChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    setInputValue(target.value);
  };

  const openActiveDeviceHandle = (device: any) => {
    const florplanId = explorerRequestBody?.floorplan_id
      ? explorerRequestBody?.floorplan_id
      : data?.default_location?.floor?.wpas_ref_floor_id;

    if (device?.[15]?.value && florplanId !== device?.[12]?.value?.floorplan_id) {
      handleClearWebsocketData();
      setIsLoadingImageMap(true);

      setExplorerRequestBody((values: any) => {
        return {
          ...values,
          floorplan_id: device?.[12]?.value?.floorplan_id,
          building_id: device?.[12]?.value?.building_id,
          linkImage: device?.[12]?.value?.floor_image_url,
        };
      });

      const { idActiveAddress, idActiveLevels } = getIndexActiveAddressAndLevel(
        locations,
        device?.[12]?.value?.floorplan_id,
      );

      setActiveAddress(idActiveAddress);
      setActiveLevel(idActiveLevels);
    }

    if (device) {
      setActiveDevice(device);
      setIsOpenDetailsModal(true);
    }
  };

  const closeActiveDeviceHandle = () => {
    setActiveDevice(null);
    setIsOpenDetailsModal(false);
  };

  const handleClearItems = () => {
    setExplorerRequestBody((values: any) => {
      return { ...values, filters: [] };
    });
    dispatch(setFilters([]));
    setIsClearFilterItems(true);
    setInputValue('');
  };

  useEffect(() => {
    if (dataArray) {
      const packagesData = data?.items?.map((item: any) => {
        return [...item.content, ...item.info];
      });
      const searchResult =
        inputValue &&
        packagesData?.filter((element: any) => {
          return element[1].value.toLowerCase().includes(inputValue.toLowerCase());
        });
      const result = inputValue && !searchResult ? [] : searchResult || packagesData;
      setDataArray(result);
    }
  }, [inputValue]);

  useEffect(() => {
    if (data) {
      const packagesData = data?.items?.map((item: any) => [...item.content, ...item.info]);
      setDataArray(packagesData);
    }
  }, [data]);

  return (
    <>
      {!!dataArray?.length || isPackageListLoader ? (
        <ExtraWrapper>
          <Wrapper borderColor={gray3} backgroundColor={white} borderRadius={additional6}>
            {isPackageListLoader ? (
              <Loader style={{ height: '100%', position: 'static' }} type={LoaderType.CLASSIC} />
            ) : (
              <>
                <SearchWrapper>
                  <SearchIcon />
                  <SearchInput
                    borderRadius={additional6}
                    borderColor={gray3}
                    placeholder={languageStrings ? languageStrings.searchPlaceholder : 'Search...'}
                    value={inputValue}
                    onChange={(e: Event) => {
                      handleChange(e);
                    }}
                  />
                </SearchWrapper>

                <ListWrapper>
                  {dataArray.map((result: any, index: number) => {
                    return (
                      <ItemWrapper key={index} onClick={() => openActiveDeviceHandle(result)} hoverColor={primary}>
                        <BoxIcon color={setColor(result?.[3]?.color)} />
                        <WrapperTextLocation>
                          <StyledText color={gray1}>{result?.[1]?.value ?? 'N/A'}</StyledText>
                          {!result?.[15]?.value ? <LocationSlashIcon color={setColor(result?.[3]?.color)} /> : null}
                        </WrapperTextLocation>
                      </ItemWrapper>
                    );
                  })}
                </ListWrapper>
              </>
            )}
          </Wrapper>

          {activeDevice && (
            <PackageModal
              data={activeDevice}
              isOpenModal={isOpenDetailsModal}
              closeModal={closeActiveDeviceHandle}
              color={setColor(activeDevice[3]?.color)}
              languageStrings={languageStrings}
              isNeverHadLocation={activeDevice ? activeDevice[15]?.value : false}
            />
          )}
        </ExtraWrapper>
      ) : (
        <EmptyPackageList handleClearItems={handleClearItems} />
      )}
    </>
  );
};
