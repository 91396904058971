import { localTheme } from './types';
import {
  BorderFetch,
  borderFetchGeneric,
  BorderRadiusFetch,
  borderRadiusFetchGeneric,
  ColorFetch,
  colorFetchGeneric,
  ShadowFetch,
  shadowFetchGeneric,
} from '@cthings.co/styles';

export const colorFetch: ColorFetch<typeof localTheme> = colorFetchGeneric;
export const shadowFetch: ShadowFetch<typeof localTheme> = shadowFetchGeneric;
export const borderFetch: BorderFetch<typeof localTheme> = borderFetchGeneric;
export const borderRadiusFetch: BorderRadiusFetch<typeof localTheme> = borderRadiusFetchGeneric;
