/**
 * @description
 * used to generate random IDs
 *
 */

// @ts-ignore
import { v4 as uuidv4, v1 as uuidv1 } from 'uuid';

export const createId = (index?: number): string => {
  const timestampPart = uuidv1();
  const randomPart = uuidv4();
  // custom uuid logic compines static text with random uuid with
  // current index included and also adds the timestamp uuid in the end
  const combinedUuid = randomPart.slice(0, randomPart.length - 1) + (index ? index : 0) + timestampPart;
  return combinedUuid;
};

// use this hook to define tooltip ids
export const useUuid = (amount?: number) => {
  const num = amount || 1;
  const uuidArray = [...Array(num)].map((item: any) => createId(item));
  return uuidArray;
};
