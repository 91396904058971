import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Select } from '../../../../../../../../components/select/Select';
import { media } from '../../../../../../../../styles/media';
import { selectLanguageStrings } from '../../../../../../../../app/state/userSlice';
import { useTableContext, useTableFunctions } from '../../../../../../../../features/universalTable/context';
import { useGetStagesList } from '../../../../../../../../api/manageOrderDetails';
import { TableCustomComponentState } from '../../../../../../../../features/universalTable/types';
import { SmallLoader } from '../../../../../../../../features/smallLoader/SmallLoader';

const Wrapper = styled.div`
  position: relative;
`;

const StyledSelect = styled(Select)`
  width: 230px;
  & > div {
    min-height: 40px;
  }
  & > div:nth-child(2) {
    top: 44px;
  }
  ${media.tablet`
     width: 100%;
   `}
`;

const StyledLoader = styled(SmallLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(2px);
`;

export const StageSelect = ({
  label,
  value,
  setValue,
  orderId,
  disabled,
  state,
}: {
  value: any;
  setValue: any;
  orderId: string;
  label?: string;
  disabled?: boolean;
  state?: TableCustomComponentState | undefined;
}) => {
  const language = useSelector(selectLanguageStrings);

  const [{ openId }] = useTableContext();
  const { getRowEssentials } = useTableFunctions();

  const { rawData } = getRowEssentials(openId ?? '');
  const compId = rawData?.component?.id;

  const [getStagesList] = useGetStagesList(orderId, compId);
  const { stages } = getStagesList;

  const handleOnChange = (value: any) => {
    setValue(value);
  };

  return (
    <Wrapper>
      <StyledSelect
        value={value}
        label={label}
        values={stages}
        onChange={handleOnChange}
        disabled={state === TableCustomComponentState.VIEW_MODAL || !compId}
        placeholder={!compId ? language.firstChooseIndexPlaceholder : language.chooseStagePlaceholder}
        isPendingRequest={!stages?.length}
      />
      {state === TableCustomComponentState.VIEW_MODAL && !stages && <StyledLoader />}
    </Wrapper>
  );
};
