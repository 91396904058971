import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../app/state/userSlice';

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
`;

export const RowPlaceholder = () => {
  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <Wrapper>
      <Text type={TextType.TEXT_14_GRAY}>{languageStrings.noItemsText ?? 'No items'}</Text>
    </Wrapper>
  );
};
