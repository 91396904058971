import React, { createContext, useContext, useReducer } from 'react';
import { setInitialStateMobile } from './setInitialStateMobile';
import { setInitialStateValmontMobile } from './setInitialStateValmontMobile';
import { mobileReducer } from './mobileReducer';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';

const FiltersContext = createContext<any | null>(null);

const MobileFiltersProvider = ({ children, isValmontFilterState }: any) => {
  const languageStrings = useSelector(selectLanguageStrings);

  const initialState = isValmontFilterState
    ? setInitialStateValmontMobile(languageStrings)
    : setInitialStateMobile(languageStrings);

  const [stateMobile, dispatchMobile] = useReducer(mobileReducer, initialState);
  const value = { stateMobile, dispatchMobile };
  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};

const useMobileFilters = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFilters must be used within the Filters Provider');
  }
  return context;
};

export { MobileFiltersProvider, useMobileFilters };
