import React from 'react';
import { use100vh } from 'react-div-100vh';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useUserAccess, useUserInfo } from '../../api/user';
import { selectAccess, selectLanguage, selectLanguageStrings } from '../../app/state/userSlice';
import { LsValueType } from '../../enums/LsValueType';
import { UserType } from '../../enums/UserType';
import oauthRedirect from '../../features/authentication/oauthRedirect';
import withAuth from '../../features/authentication/withAuth';
import { Navigation } from '../../features/navigation/Navigation';
import withUserType from '../../routes/withUserType';
import ls from '../../utils/ls';
import { renderRoutes } from '../../utils/renderRoutes';
import { Body, Wrapper } from '../elements/wrappers';

const User = (props: any) => {
  const language = useSelector(selectLanguageStrings);
  const languageKey = useSelector(selectLanguage);
  useUserAccess(languageKey?.id);

  const globalAccess = useSelector(selectAccess);
  const { role_name }: any = globalAccess;
  const location = useLocation();
  const currentRoute = location?.pathname;

  const isPreviewDocsRedirect = currentRoute?.includes('document-preview');

  const [userInfo] = useUserInfo(!isPreviewDocsRedirect);
  const userName = userInfo?.name;

  const handleLogout = (e: any) => {
    e.preventDefault();
    ls.remove(LsValueType.token);
    oauthRedirect(languageKey.id);
  };

  const minHeight = use100vh();

  return (
    <Wrapper minHeight={minHeight}>
      <Navigation
        onLogout={handleLogout}
        userStatus={role_name ? role_name : language ? language.lableUser : 'User'}
        userName={userName}
        withLanguages
      />
      <Body>{renderRoutes(props.routes, props.parentPathLength)}</Body>
    </Wrapper>
  );
};

export default withAuth(withUserType(UserType.USER)(User));
