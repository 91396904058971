import React from 'react';

export const ResetIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9999 6.45459V11.3637H17.0908"
        stroke={color ? color : 'white'}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 19.5454V14.6363H8.90909"
        stroke={color ? color : 'white'}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.05364 10.5455C6.46859 9.37283 7.17384 8.32443 8.10356 7.49808C9.03329 6.67172 10.1572 6.09436 11.3704 5.81985C12.5836 5.54535 13.8466 5.58265 15.0415 5.92828C16.2364 6.2739 17.3243 6.91659 18.2036 7.79637L22 11.3636M4 14.6364L7.79636 18.2036C8.6757 19.0834 9.76358 19.7261 10.9585 20.0717C12.1534 20.4174 13.4164 20.4547 14.6296 20.1802C15.8428 19.9056 16.9667 19.3283 17.8964 18.5019C18.8262 17.6756 19.5314 16.6272 19.9464 15.4545"
        stroke={color ? color : 'white'}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
