import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setStages } from '../app/state/manageStagesSlice';

import { API_URL } from '../consts';
import { HttpService } from '../utils/http';
import { handleUncoveredNotification } from '../utils/handleUncoveredNotification';

export const deleteZoneGlobal = (
  id: string[],
  deleteZone: any,
  deleteCheckedZones: any,
  handleAddNotification: Function,
  triggerUpdateZonesFloorPlan?: any,
  language?: any,
) => {
  const data = { zone_ids: id };
  const url = ` ${API_URL}/v1/zones/delete`;

  const deleteOneZone = () => deleteZone(id[0]);
  (async () => {
    try {
      let responseData = (await HttpService.post(url, data)).data;
      if (id.length === 1) {
        deleteOneZone();
      } else {
        deleteCheckedZones();
      }
      triggerUpdateZonesFloorPlan && triggerUpdateZonesFloorPlan();
      handleAddNotification({
        message: language ? language.deletedSuccessfullyNotification : 'Deleted successfully',
        type: 'success',
      });
    } catch (e) {
      handleUncoveredNotification(e, handleAddNotification, language);
    }
  })();
};

export const useGetStagesList = () => {
  const [localStages, setLocalStages] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    const url = `${API_URL}/v1/stages/?limit=0&offset=0`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { payload } = responseData;
        const result = payload.map((item: any) => ({ name: item.name, id: item.id, isSelected: false }));
        setLocalStages(result);
      } catch (e) {
        //
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(setStages({ list: localStages }));
  }, [localStages]);
  return localStages;
};
