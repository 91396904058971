/**
 * @description
 * This is component for close button
 * all the functionalities cthings require for close button
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseButtonRoundImage } from '../../assets/roundClose.svg';
import { colorFetch } from '../../styles/utils';

type WrapperProps = {
  color?: string;
  hoveredColor?: string;
};

const Wrapper = styled.div<WrapperProps>`
  width: 28px;
  height: 28px;

  display: flex;
  align-items: center;

  cursor: pointer;

  & > svg > path {
    stroke: ${colorFetch('primary')};
    transition: all 0.3s ease;
  }

  &:hover > svg > path {
    stroke: ${({ hoveredColor, theme }) => hoveredColor || colorFetch('primary')({ theme })};
  }
`;

export interface CloseButtonRoundProps {
  onClick: any;

  color?: string;
  hoveredColor?: string;
  className?: string;
}

export const CloseButtonRound: FC<CloseButtonRoundProps> = ({ onClick, color, hoveredColor, ...props }) => {
  return (
    <Wrapper color={color} hoveredColor={hoveredColor} onClick={onClick} {...props}>
      <CloseButtonRoundImage />
    </Wrapper>
  );
};
