import React from 'react';

export const DettachIcon = () => {
  return (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M12.9642 10.2866L14.7319 8.51879C15.1189 8.13188 15.5782 7.82496 16.0837 7.61557C16.5892 7.40617 17.131 7.2984 17.6782 7.2984C18.2254 7.2984 18.7672 7.40617 19.2727 7.61557C19.7783 7.82496 20.2376 8.13188 20.6245 8.51879C21.0114 8.9057 21.3183 9.36503 21.5277 9.87055C21.7371 10.3761 21.8449 10.9179 21.8449 11.4651C21.8449 12.0122 21.7371 12.5541 21.5277 13.0596C21.3183 13.5651 21.0114 14.0244 20.6245 14.4113L18.8567 16.1791M15.3212 19.7146L13.5534 21.4824C13.1665 21.8693 12.7072 22.1762 12.2017 22.3856C11.6961 22.595 11.1543 22.7028 10.6072 22.7028C9.50209 22.7028 8.44228 22.2638 7.66088 21.4824C6.87948 20.701 6.44049 19.6412 6.44049 18.5361C6.44049 17.4311 6.87948 16.3713 7.66088 15.5899L9.42864 13.8221"
          stroke="white"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.91626 8.77539L20.4163 21.2754"
          stroke="white"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" transform="translate(0 15.001) rotate(-45)" />
        </clipPath>
      </defs>
    </svg>
  );
};
