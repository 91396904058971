import React, { createContext, useContext, useReducer } from 'react';
import { reducer } from './reducer';
// @ts-ignore
import { isString, isUndefined } from 'underscore';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';

enum TypeDevice {
  ALL = 'ALL',
  NODE = 'NODE',
  TRACKER = 'TRACKER',
  OFFLINE = 'OFFLINE',
}

export enum TypeAttach {
  ATTACHED = 'ATTACHED',
  DETACHED = 'DETACHED',
}

export enum TypeEntity {
  PACKAGE = 'PACKAGE',
  INVENTORY = 'INVENTORY',
}

const FiltersContext = createContext<any | null>(null);

const getStatusFilter = (valueFilter: string[] | string, type: string) => {
  return !!valueFilter && valueFilter.length > 1 ? valueFilter.includes(type) : valueFilter === type;
};

const FiltersProvider = ({ children, queryFilter }: any) => {
  const languageStrings = useSelector(selectLanguageStrings);

  const { devices, attach, object_type, zones } = queryFilter;

  const countDevices = isUndefined(devices) ? 0 : isString(devices) ? 1 : devices?.length;
  const countAttach = isUndefined(attach) ? 0 : isString(attach) ? 1 : attach?.length;
  const countObjectType = isUndefined(object_type) ? 0 : isString(object_type) ? 1 : object_type?.length;

  const initialFilterState = {
    nodes: [],
    temporaryDeviceState: {},
    temporaryAttachState: {},
    temporaryInventoriesState: {},
    zonesState: {
      zones: [],
      isZones: getStatusFilter(zones, 'zone'),
    },
    deviceState: {
      checkedDevicesCount: countDevices,
      isOpenDeviceList: false,
      devicesFilter: {
        [TypeDevice.NODE]: {
          isSelected: getStatusFilter(devices, 'nodes'),
          name: 'Node',
          type: TypeDevice.NODE,
        },

        [TypeDevice.TRACKER]: {
          isSelected: getStatusFilter(devices, 'tracker'),
          name: 'Tracker',
          type: TypeDevice.TRACKER,
        },

        [TypeDevice.OFFLINE]: {
          isSelected: getStatusFilter(devices, 'offline'),
          name: 'Offline',
          type: TypeDevice.OFFLINE,
        },
      },
    },
    attachState: {
      checkedAttachCount: countAttach,
      isOpenAttachList: false,
      attachFilter: {
        [TypeAttach.ATTACHED]: {
          isSelected: getStatusFilter(attach, 'attached'),
          name: languageStrings.attached,
          type: TypeAttach.ATTACHED,
        },

        [TypeAttach.DETACHED]: {
          isSelected: getStatusFilter(attach, 'detached'),
          name: languageStrings.detached,
          type: TypeAttach.DETACHED,
        },
      },
    },

    entityState: {
      checkedEntityCount: countObjectType,
      isOpenEntityList: false,
      entityFilter: {
        [TypeEntity.PACKAGE]: {
          isSelected: getStatusFilter(object_type, 'packages'),
          name: languageStrings.packages,
          type: TypeEntity.PACKAGE,
        },

        [TypeEntity.INVENTORY]: {
          isSelected: getStatusFilter(object_type, 'inventories'),
          name: languageStrings.inventories,
          type: TypeEntity.INVENTORY,
        },
      },
    },

    inventoriesState: {
      checkedInventoriesCount: 0,
      isOpenInventoriesList: false,
      inventoriesFilter: [],
    },
  };

  const [state, dispatch] = useReducer(reducer, initialFilterState);
  const value = { state, dispatch };
  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};

const useFilters = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFilters must be used within the Filters Provider');
  }
  return context;
};

export { FiltersProvider, useFilters };
