import React, { FC, useState } from 'react';
// @ts-ignore
import { useTheme } from '@xstyled/styled-components';

import { ArrowButton, ArrowButtonType } from '../../../../../components/arrowButton/ArrowButton';
import { AllAdress } from './components/AllAdress';
import { AdressBlock, Arrow, ItemCount, ItemCountBlock, LevelsBlock, StyledText, Wrapper } from './style/styles';
import { AllLevels } from './components/AllLevels';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { useStyleContext } from '../../../../../styles/style.context';
import { SmallLoader } from '../../../../../features/smallLoader/SmallLoader';

export interface LocationForCollectionOrderProps {
  onClick?: () => void;
  locations: any[];
  setExplorerRequestBody: (value: any) => any;
  setIsLoadingImageMap: (value: any) => any;
  handleClearWebsocketData: () => any;
  activeAddress: any;
  setActiveAddress: any;
  activeLevel: any;
  setActiveLevel: any;
}

export const LocationDropDown: FC<LocationForCollectionOrderProps> = ({
  onClick,
  locations,
  setExplorerRequestBody,
  setIsLoadingImageMap,
  handleClearWebsocketData,
  activeAddress,
  setActiveAddress,
  activeLevel,
  setActiveLevel,
}) => {
  const [showAllAddresses, setShowAllAddresses] = useState(false);
  const [showAllLevels, setShowAllLevels] = useState(false);
  const [{ mediaType }] = useStyleContext();

  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);

  const { gray1, gray2, gray3, gray9, primary } = theme.colors;

  return (
    <Wrapper>
      <AdressBlock
        showAllLEvels={showAllLevels}
        showAllAddresses={showAllAddresses}
        onClick={() => {
          setShowAllAddresses((val) => !val);
          setShowAllLevels(false);
        }}
        primary={primary}
        gray3={gray3}
      >
        {locations ? (
          <>
            <StyledText gray1={locations?.[activeAddress] ? gray1 : gray2} primary={primary}>
              {locations?.[activeAddress]?.building?.name}
              <StyledText gray9={gray9} primary={primary} fontWeight={'400'}>
                {!mediaType.monitorS
                  ? locations?.[activeAddress]
                    ? `,${locations?.[activeAddress]?.building?.address?.city}
            ${locations?.[activeAddress]?.building?.address?.line1}
             ${locations?.[activeAddress]?.building?.address?.line2} `
                    : 'N/A'
                  : null}
              </StyledText>
            </StyledText>
            <ItemCountBlock>
              <ItemCount primary={primary} gray2={gray2}>
                {`${languageStrings.labelItems.toLowerCase()}: ${
                  locations?.[activeAddress]?.building?.items_count
                    ? locations?.[activeAddress]?.building?.items_count
                    : 'N/A'
                }`}
              </ItemCount>
              <Arrow
                onClick={() => {
                  setShowAllAddresses((val) => !val);
                  setShowAllLevels(false);
                }}
                isOpen={showAllAddresses}
              >
                <ArrowButton
                  color={gray3}
                  hoverColor={gray3}
                  type={ArrowButtonType.LEFT}
                  onClick={() => {
                    setShowAllAddresses((val) => !val);
                    setShowAllLevels(false);
                  }}
                />
              </Arrow>
            </ItemCountBlock>
          </>
        ) : (
          <SmallLoader style={{ width: '24px', height: '24px' }} />
        )}

        {showAllAddresses && (
          <AllAdress
            setActiveAdress={setActiveAddress}
            setActiveLevel={setActiveLevel}
            setShowAllAddresses={setShowAllAddresses}
            setShowAllLevels={setShowAllLevels}
            adressData={locations}
            activeItem={activeAddress}
            setExplorerRequestBody={setExplorerRequestBody}
            setIsLoadingImageMap={setIsLoadingImageMap}
            handleClearWebsocketData={handleClearWebsocketData}
          />
        )}
      </AdressBlock>

      <LevelsBlock
        showAllLEvels={showAllLevels}
        onClick={() => setShowAllLevels((val) => !val)}
        primary={primary}
        gray3={gray3}
      >
        {locations ? (
          <>
            <StyledText primary={primary} gray1={locations?.[activeAddress] ? gray1 : gray2}>
              {locations?.[activeAddress]
                ? languageStrings.labelLevel + ' ' + `${locations?.[activeAddress]?.floors?.[activeLevel]?.level}`
                : 'N/A'}
            </StyledText>
            <ItemCountBlock>
              <ItemCount primary={primary} gray2={gray2}>
                {locations?.[activeAddress]
                  ? languageStrings.labelItems.toLowerCase() +
                    ': ' +
                    locations?.[activeAddress]?.floors[activeLevel]?.items_count
                  : 'N/A'}
              </ItemCount>
              <Arrow onClick={() => setShowAllLevels((val) => !val)} isOpen={showAllLevels}>
                <ArrowButton
                  color={gray3}
                  hoverColor={gray3}
                  type={ArrowButtonType.LEFT}
                  onClick={() => setShowAllLevels((val) => !val)}
                />
              </Arrow>
            </ItemCountBlock>
          </>
        ) : (
          <SmallLoader style={{ width: '24px', height: '24px' }} />
        )}
        {showAllLevels && (
          <AllLevels
            setActiveLevel={setActiveLevel}
            levelsData={locations?.[activeAddress]?.floors}
            activeItem={activeLevel}
            setExplorerRequestBody={setExplorerRequestBody}
            setIsLoadingImageMap={setIsLoadingImageMap}
            handleClearWebsocketData={handleClearWebsocketData}
          />
        )}
      </LevelsBlock>
    </Wrapper>
  );
};
