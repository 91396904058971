import { TypeAttach, TypeEntity } from './сontext';

export const SELECT_ALL_DEVICE = 'ALL_DEVICE';
export const SELECT_ONE_DEVICE = 'SELECT_ONE_DEVICE';
export const HANDLE_DEVICE_LIST = 'HANDLE_DEVICE_LIST';
export const SELECT__TEMPORARY_DEVICE = 'SELECT__TEMPORARY_DEVICE';

export const SELECT_ALL_ATTACH = 'SELECT_ALL_ATTACH';
export const SELECT_ONE_ATTACH = 'SELECT_ONE_ATTACH';

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD_NODES':
      const dataNodes = action.payload;
      return {
        ...state,
        nodes: dataNodes ? action.payload?.buildings[0]?.floors[0]?.nodes : [],
      };

    case 'SELECT_ALL_DEVICE':
      const { isSelectAllDevice, isClearAll } = action.payload;

      const devicesFilter = state.deviceState.devicesFilter;
      Object.keys(devicesFilter).map(
        (key: any) => (devicesFilter[key] = { ...devicesFilter[key], isSelected: !isSelectAllDevice }),
      );

      const attachFilterDevice = state?.attachState?.attachFilter;
      Object.keys(attachFilterDevice).map(
        (key: any) => (attachFilterDevice[key] = { ...attachFilterDevice[key], isSelected: !isSelectAllDevice }),
      );

      const entityFilterDevice = state?.entityState?.entityFilter;
      Object.keys(entityFilterDevice).map(
        (key: any) => (entityFilterDevice[key] = { ...entityFilterDevice[key], isSelected: !isSelectAllDevice }),
      );

      const zonesState = isClearAll
        ? {
            zones: state.zonesState.zones,
            isZones: isSelectAllDevice ? false : true,
          }
        : { ...state.zonesState };

      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          checkedDevicesCount: isSelectAllDevice ? 0 : 3,
          devicesFilter: {
            ...devicesFilter,
          },
        },
        attachState: {
          ...state.attachState,
          checkedAttachCount: isSelectAllDevice ? 0 : 2,
          attachFilter: {
            ...attachFilterDevice,
          },
        },
        entityState: {
          ...state.entityState,
          checkedEntityCount: isSelectAllDevice ? 0 : 2,
          entityFilter: {
            ...entityFilterDevice,
          },
        },
        zonesState: { ...zonesState },
      };

    case 'SELECT_CLEAR_ALL':
      const devicesFilterClearAll = state.deviceState.devicesFilter;
      Object.keys(devicesFilterClearAll).map(
        (key: any) => (devicesFilterClearAll[key] = { ...devicesFilterClearAll[key], isSelected: false }),
      );

      const attachFilterClearAll = state?.attachState?.attachFilter;
      Object.keys(attachFilterClearAll).map(
        (key: any) => (attachFilterClearAll[key] = { ...attachFilterClearAll[key], isSelected: false }),
      );

      const entityFilterClearAll = state?.entityState?.entityFilter;
      Object.keys(entityFilterClearAll).map(
        (key: any) => (entityFilterClearAll[key] = { ...entityFilterClearAll[key], isSelected: false }),
      );

      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          checkedDevicesCount: 0,
          devicesFilter: {
            ...devicesFilterClearAll,
          },
        },
        attachState: {
          ...state.attachState,
          checkedAttachCount: 0,
          attachFilter: {
            ...attachFilterClearAll,
          },
        },
        entityState: {
          ...state.entityState,
          checkedEntityCount: 0,
          entityFilter: {
            ...entityFilterClearAll,
          },
        },
        zonesState: {
          zones: state.zonesState.zones,
          isZones: false,
        },
      };

    case 'SELECT_SHOW_ALL':
      const devicesFilterShowAll = state.deviceState.devicesFilter;
      Object.keys(devicesFilterShowAll).map(
        (key: any) => (devicesFilterShowAll[key] = { ...devicesFilterShowAll[key], isSelected: true }),
      );

      const attachFilterShowAll = state?.attachState?.attachFilter;
      Object.keys(attachFilterShowAll).map(
        (key: any) => (attachFilterShowAll[key] = { ...attachFilterShowAll[key], isSelected: true }),
      );

      const entityFilterShowAll = state?.entityState?.entityFilter;
      Object.keys(entityFilterShowAll).map(
        (key: any) => (entityFilterShowAll[key] = { ...entityFilterShowAll[key], isSelected: true }),
      );

      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          checkedDevicesCount: 3,
          devicesFilter: {
            ...devicesFilterShowAll,
          },
        },
        attachState: {
          ...state.attachState,
          checkedAttachCount: 3,
          attachFilter: {
            ...attachFilterShowAll,
          },
        },
        entityState: {
          ...state.entityState,
          checkedEntityCount: 3,
          entityFilter: {
            ...entityFilterShowAll,
          },
        },
        zonesState: {
          zones: state.zonesState.zones,
          isZones: true,
        },
      };

    case 'SELECT_ONE_DEVICE':
      const { type } = action.payload;

      const checkedDeviceState = state?.deviceState?.devicesFilter;
      let checkedCount = state?.deviceState?.checkedDevicesCount;

      const attachFilterState = state?.attachState?.attachFilter;

      Object.keys(attachFilterState).map(
        (key: any) =>
          (attachFilterState[key] = {
            ...attachFilterState[key],
            isSelected: !checkedDeviceState[type].isSelected ? true : attachFilterState[key].isSelected,
          }),
      );

      if (checkedDeviceState[type].isSelected) {
        checkedCount -= 1;
      } else {
        checkedCount += 1;
      }

      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          checkedDevicesCount: checkedCount,
          devicesFilter: {
            ...state.deviceState.devicesFilter,
            [type]: {
              ...state.deviceState.devicesFilter[type],
              isSelected: !state.deviceState.devicesFilter[type]?.isSelected,
            },
          },
        },
        attachState: {
          ...state.attachState,
          checkedAttachCount: !state.deviceState.devicesFilter[type].isSelected
            ? 2
            : state?.attachState?.checkedAttachCount,
        },
      };

    case 'HANDLE_DEVICE_LIST':
      return {
        ...state,
        deviceState: {
          ...state.deviceState,
          isOpenDeviceList: action.payload,
        },
      };

    case 'SELECT_ALL_ATTACH':
      const isSelectAllAttach = action.payload;
      const attachFilter = state.attachState.attachFilter;
      Object.keys(attachFilter).map(
        (key: any) => (attachFilter[key] = { ...attachFilter[key], isSelected: !isSelectAllAttach }),
      );
      return {
        ...state,
        attachState: {
          ...state.attachState,
          checkedAttachCount: isSelectAllAttach ? 0 : 2,
          attachFilter: {
            ...attachFilter,
          },
        },
      };

    case 'SELECT_ONE_ATTACH':
      const { typeAttach } = action.payload;
      const checkedAttachState = state?.attachState?.attachFilter;
      let checkedAttachCount = state?.attachState?.checkedAttachCount;

      if (checkedAttachState[typeAttach].isSelected) {
        checkedAttachCount -= 1;
      } else {
        checkedAttachCount += 1;
      }

      return {
        ...state,
        attachState: {
          ...state.attachState,
          checkedAttachCount: checkedAttachCount,
          attachFilter: {
            ...state.attachState.attachFilter,
            [typeAttach]: {
              ...state.attachState.attachFilter[typeAttach],
              isSelected: !state.attachState.attachFilter[typeAttach]?.isSelected,
            },
          },
        },
      };

    case 'HANDLE_ATTACH_LIST':
      return {
        ...state,
        attachState: {
          ...state.attachState,
          isOpenAttachList: action.payload,
        },
      };

    case 'SELECT_ALL_ENTITY':
      const isSelectAllEntity = action.payload;
      const entityFilter = state.entityState.entityFilter;
      Object.keys(entityFilter).map(
        (key: any) => (entityFilter[key] = { ...entityFilter[key], isSelected: !isSelectAllEntity }),
      );
      return {
        ...state,
        entityState: {
          ...state.entityState,
          checkedEntityCount: isSelectAllEntity ? 0 : 2,
          entityFilter: {
            ...entityFilter,
          },
        },
      };

    case 'SELECT_ONE_ENTITY':
      const typeEntity = action.payload;
      const checkedEntityState = state?.entityState?.entityFilter;
      let checkedEntityCount = state?.entityState?.checkedEntityCount;

      if (checkedEntityState[typeEntity].isSelected) {
        checkedEntityCount -= 1;
      } else {
        checkedEntityCount += 1;
      }

      return {
        ...state,
        entityState: {
          ...state.entityState,
          checkedEntityCount: checkedEntityCount,
          entityFilter: {
            ...state.entityState.entityFilter,
            [typeEntity]: {
              ...state.entityState.entityFilter[typeEntity],
              isSelected: !state.entityState.entityFilter[typeEntity]?.isSelected,
            },
          },
        },
      };

    case 'HANDLE_ENTITY_LIST':
      return {
        ...state,
        entityState: {
          ...state.entityState,
          isOpenEntityList: action.payload,
        },
      };

    case 'ADD_ZONES':
      const zones = action.payload;
      const isZones = action.payload.length > 0;
      return {
        ...state,
        zonesState: {
          zones: [...zones],
          isZones: isZones,
        },
      };

    case 'SELECT_ZONES':
      return {
        ...state,
        zonesState: {
          zones: state.zonesState.zones,
          isZones: !state.zonesState.isZones,
        },
      };

    case 'SELECT__TEMPORARY_DEVICE':
      return {
        ...state,
        temporaryDeviceState: { ...state.deviceState },
      };

    case 'SET_LANGUAGE':
      return {
        ...state,
        attachState: {
          ...state.attachState,
          attachFilter: {
            [TypeAttach.ATTACHED]: {
              ...state.attachState.attachFilter[TypeAttach.ATTACHED],
              name: action.payload.attached,
            },

            [TypeAttach.DETACHED]: {
              ...state.attachState.attachFilter[TypeAttach.DETACHED],
              name: action.payload.detached,
            },
          },
        },
        entityState: {
          ...state.entityState,
          entityFilter: {
            [TypeEntity.PACKAGE]: {
              ...state.entityState.entityFilter[TypeEntity.PACKAGE],
              name: action.payload.packages,
            },

            [TypeEntity.INVENTORY]: {
              ...state.entityState.entityFilter[TypeEntity.INVENTORY],
              name: action.payload.inventories,
            },
          },
        },
      };

    default:
      return state;
  }
};

export { reducer };
