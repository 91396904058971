import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from '../../../../assets/Search_placeholder_icon.svg';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as File } from '../../../../assets/file.svg';
import { useTheme } from '@cthings.co/styles';

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0 14px 0;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 14px 0;
`;

const FileIcon = styled(File)`
  margin-right: 6px;
`;

export const PopupPlaceholder = ({ languageStrings, isSearch }: { languageStrings?: any; isSearch?: boolean }) => {
  const theme = useTheme();
  const { gray3, gray2 } = theme.colors;

  return (
    <>
      {isSearch ? (
        <SearchWrapper>
          <SearchIcon />
          <Text type={TextType.TEXT_14_GRAY}>
            {languageStrings ? languageStrings.searchBarPlaceholderLabel : 'No results found'}
          </Text>
          <Text type={TextType.TEXT_10_GRAY} color={gray3} weight={'400'}>
            {languageStrings ? languageStrings.searchBarPlaceholderTerm : 'Please try searching with another term'}
          </Text>
        </SearchWrapper>
      ) : (
        <Wrapper>
          <FileIcon />
          <Text type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'}>
            {languageStrings ? languageStrings.labelNoItemsYet : 'No items yet'}
          </Text>
        </Wrapper>
      )}
    </>
  );
};
