import React from 'react';
import { StyledModalContainer, Title, Text, ButtonsContainer, YesButton, NoButton } from './styles';
import { Modal } from '../../../../../../../../../components/newModals/Modal';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../../../app/state/userSlice';
import { useTheme } from '@cthings.co/styles';

export const DeleteModal = ({
  isVisible,
  onYesClick,
  onNoClick,
}: {
  isVisible: boolean;
  onYesClick: () => void;
  onNoClick: () => void;
}) => {
  const theme = useTheme();
  const { pureWhite, black1, red2, red1, primary } = theme.colors;

  const language = useSelector(selectLanguageStrings);

  return (
    <Modal isOpenModal={isVisible}>
      <StyledModalContainer>
        <Title>{language ? language.confirmDelete : 'Are you sure?'}</Title>
        <Text>{language ? language.commentRemoving : 'The comment will be permanently deleted.'}</Text>
        <ButtonsContainer>
          <NoButton color={black1} backgroundColor={pureWhite} borderColor={primary} onClick={onNoClick}>
            {language ? language.labelNo : 'No'}
          </NoButton>
          <YesButton
            backgroundColor={red1}
            backgroundColorHover={red2}
            borderColor={red1}
            borderColorHover={red2}
            onClick={onYesClick}
          >
            {language ? language.labelYes : 'Yes'}
          </YesButton>
        </ButtonsContainer>
      </StyledModalContainer>
    </Modal>
  );
};
