import styled from 'styled-components';
import { media } from '../../styles/media';
import { borderRadiusFetch, colorFetch, shadowFetch } from '../../styles/utils';

export const ModalSearch = styled.div`
  position: absolute;
  z-index: 9999999999;
  bottom: -8px;
  left: 0;
  right: 0;
  transform: translateY(100%);
  background-color: ${colorFetch('pureWhite')};
  box-shadow: ${shadowFetch('additionalShadow4')};
  border-radius: ${borderRadiusFetch('primary')};
  min-height: 65px;
  max-height: 440px;
  padding: 20px 8px 20px 20px;
  box-sizing: border-box;
  ${media.tablet`
    max-height: 380px;
  `}
  ${media.phone`
    max-height: 342px;
  `}
`;
