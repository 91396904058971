/**
 * @description
 * This is component for rows of orders table
 * all the functionalities cthings require for orders table
 * good customisable.
 *
 */

import { Text, TextType } from '@bit/first-scope.text';
import styled from 'styled-components';
import React from 'react';
import { media } from '../../../../styles/media';
import { colorFetch } from '../../../../styles/utils';

type WrapperProps = {
  isFirst?: boolean;
  isLast?: boolean;
  gridTemplate?: string;
  gridTemplateDesktop?: string;
  gridTemplateTablet?: string;
  gridTemplatePhone?: string;
};

const Wrapper = styled.div<WrapperProps>`
  height: max-content;
  width: 100%;
  display: grid;
  padding: 13px 22px;
  box-sizing: border-box;
  background-color: ${colorFetch('pureWhite')};
  border-top: ${({ isFirst }) => (isFirst ? 'none' : '1px solid')};
  border-color: ${colorFetch('gray4')};
  border-radius: ${({ isLast }) => (isLast ? '0 0 8px 8px' : 'none')};
  grid-template-columns: ${({ gridTemplateDesktop }) => gridTemplateDesktop || '1.25fr 1.5fr'};
  grid-gap: 24px;
  ${media.tablet`
    grid-template-columns: ${({ gridTemplateTablet }: any) => gridTemplateTablet || '1.25fr 1.5fr'};
  `};
  ${media.phone`
    grid-template-columns: ${({ gridTemplatePhone }: any) => gridTemplatePhone || '1.25fr 1.5fr'};
    height: max-content;
  `};
`;

const StyledText = styled(Text)`
  word-break: break-word;
`;

export const TableRow = ({
  property,
  value,
  isFirst,
  isLast,
  gridTemplate,
  gridTemplateDesktop,
  gridTemplateTablet,
  gridTemplatePhone,
  ...props
}: {
  property: string;
  value: string;
  isFirst?: boolean;
  isLast?: boolean;
  gridTemplate?: string;
  gridTemplateDesktop?: string;
  gridTemplateTablet?: string;
  gridTemplatePhone?: string;
  className?: string;
}) => {
  return (
    <Wrapper
      isFirst={isFirst}
      isLast={isLast}
      gridTemplate={gridTemplate}
      gridTemplateDesktop={gridTemplateDesktop}
      gridTemplateTablet={gridTemplateTablet}
      gridTemplatePhone={gridTemplatePhone}
      {...props}
    >
      <Text type={TextType.TEXT_14_BLACK} weight={'400'}>
        {property}
      </Text>
      <StyledText type={TextType.TEXT_14_BLACK} weight={'500'}>
        {value || 'N/A'}
      </StyledText>
    </Wrapper>
  );
};
