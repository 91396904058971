import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../app/state/userSlice';

import { API_URL } from '../consts';
import { useLoaderOptions } from '../features/placeholderComponent/loaderFunctions';

import { HttpService } from '../utils/http';
import { usePrevious } from '../utils/usePrevious';

const baseProblems = {
  payload: {
    problems: [],
    map: {
      geopivoted: [],
      centre_coord: {
        lat: '',
        lng: '',
      },
      zoom_level_mapbox: -1,
    },
  },
  pagination: {
    total_items: 0,
  },
};

export const useProblems = (
  dimensions: any,
  page: number,
  pageSize: number,
  setPlaceholder?: any,
  stopPlaceholder?: any,
) => {
  const [data, setData] = useState(baseProblems);
  const language = useSelector(selectLanguage);
  const prevDimensions = usePrevious(dimensions);
  const prevPage = usePrevious(page);
  const dimensionsChanged = prevDimensions
    ? dimensions.width !== (prevDimensions as any).width && dimensions.height !== (prevDimensions as any).height
    : dimensions && !!dimensions.width;
  const offset = pageSize * (page - 1);
  const pageChanged = page !== prevPage;

  const { payload, pagination } = data;
  const trigger = [
    page,
    prevPage,
    pageChanged,
    pageSize,
    offset,
    dimensions,
    dimensionsChanged,
    language,
    prevDimensions,
  ] as const;
  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);
  useEffect(() => {
    if (dimensions && dimensions.width && dimensions.height && (dimensionsChanged || pageChanged)) {
      const url = `${API_URL}/v2/devices/aggregated/insight/problems?map_pwidth=${Math.floor(
        dimensions.width,
      )}&map_pheight=${Math.floor(dimensions.height)}&limit=${pageSize}&offset=${offset}`;
      (async () => {
        try {
          const responseData = (await HttpService.get(url, loaderOptions)).data;
          setData(responseData);
        } catch (e) {
          //dispatch(setUserType(UserType.PATIENT));
        }
      })();
    }
  }, [...trigger, loaderOptions]);

  return [payload, pagination] as const;
};
