/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for modal system of universal table require
 * Highly customisable.
 *
 */

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useTableContext, useTableFunctions } from '../../context';
import {
  ActionConfig,
  ActionType,
  ModalColumnCount,
  RowItemType,
  TableCustomComponentState,
  UpdateUserType,
} from '../../types';
import { DeletePopup } from '../../../../components/deletePopup/DeletePopup';
import { CommonButton } from '@bit/first-scope.common-button';
import { media, sizes } from '../../../../styles/media';
import { InputModal } from '../../../../components/inputModal/InputModal';
import { Select } from '../../../../components/select/Select';
import { usePrevious } from '../../../../utils/usePrevious';
import { ModalContainer } from '../../../../components/newModals/ModalContainer';
import { Modal } from '../../../../components/newModals/Modal';
import { getValFromObj, setValInObj } from '../../../../utils/objectDeconstruct';
import { MobilePortalContainer } from '../../../../components/mobilePortalContainer/MobilePortalContainer';
import { useBlockBodyScroll } from '../../../../utils/useBlockBodyScroll';
import { useNotifierFunctions } from '../../../notifier2';
import { CloseWhiteRoundButton } from '../../../../components/closeWhiteRoundButton/CloseWhiteRoundButton';
import { useWindowSize } from '../../../../styles/style.context';
import { ButtonIconType } from '../../../../components/commonButtonWithIcon/components/Icons';
import { CommonButtonWithIcon } from '../../../../components/commonButtonWithIcon/CommonButtonWithIcon';
import { useCustomHistory } from '../../../../utils/react-router-dom-abstraction';
import { View } from '../../../../routes/routeInterfaces';
import { colorFetch } from '../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';
import ls from '../../../../utils/ls';
import { isClasp, isTablet } from '../../../../consts';
import { use100vh } from 'react-div-100vh';

const StyledCloseWhiteRoundButton = styled(CloseWhiteRoundButton)`
  display: none;
  ${media.tablet`
    display: flex;
  `}
`;

const MobileSelectActionButton = styled(Text)`
  cursor: pointer;
  margin: 10px 16px;
  font-size: 16px;
  line-height: 24px;
` as typeof Text;

const MobileSelectActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const MobileCancelActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const MobileSelectActionBlock = styled.div`
  display: flex;
`;

const WrapperPreview = styled.a`
  text-decoration: none;
  cursor: pointer;
  width: 108px;
  ${media.tablet`
      width: 100%;
  `};
`;

type StyledButtonPreviewProps = {
  isBlocked: boolean;
};

// @TODO fix meddia types
const StyledButtonPreview = styled(CommonButton)<StyledButtonPreviewProps>`
  width: 108px;
  margin: 5px 0;
  height: auto;
  padding: 4px 0;
  line-height: 22px;
  pointer-events: ${({ isBlocked }) => (isBlocked ? 'none' : 'auto')};
  ${media.tablet`
      width: 100%;  
      height: 40px; 
      pointer-events: ${({ isBlocked }: any) => isBlocked && 'none'};
  `};
  &.preview {
    margin: 5px 0;
    ${media.tablet`
      margin: 0;
  `}
    & > div > svg > path {
      transition: 0.2s linear;
    }
  }
  &.preview:hover {
    background-color: ${colorFetch('primary')};
    color: ${colorFetch('pureWhite')};
    & > div > svg > path {
      stroke: ${colorFetch('pureWhite')};
    }
  }
  ${media.phone`
    &.preview {
      margin: 5px 0;
    }
  `};
` as any; // @TODO fix type

type StyledButtonProps = {
  isBlocked: boolean;
};

// @TODO fix media types
const StyledButton = styled(CommonButtonWithIcon)<StyledButtonProps>`
  width: 108px;
  margin: 5px 0;
  height: auto;
  padding: 4px 0;
  line-height: 22px;
  pointer-events: ${({ isBlocked }) => (isBlocked ? 'none' : 'auto')};
  ${media.tablet`
      width: 100%;  
      height: 40px; 
      pointer-events: ${({ isBlocked }: any) => isBlocked && 'none'};
  `};
  &.location {
    margin: 5px 0;
    padding: 4px 7px;
    & > div > svg > path {
      transition: 0.2s linear;
    }
  }
  &.location:hover {
    background-color: ${colorFetch('primary')};
    color: ${colorFetch('pureWhite')};
    & > div > svg > path {
      stroke: ${colorFetch('pureWhite')};
    }
  }
  ${media.phone`
    &.location {
      margin: 5px 0;
    }
  `};
`;

type StyledInputProps = {
  isWide: boolean;
};

const StyledInput = styled(InputModal)<StyledInputProps>`
  width: ${({ isWide }) => (isWide ? '480px' : '230px')};
  margin: 0 0 12px 0;

  ${media.tablet`
      width: 100%;   
  `};
`;

type StyledSelectProps = {
  isWide: boolean;
};

const StyledSelect = styled(Select)<StyledSelectProps>`
  width: ${({ isWide }) => (isWide ? '480px' : '230px')};
  margin: 0 0 12px 0;
  & > div {
    min-height: 40px;
  }
  ${media.tablet`
      width: 100%;   
  `};
`;

const getCustomBlockWidth = (modalColumns: number) => {
  const width = modalColumns * 230 + Math.max(0, modalColumns - 1) * 20;

  return width + 'px';
};

type CustomBlockProps = {
  modalColumns: number;
  disabled: boolean;
  isFocused?: boolean;
  isError: boolean;
  isShowMessage: boolean;
};

// @TODO fix media types
const CustomBlock = styled.div<CustomBlockProps>`
  display: flex;
  flex-direction: column;
  width: ${({ modalColumns }) => getCustomBlockWidth(modalColumns)};
  margin: 0 0 12px 0;
  &.custom-select-view {
    & > div > div {
      & > div.select {
        height: 40px;
        background-color: ${({ disabled, theme }) => (disabled ? colorFetch('gray5')({ theme }) : 'transparent')};
        border: 1px solid
          ${({ disabled, isFocused, isError, theme }) =>
            disabled
              ? colorFetch('gray5')({ theme })
              : isFocused
              ? colorFetch('primary')({ theme })
              : isError
              ? colorFetch('red1')({ theme })
              : colorFetch('gray3')({ theme })};
        & > svg {
          width: 26px;
          height: 26px;
          & > path {
            stroke: ${({ disabled, isFocused, isError, theme }) =>
              disabled
                ? colorFetch('gray5')({ theme })
                : isFocused
                ? colorFetch('primary')({ theme })
                : isError
                ? colorFetch('red1')({ theme })
                : colorFetch('gray2')({ theme })};
          }
        }
      }
      & > span {
        display: ${({ isShowMessage }) => (isShowMessage ? 'flex' : 'none')} !important;
      }
    }
  }

  ${media.tablet`
      width: 100%; 
      grid-area: ${({ modalColumns }: any) => (modalColumns > 1 ? 'auto/span 2' : '')};    
  `};
  ${media.phone`
      grid-area: auto; 
  `};
`;

type CustomInnerBlockProps = {
  disabled: boolean;
  isError?: boolean;
};

const CustomInnerBlock = styled.div<CustomInnerBlockProps>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${({ disabled, theme }) => (disabled ? colorFetch('gray5')({ theme }) : 'transparent')};
  padding: 0;
  transition: all 0.3s linear;

  & > div > div > div {
    border-color: ${({ isError, theme }: any) => isError && colorFetch('red3')({ theme })};
  }

  ${media.tablet`
    & > div {
      width: 100%;
    }
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet`
      width: 100%;   
  `};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ViewportSwitcher = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
`;

type BodyWrapperProps = {
  isScrollBodyModalSystem?: boolean;
  maxHeight?: number;
  bodyHeight?: number;
};

const BodyWrapper = styled.div<BodyWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  scrollbar-gutter: stable;
  margin: 0 -26px 0 -10px;
  max-height: ${({ maxHeight }: any) => `${maxHeight}px`};
  height: 100%;
  overflow-y: ${({ isScrollBodyModalSystem, bodyHeight, maxHeight }: any) =>
    isScrollBodyModalSystem || bodyHeight >= maxHeight ? 'auto' : 'none'};

  & > * {
    margin-right: 10px;
    margin-left: 10px;
    ${media.tablet`
      margin-right: 0;   
      margin-left: 0;
      `};
  }
  ${media.tablet`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    margin: 0;
    max-height: 520px;
    overflow-y: scroll;
   `};
  ${media.phone`
    max-height: 56vh;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  `};
  ${media.phoneS`
    max-height: 51vh;
  `};
  ${media.phoneXS`
    max-height: 49vh;
  `};
`;

const StyledModalContainer = styled(ModalContainer)`
  padding: 24px 26px 24px 24px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  ${media.tablet`
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%; 
  `};
  ${media.phone`
    flex-direction: column-reverse;
    gap: 0;
  `}
`;

const CustomButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${media.tablet`
    & > button {
      margin-right: 15px;
    }
  `};
  ${media.phone`
    & > button {
      margin-right: 0;
    }
  `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  min-height: 32px;
  width: 100%;
  ${media.tablet`
    grid-gap: 15px;
  `};
  ${media.phone`
    flex-direction: column-reverse; 
     grid-gap: 0;
  `};
`;
const DeletePopupContainer = styled(ModalContainer)`
  max-width: 384px;
  ${media.tablet`
    max-width: 100%;
  `}
`;

const Message = styled(Text)`
  width: 100%;
`;

export enum displayViewType {
  TABLET_VIEW = 'TABLET',
  DESKTOP_VIEW = 'DESKTOP',
}

export interface ModalSystemProps {
  actionSet: ActionConfig[];
  deleteItems: any;
  allowDelete: boolean;
  modalColumnCount: ModalColumnCount;
  updateItems: UpdateUserType;
  banAutoFocus?: boolean;
  resetIsAddModalFromGlobalSearch?: () => void;
  triggerUpdate?: () => void;
  keywordAddModalGlobalSearch: string | undefined;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  isPendingRequestFromModalApi?: boolean;
  triggerColumnStructure: () => void;
  allowPreview?: boolean;
  semiTitleDeleteModal?: string;
  languageStrings?: any;
  isLocationButton?: boolean;
  isRevertDataAfterErrorApi?: boolean;
  isScrollBodyModalSystem?: boolean;
}

export const ModalSystem: FC<ModalSystemProps> = ({
  actionSet,
  deleteItems,
  allowDelete,
  updateItems,
  modalColumnCount,
  banAutoFocus,
  resetIsAddModalFromGlobalSearch = () => {},
  triggerUpdate = () => {},
  keywordAddModalGlobalSearch,
  accessData,
  isPendingRequestFromModalApi,
  triggerColumnStructure,
  allowPreview,
  semiTitleDeleteModal,
  languageStrings,
  isLocationButton,
  isRevertDataAfterErrorApi,
  isScrollBodyModalSystem,
}) => {
  const refModalContainer = useRef<any>(null);
  const inputWidth = 230;
  const flexGap = 20;
  const outerPadding = 24;
  const width = (modalColumnCount - 1) * (inputWidth + flexGap) + inputWidth;
  const containerWidth = width + outerPadding * 2;
  const theme = useTheme();
  const widthView = useWindowSize()[0];
  const isMobileSize = widthView < sizes.phone;
  const { pureWhite, black1, gray1, red1, red3, primary } = theme.colors;
  const history = useCustomHistory();

  const isValmontTabletView = !isClasp && isTablet;
  const getDisplayView = ls.get('displayView');
  const fullHeight: any = use100vh();
  const heightOfModalContent = fullHeight - 160;

  const contentHeight = refModalContainer && refModalContainer?.current?.getBoundingClientRect().height;

  const {
    deleteItem,
    deleteCheckedItems,
    getSelectedItems,
    toggleView,
    getViewedData,
    setField,
    toggleEdit,
    toggleDelete,
    toggleAdd,
    updateLocalItem,
    setLocalDeleteIndex,
    getLocalDeleteName,
    getLocalDeleteId,
    createItem,
    uncheckAll,
    getModalItems,
    getRequiredData,
    toggleMobileSelectMode,
    setValidatingIsStarted,
    handleRedirectToPage,
    handleSingleValueChange,
  } = useTableFunctions();

  const arr = getModalItems();
  const requiredData = getRequiredData();
  const viewedData = getViewedData();
  const prevViewedData = usePrevious(viewedData);
  const [savedData, setSavedData] = useState(viewedData);
  const [blockButtons, setBlockButtons] = useState(false);
  const [displayView, setDisplayView] = useState(getDisplayView || displayViewType.DESKTOP_VIEW);
  const { addNotification } = useNotifierFunctions();

  const [
    {
      isViewInProcess,
      openIndex,
      isEditInProcess,
      isAddInProcess,
      openId,
      currentModalApiId,
      isDeleteInProcess,
      localDeleteIndex,
      mobileSelectMode,
      saveToggle,
    },
  ] = useTableContext();

  const markedArr = getSelectedItems();

  const nameList = markedArr.map(
    (item: any) => item.name || `${languageStrings ? languageStrings.labelId : 'id:'} ${item.id}`,
  );
  const idList = markedArr.map((item: any) => item.id);

  const validatedFields = arr.filter(
    (item: any) => !item.disabled && !item.excludedFromAdd && !item.excludedFromModalView,
  );

  const isAllFieldsValidated = validatedFields.length === validatedFields.filter((item: any) => !item.isError).length;
  const isLocalDeleteInProcess = localDeleteIndex !== -1;
  const localDeleteId = getLocalDeleteId();
  const disabled = !isEditInProcess && !isAddInProcess;

  const title = isEditInProcess
    ? languageStrings
      ? languageStrings.labelEdit
      : 'Edit'
    : isAddInProcess
    ? languageStrings
      ? languageStrings.labelAdd
      : 'Add'
    : languageStrings
    ? languageStrings.labelDetails
    : 'Details';

  const localDeleteName = getLocalDeleteName();

  const customComponentState =
    isEditInProcess || isAddInProcess ? TableCustomComponentState.EDIT : TableCustomComponentState.VIEW_MODAL;

  const handleGlobalDelete = useCallback(() => {
    const deleteItemRow = () => {
      deleteItems && deleteItems([localDeleteId], deleteItem, deleteCheckedItems, addNotification, languageStrings);
      isViewInProcess && toggleView(null, null);
      setLocalDeleteIndex(-1);
    };
    const deleteMultipleRows = () => {
      deleteItems && deleteItems(idList, deleteItem, deleteCheckedItems, addNotification, languageStrings);
    };
    isDeleteInProcess ? deleteMultipleRows() : deleteItemRow();
    isDeleteInProcess && toggleDelete();
  }, [
    isDeleteInProcess,
    deleteCheckedItems,
    deleteItem,
    isViewInProcess,
    toggleView,
    deleteItems,
    idList,
    toggleDelete,
    setLocalDeleteIndex,
    localDeleteId,
  ]);

  const handleCallback = (callback: any) => {
    callback && callback(viewedData);
  };

  const handleInputChange = (e: any, fieldName: any) => {
    setField(openIndex, e.target.value, fieldName);
  };

  const handleLocalUpdate = useCallback(
    (index: number, value: any) => {
      updateLocalItem(index, value);
      toggleEdit();
    },
    [updateLocalItem, toggleEdit],
  );

  const handleLocalCreate = useCallback(
    (id: string, value: any) => {
      createItem(id, value);
      toggleAdd();
    },
    [createItem, toggleAdd],
  );

  const handleSave = useCallback(() => {
    setValidatingIsStarted(true);
    if (openId && updateItems) {
      if (isAllFieldsValidated) {
        // const setSavedData = isRevertDataAfterErrorApi ? setSavedData : () => {};
        // addNotification({ message: 'Saved successfully', type: 'success' });
        updateItems(
          openId,
          requiredData,
          openIndex,
          !isAddInProcess ? handleLocalUpdate : handleLocalCreate,
          addNotification,
          languageStrings,
          setBlockButtons,
          isRevertDataAfterErrorApi ? setSavedData : () => {},
          isRevertDataAfterErrorApi ? viewedData : null,
        );
        setValidatingIsStarted(false);
      }
    }
  }, [openId, requiredData, openIndex, handleLocalUpdate, updateItems, handleLocalCreate, isAddInProcess]);

  const handleRevert = useCallback(() => {
    if (!isAddInProcess) {
      setTimeout(() => {
        handleLocalUpdate(openIndex, savedData);
      }, 10);
    } else {
      setTimeout(() => {
        deleteItem(openId);
        toggleAdd();
      }, 10);
    }
  }, [openIndex, savedData, handleLocalUpdate, isAddInProcess, deleteItem, openId, toggleAdd]);

  const handleDelete = () => {
    setLocalDeleteIndex(openIndex);
  };

  const handleCloseDeleteModal = useCallback(() => {
    isLocalDeleteInProcess && setLocalDeleteIndex(-1);
    isDeleteInProcess && toggleDelete();
  }, [isLocalDeleteInProcess, isDeleteInProcess, toggleDelete, setLocalDeleteIndex]);

  // @TODO Alex work on handleSetValue
  const handleSetValue = useCallback(
    (value: any, fieldName: any) => {
      const deconstructObject: any = (fieldName: any, value: any, path: string[] = []) => {
        const isObj = typeof fieldName === 'object' && fieldName !== null;
        if (isObj) {
          return Object.keys(fieldName).reduce((accumulator: any[], newKey: any) => {
            return [...accumulator, ...deconstructObject(fieldName[newKey], value && value[newKey], [...path, newKey])];
          }, []);
        } else {
          return [{ fieldName, value: value || null, path }];
        }
      };
      const mapping = deconstructObject(fieldName, value);
      mapping.forEach((mappingItem: any) => {
        setField(openIndex, (prevValue: any) => {
          const modifiedValue = setValInObj(mappingItem.path, prevValue, mappingItem.value);
          return modifiedValue;
        });
      });
    },
    [openIndex, setField],
  );

  const handleSelectChange = (value: any, fieldName: any, onlyIdForOnChangeSelect?: boolean) => {
    if (onlyIdForOnChangeSelect) {
      setField(openIndex, value.id, fieldName.id);
    } else {
      setField(openIndex, value.id, fieldName.id);
      setField(openIndex, value.name, fieldName.name);
    }
  };
  const handleJoinedSelectChange = (value: any, fieldName: any) => {
    setField(openIndex, value, fieldName);
  };
  const handleCancel = () => {
    setTimeout(() => {
      uncheckAll();
      toggleMobileSelectMode();
    }, 10);
  };

  const handleRedirectPreviewDocuments = () => {
    handleRedirectToPage(history, View, viewedData?.id);
  };

  const handleRedirectToFloorMap = () => {
    const buildingId = viewedData?.tracker?.location?.wpas_ref_building_id;
    return buildingId
      ? handleRedirectToPage({
          view: View.USER_INSIGHTS_DETAILS,
          pathParams: {
            id: buildingId,
            name: viewedData?.tracker?.name,
          },
        })
      : null;
  };

  const changeDisplayViewHandle = useCallback(() => {
    if (displayView === displayViewType.DESKTOP_VIEW) {
      setDisplayView(displayViewType.TABLET_VIEW);
      ls.set('displayView', displayViewType.TABLET_VIEW);
    } else {
      setDisplayView(displayViewType.DESKTOP_VIEW);
      ls.set('displayView', displayViewType.DESKTOP_VIEW);
    }
  }, [displayView]);

  useEffect(() => {
    !prevViewedData && viewedData && setSavedData(viewedData);
  }, [viewedData, prevViewedData]);

  useEffect(() => {
    currentModalApiId && currentModalApiId === openId && setSavedData(viewedData);
  }, [currentModalApiId]);

  useEffect(() => {
    setBlockButtons(!isViewInProcess && !isAddInProcess);
  }, [isViewInProcess, isAddInProcess]);

  useEffect(() => {
    !!getDisplayView && setDisplayView(getDisplayView);
  }, [getDisplayView]);

  useEffect(() => {
    if (!isValmontTabletView) {
      ls.remove('displayView');
      setDisplayView(displayViewType.DESKTOP_VIEW);
    }
  }, [isValmontTabletView]);

  //will block body scroll when modal is open
  useBlockBodyScroll(isViewInProcess || isAddInProcess);

  return (
    <>
      <Modal isOpenModal={isViewInProcess || isAddInProcess} displayView={displayView}>
        <StyledModalContainer width={`${containerWidth}px`} fullHeight={fullHeight} padding={'24px 16px 30px 16px'}>
          <StyledCloseWhiteRoundButton
            onClick={() => {
              if (isAddInProcess) {
                handleRevert();
                setValidatingIsStarted(false);
              } else if (isEditInProcess) {
                handleRevert();
                setValidatingIsStarted(false);
                toggleView(null, null);
              } else {
                toggleView(null, null);
                setValidatingIsStarted(false);
              }
            }}
          />
          <Wrapper>
            <TitleWrapper>
              <Text type={TextType.TITLE_H3} color={black1} margin="0 0 14px 0">
                {title}
              </Text>
              {isValmontTabletView && (
                //@ts-ignore
                <ViewportSwitcher onClick={changeDisplayViewHandle} type={TextType.TEXT_14_GRAY} color={primary}>
                  {displayView === displayViewType.DESKTOP_VIEW
                    ? languageStrings.tabletView
                    : languageStrings.desktopView}
                </ViewportSwitcher>
              )}
            </TitleWrapper>
            <BodyWrapper
              ref={refModalContainer}
              isScrollBodyModalSystem={isScrollBodyModalSystem}
              maxHeight={heightOfModalContent}
              bodyHeight={contentHeight + 20}
            >
              {arr
                .filter((elem: any) => {
                  return (
                    (!isAddInProcess && !elem.excludedFromModalView) ||
                    ((!!elem.showDisabledInAdd || !elem.disabled) && !elem.excludedFromAdd)
                  );
                })
                .map((item: any, index: any) => {
                  const {
                    value,
                    fieldName,
                    label,
                    placeholder,
                    type,
                    selectItems,
                    component,
                    getTextDisplay,
                    modalColumns,
                    pathToSelectItems,
                    defaultValue,
                    disabled: isSingleDisabled,
                    rows,
                    selectItemsModifier,
                    isError,
                    tooltipText,
                    validatingIsStarted,
                    customComponentHasSelectView,
                    excludedTitleFromMobileViewEmptyData,
                    defaultSelectValue,
                    updateItem,
                    createItem,
                    deleteItem,
                    isUnassigned,
                    onlyIdForOnChangeSelect,
                    isCreatePlaceholderSearchBar,
                    helperTextData,
                  } = item;

                  let selectVal = value || defaultValue;
                  let selectList = selectItems;
                  if (
                    type === RowItemType.SELECT ||
                    type === RowItemType.JOINED_SELECT ||
                    type === RowItemType.CUSTOM
                  ) {
                    selectList =
                      selectItems ||
                      (pathToSelectItems && pathToSelectItems.length > 0
                        ? getValFromObj(pathToSelectItems, viewedData) || []
                        : []);

                    if (selectItemsModifier) {
                      selectList = selectItemsModifier(selectList);
                    }

                    const isIncluded = selectList.findIndex((val: any) => val.id === selectVal.id) !== -1;

                    selectList = isIncluded
                      ? selectList
                      : // isUnassigned value used for detach functionality in edit inventory modal
                      isUnassigned
                      ? [...selectList, selectVal]
                      : [selectVal, ...selectList];
                  }

                  const isEmptyValue = Object.keys(value ? value : {}).length === 0;
                  //is hide title component for View_Modal type(details view)in mobile version
                  const isHideViewModalMobile =
                    customComponentState === TableCustomComponentState.VIEW_MODAL &&
                    isMobileSize &&
                    // prop from column structure
                    excludedTitleFromMobileViewEmptyData;

                  return !type ||
                    type === RowItemType.INPUT ||
                    type === RowItemType.INPUT_NUMBER ||
                    type === RowItemType.INPUT_PHONE_NUMBER ||
                    type === RowItemType.INPUT_EMAIL ||
                    type === RowItemType.INPUT_CUSTOM ? (
                    <StyledInput
                      key={index}
                      type={type === RowItemType.INPUT_NUMBER ? 'number' : 'text'}
                      name={label}
                      value={getTextDisplay ? getTextDisplay(value) : value}
                      disabled={disabled || isSingleDisabled}
                      placeholder={isViewInProcess && !isEditInProcess && !value ? 'N/A' : placeholder}
                      onChange={(e: any) => handleInputChange(e, fieldName)}
                      isWide={modalColumns === 2}
                      rows={rows}
                      startedValidation={validatingIsStarted}
                      isError={isError && (isEditInProcess || isAddInProcess)}
                      isTooltip
                      tooltipText={tooltipText}
                      helperTextData={helperTextData}
                    />
                  ) : type === RowItemType.SELECT ? (
                    <StyledSelect
                      value={selectVal}
                      label={label}
                      disabled={disabled || isSingleDisabled}
                      values={selectList}
                      onChange={(value: any) => handleSelectChange(value, fieldName, onlyIdForOnChangeSelect)}
                      isWide={modalColumns === 2}
                      placeholder={isEditInProcess || isAddInProcess ? placeholder : 'N/A'}
                      banAutoFocus={banAutoFocus}
                      startedValidation={validatingIsStarted}
                      isError={isError && (isEditInProcess || isAddInProcess)}
                      isPendingRequest={isPendingRequestFromModalApi}
                      updateItem={updateItem}
                      createItem={createItem}
                      deleteItem={deleteItem}
                      triggerColumnStructure={triggerColumnStructure}
                      helperTextData={helperTextData}
                    />
                  ) : type === RowItemType.JOINED_SELECT ? (
                    <StyledSelect
                      value={getTextDisplay ? getTextDisplay(value) : value}
                      label={label}
                      disabled={disabled || isSingleDisabled}
                      values={selectList}
                      onChange={(value: any) => handleJoinedSelectChange(value, fieldName)}
                      isWide={modalColumns === 2}
                      placeholder={isEditInProcess || isAddInProcess ? placeholder : 'N/A'}
                      banAutoFocus={banAutoFocus}
                      startedValidation={validatingIsStarted}
                      isError={isError && (isEditInProcess || isAddInProcess)}
                      isPendingRequest={isPendingRequestFromModalApi}
                      defaultSelectValue={defaultSelectValue}
                      updateItem={updateItem}
                      createItem={createItem}
                      deleteItem={deleteItem}
                      triggerColumnStructure={triggerColumnStructure}
                      isCreatePlaceholderSearchBar={isCreatePlaceholderSearchBar}
                      helperTextData={helperTextData}
                    />
                  ) : type === RowItemType.CUSTOM ? (
                    <CustomBlock
                      modalColumns={modalColumns}
                      className={customComponentHasSelectView ? 'custom-select-view' : ''}
                      isError={
                        (customComponentHasSelectView ? isError && validatingIsStarted : isError) &&
                        (isEditInProcess || isAddInProcess)
                      }
                      disabled={disabled || isSingleDisabled}
                      isShowMessage={validatingIsStarted && isError && !disabled}
                    >
                      {isHideViewModalMobile && isEmptyValue ? null : (
                        <Text type={TextType.TEXT_12_BLACK} color={gray1} margin="0 0 4px 6px">
                          {label}
                        </Text>
                      )}
                      <CustomInnerBlock
                        disabled={disabled || isSingleDisabled}
                        isError={validatingIsStarted && isError && !disabled}
                      >
                        {isHideViewModalMobile && isEmptyValue
                          ? null
                          : component &&
                            component(
                              value,
                              customComponentState,
                              (val: any) => handleSingleValueChange(val, fieldName, openIndex),
                              selectList,
                              saveToggle,
                            )}
                      </CustomInnerBlock>
                      {validatingIsStarted && isError && !disabled && (
                        <Message type={TextType.TEXT_12_GRAY} color={red3} margin={'4px 0 0 12px'}>
                          {`${label} ${languageStrings.inputErrorText}`}
                        </Message>
                      )}
                    </CustomBlock>
                  ) : null;
                })}
            </BodyWrapper>

            <ButtonWrapper>
              {!isEditInProcess && !isAddInProcess ? (
                <>
                  <StyledButton
                    borderColor={primary}
                    borderColorHover={primary}
                    onClick={() => {
                      toggleView(null, null);
                      setValidatingIsStarted(false);
                    }}
                    isBlocked={blockButtons}
                  >
                    {languageStrings ? languageStrings.closeButton : 'Close'}
                  </StyledButton>

                  <ActionsWrapper>
                    {actionSet.map((item) => {
                      return item.type === ActionType.EDIT && accessData?.edit ? (
                        <StyledButton
                          onClick={() => {
                            setSavedData(viewedData);
                            toggleEdit();
                          }}
                          backgroundColor={pureWhite}
                          borderColor={primary}
                          backgroundColorHover={primary}
                          borderColorHover={primary}
                          color={black1}
                          isBlocked={blockButtons}
                        >
                          {languageStrings ? languageStrings.editButton : 'Edit'}
                        </StyledButton>
                      ) : !!item.component ? (
                        <CustomButtonWrapper
                          onClick={() => {
                            handleCallback(item.callback);
                          }}
                        >
                          {item.component}
                        </CustomButtonWrapper>
                      ) : null;
                    })}

                    {allowDelete && accessData?.delete && (
                      <StyledButton
                        onClick={handleDelete}
                        backgroundColor={pureWhite}
                        borderColor={red1}
                        backgroundColorHover={red1}
                        borderColorHover={red1}
                        color={black1}
                        isBlocked={blockButtons}
                      >
                        {languageStrings ? languageStrings.deleteButton : 'Delete'}
                      </StyledButton>
                    )}

                    {allowPreview && (
                      // @TODO Need improve redirect for PREVIEW button (was need for backend)
                      <WrapperPreview target={'_blank'} rel={'noopener noreferrer'} href={viewedData?.preview_url}>
                        <StyledButtonPreview
                          backgroundColor={pureWhite}
                          borderColor={primary}
                          hoverColor={pureWhite}
                          color={black1}
                          isBlocked={blockButtons}
                          iconType={ButtonIconType.PREVIEW}
                          className={'preview'}
                        >
                          {languageStrings ? languageStrings.externalPreview : 'Preview'}
                        </StyledButtonPreview>
                      </WrapperPreview>
                    )}

                    {isLocationButton && viewedData?.tracker && (
                      <StyledButton
                        onClick={handleRedirectToFloorMap}
                        backgroundColor={pureWhite}
                        borderColor={primary}
                        hoverColor={pureWhite}
                        color={black1}
                        isBlocked={blockButtons}
                        iconType={ButtonIconType.LOCATION}
                        className={'location'}
                      >
                        {languageStrings ? languageStrings.location : 'Location'}
                      </StyledButton>
                    )}
                  </ActionsWrapper>
                </>
              ) : (
                <>
                  <ActionsWrapper>
                    <StyledButton
                      onClick={() => {
                        handleRevert();
                        setValidatingIsStarted(false);
                        keywordAddModalGlobalSearch && resetIsAddModalFromGlobalSearch();
                      }}
                      backgroundColor={pureWhite}
                      borderColor={primary}
                      backgroundColorHover={primary}
                      borderColorHover={primary}
                      color={black1}
                      isBlocked={blockButtons}
                    >
                      {languageStrings ? languageStrings.cancelButton : 'Cancel'}
                    </StyledButton>

                    <StyledButton
                      borderColor={primary}
                      borderColorHover={primary}
                      onClick={() => {
                        handleSave();
                        !isRevertDataAfterErrorApi && setSavedData(viewedData);
                        keywordAddModalGlobalSearch && resetIsAddModalFromGlobalSearch();
                        triggerUpdate && triggerUpdate();
                      }}
                      isBlocked={blockButtons}
                      disabled={blockButtons}
                    >
                      {languageStrings ? languageStrings.saveButton : 'Save'}
                    </StyledButton>
                  </ActionsWrapper>
                </>
              )}
            </ButtonWrapper>
          </Wrapper>
        </StyledModalContainer>
      </Modal>

      <Modal isOpenModal={isLocalDeleteInProcess || isDeleteInProcess}>
        {/** @TODO fix the fullHeight for delete popup */}
        <DeletePopupContainer padding="24px">
          <DeletePopup
            title={languageStrings ? languageStrings.confirmDelete : 'Are you sure?'}
            semiTitle={
              semiTitleDeleteModal
                ? semiTitleDeleteModal
                : languageStrings
                ? languageStrings.entitiesDeleted
                : 'This entities will be permanently deleted:'
            }
            isOpenModal
            deletedElementsList={semiTitleDeleteModal ? '' : !isDeleteInProcess ? [localDeleteName] : nameList}
            handleOnClose={handleCloseDeleteModal}
            handleOnDelete={handleGlobalDelete}
          />
        </DeletePopupContainer>
      </Modal>

      {markedArr.length > 0 && !isDeleteInProcess && (
        <MobilePortalContainer zIndex={'999999999'}>
          <MobileSelectActionWrapper>
            <MobileSelectActionBlock onClick={toggleDelete}>
              <MobileSelectActionButton type={TextType.TEXT_14_RED}>
                {languageStrings ? languageStrings.deleteButton : 'Delete'}
              </MobileSelectActionButton>
            </MobileSelectActionBlock>
            <MobileSelectActionBlock onClick={handleCancel}>
              <MobileSelectActionButton type={TextType.TEXT_14_GRAY}>
                {languageStrings ? languageStrings.cancelButton : 'Cancel'}
              </MobileSelectActionButton>
            </MobileSelectActionBlock>
          </MobileSelectActionWrapper>
        </MobilePortalContainer>
      )}

      {markedArr.length === 0 && !isDeleteInProcess && mobileSelectMode && (
        <MobilePortalContainer zIndex={'999999999'}>
          <MobileCancelActionWrapper>
            <MobileSelectActionBlock onClick={handleCancel}>
              <MobileSelectActionButton type={TextType.TEXT_14_GRAY}>
                {languageStrings ? languageStrings.cancelButton : 'Cancel'}
              </MobileSelectActionButton>
            </MobileSelectActionBlock>
          </MobileCancelActionWrapper>
        </MobilePortalContainer>
      )}
    </>
  );
};
