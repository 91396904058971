import styled from 'styled-components';
import { colorFetch } from '../../../../../../../../../styles/utils';
import { CommentDataProps, CommentTextProps } from './types';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 0;
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray4')};
`;

export const CommentBlock = styled.div``;

export const CommentText = styled.div<CommentTextProps>`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${({ editMode, theme }) => (editMode ? colorFetch('primary')({ theme }) : colorFetch('gray1')({ theme }))};
  padding-right: 10px;
  transition: all 0.3s ease;
`;

export const CommentDataBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
`;

export const CommentData = styled.div<CommentDataProps>`
  font-family: 'Poppins';
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  font-size: 12px;
  line-height: 18px;
  color: ${({ editMode, theme }) => (editMode ? colorFetch('primary')({ theme }) : colorFetch('gray2')({ theme }))};
  transition: all 0.3s ease;
`;

export const Line = styled.div`
  height: 10px;
  width: 1px;
  background-color: ${colorFetch('gray3')};
  margin: 0 9px;
`;
