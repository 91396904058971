import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../app/state/userSlice';
import { NEW_ID } from '../consts';
import { API_URL } from '../consts';
import { AnyObject } from '../features/apiBuilder/types';
import { generateApi, useApi } from '../features/apiBuilder/useApiBuilder';
import { ErrorEventType } from '../types/error';
import { ApiCallType, HttpService } from '../utils/http';
import { QueryKeys } from './types';
import { handleUncoveredNotification } from '../utils/handleUncoveredNotification';

export const useGetTypes = () => {
  const url = `${API_URL}/v1/inventory_types/`;

  const [data, setData] = useApi({
    url,
    defaultValue: [] as AnyObject[],
    handleSuccess: (value) => setData(value as Object[]),
    transformResponse: (response) => response.payload.map((item: any) => ({ name: item.name, id: item.id })),
    keywords: [QueryKeys.GET_INVENTORY_TYPES],
  });

  return [data, setData];
};

export const updateInventories = (
  id: string,
  data: any,
  index: number,
  updateLocalItem: any,
  handleAddNotification: Function,
  language: any,
  setBlockButtons: (status: boolean) => void,
) => {
  if (id !== NEW_ID) {
    setBlockButtons(true);
    const url = `${API_URL}/v1/inventories/${id}`;
    (async () => {
      try {
        data.inventory_type_id = data?.inventory_type?.id;
        data.tracker_id = data?.tracker?.id === '' ? null : data?.tracker?.id;
        data.tracker = !data?.tracker ? null : Object.values(data?.tracker).includes('') ? null : data?.tracker;

        let responseData = (await HttpService.patch(url, data)).data;
        updateLocalItem(index, responseData.payload);
        handleAddNotification({
          message: language ? language.savedSuccessfullyNotification : 'Saved successfully',
          type: 'success',
        });
        setBlockButtons(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleUncoveredNotification(error, handleAddNotification, language);
        setBlockButtons(false);
      }
    })();
  } else {
    setBlockButtons(true);
    const url = `${API_URL}/v1/inventories/`;
    (async () => {
      try {
        data.inventory_type_id = data?.inventory_type?.id;
        data.tracker_id = data?.tracker?.id === '' ? null : data?.tracker?.id;
        data.tracker = Object.values(data?.tracker).includes('') ? null : data?.tracker;
        let responseData = (await HttpService.post(url, data)).data;
        updateLocalItem(responseData.payload.id, responseData.payload);
        handleAddNotification({
          message: language ? language.savedSuccessfullyNotification : 'Saved successfully',
          type: 'success',
        });
        setBlockButtons(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleUncoveredNotification(error, handleAddNotification, language);
        setBlockButtons(false);
      }
    })();
  }
};

export const deleteInventoriesGlobal = (
  id: string[],
  deleteInventories: any,
  deleteCheckedInventories: any,
  handleAddNotification: Function,
  language: any,
) => {
  const data = { inventories_ids: id };
  const url = `${API_URL}/v1/inventories/delete/`;
  const deleteOneInventories = () => deleteInventories(id[0]);
  (async () => {
    try {
      await HttpService.delete(url, data);
      if (id.length === 1) {
        deleteOneInventories();
      } else {
        deleteCheckedInventories();
      }
      handleAddNotification({
        message: language ? language.deletedSuccessfullyNotification : 'Deleted successfully',
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      handleUncoveredNotification(error, handleAddNotification, language);
    }
  })();
};

export const createInventoriesTypeFunctions = () => {
  const updateInventoriesType = ({
    body,
    handleSuccess,
    handleError,
  }: {
    body: { id: string; name: string };
    handleSuccess?: (data: any) => void;
    handleError?: (data: any) => void;
  }) => {
    const { id, ...remainingBody } = body;
    const url = `${API_URL}/v1/inventory_types/${id}`;
    return generateApi({ url: url, callType: ApiCallType.PATCH })({ handleSuccess, handleError, body: remainingBody });
  };

  const createInventoriesType = ({
    body,
    handleSuccess,
    handleError,
  }: {
    body: { name: string };
    handleSuccess?: (data: any) => void;
    handleError?: (data: any) => void;
  }) => {
    const url = `${API_URL}/v1/inventory_types/`;
    return generateApi({ url, callType: ApiCallType.POST })({ handleSuccess, handleError, body });
  };

  const deleteInventoriesType = ({
    body,
    handleSuccess,
    handleError,
  }: {
    body: { id: string };
    handleSuccess?: (data: any) => void;
    handleError?: (data: any) => void;
  }) => {
    const { id } = body;
    const url = `${API_URL}/v1/inventory_types/${id}`;
    return generateApi({ url, callType: ApiCallType.DELETE })({ handleSuccess, handleError });
  };

  return { updateInventoriesType, createInventoriesType, deleteInventoriesType };
};

export const deleteInventoriesType = (
  id: string[],
  deleteInventories: any,
  deleteCheckedInventories: any,
  handleAddNotification: Function,
  language: any,
) => {
  const data = { inventories_ids: id };
  const url = `${API_URL}/v1/inventory_types/${id[0]}`;
  const deleteOneInventories = () => deleteInventories(id[0]);
  (async () => {
    try {
      await HttpService.delete(url, data);
      if (id.length === 1) {
        deleteOneInventories();
      } else {
        deleteCheckedInventories();
      }
      handleAddNotification({
        message: language ? language.deletedSuccessfullyNotification : 'Deleted successfully',
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      handleUncoveredNotification(error, handleAddNotification, language);
    }
  })();
};

export const useGetDeviceUnattached = () => {
  const [unattachedDevice, setUnattachedDevice] = useState([] as any[]);
  const language = useSelector(selectLanguageStrings);
  // It is id for unassigned device, for unassigned functionality in edit modals
  // const idForUnassign = '62baea9d388a5efe84e73338';
  useEffect(() => {
    const url = `${API_URL}/v1/devices/unattached?limit=0`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { payload } = responseData;
        const result = payload.map((item: any) => ({ name: item.name, id: item.id }));
        // const resultWithUnassigned = [
        //   { name: language ? language.lableUnassigned : 'Unassigned', id: idForUnassign },
        //   ...result,
        // ];
        // setUnattachedDevice(resultWithUnassigned);
        setUnattachedDevice(result);
      } catch (e) {
        //
      }
    })();
  }, [language]);
  return unattachedDevice;
};

export const setInventoriesAttach = (
  inventoryId: string,
  data: object,
  setIsLoader?: any,
  setIsAttach?: any,
  addNotification?: any,
  language?: any,
) => {
  const url = `${API_URL}/v1/devices/${inventoryId}/attach`;
  (async () => {
    try {
      await HttpService.patch(url, data);
      setIsLoader(false);
      addNotification({
        message: language ? language.savedAttachedNotification : `Successfully attached`,
        type: 'success',
      });
      setIsAttach(true);
    } catch (e) {
      const error = e as ErrorEventType;
      setIsLoader(false);
      handleUncoveredNotification(error, addNotification, language);
    }
  })();
};

export const useGetInventories = () => {
  const [inventoriesList, setInventoriesList] = useState([]);
  useEffect(() => {
    const url = `${API_URL}/v1/inventories/`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { payload } = responseData;
        const result = payload.filter((inventory: any) => !inventory?.currently_attached);
        setInventoriesList(result);
      } catch (e) {
        //
      }
    })();
  }, []);
  return inventoriesList;
};
