import React from 'react';

export const SiteIcon = ({ color }: { color: string }) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.3639 22.3638C42.3639 35.0911 26.0001 46.0003 26.0001 46.0003C26.0001 46.0003 9.63635 35.0911 9.63635 22.3638C9.63635 18.0238 11.3604 13.8616 14.4292 10.7928C17.498 7.72403 21.6602 6 26.0001 6C30.3401 6 34.5023 7.72403 37.5711 10.7928C40.6399 13.8616 42.3639 18.0238 42.3639 22.3638Z"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.0001 27.8184C29.0126 27.8184 31.4547 25.3763 31.4547 22.3638C31.4547 19.3513 29.0126 16.9092 26.0001 16.9092C22.9876 16.9092 20.5455 19.3513 20.5455 22.3638C20.5455 25.3763 22.9876 27.8184 26.0001 27.8184Z"
        opacity="0.4"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
