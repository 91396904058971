import { useEffect, useState } from 'react';
import { API_URL } from '../consts';
import { callFakeApi } from '../features/apiBuilder/fakeApi';
import { useLoaderOptions } from '../features/placeholderComponent/loaderFunctions';
import { HttpService } from '../utils/http';
import { handleUncoveredNotification } from '../utils/handleUncoveredNotification';

export const useManageDeviceDetails = (
  isReloadRequestAPI: boolean,
  setIsReloadRequestAPI: any,
  id: string,
  setPlaceholder?: any,
  stopPlaceholder?: any,
) => {
  const [localDeviceDetails, setLocalDeviceDetails] = useState();
  const trigger = [id] as const;
  /* eslint-disable react-hooks/exhaustive-deps */
  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);
  useEffect(() => {
    const url = `${API_URL}/v1/devices/deviceinfo/${id}`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url, loaderOptions)).data.payload;
        setLocalDeviceDetails(responseData);
        setIsReloadRequestAPI(false);
      } catch (e) {
        setIsReloadRequestAPI(false);
        //
      }
    })();
  }, [isReloadRequestAPI]);

  return localDeviceDetails;
};

export const useDeviceDetailsOrders = () => {
  const [localOrderList, setLocalOrderList] = useState();
  useEffect(() => {
    const url = `${API_URL}/v1/orders/?limit=0`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data.payload;
        setLocalOrderList(responseData);
      } catch (e) {
        //
      }
    })();
  }, []);

  return localOrderList;
};

export const useOrderList = () => {
  const [listOrder, setListOrder] = useState([]);
  useEffect(() => {
    const url = `${API_URL}/v1/orders/?limit=0`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data.payload;
        setListOrder(responseData);
      } catch (e) {
        //
      }
    })();
  }, []);
  return listOrder;
};

export const getDeviceDetailsComponents = async (id: any) => {
  const url = `${API_URL}/v1/order/details/${id}/package/`;
  try {
    let responseData = (await HttpService.get(url)).data.payload.available;
    return responseData;
  } catch (e) {}
};

export const getDeviceDetailsProductionIds = async (id: any) => {
  const url = `${API_URL}/v1/order/details/${id}/production_ids`;
  try {
    let responseData = (await HttpService.get(url)).data.payload;
    return responseData;
  } catch (e) {}
};

export const useProductionIdsList = () => {
  const [listOrder, setListOrder] = useState([]);
  useEffect(() => {
    const url = `${API_URL}/v1/components/production_ids/dropdown`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data.payload;
        setListOrder(responseData);
      } catch (e) {
        //
      }
    })();
  }, []);
  return listOrder;
};

export const attachToTracker = async (id: string, data: any, handleAddNotification?: any, language?: any) => {
  const url = `${API_URL}/v1/order/details/${id}/package/addpackage/`;
  try {
    const responseData = (await HttpService.post(url, data)).data.payload;
    handleAddNotification({
      message: language ? language.addedPackageNotification : 'New package was created',
      type: 'success',
    });
    return responseData;
  } catch (e) {
    const error = e as any;
    handleUncoveredNotification(error, handleAddNotification, language);
  }
};

export const deAttachToTracker = (id: string) => {
  const url = `${API_URL}/v1/devices/deviceinfo/${id}/detach/`;
  (async () => {
    try {
      await HttpService.patch(url, id);
    } catch (e) {
      //
    }
  })();
};

export const deAttachDevice = (idDevice: string, handleAddNotification: any, setIsLoader: any, language: any) => {
  const url = `${API_URL}/v1/devices/deviceinfo/${idDevice}/detach/`;
  (async () => {
    try {
      await HttpService.patch(url, {});
      setIsLoader(false);
      handleAddNotification({
        message: language ? language.savedDetachedNotification : `Successfully detached`,
        type: 'success',
      });
    } catch (e) {
      setIsLoader(false);
      handleUncoveredNotification(e, handleAddNotification, language);
      //
    }
  })();
};

// @TODO Liza use this api

const getFakeInventoryAttachResponse = (data: any) => ({
  id: 'inventory_id',
  name: 'inventory_name',
  ...data,
});

// data is the param u need
export const attachToFakeInventory = (data: any) =>
  callFakeApi({
    result: getFakeInventoryAttachResponse,
    options: data,
    callback: (response) => {},
  });
attachToFakeInventory({ third_key: 'test' });

export const getPackageList = (
  orderId: string,
  setPackages: any,
  setIsLoaderPackageModal: any,
  production_name?: string,
) => {
  const url = `${API_URL}/v1/order/details/${orderId}/packages?limit=0`;
  (async () => {
    try {
      const responseData = (await HttpService.get(url, {})).data.payload;
      const result = responseData.filter((onePackage: any) => !onePackage?.tracker);
      setIsLoaderPackageModal(false);

      const filteredResult =
        production_name &&
        result.filter((item: any) => {
          if (item.component.production_id === production_name) return item;
        });

      setPackages(
        result.length
          ? production_name
            ? filteredResult.length
              ? filteredResult
              : [{ id: '', name: '' }]
            : result
          : [{ id: '', name: '' }],
      );
    } catch (e) {
      setIsLoaderPackageModal(false);
      //
    }
  })();
};
