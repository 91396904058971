import React from 'react';

export const ShippingIcon = ({ color }: { color: string }) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 44C15.7614 44 18 41.7614 18 39C18 36.2386 15.7614 34 13 34C10.2386 34 8 36.2386 8 39C8 41.7614 10.2386 44 13 44Z"
        opacity="0.4"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39 44C41.7614 44 44 41.7614 44 39C44 36.2386 41.7614 34 39 34C36.2386 34 34 36.2386 34 39C34 41.7614 36.2386 44 39 44Z"
        opacity="0.4"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M34 8H4V34H34V8Z" stroke={color} stroke-width="2.7" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M34 18H42L48 24V34H34V18Z"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
