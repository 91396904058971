/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for head of universal table require
 * Highly customisable.
 *
 */

import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { media } from '../../../../styles/media';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@bit/first-scope.common-button';
import { useTableContext, useTableFunctions } from '../../context';
import { SortingArrows } from '../../../../components/sortingArrows/SortingArrows';
import { useStyleContext } from '../../../../styles/style.context';
import { colorFetch } from '../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';
import { CheckBox } from '../checkbox/Checkbox';

export enum SORT_TYPE {
  DESC = '1',
  ASC = '-1',
  NONE = '0',
}

type WrapperProps = {
  withCheckBox: boolean;
  withHeaderInMobile?: boolean;
};

// @TODO fix media types
const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 56px;
  display: flex;
  position: relative;
  background-color: ${colorFetch('pureWhite')};
  box-sizing: border-box;
  padding: ${({ withCheckBox }) => (withCheckBox ? '12px 24px' : '12px 24px 12px 12px')};
  border-radius: 8px 8px 0 0;
  border-bottom: 2px solid;
  border-color: ${colorFetch('gray4')};
  user-select: none;

  ${media.tablet`
    display: ${({ withHeaderInMobile }: any) => (withHeaderInMobile ? 'flex' : 'none')};
    padding: 12px;
    &.isChecked{
      padding: 12px 12px 12px 16px;
    }
  `};
`;

type TitleBoxProps = {
  gridTemplate?: string;
  mobileGridTemplate?: string;
};

// @TODO fix media types
const TitleBox = styled.div<TitleBoxProps>`
  width: 100%;
  display: grid;
  gap: 0px;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  align-items: center;
  ${media.tablet`
    grid-template-columns: ${({ mobileGridTemplate }: any) => mobileGridTemplate};
  `}
`;

type TitleWrapperProps = {
  isSortable?: boolean;
};

const TitleWrapper = styled.div<TitleWrapperProps>`
  display: flex;
  align-items: center;
  cursor: ${({ isSortable }) => (isSortable ? 'pointer' : 'auto')};
  & > span {
    font-weight: 500;
  }
  &:hover > div > svg > path {
    stroke: ${colorFetch('gray1')};
    fill: ${colorFetch('gray1')};
  }
`;

const GridItem = styled.div`
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  &.field {
    padding: 0 12px 0 4px;
    touch-action: none;
  }
  &.select {
    margin-right: 0 12px;
    ${media.tablet`

    `}
  }
`;

type ActionWrapperProps = {
  withHeaderInMobile?: boolean;
};

// @TODO fix media types
const ActionWrapper = styled.div<ActionWrapperProps>`
  display: flex;
  align-items: center;

  justify-content: flex-end;
  ${media.tablet`
    display: ${({ withHeaderInMobile }: any) => (withHeaderInMobile ? 'flex' : 'none')};
 `}
`;

const StyledSortingArrow = styled(SortingArrows)`
  margin-left: 6px;
`;
const DeleteButton = styled(CommonButton)`
  font-size: 12px;
`;

export interface TableHeaderProps {
  titleActionComponent?: ReactNode;

  allowDelete: boolean;
  allowSelect: boolean;
  selectComponent?: (selectedList: any[]) => ReactNode;
  withHeaderInMobile?: boolean;
  withHeaderGridTemplate?: string;
  isSelectAllCheckBox: boolean;
  languageStrings?: any;
}

export const Head: FC<TableHeaderProps> = ({
  titleActionComponent,
  allowDelete,
  allowSelect,
  selectComponent,
  withHeaderInMobile,
  withHeaderGridTemplate,
  isSelectAllCheckBox,
  languageStrings,
}) => {
  const theme = useTheme();
  const { black1, red1, red2 } = theme.colors;

  const {
    toggleIsCheckedAll,
    toggleDelete,
    getSelectedItems,
    getGridStructure,
    getHeaderData,
    setSortingData,
    getSortingData,
  } = useTableFunctions();

  const [{ isSelectAll, mobileSelectMode }] = useTableContext();

  const markedArr = getSelectedItems();
  const countOfMarkedElements = markedArr && markedArr.length;
  const [{ mediaType }] = useStyleContext();

  const { headGridTemplate, mobileHeadGridTemplate } = getGridStructure(-1, mediaType);
  const headerData = getHeaderData(mediaType);
  const headerDataFiltered =
    !mediaType.tablet && mediaType.narrowMonitor && headerData.length > 3 ? headerData.slice(0, 3) : headerData;

  const sortData = getSortingData();

  const selectAllCheckbox = () => {
    toggleIsCheckedAll();
  };

  const handleDelete = () => {
    toggleDelete();
  };

  return (
    <Wrapper
      withHeaderInMobile={withHeaderInMobile}
      withCheckBox={allowDelete || allowSelect}
      className={mobileSelectMode ? 'isChecked' : ''}
    >
      <TitleBox
        gridTemplate={headGridTemplate}
        mobileGridTemplate={
          withHeaderInMobile && isSelectAllCheckBox && mobileSelectMode
            ? '25px 1fr 1fr 100px'
            : withHeaderInMobile
            ? withHeaderGridTemplate
            : mobileHeadGridTemplate
        }
      >
        {((allowDelete && !mediaType.tablet) || allowSelect || (isSelectAllCheckBox && mobileSelectMode)) && (
          <GridItem className={'select'}>
            <CheckBox value={isSelectAll} onChange={selectAllCheckbox} />
          </GridItem>
        )}
        {headerDataFiltered.map((item: any, index: number) => {
          const { sortable, sort_field, label } = item;
          return (
            <GridItem key={index} className={'field'}>
              <TitleWrapper
                onClick={
                  sortable
                    ? () => {
                        const { sort_type, sort_field: old_sort_field } = sortData;

                        return setSortingData({
                          sort_field: sort_field,
                          sort_type:
                            old_sort_field !== sort_field
                              ? SORT_TYPE.DESC
                              : sort_type === SORT_TYPE.NONE
                              ? SORT_TYPE.DESC
                              : sort_type === SORT_TYPE.DESC
                              ? SORT_TYPE.ASC
                              : sort_type === SORT_TYPE.ASC
                              ? SORT_TYPE.NONE
                              : SORT_TYPE.DESC,
                        });
                      }
                    : () => {}
                }
                isSortable={sortable}
              >
                <Text type={TextType.TITLE_H4} color={black1}>
                  {label}
                </Text>
                {sortable && <StyledSortingArrow sortData={sortData} fieldName={sort_field} />}
              </TitleWrapper>
            </GridItem>
          );
        })}
        <GridItem className={'actionItem'}>
          <ActionWrapper withHeaderInMobile={withHeaderInMobile}>
            {countOfMarkedElements > 0 ? (
              <>
                <Text type={TextType.TEXT_14_RED} margin={'0 10px 0 0'}>
                  {`${languageStrings ? languageStrings.labelSelected : 'Selected:'} ${countOfMarkedElements}`}
                </Text>
                {allowDelete && !withHeaderInMobile && (
                  <DeleteButton
                    width="94px"
                    height="32px"
                    backgroundColor={red2}
                    backgroundColorHover={red1}
                    borderColor={red2}
                    borderColorHover={red1}
                    onClick={handleDelete}
                  >
                    {languageStrings ? languageStrings.deleteButton : 'Delete'}
                  </DeleteButton>
                )}
                {allowSelect && selectComponent ? selectComponent(markedArr) : null}
              </>
            ) : !!titleActionComponent ? (
              titleActionComponent
            ) : null}
          </ActionWrapper>
        </GridItem>
      </TitleBox>
    </Wrapper>
  );
};
