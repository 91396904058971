import React from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';
import { media } from '../../styles/media';
import { useBlockBodyScroll } from '../../utils/useBlockBodyScroll';
import { borderRadiusFetch, colorFetch, shadowFetch } from '../../styles/utils';

type ContainerProps = {
  withoutNav?: boolean;
  isOpenModal: boolean;
  isDraggable: boolean;
  isSearchModal?: boolean;
};

// @TODO fix media types
const Container = styled.div<ContainerProps>`
  display: none;
  width: calc(100% - 520px);
  transition: transform 0.2s ease-in-out;
  touch-action: pan-y;
  ${media.tablet`
    width: 100%;
    display: block;
    position: fixed;
    z-index: 99999999;
    bottom: ${({ withoutNav }: any) => (withoutNav ? '0' : '45px')};
    left: 0;
    right: 0;
    transform: ${({ isOpenModal, isDraggable, isSearchModal, withoutNav }: any) =>
      isOpenModal && isDraggable
        ? 'translateY(0)'
        : isSearchModal
        ? 'translateY(90%)'
        : withoutNav
        ? 'translateY(85%)'
        : 'translateY(70%)'};
        
    background-color: transparent;
    border-radius: ${borderRadiusFetch('additional24')} ${borderRadiusFetch('additional24')} 0 0;
    &:before {
      content: '';
      position: absolute;
      z-index: 99999999;
      top: 10px;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 4px;
      background-color: ${colorFetch('black1')};
      opacity: 0.2;
      border-radius: 2px;
    }
  `}
  & > div > div > div {
    overflow: ${({ isOpenModal }) => (isOpenModal ? 'auto' : 'hidden')};
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
      opacity: 0.6;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colorFetch('gray3')};
      border-radius: 15px;
    }
  }
`;

const Wrapper = styled.div`
  display: none;
  ${media.tablet`
    display: block;
    width: 100%;
    height: max-content;
    box-sizing: border-box;
    box-shadow: ${shadowFetch('additionalShadow2')};
    border-radius: 24px 24px 0 0;
    background-color: ${colorFetch('pureWhite')};
    padding: 20px 16px;
    z-index: 99999999;
  `}
`;

type BackgroundProps = {
  isOpenModal: boolean;
};

// @TODO fix media types
const Background = styled.div<BackgroundProps>`
  position: static;
  ${media.tablet`
    position: fixed;
    z-index: 9999999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${colorFetch('black1')};
    opacity: 0.6;
    display: ${({ isOpenModal }: any) => (isOpenModal ? 'block' : 'none')};
  `}
`;

export const DraggableModal = ({
  isDraggable,
  withoutNav,
  isSearchModal,
  children,
  isOpenModal,
  setIsOpenModal,
  isScroll,
  setModalNode,
  ...props
}: {
  isDraggable: boolean;
  children: any;
  isOpenModal: boolean;
  setIsOpenModal: (val: boolean) => void;
  isScroll: boolean;
  setModalNode?: (node: any) => void;
  withoutNav?: boolean;
  isSearchModal?: boolean;
}) => {
  useBlockBodyScroll(isDraggable);

  const handlers = useSwipeable({
    onSwipedUp: () => setIsOpenModal(true),
    onSwipedDown: () => (isScroll ? null : setIsOpenModal(false)),
    preventScrollOnSwipe: false,
  });

  const modalRef = (el: any) => {
    handlers.ref(el);
    setModalNode && setModalNode(el);
  };

  return (
    <>
      <Container
        {...handlers}
        ref={modalRef}
        isOpenModal={isOpenModal}
        isDraggable={isDraggable}
        withoutNav={withoutNav}
        isSearchModal={isSearchModal}
        className={'draggable-table'}
        {...props}
      >
        <Wrapper>{children}</Wrapper>
      </Container>
      <Background isOpenModal={isOpenModal} />
    </>
  );
};
