/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings loaders require
 * Highly customisable.
 *
 */

import { useTheme } from '@cthings.co/styles';
import React, { FC } from 'react';
import styled from 'styled-components';
import { SpinnerCircle } from '../../features/animations/SpinnerCircle';
export interface ComponentProps {}

const Background = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  backdrop-filter: blur(2px);
`;

export interface ComponentProps {
  withoutLoader?: boolean;
}

export const Loader: FC<ComponentProps> = ({ withoutLoader = false }) => {
  const theme = useTheme();

  return <Background>{!withoutLoader && <SpinnerCircle backgroundColor={theme.colors.primary} />}</Background>;
};
