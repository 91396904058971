import React from 'react';

export const OrderIcon = ({ color }: { color: string }) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26 6V17" opacity="0.4" stroke={color} stroke-width="2.7" stroke-linejoin="round" />
      <path
        d="M9 44.5V17.2734C9 17.0944 9.04803 16.9187 9.13907 16.7646L15.2097 6.49127C15.3896 6.1868 15.717 6 16.0706 6H35.9294C36.283 6 36.6104 6.1868 36.7903 6.49127L42.8609 16.7646C42.952 16.9187 43 17.0944 43 17.2734V44.5C43 45.0523 42.5523 45.5 42 45.5H10C9.44772 45.5 9 45.0523 9 44.5Z"
        stroke={color}
        stroke-width="2.7"
        stroke-linejoin="round"
      />
      <path d="M9 17H43" stroke={color} stroke-width="2.7" stroke-linejoin="round" />
      <path
        d="M22.4546 30.5454C21.9274 30.0182 21.0726 30.0182 20.5454 30.5454C20.0182 31.0726 20.0182 31.9274 20.5454 32.4546L22.4546 30.5454ZM24.5 34.5L23.5454 35.4546C24.0726 35.9818 24.9274 35.9818 25.4546 35.4546L24.5 34.5ZM31.9546 28.9546C32.4818 28.4274 32.4818 27.5726 31.9546 27.0454C31.4274 26.5182 30.5726 26.5182 30.0454 27.0454L31.9546 28.9546ZM25.4546 35.4546L31.9546 28.9546L30.0454 27.0454L23.5454 33.5454L25.4546 35.4546ZM20.5454 32.4546L22.0454 33.9546L23.9546 32.0454L22.4546 30.5454L20.5454 32.4546ZM22.0454 33.9546L23.5454 35.4546L25.4546 33.5454L23.9546 32.0454L22.0454 33.9546Z"
        opacity="0.4"
        fill={color}
      />
    </svg>
  );
};
