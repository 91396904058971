import React, { FC } from 'react';
import { ItemWrapper, StyledNavLink, TextWrapper, StyledText } from './styled';
import { TextType } from '@bit/first-scope.text';
import Tooltip from '../../../../components/tooltip';
import { ItemImage } from '../ItemImages';
import { MenuItemType } from './ItemFirstMobileMenu';
import { getAccess } from '../../../../routes/paths';
import { useNavigationContext } from '../navigation.context';
import { useTheme } from '@cthings.co/styles';

export interface ItemWithoutChildMenuTypes {
  disabled?: boolean;
  tooltipId?: string;
  to: any;
  type: MenuItemType;
  isOpen: boolean;
  value?: string;
  isActive?: boolean;
  handleCloseFirstMenu?: () => void;
  isSmallHeightViewport: boolean;
}

export const ItemWithoutChildMenu: FC<ItemWithoutChildMenuTypes> = ({
  disabled,
  tooltipId,
  to,
  type,
  isOpen,
  value,
  isActive,
  isSmallHeightViewport,
  handleCloseFirstMenu,
}) => {
  const theme = useTheme();
  const { black1, primary } = theme.colors;

  const [, , { clearSecondaryItems }] = useNavigationContext();

  const activeOptions = isActive ? { className: 'active' } : {};

  return (
    <ItemWrapper className={isActive ? 'active' : ''} isSmallHeightViewport={isSmallHeightViewport}>
      <Tooltip id={!isOpen ? tooltipId ?? '' : ''} text={!isOpen ? value ?? '' : ''}>
        <StyledNavLink
          to={to}
          access={getAccess(to)}
          onClick={(e: any) => {
            disabled && e.preventDefault();
            clearSecondaryItems && clearSecondaryItems();
            handleCloseFirstMenu && handleCloseFirstMenu();
          }}
          {...activeOptions}
        >
          <ItemImage type={type} />
          <TextWrapper className={isOpen ? 'open' : ''}>
            <StyledText type={TextType.MENU_ITEM_TEXT_PRIMARY} color={black1} hoverColor={primary}>
              {value}
            </StyledText>
          </TextWrapper>
        </StyledNavLink>
      </Tooltip>
    </ItemWrapper>
  );
};
