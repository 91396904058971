import React from 'react';
import styled from 'styled-components';
import { useCustomHistory } from '../../../../../utils/react-router-dom-abstraction';
import { media } from '../../../../../styles/media';
import { ReactComponent as DocumentsImage } from '../../../../../assets/Documents.svg';
import { ReactComponent as PhoneImage } from '../../../../../assets/phone.svg';
import { ReactComponent as AcceptCircleImage } from '../../../../../assets/AcceptCircle.svg';
import { CommonButton } from '../../../../../components/commonButton/CommonButton';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { View } from '../../../../../routes/routeInterfaces';
import { useTheme } from '@cthings.co/styles';

const Button = styled(CommonButton)`
  width: 336px;
  height: 40px;
  &:first-child {
    margin-bottom: 10px;
  }
  & > svg {
    margin-right: 5px;
  }
  ${media.tablet`
      width: 100%;
      margin: 4px 0;
  `}
`;

const LinkPreview = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  ${media.tablet`
      width: 100%;
      margin: 4px 0;
  `}
`;

export const PreviewButtons = ({
  id,
  orderId,
  lang,
  password,
}: {
  id: string;
  orderId: string | null;
  lang: string | null;
  password: string | number;
}) => {
  const theme = useTheme();
  const { primary, pureWhite, gray1 } = theme.colors;

  const language = useSelector(selectLanguageStrings);
  const history = useCustomHistory();

  const handleRedirectToDocuments = () => {
    history.push(View.DOCUMENTS_PREVIEW_DOCS, { pathParams: { id, lang }, state: { password: password } });
  };

  const handleRedirectToCertificates = () => {
    history.push(View.DOCUMENTS_PREVIEW_CERTIFICATES, { pathParams: { id, lang }, state: { password } });
  };

  return (
    <>
      <Button onClick={handleRedirectToDocuments} backgroundColor={pureWhite} borderColor={primary} color={gray1}>
        <DocumentsImage />
        {language ? language.externalLabelDocuments : 'Documents'}
      </Button>
      <Button onClick={handleRedirectToCertificates} backgroundColor={pureWhite} borderColor={primary} color={gray1}>
        <AcceptCircleImage />
        {language ? language.externalLabelCertificates : 'Certificates'}
      </Button>
      <LinkPreview href={'https://www.valmont.pl/valmont-poland/pl/kontakt/dział-handlowy'}>
        <Button onClick={() => {}} backgroundColor={pureWhite} borderColor={primary} color={gray1}>
          <PhoneImage />
          {language ? language.previewContactUs : 'Contact us'}
        </Button>
      </LinkPreview>
    </>
  );
};
