import { useCallback, useMemo } from 'react';
import { useStyleContext } from '../styles/style.context';

export const useDimensions = ({
  imageWrapperNode,
  scale,
  imageWidth,
  imageHeight,
}: {
  imageWrapperNode: SVGGElement | null;
  scale: number;
  imageWidth: number;
  imageHeight: number;
}) => {
  const [{ width: windowWidth, height: windowHeight }] = useStyleContext();

  const { offset, initialImageScale, containerCenter } = useMemo(() => {
    const { width: containerWidthRaw, height: containerHeightRaw } = imageWrapperNode?.getBoundingClientRect() || {
      width: 0,
      height: 0,
    };

    const containerWidth = containerWidthRaw / scale;
    const containerHeight = containerHeightRaw / scale;

    const containerSideRatio = containerWidth / containerHeight;
    const imageSideRatio = imageWidth / imageHeight;

    const isWidthMatch = imageSideRatio >= containerSideRatio;

    const initialImageScale = isWidthMatch ? containerWidth / imageWidth : containerHeight / imageHeight;
    return {
      offset: {
        x: (containerWidth - imageWidth * initialImageScale) / 2,
        y: (containerHeight - imageHeight * initialImageScale) / 2,
      },
      initialImageScale,
      containerCenter: { x: containerWidth / 2, y: containerHeight / 2 },
    };
  }, [imageWidth, imageHeight, imageWrapperNode, windowWidth, windowHeight /*scale*/]);

  const getX = useCallback(
    (rawX: number) => {
      const x = offset.x + initialImageScale * rawX;

      return x;
    },
    [offset.x, initialImageScale],
  );

  const getY = useCallback(
    (rawY: number) => {
      const y = offset.y + initialImageScale * rawY;

      return y;
    },
    [offset.y, initialImageScale],
  );

  return {
    getX,
    getY,
    containerCenter,
  };
};
