/**
 * @description
 * This is container for device list map
 *
 */

import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDevices } from '../../../../../api/locations';
import { MAPBOX_TOKEN } from '../../../../../consts';
import DeviceListMap from '../../../../../components/deviceListMap/DeviceListMap';
import { useLoaderFunctions } from '../../../../../features/placeholderComponent/loader.context';

export const RealTimeInsight = ({ boundingRect }: { boundingRect: any }) => {
  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();
  const keywordList = ['DeviceMap'];
  const [zoom, setZoom] = useState(0);
  const { state }: any = useLocation();

  const devices = useDevices(
    boundingRect,
    (isPlaceholder: boolean) => appendKeywordList(keywordList, isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList(keywordList, isPlaceholder),
  );

  const params = useParams();

  const [centre, setCentre] = useState<any>(
    params?.lat && params?.lon
      ? { lat: parseFloat(params?.lat), lon: parseFloat(params?.lon) }
      : { lat: 52.25603, lon: 20.98765 },
  );

  const [isInfoHomeId, setIsInfoHomeId] = useState('');

  const deviceList = devices.geopivoted;

  const centreLat = devices.centre_coord.lat;
  const centreLon = devices.centre_coord.lng;

  useLayoutEffect(() => {
    centreLat && centreLon && setCentre({ lat: parseFloat(centreLat), lon: parseFloat(centreLon) });
  }, [centreLat, centreLon]);

  useEffect(() => {
    if (!state && !params?.lat) {
      centreLat && centreLon && setCentre({ lat: parseFloat(centreLat), lon: parseFloat(centreLon) });
    } else {
      setCentre({ lat: parseFloat(centre?.lat), lon: parseFloat(centre?.lon) });
      setZoom(parseFloat(params?.zoom || '1'));
    }
  }, [centreLat, centreLon]);

  const handleSetCentre = (centre: any) => {
    setCentre({ lat: parseFloat(centre.lat), lon: parseFloat(centre.lng) });
  };

  return (
    <>
      {devices.centre_coord.lat && devices.centre_coord.lng && devices.geopivoted.length > 0 && (
        <DeviceListMap
          zoom={zoom ? zoom : devices.zoom_level_mapbox > 0 ? devices.zoom_level_mapbox - 1 : 0}
          latitude={centre.lat}
          longitude={centre.lon}
          centerCoords={centre}
          setCentre={handleSetCentre}
          staticDisplay
          deviceList={deviceList}
          mapToken={MAPBOX_TOKEN}
          isInfoHomeId={isInfoHomeId}
          setIsInfoHomeId={setIsInfoHomeId}
        />
      )}
    </>
  );
};
