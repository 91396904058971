import React, { FC } from 'react';
import styled from 'styled-components';
import { TableRow } from '../components/tableRow/TableRow';
import { withLoader } from '../../../../../../features/placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../../../../../../components/placeholders/typePlaceholders/placeholdersType';
import { TableHeader } from '../../firstTableInfo/components/tableHeader/TableHeader';
import { borderRadiusFetch, colorFetch } from '../../../../../../styles/utils';

const TableWrapper = styled.div`
  width: 100%;
  border-radius: ${borderRadiusFetch('primary')};
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  & > div:nth-child(1) {
    height: 56px;
    display: flex;
    align-items: center;
  }
`;

const WrapperItems = styled.div`
  & > div:nth-child(7) {
    border-top: 2px solid;
    border-color: ${colorFetch('gray4')};
  }
`;

export interface HeaderLabelsProps {
  name: string;
  isSorted: boolean;
}

export interface ComponentProps {
  headerLabels: any;
  headerGridTemplate: any;
  arr: any;
  type: string;
  offset?: number;
}

const ContentTable: FC<ComponentProps> = ({ headerLabels, headerGridTemplate, arr, type, offset, ...props }) => {
  const dateHandler = (value: string) => {
    const timezoneOffset = new Date().getTimezoneOffset();
    const hour = Math.abs(timezoneOffset / 60);
    const timeValue = Number(value.slice(14, 16)) + hour;
    const year = value.slice(6, 8);
    const validDate =
      value !== 'N/A'
        ? value.replace(year, '20') + value.replace(value.slice(14, 16), timeValue.toString()).slice(13).slice(6)
        : value;
    return validDate;
  };

  return (
    <>
      <TableWrapper {...props}>
        <TableHeader
          labels={headerLabels}
          gridTemplateDesktop={headerGridTemplate.desktop}
          gridTemplateTablet={headerGridTemplate.tablet}
          gridTemplatePhone={headerGridTemplate.phone}
        />
        <WrapperItems>
          {arr &&
            arr.map((item: any, index: number) => {
              const { value, property } = item;
              const validValue = property === 'Last Report' ? dateHandler(value) : value;

              return <TableRow key={index} data={item} property={property} value={validValue} offset={offset} />;
            })}
        </WrapperItems>
      </TableWrapper>
    </>
  );
};

export const InfoContentTable = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(ContentTable);
