import Check from './assets/Check.svg';
import DisabledCheck from './assets/DisabledCheck.svg';
// @ts-ignore
import styled from '@xstyled/styled-components';

export const Wrapper = styled.div`
  position: relative;
  touch-action: none;
`;

export const Input = styled.input`
  display: none;
  &.checked + span {
    background-color: ${({ backgroundColor }: any) => backgroundColor};
    &::before {
      position: absolute;
      content: '';
      width: 16px;
      height: 16px;
      top: 3.5px;
      background-image: url('${Check}');
      background-position: center;
    }
  }
  &:hover + span {
    box-shadow: ${({ boxShadow }: any) => boxShadow};
  }

  &.disabled.checked + span {
    background-color: ${({ disabledBackground }: any) => disabledBackground};
    &::before {
      position: absolute;
      content: '';
      width: 13px;
      height: 9px;
      top: 4px;
      left: 1.5px;
      background-image: url('${DisabledCheck}');
      background-position: center;
    }
  }
`;

export const StyledCheckbox = styled.span`
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: block;
  border-radius: ${({ borderRadius }: any) => borderRadius};
  border: 1px solid;
  border-color: ${({ borderColor }: any) => borderColor};
  background-color: white;
  &.disabled {
    border-color: ${({ disabledBorderColor }: any) => disabledBorderColor};
    background-color: ${({ disabledBackground }: any) => disabledBackground};
  }
`;
