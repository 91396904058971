import React from 'react';

export const RefreshIcon = ({ color }: { color: string }) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M47.6378 10.2632V22.0658H35.8352"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g opacity="0.4">
        <path
          d="M4.36182 41.737V29.9343H16.1644"
          stroke={color}
          stroke-width="2.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.36182 29.9343L13.4892 38.5109C15.6033 40.6261 18.2188 42.1713 21.0917 43.0022C23.9645 43.8332 27.001 43.9229 29.9179 43.2629C32.8347 42.6029 35.5369 41.2148 37.7722 39.2281C40.0075 37.2413 41.703 34.7207 42.7007 31.9014"
          stroke={color}
          stroke-width="2.7"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <path
        d="M9.29907 20.0985C10.2967 17.2792 11.9923 14.7586 14.2276 12.7719C16.4629 10.7851 19.165 9.397 22.0819 8.73702C24.9988 8.07705 28.0353 8.16673 30.9081 8.99769C33.7809 9.82866 36.3964 11.3738 38.5106 13.489L47.638 22.0656"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
