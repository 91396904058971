/**
 * @description
 * This is component for map list of device
 * all the functionalities cthings require for DeviceListMap
 * Highly customisable.
 *
 */

import React, { FC, useState, useRef, useEffect, useCallback } from 'react';
// @ts-ignore
import ReactMapGL, { Marker, FlyToInterpolator } from 'react-map-gl';
import useSupercluster from 'use-supercluster';
import styled from 'styled-components';
import { OverlayGradient } from '../overlayGradient/OverlayGradient';
import { ManholeButton } from '../manholeButton/ManholeButton';
import { ManholesType } from '../../components/manholeButton/ManholeButton';
import { injectPathParams, PATHS } from '../../routes/paths';
import { useMapResizeControl } from '../../features/mapResizeControl/useMapResizeControl';
import { withLoader } from '../../features/placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../placeholders/typePlaceholders/placeholdersType';
import { CustomMarker } from './components/customMarker/CustomMarker';
import { hexToRGBA } from '../../utils/hexToRgb';
import { useHistory } from '../../utils/react-router-dom-abstraction';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
`;

const SemiWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  border-radius: ${borderRadiusFetch('additional10')};
`;

const ClusterContainer = styled.div`
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  box-sizing: border-box;
  border-radius: 50%;
  border: 3px solid rgba(0, 132, 182, 0.2);
  position: absolute;
  top: -5px;
  left: -5px;
  opacity: 0;
  z-index: 1;
  transition: all 0.3s linear;
`;

type ClusterProps = {
  pointCount: number;
  length: number;
  boxShadowColor: string;
};

const Cluster = styled.div<ClusterProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ pointCount, length }) => `${35 + (pointCount / length) * 10}px`};
  height: ${({ pointCount, length }) => `${35 + (pointCount / length) * 10}px`};
  color: ${colorFetch('pureWhite')};
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.6);
  background-color: ${colorFetch('secondary2')};
  cursor: pointer;
  font-size: 20px;
  position: relative;
  &:hover {
    box-shadow: 0 0 0 3px ${({ boxShadowColor }) => boxShadowColor};
    & > div {
      opacity: 1;
      font-weight: 500;
      transition: 0.3s;
    }
  }
`;

export interface DeviceMapProps {
  zoom: number;
  latitude?: number;
  longitude?: number;
  isGradiented?: boolean;
  staticDisplay?: boolean;
  deviceList: any[];
  className?: string;
  mapToken: string;
  centerCoords?: any;
  setCentre?: any;
  isForZones?: boolean;
  isInfoHomeId?: string;
  setIsInfoHomeId?: any;
}

//@TODO Alex split this component into smaller ones
const DeviceListMap: FC<DeviceMapProps> = ({
  zoom,
  isGradiented,
  latitude,
  longitude,
  staticDisplay,
  deviceList,
  mapToken,
  centerCoords,
  setCentre,
  isForZones,
  isInfoHomeId,
  setIsInfoHomeId,
  ...props
}) => {
  const [viewport, setViewport] = useState({
    latitude: latitude,
    longitude: longitude,
    width: '100%',
    height: '100%',
    zoom: zoom || 12,
    transitionInterpolator: new FlyToInterpolator({
      speed: 4,
    }),
    transitionDuration: 400,
  });

  const history = useHistory();

  //resize map
  const mapbox = useMapResizeControl(setViewport);
  const theme = useTheme();
  const mapReference = useRef();

  const points = deviceList.map((values: any) => {
    return {
      type: 'Feature',
      properties: {
        cluster: false,
        values: values.map((value: any) => ({
          manholeId: value.id,
          name: value.name,
          temperature: ``,
          waterLevel: ``,
          address: `${value.address.line1}, ${value.address.line2}`,
          to: isForZones
            ? injectPathParams(PATHS.MANAGE_ZONES_DETAILS, {
                building_id: value.building_id,
                floor: value.floor,
                zone_id: 'noselection',
                offset: 0,
              })
            : injectPathParams(PATHS.USER_INSIGHTS_DETAILS, { id: value.building_id, name: 'noselection' }),
          coordinates: `${value.address.geotag.lat}, ${value.address.geotag.lng}`,
        })),
      },
      geometry: {
        type: 'Point',
        coordinates: [values[0].address.geotag.lng, values[0].address.geotag.lat],
      },
    };
  });

  const bounds = mapReference.current ? (mapReference as any).current.getMap().getBounds().toArray().flat() : null;

  const { clusters, supercluster } = useSupercluster({
    points,
    zoom: viewport.zoom,
    bounds,
    options: {
      radius: 75,
    },
  });

  const getExpansionZoom = (clusterId: string) => Math.min(supercluster.getClusterExpansionZoom(clusterId), 20);

  const saveState = (lat?: number, lon?: number, zoom?: number) => {
    const to = injectPathParams(PATHS.USER_INSIGHTS, {
      lat,
      lon,
      zoom,
    });
    history.replace(to);
  };

  const handleClusterClick = (latitude: number, longitude: number, clusterId: string) => {
    const expansioZoom = getExpansionZoom(clusterId);
    setViewport((currentViewport: any) => ({
      ...currentViewport,
      latitude,
      longitude,
      zoom: expansioZoom,
      transitionInterpolator: new FlyToInterpolator({
        speed: 5,
      }),
      transitionDuration: 400,
    }));
    !isForZones && saveState(latitude, longitude, expansioZoom);
  };

  useEffect(() => {
    setViewport((currentViewport) => ({
      ...currentViewport,
      zoom,
    }));
  }, [zoom]);

  useEffect(() => {
    setViewport((currentViewport) => ({
      ...currentViewport,
      latitude: centerCoords ? centerCoords.lat : 0,
      longitude: centerCoords ? centerCoords.lon : 0,
    }));
  }, [centerCoords]);

  const onClickOutsideHandler = useCallback(
    (e) => {
      const element = e?.target?.toString();
      if (element?.includes('[object HTMLDivElement]')) setIsInfoHomeId('');
    },
    [isInfoHomeId],
  );

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  const handleClick = (id: string | undefined = '') => {
    setViewport((currentViewport: any) => ({
      ...currentViewport,
      transitionDuration: 400,
    }));
    setIsInfoHomeId(id);
    !isForZones && saveState(latitude, longitude, viewport.zoom);
  };

  const boxShadowColor = hexToRGBA(theme.colors.primary, 0.2);

  return (
    <Wrapper {...props}>
      <SemiWrapper ref={mapbox} theme={theme}>
        {isGradiented ? <OverlayGradient /> : null}
        <ReactMapGL
          {...viewport}
          mapboxApiAccessToken={mapToken}
          ref={mapReference as any}
          onViewportChange={(viewPort: any) => setViewport({ ...viewPort })}
          onTransitionEnd={() => {
            !isForZones && saveState(viewport.latitude, viewport.longitude, viewport.zoom);
          }}
        >
          {clusters.map((clusterObject, index) => {
            const { properties, geometry, id: clusterId } = clusterObject;
            const [longitude, latitude] = geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } = properties;

            return (
              <>
                {isCluster ? (
                  <Marker key={clusterId} latitude={latitude} longitude={longitude}>
                    <Cluster
                      onClick={() => handleClusterClick(latitude, longitude, clusterId)}
                      pointCount={pointCount}
                      length={points.length}
                      boxShadowColor={boxShadowColor}
                    >
                      {pointCount}
                      <ClusterContainer />
                    </Cluster>
                  </Marker>
                ) : (
                  <CustomMarker
                    key={index}
                    latitude={parseFloat(latitude)}
                    longitude={parseFloat(longitude)}
                    isReq={isInfoHomeId === properties.values[0].manholeId}
                    handleClick={() => handleClick(properties.values[0].manholeId)}
                    setCentre={setCentre}
                    zoom={viewport.zoom}
                  >
                    <ManholeButton
                      type={ManholesType.BLUE}
                      isReq={isInfoHomeId === properties.values[0].manholeId}
                      {...properties}
                    />
                  </CustomMarker>
                )}
              </>
            );
          })}
        </ReactMapGL>
      </SemiWrapper>
    </Wrapper>
  );
};

export default withLoader(
  'DeviceMap',
  PlaceholderType.MAP,
  { width: '100%', height: '100%' },
  { mapFlag: true },
)(DeviceListMap);

DeviceListMap.defaultProps = {
  zoom: 12,
  latitude: 52.25603,
  longitude: 20.98765,
  isGradiented: false,
};
