import { TextType } from '@bit/first-scope.text';
import React, { FC, useEffect } from 'react';
import Tooltip from '../../../../components/tooltip';
import { getAccess } from '../../../../routes/paths';
import { useStyleContext } from '../../../../styles/style.context';
import { MenuItemType } from './ItemFirstDesktopMenu';
import { ItemImage } from '../ItemImages';
import {
  ItemWrapper,
  LineForModal,
  ManageList,
  ManageModal,
  StyledManageArrowImage,
  StyledManageNavlink,
  StyledNavLink,
  StyledText,
  TextWrapperManage,
} from './styled';
import { useTheme } from '@cthings.co/styles';

export interface ItemWithChildMenuTypes {
  type: MenuItemType;
  manageRef: any;
  isActive: boolean;
  to: any;
  childRoutes: any;
  isOpen: boolean;
  tooltipId?: string;
  value?: string;
  setOpenManageList: any;
  openManageList: boolean;
  appendSecondaryItems?: (val: any) => void;
  clearSecondaryItems?: () => void;
}

export const ItemWithChildMenu: FC<ItemWithChildMenuTypes> = ({
  type,
  manageRef,
  isActive,
  to,
  childRoutes,
  isOpen,
  tooltipId,
  value,
  setOpenManageList,
  openManageList,
  appendSecondaryItems,
  clearSecondaryItems,
}) => {
  const theme = useTheme();

  const [media] = useStyleContext();

  useEffect(() => {
    openManageList && childRoutes.length && appendSecondaryItems && appendSecondaryItems(childRoutes);
  }, [openManageList]);

  return (
    <ItemWrapper ref={manageRef} className={isActive ? 'active' : ''}>
      <Tooltip
        id={!isOpen && !openManageList ? tooltipId ?? '' : ''}
        text={!isOpen && !openManageList ? value ?? '' : ''}
      >
        <StyledNavLink
          to={to}
          access={getAccess(to)}
          onClick={(e: any) => {
            e.preventDefault();
            setOpenManageList && setOpenManageList((el: boolean) => !el);
            clearSecondaryItems && clearSecondaryItems();
          }}
          className={isActive ? 'active' : ''}
        >
          <ItemImage type={type} />
          <TextWrapperManage className={isOpen ? 'open' : ''}>
            <StyledText
              type={TextType.MENU_ITEM_TEXT_PRIMARY}
              color={theme.colors.black1}
              hoverColor={theme.colors.primary}
            >
              {value}
            </StyledText>
            <StyledManageArrowImage className={openManageList ? 'open' : ''} />
          </TextWrapperManage>
        </StyledNavLink>
      </Tooltip>
      {!media.mediaType.tablet && isOpen && childRoutes && childRoutes.length && (
        <ManageList
          className={openManageList ? 'open' : ''}
          height={16 * childRoutes.length + 15 * (childRoutes.length - 1) + 10 + 'px'}
        >
          {childRoutes.map((item: any, index: number) => {
            const { name, to, view } = item;
            return view === 'LINE' ? (
              <LineForModal />
            ) : to ? (
              <StyledManageNavlink to={to} access={getAccess(to)}>
                {name}
              </StyledManageNavlink>
            ) : null;
          })}
        </ManageList>
      )}
      {!media.mediaType.tablet && !isOpen && childRoutes && childRoutes.length && (
        <ManageModal
          // @TODO check implementation of height prop. It doesn't exist in ManageModal styles
          // height={16 * childRoutes.length + 15 * (childRoutes.length - 1) + 24 + 'px'}
          className={openManageList ? 'open' : ''}
        >
          {childRoutes.map((item: any, index: number) => {
            const { name, to, view } = item;
            return view === 'LINE' ? (
              <LineForModal className="modal" />
            ) : to ? (
              <StyledManageNavlink
                to={to}
                access={getAccess(to)}
                style={{ marginLeft: 0, marginTop: 0 }}
                onClick={() => setOpenManageList(false)}
              >
                {name}
              </StyledManageNavlink>
            ) : null;
          })}
        </ManageModal>
      )}
    </ItemWrapper>
  );
};
