import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as Document } from './assets/Document.svg';
import { ReactComponent as Download } from './assets/Download.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { media } from '../../styles/media';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.a`
  width: 100%;
  max-width: 340px;
  height: 100px;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  background: ${colorFetch('pureWhite')};
  box-shadow: 0px 2px 8px rgba(96, 97, 112, 0.06);
  border-radius: ${borderRadiusFetch('primary')};
  padding: 15px 20px 15px 20px;
  ${media.phone`
    max-width: 100%;
  `}
`;

const InfoSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled(Text)`
  max-width: 250px;
  width: 100%;
  font-size: 16px;
  color: ${colorFetch('black1')};
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.2px;
  overflow: hidden;
  text-overflow: ellipsis;
` as any; // @TODO fix type

type LinkTextProps = {
  isRedirectLink?: boolean;
};

const LinkText = styled(Text)<LinkTextProps>`
  color: inherit;
  font-size: 12px;
  margin-left: 5px;
  border-bottom: ${({ isRedirectLink }) => (isRedirectLink ? '1px solid' : 'none')};
  border-color: ${colorFetch('gray3')};
` as any; // @TODO fix type

const LinkWrapper = styled.div`
  width: max-content;
  display: flex;
  justify-content: flex-start;
  color: ${colorFetch('gray2')};

  &:hover {
    color: ${colorFetch('gray1')};
    & > svg > path {
      transition: all 0.3s ease;
      stroke: ${colorFetch('gray1')};
    }
  }
  & > svg > path {
    transition: all 0.3s ease;
    stroke: ${colorFetch('gray2')};
  }
`;

export interface DocumentCardProps {
  title: string;
  date: string;
  downloadUrl: string;
  isRedirectLink: string;
  href: string;
}

export const DocumentCard: React.FC<DocumentCardProps> = ({ title, date, downloadUrl, isRedirectLink, href }) => {
  const theme = useTheme();
  const language = useSelector(selectLanguageStrings);

  const titleHandler = (title: string) => {
    let finishedTitle = title;
    if (title.length >= 28) {
      finishedTitle = title.slice(0, 28) + '...';
    }
    return finishedTitle;
  };

  return (
    <Wrapper
      href={isRedirectLink ? href : downloadUrl}
      target={isRedirectLink ? '_self' : '_blank'}
      rel={'noopener noreferer'}
    >
      <InfoSection>
        <Title>{titleHandler(title)}</Title>
        <Document />
      </InfoSection>
      <InfoSection>
        <Text weight={'500'} fontSize={'12px'} color={theme.colors.gray2} type={TextType.TEXT_12_GRAY}>
          {date}
        </Text>
        {isRedirectLink ? (
          <LinkWrapper>
            <LinkText isRedirectLink={!!isRedirectLink}>{language ? language.externalLabelLink : 'Link'}</LinkText>
          </LinkWrapper>
        ) : (
          <LinkWrapper>
            <Download />
            <LinkText>{language ? language.previewDocumentsDownload : 'Download'}</LinkText>
          </LinkWrapper>
        )}
      </InfoSection>
    </Wrapper>
  );
};
