import React, { useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { CheckBox } from '@bit/first-scope.controls';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '../../../../../../styles/media';
import { SearchInput } from '../../../../../../containers/common/documentationNFC/components/dropdownMultiChoice/components/searchInput/SearchInput';
import { ReactComponent as ClearFilterIcon } from '../../../../../../assets/ClearFilters.svg';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid;
  border-color: gray3;
  box-shadow: ${(props: any) => props.theme.shadows.additionalShadow10};
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  position: absolute;
  top: ${(props: any) => (props.title ? `${props.fieldHeight + 4}px` : `${props.fieldHeight + 16}px`)};
  left: 0;
  z-index: 1;
  cursor: auto;
`;
const ParameterListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 4px 10px 12px;
  max-height: 190px;
`;
const ParameterList = styled.ul`
  width: 100%;
  max-height: 210px;
  margin: 0;
  padding: 0;
  overflow-y: ${(props: any) => (props.heightOfList > 200 ? 'scroll' : 'auto')};
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
    background: gray4;
    opacity: 1;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray2;
    border-radius: ${(props: any) => props.theme.borderRadius.primary};
    position: relative;
    top: 4px;
    right: 4px;
  }
`;
const ParameterItem = styled.li`
  display: flex;
  margin-bottom: ${(props: any) => (props.isLast ? '0' : '12px')};
  align-items: center;
  cursor: pointer;
  & > div > span {
    color: ${(props: any) => (props.isActive ? props.primary : props.gray1)};
  }
  & > div > div > span {
    color: ${(props: any) => (props.isActive ? props.primary : props.gray1)};
  }
  &:hover > div > span {
    color: primary;
  }
  &:hover > div > div > span {
    color: primary;
  }
`;
const CheckboxWrapper = styled.div`
  width: max-content;
  display: flex;
  ${media.phone`
    align-items: center;
  `}
`;
const CheckboxSemiwrapper = styled.div`
  width: max-content;
  box-sizing: border-box;
  padding: 2px;
  margin-right: 10px;
`;

const NameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: 12px;
  ${media.phone`
    grid-template-columns: 1fr;
    grid-gap: 5px;
  `}
`;

const ButtonWrapper = styled.div`
  padding: 10px 12px;
  border-top: 1px solid;
  border-color: gray4;
`;

const NameSecondWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  padding: 4px 82px;
  cursor: pointer;
  border: 1px solid;
  border-color: primary;
  border-radius: 6px;
  align-items: center;

  &:hover {
    & > svg > path {
      stroke: white;
    }
    & > span {
      color: white;
      transition: 0s;
    }
    background-color: primary;
    border-color: white;
  }
`;

const StyledText = styled(Text)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  word-break: break-all;
`;

const Icon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 20px;
  background-color: ${(props: any) => props.color};
`;

export const SelectListPopup = ({
  popupRef,
  parameterList,
  handleCheckedItem,
  onButtonClick,
  fieldHeight,
  languageStrings,
  isOpenSelectList,
  wrapperRef,
  selectedIndexOfPeriod,
  scrollTo,
  scrollToWithCustomPeriod,
  handleTransformName,
  title,
}: {
  popupRef: React.MutableRefObject<any>;
  parameterList: any[];
  handleCheckedItem: (id: string) => void;
  onButtonClick: () => void;
  fieldHeight: number;
  wrapperRef: any;
  selectedIndexOfPeriod: number;
  scrollTo: number;
  scrollToWithCustomPeriod: number;
  isSelectAll?: boolean;
  isOpenSelectList?: boolean;
  handleTransformName?: (name: any) => string;
  languageStrings?: any;
  title?: string;
}) => {
  const theme = useTheme();
  const listRef = useRef<any>(null);
  const { gray1, gray2, white, primary, black, blue2, orange2, red2 } = theme.colors;
  const [searchInputValue, setSearchInputValue] = useState('');
  const [heightOfList, setHeightOfList] = useState(0);

  const handleInutOnChange = (e: any) => {
    setSearchInputValue(e.target.value);
  };

  const handleClearInput = () => {
    setSearchInputValue('');
  };

  const filterParameterList = (flag: string) => {
    return parameterList.filter((item: any) => {
      if (handleTransformName) {
        let condition;
        const values = handleTransformName(item.name).split(',');
        for (let i = 0; i < values.length; i++) {
          if (values[i - 1]) {
            break;
          }
          condition = values[i].trim().toLowerCase().indexOf(flag.toLowerCase()) !== -1;
        }
        return condition;
      } else {
        return item.name.toLowerCase().indexOf(flag.toLowerCase()) !== -1;
      }
    });
  };

  const clearEvents = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSelect = useCallback(
    (id: string) => {
      handleCheckedItem(id);
    },
    [handleCheckedItem],
  );

  const setColor = (num: string) => {
    const color: any = {
      blue: blue2,
      orange: orange2,
      red: red2,
    };

    return color[num];
  };

  useEffect(() => {
    listRef && listRef.current && setHeightOfList(listRef.current.clientHeight);
  }, []);

  useEffect(() => {
    const scrollRef = wrapperRef && wrapperRef.current;
    if (isOpenSelectList && scrollRef) {
      scrollRef.scrollTo({
        top: selectedIndexOfPeriod === 3 ? scrollToWithCustomPeriod : scrollTo,
        behavior: 'smooth',
      });
    }
  }, [isOpenSelectList, searchInputValue.length]);

  return (
    <Wrapper
      theme={theme}
      fieldHeight={fieldHeight}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      title={title}
    >
      <SearchInput
        value={searchInputValue}
        handleOnChange={handleInutOnChange}
        handleClearInput={handleClearInput}
        languageStrings={languageStrings}
      />
      <ParameterListWrapper ref={popupRef}>
        {parameterList.length > 0 ? (
          <ParameterList ref={listRef} heightOfList={heightOfList}>
            {filterParameterList(searchInputValue).map((item: any, index: number) => {
              const { name, icon, items_number, isSelected, id } = item;
              return (
                <ParameterItem
                  key={index}
                  isLast={index === parameterList.length - 1}
                  isActive={isSelected}
                  primary={primary}
                  gray1={gray1}
                  onClick={() => {
                    handleSelect(id);
                  }}
                >
                  <CheckboxWrapper onTouchStart={clearEvents} onTouchEnd={clearEvents}>
                    <CheckboxSemiwrapper onClick={() => {}}>
                      <CheckBox
                        label={''}
                        color={gray2}
                        backgroundColor={white}
                        backgroundColorHover={primary}
                        toggle={isSelected}
                        disabled={false}
                        handleToggle={(e: any) => {
                          clearEvents(e);
                          handleSelect(id);
                        }}
                      />
                    </CheckboxSemiwrapper>
                  </CheckboxWrapper>
                  <NameWrapper isSecondNameExists={!!items_number}>
                    <NameSecondWrapper>
                      <Text type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'} margin={'0 4px 0 0'}>
                        {handleTransformName ? handleTransformName(name) : name}
                      </Text>
                      {icon && <Icon color={setColor(icon)} />}
                    </NameSecondWrapper>
                    {items_number && <StyledText type={TextType.TEXT_14_GRAY}>{items_number}</StyledText>}
                  </NameWrapper>
                </ParameterItem>
              );
            })}
          </ParameterList>
        ) : (
          <>
            <Text type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'}>
              {languageStrings.placeholderNoData || 'No data'}
            </Text>
          </>
        )}
      </ParameterListWrapper>
      <ButtonWrapper>
        <Button onClick={onButtonClick}>
          <ClearFilterIcon />
          <Text type={TextType.TEXT_14_GRAY} color={black} weight={'500'}>
            {languageStrings.labelClear}
          </Text>
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};
