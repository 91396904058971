export enum DashboardItemType {
  CARD = 'CARD',
  COMPLEX_CARD = 'COMPLEX_CARD',
  DISTRIBUTION_GRAPH = 'DISTRIBUTION_GRAPH',
  CAPACITY_GRAPH = 'CAPACITY_GRAPH',
}

export enum DashboardItemIconType {
  DEVICE = 'DEVICE',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  WAREHOUSE = 'WAREHOUSE',
  SHIPPING = 'SHIPPING',
  PRODUCTION = 'PRODUCTION',
  LOADING = 'LOADING',
  SITE = 'SITE',
  INVENTORY = 'INVENTORY',
  PACKAGE = 'PACKAGE',
  ORDER = 'ORDER',
  NONE = 'NONE',
}

export enum DashboardItemState {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  CRITICAL = 'CRITICAL',
  NORMAL = 'NORMAL',
}
