import React from 'react';
import styled from 'styled-components';
import { media } from '../../../../../styles/media';
import { Text, TextType } from '@bit/first-scope.text';
import moment from 'moment';
import { withTableContextProvider } from '../../../../../features/universalTable/context';
import { UniversalTable } from '../../../../../features/universalTable/UniversalTable';
import { API_URL } from '../../../../../consts';
import { View } from '../../../../../routes/routeInterfaces';
import { DeviceDetailsType } from '../../../../../enums/View';
import { useWindowSize } from '../../../../../styles/style.context';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  ${media.desktop`
    max-width: 1024px;
    margin-top: 30px;
  `}
`;

const WrapperText = styled(Text)`
  margin-bottom: 15px;
`;

const DeviceLogsTablePlain = ({
  id,
  pathParams,
  type,
  languageStrings,
}: {
  id: string;
  pathParams: any;
  type: DeviceDetailsType;
  languageStrings: any;
}) => {
  const offsetKey = 'offset';
  const offset = pathParams[offsetKey];
  const keyword = 'PackagesInfoTable';
  const width = useWindowSize()[0];

  return (
    <Wrapper>
      <WrapperText type={TextType.SEMITITLE}>
        {languageStrings ? languageStrings.titleOfDeviceLogs : 'Device Logs'}
      </WrapperText>
      <UniversalTable
        mobileHeadless
        placeholderProps={{ keyword, placeholderOptions: { countRows: 7 } }}
        updateItems={() => {}}
        keyword={keyword}
        offsetKey={offsetKey}
        offset={offset}
        pathParams={pathParams}
        tablePath={
          type === DeviceDetailsType.NODE
            ? View.USER_MANAGE_DEVICES_DETAILS_NODE
            : View.USER_MANAGE_DEVICES_DETAILS_TRACKER
        }
        mainApiUrl={`${API_URL}/v1/devices/${id}/events/`}
        inlineActionSet={[]}
        withHeaderInMobile={width < 1250}
        withHeaderGridTemplate={'1fr 1fr 2fr'}
        withRowGridTemplate={'1fr 1fr 2fr'}
        columnStructure={[
          {
            fieldName: 'ts',
            placeholder: '',
            label: languageStrings.labelDate || 'Date',
            defaultValue: new Date(),
            disabled: true,
            getTextDisplay: (value: any) => moment(value).format('DD.MM.YYYY') || 'N/A',
            shownInHeader: width < 1250,
          },
          {
            fieldName: 'ts',
            placeholder: '',
            label: languageStrings.labelTime || 'Time',
            defaultValue: new Date(),
            disabled: true,
            getTextDisplay: (value: any) => moment(value).format('HH:mm') || 'N/A',
            shownInHeader: width < 1250,
          },
          {
            fieldName: 'event_type',
            placeholder: languageStrings.logsPlaceholder || 'Logs',
            label: languageStrings.labelLogs || 'Logs',
            getTextDisplay: (value: any) => value.desc,
            defaultValue: '',
            shownInHeader: width < 1250,
          },
        ]}
      />
    </Wrapper>
  );
};
export const LogsTable = withTableContextProvider(DeviceLogsTablePlain);
