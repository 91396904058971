/**
 * @description
 * This is component for animation SpinnerDoubleBounce
 *
 */

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colorFetch } from '../../styles/utils';

const loadingBounce = keyframes`
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
`;

const Wrapper = styled.div`
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
`;

type BounceProps = {
  backgroundColor?: string;
};

const Bounce = styled.div<BounceProps>`
  &.double-bounce1,
  &.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${({ backgroundColor, theme }) => backgroundColor || colorFetch('primary')({ theme })};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: ${loadingBounce} 2s infinite ease-in-out;
    animation: ${loadingBounce} 2s infinite ease-in-out;
  }

  &.double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
`;

export const SpinnerDoubleBounce = ({
  className,
  backgroundColor,
}: {
  className?: string;
  backgroundColor?: string;
}) => {
  return (
    <Wrapper className={className}>
      <Bounce className="double-bounce1" backgroundColor={backgroundColor} />
      <Bounce className="double-bounce2" backgroundColor={backgroundColor} />
    </Wrapper>
  );
};
