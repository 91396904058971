/**
 * @description
 * This is component that displays info about zone on zonesFloorplan
 */

import React from 'react';
import { DesktopView } from './components/desktopView/DesktopView';
import { MobileView } from './components/mobileView/MobileView';

export const DescriptionPopup = ({
  isEdit,
  value,
  height,
  onChange,
  closeModal,
  pixel_ratio,
  pictureSize,
  isRight,
  accessData,
  isEditingStart,
  attemptDelete,
  intersectingZonesCount,
  validatingIsStarted,
  setSavedZones,
  handleScrollToTop,
  locations,
}: {
  attemptDelete: () => void;
  closeModal: any;
  value: any;
  height: any;
  onChange: any;
  isEdit?: boolean;
  validatingIsStarted: boolean;
  intersectingZonesCount: number;
  pixel_ratio: number;
  pictureSize: { height: number; width: number };
  isRight?: boolean;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  isEditingStart?: boolean;
  setSavedZones?: (val: any) => void;
  handleScrollToTop?: (val: string) => void;
  locations?: any;
}) => {
  return (
    <>
      <DesktopView
        pictureSize={pictureSize}
        isEdit={isEdit}
        value={value}
        height={height}
        onChange={onChange}
        closeModal={closeModal}
        pixel_ratio={pixel_ratio}
        isRight={isRight}
        accessData={accessData}
        isEditingStart={isEditingStart}
        attemptDelete={attemptDelete}
        intersectingZonesCount={intersectingZonesCount}
        validatingIsStarted={validatingIsStarted}
      />
      <MobileView
        value={value}
        closeModal={closeModal}
        setSavedZones={setSavedZones}
        handleScrollToTop={handleScrollToTop}
        locations={locations}
      />
    </>
  );
};
