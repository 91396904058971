/**
 * @description
 * This is component to display badges on the map
 * all the functionalities cthings require for InsightBadges
 * Highly customisable.
 *
 */

import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styles';
import { borderRadiusFetch } from '../../styles/utils';

type WrapperProps = {
  backgroundColor?: string;
  color?: string;
};

const Wrapper = styled.div<WrapperProps>`
  width: 72px;
  height: 20px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ backgroundColor }) => backgroundColor};

  border-radius: ${borderRadiusFetch('primary')};
  padding: 0 8px;

  cursor: pointer;

  & > span {
    font-weight: 700;
    line-height: 15px;
    color: ${({ color }) => color};
  }
`;

export enum BadgesType {
  MISC_TEAL = 'MISC_TEAL',
  MISC_PURPLE = 'MISC_PURPLE',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  PRIMARY = 'PRIMARY',
  REMINDER = 'REMINDER',
  UNKNOWN = 'UNKNOWN',
}

export enum ContentType {
  TAG_PACKAGE = 'TAG_PACKAGE',
  TAG_INVENTORY = 'TAG_INVENTORY',
  UNKNOWN = 'UNKNOWN',
  NONE = 'NONE',
}

export interface InsightBadgesProps {
  type: BadgesType;
  content: string;
}

export const InsightBadges: FC<InsightBadgesProps> = ({ type, content }) => {
  const theme = useTheme();
  const { gray2, gray5, blue4, blue5, green5, orange1, orange2, orange5, orange3, red2, red1, red4 } = theme.colors;

  const [background, setBackground] = useState<string | undefined>('');
  const [fontColor, setFontColor] = useState<string | undefined>('');
  const [typeContent, setTypeContent] = useState<string | undefined>('');

  useEffect(() => {
    switch (type) {
      case BadgesType.MISC_TEAL:
        setBackground(orange3);
        setFontColor(orange1);
        break;
      case BadgesType.MISC_PURPLE:
        setBackground(orange3);
        setFontColor(orange1);
        break;
      case BadgesType.SUCCESS:
        setBackground(green5);
        setFontColor(red2);
        break;
      case BadgesType.WARNING:
        setBackground(orange5);
        setFontColor(orange2);
        break;
      case BadgesType.DANGER:
        setBackground(red4);
        setFontColor(red1);
        break;
      case BadgesType.PRIMARY:
        setBackground(blue5);
        setFontColor(blue4);
        break;
      case BadgesType.REMINDER:
        setBackground(orange5);
        setFontColor(orange3);
        break;
      case BadgesType.UNKNOWN:
        setBackground(gray5);
        setFontColor(gray2);
        break;
      default:
        setBackground('');
        setFontColor('');
    }
  }, [type]);

  useEffect(() => {
    switch (content) {
      case ContentType.TAG_PACKAGE:
        setTypeContent('TRACKER');
        break;
      case ContentType.TAG_INVENTORY:
        setTypeContent('NODE');
        break;
      case ContentType.UNKNOWN:
        setTypeContent('UNKNOWN');
        break;

      default:
        setBackground('');
        setFontColor('');
    }
  }, [content]);

  return (
    <Wrapper backgroundColor={background} color={fontColor} theme={theme}>
      <Text type={TextType.TEXT_10_WHITE}>{typeContent}</Text>
    </Wrapper>
  );
};

InsightBadges.defaultProps = {};
