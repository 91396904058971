/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for connection status require
 * Highly customisable.
 *
 */

import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { Text, TextType } from '@bit/first-scope.text';
import { useCustomHistory } from '../../../../../utils/react-router-dom-abstraction';
import { View } from '../../../../../routes/routeInterfaces';
import { DropdownMultiChoice } from '../dropdownMultiChoice/DropdownMultiChoice';
import { useTheme } from '@cthings.co/styles';

const StyledText = styled(Text)`
  &.link {
    text-decoration: underline;
    cursor: pointer;
  }
` as any; // @TODO fix type

const prepareData = (value: { id: string; index: string }[], values: { id: string; name: string }[]) =>
  values.map((item) => ({
    ...item,
    isSelected: !!(value || []).find((selectedItem) => selectedItem.id === item.id),
  }));

const IndexMultiSelectPlain = ({
  setValue,
  stateIndex,
  value,
  values,
  label,
  disabled,
  language,
  maxSelectableItems = 1,
}: {
  setValue: any;
  stateIndex: TableCustomComponentState | undefined;
  value: { id: string; index: string; offset: number }[];
  values: { id: string; name: string }[];
  label?: string;
  disabled?: boolean;
  language?: any;
  maxSelectableItems?: number;
}) => {
  const theme = useTheme();
  const { gray1, gray2 } = theme.colors;
  const customHistory = useCustomHistory();
  const [items, setItems] = useState(prepareData(value, values));
  const [isIndexListOpen, setIsIndexListOpen] = useState(false);
  const wrapperRef = useRef<any>(null);
  const noIndexSelected = value.length === 0;

  const checkIndex = (id: string) => {
    setItems((currentItems) => {
      const index = currentItems.findIndex(({ id: itemId }) => itemId === id);
      if (index !== -1) {
        const { isSelected } = currentItems[index];
        if (!isSelected) {
          if (maxSelectableItems === 1) {
            const updatedItem = { ...currentItems[index], isSelected: !isSelected };
            const updatedCurrentItems = currentItems.map((item) => ({ ...item, isSelected: false }));

            const result = [
              ...updatedCurrentItems.slice(0, index),
              updatedItem,
              ...updatedCurrentItems.slice(index + 1),
            ];

            setValue([updatedItem]);
            return result;
          }

          if (maxSelectableItems === value.length) {
            // callNotification

            return currentItems;
          }
        }
        const updatedItem = { ...currentItems[index], isSelected: !isSelected };
        const result = [...currentItems.slice(0, index), updatedItem, ...currentItems.slice(index + 1)];
        const selectedParameters = result.filter((index: any) => index.isSelected);
        setValue(selectedParameters);
        return result;
      } else {
        const selectedParameters = currentItems.filter((index: any) => index.isSelected);
        setValue(selectedParameters);
        return currentItems;
      }
    });
  };

  const selectAllIndex = () => {
    setItems((currentItems) => currentItems.map((currentItem) => ({ ...currentItem, isSelected: true })));
  };

  const handleRemoveSelectedIndex = (id: string) => {
    setItems((currentItems) => {
      const index = currentItems.findIndex(({ id: itemId }) => itemId === id);
      if (index !== -1) {
        return [...currentItems.slice(0, index), ...currentItems.slice(index + 1)];
      } else {
        return currentItems;
      }
    });
  };

  const handleLink = () => {
    customHistory.push(View.MANAGE_COMPONENTS, {
      pathParams: { offset: value[0]?.offset },
      state: { id: value[0]?.id },
    });
  };

  return (
    <>
      {stateIndex === TableCustomComponentState.VIEW_MODAL || stateIndex === TableCustomComponentState.EDIT ? (
        <DropdownMultiChoice
          parameters={items}
          options={{
            placeholder: language.multipleSelectIndexPlaceholder || 'Choose index',
            title: 'Index',
            isValidatingStarted: false,
            isError: noIndexSelected,
            isOpenSelectList: isIndexListOpen,
            popUpScrollTo: 244,
            popUpScrollToWithCustomPeriod: 288,
            wrapperRef,
            isDisabled: !(stateIndex === TableCustomComponentState.EDIT),
            disabledPlaceholder: language.multipleSelectIndexPlaceholder || 'Choose index',
          }}
          handleCheckedItem={(id: string) => checkIndex(id)}
          handleRemoveSelectedItem={({ id }: { id: string }) => {
            handleRemoveSelectedIndex(id);
          }}
          handleSelectAll={() => {
            selectAllIndex();
          }}
          setIsOpenSelectList={(value: any) => {
            setIsIndexListOpen(!!value);
          }}
          isEditInProcess={stateIndex === TableCustomComponentState.EDIT}
          languageStrings={{}}
          setValue={setValue}
        />
      ) : (
        <>
          {value && value.length > 0 ? (
            <StyledText
              type={TextType.TEXT_14_GRAY}
              color={gray1}
              weight={'400'}
              className={'link'}
              onClick={handleLink}
              onTouchStart={handleLink}
            >
              {value[0]?.index}
            </StyledText>
          ) : (
            <StyledText type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'}>
              {'N/A'}
            </StyledText>
          )}
        </>
      )}
    </>
  );
};

export const IndexMultiSelect = React.memo(IndexMultiSelectPlain);
