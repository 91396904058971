import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../../../../styles/media';
import { deletePackageGlobal, updatePackage } from '../../../../../api/manageOrderDetails';
import { UniversalTable } from '../../../../../features/universalTable/UniversalTable';
import { EXTRA_KEY, useTableFunctions, withTableContextProvider } from '../../../../../features/universalTable/context';
import {
  ActionType,
  InlineActionType,
  ModalApiType,
  RowItemType,
  TableCustomComponentState,
} from '../../../../../features/universalTable/types';
import { AddButton } from '../../../../../components/addButton/AddButton';
import { Text, TextType } from '@bit/first-scope.text';
import { API_URL, NEW_ID } from '../../../../../consts';
import { View } from '../../../../../routes/routeInterfaces';
import { useMediaType } from '../../../../../styles/style.context';
import { useLocation } from 'react-router-dom';
import { useNotifierFunctions } from '../../../../../features/notifier2';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { Tracker } from '../../../../../components/tracker/Tracker';
import { LocationButtonInTable } from '../../../../../components/locationButtonInTable/LocationButtonInTable';
import { useCustomHistory } from '../../../../../utils/react-router-dom-abstraction';
import { onlyPositiveNumber } from '../../../../../utils/validation';
import { IndexComponent } from './components/indexComponent/IndexComponent';
import { Comments } from './components/commentsTest/Comments';
import { StagesList } from './components/stage/StagesList';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 46px;
  ${media.desktop`
    max-width: 1024px;
    margin-top: 30px;
  `}
`;

const WrapperText = styled(Text)`
  margin-bottom: 15px;
`;

export interface PackagesInfoPlainProps {
  id: string;
  pathParams: any;
  toggleTrigger: any;
  idItemForViewModal?: string;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  offsetFromSmartSearch?: number;
  setIsReloading?: (isLoading: boolean) => void;
}

const PackagesInfoPlain: FC<PackagesInfoPlainProps> = ({
  id,
  pathParams,
  toggleTrigger,
  idItemForViewModal,
  accessData,
  //this offset need for show certain page during from smart search redirect
  offsetFromSmartSearch,
  setIsReloading,
}) => {
  const offsetKey = 'packagesOffset';
  const keyword = 'PackagesInfo';

  const { startAddProcess } = useTableFunctions();
  const { tablet } = useMediaType();
  const { addNotification } = useNotifierFunctions();
  const language = useSelector(selectLanguageStrings);
  const customHistory = useCustomHistory();
  const offset = pathParams[offsetKey];
  const { state }: any = useLocation();

  const [isAddModalFromGlobalSearch, setIsAddModalFromGlobalSearch] = useState(
    state?.state?.isAddModalFromGlobalSearch,
  );

  const handleUpdateItems = (
    package_id: string,
    data: any,
    index: number,
    updateLocalItem: any,
    addNotification: any,
    languageStrings: any,
    setBlockButtons: (status: boolean) => void,
    setSavedData: (status: any) => void,
    viewedData: any,
  ) => {
    updatePackage(
      package_id,
      data,
      index,
      updateLocalItem,
      id,
      toggleTrigger,
      addNotification,
      language,
      setBlockButtons,
      setSavedData,
      viewedData,
    );
  };

  const handleDeletePackages = (packageIds: string[], deleteItem: any, deleteCheckedItems: any) => {
    deletePackageGlobal(packageIds, deleteItem, deleteCheckedItems, id, addNotification, language);
  };

  const resetIsAddModalFromGlobalSearch = () => {
    setIsAddModalFromGlobalSearch(false);
  };

  return (
    <Wrapper>
      <WrapperText type={TextType.SEMITITLE}>{language ? language.titlePackagesInfo : 'Packages info'}</WrapperText>
      <UniversalTable
        placeholderProps={{
          keyword,
          style: { height: 'max-content' },
          conditionalStyle: { overflow: 'hidden' },
          placeholderOptions: { countRows: 6 },
        }}
        modalColumnCount={3}
        deleteItems={handleDeletePackages}
        mainApiUrl={`${API_URL}/v1/order/details/${id}/packages`}
        pathParams={pathParams}
        tablePath={View.MANAGE_ORDERS_DETAILS}
        offset={offsetFromSmartSearch ? offsetFromSmartSearch : offset}
        offsetKey={offsetKey}
        keyword={keyword}
        updateItems={handleUpdateItems}
        accessData={accessData}
        isLocationButton
        titleActionComponent={
          accessData?.edit && (
            <AddButton
              onClick={() => {
                startAddProcess();
              }}
              title={tablet ? 'Add' : language ? language.addPackageButton : 'Add package'}
              width={tablet ? '72px' : '120px'}
              height="32px"
            />
          )
        }
        actionSet={[
          {
            type: ActionType.EDIT,
          },
        ]}
        inlineActionSet={[
          {
            type: InlineActionType.CUSTOM,
            component: (value: any) => {
              return value?.tracker ? <LocationButtonInTable onClick={() => {}} /> : null;
            },
            callback: (data: any) => {
              const { tracker } = data;
              return tracker
                ? customHistory.push(View.USER_INSIGHTS_DETAILS, {
                    pathParams: { id: tracker?.location?.wpas_ref_building_id, name: tracker?.name },
                  })
                : null;
            },
          },
          {
            type: InlineActionType.DETAILS,
          },
        ]}
        modalApi={{
          type: ModalApiType.GET,
          url: (package_id: string) =>
            `${API_URL}/v1/order/details/${id}/package/${package_id === NEW_ID ? '' : package_id}`,
        }}
        idItemForViewModal={idItemForViewModal}
        keywordAddModalGlobalSearch={isAddModalFromGlobalSearch && keyword}
        resetIsAddModalFromGlobalSearch={resetIsAddModalFromGlobalSearch}
        withHeaderInMobile={tablet}
        withHeaderGridTemplate={'1fr 1fr 1fr'}
        withRowGridTemplate={'1fr 1fr 1fr'}
        triggerUpdate={() => {
          setIsReloading && setIsReloading(true);
        }}
        isRevertDataAfterErrorApi
        columnStructure={[
          {
            fieldName: 'name',
            placeholder: language.enterNamePlaceholder,
            label: language.labelName,
            defaultValue: '',
            shownInHeader: tablet,
            width: '1.3fr',
          },
          {
            fieldName: 'component',
            placeholder: language.enterProductionIDPlaceholder,
            type: RowItemType.INPUT,
            label: language.labelProductionID,
            defaultValue: '',
            excludedFromAdd: true,
            disabled: true,
            width: '0.7fr',
            getTextDisplay: (value: any) => value.production_id,
          },
          {
            fieldName: 'quantity',
            type: RowItemType.INPUT_NUMBER,
            placeholder: language.enterQuantityPlaceholder,
            label: language.labelQuantity,
            conditionForValidationInCustom: onlyPositiveNumber,
            excludedFromView: true,
            defaultValue: 1,
          },
          {
            fieldName: 'component',
            placeholder: language.multipleSelectInputPlaceholder,
            label: language.labelIndex,
            type: RowItemType.CUSTOM,
            shownInHeader: tablet,
            customComponentHasSelectView: true,
            defaultValue: { name: '', id: '' },
            component: (value: any, state?: TableCustomComponentState, setValue?: any) => {
              return (
                <IndexComponent
                  value={value}
                  setValue={setValue}
                  orderId={id}
                  state={state}
                  disabled={state === TableCustomComponentState.VIEW_MODAL}
                />
              );
            },
          },
          {
            fieldName: 'component',
            placeholder: language.enterDescriptionPlaceholder,
            type: RowItemType.JOINED_SELECT,
            label: language ? `${language.description} 1` : 'Description 1',
            defaultValue: { name: '', id: '' },
            excludedFromAdd: true,
            disabled: true,
            getTextDisplay: (value: any) => value.description_1 || 'N/A',
            width: '1.5fr',
          },
          {
            fieldName: 'component',
            placeholder: language.labelNA,
            type: RowItemType.JOINED_SELECT,
            label: language ? `${language.description} 2` : 'Description 2',
            defaultValue: { name: '', id: '' },
            excludedFromAdd: true,
            excludedFromView: true,
            disabled: true,
            getTextDisplay: (value: any) => value.description_2 || 'N/A',
            width: '1.5fr',
          },
          {
            fieldName: 'process',
            placeholder: language.firstChooseIndexPlaceholder,
            label: language.labelProcess,
            type: RowItemType.JOINED_SELECT,
            selectItems: [],
            defaultValue: { name: '', id: '' },
            showDisabledInAdd: true,
            disabled: true,
            excludedFromView: true,
          },
          {
            fieldName: 'quantity',
            type: RowItemType.INPUT_NUMBER,
            placeholder: language.enterQuantityPlaceholder,
            label: language.labelQuantity,
            conditionForValidationInCustom: onlyPositiveNumber,
            excludedFromModalView: true,
            excludedFromAdd: true,
            defaultValue: 1,
            width: '0.7fr',
          },
          {
            fieldName: 'stage',
            placeholder: language.firstChooseIndexPlaceholder,
            label: language.labelStage,
            type: RowItemType.CUSTOM,
            customComponentHasSelectView: true,
            defaultValue: { name: '', id: '' },
            component: (value: any, state?: TableCustomComponentState, setValue?: any) => {
              return <StagesList value={value} setValue={setValue} orderId={id} state={state} />;
            },
            width: '1.5fr',
          },
          {
            fieldName: 'status',
            placeholder: language.selectStatusPlaceholder,
            label: language.labelStatus,
            type: RowItemType.JOINED_SELECT,
            pathToSelectItems: [`${EXTRA_KEY}available`, 'status'],
            defaultValue: { name: '', id: '' },
            excludedFromView: true,
          },
          {
            fieldName: 'tracker',
            placeholder: '',
            label: language.clusteredContentTracker,
            defaultValue: { name: '', id: '' },
            type: RowItemType.CUSTOM,
            pathToSelectItems: [`${EXTRA_KEY}available`, 'devices'],
            customComponentHasSelectView: true,
            conditionForValidationInCustom: 'not valid',
            component: (value: any, state?: TableCustomComponentState, setValue?: any, values?: any) => {
              return (
                <Tracker
                  state={state}
                  setValue={setValue}
                  value={value}
                  values={values}
                  defaultSelectValue={language ? language.notAttachedPlaceholder : 'Not attached'}
                  disabled={state === TableCustomComponentState.VIEW_MODAL}
                  language={language}
                />
              );
            },
          },
          {
            fieldName: 'comments',
            placeholder: '',
            label: language.labelRemarks,
            defaultValue: [],
            modalColumns: 3,
            type: RowItemType.CUSTOM,
            excludedFromView: true,
            excludedFromAdd: true,
            component: (value: any, state?: TableCustomComponentState) => <Comments state={state} orderId={id} />,
          },
        ]}
      />
    </Wrapper>
  );
};

export const PackagesInfo = withTableContextProvider(PackagesInfoPlain);
