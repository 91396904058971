import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { media } from '../../../../../../../styles/media';
import { View } from '../../../../../../../routes/routeInterfaces';
import { useCustomHistory } from '../../../../../../../utils/react-router-dom-abstraction';
import { colorFetch } from '../../../../../../../styles/utils';
import { RedirectItemType } from '../../../firstTableInfo/FirstTableInfo';

type WrapperProps = {
  isFirst?: boolean;
  isLast?: boolean;
  gridTemplate?: string;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: grid;
  height: max-content;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate || '1.25fr 1.5fr'};
  grid-gap: 24px;
  padding: 11px 22px 10px 22px;
  box-sizing: border-box;
  background-color: ${colorFetch('pureWhite')};
  border-top: ${({ isFirst }) => (isFirst ? 'none' : '1px solid')};
  border-color: ${colorFetch('gray4')};
  border-radius: ${({ isLast }) => (isLast ? '0 0 8px 8px' : 'none')};

  &:first-child {
    border-top: none;
  }
  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  ${media.phone`
    height: max-content;
    padding: 11px 18px 11px 16px;
`}
`;

const WrapperText = styled(Text)`
  color: ${colorFetch('gray1')};
  &.underline {
    text-decoration: underline;
    color: ${colorFetch('primary')};
    cursor: pointer;
    &:hover {
      color: ${colorFetch('secondary3')};
    }
  }
` as any; // @TODO fix type

export const TableRow = ({
  data,
  property,
  value,
  gridTemplate,
  offset,
  ...props
}: {
  data: any;
  property: string;
  value: string;
  offset?: number;
  gridTemplate?: string;
  className?: string;
}) => {
  const { type, orderId, packageId, locationId, zoneId, deviceName, isClickable } = data;

  const customHistory = useCustomHistory();

  const redirectToOrder = () => {
    customHistory.push(View.MANAGE_ORDERS_DETAILS, {
      state: { package_id: packageId, offset },
      pathParams: { id: orderId, componentsOffset: 0, packagesOffset: 0, eventsOffset: 0 },
    });
  };

  const redirectToPackage = () => {
    customHistory.push(View.MANAGE_ORDERS_DETAILS, {
      pathParams: { id: orderId, componentsOffset: 0, packagesOffset: 0, eventsOffset: 0 },
      state: { id: packageId, offset },
    });
  };

  const redirectToLocation = () => {
    customHistory.push(View.USER_INSIGHTS_DETAILS, {
      pathParams: { id: locationId, name: deviceName },
    });
  };

  const redirectToZone = () => {
    customHistory.push(View.MANAGE_ZONES_DETAILS, {
      state: { zone_id: zoneId },
      pathParams: { building_id: locationId, floor: 0, zone_id: zoneId ?? 'noselect', offset: 0 },
    });
  };

  const getRedirectData = (type: RedirectItemType) => {
    const data: any = {
      [RedirectItemType.LOCATION]: () => redirectToLocation(),
      [RedirectItemType.ZONE]: () => redirectToZone(),
      [RedirectItemType.PACKAGE]: () => redirectToPackage(),
      [RedirectItemType.ORDER]: () => redirectToOrder(),
    };

    return data[type];
  };

  return (
    <Wrapper gridTemplate={gridTemplate} {...props}>
      <WrapperText type={TextType.TEXT_14_BLACK} weight={'400'}>
        {property}
      </WrapperText>
      <WrapperText
        onClick={getRedirectData(type)}
        type={TextType.TEXT_14_BLACK}
        className={value && isClickable ? 'underline' : ''}
        weight={'500'}
      >
        {value || 'N/A'}
      </WrapperText>
    </Wrapper>
  );
};
