import { API_URL } from '../consts';
import { HttpService } from '../utils/http';
import ls from '../utils/ls';
import { LsValueType } from '../enums/LsValueType';
import { ErrorEventType } from '../types/error';
import { useApi } from '../features/apiBuilder/useApiBuilder';
import { QueryKeys } from './types';
import { Document } from '../containers/common/documentsPreview/DocumentsPreview';
import { handleUncoveredNotification } from '../utils/handleUncoveredNotification';
import { View } from '../routes/routeInterfaces';

export const updateDocument = (
  id: string,
  data: {
    name: string;
    metadata: any;
    client_id: any;
    files: any;
    attached_component: any;
    attached_components: any;
    certificates: any;
    certificates_ids: any;
    attached_order: any;
    password_protected: any;
    password: any;
  },
  index: number,
  updateLocalItem: any,
  handleAddNotification: Function,
  client_id: string,
  language: Object,
  setBlockButtons: (status: boolean) => void,
) => {
  const token = ls.get(LsValueType.token);

  if (id !== 'NEW_ID') {
    setBlockButtons(true);
    const {
      attached_order,
      password_protected,
      password,
      files,
      attached_component,
      certificates,
      ...remainingData
    } = data;
    const url = `${API_URL}/v2/documents/${id}?access_token=${token}`;

    const filesNew = data.files.newItems;
    const filesOld = data?.files?.oldItems?.map((item: any) => item.id);
    const attachedComponentIds = data?.attached_component?.map((item: any) => item.id);
    const certificatesIds = data?.certificates?.map((item: any) => {
      if (item?.id) {
        return item?.id;
      }
      return item;
    });

    const preparedData = {
      ...remainingData,
      client_id: client_id || '6012a7c3b9dcc2c7555c5cab',
      attached_order: attached_order?.id,
      password,
    };

    const getFormData = (data: any) => {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (typeof data[key] !== 'object') formData.append(key, data[key]);
        else formData.append(key, JSON.stringify(data[key]));
      });

      filesOld.forEach((file: any) => {
        formData.append('files', file);
      });
      filesNew.forEach((file: any) => {
        formData.append('files_to_attach', file);
      });

      attachedComponentIds.length
        ? attachedComponentIds.forEach((file: any) => {
            formData.append('attached_components', file);
          })
        : formData.append('attached_components', '[]');

      certificatesIds.length
        ? certificatesIds.forEach((file: any) => {
            formData.append('certificates_ids', file);
          })
        : formData.append('certificates_ids', '[]');

      return formData;
    };

    (async () => {
      try {
        let responseData = (
          await HttpService.patch(url, getFormData(preparedData), {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        ).data;

        updateLocalItem(index, responseData.payload);
        handleAddNotification({
          message: 'Saved successfully',
          type: 'success',
        });
        setBlockButtons(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleUncoveredNotification(error, handleAddNotification, language);
        setBlockButtons(false);
      }
    })();
  } else {
    setBlockButtons(true);
    const url = `${API_URL}/v2/documents/?access_token=${token}`;
    data.client_id = client_id ? client_id : '6012a7c3b9dcc2c7555c5cab';
    const attachedOrder = data?.attached_order?.id;
    data.attached_order = attachedOrder;
    const attachedComponentIds = data?.attached_component?.map((item: any) => item.id);
    const certificatesIds = data?.certificates?.map((id: any) => id);

    const files = data.files.newItems;
    delete data.files;

    data.attached_component = data.attached_component.id;

    const getFormData = (data: any) => {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (typeof data[key] !== 'object') formData.append(key, data[key]);
        else formData.append(key, JSON.stringify(data[key]));
      });

      files.forEach((file: any) => {
        formData.append('files', file);
      });

      attachedComponentIds.forEach((file: any) => {
        formData.append('attached_components', file);
      });

      certificatesIds.forEach((file: any) => {
        formData.append('certificates_ids', file);
      });

      return formData;
    };

    (async () => {
      try {
        let responseData = (
          await HttpService.post(url, getFormData(data), {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        ).data;
        updateLocalItem(responseData.payload.id, responseData.payload);
        handleAddNotification({
          message: 'Saved successfully',
          type: 'success',
        });
        setBlockButtons(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleUncoveredNotification(error, handleAddNotification, language);
        setBlockButtons(false);
      }
    })();
  }
};

export const deleteDocument = (
  id: string[],
  deleteDocument: any,
  deteleCheckedDocuments: any,
  handleAddNotification: Function,
  language: Object,
) => {
  const token = ls.get(LsValueType.token);
  const data = { document_ids: id };
  const url = `${API_URL}/v2/documents/?access_token=${token}`;
  const deleteOneDocument = () => deleteDocument(id[0]);

  (async () => {
    try {
      await HttpService.delete(url, data);
      if (id.length === 1) {
        deleteOneDocument();
      } else {
        deteleCheckedDocuments();
      }
      handleAddNotification({
        message: 'Successfully deleted',
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      handleUncoveredNotification(error, handleAddNotification, language);
    }
  })();
};

export const useGetDocumentsData = (
  id: string,
  id_order: string | null,
  lang: string | null,
  setIsLoading: (isLoading: boolean) => void,
  password?: string,
) => {
  const urlApi = `${API_URL}/v2/documents/${id}?lang=${lang}&password=${password}`;

  const [data] = useApi({
    defaultValue: {},
    url: urlApi,
    keywords: [QueryKeys.GET_DOCUMENTS_PREVIEW],
    transformResponse: (data) => data.payload,
    handleSuccess: () => setIsLoading(false),
  });

  return data as Document;
};

export const getDocumentsDataWithPassword = (
  id: string,
  lang: string | null,
  setIsLoading: (isLoading: boolean) => void,
  password: string,
  handleAddNotification: Function,
  history: any,
) => {
  const urlApi = `${API_URL}/v2/documents/${id}?lang=${lang}&password=${password}`;
  (async () => {
    try {
      setIsLoading(true);
      let responseData = (await HttpService.get(urlApi)).data;
      const { payload } = responseData;

      history.push(View.DOCUMENTS_PREVIEW, { pathParams: { id }, state: { password }, query: { lang } });
      setIsLoading(false);
      return payload;
    } catch (e) {
      const error = e as ErrorEventType;
      handleAddNotification({
        message: error?.response?.data?.detail,
        type: 'error',
      });
      setIsLoading(false);
    }
  })();
};
