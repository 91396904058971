/**
 * @description
 * This is component hoc for mobile draggable container
 * all the functionalities cthings require for withMobileDraggableContainer
 * Highly customisable.
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';
import { media } from '../../styles/media';
import { useStyleContext } from '../../styles/style.context';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';

type ContainerProps = {
  mobileTable: boolean;
  isDraggable: boolean;
};

const Container = styled.div<ContainerProps>`
  position: fixed;
  z-index: 99;
  bottom: 49px;
  left: 0;
  right: 0;
  transform: ${({ mobileTable, isDraggable }) => (mobileTable && isDraggable ? 'translateY(0)' : 'translateY(70%)')};
  background-color: ${colorFetch('pureWhite')};
  padding-top: 10px;
  border-radius: ${borderRadiusFetch('primary')} ${borderRadiusFetch('primary')} 0 0;
  &:before {
    content: '';
    ${media.tablet`
        position: absolute;
        z-index: 99;
        top: 10px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 4px;
        background-color: ${colorFetch('black1')};
        opacity: 0.2;
      `}
  }
`;

type BackgroundProps = {
  mobileTable: boolean;
};

const Background = styled.div<BackgroundProps>`
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${colorFetch('black1')};
  opacity: 0.6;
  display: ${({ mobileTable }) => (mobileTable ? 'block' : 'none')};
`;

export const withMobileDraggableContainer = (isDraggable: boolean) => {
  const enhance = (Component: any) => ({ ...props }) => {
    const [style] = useStyleContext();
    const { mediaType } = style;

    const [mobileTable, setMobileTable] = useState(false);

    const handlers = useSwipeable({
      onSwipedUp: () => setMobileTable(true),
      onSwipedDown: () => setMobileTable(false),
      preventScrollOnSwipe: false,
    });
    return (
      <>
        {mediaType.tablet ? (
          <>
            <Container mobileTable={mobileTable} isDraggable={isDraggable} {...handlers}>
              <Component {...props} />
            </Container>
            <Background mobileTable={mobileTable} />
          </>
        ) : (
          <Component {...props} />
        )}
      </>
    );
  };
  return enhance;
};
