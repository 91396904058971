/**
 * @description
 * This is component for device filtering floor Plan mobile version.
 *
 */

import React from 'react';
import styled from 'styled-components';
import { SelectWithModal } from '../../../../../../components/selectWithModal/SelectWithModal';
import { useMobileFilters } from '../../../../../../containers/common/campusMapInsight/Content/mobileFilterContext/mobileContext';
import { getIsNodes } from '../../../../../../containers/common/campusMapInsight/Content/filterContext/selectors';
import { getIsAllDeviceSelect } from '../../../../../../containers/common/campusMapInsight/Content/mobileFilterContext/selectorMobile';

const WrapperSelectWithModal = styled(SelectWithModal)`
  margin-top: 20px;
  padding: 20px;
`;

export const DeviceFilterMobile = ({ languageStrings }: any) => {
  const { stateMobile, dispatchMobile } = useMobileFilters();
  const { devicesFilter } = stateMobile?.secondModal?.secondModalDeviceState;
  const { checkedDevicesCount } = stateMobile?.firstModal?.firstModalDeviceState;

  const isSelectAllDeviceSecondModal = getIsAllDeviceSelect(
    stateMobile?.secondModal?.secondModalDeviceState?.devicesFilter,
  );
  const isSelectAllDeviceFirstModal = getIsAllDeviceSelect(
    stateMobile?.firstModal?.firstModalDeviceState?.devicesFilter,
  );

  const isNodes = getIsNodes(stateMobile);

  const handleSelectAllDevices = () =>
    dispatchMobile({ type: 'SELECT_ALL_DEVICE', payload: isSelectAllDeviceSecondModal });

  const handleSelectOneDevices = (type: string) =>
    dispatchMobile({ type: 'SELECT_ONE_DEVICE', payload: { type: type } });

  const handleClearAllDevices = () => dispatchMobile({ type: 'SELECT_CLEAR_ALL_DEVICE' });

  const handleDone = () => dispatchMobile({ type: 'HANDLE_DONE_DEVICE_MODAL' });

  const handleCloseModal = () => dispatchMobile({ type: 'HANDLE_CLOSE_DEVICE_MODAL' });

  const devicesParamState = Object.keys(devicesFilter).map((key: any) => devicesFilter[key]);

  return (
    <WrapperSelectWithModal
      maxWidth={'100%'}
      minHeight={'40px'}
      name={languageStrings ? languageStrings.labelDevices : 'Devices'}
      fontSizeCount={'14px'}
      parameterList={devicesParamState}
      handleCheckedItem={handleSelectOneDevices}
      handleSelectAll={handleSelectAllDevices}
      isCheckedAll={isSelectAllDeviceSecondModal}
      isSelectAllFirstModal={isSelectAllDeviceFirstModal}
      checkedCount={checkedDevicesCount}
      handleClearAll={handleClearAllDevices}
      isDisabled={!isNodes}
      closeModal={handleCloseModal}
      handleDone={handleDone}
    />
  );
};
