import React, { FC } from 'react';
import styled from 'styled-components';
import { TextareaAutosize } from '@material-ui/core';
import { DeleteButton } from '../../../../../components/deleteButton/DeleteButton';
import { isStringConsistOfSpaces } from '../../../../../utils/validation';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { colorFetch } from '../../../../../styles/utils';

const WrapperComments = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

type ContainerProps = {
  isFirst?: boolean;
};

const Container = styled.div<ContainerProps>`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 20px;
  padding: 13px 0px;
  border-bottom: ${({ isFirst }) => (isFirst ? 'none' : '1px solid')};
  border-color: ${colorFetch('gray3')};
`;

type CommentTextareaProps = {
  isEdit?: boolean;
  isValue?: boolean;
};

const CommentTextarea = styled(TextareaAutosize)<CommentTextareaProps>`
  margin: 0;
  width: 100%;
  max-height: ${({ isEdit }) => (isEdit ? '72' : '112')}px;
  overflow: overlay;
  font-family: 'Poppins', sans-serif;
  font-weight: ${({ isValue }) => (isValue ? '500' : 'normal')};
  font-size: 14px;
  line-height: 24px;
  background-color: transparent;
  border: none;
  outline: transparent;
  resize: none;
  box-sizing: border-box;
  color: ${colorFetch('gray1')};
  &::placeholder {
    color: ${colorFetch('gray2')};
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
  }
  &.red {
    &::placeholder {
      color: ${colorFetch('red3')};
    }
  }
`;

export interface ItemMetaDataProps {
  handleDeleteRowMetaData: any;
  propertyMetaData: any;
  setPropertyMetaData: any;
  valueMetaData: any;
  setValueMetaData: any;
  state?: TableCustomComponentState;
  isFirst?: boolean;
  isStartAddingAnotherRow?: boolean;
  language: any;
}

export const ItemMetaData: FC<ItemMetaDataProps> = ({
  handleDeleteRowMetaData,
  propertyMetaData,
  setPropertyMetaData,
  setValueMetaData,
  valueMetaData,
  isFirst,
  isStartAddingAnotherRow,
  state,
  language,
}) => {
  return (
    <WrapperComments>
      <Container isFirst={isFirst}>
        <CommentTextarea
          className={
            isStringConsistOfSpaces(propertyMetaData) || (propertyMetaData?.length === 0 && isStartAddingAnotherRow)
              ? 'red'
              : ''
          }
          value={propertyMetaData}
          placeholder={
            isStringConsistOfSpaces(propertyMetaData) || (propertyMetaData?.length === 0 && isStartAddingAnotherRow)
              ? language
                ? language.requiredFieldPlaceholder
                : 'Required field...'
              : language
              ? language.enterPropertyPlaceholder
              : 'Enter property'
          }
          onChange={setPropertyMetaData}
        />
        <CommentTextarea
          className={
            isStringConsistOfSpaces(valueMetaData) || (valueMetaData?.length === 0 && isStartAddingAnotherRow)
              ? 'red'
              : ''
          }
          value={valueMetaData}
          placeholder={
            isStringConsistOfSpaces(valueMetaData) || (valueMetaData?.length === 0 && isStartAddingAnotherRow)
              ? language
                ? language.requiredFieldPlaceholder
                : 'Required field...'
              : language
              ? language.enterPropertyPlaceholder
              : 'Enter property'
          }
          onChange={setValueMetaData}
          isValue
        />
        {state === TableCustomComponentState.EDIT && (
          <DeleteButton className="rightIcon" onClick={handleDeleteRowMetaData} />
        )}
      </Container>
    </WrapperComments>
  );
};
