import { useEffect, useState } from 'react';
import { NEW_ID } from '../consts';

import { API_URL } from '../consts';
import { useLoaderOptions } from '../features/placeholderComponent/loaderFunctions';
import { DEFAULT_TABLE_DATA } from '../features/universalTable/context';
import { SortingData } from '../features/universalTable/types';
import { ErrorEventType } from '../types/error';
import { HttpService } from '../utils/http';
import { handleUncoveredNotification } from '../utils/handleUncoveredNotification';

export const useStagesList = (
  page: number,
  pageSize: number,
  setStages: any,
  searchField: string,
  sortingData: SortingData,
  setPlaceholder?: any,
  stopPlaceholder?: any,
) => {
  const { sort_field, sort_type } = sortingData;
  const [localStages, setLocalStages] = useState<any>(DEFAULT_TABLE_DATA);
  const offset = pageSize * (page - 1);
  const trigger = [pageSize, offset, searchField, sort_field, sort_type] as const;
  /* eslint-disable react-hooks/exhaustive-deps */
  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);
  useEffect(() => {
    const url = `${API_URL}/v1/stages/?limit=${pageSize}&offset=${offset}${
      sort_field && sort_type ? `&sort_type=${sort_type}&sort_field=${sort_field}` : ''
    }&search_field=${searchField}`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url, loaderOptions)).data;
        const { pagination, payload, metadata } = responseData;
        const result = {
          list: payload.map((item: any) => ({ ...item, isChecked: false, isEdit: false })),
          pagination,
          metadata,
        };
        setStages && setStages(result.list, result.pagination, result.metadata);
        setLocalStages(result);
      } catch (e) {
        //
      }
    })();
  }, [...trigger, loaderOptions]);

  return [localStages.list, localStages.pagination];
};

export const deleteStageGlobal = (
  id: string[],
  deleteStage: any,
  deleteCheckedStages: any,
  handleAddNotification: Function,
  language: any,
) => {
  const data = { stage_ids: id };
  const url = `${API_URL}/v1/stages/delete`;
  const deleteOneStage = () => deleteStage(id[0]);
  (async () => {
    try {
      await HttpService.delete(url, data);
      if (id.length === 1) {
        deleteOneStage();
      } else {
        deleteCheckedStages();
      }
      handleAddNotification({
        message: language ? language.deletedSuccessfullyNotification : 'Deleted successfully',
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      handleUncoveredNotification(error, handleAddNotification, language);
    }
  })();
};

export const updateStages = (
  id: string,
  data: { name: string; description: string; exp_duration: string; zone: string; metadata: any; client_id: string },
  index: number,
  updateLocalItem: any,
  handleAddNotification: Function,
  language: any,
  setBlockButtons: (status: boolean) => void,
) => {
  if (id !== NEW_ID) {
    const url = `${API_URL}/v1/stages/${id}`;
    setBlockButtons(true);
    (async () => {
      try {
        let responseData = (await HttpService.patch(url, data)).data;
        updateLocalItem(index, responseData.payload);
        handleAddNotification({
          message: language ? language.savedSuccessfullyNotification : 'Saved successfully',
          type: 'success',
        });
        setBlockButtons(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleUncoveredNotification(error, handleAddNotification, language);
        setBlockButtons(false);
      }
    })();
  } else {
    const url = `${API_URL}/v1/stages/`;
    setBlockButtons(true);
    (async () => {
      try {
        let responseData = (await HttpService.post(url, data)).data;
        updateLocalItem(responseData.payload.id, responseData.payload);
        handleAddNotification({
          message: language ? language.savedSuccessfullyNotification : 'Saved successfully',
          type: 'success',
        });
        setBlockButtons(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleUncoveredNotification(error, handleAddNotification, language);
        setBlockButtons(false);
      }
    })();
  }
};
