/**
 * @description
 * This is component that set sizes of zone in DescriptionPopup
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { RoundedRange } from '../../../range/RoundedRange';
import { useNotifierFunctions } from '../../../../../../../features/notifier2';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../app/state/userSlice';
import { borderRadiusFetch, colorFetch } from '../../../../../../../styles/utils';

const PropertyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
`;

const StyledPropertyText = styled(Text)`
  font-size: 13px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
  font-family: 'Poppins';
`;

const StyledValuesText = styled(Text)`
  font-size: 13px;
  line-height: 24px;
  font-family: 'Poppins';
`;

type RangeWrapperProps = {
  isRange?: boolean;
};

const RangeWrapper = styled.div<RangeWrapperProps>`
  margin-bottom: ${({ isRange }) => isRange && '14px'};
`;

const NumberWrapper = styled.div`
  width: max-content;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: ${borderRadiusFetch('primary')};
  padding: 0 8px;
  margin-bottom: 3px;
`;

const TextField = styled(TextareaAutosize)`
  width: 30px;
  box-sizing: border-box;
  background-color: inherit;
  color: inherit;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 13px;
  outline: none;
  resize: none;
  border: none;
`;

export const Range = ({
  index,
  name,
  localValue,
  onLocalChange,
  isEdit,
  maxVal = 20,
}: {
  index: number;
  name: string;
  localValue: number;
  onLocalChange: any;
  isEdit?: boolean;
  maxVal?: number;
}) => {
  const [size, setSize] = useState(localValue);
  const { addNotification } = useNotifierFunctions();

  const languageStrings = useSelector(selectLanguageStrings);

  const handleSetSize = (value: number, index: number) => {
    const preparedValue = value > maxVal ? maxVal : value;
    setSize(preparedValue);
    onLocalChange([preparedValue]);
    value > maxVal &&
      addNotification({
        message: `${languageStrings ? languageStrings.maxValueNotification : 'The maximum value of'}  ${
          index === 2
            ? languageStrings
              ? languageStrings.width
              : 'width'
            : languageStrings
            ? languageStrings.height
            : 'height'
        } ${languageStrings ? languageStrings.is : 'is'} ${maxVal}`,
        type: 'error',
      });
  };

  useEffect(() => {
    setSize(localValue);
  }, [localValue]);

  return (
    <RangeWrapper key={index} isRange={(index === 2 || index === 3) && isEdit}>
      <PropertyWrapper>
        <StyledPropertyText type={TextType.TEXT_12_GRAY}>{name}</StyledPropertyText>
        {(index === 2 && isEdit) || (index === 3 && isEdit) ? (
          <NumberWrapper>
            <TextField
              value={size}
              onChange={(e: any) => {
                const value = isNaN(e.target.value) ? 1 : +e.target.value;
                handleSetSize(value, index);
              }}
            />
            <StyledValuesText type={TextType.TEXT_12_BLACK}> m</StyledValuesText>
          </NumberWrapper>
        ) : index === 2 || index === 3 ? (
          <StyledValuesText type={TextType.TEXT_12_BLACK}>{`${
            localValue > maxVal ? maxVal : Math.floor(localValue)
          } m`}</StyledValuesText>
        ) : (
          <StyledValuesText type={TextType.TEXT_12_BLACK}>{Math.round(localValue)}</StyledValuesText>
        )}
      </PropertyWrapper>
      {(index === 2 || index === 3) && isEdit && (
        <RoundedRange values={[Math.floor(localValue)]} handleOnChange={onLocalChange} min={0} max={maxVal} />
      )}
    </RangeWrapper>
  );
};
