/**
 * @description
 * This is component for badges
 * all the functionalities cthings require for badges
 * Highly customisable.
 *
 */

import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styles';
import { borderRadiusFetch } from '../../styles/utils';

type WrapperProps = {
  backgroundColor?: string;
  color?: string;
};

const Wrapper = styled.div<WrapperProps>`
  min-width: 92px;
  height: 20px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ backgroundColor }) => backgroundColor};

  border-radius: ${borderRadiusFetch('primary')};
  padding: 0 8px;

  cursor: pointer;

  & > span {
    font-weight: 700;
    line-height: 15px;
    color: ${({ color }) => color};
  }
`;

export enum BadgesType {
  MISC_TEAL = 'MISC_TEAL',
  MISC_PURPLE = 'MISC_PURPLE',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  PRIMARY = 'PRIMARY',
  REMINDER = 'REMINDER',
  UNKNOWN = 'UNKNOWN',
}
export interface BadgesProps {
  type: BadgesType;
  content: string;
  className?: string;
}

export const Badges: FC<BadgesProps> = ({ type, content, ...props }) => {
  const theme = useTheme();
  const {
    gray1,
    gray5,
    gray6,
    blue0,
    blue3,
    blue4,
    green1,
    green5,
    orange2,
    orange3,
    orange5,
    red1,
    red4,
  } = theme.colors;

  const [background, setBackground] = useState<string | undefined>('');
  const [fontColor, setFontColor] = useState<string | undefined>('');

  useEffect(() => {
    switch (type) {
      case BadgesType.MISC_TEAL:
        setBackground(green5);
        setFontColor(green1);
        break;
      case BadgesType.MISC_PURPLE:
        setBackground(blue4);
        setFontColor(blue0);
        break;
      case BadgesType.SUCCESS:
        setBackground(green5);
        setFontColor(green1);
        break;
      case BadgesType.WARNING:
        setBackground(orange5);
        setFontColor(orange2);
        break;
      case BadgesType.DANGER:
        setBackground(red4);
        setFontColor(red1);
        break;
      case BadgesType.PRIMARY:
        setBackground(gray6);
        setFontColor(blue3);
        break;
      case BadgesType.REMINDER:
        setBackground(orange5);
        setFontColor(orange3);
        break;
      case BadgesType.UNKNOWN:
        setBackground(gray5);
        setFontColor(gray1);
        break;
      default:
        setBackground('');
        setFontColor('');
    }
  }, [type]);

  return (
    <Wrapper backgroundColor={background} color={fontColor} {...props}>
      <Text type={TextType.TEXT_10_WHITE}>{content}</Text>
    </Wrapper>
  );
};

Badges.defaultProps = {};
