import { TextType } from '@bit/first-scope.text';
import React, { FC } from 'react';
import Tooltip from '../../../../components/tooltip';
import { getAccess } from '../../../../routes/paths';
import { useStyleContext } from '../../../../styles/style.context';
import { MenuItemType } from './ItemFirstMobileMenu';
import { ItemImage } from '../ItemImages';
import {
  ItemWrapper,
  LineForModal,
  ManageList,
  ManageModal,
  StyledManageArrowImage,
  StyledManageNavlink,
  StyledNavLink,
  StyledText,
  TextWrapperManage,
} from './styled';
import { useNavigationContext } from '../navigation.context';
import { useTheme } from '@cthings.co/styles';

export interface ItemWithChildMenuTypes {
  type: MenuItemType;
  manageRef: any;
  isActive: boolean;
  to: any;
  childRoutes: any;
  isOpen: boolean;
  tooltipId?: string;
  value?: string;
  isOpenChildMenu?: boolean;
  setIsOpenChildMenu: any;
  appendSecondaryItems?: (val: any) => void;
  clearSecondaryItems?: () => void;
  isSmallHeightViewport: boolean;
}

export const ItemWithChildMenu: FC<ItemWithChildMenuTypes> = ({
  type,
  manageRef,
  isActive,
  to,
  childRoutes,
  isOpen,
  tooltipId,
  value,
  isOpenChildMenu,
  setIsOpenChildMenu,
  isSmallHeightViewport,
}) => {
  const theme = useTheme();
  const { black1, primary } = theme.colors;

  const [media] = useStyleContext();
  const [{ secondaryMenuItems }, , { clearSecondaryItems, appendSecondaryItems }] = useNavigationContext();

  const isOpenSecondMenu = secondaryMenuItems.length > 0;

  return (
    <ItemWrapper ref={manageRef} className={isActive ? 'active' : ''} isSmallHeightViewport={isSmallHeightViewport}>
      <Tooltip
        id={!isOpen && !isOpenSecondMenu ? tooltipId ?? '' : ''}
        text={!isOpen && !isOpenSecondMenu ? value ?? '' : ''}
      >
        <StyledNavLink
          to={to}
          access={getAccess(to)}
          onClick={(e: any) => {
            e.preventDefault();
            setIsOpenChildMenu && setIsOpenChildMenu((el: boolean) => !el);
            clearSecondaryItems && clearSecondaryItems();
            appendSecondaryItems && appendSecondaryItems(childRoutes);
          }}
          className={isActive ? 'active' : ''}
        >
          <ItemImage type={type} />
          <TextWrapperManage className={isOpen ? 'open' : ''}>
            <StyledText type={TextType.MENU_ITEM_TEXT_PRIMARY} color={black1} hoverColor={primary}>
              {value}
            </StyledText>
            <StyledManageArrowImage className={isOpenChildMenu ? 'open' : ''} />
          </TextWrapperManage>
        </StyledNavLink>
      </Tooltip>
      {!media.mediaType.tablet && isOpen && childRoutes && childRoutes.length && (
        <ManageList
          className={isOpenSecondMenu ? 'open' : ''}
          height={16 * childRoutes.length + 15 * (childRoutes.length - 1) + 10 + 'px'}
        >
          {childRoutes.map((item: any, index: number) => {
            const { name, to, view } = item;
            return to ? (
              view === 'LINE' ? (
                <LineForModal />
              ) : (
                <StyledManageNavlink to={to} access={getAccess(to)}>
                  {name}
                </StyledManageNavlink>
              )
            ) : null;
          })}
        </ManageList>
      )}
      {!media.mediaType.tablet && !isOpen && childRoutes && childRoutes.length && (
        <ManageModal
          // @TODO check implementation of ManageModal. Prop height doesn't exist in styles
          // height={16 * childRoutes.length + 15 * (childRoutes.length - 1) + 24 + 'px'}
          className={isOpenSecondMenu ? 'open' : ''}
        >
          {childRoutes.map((item: any, index: number) => {
            const { name, to, view } = item;

            return to ? (
              view === 'LINE' ? (
                <LineForModal className="modal" />
              ) : (
                <StyledManageNavlink
                  style={{ marginLeft: 0, marginTop: 0 }}
                  to={to}
                  access={getAccess(to)}
                  onClick={() => setIsOpenChildMenu(false)}
                >
                  {name}
                </StyledManageNavlink>
              )
            ) : null;
          })}
        </ManageModal>
      )}
    </ItemWrapper>
  );
};
