import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/userSlice';
import { ContentType } from '../../../../components/insightBadges/InsightBadges';
import { AttachedInventory } from '../popupFloorMobile/components/attachedInventory/AttachedInventory';
import { AttachedPackage } from '../popupFloorMobile/components/attachedPackage/AttachedPackage';
import { DetachedModal } from '../popupFloorMobile/components/detachedModal/DetachedModal';

export interface PopupFloorModalType {
  popupInfo: any;
  setPopupInfo: any;
  pathParams: any;
}

export const PopupFloorMobile: FC<PopupFloorModalType> = ({ popupInfo, setPopupInfo, pathParams }) => {
  const [data, setData] = useState<any>(null);

  const languageStrings = useSelector(selectLanguageStrings);

  const { package_id, inventory_id } = popupInfo.content;

  const roleName = inventory_id
    ? ContentType.TAG_INVENTORY
    : package_id
    ? ContentType.TAG_PACKAGE
    : ContentType.UNKNOWN;

  useEffect(() => {
    if (Object.keys(popupInfo.content).length !== 0) {
      setData(popupInfo.content);
    }
  }, [popupInfo.content]);

  const switchRoleName = useCallback(() => {
    return data && data.inventoryName
      ? ContentType.TAG_INVENTORY
      : data && data.packageName
      ? ContentType.TAG_PACKAGE
      : data && !data.attached_info
      ? ContentType.UNKNOWN
      : ContentType.NONE;
  }, [data]);

  const switchModals = (type: ContentType) => {
    const data = {
      [ContentType.TAG_INVENTORY]: (() => (
        <AttachedInventory
          popupInfo={popupInfo}
          isOpen={popupInfo.isOpen}
          setPopupInfo={setPopupInfo}
          pathParams={pathParams}
          roleName={roleName}
          languageStrings={languageStrings}
        />
      ))(),
      [ContentType.TAG_PACKAGE]: (() => (
        <AttachedPackage
          popupInfo={popupInfo}
          isOpen={popupInfo.isOpen}
          setPopupInfo={setPopupInfo}
          pathParams={pathParams}
          roleName={roleName}
          languageStrings={languageStrings}
        />
      ))(),
      [ContentType.UNKNOWN]: (() => (
        <DetachedModal
          roleName={roleName}
          popupInfo={popupInfo}
          isOpen={popupInfo.isOpen}
          setPopupInfo={setPopupInfo}
          pathParams={pathParams}
          languageStrings={languageStrings}
        />
      ))(),
      [ContentType.NONE]: (() => <></>)(),
    };
    return data[type];
  };

  return <div>{switchModals(switchRoleName())}</div>;
};
