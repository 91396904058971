import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { ManagementItemState } from './managementInterfaces';

const initialState: ManagementItemState = {
  keyWord: '',
  searchInputValue: '',
};

export const managementSlice = createSlice({
  name: 'management',
  initialState,
  reducers: {
    setManagementItem: (state, action: PayloadAction<any>) => {
      state.keyWord = action.payload.keyWord;
    },
    setSearchInputValue: (state, action: PayloadAction<any>) => {
      state.searchInputValue = action.payload.searchInputValue;
    },
  },
});

export const { setManagementItem, setSearchInputValue } = managementSlice.actions;

export const selectManagementItems = (state: RootState) => {
  return state.management;
};
export const selectSearchInputValue = (state: RootState) => {
  return state.management.searchInputValue;
};

export default managementSlice.reducer;
