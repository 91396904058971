import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../app/state/userSlice';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 16px 16px 15px 16px;
`;

export const RowPlaceholder = () => {
  const language = useSelector(selectLanguageStrings);

  return (
    <Wrapper>
      <Text type={TextType.TEXT_14_GRAY}>{language ? language.noItemsText : 'No item'}</Text>
    </Wrapper>
  );
};
