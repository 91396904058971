/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for tooltip require
 * Highly customisable.
 * Background logic is contained in context.tsx
 *
 */

import React, { useCallback, ReactNode, useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useTooltip, useTooltipEventHandlers } from './functions';
import { TooltipPlace, TooltipTriggerType } from './types';
import { useTheme } from '@cthings.co/styles';

type DivProps = {
  width?: string;
};

const Div = styled.div<DivProps>`
  width: ${({ width }) => width || '100%'};
  margin: 0;
  box-sizing: border-box;
`;

type StyledTooltipProps = {
  styledColor?: string;
};

const StyledTooltip = styled(ReactTooltip as any)<StyledTooltipProps>`
  white-space: pre;
  &.coloured {
    background-color: ${({ styledColor }) => styledColor};
  }
  &.top {
    &.coloured:after {
      border-top-color: ${({ styledColor }) => styledColor} !important;
    }
  }
  &.bottom {
    &.coloured:after {
      border-bottom-color: ${({ styledColor }) => styledColor} !important;
    }
  }
  &.right {
    &.coloured:after {
      border-right-color: ${({ styledColor }) => styledColor} !important;
    }
  }
  &.left {
    &.coloured:after {
      border-left-color: ${({ styledColor }) => styledColor} !important;
    }
  }
`;

export const constructTooltip = (children: ReactNode, config: any, width?: string) => {
  return (
    <Tooltip width={width} id={config.tooltipId} text={config.tooltipText}>
      {children}
    </Tooltip>
  );
};

const Tooltip = ({
  id,
  text,
  children,
  triggerType = TooltipTriggerType.HOVER,
  color,
  width,
  place = TooltipPlace.TOP,
  disabled,
}: {
  id: string;
  text: string;
  children: ReactNode;
  triggerType?: TooltipTriggerType;
  color?: string;
  width?: string;
  place?: TooltipPlace;
  disabled?: boolean;
}) => {
  const [tooltipNode, setTooltipNode] = useState<any>(null);
  const { black1 } = useTheme().colors;

  const tooltipRef = useCallback((node) => {
    if (node !== null) {
      setTooltipNode(node);
    }
  }, []);

  const [isShown, handlers] = useTooltipEventHandlers(id, triggerType, tooltipNode);

  return (
    <>
      {id ? (
        <>
          <Div width={width} ref={tooltipRef} data-tip="tooltip" data-for={id} data-place={place} {...handlers}>
            {children}
          </Div>
          <StyledTooltip
            effect="solid"
            backgroundColor={black1}
            id={id}
            className={color ? `coloured ${place}` : ''}
            styledColor={color}
            disable={!!disabled || !isShown}
          >
            {text}
          </StyledTooltip>
        </>
      ) : (
        <Div>{children}</Div>
      )}
    </>
  );
};

export default Tooltip;
export { useTooltip };
