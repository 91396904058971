import { useCallback, useEffect, useState } from 'react';
import { useDimensions } from './useDimensions';

const maxScale = 5,
  minScale = 0.0005;

export const useZoomAndPinch = ({ imageWidth, imageHeight }: { imageWidth: number; imageHeight: number }) => {
  const [selected, setSelected] = useState<SVGGElement | null>(null);
  const [scale, setScale] = useState(1);
  const [svgNode, setSvgNode] = useState<SVGSVGElement | null>(null);
  const [gNode, setGNode] = useState<SVGGElement | null>(null);
  const [imageWrapperNode, setImageWrapperNode] = useState<SVGGElement | null>(null);

  const { getX, getY, containerCenter } = useDimensions({
    imageWrapperNode,
    scale,
    imageHeight,
    imageWidth,
  });

  const { e: translateX, f: translateY } = selected?.getCTM() || gNode?.getCTM() || {};

  const svgRef = useCallback((node: SVGSVGElement) => {
    if (node !== null) {
      setSvgNode(node);
    }
  }, []);

  const gRef = useCallback((node: SVGGElement) => {
    if (node !== null) {
      setGNode(node);
    }
  }, []);

  const imageWrapperRef = useCallback((node: SVGGElement) => {
    if (node !== null) {
      setImageWrapperNode(node);
    }
  }, []);

  const beginDrag = useCallback(
    (e: DragEvent | TouchEvent) => {
      e.stopPropagation();
      if (!gNode) return;
      const target = e.target as SVGGElement;
      let currentSelected: SVGGElement;

      if (target.classList?.contains('draggable')) {
        currentSelected = target;
      } else {
        currentSelected = gNode;
      }

      let { clientX, clientY } = e as DragEvent;
      if (!clientX || !clientY) {
        clientX = (e as TouchEvent).touches[0].clientX;
        clientY = (e as TouchEvent).touches[0].clientY;
      }

      currentSelected.dataset['startMouseX'] = `${clientX}`;
      currentSelected.dataset['startMouseY'] = `${clientY}`;

      setSelected(currentSelected);
    },
    [gNode],
  );

  const drag = useCallback(
    (e: DragEvent | TouchEvent) => {
      if (!selected || !svgNode) return;
      e.stopPropagation();

      const startX = parseFloat(selected?.dataset['startMouseX'] || '0'),
        startY = parseFloat(selected?.dataset['startMouseY'] || '0');

      let { clientX, clientY } = e as DragEvent;
      if (!clientX || !clientY) {
        clientX = (e as TouchEvent).touches[0].clientX;
        clientY = (e as TouchEvent).touches[0].clientY;
      }
      let dx = clientX - startX,
        dy = clientY - startY;

      if (selected.classList?.contains('draggable')) {
        const selectedBox = selected.getBoundingClientRect(),
          boundaryBox = selected?.parentElement?.getBoundingClientRect();
        if (!boundaryBox) return;

        if (selectedBox.right + dx > boundaryBox.right) {
          dx = boundaryBox.right - selectedBox.right;
        } else if (selectedBox.left + dx < boundaryBox.left) {
          dx = boundaryBox.left - selectedBox.left;
        }

        if (selectedBox.bottom + dy > boundaryBox.bottom) {
          dy = boundaryBox.bottom - selectedBox.bottom;
        } else if (selectedBox.top + dy < boundaryBox.top) {
          dy = boundaryBox.top - selectedBox.top;
        }
      }
      const currentMatrix = selected.transform?.baseVal.consolidate()?.matrix as DOMMatrix;
      const newMatrix = currentMatrix?.translate(dx / scale, dy / scale);
      if (newMatrix) {
        const transform = svgNode.createSVGTransformFromMatrix(newMatrix);
        selected.transform.baseVal.initialize(transform);
        selected.dataset['startMouseX'] = `${dx + startX}`;
        selected.dataset['startMouseY'] = `${dy + startY}`;
      }
    },
    [scale, selected, svgNode],
  );

  const endDrag = useCallback(
    (e: DragEvent) => {
      e.stopPropagation();

      if (selected) {
        setSelected(null);
      }
    },
    [selected],
  );

  // @TODO work on this type later
  const zoom = useCallback(
    (isPositive: boolean, clientX: number, clientY: number) => {
      if (!gNode || !svgNode) return;

      let scaleStep = isPositive ? 1.25 : 0.8;

      if (scale * scaleStep > maxScale) {
        scaleStep = maxScale / scale;
      }

      if (scale * scaleStep < minScale) {
        scaleStep = minScale / scale;
      }

      setScale((current) => (current *= scaleStep));

      const box = svgNode.getBoundingClientRect();
      let point = svgNode.createSVGPoint();
      point.x = clientX - box.left;
      point.y = clientY - box.top;

      const currentZoomMatrix = gNode.getCTM();

      point = point.matrixTransform(currentZoomMatrix?.inverse());

      const matrix = svgNode
        .createSVGMatrix()
        .translate(point.x, point.y)
        .scale(scaleStep)
        .translate(-point.x, -point.y);

      const newZoomMatrix = currentZoomMatrix?.multiply(matrix);

      gNode.transform.baseVal.initialize(svgNode.createSVGTransformFromMatrix(newZoomMatrix as DOMMatrix));
    },
    [gNode, svgNode, scale],
  );

  const handleZoomEvent = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      const { wheelDelta, clientX, clientY } = e;
      zoom(wheelDelta > 0, clientX, clientY);
    },
    [zoom],
  );

  const handleZoom = useCallback(
    (isPositive: boolean) => {
      zoom(isPositive, containerCenter.x, containerCenter.y);
    },
    [zoom, containerCenter.x, containerCenter.y],
  );

  const handleRevert = useCallback(() => {
    if (!gNode || !svgNode) return;

    gNode.transform.baseVal.initialize(svgNode.createSVGTransformFromMatrix(svgNode.createSVGMatrix()));
    setScale(1);
  }, [svgNode, gNode]);

  useEffect(() => {
    gNode?.addEventListener('mousedown', beginDrag as any);
    gNode?.addEventListener('touchstart', beginDrag as any, { passive: false });
    svgNode?.addEventListener('mousewheel', handleZoomEvent);
    svgNode?.addEventListener('mousemove', drag as any);
    svgNode?.addEventListener('touchmove', drag as any, { passive: false });
    window.addEventListener('mouseup', endDrag as any);
    window.addEventListener('touchend', endDrag as any, { passive: false });
    return () => {
      gNode?.removeEventListener('mousedown', beginDrag as any);
      gNode?.removeEventListener('touchstart', beginDrag as any);
      svgNode?.removeEventListener('mousewheel', handleZoomEvent);
      svgNode?.removeEventListener('mousemove', drag as any);
      svgNode?.removeEventListener('touchmove', drag as any);
      window.removeEventListener('mouseup', endDrag as any);
      window.removeEventListener('touchend', endDrag as any);
    };
  }, [gNode, svgNode, beginDrag, drag, endDrag, handleZoomEvent]);

  return {
    scale,
    handleZoom,
    handleRevert,
    svgRef,
    gRef,
    imageWrapperRef,
    translateX,
    translateY,
    getX,
    getY,
    isReady: !!gNode,
  };
};
