/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for device details page require
 * Highly customisable.
 *
 */

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ButtonIconType, CommonButton } from '@bit/first-scope.common-button';
import { media } from '../../../styles/media';
import { useLoaderFunctions } from '../../../features/placeholderComponent/loader.context';
import { useManageDeviceDetails } from '../../../api/manageDevicesDetails';
import { LogsTable } from './components/logsTable/LogsTable';
import { FirstTableInfo } from './components/firstTableInfo/FirstTableInfo';
import { SecondTableInfo } from './components/secondTableInfo/SecondTableInfo';
import { DeviceDetailsType } from '../../../enums/View';
import { deAttachDevice } from '../../../api/manageDevicesDetails';
import { CopyButton } from '../../../components/copyButton/CopyButton';
import { useNotifierFunctions } from '../../../features/notifier2';
import { useCustomHistory } from '../../../utils/react-router-dom-abstraction';
import { ButtonsContainer } from './components/firstTableInfo/components/buttonsContainer/ButtonsContainer';
import { View } from '../../../routes/routeInterfaces';
import { useMediaType } from '../../../styles/style.context';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/userSlice';
import { AttachModal } from './components/attachModal/AttachModal';
import { useParams } from 'react-router-dom';
import { colorFetch } from '../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  ${media.tablet`
    padding: 34px 16px 65px 16px;
  `}
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 45px;
  ${media.tablet`
    margin-bottom: 30px;
  `}
`;

const TitleText = styled(Text)`
  user-select: none;

  ${media.surfacePro`
    font-size: 26px;
  `}
  ${media.phone`
    font-size: 20px;
  `}
`;

const StyledButtonContainer = styled(ButtonsContainer)`
  margin-top: 0;
`;

const NameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 14px;
  ${media.tablet`
    justify-content: center;
  `}
`;

const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > svg > path {
    transition: all 0.3s linear;
  }
  &:hover {
    & > svg > path {
      stroke: ${colorFetch('gray3')};
    }
    & > span {
      color: ${colorFetch('gray1')};
    }
  }
`;

const WrapperFirstSecondTables = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-gap: 20px;
  margin-bottom: 20px;
  ${media.desktop`
  display: flex;
  flex-direction: column;
  grid: none;
  grid-gap: 0;
`}
  ${media.tablet`
  display: flex;
  flex-direction: column;
`}
`;

export interface DeviceDetailsProps {
  type: DeviceDetailsType;
}

export const DeviceDetails: FC<DeviceDetailsProps> = ({ type }) => {
  const pathParams = useParams();
  const idDevice: any = pathParams.id;

  const mediaType = useMediaType();
  const theme = useTheme();
  const { primary, secondary2 } = theme.colors;
  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();
  const keywordList = ['OrderInfoTable'];
  // need for update status attach detach
  const [isReloadRequestAPI, setIsReloadRequestAPI] = useState(false);

  const languageStrings = useSelector(selectLanguageStrings);

  const data: any = useManageDeviceDetails(
    isReloadRequestAPI,
    setIsReloadRequestAPI,
    idDevice,
    (isPlaceholder: boolean) => appendKeywordList(keywordList, isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList(keywordList, isPlaceholder),
  );

  const [showAttachModal, setShowAttachModal] = useState(false);
  //state for attach button
  const [isAttach, setIsAttach] = useState(data?.attached);
  const history = useCustomHistory();
  const { addNotification } = useNotifierFunctions();
  const [isLoader, setIsLoader] = useState(false);

  const setFirstLetterCapital = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

  const handleCopyText = async (copiedText: string) => {
    await navigator.clipboard.writeText(copiedText);
    addNotification({
      message: languageStrings ? languageStrings.notificationCopieMessage : 'Copied to clipboard',
      type: 'info',
    });
  };

  const handleGoToLocation = () => {
    history.push(View.USER_INSIGHTS_DETAILS, {
      pathParams: { id: data.location.building_id, name: data.name },
    });
  };

  const handleDeAttachDeviceApi = () => {
    setIsReloadRequestAPI(true);
    setIsLoader(true);
    deAttachDevice(idDevice, addNotification, setIsLoader, languageStrings);
    setIsAttach(false);
  };

  const handleAttachLoadingStatus = (value: boolean) => {
    setIsAttach(value);
    setIsReloadRequestAPI(value);
  };

  return (
    <>
      <Wrapper>
        <TitleWrapper>
          <CommonButton
            width={'32px'}
            height={'32px'}
            backgroundColor={primary}
            backgroundColorHover={secondary2}
            borderColorHover={secondary2}
            iconType={ButtonIconType.ARROW_LEFT}
            onClick={() => history.goBack()}
            iconWithoutText
          />
          <NameWrapper>
            <CopyWrapper onClick={() => handleCopyText(data?.name)}>
              <TitleText type={TextType.TITLE_PRIMARY}>
                {data?.role && setFirstLetterCapital(data?.role)} {data?.name}
              </TitleText>
              <CopyButton />
            </CopyWrapper>
          </NameWrapper>
          {!mediaType.tablet && (
            <StyledButtonContainer
              setShowAttachModal={setShowAttachModal}
              data={data}
              handleDeAttachDeviceApi={handleDeAttachDeviceApi}
              setIsAttach={setIsAttach}
              handleShowOnTheMap={handleGoToLocation}
              isAttach={isAttach}
              isAttachDeAttachButton={type === DeviceDetailsType.TRACKER}
            />
          )}
        </TitleWrapper>
        <WrapperFirstSecondTables>
          <FirstTableInfo
            type={type}
            data={data}
            setIsAttach={setIsAttach}
            isAttach={isAttach}
            headerLabels={[
              { name: languageStrings ? languageStrings.propertyOfTable : 'Property', isSorted: false },
              { name: languageStrings ? languageStrings.valueOfTable : 'Value', isSorted: false },
            ]}
            headerGridTemplate={{ desktop: '1.25fr 1.5fr', tablet: '1.25fr 1.5fr', phone: '1.25fr 1.5fr' }}
            setShowAttachModal={setShowAttachModal}
            handleDeAttachDeviceApi={handleDeAttachDeviceApi}
            languageStrings={languageStrings}
          />
          <SecondTableInfo
            type={type}
            data={data}
            headerLabels={[
              { name: languageStrings ? languageStrings.propertyOfTable : 'Property', isSorted: false },
              { name: languageStrings ? languageStrings.valueOfTable : 'Value', isSorted: false },
            ]}
            headerGridTemplate={{ desktop: '1.25fr 1.5fr', tablet: '1.25fr 1.5fr', phone: '1.25fr 1.5fr' }}
            isAttach={isAttach}
            language={languageStrings}
          />
        </WrapperFirstSecondTables>
        <LogsTable id={idDevice} pathParams={pathParams} type={type} languageStrings={languageStrings} />
      </Wrapper>
      <AttachModal
        idDevice={idDevice}
        data={data}
        isOpenModal={showAttachModal}
        handleCloseAttachModal={setShowAttachModal}
        setIsAttach={handleAttachLoadingStatus}
        isLoader={isLoader}
        setIsLoader={setIsLoader}
        languageStrings={languageStrings}
      />
    </>
  );
};
