import styled from 'styled-components';
import { media } from '../../styles/media';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';

type ValueLevelProps = {
  backgroundColor?: string;
  scale?: string | number;
  staticDisplay?: boolean;
  index?: any;
  ITracker?: any;
  top?: string | number;
  left?: string | number;
};

export const ValueLevel = styled.div<ValueLevelProps>`
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  transform: ${({ staticDisplay, scale }) => (!staticDisplay ? `translate(-50%, -50%) scale(${scale})` : 'none')};
  z-index: ${({ index, ITracker }) => index === ITracker && '1'};
  top: ${({ top }) => top + 'px'};
  left: ${({ left }) => left + 'px'};
  position: ${({ staticDisplay }) => (!staticDisplay ? 'absolute' : 'static')};
  margin-right: ${({ staticDisplay }) => staticDisplay && '5px'};
  box-sizing: border-box;
  cursor: pointer;
`;

type LocationCircleProps = {
  withBorder?: boolean;
};

export const LocationCircle = styled(ValueLevel)<LocationCircleProps>`
  width: ${({ withBorder }) => (!withBorder ? '8px' : '16px')};
  height: ${({ withBorder }) => (!withBorder ? '8px' : '16px')};
  border: ${({ staticDisplay, withBorder }) => staticDisplay && withBorder && '3px solid rgba(255, 255, 255, 0.6)'};
`;

export const LocationCircleCluster = styled(ValueLevel)`
  width: 26px;
  height: 26px;
`;

type LocationMaskProps = {
  top?: string | number;
  left?: string | number;
  isCluster?: boolean;
  scale?: string | number;
  index?: any;
  ITracker?: any;
};

export const LocationMask = styled.div<LocationMaskProps>`
  pointer-events: none;
  position: absolute;
  top: ${({ top }) => top + 'px'};
  left: ${({ left }) => left + 'px'};
  width: ${({ isCluster }) => (isCluster ? '26px' : '16px')};
  height: ${({ isCluster }) => (isCluster ? '26px' : '16px')};
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  transform: translate(-50%, -50%) ${({ scale }) => `scale(${scale})`};

  z-index: ${({ index, ITracker }) => index === ITracker && '1'};
  cursor: pointer;
`;

export const SectorWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
`;

type SectorProps = {
  left?: any;
  doubleSector?: any;
};

export const Sector = styled.div<SectorProps>`
  position: absolute;
  transform-origin: 100% 100%;
  background: ${({ left, theme }) => (left ? colorFetch('gray2')({ theme }) : colorFetch('orange2')({ theme }))};
  width: 100%;
  height: 100%;
  left: 50%;
  top: ${({ doubleSector, left }) => (doubleSector ? (left ? '0' : '100%') : '50%')};
  margin-top: -100%;
  margin-left: -100%;
  transform: ${({ doubleSector, left }) =>
    doubleSector
      ? left
        ? 'rotate(270deg)'
        : 'rotate(90deg)'
      : left
      ? 'rotate(270deg) skew(150deg)'
      : 'rotate(150deg) skew(150deg)'};
`;

export const SectorText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: ${colorFetch('pureWhite')};
`;

type ImageProps = {
  src?: string;
  ratio: number;
};

export const Image = styled.img<ImageProps>`
  display: ${({ src }) => (!!src ? 'block' : 'none')};
  height: ${({ ratio }) => (ratio <= 1 ? '100%' : '')};
  width: ${({ ratio }) => (ratio <= 1 ? '' : '100%')};
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type MapInteractionProps = {
  height?: string;
};

export const MapInteraction = styled.div<MapInteractionProps>`
  margin-top: 15px;
  height: ${({ height }) => height};
  width: 100%;
  position: relative;
  border: 1px solid;
  border-radius: 8px;
  background-color: ${colorFetch('gray4')};
  border-color: ${colorFetch('gray3')};
  ${media.phone`
  border-radius: 0;
  `}
  & > div > div {
    border-radius: 8px;
    & > div:first-child {
      height: 100%;
      width: 100%;
    }
  }
  ${media.tablet`
    margin: 0;
    border: none;
    border-radius: 0;
  `}
`;

export const ZoomToolsContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  z-index: 99;
  ${media.phoneXS`
    bottom: 40px;
  `}
`;

export const ToolsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 0;
  background-color: ${colorFetch('pureWhite')};
  outline: transparent;
  width: 32px;
  height: 32px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 3px 6px rgba(96, 97, 112, 0.16);
  border: none;
  border-radius: ${borderRadiusFetch('additional6')};
  cursor: pointer;
  transition: all 0.3s linear;
  &:nth-child(1) {
    border-bottom: 1px solid;
    border-color: ${colorFetch('gray4')};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:nth-child(2) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:nth-child(3) {
    margin-top: 6px;
  }
  &:hover {
    background-color: ${colorFetch('gray6')};
  }
`;

export const MapLegendLeft = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 28px;
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 99;
  ${media.phone`
    grid-gap: 18px;
  `}
`;

export const MapLegendRight = styled.div`
  position: absolute;
  bottom: 0px;
  right: 75px;
  display: flex;
  align-items: center;
  z-index: 99;
  ${media.desktop`
    display: none;
  `}
  ${media.tablet`
    display: none;
  `}
  ${media.phone`
    display: none;
  `}
`;
