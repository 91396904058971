import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = {
  data: { process_id: '', process_name: '' },
};

export const manageOrderDetailComponentSlice = createSlice({
  name: 'manageOrderDetailComponent',
  initialState,
  reducers: {
    setManageOrderDetailComponent: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setProcessId: (state, action: PayloadAction<any>) => {
      state.data.process_name = action.payload.process_name;
      state.data.process_id = action.payload.process_id;
    },
  },
});

export const { setManageOrderDetailComponent, setProcessId } = manageOrderDetailComponentSlice.actions;

export const selectManageOrderDetailComponent = (state: RootState) => {
  return state.manageOrderDetailComponent;
};

export default manageOrderDetailComponentSlice.reducer;
