import React, { FC } from 'react';
import styled from 'styled-components';
import { media } from '../../../../../../../styles/media';
import { SelectPeriod } from '../../../../../../../components/selectPeriod/SelectPeriod';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as ArrowImage } from '../../../../../../../assets/Shape.svg';
import { colorFetch } from '../../../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  background-color: ${colorFetch('pureWhite')};
  box-sizing: border-box;
  padding: 13px 24px;
  border-radius: 8px 8px 0 0;
  border-bottom: 2px solid;
  border-color: ${colorFetch('gray4')};
  ${media.phone`
    padding: 11px 18px 11px 16px;
  `};
`;

type TitleBoxProps = {
  gridTemplateDesktop?: string;
  gridTemplateTablet?: string;
  gridTemplatePhone?: string;
};

// @TODO fix media types
const TitleBox = styled.div<TitleBoxProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ gridTemplateDesktop }) => gridTemplateDesktop || '190px 280px 10fr'};
  grid-gap: 24px;
  ${media.tablet`
    grid-template-columns: ${({ gridTemplateTablet }: any) => gridTemplateTablet || '130px 130px 10fr'};
  `};
  ${media.phone`
    grid-template-columns: ${({ gridTemplatePhone }: any) => gridTemplatePhone || '86px 60px 10fr'};
  `};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  & > span {
    font-weight: 500;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 4px;
  & > svg:nth-child(2n + 1) {
    transform: rotate(180deg);
    margin: 0 0 2px 0;
  }
  & > svg {
    cursor: pointer;
  }
  & > svg > path {
    fill: ${colorFetch('gray2')};
    transition: fill 0.3s ease;
  }
  & > svg:hover > path {
    fill: ${colorFetch('gray1')};
  }
`;

const StyledSelect = styled(SelectPeriod)`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`;

export interface TableRowProps {
  labels: any;
  isSelectHere?: boolean;
  gridTemplateDesktop?: string;
  gridTemplateTablet?: string;
  gridTemplatePhone?: string;
  className?: string;
}

export const TableHeader: FC<TableRowProps> = ({
  labels,
  isSelectHere,
  gridTemplateDesktop,
  gridTemplateTablet,
  gridTemplatePhone,
  ...props
}) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  const selectProps = {
    values: ['All'],
  };
  return (
    <Wrapper {...props}>
      <TitleBox
        gridTemplateDesktop={gridTemplateDesktop}
        gridTemplateTablet={gridTemplateTablet}
        gridTemplatePhone={gridTemplatePhone}
      >
        {labels.map((item: any, index: number) => {
          return (
            <TitleWrapper key={index}>
              <Text type={TextType.TITLE_H4} color={black1}>
                {item.name}
              </Text>
              {item.isSorted ? (
                <ArrowWrapper>
                  <ArrowImage />
                  <ArrowImage />
                </ArrowWrapper>
              ) : null}
            </TitleWrapper>
          );
        })}
      </TitleBox>
      {isSelectHere ? <StyledSelect {...selectProps} /> : null}
    </Wrapper>
  );
};

TableHeader.defaultProps = {
  labels: ['Date', 'Time', 'Event'],
};
