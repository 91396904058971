/**
 * @description
 * This is re-usable component that is used for
 * realizing functionality of searching other subjects (devices, users, stages and so on)
 */

import React, { FC, useEffect } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';

import {
  CloseButtonWrapper,
  ImageWrapper,
  Loader,
  SearchButton,
  SearchInput,
  SearchWrapper,
} from '../../../styledComponents';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { ReactComponent as SearchImage } from '../../../../../assets/Search.svg';
import { CloseButton } from '../../../../closeButton/CloseButton';

export interface NewSearchBarProps {
  className?: string;
  onChange?: (val: string) => void;
  isGlobalSearch: boolean;
  placeholder?: string;
  isLoadingInProcess?: boolean;
  setResults?: (val: any) => void;
  modalSearch: boolean;
  setModalSearch: (val: boolean) => void;
  inputValue: string;
  setInputValue: (val: string) => void;
  inputRef: any;
  result: any;
}

export const SearchBlock: FC<NewSearchBarProps> = ({
  onChange,
  placeholder,
  isLoadingInProcess,
  setResults,
  isGlobalSearch,
  modalSearch,
  setModalSearch,
  inputValue,
  setInputValue,
  inputRef,
  result,
  ...props
}) => {
  const languageStrings = useSelector(selectLanguageStrings);

  const theme = useTheme();
  const { primary } = theme.borderRadius;
  const { primary: primaryColor, gray1, gray2 } = theme.colors;

  const cleanUpDataHandle = () => {
    onChange && onChange('');
    setResults && setResults(null);
    setModalSearch(false);
  };

  const handleChange = (e: any, handler: any) => {
    handler(e.target.value);
    cleanUpDataHandle();
  };

  const handleClick = () => {
    inputValue && setModalSearch(true);
  };

  const onClearButtonClickHandle = () => {
    setInputValue('');
    cleanUpDataHandle();
  };

  const onSearchButtonClick = () => {
    onChange && onChange(inputValue);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const { key } = e;
    if (key === 'Enter') {
      onSearchButtonClick();
    }
  };

  useEffect(() => {
    result && setModalSearch(true);
  }, [result]);

  return (
    <>
      <SearchWrapper>
        <ImageWrapper colorOfSearchImage={primaryColor}>
          <SearchImage />
        </ImageWrapper>
        <SearchInput
          ref={inputRef}
          borderRadius={primary}
          placeholder={placeholder ?? languageStrings.searchPlaceholder}
          value={inputValue}
          onChange={(e: any) => {
            handleChange(e, setInputValue);
          }}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
        />
        {inputValue.length ? (
          isLoadingInProcess ? (
            <Loader />
          ) : (
            <CloseButtonWrapper>
              <CloseButton onClick={onClearButtonClickHandle} color={gray1} hoverColor={gray2} />
            </CloseButtonWrapper>
          )
        ) : null}
      </SearchWrapper>
      <SearchButton onClick={onSearchButtonClick} borderRadius={primary}>
        {languageStrings.labelSearch}
      </SearchButton>
    </>
  );
};
