/**
 * @description
 * This is a button to quickly turn on and off all filtering
 * all the functionalities cthings require for FilteredButton
 * Highly customisable.
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/media';
import { colorFetch } from '../../styles/utils';

const FilteredButtonWrapper = styled.div`
  width: auto;
  margin-left: 10px;
  ${media.desktop`
    margin: 10px 0 0 10px;
  `}
`;

const Button = styled.button`
  font-family: 'Poppins';
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  color: ${colorFetch('gray1')};
  border: none;
  outline: transparent;
  background: transparent;
  cursor: pointer;
  transition: color 0.3s linear;
  &:hover {
    color: ${colorFetch('gray2')};
  }
`;

export const FilteredButton = ({
  toggleFilterHandler,
  isCheckedAllDevices,
  languageStrings,
}: {
  toggleFilterHandler: (toggleFilter: boolean) => void;
  isCheckedAllDevices: boolean;
  languageStrings?: any;
}) => {
  const [isShowAllFilters, setIsShowAllFilters] = useState(true);

  const changeFilteredStatusHandler = () => {
    setIsShowAllFilters((val: boolean) => !val);
    toggleFilterHandler(isShowAllFilters);
  };

  return (
    <FilteredButtonWrapper>
      <Button onClick={changeFilteredStatusHandler}>
        {isCheckedAllDevices
          ? languageStrings.clearAllButton || 'Clear all'
          : languageStrings.showAllButton || 'Show all'}
      </Button>
    </FilteredButtonWrapper>
  );
};
