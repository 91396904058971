/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings placeholder for add buttons require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { CommonButton } from '@bit/first-scope.common-button';
import { ReactComponent as Plus } from '../assets/plus.svg';

const StyledButton = styled(CommonButton)`
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
`;
export interface ComponentProps {
  // type: PlaceholderType;
  languageStrings?: any;
}

export const PlaceholderAddButton: FC<ComponentProps> = ({ languageStrings }) => {
  return (
    <StyledButton onClick={() => {}} width="100%">
      <Plus /> {languageStrings ? languageStrings.addButton : 'Add'}
    </StyledButton>
  );
};
