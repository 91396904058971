import React, { FormEvent, useState, useEffect } from 'react';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as EyeSlash } from '../../../../../assets/EyeSlash.svg';
import { ReactComponent as Eye } from '../../../../../assets/Eye.svg';
import { InputModal } from '../../../../../components/inputModal/InputModal';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { CheckBox } from '../dropdownMultiChoice/components/checkbox/Checkbox';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { useWindowSize } from '../../../../../styles/style.context';
import { media, sizes } from '../../../../../styles/media';
// @ts-ignore
import _ from 'underscore';
import { colorFetch } from '../../../../../styles/utils';

const Wrapper = styled.div`
  width: 100%;
  max-height: 64px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${media.phone`
    max-height: 40px;
    flex-direction: column;
  `}
`;

const PasswordStatus = styled.div`
  width: calc(100% / 2);
  margin-right: 10px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 8px 12px;
  box-sizing: border-box;
  background-color: ${colorFetch('gray5')};
  border-radius: 6px;
  ${media.phone`
    width: 100%;
    margin: 0 0 10px;
  `}
`;

const NoPasswordStatus = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 8px 12px;
  box-sizing: border-box;
`;

const StyledInput = styled(InputModal)`
  width: calc(100% / 2);
  margin: 0 0 0 10px;
  & > div > input {
    border-radius: 6px;
  }
  & > div > div {
    top: 11px;
  }
  ${media.phone`
    width: 100%;
    margin: 0;
  `}
`;

const CheckboxWrapper = styled.div`
  width: max-content;
  display: flex;
`;

const CheckboxSemiWrapper = styled.div`
  width: max-content;
  box-sizing: border-box;
  padding: 2px;
`;

const StyledText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
`;

const NoPasswordWrapper = styled.div`
  width: calc(100% / 2);
  margin-left: 10px;
  display: flex;
  background-color: ${colorFetch('gray5')};
  border-radius: 6px;
  ${media.phone`
    width: 100%;
    margin: 0;
  `}
`;

export const PasswordInput = ({
  state,
  value,
  disabled,
  setValue,
}: {
  state?: TableCustomComponentState;
  value: boolean;
  disabled: boolean;
  setValue: any;
}) => {
  const [showPassword, setShowPassword] = useState(value);
  const [passwordsStatus, setPasswordsStatus] = useState(value);
  const [inputValue, setInputValue] = useState<any>(value);
  const language = useSelector(selectLanguageStrings);
  const widthView = useWindowSize()[0];
  const isMobileSize = widthView < sizes.phone;

  const onAdditionalImageClick = () => {
    setShowPassword(!showPassword);
  };

  const clearEvents = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleCheckbox = () => {
    setPasswordsStatus((prevStatus) => {
      if (prevStatus) {
        setValue(null);
      }
      return !prevStatus;
    });

    setInputValue(null);
  };

  const handleCheckboxToggle = (e: FormEvent<HTMLElement>) => {
    clearEvents(e);
    handleCheckbox();
  };

  const handleCheckBoxWithChecked = (e: FormEvent<HTMLElement>) => {
    if (!disabled) {
      handleCheckboxToggle(e);
    }
  };

  useEffect(() => {
    if (state === TableCustomComponentState.VIEW_MODAL) {
      setValue(inputValue);
    }
  }, [state]);

  const valueWithoutNull = _.isNull(inputValue) ? '' : inputValue;

  return (
    <Wrapper>
      <PasswordStatus>
        <CheckboxWrapper>
          <CheckboxSemiWrapper>
            <CheckBox value={passwordsStatus} disabled={disabled} onChange={handleCheckBoxWithChecked} />
          </CheckboxSemiWrapper>
        </CheckboxWrapper>
        <StyledText type={TextType.TEXT_14_GRAY} margin={'0 0 0 8px'}>
          {isMobileSize
            ? language
              ? language.externalDocChoosePassword
              : ' Choose password'
            : language
            ? language.externalDocProtectWithPassword
            : ' Protect with password'}
        </StyledText>
      </PasswordStatus>
      {passwordsStatus ? (
        <StyledInput
          additionalImage={showPassword && !disabled ? <Eye /> : <EyeSlash />}
          additionalImageOnClick={onAdditionalImageClick}
          value={valueWithoutNull}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.currentTarget.value);
            setValue(e.currentTarget.value);
          }}
          name={''}
          placeholder={
            value
              ? language
                ? language.externalDocEditPassword
                : 'Edit password'
              : language
              ? language.externalDocEnterPassword
              : 'Enter password'
          }
          type={showPassword && !disabled ? 'text' : 'password'}
          disabled={disabled}
        />
      ) : (
        <NoPasswordWrapper>
          <NoPasswordStatus>
            <StyledText type={TextType.TEXT_14_GRAY}>
              {language ? language.externalDocNoPassword : ' No password'}
            </StyledText>
          </NoPasswordStatus>
        </NoPasswordWrapper>
      )}
    </Wrapper>
  );
};
