import React from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';

const StyledButton = styled.div`
  width: 100%;
  height: 40px;
  max-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: gray3;
  border-radius: 6px;
  background-color: white;
  box-sizing: border-box;
  cursor: pointer;
`;

const Field = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background-color: red2;
  border-radius: 20px;
  margin-left: 6px;
  padding: 2px;
`;

export const FilterButton = ({ value, onClick }: { value: number; onClick: () => void }) => {
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <StyledButton onClick={onClick}>
      <Text type={TextType.TEXT_14_BLACK} lineHeight={'16px'} color={theme.colors.black}>
        {languageStrings.filtersLabel}
      </Text>
      {!!value ? (
        <Field>
          <Text type={TextType.TEXT_10_WHITE} weight={'600'} lineHeight={'16px'}>
            {value}
          </Text>
        </Field>
      ) : null}
    </StyledButton>
  );
};
