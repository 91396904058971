import React, { FC, useCallback, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { media } from '../../../../../styles/media';
import { Text } from '@bit/first-scope.text';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { ItemMetaData } from './ItemMetaData';
// @ts-ignore
import { v4 as uuidv4, v1 as uuidv1 } from 'uuid';
import { isStringConsistOfSpaces } from '../../../../../utils/validation';
import { useTableContext } from '../../../../../features/universalTable/context';
import { RowPlaceholder } from './components/rowPlaceholder/RowPlaceholder';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { borderRadiusFetch, colorFetch } from '../../../../../styles/utils';

type WrapperMetaDataContainerProps = {
  containerHeight?: string;
};

const WrapperMetaDataContainer = styled.div<WrapperMetaDataContainerProps>`
  height: ${({ containerHeight }: any) => containerHeight ?? '100%'};
`;

type MetaDataContainerProps = {
  state?: TableCustomComponentState;
};

// styles for metaData block
const MetaDataContainer = styled.div<MetaDataContainerProps>`
  width: 480px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadiusFetch('primary')};
  border: ${({ state }) => (state === TableCustomComponentState.EDIT ? '1px solid' : 'none')};
  border-color: ${colorFetch('gray4')};
  overflow: hidden;
  transition: all 0.2s linear;
  ${media.tablet`
    max-width: 100%;
    width: 100%;
  `}
  ${media.phone`
    height: 100%;
  `}
  &.disabled {
    background-color: ${colorFetch('gray5')};
  }
`;

const WrapperTitleMetaData = styled.div`
  padding: 10px 22px;
  display: grid;
  grid-template-columns: 1fr 1fr 20px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-family: Poppins;
  border-bottom: 1px solid;
  color: ${colorFetch('gray1')};
  border-color: ${colorFetch('gray4')};
`;

const StyledTitleText = styled(Text)`
  color: ${colorFetch('black1')};
  font-size: 14px;
  font-weight: 500;
` as any; // @TODO

const MetaDataHistory = styled.div`
  box-sizing: border-box;
  overflow: overlay;
  flex: 1;
  padding: 2px 4px 2px 22px;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: 9px;
    width: 4px;
  }
`;

type MetaDataContentProps = {
  height: number;
};

const MetaDataContent = styled.div<MetaDataContentProps>`
  height: ${({ height }) => `${height}px`};
  box-sizing: border-box;
  padding: 0 14px 0 0;
  display: flex;
  flex-direction: column-reverse;
  max-height: 300px;
  overflow: ${({ height }) => (height > 218 ? 'auto' : 'hidden')};
  transition: all 0.3s linear;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
    padding-left: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: 9px;
    width: 4px;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  height: 45px;
  padding: 0;
  margin: 0;
  background-color: ${colorFetch('gray5')};
  color: ${colorFetch('gray2')};
  border: none;
  outline: transparent;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: Poppins;
  border-top: 1px solid;
  border-color: ${colorFetch('gray3')};
  transition: all 0.3s linear;
  &:hover {
    background-color: ${colorFetch('gray4')};
  }
  &:active {
    background-color: ${colorFetch('gray4')};
  }
  &.disabled {
    background-color: ${colorFetch('gray4')};
    cursor: initial;
  }
`;

export interface MetaDataComponentProps {
  value: any;
  state?: TableCustomComponentState;
  setValue?: (value: any) => void;
  withoutUniversalTable?: boolean;
  containerHeight?: string;
}

export const MetaDataComponent: FC<MetaDataComponentProps> = ({
  value,
  state,
  setValue,
  withoutUniversalTable = false,
  containerHeight,
}) => {
  const language = useSelector(selectLanguageStrings);
  const timerId = useRef(null as any);
  const [{ isEditInProcess, isAddInProcess }] = useTableContext();
  const [metaData, setMetaData] = useState<any>(
    !!value
      ? Object.keys(value).map((key, index) => {
          const timestampPart = uuidv1();
          const randomPart = uuidv4();
          return { key, value: value[key], inner_index: index + timestampPart + randomPart };
        })
      : [],
  );
  const [isStartAddingAnotherRow, setIsStartAddingAnotherRow] = useState(false);
  const prepareValue = (array: any[]) => {
    return array.reduce((acc, newVal) => ({ ...acc, [newVal.key]: newVal.value }), {});
  };
  const handleDeleteRowMetaData = useCallback(
    (index: number) => {
      const newMeta = [...metaData.slice(0, index), ...metaData.slice(index + 1)];
      const preparedValue = prepareValue(newMeta);
      setValue && setValue(preparedValue);
      if (withoutUniversalTable || isEditInProcess || isAddInProcess) {
        setMetaData(newMeta);
      }
    },
    [setValue, metaData],
  );

  const handlerOnSetRowMetaData = useCallback(
    (newMeta: any) => {
      const preparedValue = prepareValue(newMeta);
      setValue && setValue(preparedValue);
    },
    [setValue],
  );

  const handleTextChange = useCallback(
    (e: any, isProperty: boolean, inner_index: number) => {
      const val = e.target.value;
      const searchIndex = metaData.findIndex((el: any) => el.inner_index === inner_index);
      if (searchIndex !== -1) {
        const newKey = isProperty ? val : metaData[searchIndex].key;
        const newValue = !isProperty ? val : metaData[searchIndex].value;
        const newVal = { key: newKey, value: newValue, inner_index };
        const newMeta = [...metaData.slice(0, searchIndex), newVal, ...metaData.slice(searchIndex + 1)];
        setMetaData(newMeta);

        timerId.current && clearTimeout(timerId.current);
        timerId.current = setTimeout(() => {
          if (newVal.key !== '' && newVal.value !== '') {
            handlerOnSetRowMetaData(newMeta);
          }
        }, 700);
      }
    },
    [timerId, handlerOnSetRowMetaData, metaData],
  );

  const handleAddStage = () => {
    const lastItem = metaData[0];
    setIsStartAddingAnotherRow(true);
    if (
      lastItem?.value !== '' &&
      lastItem?.key !== '' &&
      !isStringConsistOfSpaces(lastItem?.value) &&
      !isStringConsistOfSpaces(lastItem?.key)
    ) {
      const timestampPart = uuidv1();
      const randomPart = uuidv4();
      setMetaData((meta: any[]) => [
        {
          key: '',
          value: '',
          inner_index: meta.length + timestampPart + randomPart,
        },
        ...meta,
      ]);
      setIsStartAddingAnotherRow(false);
    } else if (isStringConsistOfSpaces(value)) {
    }
  };

  useEffect(() => {
    if (!isEditInProcess) {
      setMetaData(
        !!value
          ? Object.keys(value).map((key, index) => {
              const timestampPart = uuidv1();
              const randomPart = uuidv4();
              return { key, value: value[key], inner_index: index + timestampPart + randomPart };
            })
          : [],
      );
    }
  }, [isEditInProcess]);

  return (
    <WrapperMetaDataContainer containerHeight={containerHeight}>
      <MetaDataContainer state={state}>
        <WrapperTitleMetaData>
          <StyledTitleText>{language ? language.propertyOfTable : 'Property'}</StyledTitleText>
          <StyledTitleText>{language ? language.valueOfTable : 'Value'}</StyledTitleText>
        </WrapperTitleMetaData>
        <MetaDataHistory>
          {metaData.length > 0 ? (
            <MetaDataContent height={metaData.length * 55}>
              {metaData.map((el: any, index: number) => (
                <ItemMetaData
                  key={index}
                  isFirst={index === 0}
                  propertyMetaData={el.key}
                  setPropertyMetaData={(e: any) => handleTextChange(e, true, el.inner_index)}
                  valueMetaData={el.value}
                  handleDeleteRowMetaData={() => handleDeleteRowMetaData(index)}
                  setValueMetaData={(e: any) => handleTextChange(e, false, el.inner_index)}
                  isStartAddingAnotherRow={isStartAddingAnotherRow}
                  state={state}
                  language={language}
                />
              ))}
            </MetaDataContent>
          ) : (
            <RowPlaceholder />
          )}
        </MetaDataHistory>
        {state === TableCustomComponentState.EDIT && (
          <SubmitButton onClick={handleAddStage}>{language ? language.addRowButton : '+ Add row'}</SubmitButton>
        )}
      </MetaDataContainer>
    </WrapperMetaDataContainer>
  );
};
