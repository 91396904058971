/**
 * @description
 * common use utility for storing and returning previous state
 *
 */

import { useRef, useEffect } from 'react';

export function usePrevious(value: any) {
  const ref: typeof value = useRef();

  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
