import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { StagesFunctional } from './components/stagesFunctional/StagesFunctional';
import { StagesViewed } from './components/stagesViewed/StagesViewed';

export const StagesComponent = ({
  value,
  state,
  setValue,
  stagesList,
  isPopupDescription,
}: {
  value: any;
  state?: TableCustomComponentState;
  setValue?: any;
  stagesList: any;
  isPopupDescription?: boolean;
}) => {
  const language = useSelector(selectLanguageStrings);
  return (
    <>
      {state === TableCustomComponentState.VIEW_MODAL ? (
        <StagesFunctional
          stagesList={stagesList}
          placeholder={language ? language.chooseStagesPlaceholder : 'Choose stages'}
          isDisabled
          isPopupDescription={isPopupDescription}
          value={value}
        />
      ) : state === TableCustomComponentState.EDIT ? (
        <StagesFunctional
          stagesList={stagesList}
          placeholder={language ? language.chooseStagesPlaceholder : 'Choose stages'}
          value={value}
          setValue={setValue}
        />
      ) : (
        <StagesViewed value={value} />
      )}
    </>
  );
};
