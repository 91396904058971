/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for documents page require
 * Highly customisable.
 *
 */

import React from 'react';
import { ContentDocuments } from './components/contentDocuments/ContentDocuments';

export const Documents = () => {
  return <ContentDocuments />;
};
