/**
 * @description
 * This is a component of the delete button
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '../../assets/Delete_button.svg';
import { colorFetch } from '../../styles/utils';

type ButtonProps = {
  color?: string;
  colorHover?: string;
  editInProcess?: boolean;
};

const Button = styled.div<ButtonProps>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ editInProcess }) => (editInProcess ? 'auto' : 'pointer')};

  & > svg > path {
    stroke: ${({ color, theme }) => color || colorFetch('gray3')({ theme })};
    transition: stroke 0.3s ease;
  }

  &:hover > svg > path {
    stroke: ${({ colorHover, theme }) => colorHover || colorFetch('gray2')({ theme })};
  }
`;

export interface DeleteButtonProps {
  onClick: any;
  color?: string;
  colorHover?: string;
  editInProcess?: boolean;
  className?: string;
}

export const DeleteButton: FC<DeleteButtonProps> = ({ onClick, color, colorHover, editInProcess, ...props }) => {
  return (
    <Button
      onClick={onClick}
      color={color}
      colorHover={editInProcess ? color : colorHover}
      editInProcess={editInProcess}
      {...props}
    >
      <DeleteIcon />
    </Button>
  );
};
