import React from 'react';

export const FactoryIcon = ({ color }: { color: string }) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M23.9421 28.7908L21.7427 7.52002H11.4006L9.23047 28.4291"
        stroke={color}
        stroke-width="2.7"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M33.7468 44.48V22.9453L18.2434 32.1926V44.48"
        stroke={color}
        stroke-width="2.7"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M33.7468 44.48V32.1926L49.26 22.9453V44.48H2.73999V32.1926L18.2434 22.9453V44.48"
        stroke={color}
        stroke-width="2.7"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
    </svg>
  );
};
