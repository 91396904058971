import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTooltipContext } from './context';
// @ts-ignore
import { v4 as uuidv4, v1 as uuidv1 } from 'uuid';
import { TooltipTriggerType } from './types';
import ReactTooltip from 'react-tooltip';

// use this hook to define tooltip ids
export const useTooltip = (amount?: number) => {
  const [, setTooltip] = useTooltipContext();
  const [uuidArray, setUuidArray] = useState([]);

  const createId = (index: number): string => {
    const timestampPart = uuidv1();
    const randomPart = uuidv4();
    // custom uuid logic compines static text with random uuid with
    // current index included and also adds the timestamp uuid in the end
    const combinedUuid = 'tooltip' + randomPart.slice(0, randomPart.length - 1) + index + timestampPart;
    return combinedUuid;
  };

  const createIndexArray = (limit: number): number[] => {
    let i = 0;
    let indexArray: number[] = [];
    for (i; i < limit; i++) {
      indexArray = [...indexArray, i];
    }
    return indexArray;
  };

  useEffect(() => {
    const requiredAmount = amount || 1;
    const ids = createIndexArray(requiredAmount).map((index) => {
      return createId(index);
    });
    setUuidArray(ids as any);
  }, []);

  const showTooltip = (id: string) => {
    setTooltip((value) => ({ ...value, openRequests: [...value.openRequests, { id }] }));
  };

  const hideTooltip = (id: string) => {
    setTooltip((value) => ({ ...value, closeRequests: [...value.closeRequests, { id }] }));
  };

  return [uuidArray, showTooltip, hideTooltip] as const;
};

export const useTooltipRequestMonitor = (id: string) => {
  const [tooltip] = useTooltipContext();
  const { openRequests, closeRequests } = tooltip;

  const requestedToOpen = openRequests.findIndex((request) => request.id === id) !== -1;
  const requestedToClose = closeRequests.findIndex((request) => request.id === id) !== -1;

  return [requestedToOpen, requestedToClose];
};

export const useTooltipEventHandlers = (id: string, triggerType: TooltipTriggerType, tooltipNode: HTMLElement) => {
  const [isShown, setIsShown] = useState(triggerType === TooltipTriggerType.STATIC);

  const [requestedToOpen, requestedToClose] = useTooltipRequestMonitor(id);

  const showTooltip = useCallback(() => {
    if (tooltipNode) {
      ReactTooltip.show(tooltipNode);
      setIsShown(true);
    }
  }, [tooltipNode]);

  const hideTooltip = useCallback(() => {
    if (tooltipNode) {
      ReactTooltip.hide(tooltipNode);
      setIsShown(false);
    }
  }, [tooltipNode]);

  useEffect(() => {
    triggerType === TooltipTriggerType.STATIC && showTooltip && showTooltip();
  }, [triggerType, showTooltip]);

  useEffect(() => {
    requestedToOpen && showTooltip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedToOpen]);

  useEffect(() => {
    requestedToClose && hideTooltip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedToClose]);

  const mapping = useMemo(
    () => ({
      [TooltipTriggerType.HOVER]: {
        onMouseEnter: () => {
          showTooltip();
        },
        onMouseLeave: () => {
          hideTooltip();
        },
      },
      [TooltipTriggerType.CLICK]: {
        onClick: () => {
          if (isShown) {
            hideTooltip();
          } else {
            showTooltip();
          }
        },
      },
      [TooltipTriggerType.STATIC]: {},
    }),
    [isShown, showTooltip, hideTooltip],
  );

  const handlers = mapping[triggerType];

  return [isShown, handlers];
};
