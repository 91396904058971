import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InsightDataType } from '../../enums/InsightDataType';
import { RootState } from '../store';

const initialState: any = {
  realTimeData: {
    info: {
      address: {
        line1: '',
        city: '',
        geotag: { lat: '0', lng: '0' },
      },
    },
    data: {
      rt: {
        temp: { measurement: '', unit: '' },
        water_level: { measurement: '', unit: '' },
      },
      config: [],
      comment: '',
    },
  },
  history: {
    water_level: { sensor: '', data: [], unit: '', axis_limits: { x: null, y: null } },
    temperature: { sensor: '', data: [], unit: '', axis_limits: { x: null, y: null } },
  },
  devices: {
    geopivoted: [],
    centre_coord: {
      lat: '',
      lng: '',
    },
    zoom_level_mapbox: -1,
  },
  events: {
    list: [],
    pagination: {
      total_items: 0,
      total_pages: 1,
    },
  },
};

export const insightSlice = createSlice({
  name: 'insight',
  initialState,
  reducers: {
    setRealTimeData: (state, action: PayloadAction<any>) => {
      state.realTimeData = action.payload;
    },
    editRealTimeConfig: (state, action: PayloadAction<any>) => {
      state.realTimeData.data.config = state.realTimeData.data.config.map((e: any) => {
        if (e.key === action.payload.key) {
          return { ...e, current: e.val, val: action.payload.val };
        } else {
          return e;
        }
      });
    },
    setHistory: (state, action: PayloadAction<any>) => {
      if (action.payload.type === InsightDataType.TEMPERATURE) {
        state.history.temperature = action.payload.data;
      } else if (action.payload.type === InsightDataType.TEMPERATURE) {
        state.history.water_level = action.payload.data;
      }
    },

    setDevices: (state, action: PayloadAction<any>) => {
      state.devices = action.payload;
    },
    setEvents: (state, action: PayloadAction<any>) => {
      state.events = action.payload;
    },
  },
});

export const { setRealTimeData, editRealTimeConfig, setDevices, setEvents, setHistory } = insightSlice.actions;

export const selectRealTimeData = (state: RootState) => {
  return state.insight.realTimeData;
};

export const selectHistory = (state: RootState) => {
  return state.insight.history;
};

export const selectDevices = (state: RootState) => {
  return state.insight.devices;
};

export const selectEvents = (state: RootState) => {
  return state.insight.events;
};

export const selectConfig = (state: RootState) => {
  return state.insight.realTimeData.data.config;
};

export default insightSlice.reducer;
