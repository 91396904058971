/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for action section of universal table require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import { PreparedActionConfig, InlineActionType } from '../../types';
import styled from 'styled-components';
import { media } from '../../../../styles/media';
import { DetailsButton } from '../../../../components/detailsButton/DetailsButton';
import { ArrowButton, ArrowButtonType } from '../../../../components/arrowButton/ArrowButton';
import { CustomComponent } from './components/customComponents/CustomComponents';
import { colorFetch } from '../../../../styles/utils';

const EditWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.wide > div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  & > .locationIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  & > .rightIcon {
    margin-left: 14px;
  }

  & > div > div > svg > path {
    stroke: ${colorFetch('gray2')};
  }
`;

const StyledDetailsButton = styled(DetailsButton)`
  /* max-width: 80px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  ${media.tablet`
    display: none;
  `}
`;

type StyledArrowButtonProps = {
  isSelectAllCheckBox?: boolean;
};

// @TODO fix media types
const StyledArrowButton = styled(ArrowButton)<StyledArrowButtonProps>`
  display: none;
  ${media.tablet`
  width:  ${({ isSelectAllCheckBox }: any) => (isSelectAllCheckBox ? '80px' : '20px')};
  margin-left: 12px;
    display: flex;
  `}
`;

interface ActionSectionProps {
  inlineActionSet: PreparedActionConfig[];
  onDetailsClick: any;
  data: any;
  isSelectAllCheckBox?: boolean;
}

export const ActionSection: FC<ActionSectionProps> = ({
  inlineActionSet,
  onDetailsClick,
  data,
  isSelectAllCheckBox,
}) => {
  return (
    <EditWrapper
      className={inlineActionSet.length === 1 && inlineActionSet[0].type === InlineActionType.CUSTOM ? 'wide' : ''}
    >
      {inlineActionSet.map((item, index) => {
        return item.type === InlineActionType.DETAILS ? (
          <>
            <StyledDetailsButton onClick={onDetailsClick}>Details</StyledDetailsButton>
            <StyledArrowButton
              type={ArrowButtonType.RIGHT}
              onClick={onDetailsClick}
              isSelectAllCheckBox={isSelectAllCheckBox}
            />
          </>
        ) : !!item.component ? (
          <CustomComponent key={index} data={data} item={item} />
        ) : null;
      })}
    </EditWrapper>
  );
};
