/**
 * @description
 * This is a basic inline table component
 * for locating devices on the map
 *
 */

import React, { FC } from 'react';
import { ReactComponent as LocationIcon } from '../../assets/location.svg';
import styled from 'styled-components';
import { colorFetch } from '../../styles/utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px;
  cursor: pointer;
  & > svg {
    width: 24px;
    height: 24px;
    & > path {
      transition: stroke 0.3s ease;
      stroke: ${colorFetch('gray2')};
    }
  }

  &:hover > svg > path {
    stroke: ${colorFetch('gray3')};
  }
`;

export interface LocationButtonInTableProps {
  onClick: (...props: any[]) => void;
}

export const LocationButtonInTable: FC<LocationButtonInTableProps> = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <LocationIcon />
    </Wrapper>
  );
};
