/**
 * @description
 * This is component for animation SinnerBounce
 *
 */

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colorFetch } from '../../styles/utils';

const scaleout = keyframes`
    0% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
`;

type WrapperProps = {
  backgroundColor?: string;
};

const Wrapper = styled.div<WrapperProps>`
  width: 40px;
  height: 40px;

  background-color: ${({ backgroundColor, theme }) => backgroundColor || colorFetch('primary')({ theme })};

  border-radius: 100%;

  position: relative;

  margin: 100px auto;

  -webkit-animation: ${scaleout} 1s infinite ease-in-out;
  animation: ${scaleout} 1s infinite ease-in-out;
`;

export const SpinnerBounce = ({ className, backgroundColor }: { className?: string; backgroundColor?: string }) => {
  return <Wrapper className={`spinner ${className}`} backgroundColor={backgroundColor} />;
};
