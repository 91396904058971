/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for pagination of universal table require
 * Highly customisable.
 *
 */

import React from 'react';
import styled from 'styled-components';
import { PaginationNumbering } from '../paginationNumbering/PaginationNumbering';
import { useTableContext } from '../../../context';
import { media } from '../../../../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguage, selectLanguageStrings } from '../../../../../app/state/userSlice';
import { ExportButton } from '../../../../../components/exportButton/ExportButton';
import ls from '../../../../../utils/ls';
import { LsValueType } from '../../../../../enums/LsValueType';
import { colorFetch } from '../../../../../styles/utils';

export enum PaginationType {
  BASIC = 'BASIC',
  MANAGE = 'MANAGE',
}

type WrapperProps = {
  editInProcess?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: ${colorFetch('pureWhite')};
  box-sizing: border-box;
  padding: 13px 24px;
  border-radius: 0 0 8px 8px;
  border-top: 1px solid;
  border-color: ${colorFetch('gray4')};
  opacity: ${({ editInProcess }) => (editInProcess ? '0.5' : '1')};
  ${media.phone`
    padding: 13px 16px;
  `};
`;

const WrapperExportPaginationNumbering = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Export = styled(ExportButton)`
  margin-left: 30px;
` as any; // @TODO fix type

type MarkProps = {
  editInProcess?: boolean;
};

// @TODO fix media types
const Mark = styled.span<MarkProps>`
  display: inline-block;
  color: ${colorFetch('primary')};
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  user-select: none;
  &.showing {
    color: ${colorFetch('gray1')};
  }
  &.small {
    display: none;
  }
  &.full {
    display: inline-block;
  }
  &.sliced {
    display: none;
  }
  ${media.tablet`
  &.sliced {
    display: inline-block;
  }
  &:hover.sliced {
    cursor: ${({ editInProcess }: any) => (editInProcess ? 'auto' : 'pointer')};
    color: ${colorFetch('gray2')};
    transition: color 0.3s ease;
  }
  `}
  ${media.phoneS`
    display: none;
    display: inline-block;
  `}
`;

const BoldSpan = styled.span`
  color: ${colorFetch('black1')};
  font-weight: bold;
`;

const ShowingSpan = styled.span`
  color: ${colorFetch('gray1')};
  display: inline;
  ${media.phone`
    display: none;
  `}
`;

const WrapperExport = styled.a`
  width: 100%;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  ${media.tablet`
    display: none;
  `}
`;

export const Pagination = ({
  onPageChange,
  editInProcess,
  isExport,
  mainApiUrl,
  ...props
}: {
  onPageChange: (value: number, isOffset?: boolean) => void;
  editInProcess?: boolean;
  className?: string;
  isExport?: boolean;
  mainApiUrl?: string;
}) => {
  const token = ls.get(LsValueType.token);
  const language = useSelector(selectLanguageStrings);
  const lang = useSelector(selectLanguage);
  const [{ pagination, globalProps }] = useTableContext();
  const { total_items, page_number: page } = pagination;
  const page_number = page - 1;
  const { pageSize } = globalProps;
  const pagesNum = Math.ceil(total_items / pageSize);
  const startRange = total_items > 0 ? page_number * pageSize + 1 : 0;
  const endRange = (page_number + 1) * pageSize;
  //page size added in mod part to both sides for case where: event num < pageSize
  const groundDelta =
    total_items > 0
      ? Math.floor(endRange / total_items)
        ? (endRange + pageSize) % (total_items + pageSize)
        : 0
      : pageSize;
  const limitedEndRange = endRange - groundDelta;

  const paginationOnChange = editInProcess ? () => {} : onPageChange;

  return (
    <Wrapper editInProcess={editInProcess} {...props}>
      <Mark className="showing">
        <ShowingSpan>{language ? language.paginationShowing : 'Showing'}</ShowingSpan>{' '}
        <BoldSpan>{`${startRange}-${limitedEndRange}`}</BoldSpan>
        {language ? language.paginationOf : ' of'} <BoldSpan>{total_items}</BoldSpan>
      </Mark>
      <Mark className="small" editInProcess={editInProcess}>
        <BoldSpan>{`${startRange}-${limitedEndRange}`}</BoldSpan> {language ? language.paginationOf : 'of'}
        <BoldSpan>{total_items}</BoldSpan>
      </Mark>
      <WrapperExportPaginationNumbering>
        <PaginationNumbering
          currentPage={page_number}
          onPageChange={paginationOnChange}
          pagesNum={pagesNum}
          editInProcess={editInProcess}
        />
        {isExport && (
          <WrapperExport
            href={`${mainApiUrl}?limit=0&lang=${lang.id}&format=xlsx&access_token=${token}`}
            target="_blank"
            rel={'noopener noreferer'}
          >
            <Export />
          </WrapperExport>
        )}
      </WrapperExportPaginationNumbering>
    </Wrapper>
  );
};
