import React, { FC, useState } from 'react';
import { Text } from '@bit/first-scope.text';
import styled from 'styled-components';
import { media } from '../../../../../../../styles/media';
// @ts-ignore
import { RowPlaceholder } from '../../../manualAddSingleOrderModal/components/rowPlaceholder/RowPlaceholder';
import { AddModal } from './components/AddModal';
import { ItemComponentMobile } from './ItemComponentMobile';
import { EditModal } from './components/EditModal';
import { createId } from '../../../../../../../utils/uuid';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../app/state/userSlice';
import { borderRadiusFetch, colorFetch } from '../../../../../../../styles/utils';

// styles for components block
const ComponentContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadiusFetch('primary')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  /* overflow: hidden; */
  transition: all 0.2s linear;
  ${media.tablet`
    max-width: 100%;
    width: 100%;
  `}
  ${media.phone`
    height: 100%;
  `}
  &.disabled {
    background-color: ${colorFetch('gray5')};
  }
`;

const WrapperTitleComponent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr) 20px;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-family: Poppins;
  border-bottom: 1px solid;
  color: ${colorFetch('gray1')};
  border-color: ${colorFetch('gray3')};
  padding: 0 22px;
`;

const StyledTitleText = styled(Text)`
  color: ${colorFetch('gray1')};
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
` as any; // @TODO

const ComponentHistory = styled.div`
  box-sizing: border-box;
  overflow: scroll;
  flex: 1;
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray3')};
    border-radius: 9px;
    width: 4px;
  }
`;

const ComponentContent = styled.div`
  height: 180px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  max-height: 219px;
  /* overflow: ${(props: any) => (props.height > 218 ? 'auto' : 'hidden')}; */
  /* overflow: overlay; */
  transition: all 0.3s linear;
  justify-content: flex-end;
`;

const SubmitButton = styled.button`
  width: 100%;
  height: 45px;
  padding: 0;
  margin: 0;
  background-color: ${colorFetch('gray5')};
  color: ${colorFetch('gray2')};
  border: none;
  outline: transparent;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: Poppins;
  border-top: 1px solid;
  border-color: ${colorFetch('gray3')};
  transition: all 0.3s linear;
  &:hover {
    background-color: ${colorFetch('gray4')};
  }
  &:active {
    background-color: ${colorFetch('gray4')};
  }
  &.disabled {
    background-color: ${colorFetch('gray4')};
    cursor: initial;
  }
`;

const WrapperComponentContainer = styled.div`
  width: 100%;
`;

export interface AddComponentMobileViewProps {
  components: any[];
  setComponents: (data: any) => void;
  setIsComponentFieldsValid: (data: boolean) => void;
}

export const AddComponentMobileView: FC<AddComponentMobileViewProps> = ({
  components,
  setComponents,
  setIsComponentFieldsValid,
}) => {
  const languageStrings = useSelector(selectLanguageStrings);

  const [validatingIsStarted, setValidatingIsStarted] = useState(false);
  // const [components, setComponents] = useState<any[]>([]);
  const [isAllFieldsValid, setIsAllFieldsValid] = useState<boolean>(true);
  const [isEditingInProcess, setIsEditingInProcess] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [componentsWasTouched, setComponentsWasTouched] = useState(false);
  const [quantityWasTouched, setQuantityWasTouched] = useState(false);

  const handleDeleteRowComponent = (id: string) => {
    setComponents((components: any) => components.filter((el: any) => el.component_id !== id));
  };

  const handleAddRow = () => {
    setComponentsWasTouched(false);
    setQuantityWasTouched(false);

    setComponents((component: any) => [
      ...component,
      {
        component_id: createId(),
        request_id: '',
        components_list: [],
        component_value: {},
        index: '',
        productionId: '',
        quantity: null,
      },
    ]);

    setOpenAddModal(true);
  };

  const [activeId, setActiveId] = useState<string | null>(null);

  return (
    <WrapperComponentContainer>
      <ComponentContainer>
        <WrapperTitleComponent>
          <StyledTitleText>{languageStrings.manualAddOrderComponentsName ?? 'Name'}</StyledTitleText>
          <StyledTitleText>{languageStrings.manualAddOrderComponentsIndex ?? 'Index'}</StyledTitleText>
        </WrapperTitleComponent>
        <ComponentHistory>
          {components.length > 0 ? (
            <ComponentContent>
              {components.map((el: any, index: number) => (
                <ItemComponentMobile
                  setOpenEditModal={setOpenEditModal}
                  key={index}
                  component={el}
                  index={index}
                  setActiveId={setActiveId}
                />
              ))}
            </ComponentContent>
          ) : (
            <RowPlaceholder />
          )}
        </ComponentHistory>

        <AddModal
          isOpen={openAddModal}
          setClose={setOpenAddModal}
          isEditingInProcess={isEditingInProcess}
          setIsEditingInProcess={setIsEditingInProcess}
          setComponents={setComponents}
          components={components}
          setIsAllFieldsValid={setIsAllFieldsValid}
          componentsWasTouched={componentsWasTouched}
          setComponentsWasTouched={setComponentsWasTouched}
          quantityWasTouched={quantityWasTouched}
          setQuantityWasTouched={setQuantityWasTouched}
          setIsComponentFieldsValid={setIsComponentFieldsValid}
          languageStrings={languageStrings}
        />

        <EditModal
          isOpen={openEditModal}
          setClose={setOpenEditModal}
          isEditingInProcess={isEditingInProcess}
          setIsEditingInProcess={setIsEditingInProcess}
          setComponents={setComponents}
          components={components}
          activeId={activeId}
          setActiveId={setActiveId}
          handleDeleteRowComponent={handleDeleteRowComponent}
          languageStrings={languageStrings}
        />

        <SubmitButton onClick={handleAddRow}>{languageStrings.addRowButton ?? '+ Add row'}</SubmitButton>
      </ComponentContainer>
    </WrapperComponentContainer>
  );
};
