/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for cards of documents require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { media } from '../../../../../styles/media';
import { CardType } from '../../../../../enums/CardType';
import { ReactComponent as Download } from '../../../../../assets/download.svg';
import ls from '../../../../../utils/ls';
import { LsValueType } from '../../../../../enums/LsValueType';
import { API_URL } from '../../../../../consts';
import { DocumentsIcon } from '../documentsIcon/DocumentsIcon';
import { borderRadiusFetch, colorFetch, shadowFetch } from '../../../../../styles/utils';

type ExtraWrapperProps = {
  margin?: string;
  type?: CardType;
};

const ExtraWrapper = styled.div<ExtraWrapperProps>`
  width: 280px;
  /* height: 120px; */
  box-sizing: border-box;
  box-shadow: ${shadowFetch('additionalShadow2')};
  background-color: ${colorFetch('pureWhite')};
  border: none;
  border-radius: ${borderRadiusFetch('additional16')};
  margin: ${({ margin }) => margin};
  padding: ${({ type }) => (type === CardType.CITIES ? '20px 24px 30px 24px' : '15px 24px')};
  ${media.tablet`
    width: 100%; 
  `}
`;

const WrapperTitle = styled.div`
  font-size: 21px;
  font-weight: 500;
  color: ${colorFetch('black1')};
`;

const DownloadLink = styled.a`
  width: max-content;
  display: flex;
  justify-content: flex-start;
  color: ${colorFetch('gray2')};
  text-decoration: none;
  transition: all 0.3s linear;
  cursor: pointer;
  &:hover {
    color: ${colorFetch('gray1')};
    & > svg > path {
      transition: all 0.3s ease;
      stroke: ${colorFetch('gray1')};
    }
  }
  & > svg > path {
    transition: all 0.3s ease;
    stroke: ${colorFetch('gray2')};
  }
`;

const Button = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

type WrapperProps = {
  type?: CardType;
};

const Wrapper = styled.div<WrapperProps>`
  height: ${({ type }) => (type === CardType.CITIES ? 'max-content' : '64px')};
  display: ${({ type }) => (type === CardType.CITIES ? 'block' : 'flex')};
  justify-content: space-between;
  align-items: flex-end;
  & > svg > path {
    stroke: ${colorFetch('primary')};
  }
`;

const WrapperText = styled.div`
  color: inherit;
  font-size: 12px;
  margin-left: 5px;
`;

export interface DashboardCardProps {
  languageStrings: any;
  type?: CardType;
  title: string;
  data?: any[];
  margin?: string;
  count?: string | number;
  filename: string;
}

export const DocumentsCard: FC<DashboardCardProps> = ({
  languageStrings,
  type,
  count,
  title,
  margin,
  data,
  filename,
  ...props
}) => {
  return (
    <ExtraWrapper margin={margin} type={type} {...props}>
      <Button>
        <WrapperTitle>{title}</WrapperTitle>
        <Wrapper type={type}>
          <DownloadLink
            href={`${API_URL}/v1/static/docs/${filename}?access_token=${ls.get(LsValueType.token)}`}
            target="_blank"
            rel={'noopener noreferer'}
          >
            <Download />
            <WrapperText>{languageStrings ? languageStrings.download : 'Download'}</WrapperText>
          </DownloadLink>
          <DocumentsIcon />
        </Wrapper>
      </Button>
    </ExtraWrapper>
  );
};
DocumentsCard.defaultProps = {};
