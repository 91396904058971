import React, { useEffect } from 'react';
import styled from 'styled-components';
import { media } from '../../../../../styles/media';
import { FilterDevice } from './filterDevice/FilterDevice';
import { FilterEntity } from './filterEntity/FilterEntity';
import { FilterAttach } from './filterAttach/FilterAttach';
import { View } from '../../../../../routes/routeInterfaces';
import { CommonButton } from '@bit/first-scope.common-button';
import { useFilters } from '../../Content/filterContext/сontext';
import { useCustomHistory } from '../../../../../utils/react-router-dom-abstraction';
import { FilteredButton } from '../../../../../components/filteredButton/FilteredButton';
import { SwipeSwitchFilter } from '../../../../../components/swipeSwitchFilter/SwipeSwitchFilter';
import { getIsZones, getIsAllDeviceSelect, getIsActiveSwitchZones } from '../../Content/filterContext/selectors';
import { useSelector } from 'react-redux';
import { selectLanguage, selectLanguageStrings } from '../../../../../app/state/userSlice';
import { injectPathParams, PATHS } from '../../../../../routes/paths';
import { useHistory } from '../../../../../utils/react-router-dom-abstraction';
import { stringifyUrl } from 'query-string';
import { useQueryParams } from '../../../../../utils/useQueryParams';
import { usePrevious } from '@cthings.co/utils';

const Wrapper = styled.div`
  display: flex;
`;

const FilteredWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  ${media.desktop`
    flex-wrap: wrap;
  `}
  ${media.phone`
    display: none;
  `}
`;

const ZonesButton = styled(CommonButton)`
  max-width: 126px;
  margin-top: 20px;
  ${media.desktop`
    margin-top: 30px;
  `}
  ${media.phone`
    display: none;
  `} /* ${media.monitorS`
  grid-area: 1/5;
  `}
  ${media.desktop`
    max-width: 100%;
    width: 100%;
    grid-area: 1/5;
  `}
  ${media.tablet`
    grid-area: 1/4;
  `} 
  ${media.tabletS`
    grid-area: 1/3;
  `} */
`;

export const Filters = ({ dataFloorMap, data, pathParams }: { dataFloorMap: any; data: any; pathParams: any }) => {
  const queryParams = useQueryParams() || {};
  const statusQueryZones = queryParams?.zones;
  const { state, dispatch } = useFilters();
  const { zonesState } = state;
  const { isZones } = zonesState;
  const isDisabledZones = !getIsZones(state);
  const isSelectAllDevice = getIsAllDeviceSelect(state);
  const isActiveSwitchZones = getIsActiveSwitchZones(state);
  const customHistory = useCustomHistory();
  const { id, name } = pathParams;
  const history = useHistory();
  const language = useSelector(selectLanguage);
  const prevLanguageName = usePrevious(language.name);

  const languageStrings = useSelector(selectLanguageStrings);

  useEffect(() => {
    dispatch({ type: 'ADD_NODES', payload: dataFloorMap });
  }, [dataFloorMap]);

  const setFilterParam = (filterParam: any) => {
    const toGoToLocation = injectPathParams(PATHS.USER_INSIGHTS_DETAILS, {
      id,
      name,
    });
    const url = stringifyUrl({ url: toGoToLocation, query: filterParam as any });
    history.replace(url);
  };

  const handleSwitch = () => {
    dispatch({ type: 'SELECT_ZONES' });
    setFilterParam({ ...queryParams, zones: statusQueryZones ? '' : ['zones'] });
  };

  const toggleFilterHandler = (isShowAllFilters: boolean) => {
    dispatch({
      type: isShowAllFilters ? 'SELECT_CLEAR_ALL' : 'SELECT_SHOW_ALL',
    });

    setFilterParam(
      isShowAllFilters
        ? {}
        : {
            devices: ['nodes', 'tracker', 'offline'],
            attach: ['attached', 'detached'],
            object_type: ['packages', 'inventories'],
            zones: ['zone'],
          },
    );
  };

  const getStatusFilter = (valueFilter: string[] | string, type: string) => {
    return !!valueFilter && valueFilter.length > 0 ? valueFilter.includes(type) : valueFilter === type;
  };

  useEffect(() => {
    prevLanguageName &&
      prevLanguageName !== language.name &&
      dispatch({ type: 'SET_LANGUAGE', payload: languageStrings });
  }, [language.name, prevLanguageName]);

  return (
    <Wrapper>
      <FilteredWrapper>
        <FilterDevice languageStrings={languageStrings} setFilterParam={setFilterParam} />
        <FilterAttach languageStrings={languageStrings} setFilterParam={setFilterParam} />
        <FilterEntity languageStrings={languageStrings} setFilterParam={setFilterParam} />
        <SwipeSwitchFilter
          disabled={isDisabledZones}
          name={languageStrings ? languageStrings.labelZones : 'Zones'}
          isActive={getStatusFilter(statusQueryZones, 'zone')}
          handleStateSwitch={handleSwitch}
        />
        <FilteredButton
          toggleFilterHandler={toggleFilterHandler}
          isCheckedAllDevices={isSelectAllDevice || isActiveSwitchZones}
          languageStrings={languageStrings}
        />
      </FilteredWrapper>
      <ZonesButton
        onClick={() =>
          customHistory.push(View.MANAGE_ZONES_DETAILS, {
            pathParams: { building_id: id, floor: data.floors[0].floor_level, zone_id: name, offset: 0 },
          })
        }
      >
        {languageStrings ? languageStrings.labelZones : 'Zones'}
      </ZonesButton>
    </Wrapper>
  );
};
