import React, { Dispatch, SetStateAction } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { ScrolledList, ScrolledWrapper, StyledItem, StyledText } from '../style/styles';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../app/state/userSlice';
import { Text } from '@bit/first-scope.text';

export const StyledLevelText = styled(Text)`
  font-style: normal;
  font-weight: ${(props: any) => (props.fontWeight ? props.fontWeight : '500')};
  font-size: 12px;
  line-height: 24px;
  color: ${(props: any) => (props.gray9 ? props.gray9 : props.gray1)};
  &.active {
    color: primary;
  }
`;

export interface AllLevelssProps {
  setActiveLevel: Dispatch<SetStateAction<number>>;
  levelsData: any[];
  activeItem: number;
  setExplorerRequestBody: (value: any) => any;
  setIsLoadingImageMap: (value: any) => any;
  handleClearWebsocketData: () => any;
}

export const AllLevels: React.FC<AllLevelssProps> = ({
  levelsData,
  setActiveLevel,
  activeItem,
  setExplorerRequestBody,
  setIsLoadingImageMap,
  handleClearWebsocketData,
}) => {
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);

  const { gray1, gray2, gray3, gray9, primary } = theme.colors;

  const setLocationLevel = (floorPlan_id: any, ActiveLevel: number, linkImage: string) => {
    setExplorerRequestBody((values: any) => {
      if (values?.floorplan_id !== floorPlan_id && values?.floorplan_id !== '') {
        setIsLoadingImageMap(true);
        handleClearWebsocketData();
      }
      return { ...values, floorplan_id: floorPlan_id, linkImage: linkImage };
    });
    setActiveLevel(ActiveLevel);
  };

  return (
    <ScrolledWrapper gray2={gray2} gray3={gray3}>
      <ScrolledList isLevel>
        {!!levelsData ? (
          levelsData.map((item, index: number) => {
            return (
              <StyledItem
                key={item?.building_id}
                primary={primary}
                onClick={() => setLocationLevel(item?.wpas_ref_floor_id, index, item?.floor_image_url)}
                className={index === activeItem ? 'active' : ''}
              >
                <StyledText gray1={gray1}>{`${languageStrings.labelLevel} ${item?.level}`}</StyledText>
                <StyledLevelText gray9={gray9} fontWeight={'400'}>
                  {`${languageStrings.labelItems.toLowerCase()}: ${item?.items_count} `}
                </StyledLevelText>
              </StyledItem>
            );
          })
        ) : (
          <StyledLevelText gray1={gray1}>{'N/A'}</StyledLevelText>
        )}
      </ScrolledList>
    </ScrolledWrapper>
  );
};
