/**
 * @description
 * This is component for animation SpinnerChaseDots
 *
 */

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colorFetch } from '../../styles/utils';

const loadingChase = keyframes`
    100% { 
      transform: rotate(360deg); 
    } 
`;

const dot = keyframes`
    80%, 100% {
       transform: rotate(360deg); 
      }
`;

const dotBefore = keyframes`
    50% {
      transform: scale(0.4); 
    } 
    100%, 0% {
      transform: scale(1.0); 
    } 
`;

const Wrapper = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  animation: ${loadingChase} 2.5s infinite linear both;
`;

type DotProps = {
  backgroundColor?: string;
};

const Dot = styled.div<DotProps>`
  &.sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: ${dot} 2s infinite ease-in-out both;
  }

  &.sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: ${({ backgroundColor, theme }) => backgroundColor || colorFetch('primary')({ theme })};
    border-radius: 100%;
    animation: ${dotBefore} 2s infinite ease-in-out both;
  }

  &.sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s;
  }

  &.sk-chase-dot:nth-child(2) {
    animation-delay: -1s;
  }

  &.sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s;
  }

  &.sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s;
  }

  &.sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s;
  }

  &.sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s;
  }

  &.sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s;
  }

  &.sk-chase-dot:nth-child(2):before {
    animation-delay: -1s;
  }

  &.sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s;
  }

  &.sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s;
  }

  &.sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s;
  }

  &.sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s;
  }
`;

export const SpinnerChaseDots = ({ className, backgroundColor }: { className?: string; backgroundColor?: string }) => {
  return (
    <Wrapper className={className}>
      <Dot className="sk-chase-dot" backgroundColor={backgroundColor} />
      <Dot className="sk-chase-dot" backgroundColor={backgroundColor} />
      <Dot className="sk-chase-dot" backgroundColor={backgroundColor} />
      <Dot className="sk-chase-dot" backgroundColor={backgroundColor} />
      <Dot className="sk-chase-dot" backgroundColor={backgroundColor} />
      <Dot className="sk-chase-dot" backgroundColor={backgroundColor} />
    </Wrapper>
  );
};
