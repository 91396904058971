import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CollectionIcons } from '../collectionIcons/CollectionIcons';
import { CollectionIconsType } from '../types';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/userSlice';
import { borderRadiusFetch, colorFetch, shadowFetch } from '../../../../styles/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 130px;
  height: 130px;
  margin-right: 22px;

  background-color: ${colorFetch('pureWhite')};
  box-shadow: ${shadowFetch('additionalShadow1')};
  border-radius: ${borderRadiusFetch('additional6')};

  transition: all 0.3s ease;

  &:hover {
    background-color: ${colorFetch('gray6')};
  }
  &:active {
    background-color: ${colorFetch('gray6')};
  }
  &:last-child {
    margin: 0;
  }
`;

interface CardProps {
  type: CollectionIconsType;
  textColor?: string;
  onClick: () => void;
}

export const Card = ({ type, textColor, onClick }: CardProps) => {
  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <Wrapper onClick={onClick}>
      <CollectionIcons margin="29px 0 0" type={type} />
      <Text
        color={textColor}
        margin="10px 0 0"
        fontSize="16px"
        weight="600"
        lineHeight="32px"
        type={TextType.TEXT_12_BLACK}
      >
        {CollectionIconsType.ORDER === type && (languageStrings.labelOrder || 'Order')}
        {CollectionIconsType.PACKAGE === type && (languageStrings.labelPackage || 'Package')}
        {CollectionIconsType.INVENTORY === type && (languageStrings.labelInventory || 'Inventory')}
        {CollectionIconsType.DEVICE === type && (languageStrings.labelDevice || 'Device')}
        {CollectionIconsType.SENSORS === type && (languageStrings || 'Sensors')}
      </Text>
    </Wrapper>
  );
};
