/**
 * @description
 * This component is used to search in management pages
 * Highly customisable.
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Icon } from '../../assets/Search.svg';
import { CloseButton } from '@bit/first-scope.close-button';
import { media } from '../../styles/media';
import { withLoader } from '../../features/placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../placeholders/typePlaceholders/placeholdersType';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';

type WrapperProps = {
  width?: string;
  height?: string;
};

const Wrapper = styled.div<WrapperProps>`
  box-sizing: border-box;
  width: ${({ width }) => width || '536px'};
  height: ${({ height }) => height || '40px'};
  position: relative;
  ${media.phone`
    width: 100%;
  `}
`;

type InputProps = {
  iconColor?: string;
  isActive: boolean;
};

const Input = styled.input<InputProps>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${colorFetch('pureWhite')};
  border: 1px solid;
  border-color: ${({ isActive, iconColor, theme }) =>
    isActive ? (iconColor ? iconColor : colorFetch('primary')({ theme })) : colorFetch('gray3')({ theme })};
  border-radius: ${borderRadiusFetch('primary')};
  outline: none;
  padding: 12px 12px 12px 36px;
  transition: all 0.3s linear;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
  ::placeholder {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: ${colorFetch('gray2')};
  }
  -webkit-appearance: caret;
  -moz-appearance: caret; /* mobile firefox too! */
`;

type IconWrapperProps = {
  right?: string;
  left?: string;
};

const IconWrapper = styled.div<IconWrapperProps>`
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: ${({ right }) => right};
  left: ${({ left }) => left};
`;

type SearchIconProps = {
  iconColor?: string;
};

const SearchIcon = styled(Icon)<SearchIconProps>`
  & > path {
    stroke: ${({ iconColor, theme }) => (iconColor ? iconColor : colorFetch('primary')({ theme }))};
  }
`;

const ResetButton = styled(CloseButton)`
  width: 18px;
  height: 18px;
  stroke-width: 2px;
`;

const ManagementSearchInputPlain = ({
  value,
  handleOnChange,
  placeholder,
  width,
  height,
  disabled,
  iconColor,
  ...props
}: {
  value: string;
  handleOnChange: (value: string) => void;
  placeholder: string;
  width?: string;
  height?: string;
  iconColor?: string;
  className?: string;
  disabled?: boolean;
}) => {
  const [isActive, setIsActive] = useState(false);
  const handleResetOnClick = () => {
    handleOnChange('');
  };

  return (
    <Wrapper {...props}>
      <Input
        value={value}
        onChange={(e: any) => handleOnChange(e.target.value)}
        placeholder={placeholder}
        onFocus={() => {
          setIsActive(true);
        }}
        onBlur={() => {
          setIsActive(false);
        }}
        isActive={isActive}
        iconColor={iconColor}
        disabled={disabled}
      />
      <IconWrapper left={'8px'}>
        <SearchIcon iconColor={iconColor} />
      </IconWrapper>
      {value && (
        <IconWrapper right={'8px'}>
          <ResetButton onClick={handleResetOnClick} />
        </IconWrapper>
      )}
    </Wrapper>
  );
};

export const ManagementSearchInput = withLoader(undefined, PlaceholderType.SEARCH)(ManagementSearchInputPlain);
