import { SetStateAction, Dispatch } from 'react';
import { createInventoriesTypeFunctions } from '../../../api/manageInventories';
import { SelectValue } from '../../../features/universalTable/types';

export const useInventoryTypes = (setTypes: Dispatch<SetStateAction<{ id: string; name: string }[]>>) => {
  const { createInventoriesType, updateInventoriesType, deleteInventoriesType } = createInventoriesTypeFunctions();

  const handleCreateInventoryType = ({
    body,
    callback,
  }: {
    body: { name: string };
    callback?: (data: SelectValue) => void;
  }) => {
    createInventoriesType({
      body,
      handleSuccess: (data) => {
        const { name, id } = data.payload;
        setTypes((values: any) => [...values, { name, id }]);
        callback && callback(data);
      },
    });
  };

  const handleUpdateInventoryType = ({
    body,
    callback,
  }: {
    body: SelectValue;
    callback?: (data: SelectValue) => void;
  }) => {
    updateInventoriesType({
      body,
      handleSuccess: (data) => {
        const { name, id } = data.payload;

        setTypes((values: any) => {
          const index = values.findIndex((item: SelectValue) => item.id === id);
          let newVal = [...values];
          if (index !== -1) {
            const itemToChange = values[index];
            const newItem = { id: itemToChange.id, name };
            newVal = [...values.slice(0, index), newItem, ...values.slice(index + 1)];
          }
          return newVal;
        });
        callback && callback(data);
      },
    });
  };

  const handleDeleteInventoryType = ({ body, callback }: { body: { id: string }; callback?: () => void }) => {
    deleteInventoriesType({
      body,
      handleSuccess: () => {
        const { id } = body;

        setTypes((values: any) => {
          const index = values.findIndex((item: SelectValue) => item.id === id);
          let newVal = [...values];
          if (index !== -1) {
            newVal = [...values.slice(0, index), ...values.slice(index + 1)];
          }
          return newVal;
        });
        callback && callback();
      },
    });
  };
  return { handleCreateInventoryType, handleUpdateInventoryType, handleDeleteInventoryType };
};
