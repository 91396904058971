/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings placeholder for calendar require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as Calendar } from '../assets/calendarPlaceholder.svg';

const StyledCalendar = styled(Calendar)`
  height: 293px;
  position: absolute;
  z-index: 9999;
  top: 0;
  right: -1;
  bottom: 0;
  left: 0;
`;

export interface PlaceholderCalendarProps {
  props?: any;
}

export const PlaceholderCalendar: FC<PlaceholderCalendarProps> = ({ ...props }) => {
  return <StyledCalendar width="100%" height="100%" preserveAspectRatio={'none'} {...props} />;
};
