export type ZonesActionAttempts = {
  save: boolean;
  cancel: boolean;
  delete: boolean;
};

export enum ZoneNotificationType {
  SUCCESS = 'SUCCESS',
  ERROR_OVERFLOW = 'ERROR_OVERFLOW',
  ERROR_INTERSECTION = 'ERROR_INTERSECTION',
}
