import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { FiltersState } from './filtersInterface';

const initialState: FiltersState = {
  filters: [],
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
  },
});

export const { setFilters } = filtersSlice.actions;

export const selectFilters = (state: RootState) => {
  return state.filters.filters;
};

export default filtersSlice.reducer;
