import { number } from 'joi';
import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { SOCKET_URL } from '../consts';
import { LsValueType } from '../enums/LsValueType';
import { OnlineStatusType } from '../features/clustering/types';
import ls from '../utils/ls';
import { createId } from '../utils/uuid';
import { debounce } from '../utils/throttling';

const defaultData = {
  buildings: [
    {
      id: 'string',
      floors: [
        {
          id: 'string',
          image_width: number,
          image_height: number,
          devices: [
            {
              id: 'string',
              positioning_role: 0,
              base_role: 1,
              pixel_position: {
                x: 0,
                y: 0,
              },
              options: { hasOffline: false, hasNodes: false, hasTrackers: false },
              online_status: OnlineStatusType.OFFLINE,
              base_role_name: 'string',
              positioning_role_name: 'string',
            },
          ],
        },
      ],
    },
  ],
  last_updated_at: createId(),
};

export enum typeError {
  IO_SERVER_DISCONNECT = 'io server disconnect',
  IO_CLIENT_DISCONNECT = 'io client disconnect',
  PING_TIMEOUT = 'ping timeout',
  TRANSPORT_CLOSE = 'transport close',
  TRANSPORT_ERROR = 'transport error',
}

const getMessageError = (reason: keyof typeError, langString: any) => {
  const messageError: any = {
    [typeError.IO_SERVER_DISCONNECT]: langString.webSocketErrorServerDisconnect,
    [typeError.IO_CLIENT_DISCONNECT]: langString.webSocketErrorClientDisconnect,
    [typeError.PING_TIMEOUT]: langString.webSocketErrorPingTimeout,
    [typeError.TRANSPORT_CLOSE]: langString.webSocketErrorTransportClose,
    [typeError.TRANSPORT_ERROR]: langString.webSocketErrorTransportError,
  };

  return messageError[reason] || langString.websocketWasDisconnected;
};

export const useFloorMapDetails = (
  ids: string,
  floor_ids: string,
  orderId: string | undefined,
  filterData: any,
  addNotification: any,
  languageStrings: any,
) => {
  const [data, setData] = useState<typeof defaultData>();
  const [connected, setConnected] = useState(false);
  const [updateCounter, setUpdateCounter] = useState(0);

  const [isStreamStarted, setIsStreamStarted] = useState(false);

  const resetData = () => {
    setData(defaultData);
  };

  const token = ls.get(LsValueType.token);

  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const newSocket = io(SOCKET_URL, { transports: ['websocket'] });
    setSocket(newSocket);
    return () => {
      newSocket.close();
    };
  }, [setSocket]);

  const invokeLimitedNotification = (reason: keyof typeError) =>
    debounce(
      (reason: keyof typeError) => {
        addNotification({
          message: getMessageError(reason, languageStrings),

          type: 'error',
        });
      },

      2000,
    );

  useEffect(() => {
    if (socket) {
      socket?.on('connect', () => {
        console.log('connected');
        setConnected(true);
      });

      socket.on('disconnect', (reason: any) => {
        invokeLimitedNotification(reason);
        socket.connect();
      });

      // listener for stream
      socket?.on('location_operation_mode_start_stream', (val: any) => {
        const data = JSON.parse(val).payload;

        setUpdateCounter((prev) => prev + 1);

        if (!isStreamStarted) {
          socket.emit('location_operation_mode_start_stream', {});
          setIsStreamStarted(true);
        }

        setData((value: any) => {
          const resultFloors = data.buildings[0].floors;
          const resultBuildings = [{ ...data.buildings[0], floors: resultFloors }];
          return {
            ...data,
            buildings: resultBuildings,
          };
        });
      });
    }
    return () => {
      socket?.off('location_operation_mode_start_stream');
    };
  }, [socket, isStreamStarted]);

  useEffect(() => {
    let interval: any;
    const keepAlive = () => {
      socket?.emit('location_operation_mode_start_stream');
    };
    interval = setInterval(keepAlive, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [updateCounter]);

  useEffect(() => {
    return () => {
      socket?.disconnect();
      setConnected(false);
    };
  }, [socket]);

  useEffect(() => {
    if (ids && floor_ids && socket && connected) {
      const data = {
        access_token: token,
        assets_map_request: {
          object_ids: [orderId],
          floorplan_id: floor_ids,
          building_id: ids,
          mode_type: 'collection',
          mode_collection: 'orders',
          filters: filterData,
        },
      };

      socket.emit('location_operation_mode_set_filter', data);
    }
  }, [ids, floor_ids, connected, filterData]);

  return [data, resetData] as const;
};
