import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL } from '../consts';
import { HttpService } from '../utils/http';

export const useResultSummaryData = (
  setIsLoading: (isLoading: boolean) => void,
  modeType: string,
  modeCollection: string,
  languageKey: string,
) => {
  const [localData, setLocalData] = useState({} as any);
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    setIsLoading(true);
    const url = `${API_URL}/v1/operation_mode/result_summary?object_ids=${id}&mode_type=${modeType}&mode_collection=${modeCollection}&lang=${languageKey}`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { payload } = responseData;
        setIsLoading(false);
        setLocalData(payload);
      } catch (e) {
        setIsLoading(false);
      }
    })();
  }, [languageKey]);

  return localData;
};
