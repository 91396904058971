import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TextType } from '@bit/first-scope.text';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { NewSearchBar } from './newSearchBar/NewSearchBar';
import { Breadcrumbs } from '../breadcrumbs/Breadcrumbs';
import { newSearchText } from '../../api/smartSearchMode';
import { Wrapper, Title } from './styledComponents';

export enum SearchBarObjectType {
  ORDERS = 'orders',
}

export const NewSmartSearch = ({ breadcrumbsArray }: { breadcrumbsArray: any[] }) => {
  const [searchValue, setSearchValue] = useState('');
  const [isLoadingInProcess, setIsLoadingInProcess] = useState(false);
  const [searchList, setSearchList] = useState<any>([]);

  const language = useSelector(selectLanguageStrings);

  const handleSetSmartSearchList = (list: any[]) => {
    setSearchList(list);
  };

  const handleChangeSearchBarValue = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  useEffect(() => {
    newSearchText(searchValue, 'collection', 'orders', handleSetSmartSearchList, setIsLoadingInProcess);
  }, [searchValue]);

  return (
    <Wrapper>
      <Title type={TextType.TITLE_H1} margin={'0'}>
        {language.titleOfSearchPage}
      </Title>
      <Breadcrumbs breadcrumbsArr={breadcrumbsArray} />
      <NewSearchBar
        onChange={handleChangeSearchBarValue}
        value={searchValue}
        results={searchList}
        setResults={setSearchList}
        isLoadingInProcess={isLoadingInProcess}
        isGlobalSearch
      />
    </Wrapper>
  );
};
