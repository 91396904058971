import Check from './assets/Check.svg';
import DisabledCheck from './assets/DisabledCheck.svg';
import styled from 'styled-components';
import { borderRadiusFetch, colorFetch, shadowFetch } from '../../../../../../../styles/utils';

export const Wrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  display: none;
  &.checked + span {
    background-color: ${colorFetch('primary')};
    &::before {
      position: absolute;
      content: '';
      width: 14px;
      height: 14px;
      top: 3.5px;
      background-image: url('${Check}');
      background-position: center;
    }
  }
  &:hover + span {
    box-shadow: ${shadowFetch('primary')};
  }

  &.disabled.checked + span {
    background-color: ${colorFetch('gray4')};
    &::before {
      position: absolute;
      content: '';
      width: 13px;
      height: 9px;
      top: 4px;
      left: 1.5px;
      background-image: url('${DisabledCheck}');
      background-position: center;
    }
  }
`;

export const StyledCheckbox = styled.span`
  cursor: pointer;
  width: 14px;
  height: 14px;
  display: block;
  border-radius: ${borderRadiusFetch('additional4')};
  border: 1px solid;
  border-color: ${colorFetch('primary')};
  background-color: ${colorFetch('pureWhite')};
  //transition: 0.3s linear;
  &.disabled {
    border-color: ${colorFetch('gray4')};

    background-color: ${colorFetch('gray5')};
  }
`;
