import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Facebook } from '../../../../../../assets/Facebook.svg';
import { ReactComponent as Linkedin } from '../../../../../../assets/Linkedin.svg';
import { ReactComponent as Youtube } from '../../../../../../assets/Youtube.svg';
import { media } from '../../../../../../styles/media';

const WrapperBlocks = styled.div`
  display: flex;
  justify-content: flex-left;
  margin-top: 6px;
  ${media.tabletS`
    display: flex;
    justify-content: center;
  `}
`;

const Wrapper = styled.a`
  margin: 10px;
  ${media.tabletS`
    margin: 10px 10px 0 10px;
  `}
`;

export const IconsBlock = () => (
  <WrapperBlocks>
    <Wrapper href={'https://www.linkedin.com/company/valmont-industries/'} target="_blank" rel={'noopener noreferer'}>
      <Linkedin />
    </Wrapper>
    <Wrapper href={'https://www.facebook.com/ValmontIndustries/'} target="_blank" rel={'noopener noreferer'}>
      <Facebook />
    </Wrapper>
    <Wrapper href={'https://www.youtube.com/user/ValmontIndustriesInc'} target="_blank" rel={'noopener noreferer'}>
      <Youtube />
    </Wrapper>
  </WrapperBlocks>
);
