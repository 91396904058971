/**
 * @description
 * This is component that is used in SearchBar on devices with screen width less than 768px
 */

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../../../modal/Modal';
import { SearchInput } from '../searchInput/SearchInput';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as SearchImage } from '../../../../assets/Search.svg';
import { borderRadiusFetch, colorFetch, shadowFetch } from '../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 343px;
  min-width: 343px;
  box-sizing: border-box;

  background-color: ${colorFetch('pureWhite')};

  border-radius: ${borderRadiusFetch('additional14')};

  box-shadow: ${shadowFetch('additionalShadow3')};

  padding: 8px;
`;

const SearchWrapper = styled.div`
  min-height: 104px;
  box-sizing: border-box;
  padding: 20px 12px 12px 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  & > svg > path {
    stroke: ${colorFetch('gray2')};
  }
`;

export interface SearchBarModalProps {
  isOpenModal: boolean;
  setIsOpenModal?: any;
  className?: string;
  languageStrings?: any;
}

export const SearchBarModal: FC<SearchBarModalProps> = ({ isOpenModal, setIsOpenModal, languageStrings, ...props }) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState('');

  const handleChange = (e: any, handler: any) => {
    handler(e.target.value);
  };

  return isOpenModal ? (
    <Modal isOpenModal={isOpenModal} {...props}>
      <Wrapper>
        <SearchInput
          action={() => {
            setInputValue('');
            setIsOpenModal(false);
          }}
          value={inputValue}
          onChange={(e: any) => {
            handleChange(e, setInputValue);
          }}
        />
        <SearchWrapper>
          {inputValue.length === 0 ? (
            <>
              <SearchImage />
              <Text type={TextType.TEXT_14_GRAY} color={theme.colors.gray2}>
                {languageStrings ? languageStrings.searchBarInputPlaceholder : 'Search for something...'}
              </Text>
            </>
          ) : null}
        </SearchWrapper>
      </Wrapper>
    </Modal>
  ) : null;
};

SearchBarModal.defaultProps = {
  isOpenModal: false,
};
