import React, { FormEvent } from 'react';
import styled from 'styled-components';
import { CloseButton } from '@bit/first-scope.close-button';
import { ReactComponent as SearchIcon } from '../../../../../../../assets/Search.svg';
import { colorFetch } from '../../../../../../../styles/utils';

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px;
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray3')};
  & > svg > path {
    stroke: ${colorFetch('primary')};
  }
`;

const Input = styled.input`
  width: calc(100% - 36px);
  margin-left: 4px;
  border: none;
  padding: none;
  outline: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: ${colorFetch('gray1')};
  ::placeholder {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: ${colorFetch('gray2')};
  }
`;

export const SearchInput = ({
  value,
  handleOnChange,
  handleClearInput,
  languageStrings,
  name,
  ...props
}: {
  value: string;
  handleOnChange: (e: FormEvent<HTMLInputElement>) => void;
  handleClearInput: () => void;
  languageStrings: any;
  name?: string;
}) => {
  return (
    <Wrapper {...props}>
      <SearchIcon />
      <Input
        name={name}
        value={value}
        onChange={handleOnChange}
        placeholder={languageStrings ? languageStrings.searchBarPlaceholder : 'Search...'}
      />
      {value && <CloseButton onClick={handleClearInput} width={'16px'} height={'16px'} strokeWidth={'2px'} />}
    </Wrapper>
  );
};
