import React, { ReactNode } from 'react';
// @ts-ignore
import { DragDropContainer } from 'react-drag-drop-container';

export const DragDropLayer = ({
  children,
  isDraggable,
  onDragStart,
  onDragEnd,
}: {
  children: ReactNode;
  isDraggable: boolean;
  onDragStart: any;
  onDragEnd: any;
}) => {
  return isDraggable ? (
    <DragDropContainer targetKey="foo" onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {children}
    </DragDropContainer>
  ) : (
    <>{children}</>
  );
};
