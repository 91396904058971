import React from 'react';
import styled from 'styled-components';
import { colorFetch } from '../../../../../../../styles/utils';

const Level = styled.span`
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
`;

export const LevelView = ({ value }: { value: any }) => {
  return <Level>{value || value === 0 ? value : 'N/A'}</Level>;
};
