/**
 * @description
 * This is component that display row of search popup on desctops screens
 * and contain information of searchable item
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { View } from '../../../../../routes/routeInterfaces';
import { RoleType } from '../../../types';
import { AddButton } from '../../../../../components/addButton/AddButton';
import { useMediaType } from '../../../../../styles/style.context';
import { useCustomHistory } from '../../../../../utils/react-router-dom-abstraction';
import { CommonButton } from '../../../../commonButton/CommonButton';
import { useTheme } from '@cthings.co/styles';

type ContainerProps = {
  isLast: boolean;
  borderColor: string;
};

type StyledAddButtonProps = {
  width: string;
};

const StyledAddButton = styled(AddButton)<StyledAddButtonProps>`
  width: ${({ width }) => width};
`;

const Container = styled.div<ContainerProps>`
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 14px 20px;
  border-bottom: ${({ isLast }) => !isLast && '1px solid'};
  border-color: ${({ borderColor }) => borderColor};
`;

const DeviceRoleWrapper = styled.div``;

const StyledRoleTitle = styled(Text)`
  font-weight: 400;
  margin-right: 25px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type ButtonProps = {
  margin: string;
  padding: string;
};

const Button = styled(CommonButton)<ButtonProps>`
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  white-space: nowrap;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
`;

export enum ObjectType {
  USERS = 'USERS',
  STAGES = 'STAGES',
  PROCESSES = 'PROCESSES',
  COMPONENTS = 'COMPONENTS',
  ZONES = 'ZONES',
  INVENTORIES = 'INVENTORIES',
  DEVICES = 'DEVICES',
  ORDERS = 'ORDERS',
  PACKAGES = 'PACKAGES',
}

interface PropsType {
  result: any;
  text: string;
  isLast: boolean;
  hightlight: any;
  leadToMap?: boolean;
  leadToAddPackage?: boolean;
  orders_id?: string;
  languageStrings?: any;
}

const getButtons = ({
  onDetailsButtonClick,
  goToMapHandle,
  object_type,
  languageStrings,
  theme,
}: {
  onDetailsButtonClick: (type: ObjectType) => void;
  goToMapHandle: () => void;
  object_type: any;
  languageStrings: any;
  theme: any;
}) => {
  const {
    colors: { pureWhite, gray3, gray1_5 },
  } = theme;
  return [
    {
      value: languageStrings.detailsButton || 'Details',
      onClick: () => {
        onDetailsButtonClick(object_type.toUpperCase());
      },
      width: 'auto',
      color: gray1_5,
      backgroundColor: pureWhite,
      borderColor: gray3,
      hoverColor: pureWhite,
      backgroundColorHover: gray3,
      borderColorHover: gray3,
      margin: '0 10px 0 0',
      padding: '8px 10px',
    },
    {
      value: languageStrings.goToMapButton || 'Go to map',
      onClick: goToMapHandle,
      width: 'auto',
      color: gray1_5,
      backgroundColor: pureWhite,
      borderColor: gray3,
      hoverColor: pureWhite,
      backgroundColorHover: gray3,
      borderColorHover: gray3,
      padding: '8px 10px',
    },
  ];
};

interface PropsType {
  result: any;
  text: string;
  isLast: boolean;
  highlight: any;
  leadToMap?: boolean;
  leadToAddPackage?: boolean;
  orders_id?: string;
  languageStrings?: any;
}

export const ModalRow: FC<PropsType> = ({
  result,
  text,
  isLast,
  orders_id,
  highlight,
  leadToMap,
  leadToAddPackage,
  languageStrings,
}) => {
  const theme = useTheme();
  const { black1, gray1, gray1_5, gray4 } = theme.colors;
  const { tablet } = useMediaType();
  const customHistory = useCustomHistory();
  const { object_type, id, offset, building_id, name, order_id } = result;
  const { role, name: collectionItemName, collection } = result?.searched_doc;

  const setRoleTitle = (type: RoleType) => {
    const role: any = {
      [RoleType.TRACKER]: `${languageStrings.clusteredContentTracker}:`,
      [RoleType.PACKAGES]: `${languageStrings.labelPackage}:`,
    };
    return role[type];
  };

  const getRoutes = (type: ObjectType) => {
    const to = role === 'tracker' ? View.USER_MANAGE_DEVICES_DETAILS_TRACKER : View.USER_MANAGE_DEVICES_DETAILS_NODE;

    switch (type) {
      case ObjectType.DEVICES:
        return to && customHistory.push(to, { pathParams: { id: id, buildingId: building_id, offset: 0 } });
      case ObjectType.ORDERS:
        return customHistory.push(View.MANAGE_ORDERS_DETAILS, {
          pathParams: { id: id, componentsOffset: 0, packagesOffset: 0, eventsOffset: 0 },
        });
      case ObjectType.PACKAGES:
        return customHistory.push(View.MANAGE_ORDERS_DETAILS, {
          pathParams: { id: order_id, componentsOffset: 0, packagesOffset: 0, eventsOffset: 0 },
          state: { id, offset },
        });
      case ObjectType.COMPONENTS:
        return customHistory.push(View.MANAGE_COMPONENTS, {
          pathParams: { offset: offset },
          state: { id: id },
        });
      case ObjectType.INVENTORIES:
        return customHistory.push(View.MANAGE_INVENTORIES, {
          pathParams: { offset: offset },
          state: { id: id },
        });
    }
  };

  const goToMapHandle = () => {
    return customHistory.push(View.USER_FLOOR_MAP_DETAILS, { pathParams: { id: id } });
  };

  const buttons = getButtons({ onDetailsButtonClick: getRoutes, goToMapHandle, object_type, languageStrings, theme });

  return (
    <Container borderColor={gray4} isLast={isLast}>
      <Text type={TextType.TEXT_14_BLACK} color={black1}>
        {highlight([text])}
      </Text>
      <DeviceRoleWrapper>
        <StyledRoleTitle type={TextType.TEXT_14_BLACK} color={gray1_5}>
          {setRoleTitle(collection)}
        </StyledRoleTitle>
        <Text type={TextType.TEXT_14_BLACK} color={gray1}>
          {highlight([collectionItemName])}
        </Text>
      </DeviceRoleWrapper>
      <ButtonsWrapper>
        <>
          {leadToAddPackage && (
            <StyledAddButton
              onClick={(e: any) => {
                e.stopPropagation();
                const to = View.MANAGE_ORDERS_DETAILS;
                to &&
                  customHistory.push(to, {
                    pathParams: { id: orders_id, componentsOffset: 0, packagesOffset: 0, eventsOffset: 0 },
                    state: { isAddModalFromGlobalSearch: true },
                  });
              }}
              title={tablet ? languageStrings.addButton : languageStrings.addPackageButton}
              width={tablet ? '72px' : '125px'}
              height="32px"
              marginButton={'0 20px 0 0'}
            />
          )}
          {buttons.map((button: any, index: number) => {
            const { value } = button;
            return (
              <Button key={index} {...button}>
                {value}
              </Button>
            );
          })}
        </>
      </ButtonsWrapper>
    </Container>
  );
};
