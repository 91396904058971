/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings swipe switch require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import SwitchUntyped from 'react-switch';

const Switch = SwitchUntyped as any;

export interface SwipeSwitchProps {
  stateSwitch: boolean;
  handleStateSwitch: any;
  height: number;
  width: number;
  onColor: string;
  offColor: string;
  disabled?: boolean;
}

export const SwipeSwitch: FC<SwipeSwitchProps> = ({
  stateSwitch,
  handleStateSwitch,
  height,
  width,
  onColor,
  offColor,
  disabled,
}) => {
  return (
    <Switch
      onChange={handleStateSwitch}
      checked={stateSwitch}
      checkedIcon={false}
      uncheckedIcon={false}
      onColor={onColor}
      offColor={offColor}
      height={height}
      width={width}
      disabled={disabled}
    />
  );
};
