/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for devices page
 * Highly customisable.
 *
 */

import styled from 'styled-components';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectLanguageStrings, selectAccess } from '../../../app/state/userSlice';
import { ReactComponent as LocationImage } from '../../../assets/locationGray.svg';
import { API_URL } from '../../../consts';
import { useTableFunctions } from '../../../features/universalTable/context';
import { InlineActionType, RowItemType, TableCustomComponentState } from '../../../features/universalTable/types';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import { View } from '../../../routes/routeInterfaces';
import { media } from '../../../styles/media';
import { useMediaType } from '../../../styles/style.context';
import { useCustomHistory } from '../../../utils/react-router-dom-abstraction';
import { useQueryParams } from '../../../utils/useQueryParams';
import { ManageTitle } from '../components/manageTitle/ManageTitle';
import { Attached } from './components/attached/Attached';
import { Battery } from './components/battery/Battery';
import { Wifi } from './components/wifi/Wifi';
import { stringifyUrl } from 'query-string';
import { colorFetch } from '../../../styles/utils';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  ${media.tablet`
    padding: 60px 0 10px 0;
  `}
`;

const LocationButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LocationButton = styled(LocationImage)`
  width: 24px;
  height: 24px;
  margin-right: 14px;
  cursor: pointer;
  & > path {
    stroke: ${colorFetch('gray2')};
    transition: stroke 0.3s ease;
  }
  &:hover > path {
    stroke: ${colorFetch('gray3')};
  }
`;

export interface DevicesProps {}

export const Devices: FC<DevicesProps> = () => {
  const keyword = 'DevicesTable';
  const offsetKey = 'offset';
  const { setSearchValue, getSearchValue, resetTable } = useTableFunctions();

  const { actual: searchValue } = getSearchValue();
  const params = useParams();
  const mediaType = useMediaType();
  const customHistory = useCustomHistory();

  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const queryParams = useQueryParams() || {};
  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };
  const queryTransformed = stringifyUrl({ url: '', query: queryParams }) + '&';
  const languageStrings = useSelector(selectLanguageStrings);
  const accessData = useSelector(selectAccess).management.devices;

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  return (
    <Wrapper>
      <ManageTitle
        keyword={keyword}
        title={languageStrings ? languageStrings.titleOfDevicespage : 'Devices'}
        searchInputValue={searchValue}
        handleChangeSearchInputValue={setSearchValue}
      />
      <UniversalTable
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !mediaType.tablet, countRows: mediaType.phone ? 6 : 12 },
        }}
        offset={+offset}
        offsetKey={offsetKey}
        tablePath={View.MANAGE_DEVICES}
        mainApiUrl={
          queryParams.device_role ? `${API_URL}/v1/devices/filters${queryTransformed}` : `${API_URL}/v1/devices/`
        }
        withoutQuestionMarkBeforeLimit={!!queryParams.device_role}
        keyword={keyword}
        additionalKeywords={['ManageLayoutSearch']}
        sortingData={sortingData}
        sortingKeys={sortingKeys}
        pathParams={params}
        queryParams={queryParams}
        isExport={accessData?.view}
        updateItems={() => {}}
        customRowClick={(value: any) => {
          const to =
            value.role === 'Tracker' ? View.USER_MANAGE_DEVICES_DETAILS_TRACKER : View.USER_MANAGE_DEVICES_DETAILS_NODE;
          to &&
            customHistory.push(to, {
              pathParams: { id: value.id, buildingId: value.location.building_id, offset: 0 },
            });
        }}
        inlineActionSet={[
          {
            type: InlineActionType.CUSTOM,
            component: (
              <LocationButtonWrapper>
                <LocationButton />
              </LocationButtonWrapper>
            ),
            callback: (data: any) => {
              customHistory.push(View.USER_INSIGHTS_DETAILS, {
                pathParams: { id: data.location.building_id, name: data.name },
              });
            },
          },
          {
            type: InlineActionType.DETAILS,
          },
        ]}
        pageSize={mediaType.phone ? 6 : 18}
        columnStructure={[
          {
            fieldName: 'name',
            placeholder: '',
            label: languageStrings ? languageStrings.name : 'Name',
            defaultValue: '',
          },
          {
            fieldName: 'role',
            placeholder: '',
            label: languageStrings ? languageStrings.labelType : 'Type',
            defaultValue: '',
          },
          {
            fieldName: 'location',
            placeholder: '',
            label: languageStrings ? languageStrings.labelLocation : 'Location',
            defaultValue: '',
            getTextDisplay: (value: any) =>
              value.address.city === '' ? 'N/A' : `${value.address.city}, ${value.address.country}`,
          },
          {
            fieldName: 'location',
            placeholder: '',
            label: languageStrings ? languageStrings.labelZone : 'Zone',
            defaultValue: '',
            getTextDisplay: (value: any) => value.zone || 'N/A',
          },
          {
            fieldName: 'battery_status',
            placeholder: '',
            label: languageStrings ? languageStrings.labelBattery : 'Battery',
            defaultValue: '',
            type: RowItemType.CUSTOM,
            component: (value: any, state?: TableCustomComponentState) => (
              <Battery batteryLevel={value.battery_lvl} isCharging={value.is_charging} state={state} />
            ),
          },
          {
            fieldName: 'tag_status',
            placeholder: '',
            label: languageStrings ? languageStrings.labelConnection : 'Connection',
            defaultValue: '',
            type: RowItemType.CUSTOM,
            component: (value: any, state?: TableCustomComponentState) => <Wifi status={value} state={state} />,
          },
          {
            fieldName: 'attached',
            placeholder: '',
            label: languageStrings ? languageStrings.labelAttached : 'Attached',
            defaultValue: '',
            type: RowItemType.CUSTOM,
            component: (value: any) => <Attached status={value} />,
          },
        ]}
      />
    </Wrapper>
  );
};
