/**
 * @description
 * This is component that display levels
 * and give possibility necessary level on ZonesDetails and CampusMapInsight pages
 */

import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as ArrowIcon } from '../../assets/small_arrow.svg';
import { useEffect } from 'react';
import { media } from '../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguage, selectLanguageStrings } from '../../app/state/userSlice';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';
import { useTheme } from '@cthings.co/styles';

type WrapperProps = {
  width?: string;
  isShown?: boolean;
};

// @TODO fix media types
const Wrapper = styled.div<WrapperProps>`
  width: ${({ width }) => width || '210px'};
  height: 40px;
  box-sizing: border-box;
  background-color: ${colorFetch('pureWhite')};
  border-radius: ${borderRadiusFetch('primary')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  position: relative;
  margin-left: 10px;
  cursor: pointer;
  ${media.tablet`
    display: ${({ isShown }: any) => (isShown ? 'flex' : 'none')};
  `}
`;

const Container = styled.div`
  width: 210px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type ArrowProps = {
  isOpenModal: boolean;
};

const Arrow = styled(ArrowIcon)<ArrowProps>`
  transform: ${({ isOpenModal }) => isOpenModal && 'rotate(-180deg)'};
  transition: all 0.3s ease-in-out;
  margin-left: 12px;
`;

type PopupWrapperProps = {
  isOpenModal: boolean;
};

const PopupWrapper = styled.div<PopupWrapperProps>`
  width: calc(100% + 2px);
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${colorFetch('pureWhite')};
  border-radius: ${borderRadiusFetch('primary')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  padding: 10px 12px;
  position: absolute;
  top: 46px;
  left: -1;
  z-index: 1;
  display: ${({ isOpenModal }) => (isOpenModal ? 'flex' : 'none')};
  transition: all 0.2s ease-in-out;
`;

const LevelItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > span:last-child {
    width: 77px;
  }
`;

const ElementsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MockContainer = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 12px;
`;

const NoSelectText = styled(Text)`
  user-select: none;
`;

export const SelectLevel = ({
  currentLevel,
  setCurrentLevel,
  countOfLevels,
  countOfElementsOnTheFloor,
  width,
  levelArr,
  nameOfElements,
  isShown,
  ...props
}: {
  currentLevel: number;
  setCurrentLevel: React.Dispatch<React.SetStateAction<number>>;
  countOfElementsOnTheFloor: number;
  countOfLevels: number;
  levelArr: any[];
  nameOfElements: string;
  width?: string;
  isShown?: boolean;
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const theme = useTheme();
  const { black1 } = theme.colors;

  const language = useSelector(selectLanguageStrings);
  const appLanguage = useSelector(selectLanguage);
  const levelRef = useRef<any>(null);

  const wordEnding = (nameOfElements: string) => {
    return appLanguage.shortName === 'EN' ? nameOfElements + 's' + ': ' : nameOfElements.slice(0, -1) + 'y' + ': ';
  };

  const handleSelectOnClick = () => {
    isOpenModal ? setIsOpenModal(false) : setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };
  const handleOutsideHandler = useCallback(
    (e: any) => {
      if (isOpenModal && !(levelRef && levelRef.current && (levelRef as any).current.contains(e.target))) {
        closeModal();
      }
    },
    [isOpenModal],
  );
  const handleChooseLevel = (e: any, index: number) => {
    e.stopPropagation();
    setCurrentLevel(index);
    closeModal();
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideHandler);
    return () => {
      window.removeEventListener('click', handleOutsideHandler);
    };
  }, [handleOutsideHandler]);

  return (
    <Wrapper width={width} ref={levelRef} isShown={isShown} {...props}>
      <Container onClick={handleSelectOnClick}>
        <NoSelectText type={TextType.TEXT_14_BLACK} color={black1}>
          {language ? language.labelLevel : 'Level'}{' '}
          {levelArr && levelArr[currentLevel]
            ? levelArr[currentLevel].floor_level
            : language
            ? language.labelNA
            : 'N/A'}
        </NoSelectText>
        <ArrowWrapper>
          <NoSelectText type={TextType.TEXT_12_GRAY}>
            {nameOfElements.length < 8
              ? countOfElementsOnTheFloor > 1
                ? wordEnding(nameOfElements)
                : `${nameOfElements} :`
              : ''}
            {countOfElementsOnTheFloor}
          </NoSelectText>
          {levelArr.length > 1 ? <Arrow isOpenModal={isOpenModal} /> : null}
        </ArrowWrapper>
      </Container>
      {levelArr.length > 1 ? (
        <PopupWrapper isOpenModal={isOpenModal}>
          {levelArr.map((item: any, index: number) => {
            const { floor_level } = item;
            return (
              <LevelItem
                key={index}
                onClick={
                  index !== currentLevel
                    ? (e: any) => {
                        handleChooseLevel(e, index);
                      }
                    : () => {}
                }
              >
                <NoSelectText type={TextType.TEXT_14_BLACK} color={black1}>
                  {language ? language.labelLevel : 'Level'} {floor_level}
                </NoSelectText>
                <ElementsWrapper>
                  <NoSelectText type={TextType.TEXT_12_GRAY}>
                    {countOfElementsOnTheFloor}{' '}
                    {nameOfElements.length < 8
                      ? countOfElementsOnTheFloor > 1
                        ? `${nameOfElements}s`
                        : `${nameOfElements}`
                      : ''}
                  </NoSelectText>
                  <MockContainer />
                </ElementsWrapper>
              </LevelItem>
            );
          })}
        </PopupWrapper>
      ) : null}
    </Wrapper>
  );
};
