/**
 * Converts data to pixelDistance
 * @param lat1
 * @param lon1
 * @param lat2
 * @param lon2
 *
 */

export const getGeoDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;
  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // in metres
  return d;
};

export interface PixelPoint {
  x: number;
  y: number;
}

/**
 * Get getPixelDistance between points
 * @param point1
 * @param point2
 *
 */

export const getPixelDistance = (point1: PixelPoint, point2: PixelPoint) => {
  return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
};
