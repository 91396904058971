import React from 'react';
import { useDispatch } from 'react-redux';
import { setManualModal } from '../../../../app/state/ordersTableSlice';
import { View } from '../../../../routes/routeInterfaces';
import { useViewMatch } from '../../../../routes/utils';
import { ManageTitle } from '../../../manageTitle/ManageTitle';
import { useTableContext, useTableFunctions } from '../../context';

export const MobileInput = ({
  mainApiUrl,
  isExport,
  isAccess,
}: {
  mainApiUrl?: string;
  isExport?: boolean;
  isAccess?: boolean;
}) => {
  const [data] = useTableContext();
  const { getSearchValue, setSearchValue, startAddProcess } = useTableFunctions();
  const { globalProps } = data;
  const { keyword } = globalProps;
  const { actual: searchValue } = getSearchValue();

  const matchView = useViewMatch();

  const dispatch = useDispatch();

  return (
    <ManageTitle
      keyword={keyword}
      searchInputValue={searchValue}
      handleChangeSearchInputValue={setSearchValue}
      addButtonOnClick={
        matchView(View.MANAGE_ORDERS as any)
          ? () => {
              dispatch(setManualModal(true));
            }
          : startAddProcess
      }
      hideTitle={true}
      paddingDevice={'64px 0 12px 0'}
      isExport={isExport}
      mainApiUrl={mainApiUrl}
      isAccess={isAccess}
    />
  );
};
