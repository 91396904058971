import React from 'react';
import styled from 'styled-components';
import { Text } from '@bit/first-scope.text';
import valmontLogo from '../../../../../assets/valmontLogo.png';
import { useSelector } from 'react-redux';
import { ReactComponent as Location } from '../../../../../assets/location.svg';
import { ReactComponent as Phone } from '../../../../../assets/phone.svg';
import { ReactComponent as Globe } from '../../../../../assets/globe.svg';
import { ReactComponent as Printer } from '../../../../../assets/printer.svg';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { media } from '../../../../../styles/media';
import { IconsBlock } from './iconsBlock/IconsBlock';
import { useMediaType } from '../../../../../styles/style.context';
import { colorFetch } from '../../../../../styles/utils';

type WrapperProps = {
  width?: string;
};

const Wrapper = styled.div<WrapperProps>`
  width: ${({ width }) => width || ''};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 130px;
  grid-gap: 10px;
  grid-template-areas: 'item0 item1 item2 item3';
  padding: 30px 0 40px 0;
  border-top: 1px solid;
  border-color: ${colorFetch('gray4')};

  ${media.desktop`
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "item0 item1 item3"
    "item2 item2 item2";
    ;
  `};

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "item0 item3" 
    "item2 item1";
    ;
    padding: 30px 0 40px 0;

    &.passwordPage {
      padding: 20px 35px;
      box-sizing: border-box;
  }
  `};

  ${media.tabletS`
    padding: 10px 0;
    grid-template-columns: 1fr;
    grid-template-areas:
      "item0""item3""item1""item2"
      ;
    &.passwordPage {
      padding: 30px 15px;
  }  
  `};
`;

const WrapperLogo = styled.div`
  grid-area: item0;
  ${media.tabletS`
    display: flex;
    justify-content: center;
    padding-top: 35px;
  `};
`;

const Logo = styled.img`
  transition: none;
  width: 180px;
  height: 45px;
  /* &.passwordPage {
    width: 215px;
    height: 55px;
  } */
`;

const TextInfoBold = styled(Text)`
  padding-left: 5px;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${colorFetch('gray1')};
` as any; // @TODO

const TextInfo = styled(Text)`
  position: relative;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${colorFetch('gray1')};
  ${media.tabletS`
    display: flex;
    justify-content: center;
    text-align: center;
    white-space: pre-wrap;
`}
  &.passwordPage::after {
    content: '';
    position: absolute;
    background-color: ${colorFetch('gray1')};
    left: 0;
    bottom: 7px;
    width: 100%;
    height: 1px;
  }
` as any; // @TODO

const PhoneNumber = styled(TextInfo)`
  ${media.tablet`
    white-space: nowrap;
  `}
`;

const TextContactUs = styled(Text)`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${colorFetch('gray1')};
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray1_5')};
  ${media.tabletS`
    display: flex;
    justify-content: center;
`}
` as any; // @TODO

const WrapperPhone = styled.div`
  display: flex;
  justify-content: flex-start;
  grid-area: item0;
  gap: 20px;
  ${media.tablet`
    flex-wrap: wrap;
    gap: 10px;
  `}
  ${media.tabletS`
    justify-content: center;
  `}
`;

const WrapperIcon = styled.div`
  margin-right: 5px;
`;

const WrapperAddress = styled.div`
  grid-area: item1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const WrapperContact = styled.div`
  grid-area: item2;
  gap: 10px;

  ${media.desktop`
    display: flex;
    
  `};

  ${media.tablet`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
    "item0" 
    "item1";
    ;
`}
`;

const ItemInfo = styled.div`
  ${media.tabletS`
    display: flex;
    justify-content: center;
  `}
`;

const ItemInfoWithIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  ${media.tabletS`
    justify-content: center;
  `}
`;

const ItemInfoWithLocation = styled.div`
  display: flex;
  justify-content: flex-start;
  ${media.tabletS`
    display: flex;
    justify-content: center;
  `}
`;

const ItemInfoWithFax = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-left: 20px; */
  ${media.tabletS`
    margin-left: 0;
  `}
`;

const WrapperLink = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  grid-area: item1;
  ${media.desktop`
    margin-top: 0;
  `}
  ${media.tabletS`
    justify-content: center;
    margin-bottom: 35px;
    &.passwordPage {
      margin:0
    }
  `}
`;

const WrapperContacts = styled.div`
  grid-area: item3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  ${media.tabletS`
   margin: 20px 0 10px;
   justify-content: center;
  `};
`;

const WrapperContactUs = styled.div`
  margin-left: 10px;
  ${media.tabletS`
    margin-left: 0px;
    display: flex;
    justify-content: center;
  `}
`;

const LinkPreview = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: flex;
`;

interface FooterProps {
  width?: string;
  className?: string;
  address?: string;
  valmontLink?: string;
}

export const Footer: React.FC<FooterProps> = ({ width, className, address, valmontLink }) => {
  const language = useSelector(selectLanguageStrings);
  const { tabletNavigation } = useMediaType();

  return (
    <Wrapper width={width ? width : ''} className={className}>
      <WrapperLogo>
        <Logo className={className} src={valmontLogo} />
      </WrapperLogo>
      <WrapperAddress>
        <ItemInfo>
          <TextInfoBold>Valmont Polska Sp. z o.o.</TextInfoBold>
        </ItemInfo>
        <ItemInfoWithLocation>
          <WrapperIcon>
            <Location />
          </WrapperIcon>
          <LinkPreview
            href={'https://maps.app.goo.gl/eHg1trq3wkrF88LQ6?g_st=ic'}
            target="_blank"
            rel={'noopener noreferer'}
          >
            <TextInfo>
              {address
                ? address
                : !tabletNavigation
                ? '08-110 Siedlce, ul. Majora Henryka Sucharskiego 6'
                : '08-110 Siedlce, ul. mjr H. Sucharskiego 6'}
            </TextInfo>
          </LinkPreview>
        </ItemInfoWithLocation>
      </WrapperAddress>
      <WrapperContact>
        <WrapperPhone>
          <ItemInfoWithIcon>
            <WrapperIcon>
              <Phone />
            </WrapperIcon>
            <PhoneNumber> +48 (25) 643 04 10</PhoneNumber>
          </ItemInfoWithIcon>
          <ItemInfoWithFax>
            <WrapperIcon>
              <Printer />
            </WrapperIcon>
            <PhoneNumber>+48 (25) 643 04 11</PhoneNumber>
          </ItemInfoWithFax>
        </WrapperPhone>

        <WrapperLink className={className}>
          <WrapperIcon>
            <Globe />
          </WrapperIcon>
          <LinkPreview href={'https://www.valmont.pl'} target="_blank" rel={'noopener noreferer'}>
            <TextInfo className={className}> {valmontLink ? valmontLink : 'www.valmont.pl'}</TextInfo>
          </LinkPreview>
        </WrapperLink>
      </WrapperContact>
      <WrapperContacts>
        <WrapperContactUs>
          <ItemInfo>
            <LinkPreview
              href={'https://www.valmont.pl/valmont-poland/pl/kontakt/dział-handlowy'}
              target="_blank"
              rel={'noopener noreferer'}
            >
              <TextContactUs>{language.previewContactUs}</TextContactUs>
            </LinkPreview>
          </ItemInfo>
        </WrapperContactUs>
        <IconsBlock />
      </WrapperContacts>
    </Wrapper>
  );
};
