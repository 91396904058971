import React from 'react';
import { TableCustomComponentState } from '../../../../../features/universalTable/types';
import { Charging } from './components/charging/Charging';
import { Indicator } from './components/indicator/Indicator';
import styled from 'styled-components';

type WrapperProps = {
  isModal: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  margin-left: ${({ isModal }) => (isModal ? '6px' : '0')};
`;

export const Battery = ({
  batteryLevel,
  isCharging,
  state,
}: {
  batteryLevel: number;
  isCharging?: boolean;
  state: TableCustomComponentState | undefined;
}) => {
  return (
    <Wrapper isModal={state === TableCustomComponentState.VIEW_MODAL}>
      {isCharging ? <Charging /> : <Indicator batteryLevel={batteryLevel} />}
    </Wrapper>
  );
};
