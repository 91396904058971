import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select } from '../../../../../../../../components/select/Select';
import { media } from '../../../../../../../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../../app/state/userSlice';
import { useTableContext, useTableFunctions } from '../../../../../../../../features/universalTable/context';
import { useDefaultPackageName, useGetComponentsList } from '../../../../../../../../api/manageOrderDetails';
import { SmallLoader } from '../../../../../../../../features/smallLoader/SmallLoader';
import { TableCustomComponentState } from '../../../../../../../../features/universalTable/types';

const Wrapper = styled.div`
  position: relative;
`;

const StyledSelect = styled(Select)`
  width: 230px;
  & > div {
    min-height: 40px;
  }
  & > div:nth-child(2) {
    top: 44px;
  }
  ${media.tablet`
     width: 100%;
   `}
`;

const StyledLoader = styled(SmallLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(2px);
`;

export const SelectComponent = ({
  label,
  value,
  state,
  setValue,
  orderId,
  disabled,
}: {
  value: { name: string; index: string; id: string };
  setValue: (val: { name: string; id: string }) => void;
  orderId: string;
  state: TableCustomComponentState | undefined;
  label?: string;
  disabled?: boolean;
}) => {
  const language = useSelector(selectLanguageStrings);
  const { getRowEssentials } = useTableFunctions();
  const [{ openId }] = useTableContext();
  const { rawData, setItem } = getRowEssentials(openId ?? '');

  const [productionId, setProductionId] = useState('');
  const [componentId, setComponentId] = useState('');

  const [getPackageDefaultName] = useDefaultPackageName(orderId, productionId);
  const [getComponentsList] = useGetComponentsList(orderId, rawData?.id);

  const { components } = getComponentsList;

  const handleOnChange = (value: any) => {
    setValue(value);
    setProductionId(value.production_id);
    setComponentId(value.id);
  };

  useEffect(() => {
    getPackageDefaultName?.name && setItem('name', getPackageDefaultName?.name);
    componentId && setItem('process', rawData?.component?.process);
  }, [getPackageDefaultName.name, componentId]);

  return (
    <Wrapper>
      <StyledSelect
        label={label}
        value={value}
        values={components}
        onChange={handleOnChange}
        disabled={disabled}
        placeholder={language.multipleSelectInputPlaceholder}
        isPendingRequest={!components?.length}
      />
      {state === TableCustomComponentState.VIEW_MODAL && !components && <StyledLoader />}
    </Wrapper>
  );
};
