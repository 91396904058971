import React, { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, NavLink as NavLinkRRD } from 'react-router-dom';
import { NavLinkProps } from './react-router-dom-interfaces';
import { getAccess, getPath, injectPathParams } from '../routes/paths';
import { View } from '../routes/routeInterfaces';
import { getObjectPath } from './getObjectPath';
import { selectAccess } from '../app/state/userSlice';

export const useHistory = () => {
  const navigate = useNavigate();
  const accessObj = useSelector(selectAccess);

  const handlePush = (path: string, state?: any) => {
    const { access, ...newState } = state || {};
    if (state) {
      if (access && access.length > 0) {
        const permission = getObjectPath(access, accessObj);

        if (!permission) return;
      }
    }
    navigate(path, { state: newState });
  };
  const handleReplace = (path: string, state?: any) => {
    const { access, ...newState } = state || {};
    if (state) {
      if (access && access.length > 0) {
        const permission = getObjectPath(access, accessObj);
        if (!permission) return;
      }
    }
    navigate(path, { state: newState, replace: true });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const resultHistory = { push: handlePush, replace: handleReplace, goBack: handleGoBack };

  return resultHistory;
};

export const useCustomHistory = () => {
  const navigate = useNavigate();
  const accessObj = useSelector(selectAccess);
  const handlePush = (view: typeof View[keyof typeof View], state?: any) => {
    const access = getAccess(view);
    let path = getPath(view);
    if (state) {
      const { pathParams } = state;
      if (pathParams) {
        path = injectPathParams(path, pathParams);
        delete state.pathParams;
      }
    }
    if (access && access.length > 0) {
      const permission = getObjectPath(access, accessObj);
      if (!permission) return;
    }
    navigate(path, { state });
  };
  const handleReplace = (view: typeof View[keyof typeof View], state?: any) => {
    const access = getAccess(view);
    let path = getPath(view);
    if (state) {
      const { pathParams } = state;
      if (pathParams) {
        path = injectPathParams(path, pathParams);
        delete state.pathParams;
      }
    }
    if (access && access.length > 0) {
      const permission = getObjectPath(access, accessObj);
      if (!permission) return;
    }
    navigate(path, { state, replace: true });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const resultHistory = { push: handlePush, replace: handleReplace, goBack: handleGoBack };

  return resultHistory;
};

export const NavLink = ({ access, children, onClick, ...props }: NavLinkProps) => {
  const accessObj = useSelector(selectAccess);

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    onClick && onClick(e);
    const permission = !(access && access.length > 0) || getObjectPath(access, accessObj);
    if (!permission) e.preventDefault();
  };
  return (
    <NavLinkRRD {...props} onClick={handleClick}>
      {children}
    </NavLinkRRD>
  );
};
