// @ts-ignore
import styled from '@xstyled/styled-components';
import { Text } from '@bit/first-scope.text';

export const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  width: 100%;
`;

export const AdressBlock = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border: 1px solid;
  border-color: ${(props: any) => (props.showAllAddresses ? props.primary : props.gray3)};
  border-right: ${(props: any) => props.showAllLEvels && `1px solid ${props.primary}`};
  border-radius: 6px 0px 0px 0px;
  border-bottom: 0px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 8px 12px;
`;

export const Arrow = styled.div`
  display: flex;
  transition: all 0.5s ease;
  transform: ${(props: any) => (props.isOpen ? 'rotate(90deg)' : 'rotate(-90deg)')};

  & > svg > path {
    stroke: gray3;
  }
`;

export const StyledText = styled(Text)`
  font-style: normal;
  font-weight: ${(props: any) => (props.fontWeight ? props.fontWeight : '500')};
  font-size: 14px;
  line-height: 24px;
  color: ${(props: any) => (props.gray9 ? props.gray9 : props.gray1)};
  &.active {
    color: primary;
  }
`;

export const ItemCountBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemCount = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: gray2;
  margin-right: 12px;
  &.active {
    color: primary;
  }
`;

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  &.active {
    & > span {
      color: primary;
    }
  }
  &:first-child {
    margin: 0;
  }
`;

export const LevelsBlock = styled.div`
  width: 248px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border: 1px solid;
  border-color: ${(props: any) => (props.showAllLEvels ? props.primary : props.gray3)};
  border-left: none;
  border-radius: 0px 6px 0px 0px;
  border-bottom: 0px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 8px 12px;
`;

export const ScrolledWrapper = styled.div`
  position: absolute;
  top: 42px;
  left: 0;
  width: 100%;
  z-index: 999;
  box-sizing: border-box;
  display: flex;
  background-color: white;
  border: 1px solid;
  border-color: gray4;
  box-shadow: 0px 6px 100px rgba(7, 4, 85, 0.01), 0px 4px 100px rgba(7, 4, 112, 0.02),
    0px 8px 36px rgba(7, 4, 102, 0.03), 0px 6px 16px rgba(5, 3, 88, 0.02);
  border-radius: 6px;
`;

export const ScrolledList = styled.ul`
  width: 100%;
  max-height: 295px;
  background-color: white;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  padding: ${(props: any) => (props.isLevel ? '10px 30px 10px 10px' : '10px 20px 10px 10px')};
  margin: 2px 2px 2px 0;
  border-radius: ${(props: any) => props.theme.borderRadius.primary};
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray3;
    border-radius: 9px;
    width: 4px;
  }
`;
