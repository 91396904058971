export var SizesType;
(function (SizesType) {
    SizesType["megaMonitor"] = "megaMonitor";
    SizesType["ultramonitor"] = "ultramonitor";
    SizesType["wideMonitor"] = "wideMonitor";
    SizesType["monitor"] = "monitor";
    SizesType["desktop"] = "desktop";
    SizesType["tablet"] = "tablet";
    SizesType["phone"] = "phone";
    SizesType["phoneS"] = "phoneS";
    SizesType["phoneXS"] = "phoneXS";
})(SizesType || (SizesType = {}));
export const sizes = {
    megaMonitor: 2800,
    ultramonitor: 1920,
    wideMonitor: 1680,
    monitor: 1440,
    desktop: 1024,
    tablet: 769,
    phone: 426,
    phoneS: 376,
    phoneXS: 321,
};
export const mediaType = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = window.matchMedia(`(max-width: ${sizes[label]}px)`).matches;
    return acc;
}, {});
