import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { TableCustomComponentState } from '../../../../../../../features/universalTable/types';
import { StageSelect } from './stageSelect/StageSelect';
import { useTheme } from '@cthings.co/styles';

const StyledText = styled(Text)``;

export const StagesList = ({
  state,
  value,
  setValue,
  orderId,
  label,
  disabled,
}: {
  value: any;
  setValue: any;
  orderId: string;
  state: TableCustomComponentState | undefined;
  label?: string;
  disabled?: boolean;
  language?: any;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  return (
    <>
      {state !== TableCustomComponentState.VIEW ? (
        <StageSelect label={label} value={value} setValue={setValue} orderId={orderId} state={state} />
      ) : (
        <StyledText type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
          {value?.name || 'N/A'}
        </StyledText>
      )}
    </>
  );
};
