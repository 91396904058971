import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextType, Text } from '@bit/first-scope.text';
import { CloseWhiteRoundButton } from '../../../../../../components/closeWhiteRoundButton/CloseWhiteRoundButton';
import { media } from '../../../../../../styles/media';
import { useMediaType } from '../../../../../../styles/style.context';
import { Select } from '../../../../../../components/select/Select';
import { CommonButton } from '../../../../../../components/commonButton/CommonButton';
import { InputModal } from '../../../../../../components/inputModal/InputModal';
import { Loader } from '../../../../../../components/loader/Loader';
import { CreatePackageModal } from './components/createPackageModal/CreatePackageModal';
import { getDeviceDetailsComponents } from '../../../../../../api/manageDevicesDetails';
import { useBlockBodyScroll } from '../../../../../../utils/useBlockBodyScroll';
import { CustomModal } from '../../../../../../components/customModal/CustomModal';
import { hexToRGBA, useTheme } from '@cthings.co/styles';
import { isClasp, isTablet } from '../../../../../../consts';
import { displayViewType } from '../../../../../../features/universalTable/components/modalSystem/ModalSystem';
import ls from '../../../../../../utils/ls';

const WrapperModal = styled(CustomModal)`
  & > div {
    width: auto;
  }
  & > div > div > div {
    overflow: unset;
  }

  ${media.tabletNavigation` 
    & > div {
     width: 100%;
    }
    & > div > div > div {
     overflow: auto;
    }
  `}

  &.tablet {
    align-items: start;
    justify-content: start;
    padding: 40px;
  }
`;

const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
`;

const StyledText = styled(Text)`
  font-size: 18px;
  margin-bottom: 14px;
  ${media.tablet`
    margin-bottom: 4px;
  `}
`;

const ItemsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  ${media.phone`
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  `}
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSelect = styled(Select)`
  width: 230px;
  margin: 0 0 12px 0;
  & > div {
    min-height: 40px;
  }
  ${media.tablet`
    width: 100%;   
  `};
`;

const CancelButton = styled(CommonButton)`
  width: 104px;
  height: 32px;
  margin: 10px 0 0 15px;
  ${media.tablet`
    width: 100%;
    margin: 4px 0;
  `}
`;

const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  ${media.tablet`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
    margin-top: 8px;
  `}
  ${media.phone`
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  `}
`;

const StyledInput = styled(InputModal)`
  width: 230px;
  ${media.tablet`
    width: 100%;
    margin-bottom: 4px;
  `}
`;

const ViewportSwitcher = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
`;

export enum RowItemType {
  SELECT = 'SELECT',
  ADDRESS = 'ADDRESS',
  CUSTOM = 'CUSTOM',
  INPUT = 'INPUT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  INPUT_PHONE_NUMBER = 'INPUT_PHONE_NUMBER',
  JOINED_SELECT = 'JOINED_SELECT',
}

export const AttachPackageModal = ({
  attachedToPackage,
  packageArr,
  setValidationIsStarted,
  handleAttachPackageAPI,
  isLoaderPackageModal,
  handleClosePackageModal,
  validationIsTarted,
  isAllFieldsValidated,
  data,
  languageStrings,
  componentIndex,
  setComponentIndex,
  createPackageProdId,
  setCreatePackageProdId,
  componentId,
  setComponentId,
  orderId,
}: {
  attachedToPackage: boolean;
  packageArr: any;
  setValidationIsStarted: any;
  handleAttachPackageAPI: any;
  isLoaderPackageModal: boolean;
  handleClosePackageModal: any;
  validationIsTarted: any;
  isAllFieldsValidated: any;
  data?: any;
  languageStrings?: any;
  componentIndex: string | null;
  setComponentIndex: (props: string) => void;
  createPackageProdId: string;
  setCreatePackageProdId: (val: string) => void;
  componentId: string;
  setComponentId: (val: string) => void;
  orderId: string;
}) => {
  const theme = useTheme();
  const { pureWhite, pureBlack, primary } = theme.colors;
  const prepBlack = hexToRGBA(pureBlack, 0.6);

  const mediaType = useMediaType();

  const [isOpenCreatePackageModal, setIsOpenCreatePackageModal] = useState(false);
  const [componentsData, setComponentsData] = useState<any>();
  const [newPackage, setNewPackage] = useState<any>();
  const [searchInputValue, setSearchInputValue] = useState<any>();
  const [selectedOrderId, setSelectedOrderId] = useState('');
  const isValmontTabletView = !isClasp && isTablet;
  const getDisplayView = ls.get('displayView');
  const [displayView, setDisplayView] = useState(getDisplayView || displayViewType.DESKTOP_VIEW);

  useBlockBodyScroll(attachedToPackage);

  const handleCreate = () => {
    setIsOpenCreatePackageModal(true);
  };

  const handleChangeComponentData = async (value: any) => {
    const componentsData = await getDeviceDetailsComponents(value.order_oid || value.id);
    setComponentsData(componentsData);
  };

  const handleAttachToPackage = () => {
    setValidationIsStarted(true);
    if (isAllFieldsValidated) {
      handleAttachPackageAPI();
      setValidationIsStarted(false);
    }
  };

  const changeDisplayViewHandle = useCallback(() => {
    if (displayView === displayViewType.DESKTOP_VIEW) {
      setDisplayView(displayViewType.TABLET_VIEW);
      ls.set('displayView', displayViewType.TABLET_VIEW);
    } else {
      setDisplayView(displayViewType.DESKTOP_VIEW);
      ls.set('displayView', displayViewType.DESKTOP_VIEW);
    }
  }, [displayView]);

  useEffect(() => {
    if (newPackage) {
      const { onChange, values } = packageArr[2];
      values.push(newPackage);
      onChange(newPackage);
    }
  }, [newPackage]);

  useEffect(() => {
    !!getDisplayView && setDisplayView(getDisplayView);
  }, [getDisplayView]);

  useEffect(() => {
    if (!isValmontTabletView) {
      ls.remove('displayView');
      setDisplayView(displayViewType.DESKTOP_VIEW);
    }
  }, [isValmontTabletView]);

  return (
    <WrapperModal isOpen={attachedToPackage} className={displayView === displayViewType.TABLET_VIEW ? 'tablet' : ''}>
      <WrapperTitle>
        <StyledText type={TextType.TITLE_PRIMARY}>{languageStrings.AttachedToPackageLabel}</StyledText>
        {mediaType.tablet && !isValmontTabletView && <CloseWhiteRoundButton onClick={handleClosePackageModal} />}
        {isValmontTabletView && (
          // @ts-ignore
          <ViewportSwitcher onClick={changeDisplayViewHandle} type={TextType.TEXT_14_GRAY} color={theme.colors.primary}>
            {displayView === displayViewType.DESKTOP_VIEW ? languageStrings.tabletView : languageStrings.desktopView}
          </ViewportSwitcher>
        )}
      </WrapperTitle>
      <ItemsWrapper>
        {packageArr.map((item: any, index: number) => {
          const {
            name,
            fieldName,
            value,
            disabled,
            type,
            onChange,
            resetHandle,
            values,
            placeholder,
            isError,
            isPendingRequest,
            isCreatePlaceholderSearchBar,
            isPackage,
            addButtonLabel,
          } = item;

          if (type === RowItemType.JOINED_SELECT) {
            if (!!isPackage) {
              return (
                <SelectWrapper>
                  <StyledSelect
                    value={value}
                    label={name}
                    disabled={disabled}
                    placeholder={placeholder}
                    values={values}
                    onChange={(value: any) => {
                      onChange(value, fieldName);
                    }}
                    isError={isError}
                    startedValidation={validationIsTarted}
                    isPendingRequest={isPendingRequest}
                    createItem={handleCreate}
                    setSearchValue={setSearchInputValue}
                    isCreatePlaceholderSearchBar={isCreatePlaceholderSearchBar}
                    addButtonLabel={addButtonLabel}
                    isCreateInDropDown
                  />
                </SelectWrapper>
              );
            } else {
              return (
                <SelectWrapper>
                  <StyledSelect
                    value={value}
                    label={name}
                    disabled={disabled}
                    placeholder={placeholder}
                    values={values}
                    onChange={(value: any) => {
                      const isValueEmpty = Object.values(value).includes('');
                      !isValueEmpty ? onChange(value, fieldName) : resetHandle();
                      !isValueEmpty && handleChangeComponentData(value);
                      setSelectedOrderId(value.order_oid || value.id);
                      setValidationIsStarted(false);
                    }}
                    isError={isError}
                    startedValidation={validationIsTarted}
                    isPendingRequest={isPendingRequest}
                    addButtonLabel={addButtonLabel}
                    isResetButton
                  />
                </SelectWrapper>
              );
            }
          } else if (type === RowItemType.INPUT || type === RowItemType.INPUT_NUMBER) {
            return (
              <StyledInput
                key={index}
                name={name}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                onChange={disabled ? () => {} : onChange}
                type={type === RowItemType.INPUT ? 'text' : 'number'}
                isError={isError}
                fromAttach
              />
            );
          } else {
            return null;
          }
        })}
      </ItemsWrapper>
      <WrapperButtons>
        <CancelButton
          onClick={handleClosePackageModal}
          backgroundColor={pureWhite}
          borderColor={primary}
          color={prepBlack}
        >
          {languageStrings ? languageStrings.cancelButton : 'Cancel'}
        </CancelButton>
        <CancelButton onClick={handleAttachToPackage} backgroundColor={primary} borderColor={primary}>
          {languageStrings ? languageStrings.attachButton : 'Attach'}
        </CancelButton>
      </WrapperButtons>
      <Loader isLoader={isLoaderPackageModal} />
      <CreatePackageModal
        componentIndex={componentIndex}
        isOpenModal={isOpenCreatePackageModal}
        setModalClose={setIsOpenCreatePackageModal}
        trackerData={data}
        orderId={orderId || selectedOrderId}
        componentsData={componentsData}
        setPackage={setNewPackage}
        setComponentIndex={setComponentIndex}
        createPackageProdId={createPackageProdId}
        setCreatePackageProdId={setCreatePackageProdId}
        componentId={componentId}
        setComponentId={setComponentId}
      />
    </WrapperModal>
  );
};
