/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for page number in pagination of universal table require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { ArrowButton } from '@bit/first-scope.arrow-button';
import { Text, TextType } from '@bit/first-scope.text';
import { ArrowButtonType } from '../../../../../components/arrowButton/ArrowButton';
import { media, mediaType } from '../../../../../styles/media';
import { GoToPagination } from '../goToPagination/GoToPagination';
import { useTheme } from '@cthings.co/styles';
import { borderRadiusFetch, colorFetch } from '../../../../../styles/utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

type StyledArrowButtonProps = {
  type: ArrowButtonType;
};

// @TODO fix media types
const StyledArrowButton = styled(ArrowButton)<StyledArrowButtonProps>`
  margin: ${({ type }) => (type === ArrowButtonType.LEFT ? '0 18px 0 0' : '0 0 0 18px')};
  ${media.phone`
    margin: ${({ type }: any) => (type === ArrowButtonType.LEFT ? '0 8px 0 0' : '0 0 0 8px')};
  `}
` as any; // @TODO fix type

const NumberingWrapper = styled.div`
  display: flex;
`;

type NumberWrapperProps = {
  isBigNumber?: boolean;
  editInProcess?: boolean;
};

const NumberWrapper = styled.div<NumberWrapperProps>`
  width: 24px;
  height: 24px;
  padding: ${({ isBigNumber }) => (isBigNumber ? '0 7px' : '0')};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: ${borderRadiusFetch('round')};
  margin: 0 11px;

  cursor: ${({ editInProcess }) => (editInProcess ? 'auto' : 'pointer')};
  user-select: none;

  &.bigNumber {
    padding: 0 7px;
    background-color: ${colorFetch('secondary4')};
    border-radius: 15px;
  }

  &:hover {
    background-color: ${colorFetch('secondary4')};
    border-radius: ${({ isBigNumber, theme }) => (isBigNumber ? '15px' : borderRadiusFetch('round')({ theme }))};
  }

  &:hover > span {
    color: ${colorFetch('primary')};
  }

  &.selected {
    background-color: ${colorFetch('secondary4')};
  }
  &.selected > span {
    color: ${colorFetch('primary')};
  }

  ${media.phone`
    margin: 0 4px;
  `}
`;

const DotsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 11px;
  ${media.phone`
    margin: 0 6px;
  `}
`;

export interface PaginationNumberingProps {
  pagesNum: number;
  currentPage: number;
  onPageChange: any;
  editInProcess?: boolean;
  className?: string;
}

export const PaginationNumbering: FC<PaginationNumberingProps> = ({
  pagesNum,
  onPageChange,
  currentPage,
  editInProcess,
  ...props
}) => {
  // @NOTE middleArraySize has to be ALWAYS an odd number for logic of pagination to work
  const middleArraySize = mediaType.phone ? 1 : 3;
  const distanceToArrayCenter = (middleArraySize - 1) / 2;
  const endDelta = pagesNum - currentPage - 1;
  const cutNegative = (num: number) => (num >= 0 ? num : 0);
  const size = pagesNum >= middleArraySize ? middleArraySize : pagesNum;
  const backOffset = cutNegative(distanceToArrayCenter - endDelta);
  const offset = 1 + cutNegative(currentPage - backOffset - distanceToArrayCenter);
  const middleArray = [...(Array(size) as any).keys()].map((key) => key + offset);
  const theme = useTheme();
  const arrOfNum = Array.from(Array(pagesNum).keys());

  const handleLeftArrowClick = (e: any) => {
    e.preventDefault();
    onPageChange(-1, true);
  };
  const handleRightArrowClick = (e: any) => {
    e.preventDefault();
    onPageChange(1, true);
  };

  const handleItemClick = (value: number) => {
    onPageChange(value);
  };

  const ifStartNumber = currentPage > distanceToArrayCenter;
  const ifStartDots = currentPage > distanceToArrayCenter + 1 && arrOfNum.length > 5;
  const ifEndNumber = endDelta > distanceToArrayCenter;
  const ifEndDots = endDelta > distanceToArrayCenter + 1 && arrOfNum.length > 5;

  const getLength = (number: number) => {
    return number.toString().length;
  };

  return (
    <Wrapper {...props}>
      {currentPage > 0 && (
        <StyledArrowButton
          // @TODO fix handleLeftArrowClick type
          onClick={handleLeftArrowClick as any}
          type={ArrowButtonType.LEFT}
        />
      )}

      <NumberingWrapper>
        {ifStartNumber && pagesNum !== 3 && (
          <NumberWrapper
            editInProcess={editInProcess}
            theme={theme}
            onClick={() => {
              handleItemClick(0);
            }}
            className={currentPage === 0 ? 'selected' : ''}
          >
            <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
              {'1'}
            </Text>
          </NumberWrapper>
        )}
        {ifStartDots && (
          <DotsWrapper>
            <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
              ...
            </Text>
          </DotsWrapper>
        )}
        {middleArray.map((item) => {
          const lengthNumber = getLength(item);

          const index = item - 1;
          return (
            <NumberWrapper
              editInProcess={editInProcess}
              onClick={() => {
                handleItemClick(index);
              }}
              key={index}
              className={
                lengthNumber > 3 && currentPage === index ? 'bigNumber' : currentPage === index ? 'selected' : ''
              }
              isBigNumber={lengthNumber > 3}
            >
              <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
                {item}
              </Text>
            </NumberWrapper>
          );
        })}
        {ifEndDots && (
          <DotsWrapper>
            <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
              ...
            </Text>
          </DotsWrapper>
        )}
        {ifEndNumber && pagesNum !== 3 && (
          <NumberWrapper
            editInProcess={editInProcess}
            onClick={() => {
              handleItemClick(pagesNum - 1);
            }}
            className={
              getLength(pagesNum) > 3 && currentPage === pagesNum - 1
                ? 'bigNumber'
                : currentPage === pagesNum - 1
                ? 'selected'
                : ''
            }
            isBigNumber={getLength(pagesNum) > 3}
          >
            <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
              {pagesNum}
            </Text>
          </NumberWrapper>
        )}
      </NumberingWrapper>

      {endDelta > 0 && (
        <StyledArrowButton
          // @TODO fix type
          onClick={handleRightArrowClick as any}
          type={ArrowButtonType.RIGHT}
        />
      )}
      <GoToPagination handleGoToPage={handleItemClick} pagesCount={pagesNum} />
    </Wrapper>
  );
};
