import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ManageOrderDetailsState } from './manageOrderDetailsInterfaces';

const initialState: ManageOrderDetailsState = {
  data: {
    test: 'test',
  },
  packageModalData: [],
};

export const manageOrderDetailsSlice = createSlice({
  name: 'orderDetails',
  initialState,
  reducers: {
    setOrderDetails: (state, action: PayloadAction<any>) => {
      state.data = action.payload.data;
    },
    setPackageModalComments: (state, action: PayloadAction<any>) => {
      state.packageModalData = action.payload;
    },
  },
});

export const { setOrderDetails, setPackageModalComments } = manageOrderDetailsSlice.actions;

export const selectOrderDetails = (state: RootState) => ({
  data: state.orderDetails.data,
});

export const selectPackageModalData = (state: RootState) => {
  return state.orderDetails.packageModalData;
};

export default manageOrderDetailsSlice.reducer;
