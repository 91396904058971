import React from 'react';
import styled from 'styled-components';
import { colorFetch } from '../../styles/utils';

const Hightlight = styled.span`
  color: ${colorFetch('primary')};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
`;

export const WordHightlight = ({ filter, str }: { filter: string; str: any }) => {
  const string = Array.isArray(str) ? str.join(' ') : str;

  if (!filter) return string;

  const newFilterData = filter.toLowerCase().split(' ');
  const matchValue = string.replace(
    new RegExp(`(${newFilterData.join('|')})`, 'gi'),
    (match: any) => '<mark>' + match + '</mark>',
  );
  const matchArr = matchValue.matchAll(/<mark>([\s\S]*?)<\/mark>/g);
  const boldStrings = Array.from(matchArr, (x: any) => x[1]);
  const normalStrings = matchValue.split(/<mark>([\s\S]*?)<\/mark>/).map((item: string) => item);
  const { length: boldLength } = boldStrings;

  const result =
    normalStrings.length > 1
      ? boldStrings.map((elem, i) => {
          return (
            <>
              {normalStrings[2 * i]}
              <Hightlight>{elem}</Hightlight>
              {i === boldLength - 1 && normalStrings[2 * i + 2]}
            </>
          );
        })
      : normalStrings[0];

  return result;
};
