import { API_URL } from '../consts';
import { HttpService } from '../utils/http';
import { isStringConsistOfSpaces } from '../utils/validation';

export const searchText = (text: string, setSmartSearchList: any, handleResponse: any) => {
  const url = `${API_URL}/v1/smartsearch/`;
  const isValidStr = !isStringConsistOfSpaces(text) && text.length > 0;
  (async () => {
    try {
      if (isValidStr) {
        handleResponse(true);
      }
      let responseData = text.length > 0 && (await HttpService.post(url, { text: text })).data;
      const { payload } = responseData;
      if (payload) {
        handleResponse(false);
      }
      setSmartSearchList(payload);
    } catch (e) {
      handleResponse(false);
    }
  })();
};
