/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings search select require
 * Highly customisable.
 *
 */

import React from 'react';
import styled from 'styled-components';
import { CloseButton } from '@bit/first-scope.close-button';
import { ReactComponent as SearchIcon } from '../../assets/Search.svg';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 14px 14px 8px;
  border-bottom: 1px solid !important;
  border-color: ${colorFetch('gray3')} !important;
  border-radius: ${borderRadiusFetch('primary')} ${borderRadiusFetch('primary')} 0 0;
  background-color: ${colorFetch('pureWhite')};
  & > svg > path {
    stroke: ${colorFetch('primary')};
  }
`;

const Input = styled.input`
  width: calc(100% - 36px);
  margin-left: 2px;
  border: none;
  padding: none;
  outline: none;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  font-weight: 400;
  color: ${colorFetch('gray1')};
  &::placeholder {
    color: ${colorFetch('gray2')};
  }
`;

export const SearchInputSelect = ({
  value,
  handleOnChange,
  handleClearInput,
  setIsEditing,
  languageStrings,
  isFocused,
  banAutoFocus,
  isCreatePlaceholderSearchBar,
}: {
  value: string;
  handleOnChange: (value: string) => void;
  handleClearInput: () => void;
  setIsEditing?: (e: any) => void;
  languageStrings: any;
  isFocused?: boolean;
  banAutoFocus?: boolean;
  isCreatePlaceholderSearchBar?: boolean;
}) => {
  const placeholder = languageStrings ? languageStrings.searchBarPlaceholder : 'Choose one...';
  const placeholderWithCreate = languageStrings.searchPlaceholder;

  return (
    <Wrapper
      onClick={(e: any) => {
        e.stopPropagation();
        setIsEditing && setIsEditing(null);
      }}
    >
      <SearchIcon />
      <Input
        value={value}
        onChange={handleOnChange as any /* @TODO fix type */}
        placeholder={isCreatePlaceholderSearchBar ? placeholderWithCreate : placeholder}
        autoFocus={banAutoFocus ? false : isFocused}
      />
      {value && <CloseButton onClick={handleClearInput} width={'16px'} height={'16px'} strokeWidth={'2px'} />}
    </Wrapper>
  );
};
