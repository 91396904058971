/**
 * @description
 * This is component the covered functionality for switch popupInfoModal for floorPlan desktop.
 *
 */

import React, { FC } from 'react';
import { Text, TextType } from '@bit/first-scope.text';
import styled from 'styled-components';
import { LocationCircle } from '../../styled';
import { SwipeSwitch } from '../../../../components/swipeSwitch/SwipeSwitch';
import { useTheme } from '@cthings.co/styles';

const WrapperSwitch = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WrapperSwitchBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WrapperLocationCircle = styled(LocationCircle)`
  margin: 0 28px 0 4px;
`;

export interface SwitchType {
  stateSwitch: boolean;
  handleStateSwitch: any;
  typeSwitch?: any;
  filterState?: any;
  switchName: any;
  circleColor: any;
  withBorder?: boolean;
  isCircle?: boolean;
  swipeDisabled?: boolean;
}

export const Switch: FC<SwitchType> = ({
  switchName,
  stateSwitch,
  handleStateSwitch,
  typeSwitch,
  filterState,
  circleColor,
  withBorder,
  isCircle,
  swipeDisabled,
}) => {
  const theme = useTheme();
  const { primary, gray3 } = theme.colors;

  const handleSwitch = () => {
    handleStateSwitch(typeSwitch, filterState);
  };

  return (
    <WrapperSwitch>
      <WrapperSwitchBlock>
        <Text type={TextType.TEXT_14_BLACK} color={theme.colors.gray1}>
          {switchName}
        </Text>
        {isCircle && (
          <WrapperLocationCircle
            backgroundColor={circleColor}
            staticDisplay
            withBorder={withBorder}
            onClick={() => {}}
          />
        )}
      </WrapperSwitchBlock>
      <WrapperSwitchBlock>
        <SwipeSwitch
          stateSwitch={stateSwitch}
          handleStateSwitch={handleSwitch}
          height={30}
          width={52}
          onColor={primary}
          offColor={gray3}
          disabled={swipeDisabled}
        />
      </WrapperSwitchBlock>
    </WrapperSwitch>
  );
};
