/**
 * @description
 * This is component that displays details about zone
 */

import React, { useCallback, useRef, useState } from 'react';
import { useEffect } from 'react';
import { API_URL, NEW_ID } from '../../../consts';
import { useSelector } from 'react-redux';
import { use100vh } from 'react-div-100vh';
import {
  ActionType,
  InlineActionType,
  RowItemType,
  TableCustomComponentState,
} from '../../../features/universalTable/types';
import { Text, TextType } from '@bit/first-scope.text';
import { View } from '../../../routes/routeInterfaces';
import { selectAccess, selectLanguageStrings } from '../../../app/state/userSlice';
import { SearchBarType } from '../../../enums/SearchBarType';
import { useFloorPlanDetails } from '../../../api/locations';
import { useMediaType, useWindowSize } from '../../../styles/style.context';
import { useNotifierFunctions } from '../../../features/notifier2';
import { CopyButton } from '../../../components/copyButton/CopyButton';
import { ReactComponent as ListIcon } from '../../../assets/list_button.svg';
import { deleteZoneGlobal, useGetStagesList } from '../../../api/manageZones';
import { CommonButton, ButtonIconType } from '@bit/first-scope.common-button';
import { useCustomHistory } from '../../../utils/react-router-dom-abstraction';
import { DetailsButton } from '../../../components/detailsButton/DetailsButton';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import { ZonesFloorplan } from '../../../features/zonesFloorplan/ZonesFloorplan';
import { LevelComponent } from '../zones/components/levelComponent/LevelComponent';
import { updateZone, useFloorZonesUpdate, useManageZonesDetails } from '../../../api/manageZonesDetails';
import { useTableFunctions, withTableContextProvider } from '../../../features/universalTable/context';
import { ModalView } from '../zones/components/descriptionPopup/components/modalView/ModalView';
import { SearchModal } from '../zones/components/descriptionPopup/components/modalView/searchModal/SearchModal';
import { ListOfZonesModal } from '../zones/components/descriptionPopup/components/modalView/listOfzonesModal/ListOfZonesModal';
import { ZoneNotificationType, ZonesActionAttempts } from './types';
import {
  AddressIcon,
  AddressWrapper,
  BackButton,
  ButtonsWrapper,
  CopyWrapper,
  Header,
  InputWrapper,
  ListButton,
  NameWrapper,
  RefWrapper,
  SearchBarWrapper,
  StyledSearchBar,
  StyledSelectLevel,
  Title,
  TitleWrapper,
  Wrapper,
  ZonesActionButton,
  ZonesActionButtonWrapper,
} from './styled';
import { useParams } from 'react-router-dom';
import { useTheme } from '@cthings.co/styles';

const ZonesDetailsPlain = () => {
  const theme = useTheme();
  const { gray3, pureWhite, black1, primary, secondary2 } = theme.colors;

  const [activeId, setActiveId] = useState<string | null>(null);
  const [searchIsShown, setSearchIsShown] = useState(false);
  const [imageSize, setImageSize] = useState({ height: 0, width: 0 });
  const [trigger, setTrigger] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [validatingIsStarted] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [savedZones, setSavedZones] = useState<any>(null);
  const [isUpdateZones, setIsUpdateZones] = useState(false);
  const [isEditingStart, setIsEditingStart] = useState(false);
  const [isOpenZonesModal, setIsOpenZonesModal] = useState(false);
  const [isSavingActive, setIsSavingActive] = useState(false);

  const [actionAttempts, setActionAttempts] = useState<ZonesActionAttempts>({
    delete: false,
    save: false,
    cancel: false,
  });

  const attemptDelete = useCallback(() => {
    setActionAttempts({ delete: true, save: false, cancel: false });
  }, []);

  const attemptSave = useCallback(() => {
    setActionAttempts({ delete: false, save: true, cancel: false });
  }, []);

  const attemptCancel = useCallback(() => {
    setActionAttempts({ delete: false, save: false, cancel: true });
  }, []);

  const clearAttempts = useCallback(() => {
    setActionAttempts({ delete: false, save: false, cancel: false });
  }, []);

  const pathParams = useParams();
  const mediaType = useMediaType();
  const customHistory = useCustomHistory();
  const buildingId: any = pathParams.building_id;
  const keyword = 'ZonesDetails';
  const offsetKey = 'offset';
  const offset = pathParams[offsetKey] || '0';
  const zoneId = pathParams['zone_id'] || '';
  const vh100 = use100vh();
  const fullHeight = (vh100 ?? 0) >= 750 || mediaType.phone ? vh100 : 750;

  const accessZonesData = useSelector(selectAccess).management.zones;
  const buildingData = useFloorPlanDetails(buildingId);
  const floorId = buildingData && buildingData.floors[0].floor_id;
  const floorObjectId = buildingData && buildingData.floors[0].floor_object_id;
  const tableRef = useRef<any>(null);
  const titleRef = useRef<any>(null);
  const searchInputRef = useRef<any>(null);
  const languageStrings = useSelector(selectLanguageStrings);

  const toggleTrigger = () => {
    setTrigger((val) => !val);
  };

  useGetStagesList();
  const { resetTable } = useTableFunctions();
  const { building_id, floor } = pathParams;
  const { addNotification } = useNotifierFunctions();

  const triggerNotification = useCallback(
    (type: ZoneNotificationType, message?: string) => {
      const mapping = {
        [ZoneNotificationType.SUCCESS]: () =>
          addNotification({
            message: languageStrings ? languageStrings.savedSuccessfullyNotification : 'Saved successfully',
            type: 'success',
          }),
        [ZoneNotificationType.ERROR_INTERSECTION]: () =>
          addNotification({
            message: message
              ? message
              : languageStrings
              ? languageStrings.zonesConflictNotification
              : 'Zones conflict with each other',
            type: 'error',
          }),
        [ZoneNotificationType.ERROR_OVERFLOW]: () =>
          addNotification({
            message: message
              ? message
              : languageStrings
              ? languageStrings.zonesOverflowNotification
              : 'Zones can be only placed on the live view map',
            type: 'error',
          }),
      };

      mapping[type]();
    },
    [addNotification, languageStrings],
  );

  const [scaledLocations, locations, setLocations] = useManageZonesDetails(
    floorId,
    () => {},
    () => {},
    imageSize.height,
    imageSize.width,
    activeId,
    isUpdateZones,
    setIsUpdateZones,
  );

  const intersectingZonesCount = scaledLocations?.filter((el: any) => el.isFaulty).length;

  const handleScrollToBottom = () => {
    if (windowWidth < 769) {
      setIsOpenZonesModal(true);
      if (activeData) {
        setActiveId('');
        customHistory.replace(View.MANAGE_ZONES_DETAILS, {
          pathParams: { building_id, floor, zone_id: 'noselection', offset },
        });
      }
    }
    tableRef && tableRef.current && tableRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleScrollToTop = (id: string) => {
    setActiveId(id);
    titleRef && titleRef.current && titleRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleOutsideHandler = useCallback(
    (e: any) => {
      if (
        searchIsShown &&
        !(searchInputRef && searchInputRef.current && (searchInputRef as any).current.contains(e.target))
      ) {
        setSearchIsShown(false);
      }
    },
    [searchIsShown],
  );

  const handleChange = (e: any) => {
    setSearchInputValue(e.target.value);
  };

  const handleResultOnClick = (elem: any) => {
    setActiveId(elem.id);
  };

  const handleCopyText = async (copiedText: string) => {
    await navigator.clipboard.writeText(copiedText);
    addNotification({
      message: languageStrings ? languageStrings.notificationCopieMessage : 'Copied to clipboard',
      type: 'info',
    });
  };

  const handleEdit = () => {
    setIsEditingStart(true);
    setSavedZones(scaledLocations);
    setIsEdit(true);
  };

  const activeDataIndex = scaledLocations.findIndex((el: any) => el.active);
  const activeData = activeDataIndex !== -1 ? scaledLocations[activeDataIndex] : null;

  const mutateZones = useFloorZonesUpdate(floorId, { triggerNotification });

  const applyCondition = intersectingZonesCount === 0;

  const handleApply = useCallback(() => {
    if (!applyCondition) {
      attemptSave();
    } else {
      const prepareLocations = (locations: any[]) => ({
        payload: locations.map(
          ({
            name,
            id,
            coordinates: {
              center: { x, y },
              height,
              width,
            },
            client_id,
            description,
          }: any) => {
            const idOptions = id.includes(NEW_ID) ? { floor_id: floorObjectId } : { id };
            return {
              name,
              coordinates: { x, y },
              dimensions: { x: width, y: height },
              client_id,
              description: description || '',
              ...idOptions,
            };
          },
        ),
      });

      setIsSavingActive(true);

      mutateZones(prepareLocations(scaledLocations), {
        onSuccess: () => {
          setSavedZones(scaledLocations);
          setTimeout(() => toggleTrigger(), 150);
          setIsEditingStart(false);
          setIsEdit(false);
          setIsSavingActive(false);
        },
        onError: () => {
          setIsSavingActive(false);
        },
      });
    }
  }, [attemptSave, applyCondition, mutateZones, scaledLocations]);

  const handleDeleteOnClick = () => {
    setLocations((values: any[]) => {
      const result =
        activeDataIndex !== -1
          ? [...values.slice(0, activeDataIndex), ...values.slice(activeDataIndex + 1, values.length)]
          : values;
      return result;
    });
  };

  const handleCloseEditView = () => {
    setLocations(savedZones);
    setIsEditingStart(false);
    setIsEdit(false);
  };

  const buttons = [
    {
      value: languageStrings ? languageStrings.saveButton : 'Save',
      onClick: handleApply,
      width: '105px',
      height: '40px',
      backgroundColor: isSavingActive ? gray3 : primary,
      backgroundColorHover: isSavingActive ? gray3 : secondary2,
      borderColor: isSavingActive ? gray3 : primary,
      borderColorHover: isSavingActive ? gray3 : secondary2,
      color: pureWhite,
      disabled: isSavingActive,
      isLoadingInProcess: isSavingActive,
    },
    {
      value: languageStrings ? languageStrings.cancelButton : 'Cancel',
      onClick: attemptCancel,
      width: '105px',
      height: '40px',
      backgroundColor: isSavingActive ? gray3 : pureWhite,
      backgroundColorHover: isSavingActive ? gray3 : primary,
      borderColor: isSavingActive ? gray3 : primary,
      borderColorHover: isSavingActive ? gray3 : primary,
      color: isSavingActive ? pureWhite : black1,
      disabled: isSavingActive,
      withMargin: true,
    },
  ];

  const windowWidth = useWindowSize()[0];

  useEffect(() => {
    if (windowWidth > 768) {
      setSearchIsShown(true);
    } else {
      setSearchIsShown(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    window.addEventListener('click', handleOutsideHandler);
    return () => {
      window.removeEventListener('click', handleOutsideHandler);
    };
  }, [handleOutsideHandler]);

  useEffect(() => {
    if (zoneId !== 'noselection') {
      setActiveId(zoneId);
    }
  }, [zoneId]);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  const triggerUpdateZonesFloorPlan = () => {
    setIsUpdateZones(true);
  };

  const deleteGlobalZones = (id: string[], deleteZone: any, deleteCheckedZones: any) => {
    deleteZoneGlobal(id, deleteZone, deleteCheckedZones, addNotification, triggerUpdateZonesFloorPlan, languageStrings);
  };

  const zoneAccessEditButton = accessZonesData.edit ? (
    <ZonesActionButtonWrapper>
      <ZonesActionButton onClick={handleEdit}>
        {languageStrings ? languageStrings.addEditButton : 'Add / Edit zones'}{' '}
      </ZonesActionButton>{' '}
    </ZonesActionButtonWrapper>
  ) : null;

  return (
    <Wrapper>
      <Header>
        <TitleWrapper ref={titleRef}>
          <BackButton
            width={'32px'}
            height={'32px'}
            iconType={ButtonIconType.ARROW_LEFT}
            iconWithoutText
            onClick={() => {
              customHistory.goBack();
            }}
          />
          <NameWrapper>
            <CopyWrapper
              onClick={() => {
                handleCopyText(buildingData ? buildingData.building_name : '');
              }}
              theme={theme}
            >
              <Title type={TextType.TITLE_PRIMARY} margin={'0 0 0 12px'}>
                {buildingData && buildingData.building_name}
              </Title>
              <CopyButton />
            </CopyWrapper>
          </NameWrapper>
          <AddressWrapper>
            <AddressIcon />
            <Text type={TextType.TEXT_14_GRAY}>
              {buildingData &&
                `${buildingData.address.city}, ${buildingData.address.line1} ${buildingData.address.line2}`}
            </Text>
          </AddressWrapper>
        </TitleWrapper>
        <SearchBarWrapper>
          <InputWrapper ref={searchInputRef}>
            <StyledSearchBar
              placeholderProps={{
                keyword: 'SearchZoneDetails',
                placeholderOptions: { withoutLoader: true },
                style: {
                  maxWidth: '535px',
                  width: windowWidth > 768 && '100%',
                  display: windowWidth > 769 && searchIsShown && 'flex',
                },
              }}
              type={SearchBarType.CLASSIC_VALMONT}
              countResultOfSearching={2}
              onChange={handleChange}
              onResult={handleResultOnClick}
              value={searchInputValue}
              results={
                scaledLocations &&
                scaledLocations
                  .filter((element: any) => element.name.includes(searchInputValue))
                  .map((finalElem: any, index: number) => ({
                    ...finalElem,
                    label: finalElem.name,
                    positioning_role_name: `${languageStrings ? languageStrings.labelLevel : 'Level'} ${
                      finalElem.location.floor.level
                    }`,
                    index,
                  }))
              }
              isZonesDetails
              searchIsShown={searchIsShown}
              isGlobalSearch={false}
            />
            <StyledSelectLevel
              currentLevel={currentLevel}
              setCurrentLevel={setCurrentLevel}
              countOfElementsOnTheFloor={
                scaledLocations
                  ? scaledLocations.filter(
                      (item: any) => item.location.floor.level === buildingData?.floors[0]?.floor_level,
                    ).length
                  : 'N/A'
              }
              countOfLevels={scaledLocations ? scaledLocations.length : 1}
              levelArr={buildingData ? buildingData.floors : []}
              nameOfElements={languageStrings ? languageStrings.labelZone : 'Zone'}
              isShown={!searchIsShown}
            />
            <ListButton onClick={handleScrollToBottom}>
              <ListIcon />
              {languageStrings ? languageStrings.listOfZonesButton : 'List of zones'}
            </ListButton>

            {windowWidth > 768 ? (
              isEditingStart ? (
                <ButtonsWrapper>
                  {buttons.map((item: any) => {
                    const {
                      value,
                      onClick,
                      width,
                      height,
                      backgroundColor,
                      backgroundColorHover,
                      color,
                      borderColorHover,
                      disabled,
                      isLoadingInProcess,
                      borderColor,
                    } = item;

                    return (
                      <CommonButton
                        onClick={onClick}
                        width={width}
                        height={height}
                        backgroundColor={backgroundColor}
                        backgroundColorHover={backgroundColorHover}
                        color={color}
                        borderColor={borderColor}
                        borderColorHover={borderColorHover}
                        disabled={disabled}
                        isLoadingInProcess={isLoadingInProcess}
                      >
                        {value}
                      </CommonButton>
                    );
                  })}
                </ButtonsWrapper>
              ) : (
                zoneAccessEditButton
              )
            ) : null}
          </InputWrapper>
        </SearchBarWrapper>
      </Header>
      <ZonesFloorplan
        image={{
          src: buildingData?.floors[0]?.floor_image_url,
          height: buildingData?.floors[0]?.floor_image_height,
          width: buildingData?.floors[0]?.floor_image_width,
        }}
        data={buildingData}
        setImageDimensions={setImageSize}
        id={buildingId}
        floorId={floorId}
        height={`${(fullHeight || 0) - 244}px`}
        pathParams={pathParams}
        scaledLocations={scaledLocations}
        toggleTrigger={toggleTrigger}
        locations={locations}
        setLocations={setLocations}
        triggerNotification={triggerNotification}
        activeDataIndex={activeDataIndex}
        activeData={activeData}
        setActiveId={setActiveId}
        activeId={activeId}
        isEdit={isEdit}
        accessData={accessZonesData}
        isEditingStart={isEditingStart}
        intersectingZonesCount={intersectingZonesCount}
        validatingIsStarted={validatingIsStarted}
        setSavedZones={setSavedZones}
        handleScrollToTop={handleScrollToTop}
        attemptDelete={attemptDelete}
      />
      <RefWrapper ref={tableRef}>
        <Text type={TextType.SEMITITLE} margin={'40px 0 14px 0'}>
          {languageStrings ? languageStrings.listOfZonesButton : 'List of zones'}
        </Text>
        <UniversalTable
          placeholderProps={{
            keyword,
            placeholderOptions: { borderRadiusTable: !mediaType.tablet },
          }}
          apiTriggers={[trigger]}
          mainApiUrl={floorId && `${API_URL}/v1/zones/floor/${floorId}`}
          pathParams={pathParams}
          keyword={keyword}
          offset={+offset}
          offsetKey={offsetKey}
          tablePath={View.MANAGE_ZONES_DETAILS}
          updateItems={updateZone}
          deleteItems={deleteGlobalZones}
          allowDelete={isEditingStart ? false : accessZonesData.delete}
          actionSet={[{ type: ActionType.EDIT }]}
          inlineActionSet={[
            {
              type: InlineActionType.CUSTOM,
              component: <DetailsButton onClick={() => {}} />,
              callback: (data: any) => {
                const { id } = data;
                setSavedZones(locations);
                handleScrollToTop(id);
              },
            },
          ]}
          accessData={accessZonesData}
          columnStructure={[
            {
              fieldName: 'name',
              placeholder: languageStrings ? languageStrings.enterNamePlaceholder : 'Enter Name',
              label: languageStrings ? languageStrings.labelName : 'Name',
              defaultValue: '',
            },
            {
              fieldName: 'location',
              placeholder: languageStrings ? languageStrings.chooseLevelPlaceholder : 'Choose level',
              label: languageStrings ? languageStrings.labelLevel : 'Level',
              defaultValue: [],
              type: RowItemType.CUSTOM,
              component: (value: any, state?: TableCustomComponentState, setValue?: any) => {
                return <LevelComponent value={value} state={state} setValue={setValue} />;
              },
            },
            {
              fieldName: 'description',
              placeholder: languageStrings ? languageStrings.enterDescriptionPlaceholder : 'Enter description',
              label: languageStrings ? languageStrings.labelDescription : 'Description',
              defaultValue: '',
              modalColumns: 2,
              rows: 4,
            },
          ]}
        />
      </RefWrapper>
      <ModalView
        languageStrings={languageStrings}
        handleDeleteOnClick={handleDeleteOnClick}
        attempts={actionAttempts}
        clearAttempts={clearAttempts}
        handleCloseEdit={handleCloseEditView}
        intersectingZonesCount={intersectingZonesCount}
      />

      {windowWidth < 769 && zoneId === 'noselection' && (
        <SearchModal locations={scaledLocations} setActiveId={setActiveId} />
      )}
      {isOpenZonesModal && (
        <ListOfZonesModal
          locations={scaledLocations}
          isOpenModal={isOpenZonesModal}
          setIsOpenModal={setIsOpenZonesModal}
          closeModal={() => setIsOpenZonesModal(false)}
          activeData={activeData}
          closeZoneModal={() => {
            setActiveId('');
            customHistory.replace(View.MANAGE_ZONES_DETAILS, {
              pathParams: { building_id, floor, zone_id: 'noselection', offset },
            });
          }}
          setSavedZones={setSavedZones}
          handleScrollToTop={handleScrollToTop}
          setActiveId={setActiveId}
        />
      )}
    </Wrapper>
  );
};

export const ZonesDetails = withTableContextProvider(ZonesDetailsPlain);
