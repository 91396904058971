import { useEffect, useState } from 'react';

import { API_URL, NEW_ID } from '../consts';
import { useLoaderOptions } from '../features/placeholderComponent/loaderFunctions';
import { ErrorEventType } from '../types/error';
import { HttpService } from '../utils/http';
import { handleUncoveredNotification } from '../utils/handleUncoveredNotification';

export const useStagesList = (setPlaceholder: any, stopPlaceholder: any) => {
  const [stages, setStages] = useState([]);
  const trigger = [] as const;
  /* eslint-disable react-hooks/exhaustive-deps */
  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);
  useEffect(() => {
    const url = `${API_URL}/v1/stages/?limit=0`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url, loaderOptions)).data;
        setStages(responseData.payload);
      } catch (e) {
        //
      }
    })();
  }, [...trigger, loaderOptions]);

  return [stages];
};

export const updateProcess = (
  id: string,
  data: any,
  index: number,
  updateLocalItem: any,
  handleAddNotification: Function,
  language: any,
  setBlockButtons: (status: boolean) => void,
) => {
  if (id !== NEW_ID) {
    const url = `${API_URL}/v1/processes/${id}`;
    setBlockButtons(true);
    (async () => {
      try {
        let responseData = (await HttpService.patch(url, data)).data;
        updateLocalItem(index, responseData.payload);
        handleAddNotification({
          message: language ? language.savedSuccessfullyNotification : 'Saved successfully',
          type: 'success',
        });
        setBlockButtons(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleUncoveredNotification(error, handleAddNotification, language);
        setBlockButtons(false);
      }
    })();
  } else {
    const url = `${API_URL}/v1/processes/`;
    setBlockButtons(true);
    (async () => {
      try {
        let responseData = (await HttpService.post(url, data)).data;
        const { payload } = responseData;
        const { id, ...responseItems } = payload;
        updateLocalItem(id, responseItems);
        handleAddNotification({
          message: language ? language.savedSuccessfullyNotification : 'Saved successfully',
          type: 'success',
        });
        setBlockButtons(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleUncoveredNotification(error, handleAddNotification, language);
        setBlockButtons(false);
      }
    })();
  }
};

export const deleteProcessesGlobal = (
  id: string[],
  deleteProcess: any,
  deteleCheckedProcesses: any,
  handleAddNotification: Function,
  language: any,
) => {
  const data = { process_ids: id };
  const url = `${API_URL}/v1/processes/delete`;
  const deleteOneProcess = () => deleteProcess(id[0]);

  (async () => {
    try {
      await HttpService.delete(url, data);
      if (id.length === 1) {
        deleteOneProcess();
      } else {
        deteleCheckedProcesses();
      }
      handleAddNotification({
        message: language ? language.deletedSuccessfullyNotification : 'Deleted successfully',
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      handleUncoveredNotification(error, handleAddNotification, language);
    }
  })();
};
