import styled from 'styled-components';
import { CommonButton } from '../../../../../../../../../components/commonButton/CommonButton';
import { ModalContainer } from '../../../../../../../../../components/newModals/ModalContainer';
import { media } from '../../../../../../../../../styles/media';
import { colorFetch } from '../../../../../../../../../styles/utils';

export const StyledModalContainer = styled(ModalContainer)`
  width: 384px;
  padding: 24px;
  align-items: flex-start;
  ${media.phone`
    width: 100%;
    border-radius: 8px 8px 0 0;
    padding: 24px 16px 30px;
`}
`;

export const Title = styled.div`
  align-self: flex-start;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colorFetch('black1')};
`;

export const Text = styled.div`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('gray1')};
  margin: 13px 0 33px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  ${media.phone`
    flex-direction: column-reverse;
  `}
`;

export const NoButton = styled(CommonButton)`
  height: 32px;
  width: 116px;
  ${media.phone`
    height: 40px;
    width: 100%;
  `}
`;

export const YesButton = styled(CommonButton)`
  height: 32px;
  width: 116px;
  margin: 0 0 0 16px;
  ${media.phone`
    height: 40px;
    width: 100%;
    margin: 0 0 10px;
  `}
`;
