/**
 * @description
 * This is component for visualisation one point in clusterEntity without functionality .
 *
 */

import { useTheme } from '@cthings.co/styles';
import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { LocationCircle, LocationCircleCluster, LocationMask, Sector, SectorText, SectorWrapper } from '../../styled';

export interface LocationPointProps {
  options: any;
  top: number;
  left: number;
  scale: number;
  onClick: any;
  isCluster: boolean;
  count: number;
  zoomIn: any;
  withPulsation?: boolean;
}

export enum OptionKey {
  NODE = 'hasNodes',
  TRACKER = 'hasTrackers',
  OFFLINE = 'hasOffline',
}

const pulsation = keyframes`
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform: scale(1.1, 1.1);
  }
`;

type PulsationAnimationProps = {
  isCluster?: boolean;
  isTracker?: boolean;
  baseColor: string;
};

const PulsationAnimation = styled.div<PulsationAnimationProps>`
  display: 'block';
  top: ${({ isCluster, isTracker }) => (isCluster || isTracker ? '-7px' : '-11px')};
  left: ${({ isCluster, isTracker }) => (isCluster || isTracker ? '-7px' : '-11px')};
  border: 4px solid;
  pointer-events: none;
  border-color: ${({ baseColor }) => baseColor};
  border-radius: 50%;
  height: ${({ isCluster }) => (isCluster ? '32px' : '22px')};
  width: ${({ isCluster }) => (isCluster ? '32px' : '22px')};
  position: absolute;
  z-index: ${({ isCluster }) => isCluster && -1};
  animation: ${pulsation} 1.3s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
`;

const getBaseColor = (options: any, count: number, colors: any) => {
  let color: string = colors.primary;
  if (count === 1) {
    Object.keys(options).forEach((optionKey: string) => {
      if (options[optionKey]) {
        switch (optionKey) {
          case OptionKey.TRACKER:
            color = colors.orange2;
            break;
          case OptionKey.OFFLINE:
            color = colors.gray2;
        }
      }
    });
  }
  return color;
};

export const LocationPoint: FC<LocationPointProps> = ({
  options,
  top,
  left,
  scale,
  onClick,
  isCluster,
  count,
  zoomIn,
  withPulsation = false,
}) => {
  const theme = useTheme();
  const sectorCount: number = Object.keys(options).reduce((acc: number, el: string) => acc + options[el], 0);
  const baseColor: string = getBaseColor(options, sectorCount, theme.colors);
  const withBorder = isCluster || options.hasTrackers;
  const doubleSector = sectorCount === 2;
  const handleZoom = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    zoomIn && zoomIn(left, top);
  };

  return (
    <>
      {isCluster ? (
        <LocationCircleCluster
          backgroundColor={baseColor}
          scale={scale}
          top={top}
          left={left}
          onClick={handleZoom}
          onTouchStart={handleZoom}
        >
          <SectorWrapper>
            {options.hasOffline && <Sector left doubleSector={doubleSector} />}
            {options.hasTrackers && <Sector doubleSector={doubleSector} />}
            <SectorText>{count}</SectorText>
          </SectorWrapper>
          {withPulsation && <PulsationAnimation baseColor={baseColor} isCluster />}
        </LocationCircleCluster>
      ) : (
        <LocationCircle
          backgroundColor={baseColor}
          scale={scale}
          top={top}
          withBorder={withBorder}
          left={left}
          onClick={onClick}
          onTouchStart={onClick}
        >
          {withPulsation && (
            <PulsationAnimation isTracker={options.hasTrackers && sectorCount === 1} baseColor={baseColor} />
          )}
        </LocationCircle>
      )}
      {withBorder && <LocationMask scale={scale} top={top} left={left} isCluster={isCluster} />}
    </>
  );
};
