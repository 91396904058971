import React, { useState } from 'react';
import { ChooseParameter } from '../../../../../../../components/chooseParameter/ChooseParameter';
import { useDispatch } from 'react-redux';
import { toggleIsSelected } from '../../../../../../../app/state/manageStagesSlice';

export const StagesFunctional = ({
  stagesList,
  placeholder,
  value,
  setValue,
  isDisabled,
  isPopupDescription,
}: {
  stagesList: any;
  placeholder: string;
  value: any;
  setValue?: any;
  isDisabled?: boolean;
  isPopupDescription?: boolean;
}) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const dispatch = useDispatch();

  const handleCheckedItem = (id: string) => {
    dispatch(toggleIsSelected({ id }));
  };
  const handleRemoveSelectedItem = (item: any) => {
    const index = stagesList.indexOf(item);
    const id = stagesList[index].id;
    dispatch(toggleIsSelected({ id }));
  };
  const handlePopupOpenClose = (value: boolean) => {
    setIsOpenPopup(value);
  };

  const preparingArr =
    value && value.length > 0
      ? value.map((item: any) => {
          const index = stagesList && stagesList.findIndex((el: any) => el.id === item.id);
          const preparingArrItem =
            stagesList &&
            stagesList.map((item: any, i: number) => {
              if (i === index) {
                return { ...item, isSelected: true };
              } else {
                return item;
              }
            });
          return preparingArrItem;
        })
      : [];
  const selectedParameters =
    value && value.length > 0 ? preparingArr[0].filter((item: any) => item.isSelected === true) : [];

  return (
    <ChooseParameter
      placeholder={placeholder}
      parameters={stagesList}
      selectedParameters={selectedParameters}
      handleCheckedItem={handleCheckedItem}
      handleRemoveSelectedItem={handleRemoveSelectedItem}
      setIsOpenSelectList={handlePopupOpenClose}
      isOpenSelectList={isOpenPopup}
      isDisabled={isDisabled}
      isPopupDescription={isPopupDescription}
    />
  );
};
