import React, { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { SearchInput } from '../searchInput/SearchInput';
import { debounce } from '../../../../../../../utils/throttling';
import { CheckBox } from '../checkbox/Checkbox';
import { borderRadiusFetch, colorFetch, shadowFetch } from '../../../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

type WrapperProps = {
  fieldHeight: number;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  box-sizing: border-box;
  background-color: ${colorFetch('pureWhite')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  box-shadow: ${shadowFetch('additionalShadow3')};
  border-radius: ${borderRadiusFetch('primary')};
  position: absolute;
  top: ${({ title, fieldHeight }) => (title ? `${fieldHeight + 6}px` : `${fieldHeight + 6}px`)};
  left: 0;
  z-index: 1;
  cursor: auto;
`;

const ParameterListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 6px 4px 6px 14px;
`;

type ParameterListProps = {
  heightOfList: number;
};

const ParameterList = styled.ul<ParameterListProps>`
  width: 100%;
  max-height: 210px;
  margin: 0;
  padding: 0;
  overflow-y: ${({ heightOfList }) => (heightOfList > 220 ? 'scroll' : 'auto')};
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
    background: ${colorFetch('gray4')};
    opacity: 1;
    border-radius: ${borderRadiusFetch('primary')};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray2')};
    border-radius: ${borderRadiusFetch('primary')};
    position: relative;
    top: 4px;
    right: 4px;
  }
`;

const ParameterItem = styled.li`
  display: flex;
  grid-gap: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  &:hover > span {
    color: ${colorFetch('primary')};
  }
  & > div {
    display: flex;
    align-items: center;
  }
  &:last-child {
    margin-bottom: 13px;
  }
`;

const CheckboxWrapper = styled.div`
  width: max-content;
  display: flex;
`;

const CheckboxSemiWrapper = styled.div`
  width: max-content;
  box-sizing: border-box;
  padding: 2px;
`;

export const SelectListPopup = ({
  popupRef,
  parameterList,
  handleCheckedItem,
  fieldHeight,
  languageStrings,
  selectedParameters,
  title,
}: {
  popupRef: React.MutableRefObject<any>;
  parameterList: any[];
  handleCheckedItem: any;
  handleSelectAll: () => void;
  fieldHeight: number;
  wrapperRef: any;
  scrollTo: number;
  scrollToWithCustomPeriod: number;
  isSelectAll?: boolean;
  isOpenSelectList?: boolean;
  handleTransformName?: (name: any) => string;
  languageStrings?: any;
  selectedParameters?: any;
  title?: string;
}) => {
  const theme = useTheme();
  const listRef = useRef<any>(null);
  const { gray1, gray2, primary } = theme.colors;
  const searchTimerId = useRef(null as any);
  const [actualSearch, setActualSearch] = useState('');
  const [slowSearch, setSlowSearch] = useState('');
  const [selectToggle, setSelectToggle] = useState(false);
  const [checkboxNode, setCheckboxNode] = useState<HTMLDivElement | null>(null);

  const checkboxRef = useCallback((node) => {
    if (node !== null) {
      setCheckboxNode(node);
    }
  }, []);

  const toggleSelectCheckbox = () => setSelectToggle((toggle) => !toggle);

  const handleSearchChangeValue = (e: FormEvent<HTMLInputElement>) => {
    const searchValue = e.currentTarget.value;
    setActualSearch(searchValue);
    searchTimerId.current && clearTimeout(searchTimerId.current);
    searchTimerId.current = setTimeout(() => {
      if (searchValue.length > 0 || searchValue === '') {
        setSlowSearch(searchValue);
      }
    }, 700);
  };

  const [heightOfList, setHeightOfList] = useState(0);
  const [pages, setPages] = useState(1);

  const observer = new IntersectionObserver((entries) => {
    const entry = entries[0];
    if (entry?.isIntersecting) {
      debounce(() => setPages((current) => current + 1), 700)();
    }
  });

  useEffect(() => {
    if (listRef.current && observer) {
      observer?.observe && listRef?.current?.lastElementChild && observer?.observe(listRef?.current?.lastElementChild);
    }
  }, [listRef.current, observer]);

  const handleClearInput = () => {
    setActualSearch('');
    setSlowSearch('');
  };

  const filteredParameterList = useMemo(() => {
    return parameterList.filter((item: any) => item.name.toLowerCase().includes(slowSearch.toLowerCase()));
  }, [parameterList.length, slowSearch, selectToggle]);

  const clearEvents = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSelect = useCallback(
    (id: string) => {
      handleCheckedItem(id);
      toggleSelectCheckbox();
    },
    [handleCheckedItem],
  );

  const handleCheckboxToggle = (e: FormEvent<HTMLElement>, id: string) => {
    clearEvents(e);
    handleSelect(id);
  };

  useEffect(() => {
    listRef && listRef.current && setHeightOfList(listRef.current.clientHeight);
  }, []);

  useEffect(() => {
    if (checkboxNode) {
      const pseudoFunc = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
      };
      checkboxNode.addEventListener('touchstart', pseudoFunc, { passive: false });
      checkboxNode.addEventListener('touchend', pseudoFunc, { passive: false });
      return () => {
        checkboxNode.removeEventListener('touchstart', pseudoFunc);
        checkboxNode.removeEventListener('touchend', pseudoFunc);
      };
    }
  }, [checkboxNode]);

  return (
    <Wrapper
      theme={theme}
      fieldHeight={fieldHeight}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      title={title}
    >
      <SearchInput
        value={actualSearch}
        handleOnChange={handleSearchChangeValue}
        handleClearInput={handleClearInput}
        languageStrings={languageStrings}
      />
      <ParameterListWrapper ref={popupRef}>
        {parameterList.length > 0 ? (
          <ParameterList ref={listRef} heightOfList={heightOfList}>
            {filteredParameterList.length ? (
              filteredParameterList.slice(0, 30 * pages).map((item: any) => {
                const { name, isSelected, id } = item;
                return (
                  <ParameterItem key={id} onClick={() => handleSelect(id)}>
                    <CheckboxWrapper ref={checkboxRef}>
                      <CheckboxSemiWrapper>
                        <CheckBox
                          value={isSelected}
                          disabled={false}
                          onChange={(e: any) => handleCheckboxToggle(e, id)}
                        />
                      </CheckboxSemiWrapper>
                    </CheckboxWrapper>
                    <Text type={TextType.TEXT_14_GRAY} color={isSelected ? primary : gray1}>
                      {name}
                    </Text>
                  </ParameterItem>
                );
              })
            ) : (
              <>
                <Text type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'}>
                  {languageStrings.noItemsText || 'No items'}
                </Text>
              </>
            )}
          </ParameterList>
        ) : (
          <>
            <Text type={TextType.TEXT_14_GRAY} color={gray2} weight={'400'}>
              {languageStrings.placeholderNoData || 'No data'}
            </Text>
          </>
        )}
      </ParameterListWrapper>
    </Wrapper>
  );
};
