/**
 * @description
 * This is component for a button that copies the content
 * all the functionalities cthings require for CopyButton
 * Highly customisable.
 *
 */

import { useTheme } from '@cthings.co/styles';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CopyIcon } from '../../assets/CopyIcon.svg';

const Icon = styled(CopyIcon)`
  margin-left: 6px;
  transition: all 0.3s linear;
`;

export const CopyButton = ({ ...props }: { className?: string }) => {
  const theme = useTheme();

  return <Icon theme={theme} {...props} />;
};
