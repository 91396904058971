import { useEffect, useState } from 'react';
import { NEW_ID } from '../consts';

import { API_URL } from '../consts';
import { ErrorEventType } from '../types/error';
import { HttpService } from '../utils/http';
import { handleUncoveredNotification } from '../utils/handleUncoveredNotification';

export const deleteUserGlobal = (
  id: string[],
  deleteUser: any,
  deleteCheckedUsers: any,
  handleAddNotification: Function,
  language: any,
) => {
  const data = { user_ids: id };
  const url = `${API_URL}/v1/users/delete`;
  const deleteOneUser = () => deleteUser(id[0]);
  (async () => {
    try {
      await HttpService.post(url, data);
      if (id.length === 1) {
        deleteOneUser();
      } else {
        deleteCheckedUsers();
      }
      handleAddNotification({
        message: language ? language.deletedSuccessfullyNotification : 'Deleted successfully',
        type: 'success',
      });
    } catch (e) {
      const error = e as ErrorEventType;
      handleUncoveredNotification(error, handleAddNotification, language);
    }
  })();
};

export const useGetClients = () => {
  const [localClients, setLocalClients] = useState();
  useEffect(() => {
    const url = `${API_URL}/v1/clients/`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url)).data;
        const { payload } = responseData;
        const result = payload.map((item: any) => ({ name: item.name, id: item.id }));
        setLocalClients(result);
      } catch (e) {
        //
      }
    })();
  }, []);
  return localClients;
};

export const updateUsers = (
  id: string,
  data: {
    name: string;
    email: string;
    phone: string;
    client_id: string;
    role: number;
  },
  index: number,
  updateLocalItem: any,
  handleAddNotification: Function,
  language: any,
  setBlockButtons: (status: boolean) => void,
) => {
  if (id !== NEW_ID) {
    setBlockButtons(true);
    const url = `${API_URL}/v1/users/${id}`;
    (async () => {
      try {
        let responseData = (await HttpService.patch(url, data)).data;
        updateLocalItem(index, responseData.payload);
        handleAddNotification({
          message: language ? language.savedSuccessfullyNotification : 'Saved successfully',
          type: 'success',
        });
        setBlockButtons(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleUncoveredNotification(error, handleAddNotification, language);
        setBlockButtons(false);
      }
    })();
  } else {
    const url = `${API_URL}/v1/users/`;
    setBlockButtons(true);
    (async () => {
      try {
        let responseData = (await HttpService.post(url, data)).data;
        responseData.payload.role_name = responseData.payload.role_name === '1' ? 'ADMIN' : 'USER';
        updateLocalItem(responseData.payload.id, responseData.payload);
        handleAddNotification({
          message: language ? language.savedSuccessfullyNotification : 'Saved successfully',
          type: 'success',
        });
        setBlockButtons(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleUncoveredNotification(error, handleAddNotification, language);
        setBlockButtons(false);
      }
    })();
  }
};
