import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton, ButtonIconType } from '@bit/first-scope.common-button';
import { media } from '../../../../styles/media';
import { ManagementSearchInput } from '../../../../components/managementSearchInput/ManagementSearchInput';
import { SwipeSwitch } from '../../../../components/swipeSwitch/SwipeSwitch';
import { useWindowSize } from '../../../../styles/style.context';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/userSlice';
import { colorFetch } from '../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-bottom: 25px;
  ${media.tablet`
    display: none;
  `}
`;

const TitleWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
`;

const Title = styled(Text)`
  line-height: 38px;
`;

const ButtonValue = styled(Text)`
  margin-left: 7px;
  ${media.tablet`
    display: none;
  `}
`;

const SearchInputWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > button {
    display: none;
    ${media.tablet`
      display: flex;
    `}
  }
  ${media.tablet`
    padding: 0 16px;
  `}
`;

const SearchInput = styled(ManagementSearchInput)`
  max-width: 535px;
  width: 100%;
  ${media.tablet`
    width: 100%;
    height: 32px;
    & > input {
      border-color: ${colorFetch('pureWhite')};
    }
  `}
`;

const SwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

const SwitcherText = styled(Text)`
  width: max-content;
`;

const Switcher = styled(SwipeSwitch)`
  transform: rotate(180deg);
`;

export const ManageTitle = ({
  keyword,
  title,
  searchInputValue,
  handleChangeSearchInputValue,
  addButtonOnClick,
  addButtonValue,
  switcherState,
  setSwitcherState,
  accessData,
}: {
  keyword: string;
  title: string;
  searchInputValue: string;
  handleChangeSearchInputValue: (value: string) => void;
  addButtonValue?: string;
  addButtonOnClick?: () => void;
  switcherState?: boolean;
  setSwitcherState?: React.Dispatch<React.SetStateAction<boolean>>;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
}) => {
  const theme = useTheme();
  const windowWidth = useWindowSize()[0];
  const { pureWhite, black1, primary } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <Wrapper>
      <TitleWrapper>
        <Title type={TextType.TITLE_H1}>{title}</Title>
        {addButtonValue && addButtonOnClick && accessData?.edit && (
          <CommonButton
            onClick={() => addButtonOnClick()}
            width={windowWidth > 0 ? (windowWidth > 768 ? '126px' : '32px') : '126px'}
            iconType={ButtonIconType.ADD}
            iconWithoutText
          >
            <ButtonValue type={TextType.TEXT_12_BLACK} color={pureWhite}>
              {addButtonValue}
            </ButtonValue>
          </CommonButton>
        )}
      </TitleWrapper>
      <SearchInputWrapper>
        <SearchInput
          value={searchInputValue}
          handleOnChange={handleChangeSearchInputValue}
          placeholder={languageStrings ? languageStrings.whatAreYouLookingForPlaceholder : 'What are you looking for?'}
          placeholderProps={{
            keyword: keyword,
            style: { width: addButtonValue && addButtonOnClick ? 'calc(100% - 40px)' : '100%', maxWidth: '536px' },
            placeholderOptions: { withoutLoader: true },
          }}
        />
        {setSwitcherState && (
          <SwitcherWrapper>
            <SwitcherText
              type={TextType.TEXT_14_BLACK}
              color={black1}
              weight={switcherState ? '500' : '400'}
              margin={'0 10px 0 0'}
            >
              {languageStrings ? languageStrings.mapViewButton : 'Map view'}
            </SwitcherText>
            <Switcher
              stateSwitch={switcherState ? !switcherState : true}
              handleStateSwitch={() => {
                setSwitcherState(!switcherState);
              }}
              height={20}
              width={35}
              onColor={primary}
              offColor={primary}
            />
            <SwitcherText
              type={TextType.TEXT_14_BLACK}
              color={black1}
              margin={'0 0 0 10px'}
              weight={switcherState ? '400' : '500'}
            >
              {languageStrings ? languageStrings.listViewButton : 'List view'}
            </SwitcherText>
          </SwitcherWrapper>
        )}
      </SearchInputWrapper>
    </Wrapper>
  );
};
