import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = {
  list: [],
  pagination: {
    total_items: 0,
    total_pages: 1,
  },
  manualModal: false,
};

export const usersTableSlice = createSlice({
  name: 'orderTable',
  initialState,
  reducers: {
    setOrdersTable: (state, action: PayloadAction<any>) => {
      state.list = action.payload.list;
      state.pagination = action.payload.pagination;
    },
    setManualModal: (state, action: PayloadAction<any>) => {
      state.manualModal = action.payload;
    },
  },
});

export const { setManualModal, setOrdersTable } = usersTableSlice.actions;

export const selectOrdersTable = (state: RootState) => ({
  list: state.orders.list,
  pagination: state.orders.pagination,
});

export const selectManualModal = (state: RootState) => ({
  isOpenManualModal: state.orders.manualModal,
});

export default usersTableSlice.reducer;
