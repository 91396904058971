import React from 'react';

export const AddIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99998 3.33301L8.00038 7.99927L12.6666 7.99968H3.33331L8.00017 7.99987L7.99998 12.6663V3.33301Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
