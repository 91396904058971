/**
 * @description
 * This is component for entity filtering floor Plan mobile version.
 *
 */

import React from 'react';
import styled from 'styled-components';
import { SelectWithModal } from '../../../../../../components/selectWithModal/SelectWithModal';
import { useMobileFilters } from '../../../../../../containers/common/campusMapInsight/Content/mobileFilterContext/mobileContext';
import { getIsNodes } from '../../../../../../containers/common/campusMapInsight/Content/filterContext/selectors';
import { getIsAllEntitySelect } from '../../../../../../containers/common/campusMapInsight/Content/mobileFilterContext/selectorMobile';

const WrapperSelectWithModal = styled(SelectWithModal)`
  margin-top: 20px;
  padding: 20px;
`;

export const EntityFilterMobile = ({ languageStrings }: any) => {
  const { stateMobile, dispatchMobile } = useMobileFilters();
  const { entityFilter } = stateMobile?.secondModal?.secondModalEntityState;
  const { checkedEntityCount } = stateMobile?.firstModal?.firstModalEntityState;

  const isSelectAllEntitySecondModal = getIsAllEntitySelect(
    stateMobile?.secondModal?.secondModalEntityState?.entityFilter,
  );

  const isSelectAllEntityFirstModal = getIsAllEntitySelect(
    stateMobile?.firstModal?.firstModalEntityState?.entityFilter,
  );

  const isNodes = getIsNodes(stateMobile);

  const handleSelectAllEntity = () =>
    dispatchMobile({ type: 'SELECT_ALL_ENTITY', payload: isSelectAllEntitySecondModal });

  const handleSelectOneEntity = (type: string) =>
    dispatchMobile({ type: 'SELECT_ONE_ENTITY', payload: { typeEntity: type } });

  const handleClearAllEntity = () => dispatchMobile({ type: 'SELECT_CLEAR_ALL_ENTITY' });

  const handleDone = () => dispatchMobile({ type: 'HANDLE_DONE_ENTITY_MODAL' });

  const handleCloseModal = () => dispatchMobile({ type: 'HANDLE_CLOSE_ENTITY_MODAL' });

  const entityParamState = Object.keys(entityFilter).map((key: any) => entityFilter[key]);

  return (
    <WrapperSelectWithModal
      maxWidth={'100%'}
      minHeight={'40px'}
      name={languageStrings ? languageStrings.labelObjectType : 'Object type'}
      fontSizeCount={'14px'}
      parameterList={entityParamState}
      handleCheckedItem={handleSelectOneEntity}
      handleSelectAll={handleSelectAllEntity}
      isCheckedAll={isSelectAllEntitySecondModal}
      isSelectAllFirstModal={isSelectAllEntityFirstModal}
      checkedCount={checkedEntityCount}
      handleClearAll={handleClearAllEntity}
      isDisabled={!isNodes}
      closeModal={handleCloseModal}
      handleDone={handleDone}
    />
  );
};
