/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings pagination numbering pages of tables require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { media, mediaType } from '../../../styles/media';
import { ArrowButton } from '../../arrowButton/ArrowButton';
import { Text, TextType } from '@bit/first-scope.text';
import { ArrowButtonType } from '../../../components/arrowButton/ArrowButton';
import { borderRadiusFetch, colorFetch } from '../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

// @TODO fix media types
const StyledArrowButton = styled(ArrowButton)`
  margin: ${({ type }) => (type === ArrowButtonType.LEFT ? '0 18px 0 0' : '0 0 0 18px')};
  ${media.phone`
    margin: ${({ type }: any) => (type === ArrowButtonType.LEFT ? '0 8px 0 0' : '0 0 0 8px')};
  `}
`;

const NumberingWrapper = styled.div`
  display: flex;
`;

const NumberWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: ${borderRadiusFetch('round')};
  margin: 0 11px;

  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${colorFetch('secondary4')};
  }

  &:hover > span {
    color: ${colorFetch('primary')};
  }

  &.selected {
    background-color: ${colorFetch('secondary4')};
  }
  &.selected > span {
    color: ${colorFetch('primary')};
  }
  ${media.phone`
    margin: 0 4px;
  `}
`;

const DotsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 11px;
  ${media.phone`
    margin: 0 6px;
  `}
`;

export interface PaginationNumberingProps {
  pagesNum: number;
  currentPage: number;
  onPageChange: any;
  className?: string;
}

export const PaginationNumbering: FC<PaginationNumberingProps> = ({
  pagesNum,
  onPageChange,
  currentPage,
  ...props
}) => {
  // @NOTE middleArraySize has to be ALWAYS an odd number for logic of pagination to work
  const middleArraySize = mediaType.phone ? 1 : 5;
  const distanceToArrayCenter = (middleArraySize - 1) / 2;
  const endDelta = pagesNum - currentPage - 1;
  const cutNegative = (num: number) => (num >= 0 ? num : 0);
  const size = pagesNum >= middleArraySize ? middleArraySize : pagesNum;
  const backOffset = cutNegative(distanceToArrayCenter - endDelta);
  const offset = 1 + cutNegative(currentPage - backOffset - distanceToArrayCenter);
  const middleArray = [...(Array(size) as any).keys()].map((key) => key + offset);
  const theme = useTheme();

  const handleLeftArrowClick = (e: any) => {
    e.preventDefault();
    onPageChange(-1, true);
  };
  const handleRightArrowClick = (e: any) => {
    e.preventDefault();
    onPageChange(1, true);
  };

  const handleItemClick = (value: number) => {
    onPageChange(value);
  };

  const ifStartNumber = currentPage > distanceToArrayCenter;
  const ifStartDots = currentPage > distanceToArrayCenter + 1;
  const ifEndNumber = endDelta > distanceToArrayCenter;
  const ifEndDots = endDelta > distanceToArrayCenter + 1;

  return (
    <Wrapper {...props}>
      {currentPage > 0 && <StyledArrowButton onClick={handleLeftArrowClick} type={ArrowButtonType.LEFT} />}

      <NumberingWrapper>
        {ifStartNumber && (
          <NumberWrapper
            onClick={() => {
              handleItemClick(0);
            }}
            className={currentPage === 0 ? 'selected' : ''}
          >
            <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
              {'1'}
            </Text>
          </NumberWrapper>
        )}
        {ifStartDots && (
          <DotsWrapper>
            <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
              ...
            </Text>
          </DotsWrapper>
        )}
        {middleArray.map((item) => {
          const index = item - 1;
          return (
            <NumberWrapper
              onClick={() => {
                handleItemClick(index);
              }}
              key={index}
              className={currentPage === index ? 'selected' : ''}
            >
              <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
                {item}
              </Text>
            </NumberWrapper>
          );
        })}
        {ifEndDots && (
          <DotsWrapper>
            <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
              ...
            </Text>
          </DotsWrapper>
        )}
        {ifEndNumber && (
          <NumberWrapper
            onClick={() => {
              handleItemClick(pagesNum - 1);
            }}
            className={currentPage === pagesNum - 1 ? 'selected' : ''}
          >
            <Text type={TextType.TEXT_12_GREEN} color={theme.colors.gray2}>
              {pagesNum}
            </Text>
          </NumberWrapper>
        )}
      </NumberingWrapper>

      {endDelta > 0 && <StyledArrowButton onClick={handleRightArrowClick} type={ArrowButtonType.RIGHT} />}
    </Wrapper>
  );
};
