// @ts-ignore
import styled from '@xstyled/styled-components';
import { media } from '../../styles/media';
import { Text } from '@bit/first-scope.text';
import { SmallLoader } from '../../features/smallLoader/SmallLoader';
import { CommonButton } from '../commonButton/CommonButton';
import { ReactComponent as File } from '../../assets/file.svg';
import { AddButton } from '../addButton/AddButton';

//============ NewSmartSearch styles =================
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(25%);
  box-sizing: border-box;
  ${media.tablet`
  `}
`;

export const Title = styled(Text)`
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  ${media.phone`
    line-height: 40px;
    width: 80%;
    text-align: center;`}
`;

//=============================NewSearchBar styles==============================

export const WrapperInput = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  margin: 0;
  position: relative;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: top;
`;

export const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const SearchInput = styled.input`
  /* max-width: 685px; */
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid;
  border-color: gray3;
  border-radius: ${({ borderRadius }: any) => `${borderRadius} 0 0  ${borderRadius}`};
  padding: 8px 38px;
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: black;

  &::placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: gray2;
  }
  -webkit-appearance: caret;
  -moz-appearance: caret; /* mobile firefox too! */
`;

export const ImageWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 8px;
  left: 8px;
  & > svg > path {
    stroke: ${(props: any) => props.colorOfSearchImage};
  }
`;

export const SearchButton = styled(CommonButton)`
  max-width: 115px;
  width: 100%;
  height: 40px;
  border-radius: ${({ borderRadius }: any) => `0 ${borderRadius} ${borderRadius} 0`};
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const ContainerRows = styled.div`
  max-height: 302px;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
    opacity: 0.6;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray2;
    border-radius: 15px;
  }
  ${media.tablet`
    max-height: 280px;
  `}
`;

export const Loader = styled(SmallLoader)`
  position: absolute;
  top: 12px;
  right: 12px;
  & > div {
    width: 18px;
    height: 18px;
    margin: 0;
  }
`;

export const ModalSearch = styled.div`
  position: absolute;
  z-index: 9999999999;
  bottom: 85px;
  left: 0;
  right: 0;
  transform: translateY(100%);
  background-color: white;
  box-shadow: ${({ boxShadow }: any) => boxShadow};
  border-radius: ${({ borderRadius }: any) => borderRadius};
  min-height: 65px;
  max-height: 440px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${media.tablet`
    max-height: 380px;
  `}
  ${media.phone`
    max-height: 342px;
  `}
`;

//==========================PopupPlaceholder styles============================

export const SearchPlaceholderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0 14px 0;
`;

export const PlaceholderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 14px 0;
`;

export const FileIcon = styled(File)`
  margin-right: 6px;
`;

//==========================ModalRow styles================================

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 10px;
  padding: 14px 20px;
  border-bottom: ${({ isLast }: any) => !isLast && '1px solid'};
  border-color: ${({ borderColor }: any) => borderColor};
  align-items: center;
`;

export const DeviceRoleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRoleTitle = styled(Text)`
  font-weight: 400;
  margin-right: 25px;
`;

export const StyledAddButton = styled(AddButton)`
  width: ${({ width }: any) => width};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(CommonButton)`
  margin: ${({ margin }: any) => margin};
  padding: ${({ padding }: any) => padding};
  white-space: nowrap;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
`;
