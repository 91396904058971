import React, { useEffect } from 'react';
import ls from '../../utils/ls';
import oauthRedirect, { oauthClear } from './oauthRedirect';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingScreen } from '../loadingScreen/LoadingScreen';
import { LsValueType } from '../../enums/LsValueType';
import { selectIsAuthenticated, setIsAuthenticated } from '../../app/state/appSlice';
import { useExceptions } from './useExceptions';

const polCLUSP = 'valmont.intelliventory.ai';

const defaultLang = window.location.href.includes(polCLUSP)
  ? { name: 'Polski', id: 'pl_pl', shortName: 'PL' }
  : {
      name: 'English',
      id: 'en_gb',
      shortName: 'EN',
    };

const withAuth = (Component: any) => {
  const AuthComponent = (props: any) => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const isExcepted = useExceptions();

    useEffect(() => {
      if (!isExcepted) {
        const urlParams = new URLSearchParams(window.location.search);

        if (
          !isAuthenticated &&
          !(ls.get(LsValueType.token) && Math.floor(Date.now() / 1000) < ls.get(LsValueType.exp))
        ) {
          const token = urlParams.get('access_token');
          const exp = urlParams.get('expires');
          const lang = urlParams.get('lang');
          if (exp) {
            ls.set(LsValueType.exp, exp);
          }
          if (!ls.get(LsValueType.locale)) {
            ls.set(LsValueType.locale, defaultLang.id);
          } else if (lang) {
            ls.set(LsValueType.locale, lang);
          }

          if (token) {
            ls.set(LsValueType.token, token);
            oauthClear();
          }

          if (
            (token || ls.get(LsValueType.token)) &&
            Math.floor(Date.now() / 1000) < (exp || ls.get(LsValueType.exp))
          ) {
            dispatch(setIsAuthenticated(true));
          } else {
            oauthRedirect(lang);
          }
        } else {
          dispatch(setIsAuthenticated(true));
        }
      }
    }, [dispatch, isAuthenticated]);

    if (!isExcepted && !isAuthenticated) {
      return <LoadingScreen />;
    }

    return <Component {...props} />;
  };

  return AuthComponent;
};

export default withAuth;
