import React from 'react';
import styled from 'styled-components';
import { TableCustomComponentState } from '../../../../../../../features/universalTable/types';
import { Text, TextType } from '@bit/first-scope.text';
import { SelectComponent } from './selectComponent/SelectComponent';
import { useTheme } from '@cthings.co/styles';

const StyledText = styled(Text)``;

export const IndexComponent = ({
  state,
  value,
  orderId,
  setValue,
  label,
  disabled,
}: {
  value: { name: string; index: string; id: string };
  setValue: (val: { name: string; id: string }) => void;
  orderId: string;
  state: TableCustomComponentState | undefined;
  label?: string;
  disabled?: boolean;
  language?: any;
}) => {
  const theme = useTheme();
  const { gray1 } = theme.colors;

  return (
    <>
      {state === TableCustomComponentState.VIEW_MODAL || state === TableCustomComponentState.EDIT ? (
        <SelectComponent
          label={label}
          value={value}
          setValue={setValue}
          orderId={orderId}
          disabled={disabled}
          state={state}
        />
      ) : (
        <StyledText type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
          {value?.index || 'N/A'}
        </StyledText>
      )}
    </>
  );
};
