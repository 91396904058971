import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text } from '@bit/first-scope.text';
import { DocumentCard } from '../../../components/documentCard/DocumentCard';
import { getRequiredDateFormat } from '../../../utils/date/date-format';
import { Loader, LoaderType } from '../../../features/loader/Loader';
import { media } from '../../../styles/media';
import { useGetDocumentsData } from '../../../api/manageDocuments';
import { DocumentPreviewTable } from './components/DocumentPreviewTable';
import { Footer } from './components/footer/Footer';
import { useLocation, useParams } from 'react-router-dom';
import { useLanguagePicker } from '../../../languages/useLanguagePicker';
import { isClasp } from '../../../consts';
import { PreviewButtons } from './components/previewButtons/PreviewButtons';
import { useMediaType } from '../../../styles/style.context';
import { colorFetch } from '../../../styles/utils';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 45px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 45px 45px 0 0;
  background: ${colorFetch('gray5')};
  overflow: scroll;
  ${media.tablet`
    padding: 0 25px 20px 25px;
  `}
  ${media.phone`
    padding: 0 15px 0 15px;
  `}
`;

const Title = styled(Text)`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
  color: ${colorFetch('black1')};
` as any; // @TODO

type ExtraWrapperProps = {
  isShowTable: boolean;
};

const ExtraWrapper = styled.div<ExtraWrapperProps>`
  display: grid;
  grid-template-columns: ${({ isShowTable }) => (isShowTable ? '415px 1fr' : '1fr')};
  margin-top: 35px;
  gap: 20px;
  ${media.tablet`
    grid-template-columns: 1fr;
  `}
`;

type CardWrapperProps = {
  isEmpty: boolean;
};

const CardWrapper = styled.div<CardWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  height: ${({ isEmpty }) => (isEmpty ? '100%' : 'max-content')};
  gap: 20px;
  padding-bottom: 20px;
  ${media.tabletS`
    gap: 0px;
  `}
  ${media.phone`
    justify-content: space-between;
  `}
`;

interface attachedComponent {
  id: string;
  name: string;
}

interface component {
  generic_id: string;
  name: string;
  quantity: number;
}

interface attachedOrder {
  order_id: string;
  created_at: string;
  component: component;
}

interface file {
  content_type: string;
  download_url: string;
  id: string;
  name: string;
  size: number;
  upload_date: string;
}
export interface Document {
  attached_component: attachedComponent;
  attached_order: attachedOrder;
  certificates: Object[];
  files: file[];
  id: string;
  name: string;
}

const addKeyIsLink = (data: any) => {
  const certificates = data?.certificates.map((certificate: Object) => ({ ...certificate, isLink: true }));
  const files = data?.files.map((file: Object) => ({ ...file, isLink: false }));
  return { ...data, files, certificates };
};

interface DocumentsPreviewProps {}

export const DocumentsPreview: React.FC<DocumentsPreviewProps> = () => {
  const { search } = useLocation();
  const lang = new URLSearchParams(search).get('lang');

  const order_id = new URLSearchParams(search).get('order_id');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Document>();
  const pathParams = useParams();

  const dataForPassword = useLocation();
  const password = dataForPassword?.state?.state?.password;
  const documentsData = useGetDocumentsData(pathParams.id || '0', order_id, lang, setIsLoading, password);
  const modifyData = documentsData?.files ? addKeyIsLink(documentsData) : null;
  const docFiles = modifyData?.files;
  const certificates = modifyData?.certificates;
  const docsAndCertificates = docFiles ? [...docFiles, ...certificates] : [];
  const isShowTable = data?.attached_order;
  const { changeLanguageHandler } = useLanguagePicker();
  const { tabletS } = useMediaType();

  useEffect(() => {
    setData(documentsData);
  }, [documentsData]);

  useEffect(() => {
    changeLanguageHandler(lang ? lang : 'en_gb', true);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader type={LoaderType.CLASSIC} />
      ) : (
        <Wrapper>
          <div>
            <Title>{data ? data?.name : ''}</Title>
            <ExtraWrapper isShowTable={!!isShowTable}>
              {!!isShowTable && <DocumentPreviewTable data={documentsData} />}
              <CardWrapper isEmpty={!docFiles?.length}>
                {!tabletS ? (
                  <>
                    {docsAndCertificates.map((item: any, i: number) => (
                      <DocumentCard
                        key={i}
                        downloadUrl={item?.download_url}
                        title={item?.name}
                        date={getRequiredDateFormat(item?.upload_date, 'DD.MM.YYYY')}
                        isRedirectLink={item?.isLink}
                        href={item?.href}
                      />
                    ))}
                  </>
                ) : (
                  <PreviewButtons id={pathParams.id || '0'} orderId={order_id} lang={lang} password={password} />
                )}
              </CardWrapper>
            </ExtraWrapper>
          </div>
          {!isClasp && <Footer />}
        </Wrapper>
      )}
    </>
  );
};
