import React, { FC } from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { FilterButton } from '../../../../../components/filterButton/FilterButton';
import { PackagesList } from '../packagesList/PackagesList';

const WrapperPackagesFilters = styled.div`
  display: flex;
  flex-direction: column;
`;

interface FilterPackageSectionType {
  countActiveFilter: number;
  packagesData: any;
  isClearFilterItems: boolean;
  handleFilterModal: () => void;
  setIsClearFilterItems: (data: any) => void;
  setValuesFilter: (data: any) => void;
  setExplorerRequestBody: any;
  isPackageListLoader: boolean;
  isOpenDetailsModal: boolean;
  setIsOpenDetailsModal: (data: any) => void;
  activeDevice: any;
  setActiveDevice: (data: any) => void;
  setIsLoadingImageMap: (data: any) => void;
  explorerRequestBody: any;
  handleClearWebsocketData: () => void;
  locations: any[];
  setActiveAddress: (value: number) => void;
  setActiveLevel: (value: number) => void;
}

export const FilterPackagesSection: FC<FilterPackageSectionType> = ({
  countActiveFilter,
  packagesData,
  isClearFilterItems,
  handleFilterModal,
  setIsClearFilterItems,
  setValuesFilter,
  setExplorerRequestBody,
  isPackageListLoader,
  isOpenDetailsModal,
  setIsOpenDetailsModal,
  activeDevice,
  setActiveDevice,
  setIsLoadingImageMap,
  explorerRequestBody,
  handleClearWebsocketData,
  locations,
  setActiveAddress,
  setActiveLevel,
}) => (
  <WrapperPackagesFilters>
    <FilterButton value={countActiveFilter} onClick={handleFilterModal} />
    <PackagesList
      data={packagesData}
      isClearFilterItems={isClearFilterItems}
      setIsClearFilterItems={setIsClearFilterItems}
      setValuesFilter={setValuesFilter}
      setExplorerRequestBody={setExplorerRequestBody}
      isPackageListLoader={isPackageListLoader}
      isOpenDetailsModal={isOpenDetailsModal}
      setIsOpenDetailsModal={setIsOpenDetailsModal}
      activeDevice={activeDevice}
      setActiveDevice={setActiveDevice}
      setIsLoadingImageMap={setIsLoadingImageMap}
      explorerRequestBody={explorerRequestBody}
      handleClearWebsocketData={handleClearWebsocketData}
      locations={locations}
      setActiveAddress={setActiveAddress}
      setActiveLevel={setActiveLevel}
    />
  </WrapperPackagesFilters>
);
