import { HttpService } from '../utils/http';
import { API_URL } from '../consts';

interface ExplorerInfoDataProps {
  object_ids: (string | undefined)[];
  mode_type: string;
  mode_collection: string;
  filters: any[];
}

export const getExplorerInfo = async (
  data: ExplorerInfoDataProps,
  handleAddNotification: Function,
  language?: any,
  handleLoader?: any,
  setIsPackageListLoaded?: any,
  languageKey?: string,
) => {
  handleLoader(true);
  setIsPackageListLoaded(true);
  const url = `${API_URL}/v1/operation_mode/explorer`;
  try {
    const responseData = (await HttpService.post(url, data)).data.payload;
    handleLoader(false);
    return responseData;
  } catch (e) {
    handleLoader(false);
    handleAddNotification({
      message: language ? language?.floorPlanDetailsPackagesNotLoaded : 'Packages not loaded',
      type: 'error',
    });
  }
};
