/**
 * @description
 * component for displaying zone locations
 *
 */

import React, { FC, useEffect, useRef } from 'react';
import { useState } from 'react';
// @ts-ignore
import { MapInteractionCSS } from 'react-map-interaction';
import useResizeObserver from 'use-resize-observer';
import {
  ImageContainer,
  MapInteraction,
  ChooseZoneModalWrapper,
  PlaceholderWrapper,
  Image,
  ImageOuterContainer,
  Wrapper,
  StyledText,
  StyledSecondText,
  DragContainer,
  DragImage,
  OuterCircle,
  InnerCircle,
  DragText,
} from './styled';
// @ts-ignore
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { usePrevious } from '../../utils/usePrevious';
import { LiveZone } from './components/liveZone/LiveZone';
import { NEW_ID } from '../../consts';
import { DescriptionPopup } from '../../containers/management/zones/components/descriptionPopup/DescriptionPopup';
import { useDispatch, useSelector } from 'react-redux';
// import { createZoneColors } from '../../app/state/colorsSlice';
import { useWindowSize } from '../../styles/style.context';
import { useCustomHistory } from '../../utils/react-router-dom-abstraction';
import { View } from '../../routes/routeInterfaces';
import { useBlockBodyScroll } from '../../utils/useBlockBodyScroll';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { ReactComponent as ZoneIcon } from '../../assets/zone.svg';
import { TextType } from '@bit/first-scope.text';
import { checkZoneCollision, checkZoneOverflow, checkZonePlacement } from './utils';
import { createId } from '../../utils/uuid';
import { ZoneNotificationType } from '../../containers/management/zonesDetails/types';

// const descriptionFallback = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor';
const DEFAULT_VIEWPORT = { scale: 1, translation: { x: 0, y: 0 } };
const DEFAULT_DIMENSIONS = { height: 3, width: 3 };
export interface ZonesFloorplanType {
  image: { src: string; width: number; height: number };
  id: string;
  data: any;
  scaledLocations: any;
  setImageDimensions: any;
  height: string;
  pathParams: any;
  locations: any;
  setLocations: any;
  activeId: string | null;
  setActiveId: any;
  floorId: string;
  toggleTrigger: any;
  triggerNotification: (type: ZoneNotificationType, message?: string) => void;
  activeDataIndex: number;
  activeData: any;
  isEdit: boolean;
  validatingIsStarted: boolean;
  intersectingZonesCount: number;
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  isEditingStart?: boolean;
  setSavedZones: (val: any) => void;
  handleScrollToTop: (val: string) => void;
  attemptDelete: () => void;
}

export interface Viewport {
  scale: number;
  translation: { x: number; y: number };
}

export const ZonesFloorplan: FC<ZonesFloorplanType> = ({
  image,
  scaledLocations,
  setImageDimensions,
  height,
  setActiveId,
  attemptDelete,
  data,
  locations,
  setLocations,
  floorId,
  id,
  toggleTrigger,
  activeId,
  triggerNotification,
  pathParams,
  isEdit,
  // handleDeleteOnClick,
  accessData,
  activeDataIndex,
  activeData,
  isEditingStart,
  intersectingZonesCount,
  validatingIsStarted,
  // zoneId,
  setSavedZones,
  handleScrollToTop,
}) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const dispatch = useDispatch();
  const ref: any = useRef<any>(null);
  const imageRef: any = useRef<any>(null);
  const offsetRef = useRef<any>();
  const history = useCustomHistory();

  const description = '';

  const [viewport, setViewport] = useState<Viewport>(DEFAULT_VIEWPORT);
  const { scale } = viewport;
  const [dragActive, setDragActive] = useState(false);

  const containerSize: any = useResizeObserver({ ref });
  const imageSize: any = useResizeObserver({ ref: imageRef });
  const prevImageSize = usePrevious(imageSize);
  const windowWidth = useWindowSize()[0];

  useBlockBodyScroll(windowWidth < 427 && !!activeId);

  const ratio = imageSize?.width / image.width;
  const pixel_ratio = data?.floors[0]?.floor_pixel_per_meter;

  const { building_id, floor, offset } = pathParams;

  const [onLoadImage, setOnLoadImage] = useState<boolean>(false);
  const [mainRatio, setMainRatio] = useState<number>(0);
  const [innerOffset, setInnerOffset] = useState({ x: 0, y: 0 });

  const handleDragEnd = (_: any, c: any, x: number, y: number) => {
    const rect = c.getBoundingClientRect();

    const ratio = imageSize.width / data.floors[0].floor_image_width;
    const coordinates = {
      center: { x: x - rect.x, y: y - rect.y },
      height: DEFAULT_DIMENSIONS.height * pixel_ratio,
      width: DEFAULT_DIMENSIONS.width * pixel_ratio,
    };
    const { center, height, width } = coordinates;

    const newCoordinates = {
      center: { x: center.x / ratio, y: center.y / ratio },
      height,
      width,
    };

    if (imageRef.current.contains(c)) {
      const newVal = {
        description,
        coordinates,
        name: languageStrings ? languageStrings.newZoneTitle : 'New zone',
        location: {
          floor_id: floorId,
          building_id: id,
          floor: {
            floor_id: floorId,
            floor_name: data.floors[0].floor_name,
            image_height: data.floors[0].floor_image_height,
            image_width: data.floors[0].floor_image_width,
            level: data.floors[0].floor_level,
            pixel_per_meter: data.floors[0].floor_pixel_per_meter,
            image_id: scaledLocations.length > 0 ? scaledLocations[0].location?.floor?.image_id : '',
          },
        },
      };
      const newValApi = {
        ...newVal,
        coordinates: newCoordinates,
      };

      const newId = NEW_ID + createId();

      const { faultyIds: overflownIds } = checkZoneOverflow([{ newId, ...newValApi }, ...locations], {
        x: image.width || 0,
        y: image.height || 0,
      });

      const localUpdate = (id: string, data: any) => {
        setActiveId(id);
        setLocations((value: any[]) => {
          const { faultyIds } = checkZoneCollision([{ id, ...data }, ...value]);
          const updatedZones = [
            ...value,
            {
              id,
              ...data,
            },
          ];
          return updatedZones.map((zone) => ({
            ...zone,
            isFaulty: faultyIds.findIndex((fId: string) => fId === zone.id) !== -1,
          }));
        });
        setTimeout(() => toggleTrigger(), 150);
      };

      if (overflownIds.length > 0) {
        triggerNotification(ZoneNotificationType.ERROR_OVERFLOW);
      } else {
        localUpdate(newId, newValApi);
      }
    } else {
      triggerNotification(ZoneNotificationType.ERROR_OVERFLOW);
    }
  };

  const handleRelocate = (_: any, dragElem: any, x: number, y: number, sourceNode: any, id: string) => {
    let rect = dragElem.getBoundingClientRect();

    if (ref.current.contains(dragElem) || (sourceNode && dragElem.contains(sourceNode))) {
      const imageWidth = (containerSize.height * imageSize.width) / imageSize.height;
      const imageHeight = (containerSize.width * imageSize.height) / imageSize.width;
      const rectX = mainRatio < 1 ? innerOffset.x + (containerSize.width - imageWidth) / 2 : innerOffset.x;
      const rectY = mainRatio > 1 ? innerOffset.y + (containerSize.height - imageHeight) / 2 : innerOffset.y;
      rect = { y: rectY, x: rectX };
    }

    setLocations((value: any[]) => {
      const index = value.findIndex((el: any) => el.id === id);
      const oldVal = index !== -1 ? value[index] : { coordinates: { pxPerM: 5 } };
      const coordinates = {
        center: { x: (x - rect.x) / ratio, y: (y - rect.y) / ratio },

        height: oldVal.coordinates.height,
        width: oldVal.coordinates.width,
      };

      const newVal = {
        ...oldVal,
        description: oldVal.description,
        coordinates,
        name: oldVal.name,
        id,
        location: {
          floor_id: oldVal?.location?.floor_id,
          building_id: oldVal?.location?.building_id,
          floor: {
            floor_id: oldVal?.location?.floor?.floor_id,
            image_height: imageSize.height,
            image_width: imageSize.width,
            level: oldVal?.location?.floor?.level,
            floor_name: oldVal?.location?.floor?.floor_name,
            image_id: oldVal?.location?.floor?.image_id,
            pixel_per_meter: oldVal?.location?.floor?.pixel_per_meter || 5,
          },
        },
      };

      if (index !== -1) {
        const { faultyIds: overflownIds } = checkZoneOverflow(
          [newVal, ...value.slice(0, index), ...value.slice(index + 1, value.length)],
          {
            x: image.width || 0,
            y: image.height || 0,
          },
        );
        if (overflownIds.length > 0) {
          return value;
        }

        const { faultyIds } = checkZoneCollision([
          newVal,
          ...value.slice(0, index),
          ...value.slice(index + 1, value.length),
        ]);
        const result = [...value.slice(0, index), newVal, ...value.slice(index + 1, value.length)];
        return result.map((zone) => ({
          ...zone,
          isFaulty: faultyIds.findIndex((fId: string) => fId === zone.id) !== -1,
        }));
      }

      return value;
    });
    setActiveId(id);
  };

  const handlePropertyEdit = (getValue: (currentValue: any) => any) => {
    setLocations((values: any[]) => {
      const currentValue = values[activeDataIndex];
      const resultValue = getValue(currentValue);

      if (activeDataIndex !== -1) {
        const { faultyIds } = checkZonePlacement(
          [resultValue, ...values.slice(0, activeDataIndex), ...values.slice(activeDataIndex + 1, values.length)],
          {
            x: image.width || 0,
            y: image.height || 0,
          },
        );
        const result = [
          ...values.slice(0, activeDataIndex),
          resultValue,
          ...values.slice(activeDataIndex + 1, values.length),
        ];
        return result.map((zone) => ({
          ...zone,
          isFaulty: faultyIds.findIndex((fId: string) => fId === zone.id) !== -1,
        }));
      }

      return values;
    });
  };

  useEffect(() => {
    if (offsetRef.current) {
      setInnerOffset(offsetRef.current.getBoundingClientRect());
    }
  }, [offsetRef.current]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (offsetRef.current) {
        setInnerOffset(offsetRef.current.getBoundingClientRect());
      }
    });
  }, []);

  useEffect(() => {
    const { width, height } = imageSize;
    if (width && height && (width !== prevImageSize?.width || height !== prevImageSize?.height)) {
      setImageDimensions({ width, height });
    }
  }, [imageSize.width, imageSize.height]);

  useEffect(() => {
    image.width &&
      image.height &&
      containerSize.width &&
      containerSize.height &&
      setMainRatio(image.width / image.height / (containerSize.width / containerSize.height));
  }, [image.width, image.height, containerSize.width, containerSize.height]);

  // @TODO Alex - this is a HUGE HACK - remove when possible
  useEffect(() => {
    activeDataIndex !== -1 && dragActive && setTimeout(() => setDragActive(false), 150);
  }, [activeDataIndex, dragActive]);

  // useEffect(() => {
  //   dispatch(createZoneColors(scaledLocations.length));
  // }, [scaledLocations.length]);

  return (
    <Wrapper>
      <ChooseZoneModalWrapper height={height}>
        <PlaceholderWrapper>
          <ZoneIcon />
          <StyledText type={TextType.TEXT_14_GRAY}>
            {languageStrings ? languageStrings.labelChooseZone : 'Choose zone'} <br />
            <StyledSecondText type={TextType.TEXT_12_GRAY}>
              {languageStrings ? languageStrings.labelSearchOrSelect : 'Search or select on the map'}
            </StyledSecondText>
          </StyledText>
        </PlaceholderWrapper>
      </ChooseZoneModalWrapper>
      <MapInteraction height={height}>
        <MapInteractionCSS
          disableZoom
          disablePan
          value={viewport}
          onChange={(value: Viewport) => setViewport(value)}
          maxScale={20}
        >
          <ImageOuterContainer ref={offsetRef}>
            <ImageContainer ref={ref} ratio={mainRatio}>
              <DropTarget targetKey="foo">
                <Image
                  src={image.src}
                  alt={'Floorplan'}
                  ref={imageRef}
                  onLoad={() => setOnLoadImage(true)}
                  ratio={mainRatio}
                />
              </DropTarget>
              {onLoadImage &&
                scaledLocations.map((value: any, index: number) => (
                  <LiveZone
                    pointData={value}
                    ratio={ratio}
                    scale={scale}
                    dimensionRatio={mainRatio}
                    containerDimensions={containerSize}
                    imageDimensions={imageSize}
                    handleDragEnd={(dragData: any, dragElem: any, x: number, y: number, sourceRef: any) =>
                      handleRelocate(dragData, dragElem, x, y, sourceRef, value.id)
                    }
                    handleDragStart={() => {
                      setActiveId('');
                      setDragActive(true);
                    }}
                    innerOffset={innerOffset}
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setActiveId(value.id);
                      history.replace(View.MANAGE_ZONES_DETAILS, {
                        pathParams: { building_id, floor, zone_id: value.id, offset },
                      });
                    }}
                    activeId={activeId}
                    isDraggable={windowWidth > 768 && isEdit}
                    withPoint
                  />
                ))}
            </ImageContainer>
          </ImageOuterContainer>
        </MapInteractionCSS>

        {windowWidth > 768 && isEditingStart && accessData?.edit && (
          <DragContainer>
            <DragDropContainer targetKey="foo" dragClone onDragEnd={handleDragEnd}>
              <DragImage>
                <OuterCircle>
                  <InnerCircle />
                </OuterCircle>
              </DragImage>
            </DragDropContainer>
            <DragText>
              - {languageStrings ? languageStrings.dragAndDropButton : 'Drag & Drop to make a new zone'}
            </DragText>
          </DragContainer>
        )}
      </MapInteraction>
      {activeData && (
        <DescriptionPopup
          value={activeData}
          height={height}
          closeModal={() => {
            setActiveId('');
            history.replace(View.MANAGE_ZONES_DETAILS, {
              pathParams: { building_id, floor, zone_id: 'noselection', offset },
            });
          }}
          isEditingStart={isEditingStart}
          intersectingZonesCount={intersectingZonesCount}
          pixel_ratio={pixel_ratio}
          pictureSize={{ width: data?.floors[0].floor_image_width, height: data?.floors[0].floor_image_height }}
          onChange={handlePropertyEdit}
          isEdit={isEdit}
          attemptDelete={attemptDelete}
          accessData={accessData}
          validatingIsStarted={validatingIsStarted}
          setSavedZones={setSavedZones}
          handleScrollToTop={handleScrollToTop}
          locations={scaledLocations}
        />
      )}
    </Wrapper>
  );
};
