import React from 'react';
import styled from 'styled-components';
import { colorFetch } from '../../../../../../../styles/utils';

const Stages = styled.span`
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${colorFetch('gray1')};
`;

export const StagesViewed = ({ value }: { value: any }) => {
  return (
    <Stages>
      {value && value?.length
        ? value.map((el: any, i: number) => `${el.name}${value.length - 1 === i ? '' : '; '}`)
        : 'N/A'}
    </Stages>
  );
};
