import React from 'react';

export const DocumentsIcon = () => {
  return (
    <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.0833 4.58594H13.75C12.5344 4.58594 11.3686 5.06882 10.5091 5.92836C9.64951 6.78791 9.16663 7.9537 9.16663 9.16927V45.8359C9.16663 47.0515 9.64951 48.2173 10.5091 49.0768C11.3686 49.9364 12.5344 50.4193 13.75 50.4193H41.25C42.4655 50.4193 43.6313 49.9364 44.4909 49.0768C45.3504 48.2173 45.8333 47.0515 45.8333 45.8359V18.3359L32.0833 4.58594Z"
        stroke="#33894E"
        stroke-width="3.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.0834 4.58594V18.3359H45.8334"
        stroke="#33894E"
        stroke-width="3.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.6667 29.7891H18.3334"
        stroke="#33894E"
        stroke-width="3.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        opacity="0.4"
      />
      <path
        d="M36.6667 38.9609H18.3334"
        stroke="#33894E"
        stroke-width="3.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        opacity="0.4"
      />
      <path
        d="M22.9167 20.625H20.625H18.3334"
        stroke="#33894E"
        stroke-width="3.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        opacity="0.4"
      />
    </svg>
  );
};
