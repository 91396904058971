import React from 'react';
import { LsValueType } from '../../enums/LsValueType';
import { UserType } from '../../enums/UserType';
import oauthRedirect from '../../features/authentication/oauthRedirect';
import withUserType from '../../routes/withUserType';
import ls from '../../utils/ls';
import { withTableContextProvider } from '../../features/universalTable/context';
import { Wrapper, Body } from '../elements/wrappers';
import { Navigation } from '../../features/navigation/Navigation';
import { useUserAccess, useUserInfo } from '../../api/user';
import { useSelector } from 'react-redux';
import { selectAccess, selectLanguage } from '../../app/state/userSlice';
import { PATHS } from '../../routes';
import { MobileInputAnchor } from '../../features/universalTable/components/mobileInputAnchor/MobileInputAnchor';
import { useExceptions } from '../../features/authentication/useExceptions';
import { renderRoutes } from '../../utils/renderRoutes';
import withAuth from '../../features/authentication/withAuth';
import { useLocation } from 'react-router-dom';

const ManagementMobilePlain = (props: any) => {
  const route = window.location.pathname;
  const isShowNavigation = !useExceptions();
  const languageKey = useSelector(selectLanguage);
  useUserAccess(languageKey?.id);

  const globalAccess = useSelector(selectAccess);
  const { role_name }: any = globalAccess;
  const location = useLocation();
  const currentRoute = location?.pathname;

  const isPreviewDocsRedirect = currentRoute.includes('document-preview');

  const [userInfo] = useUserInfo(!isPreviewDocsRedirect);
  const userName = userInfo?.name;

  const handleLogout = (e: any) => {
    e.preventDefault();
    ls.remove(LsValueType.token);
    oauthRedirect(languageKey.id);
  };

  const reg = /^\/manage\/orders\/\w+\/\d+\/\d+\/\d+$/;

  const title_inclusion = !route.includes(PATHS.USER_SITES_REDIRECT) || route.includes('zones');

  return (
    <Wrapper>
      {isShowNavigation ? (
        <Navigation onLogout={handleLogout} userStatus={role_name} userName={userName} withLanguages />
      ) : null}
      {title_inclusion && !reg.test(route) && <MobileInputAnchor />}
      <Body>{renderRoutes(props.routes, props.parentPathLength)}</Body>
    </Wrapper>
  );
};
const ManagementMobileContext = withTableContextProvider(ManagementMobilePlain);

export const ManagementMobile = withUserType(UserType.USER)(ManagementMobileContext);

export const ManagementMobileProtected = withAuth(ManagementMobile);
