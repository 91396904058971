/**
 * @description
 * This is container for calendar in orders page
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { Calendar, CalendarType } from '@bit/first-scope.calendar';
import { media } from '../../../../../styles/media';
import { colorFetch } from '../../../../../styles/utils';

const StyledCalendar = styled(Calendar)`
  width: 100%;

  .react-datepicker__input-container > div {
    box-shadow: none;
    & > div > svg > path {
      stroke: ${colorFetch('gray2')};
    }
  }
  .react-datepicker {
    border-radius: 8px;
    margin-top: 0px;
    ${media.tablet`
        margin: 0;
    `}
  }
  .react-datepicker__header {
    border-radius: 8px;
    padding: 20px 12px 0 12px;
  }
  .react-datepicker__month-container {
    width: 282px;
    height: max-content;
  }
  .react-datepicker__month {
    border-radius: 0 0 8px 8px;
    padding: 0 10px 18px 10px;
  }
  .react-datepicker__current-month {
    padding: 0 0 8px 0;
    font-size: 16px;
  }
  .react-datepicker__day {
    width: 26px;
    height: 26px;
    font-size: 14px;
    margin: 1px;
  }
  .react-datepicker__day-name {
    margin: 0;
  }
  .react-datepicker__navigation--previous {
    left: 18px;
    top: 26px;
  }
  .react-datepicker__navigation--next {
    right: 18px;
    top: 26px;
  }
  .react-datepicker__day-names {
    margin-top: 16px;
  }
  ${media.tablet`
    width: 100%;
    margin-bottom: 6px;

    .react-datepicker__input-container > div {
        box-shadow: none;
    }
    .react-datepicker-popper {
        top: 40px !important;
        width: 100%;
    }
    .react-datepicker__month-container {
        width: 100%;
  }
  `}
  ${media.phone`
    width: 100%;
    margin: 0;
  `}
`;

export interface DatePickerProps {
  type: CalendarType;
  startDate: any;
  setStartDate: any;
  maxDate?: any;
  minDate?: any;
  dateFormat?: string;
  inline?: boolean;
  width?: string;
}

export const DatePicker: FC<DatePickerProps> = ({
  type,
  startDate,
  setStartDate,
  maxDate,
  minDate,
  dateFormat,
  inline,
  width,
  ...props
}) => {
  return (
    <StyledCalendar
      type={type}
      startDate={startDate}
      setStartDate={setStartDate}
      inline={inline}
      dateFormat={dateFormat ?? 'D MMMM YYYY'}
      maxDate={maxDate}
      minDate={minDate}
      {...props}
    />
  );
};
