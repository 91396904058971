import { useEffect, useState } from 'react';
// @ts-ignore
import _ from 'underscore';
import { triggerPlaceholderEmpty, useLoaderOptions } from '../placeholderComponent/loaderFunctions';
import { TriggerPlaceholder } from '../placeholderComponent/types';

const callFakePromise = (delay: number, value: Object | Object[]): Object | Object[] => {
  return new Promise((resolve) => setTimeout(resolve, delay, value));
};

export const buildFakeApiData = (result: Object | Object[] | Function, options: any): Object | Object[] => {
  const isFunctionResult = _.isFunction(result);
  let preparedResult;
  if (isFunctionResult) {
    preparedResult = (result as Function)(options);
  } else {
    preparedResult = result;
  }
  return preparedResult;
};

export interface CallFakeApiType {
  result: Object | Object[] | Function;
  // @TODO Alex define options clearly
  options?: any;
  callback?: (data: Object | Object[]) => void;
}

export interface UseFakeApiGetType {
  defaultData: Object | Object[];
  result: Object | Object[] | Function;
  // @TODO Alex define options clearly
  options?: any;
  setPlaceholder: TriggerPlaceholder;
  stopPlaceholder: TriggerPlaceholder;
}

// @TODO Alex reduce the redundancy here
export const callFakeApi = ({ result, options, callback }: CallFakeApiType) => {
  const preparedResult = buildFakeApiData(result, options);
  (async () => {
    try {
      let responseData = await callFakePromise(100, preparedResult as any);
      callback && callback(responseData);
    } catch (e) {
      //
    }
  })();
};

export const useFakeApiGet = ({
  defaultData,
  result,
  // @TODO Alex define options clearly
  options,
  setPlaceholder = triggerPlaceholderEmpty,
  stopPlaceholder = triggerPlaceholderEmpty,
}: UseFakeApiGetType) => {
  const [data, setData] = useState(defaultData);

  const trigger = [options] as const;

  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);
  const { setPlaceholder: handleLoader } = loaderOptions;

  const handleLoaderStart = () => {
    handleLoader(true);
  };

  const handleLoaderEnd = () => {
    handleLoader(false);
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const preparedResult = buildFakeApiData(result, options);
    (async () => {
      try {
        handleLoaderStart();
        let responseData = await callFakePromise(100, preparedResult as any);
        setData(responseData);
        handleLoaderEnd();
      } catch (e) {
        handleLoaderEnd();
      }
    })();
  }, [...trigger, loaderOptions]);
  return data;
};
