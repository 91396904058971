import React, { FC } from 'react';
import { ArrowButton, ArrowButtonType } from '@bit/first-scope.arrow-button';
import { Text, TextType } from '@bit/first-scope.text';
import styled from 'styled-components';
import { colorFetch } from '../../../../../../../styles/utils';

const WrapperComments = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  /* overflow: auto; */
`;

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, 1fr) 20px;
  align-items: center;
  padding: 0 22px;
  border-bottom: 1px solid;
  border-color: ${colorFetch('gray3')};
`;

const StyledText = styled(Text)`
  padding: 12px 0;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export interface ItemComponentProps {
  component: any;
  setOpenEditModal: any;
  setActiveId: any;
  index: number;
}

export const ItemComponentMobile: FC<ItemComponentProps> = ({ component, setOpenEditModal, setActiveId, index }) => {
  const componentField = [
    {
      key: 'name',
      value: component.component_value.name || '',
    },
    {
      key: 'index',
      value: component.index || '',
    },
  ];

  return (
    <WrapperComments
      onClick={() => {
        setOpenEditModal(true);
        setActiveId(component.component_id);
      }}
    >
      <Container>
        {componentField.map(({ value, key }: any, index: number) => (
          <StyledText type={TextType.TEXT_14_GRAY} key={key}>
            {value}
          </StyledText>
        ))}
        <ArrowButton type={ArrowButtonType.RIGHT} onClick={() => {}} />
      </Container>
    </WrapperComments>
  );
};
