/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings select period require
 * Highly customisable.
 *
 */

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as ArrowImage } from '../../assets/Shape.svg';
import { useTheme } from '@cthings.co/styles';
import { borderRadiusFetch, colorFetch, shadowFetch } from '../../styles/utils';

const ExtraWrapper = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

type ArrowProps = {
  isOpen: boolean;
};

const Arrow = styled.div<ArrowProps>`
  display: flex;
  margin: 0px 0px 0px 8px;
  transition: all 0.5s ease;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  & > svg > path {
    fill: ${({ color }) => color};
    transition: fill 0.3s ease;
  }
`;

type ListWrapperProps = {
  isOpen: boolean;
};

const ListWrapper = styled.ul<ListWrapperProps>`
  width: 128px;
  height: 108px;

  position: absolute;
  right: 0px;
  top: 8px;
  z-index: 1000;

  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  background-color: ${colorFetch('pureWhite')};
  box-shadow: ${shadowFetch('additionalShadow4')};
  border-radius: ${borderRadiusFetch('primary')};

  padding: 6px 8px 10px 8px;
  transition: all 0.3s ease;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
`;

const Item = styled.li`
  font-size: 12px;
  font-weight: normal;
  color: ${colorFetch('black1')};
  list-style: none;
  cursor: pointer;

  transition: color 0.3s ease;
  &.choosen {
    color: ${colorFetch('primary')};
  }

  &:hover {
    color: ${colorFetch('primary')};
  }
`;

export interface SelectPeriodProps {
  values: string[];
  className?: string;
}

export const SelectPeriod: FC<SelectPeriodProps> = ({
  values,

  className,
}) => {
  const theme = useTheme();
  const { gray1, gray2 } = theme.colors;

  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(/*-1*/ 0);
  const color = isHovered || isOpen || isSelected ? gray1 : gray2;

  return (
    <ExtraWrapper className={className}>
      <Wrapper>
        <PeriodWrapper
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
        >
          {values.map((value, index) => {
            return index === selectedIndex ? (
              <Text key={index} type={TextType.TEXT_12_BLACK} color={color}>
                {value}
              </Text>
            ) : null;
          })}
          <Arrow color={color} isOpen={isOpen}>
            <ArrowImage />
          </Arrow>
        </PeriodWrapper>
        <ListWrapper isOpen={isOpen} theme={theme}>
          {values.map((value, index) => {
            return (
              <Item
                className={index === selectedIndex ? 'choosen' : ''}
                key={index}
                onClick={() => {
                  setSelectedIndex(index);
                  setIsOpen(false);
                  setIsSelected(true);
                }}
              >
                {value}
              </Item>
            );
          })}
        </ListWrapper>
      </Wrapper>
    </ExtraWrapper>
  );
};

SelectPeriod.defaultProps = {
  values: ['Last week', 'Last 30 days', 'Last 6 months', 'Last year'],
};
