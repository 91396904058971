/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for page search in pagination of universal table require
 * Highly customisable.
 *
 */

import React, { FC, useState } from 'react';
import styled from 'styled-components';
// @ts-ignore
import AutosizeInput from 'react-input-autosize';
import { CommonButton } from '../../../../../components/commonButton/CommonButton';
import { media } from '../../../../../styles/media';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { colorFetch } from '../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const InputWrapper = styled.div`
  width: 54px;
  height: 24px;
  padding-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  background-color: ${colorFetch('pureWhite')};
  border-radius: 8px 0 0 8px;
  box-sizing: border-box;
  overflow: hidden;
`;

const StyledInput = styled(AutosizeInput)`
  background-color: ${colorFetch('pureWhite')};
  border-radius: 8px 0 0 8px;
  height: 24px;
  & > input {
    width: 100%;
    -webkit-appearance: none;
    margin: 0;
    outline: transparent;
    border: 0;
    background-color: transparent;
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    color: ${colorFetch('black1')};
  }

  & > input::-webkit-outer-spin-button,
  & > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-left: 30px;
  ${media.desktop`
    display: none;
`};
`;

const StyledButton = styled(CommonButton)`
  width: 50px;
  height: 24px;
  border-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-family: Poppins;

  ${media.phone`
    width: 100%;
    height: 40px;
  `}
`;

const TextUnit = styled.span`
  width: 40px;
  padding: 5px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colorFetch('gray2')};
`;

export interface GoToPaginationProps {
  handleGoToPage: any;
  pagesCount: number;
}

export const GoToPagination: FC<GoToPaginationProps> = ({ handleGoToPage, pagesCount }) => {
  const theme = useTheme();
  const { pureWhite, primary } = theme.colors;

  const [numberPage, setNumberPage] = useState(1);
  const language = useSelector(selectLanguageStrings);

  const handleRedirectPage = () => {
    if (+numberPage > 1) {
      const numberForSet = numberPage > pagesCount ? pagesCount : numberPage;
      setNumberPage(() => numberForSet);
      handleGoToPage(numberForSet - 1);
    } else {
      handleGoToPage(0);
      setNumberPage(1);
    }
  };

  const handleSetNumberPage = (e: any) => {
    e.preventDefault();
    setNumberPage(e.target.value);
  };

  const handleKeypress = (e: any) => {
    handleSetNumberPage(e);
    if (e.keyCode === 13) {
      handleRedirectPage();
    }
  };

  return (
    <Container>
      <TextUnit>{language ? language.paginationGoTo : 'Go to'}</TextUnit>
      <InputWrapper>
        <StyledInput
          type="number"
          onChange={handleSetNumberPage}
          value={numberPage}
          onKeyUp={handleKeypress}
          inputStyle={{ height: '24px', boxSizing: 'border-box', paddingLeft: '10px' }}
        />
      </InputWrapper>
      <StyledButton onClick={handleRedirectPage} width={'104px'} backgroundColor={primary} color={pureWhite}>
        {language ? language.paginationGo : 'Go'}
      </StyledButton>
    </Container>
  );
};
