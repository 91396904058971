import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { API_URL, NEW_ID } from '../consts';
import { HttpService } from '../utils/http';
import { setOrderDetails, selectOrderDetails } from '../app/state/manageOrderDetailsSlice';
import { useLoaderOptions } from '../features/placeholderComponent/loaderFunctions';
import { ErrorEventType } from '../types/error';
import { useApi } from '../features/apiBuilder/useApiBuilder';
import { QueryKeys } from './types';
import { handleUncoveredNotification } from '../utils/handleUncoveredNotification';
import { CommentType } from '../containers/management/orderDetails/components/packagesInfoTable/components/commentsTest/components/comment/types';

//@REVIEW dont use Redux for tables anymore
export const useManageOrderDetails = (
  id: string,
  setPlaceholder?: any,
  stopPlaceholder?: any,
  isReloading?: boolean,
  setIsReloading?: (value: boolean) => any,
) => {
  const manageOrderDetails = useSelector(selectOrderDetails);
  const dispatch = useDispatch();
  const [localOrderDetails, setLocalOrderDetails] = useState(manageOrderDetails);
  const trigger = [dispatch, id] as const;
  const loaderOptions = useLoaderOptions(setPlaceholder, stopPlaceholder, [...trigger]);
  useEffect(() => {
    const url = `${API_URL}/v1/order/details/${id}`;
    (async () => {
      try {
        let responseData = (await HttpService.get(url, loaderOptions)).data.payload;
        dispatch(setOrderDetails(responseData));
        setLocalOrderDetails(responseData);
        setIsReloading && setIsReloading(false);
      } catch (e) {
        setIsReloading && setIsReloading(false);
        //
      }
    })();
  }, [...trigger, loaderOptions, isReloading]);

  useEffect(() => {
    setOrderDetails(manageOrderDetails);
  });
  return localOrderDetails;
};

export const useOrderPackageDetails = (idOrder: any, id: any) => {
  const [data] = useApi({
    defaultValue: undefined,
    url: `${API_URL}/v1/order/details/${idOrder}/package/${id}`,
    keywords: [QueryKeys.GET_ORDER_PACKAGE_DETAILS],
    transformResponse: (data) => data.payload,
    condition: !!id,
  });

  return [data];
};

export const useDefaultPackageName = (orderId: string, productionId: string) => {
  const [data] = useApi({
    defaultValue: {},
    url: `${API_URL}/v1/order/details/${orderId}/package/default_name?production_id=${productionId}`,
    keywords: ['GET_DEFAULT_PACKAGE_NAME'],
    transformResponse: (data) => {
      return data.payload;
    },
    condition: !!orderId && !!productionId,
  });

  return [data];
};

export const useGetComponentsList = (orderId: string, packageId: string) => {
  const [data] = useApi({
    defaultValue: {},
    url: `${API_URL}/v1/order/details/${orderId}/package/${packageId === NEW_ID ? '' : packageId}`,
    keywords: ['GET_COMPONENTS_LIST'],
    transformResponse: (data) => {
      return data.payload?.available;
    },
    condition: !!orderId && !!packageId,
  });
  return [data];
};

export const useGetStagesList = (orderId: string, componentId: string) => {
  const [data] = useApi({
    defaultValue: {},
    url: `${API_URL}/v1/order/details/${orderId}/package/liststages/${componentId}`,
    keywords: ['GET_STAGES_LIST'],
    transformResponse: (data) => {
      return data.payload;
    },
    condition: !!orderId && !!componentId,
  });
  return [data];
};

export const setOrderPackageComment = (
  order_id: string,
  remark: string,
  package_id: string,
  setComments: (remarks: CommentType[]) => void,
  setLoader: (value: boolean) => void,
) => {
  const url = `${API_URL}/v1/order/details/${order_id}/package/${package_id}/remark`;
  (async () => {
    setLoader(true);
    try {
      let responseData = (await HttpService.patch(url, { remark })).data;
      setComments(responseData.payload.remarks);
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  })();
};

export const updatePackage = (
  id: string,
  data: any,
  index: number,
  updateLocalItem: any,
  orderId: string,
  toggleTrigger: any,
  handleAddNotification: Function,
  language: any,
  setBlockButtons: (status: boolean) => void,
  setSavedData: (status: any) => void,
  viewedData: any,
) => {
  if (id !== NEW_ID) {
    const url = `${API_URL}/v1/order/details/${orderId}/package/${id}`;
    setBlockButtons(true);
    (async () => {
      try {
        data.tracker = !data?.tracker ? null : Object.values(data?.tracker).includes('') ? null : data?.tracker;
        let responseData = (await HttpService.patch(url, data)).data;
        toggleTrigger();
        updateLocalItem(index, responseData.payload);
        setSavedData(viewedData);
        handleAddNotification({
          message: language ? language.savedSuccessfullyNotification : 'Saved successfully',
          type: 'success',
        });
        setBlockButtons(false);
      } catch (e) {
        const error = e as ErrorEventType;
        handleUncoveredNotification(error, handleAddNotification, language);
        setBlockButtons(false);
      }
    })();
  } else {
    const url = `${API_URL}/v1/order/details/${orderId}/package/addpackage/`;
    setBlockButtons(true);
    (async () => {
      try {
        data.tracker = Object.values(data?.tracker).includes('') ? null : data?.tracker;
        let responseData = (await HttpService.post(url, data)).data;
        toggleTrigger();
        updateLocalItem(responseData.payload.id, responseData.payload);
        handleAddNotification({
          message: language ? language.savedSuccessfullyNotification : 'Saved successfully',
          type: 'success',
        });
        setBlockButtons(false);
      } catch (e) {
        handleUncoveredNotification(e, handleAddNotification, language);
        setBlockButtons(false);
      }
    })();
  }
};

export const updateEvents = (
  id: string,
  data: any,
  index: number,
  updateLocalItem: any,
  orderId: string,
  handleAddNotification: Function,
  language: any,
  setBlockButtons: (status: boolean) => void,
) => {
  if (id !== NEW_ID) {
    const url = `${API_URL}/v1/order/details/${orderId}/action/${id}`;
    setBlockButtons(true);
    (async () => {
      try {
        let responseData = (await HttpService.patch(url, data)).data;
        updateLocalItem(index, responseData.payload);
        handleAddNotification({
          message: language ? language.savedSuccessfullyNotification : 'Saved successfully',
          type: 'success',
        });
        setBlockButtons(false);
      } catch (e) {
        handleUncoveredNotification(e, handleAddNotification, language);
        setBlockButtons(false);
      }
    })();
  } else {
    const url = `${API_URL}/v1/order/details/${orderId}/action`;
    setBlockButtons(true);

    (async () => {
      try {
        let responseData = (await HttpService.post(url, data)).data;
        updateLocalItem(responseData.payload.id, responseData.payload);
        handleAddNotification({
          message: language ? language.savedSuccessfullyNotification : 'Saved successfully',
          type: 'success',
        });
        setBlockButtons(false);
      } catch (e) {
        handleUncoveredNotification(e, handleAddNotification, language);
        setBlockButtons(false);
      }
    })();
  }
};

export const deleteEventGlobal = (
  id: string[],
  deleteItem: any,
  deleteCheckedItems: any,
  handleAddNotification: Function,
  language: any,
) => {
  const data = { ids: id };
  const url = `${API_URL}/v1/actions/`;
  const deleteOneItem = () => deleteItem(id[0]);
  (async () => {
    try {
      await HttpService.delete(url, data);
      if (id.length === 1) {
        deleteOneItem();
      } else {
        deleteCheckedItems();
      }
      handleAddNotification({
        message: language ? language.deletedSuccessfullyNotification : 'Deleted successfully',
        type: 'success',
      });
    } catch (e) {
      handleUncoveredNotification(e, handleAddNotification, language);
    }
  })();
};

export const deletePackageGlobal = (
  packageIds: string[],
  deleteItem: any,
  deleteCheckedItems: any,
  orderId: string,
  handleAddNotification: Function,
  language: any,
) => {
  const data = { package_ids: packageIds };
  const url = `${API_URL}/v1/order/details/${orderId}/package/`;
  const deleteOnePackage = () => deleteItem(packageIds[0]);
  (async () => {
    try {
      await HttpService.delete(url, data);
      if (packageIds.length === 1) {
        deleteOnePackage();
      } else {
        deleteCheckedItems();
      }
      handleAddNotification({
        message: language ? language.deletedSuccessfullyNotification : 'Deleted successfully',
        type: 'success',
      });
    } catch (e) {
      handleUncoveredNotification(e, handleAddNotification, language);
    }
  })();
};
