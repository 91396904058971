import { TextType } from '../text/Text';
export const getParams = (type, theme, color, weight) => {
    const params = {
        [TextType.CHIPS_WHITE]: {
            fontWeight: '500',
            fontSize: '10px',
            lineHeight: '14px',
            color: theme.colors.white,
        },
        [TextType.CHIPS_BLACK]: {
            fontWeight: '500',
            fontSize: '10px',
            lineHeight: '14px',
            color: theme.colors.black2,
        },
        [TextType.TEXT_14_BLACK]: {
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '24px',
            color: theme.colors.black2,
        },
        [TextType.TEXT_14_WHITE]: {
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '24px',
            color: theme.colors.white,
        },
        [TextType.TEXT_14_GRAY]: {
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '24px',
            color: theme.colors.gray2,
        },
        [TextType.TEXT_14_RED]: {
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '24px',
            color: theme.colors.red2,
        },
        [TextType.TEXT_14_GREEN]: {
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '24px',
            color: theme.colors.primary,
        },
        [TextType.TEXT_20_MAP]: {
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '25px',
            color: theme.colors.white,
        },
        [TextType.PROBLEMS_BUTTON_TEXT]: {
            fontWeight: '700',
            fontSize: '40px',
            lineHeight: '40px',
            color: theme.colors.white,
        },
        [TextType.HELPER_TEXT_1]: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '24px',
            color: theme.colors.gray1,
        },
        [TextType.HELPER_TEXT_2]: {
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.colors.gray2,
        },
        [TextType.MENU_ITEM_TEXT_PRIMARY]: {
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '24px',
            color: theme.colors.black,
        },
        [TextType.MENU_ITEM_TEXT_ADDITIONAL]: {
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '16px',
            color: theme.colors.gray1,
        },
        [TextType.TEXT_12_BLACK]: {
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '14px',
            color: theme.colors.black,
        },
        [TextType.TEXT_12_GRAY]: {
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.colors.gray2,
        },
        [TextType.TEXT_12_GREEN]: {
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.colors.primary,
        },
        [TextType.TEXT_10_GRAY]: {
            fontWeight: '500',
            fontSize: '10px',
            lineHeight: '14px',
            color: theme.colors.gray1,
        },
        [TextType.TEXT_10_WHITE]: {
            fontWeight: '600',
            fontSize: '10px',
            lineHeight: '14px',
            color: theme.colors.white,
        },
        [TextType.TITLE_PRIMARY]: {
            fontWeight: '700',
            fontSize: '30px',
            lineHeight: '24px',
            color: theme.colors.black,
        },
        [TextType.TITLE_SECONDARY]: {
            fontWeight: '500',
            fontSize: '30px',
            lineHeight: '24px',
            color: theme.colors.gray2,
        },
        [TextType.TITLE_ADDITIONAL]: {
            fontWeight: '400',
            fontSize: '30px',
            lineHeight: '24px',
            color: theme.colors.gray2,
        },
        [TextType.SEMITITLE]: {
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '22px',
            color: theme.colors.gray2,
        },
        [TextType.TITLE_H1]: {
            fontWeight: 'bold',
            fontSize: '30px',
            lineHeight: '24px',
            color: theme.colors.black,
        },
        [TextType.TITLE_H1MOBILE]: {
            fontWeight: 'bold',
            fontSize: '28px',
            lineHeight: '24px',
            color: theme.colors.black,
        },
        [TextType.TITLE_H3]: {
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '22px',
            color: theme.colors.gray2,
        },
        [TextType.TITLE_H3MOBILE]: {
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: '22px',
            color: theme.colors.gray2,
        },
        [TextType.TITLE_H4]: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '24px',
            color: theme.colors.black,
        },
        [TextType.TITLE_H4MOBILE]: {
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.colors.black,
        },
        [TextType.TITLE_H5]: {
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.colors.gray2,
        },
        [TextType.TITLE_H6BOLD]: {
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: '150%',
            color: theme.colors.black,
        },
        [TextType.TITLE_H6MOBILE]: {
            fontWeight: 'normal',
            fontSize: '20px',
            lineHeight: '150%',
            color: theme.colors.gray2,
        },
    };
    const values = params[type] || {};
    const rewriteColorOption = color ? { color } : {};
    const rewriteWeightOption = weight ? { fontWeight: weight } : {};
    const result = Object.assign(Object.assign(Object.assign({}, values), rewriteColorOption), rewriteWeightOption);
    return result;
};
