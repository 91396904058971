// @ts-ignore
import styled from '@xstyled/styled-components';
import { media } from '../../../styles/media';
import { CustomModal } from '../../customModal/CustomModal';
import { CommonButton } from '../../commonButton/CommonButton';
import { ReactComponent as ClearFilterIcon } from '../../../assets/ClearFilters.svg';

export const Container = styled(CustomModal)`
  & > div > div > div {
    overflow: unset;
    ${media.phone`
      overflow: auto;
    `}
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  ${media.phone`
    display: flex;
    flex-direction: column;
  `}
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  ${media.phone`
    flex-direction: column;
    gap: 20px;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;

  ${media.phone`
    flex-direction: column;
  `}
`;

export const ClearButton = styled(CommonButton)`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;

  &:hover {
    & > svg > path {
      stroke: white;
    }
    & > span {
      color: white;
      transition: 0s;
    }
  }

  ${media.phone`
    width: 100%;
    height: 40px;
  `}
`;

export const StyledButton = styled(CommonButton)`
  ${media.phone`
    width: 100%;
    height: 40px;
  `}
`;

export const StyledIcon = styled(ClearFilterIcon)`
  margin-right: 6px;
`;
