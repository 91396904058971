import { Dispatch, SetStateAction } from 'react';

export enum TooltipTriggerType {
  HOVER = 'HOVER',
  CLICK = 'CLICK',
  STATIC = 'STATIC',
}

export enum TooltipPlace {
  TOP = 'top',
  RIGHT = 'right',
  LEFT = 'left',
  BOTTOM = 'bottom',
}

export type TooltipRequest = {
  id: string;
};

export type Tooltip = {
  openRequests: TooltipRequest[];
  closeRequests: TooltipRequest[];
};

export type TooltipContextProviderType = [Tooltip, Dispatch<SetStateAction<Tooltip>>];
