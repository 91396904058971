import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Dialogue } from '../../../../../../../../../assets/Dialogue.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../../../app/state/userSlice';
import { colorFetch } from '../../../../../../../../../styles/utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.div`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colorFetch('gray2')};
  margin-left: 6px;
`;

export const NoComments: React.FC = () => {
  const language = useSelector(selectLanguageStrings);

  return (
    <Wrapper>
      <Dialogue />
      <Text>{language ? language.noCommentsYetPlaceholder : 'No comments yet'}</Text>
    </Wrapper>
  );
};
