/**
 * @description
 * This is component that displays all inforamtion about Stages
 */

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { API_URL } from '../../../consts';
import { media } from '../../../styles/media';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  ActionType,
  InlineActionType,
  RowItemType,
  TableCustomComponentState,
} from '../../../features/universalTable/types';
import { View } from '../../../routes/routeInterfaces';
import { selectAccess, selectLanguage, selectLanguageStrings } from '../../../app/state/userSlice';
import { useMediaType } from '../../../styles/style.context';
import { ManageTitle } from '../components/manageTitle/ManageTitle';
import { deleteStageGlobal, updateStages } from '../../../api/manageStages';
import { useTableFunctions } from '../../../features/universalTable/context';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import { MetaDataComponent } from '../processes/components/metaDataComponent/MetaDataComponent';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  ${media.tablet`
    padding: 60px 0 10px 0;
  `}
`;

export const Stages: React.FC = () => {
  const { startAddProcess, setSearchValue, getSearchValue, resetTable } = useTableFunctions();

  const { actual: searchValue } = getSearchValue();

  const mediaType = useMediaType();
  const params = useParams();
  const keyword = 'StagesTable';
  const offsetKey = 'offset';
  const accessData = useSelector(selectAccess).management.stages;

  const offset = params[offsetKey] || '0';
  const { state }: any = useLocation();

  const languageStrings = useSelector(selectLanguageStrings);
  const language = useSelector(selectLanguage).shortName;

  const placeholderHandler = (placeholder: string) => {
    return language === 'PL'
      ? placeholder.length >= 23
        ? placeholder.slice(0, 23) + '...'
        : placeholder
      : placeholder;
  };

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  return (
    <Wrapper>
      <ManageTitle
        keyword={keyword}
        title={languageStrings ? languageStrings.titleOfStagesPage : 'Stages'}
        addButtonValue={languageStrings ? languageStrings.addButton : 'Add'}
        searchInputValue={searchValue}
        addButtonOnClick={startAddProcess}
        handleChangeSearchInputValue={setSearchValue}
        accessData={accessData}
      />
      <UniversalTable
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !mediaType.tablet, countRows: mediaType.phone ? 6 : 12 },
        }}
        offset={+offset}
        offsetKey={offsetKey}
        tablePath={View.MANAGE_STAGES}
        updateItems={updateStages}
        deleteItems={deleteStageGlobal}
        allowDelete={accessData.delete}
        additionalKeywords={['ManageLayoutSearch']}
        actionSet={[{ type: ActionType.EDIT }]}
        inlineActionSet={[{ type: InlineActionType.DETAILS }]}
        idItemForViewModal={state?.state?.id}
        mainApiUrl={`${API_URL}/v1/stages/`}
        keyword={keyword}
        accessData={accessData}
        isAccess={accessData?.edit}
        pageSize={mediaType.phone ? 6 : 18}
        columnStructure={[
          {
            fieldName: 'name',
            placeholder: languageStrings ? languageStrings.enterNamePlaceholder : 'Enter name',
            label: languageStrings ? languageStrings.labelName : 'Name',
            defaultValue: '',
          },
          {
            fieldName: 'exp_duration',
            placeholder: languageStrings
              ? placeholderHandler(languageStrings.expectedDurationPlaceholder)
              : 'Enter expected duration',
            label: languageStrings ? languageStrings.labelExpectedDuration : 'Expected duration',
            conditionForValidationInCustom: 'not valid',
            defaultValue: '',
          },
          {
            fieldName: 'description',
            placeholder: languageStrings ? languageStrings.enterDescriptionPlaceholder : 'Enter description',
            label: languageStrings ? languageStrings.labelDescription : 'Description',
            defaultValue: '',
          },
          {
            fieldName: 'metadata',
            placeholder: '',
            label: languageStrings ? languageStrings.labelMetadata : 'Metadata',
            defaultValue: {},
            modalColumns: 2,
            type: RowItemType.CUSTOM,
            excludedFromView: true,
            // hide title component for View_Modal in mobile version
            excludedTitleFromMobileViewEmptyData: true,
            conditionForValidationInCustom: 'not valid',
            component: (value: any, state?: TableCustomComponentState, setValue?: any) => (
              <MetaDataComponent value={value} state={state} setValue={setValue} />
            ),
          },
        ]}
      />
    </Wrapper>
  );
};
