import React from 'react';

export const OnlineIcon = ({ color }: { color: string }) => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8333 26.1054C15.1158 22.5383 20.5131 20.585 26.0866 20.585C31.6601 20.585 37.0574 22.5383 41.3399 26.1054"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        d="M3.07666 18.4139C9.40858 12.8324 17.5593 9.75293 26 9.75293C34.4407 9.75293 42.5914 12.8324 48.9233 18.4139"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        d="M18.4817 33.8193C20.6813 32.2566 23.3126 31.417 26.0109 31.417C28.7091 31.417 31.3404 32.2566 33.54 33.8193"
        stroke={color}
        stroke-width="2.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M26 42.2471H26.025" stroke={color} stroke-width="8.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
