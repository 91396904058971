import React from 'react';
import { ChooseParameterSelect } from '../../../../../../components/chooseParameterSelect/ChooseParameterSelect';
import { useFilters } from '../../../Content/filterContext/сontext';
import { getIsAllEntitySelect, getIsDisabledEntity } from '../../../Content/filterContext/selectors';
import { useQueryParams } from '../../../../../../utils/useQueryParams';

export enum typeObjType {
  PACKAGE = 'PACKAGE',
  INVENTORY = 'INVENTORY',
}

const defaultFilterState = {
  devices: ['nodes', 'tracker', 'offline'],
  attach: ['attached', 'detached'],
  object_type: ['packages', 'inventories'],
  zones: ['zone'],
};

const getStatusObjTypeFilter = (queryObjTypeParams: string[], typeObjType: string) =>
  queryObjTypeParams?.length > 0 ? queryObjTypeParams.includes(typeObjType) : false;

const filteredObjTypeState = (objTypeState: string[], type: string) => {
  return Array.isArray(objTypeState) ? objTypeState.filter((item) => item !== type) : [];
};

const addObjTypeTypeToState = (objTypeState: string[], type: string) => {
  const checkedObjTypeState = Array.isArray(objTypeState) ? [...objTypeState, type] : [objTypeState, type];
  return !!objTypeState ? checkedObjTypeState : [type];
};

const setQueryOneObjType = (type: typeObjType, setFilterParam: any, queryParams: any) => {
  const objTypeState = queryParams?.object_type;

  const stringTypeObjType = {
    [typeObjType.PACKAGE]: 'packages',
    [typeObjType.INVENTORY]: 'inventories',
  };

  const setTypesObjType = {
    [typeObjType.PACKAGE]: (isNode: boolean) =>
      setFilterParam(
        isNode
          ? { ...queryParams, object_type: filteredObjTypeState(objTypeState, stringTypeObjType[type]) }
          : { ...queryParams, object_type: addObjTypeTypeToState(objTypeState, stringTypeObjType[type]) },
      ),
    [typeObjType.INVENTORY]: (isTracker: boolean) =>
      setFilterParam(
        isTracker
          ? { ...queryParams, object_type: filteredObjTypeState(objTypeState, stringTypeObjType[type]) }
          : { ...queryParams, object_type: addObjTypeTypeToState(objTypeState, stringTypeObjType[type]) },
      ),
  };

  const statusTypeObjType = getStatusObjTypeFilter(queryParams?.object_type, stringTypeObjType[type]);

  return setTypesObjType[type](statusTypeObjType);
};

const FilterEntity = ({ languageStrings, setFilterParam }: any) => {
  const queryParams = useQueryParams() || {};
  const stateFilterObjectType = queryParams?.object_type;
  const { state, dispatch } = useFilters();
  const { entityState } = state;
  const { checkedEntityCount, isOpenEntityList, entityFilter } = entityState;

  const isSelectAllEntity = getIsAllEntitySelect(state);
  const isDisabledEntity = getIsDisabledEntity(state);

  const handleSelectAllObjType = () => {
    dispatch({ type: 'SELECT_ALL_ENTITY', payload: isSelectAllEntity });
    setFilterParam(
      !!stateFilterObjectType && stateFilterObjectType.length >= 1
        ? { ...queryParams, object_type: '' }
        : { ...queryParams, object_type: defaultFilterState.object_type },
    );
  };

  const handleSelectOneObjType = (type: typeObjType) => {
    dispatch({ type: 'SELECT_ONE_ENTITY', payload: type });
    setQueryOneObjType(type, setFilterParam, queryParams);
  };

  const handleEntityList = (state: boolean) => {
    dispatch({ type: 'HANDLE_ENTITY_LIST', payload: state });
  };

  return (
    <ChooseParameterSelect
      name={languageStrings ? languageStrings.labelObjectType : 'Object type'}
      parameters={entityFilter}
      //@ts-ignore
      handleCheckedItem={handleSelectOneObjType}
      handleSelectAll={handleSelectAllObjType}
      setIsOpenSelectList={handleEntityList}
      isSelectAll={isSelectAllEntity}
      isOpenSelectList={isOpenEntityList}
      isSearchActive={false}
      isDisabled={isDisabledEntity}
      checkedCount={checkedEntityCount}
      maxWidth={'185px'}
    />
  );
};

export { FilterEntity };
