import React from 'react';
import styled from 'styled-components';
import { Card } from './components/card/Card';
import { CollectionIconsType } from './components/types';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { useHistory } from '../../utils/react-router-dom-abstraction';
import { PATHS } from '../../routes';
import { injectPathParams, injectQueryParams } from '../../routes/paths';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  user-select: none;
`;

const CardsWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const PreFilterCards = () => {
  const theme = useTheme();
  const languageStrings = useSelector(selectLanguageStrings);
  const history = useHistory();
  // let query = useQuery();

  const { gray2 } = theme.colors;

  const types = {
    ORDER: 'order',
    PACKAGE: 'package',
  };

  const cardsMapping = [
    {
      type: CollectionIconsType.ORDER,
      onClick: () => {
        const toOrderSearch = injectPathParams(PATHS.USER_SEARCH, { type: 'order' });
        history.push(`${toOrderSearch}?type=${types.ORDER}`);
      },
    },
    // {
    //   type: CollectionIconsType.PACKAGE,
    //   onClick: () => {
    //     const toOrderSearch = injectPathParams(PATHS.USER_SEARCH, { type: 'package' });
    //     history.push(`${toOrderSearch}?type=${types.PACKAGE}`);
    //   },
    // },
    // {
    //   type: CollectionIconsType.INVENTORY,
    //   onClick: () => {
    //   },
    // },
    // {
    //   type: CollectionIconsType.DEVICE,
    //   onClick: () => {
    //   },
    // },
    // This one does not have svg
    // @TODO add svg for sensors
    // { type: CollectionIconsType.SENSORS onClick: () => {
    // }, },
  ];

  return (
    <Wrapper>
      <Text color={gray2} margin="35px 0 13px" fontSize="16px" lineHeight="32px" type={TextType.TEXT_12_GRAY}>
        {languageStrings.preFilterViewText || 'or look for Order'}
      </Text>
      <CardsWrapper>
        {cardsMapping.map((card) => (
          <Card onClick={card.onClick} key={card.type} type={card.type} />
        ))}
      </CardsWrapper>
    </Wrapper>
  );
};
