import styled from 'styled-components';
import { colorFetch } from '../../../../../../../styles/utils';
import { CommentsBlockProps } from './types';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${colorFetch('pureWhite')};
`;

export const RelativeWrapper = styled.div`
  position: relative;
`;

export const CommentsBlock = styled.div<CommentsBlockProps>`
  height: 228px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: ${({ offsetTop }) => (offsetTop ? 'hidden' : 'scroll')};
  display: flex;
  flex-direction: column;
  align-items: ${({ withComments }) => (withComments ? 'stretch' : 'center')};
  justify-content: ${({ withComments }) => (withComments ? 'flex-start' : 'center')};
  background-color: ${({ isActiveMode, theme }) =>
    isActiveMode ? colorFetch('pureWhite')({ theme }) : colorFetch('gray5')({ theme })};
  border: 1px solid
    ${({ isActiveMode, theme }) => (isActiveMode ? colorFetch('gray3')({ theme }) : colorFetch('gray5')({ theme }))};
  border-radius: 12px;
  padding: ${({ withComments }) => (withComments ? '0 20px 20px' : '0')};
  transition: background-color 0.3s linear;
`;
