import React, { useEffect, useState } from 'react';
// @ts-ignore
import styled from '@xstyled/styled-components';
import { useSelector } from 'react-redux';
import { OrderTable } from './components/orderTable/OrderTable';
import { media } from '../../../styles/media';
import { Title } from './components/title/Title';
import { LocationDropDown } from './components/locationDropDown/LocationDropDown';
import { FiltersModal } from '../../../components/filtersModal/FiltersModal';
import { AssetMapSvg } from './components/assetSvgMap/AssetMapSvg';
import { getExplorerInfo } from '../../../api/operation';
import { useResultSummaryData } from '../../../api/resultSummaryData';
import { Loader, LoaderType } from '../../../features/loader/Loader';
import { PlaceholderAssetMap } from './components/assetMapPlaceholder/PlaceholderAssetMap';
import { useParams } from 'react-router-dom';
import { FilterPackagesSection } from './components/filterPackagesSection/FilterPackagesSection';
import { useNotifierFunctions } from '../../../features/notifier2';
import { selectLanguage, selectLanguageStrings } from '../../../app/state/userSlice';
import { useFloorMapDetails } from '../../../api/floorMapDetails';

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  ${media.tablet`
    justify-content: center;
    margin-bottom: 10px;
  `}
  ${media.phone`
    justify-content: space-between;
  `}
`;

const ExtraWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${media.tablet`
    padding: 34px 15px 16px;
    border-bottom: 1px solid;
    border-color: gray3;
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 40px;
  overflow: hidden;
  position: relative;
  ${media.tablet`
    padding: 0;
    overflow: hidden;
  `}
`;

const WrapperFilterPackageMap = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
`;

const WrapperMapDropDown = styled.div`
  width: 100%;
`;

export const FloorMapDetails = () => {
  let { id } = useParams<{ id: string }>();
  const { addNotification } = useNotifierFunctions();
  const languageStrings = useSelector(selectLanguageStrings);
  const languageKey = useSelector(selectLanguage);
  const [isLoading, setIsLoading] = useState(true);
  // states for filter
  const [isClearFilterItems, setIsClearFilterItems] = useState(false);
  const [valuesFilter, setValuesFilter] = useState<any>([]);
  const [savedDataFilter, setSavedDataFilter] = useState<any>([]);
  const [isOpenFiltersModal, setIsOpenFiltersModal] = useState(false);
  // states for packages
  const [packageList, setPackageList] = useState<any>({ items: [] });
  const [isPackageListLoading, setIsPackageListLoading] = useState<boolean>(false);
  const [isPackageListLoaded, setIsPackageListLoaded] = useState<boolean>(false);
  // states for packages details modal
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [activeDevice, setActiveDevice] = useState<any>(null);

  const activeDefaultLevel =
    packageList?.available_locations?.[0]?.floors?.length && packageList?.available_locations?.[0]?.floors?.length > 1
      ? 1
      : 0;

  const [activeAddress, setActiveAddress] = useState(0);
  const [activeLevel, setActiveLevel] = useState(activeDefaultLevel);

  const [isLoadingImageMap, setIsLoadingImageMap] = useState(false);

  const [explorerRequestBody, setExplorerRequestBody] = useState({
    object_ids: [id],
    floorplan_id: '',
    building_id: '',
    mode_type: 'collection',
    mode_collection: 'orders',
    linkImage: '',
    filters: [],
  });

  const resultSummaryData = useResultSummaryData(setIsLoading, 'collection', 'orders', languageKey?.id);

  const buildingId = explorerRequestBody?.building_id
    ? explorerRequestBody?.building_id
    : packageList?.default_location?.building?.wpas_ref_building_id;

  const floorId = explorerRequestBody?.floorplan_id
    ? explorerRequestBody?.floorplan_id
    : packageList?.default_location?.floor?.wpas_ref_floor_id;

  const [dataLocationsLive, resetData] = useFloorMapDetails(
    buildingId,
    floorId,
    id,
    explorerRequestBody?.filters,
    addNotification,
    languageStrings,
  );

  useEffect(() => {
    const getData = async () => {
      const packageListData = await getExplorerInfo(
        explorerRequestBody,
        addNotification,
        languageStrings,
        setIsPackageListLoading,
        setIsPackageListLoaded,
        languageKey?.id,
      );
      setPackageList(packageListData);
    };

    getData();
  }, [explorerRequestBody?.filters]);

  const handleSetFilters = (data: any) => {
    setExplorerRequestBody((values: any) => {
      return { ...values, filters: data };
    });
    setIsOpenFiltersModal(false);
  };

  const isFilterEmpty = explorerRequestBody?.filters?.length === 0;

  if (isLoading) return <Loader type={LoaderType.CLASSIC} />;

  const handlePointFromMap = (packageId: string) => {
    // data active point(package) from map
    const activePoint =
      packageList?.items?.length > 0
        ? packageList?.items?.filter((pack: any) => pack?.content?.[0]?.value === packageId)
        : null;
    setActiveDevice(activePoint?.[0]?.content);
    setIsOpenDetailsModal(true);
  };

  return (
    <Wrapper>
      <ExtraWrapper>
        <HeaderWrapper>
          <Title />
          <OrderTable
            data={resultSummaryData}
            packageList={!!packageList?.items?.length ? packageList?.items?.length : 0}
          />
        </HeaderWrapper>

        {packageList?.items?.length === 0 && isFilterEmpty && !isPackageListLoading ? (
          <PlaceholderAssetMap />
        ) : (
          <WrapperFilterPackageMap>
            <FilterPackagesSection
              countActiveFilter={explorerRequestBody?.filters?.length}
              packagesData={packageList}
              isClearFilterItems={isClearFilterItems}
              handleFilterModal={() => setIsOpenFiltersModal(true)}
              setIsClearFilterItems={setIsClearFilterItems}
              setValuesFilter={setValuesFilter}
              setExplorerRequestBody={setExplorerRequestBody}
              isPackageListLoader={isPackageListLoading}
              isOpenDetailsModal={isOpenDetailsModal}
              setIsOpenDetailsModal={setIsOpenDetailsModal}
              activeDevice={activeDevice}
              setActiveDevice={setActiveDevice}
              setIsLoadingImageMap={setIsLoadingImageMap}
              explorerRequestBody={explorerRequestBody}
              handleClearWebsocketData={resetData}
              locations={packageList?.available_locations}
              setActiveAddress={setActiveAddress}
              setActiveLevel={setActiveLevel}
            />

            <WrapperMapDropDown>
              <LocationDropDown
                locations={packageList?.available_locations}
                setExplorerRequestBody={setExplorerRequestBody}
                setIsLoadingImageMap={setIsLoadingImageMap}
                handleClearWebsocketData={resetData}
                activeAddress={activeAddress}
                setActiveAddress={setActiveAddress}
                activeLevel={activeLevel}
                setActiveLevel={setActiveLevel}
              />
              <AssetMapSvg
                explorerRequestBody={explorerRequestBody}
                floorPlanImageLink={
                  explorerRequestBody?.linkImage
                    ? explorerRequestBody?.linkImage
                    : packageList?.default_location?.floor?.floor_image_url
                }
                isPackagesData={!!packageList?.items?.length}
                handlePointFromMap={handlePointFromMap}
                activeDevice={activeDevice}
                isPackageListLoaded={isPackageListLoaded}
                isLoadingImageMap={isLoadingImageMap}
                setIsLoadingImageMap={setIsLoadingImageMap}
                webSocketData={dataLocationsLive}
                availableLocations={packageList?.available_locations}
              />
            </WrapperMapDropDown>
          </WrapperFilterPackageMap>
        )}
      </ExtraWrapper>

      <FiltersModal
        isOpen={isOpenFiltersModal}
        setData={handleSetFilters}
        onCancel={() => setIsOpenFiltersModal(false)}
        data={packageList}
        isClearItems={isClearFilterItems}
        setIsClearItems={setIsClearFilterItems}
        setValuesFilter={setValuesFilter}
        valuesFilter={valuesFilter}
        savedDataFilter={savedDataFilter}
        setSavedDataFilter={setSavedDataFilter}
      />
    </Wrapper>
  );
};
