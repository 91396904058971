import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { colorFetch } from '../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';
import { media } from '../../../../../styles/media';
import { useStyleContext } from '../../../../../styles/style.context';

const Wrapper = styled.a`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
`;

const StyledText = styled(Text)`
  cursor: pointer;
  margin-right: 36px;
  color: ${colorFetch('blue2')};
  border-bottom: 1px solid;
  border-color: ${colorFetch('blue2')};
  ${media.tabletNavigation`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin-right: auto;
    & > svg > path {
      stroke: ${({ color }: any) => color};
    }
  `}
`;

export const PreviewItem = ({
  link,
  deviceId,
  name,
  address,
}: {
  deviceId?: string;
  link?: any;
  name: string;
  address?: string;
}) => {
  const theme = useTheme();
  const { blue4 } = theme.colors;

  const [node, setNode] = useState<any | null>(null);
  const [{ mediaType }] = useStyleContext();

  const linkRef = useCallback((currentNode) => {
    if (currentNode !== null) {
      setNode(currentNode);
    }
  }, []);

  useEffect(() => {
    if (node && mediaType?.monitorS) {
      const clickFunc = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        window.location.href = link;
        // window.open(link, '_blank');
      };
      node.addEventListener('touchstart', clickFunc, { passive: false });

      node.addEventListener('click', clickFunc);
      return () => {
        node.removeEventListener('touchstart', clickFunc);

        node.removeEventListener('click', clickFunc);
      };
    }
  }, [node, link]);

  return (
    // @TODO Need improve redirect for PREVIEW button (was need for backend)
    <Wrapper ref={linkRef} target={'_blank'} rel={'noopener noreferrer'} href={link}>
      <StyledText fontSize={'14px'} weight={'500'} lineHeight={'24px'} color={blue4} type={TextType.TEXT_14_BLACK}>
        {name}
      </StyledText>
    </Wrapper>
  );
};
