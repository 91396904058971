export const isZoneOverlap = (zoneCandidate: any, zones: any[]) => {
  const overlappedZones = zones.filter((zone) => {
    // Zone that we want to create
    const left_wall_1 = zoneCandidate.coordinates.center.x - zoneCandidate.coordinates.width / 2;
    const right_wall_1 = zoneCandidate.coordinates.center.x + zoneCandidate.coordinates.width / 2;
    const top_wall_1 = zoneCandidate.coordinates.center.y + zoneCandidate.coordinates.height / 2;
    const bot_wall_1 = zoneCandidate.coordinates.center.y - zoneCandidate.coordinates.height / 2;
    // Existing zones
    const left_wall_2 = zone.coordinates.center.x - zone.coordinates.width / 2;
    const right_wall_2 = zone.coordinates.center.x + zone.coordinates.width / 2;
    const top_wall_2 = zone.coordinates.center.y + zone.coordinates.height / 2;
    const bot_wall_2 = zone.coordinates.center.y - zone.coordinates.height / 2;

    // Checks if one zone is on the left/right side of the other
    // (overlap at x coord)
    const isHorisontalCollision = left_wall_1 < right_wall_2 && left_wall_2 < right_wall_1;

    // Checks if one zone is on the top/bottom side of the other
    // (overlap at y coord)
    const isVerticalCollision = bot_wall_1 < top_wall_2 && bot_wall_2 < top_wall_1;

    return isHorisontalCollision && isVerticalCollision;
  });

  const overlap = overlappedZones.length > 0;

  return {
    faultyIds: overlap ? [zoneCandidate.id, ...overlappedZones.map((overlappedZone) => overlappedZone.id)] : [],
  };
};

export const checkZoneCollision: any = (zones: any[], result: { faultyIds: string[] } = { faultyIds: [] }) => {
  if (zones.length < 2) {
    return result;
  }
  const currentOverlap = isZoneOverlap(zones[0], zones.slice(1));
  const combinedOverlap = {
    faultyIds: [...result.faultyIds, ...currentOverlap.faultyIds].filter((v, i, a) => a.indexOf(v) === i),
  };
  return checkZoneCollision(zones.slice(1), combinedOverlap);
};

export const isZoneOverflow = (zones: any[], bounds: { x: number; y: number }) => {
  const overflownZones = zones.filter((zone) => {
    const left_wall = zone.coordinates.center.x - zone.coordinates.width / 2;
    const right_wall = zone.coordinates.center.x + zone.coordinates.width / 2;
    const bot_wall = zone.coordinates.center.y + zone.coordinates.height / 2;
    const top_wall = zone.coordinates.center.y - zone.coordinates.height / 2;

    // Checks if one zone is on the left/right side of the bounds
    // (overflow at x coord)
    const isHorisontalOverflow = left_wall < 0 || right_wall > bounds.x;

    // Checks if one zone is on the top/bottom side of the bounds
    // (overflow at y coord)
    const isVerticalOverflow = top_wall < 0 || bot_wall > bounds.y;

    return isHorisontalOverflow || isVerticalOverflow;
  });

  return {
    faultyIds: overflownZones.map((overflownZone) => overflownZone.id),
  };
};

export const checkZoneOverflow: any = (
  zones: any[],
  bounds: { x: number; y: number },
  result: { faultyIds: string[] } = { faultyIds: [] },
) => {
  if (zones.length === 0) {
    return result;
  }
  const currentOverflow = isZoneOverflow(zones, bounds);
  const combinedOverflow = {
    faultyIds: [...result.faultyIds, ...currentOverflow.faultyIds].filter((v, i, a) => a.indexOf(v) === i),
  };
  return checkZoneOverflow(zones.slice(1), bounds, combinedOverflow);
};

export const checkZonePlacement: any = (
  zones: any[],
  bounds: { x: number; y: number },
  result: { faultyIds: string[] } = { faultyIds: [] },
) => {
  let currentOverlap = { faultyIds: [] as string[] };
  if (zones.length === 0) {
    return result;
  } else if (zones.length > 1) {
    currentOverlap = isZoneOverlap(zones[0], zones.slice(1));
  }
  const currentOverflow = isZoneOverflow(zones, bounds);
  const combinedResult = {
    faultyIds: [...result.faultyIds, ...currentOverflow.faultyIds, ...currentOverlap.faultyIds].filter(
      (v, i, a) => a.indexOf(v) === i,
    ),
  };
  return checkZoneOverflow(zones.slice(1), bounds, combinedResult);
};
