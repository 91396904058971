/**
 * @description
 * This is functions that are used in work with objects
 * (get value of object, set value of object)
 */

export const getValFromObj = (path: any, obj: any) =>
  path.reduce((accumulator: any, newVal: any) => {
    return accumulator && accumulator[newVal] ? accumulator[newVal] : null;
  }, obj);

// @TODO Alex test if this requires deep copy
export const setValInObj: any = (path: any, obj: any, value: any, index: number = 0) => {
  if (!(obj && obj[path[index]])) {
    return obj;
  } else if (index === path.length - 1) {
    return { ...obj, [path[index]]: value };
  } else {
    return { ...obj, [path[index]]: setValInObj(path, obj[path[index]], value, index + 1) };
  }
};

// @TODO Oleg - check this function
const obj = { key: { nameId: 'some id', nameLabel: 'display' }, value: 'some value' };
const newObj = setValInObj(['key', 'nameId'], obj, 'CHANGED');
