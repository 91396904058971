import React from 'react';
import styled from 'styled-components';
import { ArrowRightIcon } from './ArrowRightIcon';
import { ArrowLeftIcon } from './ArrowLeftIcon';
import { AddIcon } from './AddIcon';
import { MassageIcon } from './MassageIcon';
import { AttachIcon } from './AttachIcon';
import { DettachIcon } from './DettachIcon';
import { UploadIcon } from './UploadIcon';
import { ResetIcon } from './ResetIcon';
import { RevokeIcon } from './RevokeIcon';
import { PreviewIcon } from './PreviewIcon';

export enum ButtonIconType {
  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_RIGHT = 'ARROW_RIGHT',
  ADD = 'ADD',
  MASSAGE = 'MASSAGE',
  ATTACH = 'ATTACH',
  DETTACH = 'DETTACH',
  UPLOAD = 'UPLOAD',
  RESET = 'RESET',
  REVOKE = 'REVOKE',
  PREVIEW = 'PREVIEW',
}

type WrapperProps = {
  iconWithoutText?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  & > svg {
    width: 20px;
    height: 20px;
    margin-right: ${({ iconWithoutText }) => (iconWithoutText ? '0' : '4px')};
  }
`;

export const Icons = ({
  type,
  color,
  iconWithoutText,
}: {
  type?: ButtonIconType;
  color?: string;
  iconWithoutText?: boolean;
}) => {
  return (
    <Wrapper iconWithoutText={iconWithoutText}>
      {type === ButtonIconType.ARROW_LEFT && <ArrowLeftIcon />}
      {type === ButtonIconType.ARROW_RIGHT && <ArrowRightIcon />}
      {type === ButtonIconType.ADD && <AddIcon />}
      {type === ButtonIconType.MASSAGE && <MassageIcon />}
      {type === ButtonIconType.ATTACH && <AttachIcon />}
      {type === ButtonIconType.DETTACH && <DettachIcon />}
      {type === ButtonIconType.PREVIEW && <PreviewIcon />}
      {type === ButtonIconType.UPLOAD && <UploadIcon color={color} />}
      {type === ButtonIconType.RESET && <ResetIcon color={color} />}
      {type === ButtonIconType.REVOKE && <RevokeIcon color={color} />}
    </Wrapper>
  );
};
