const getIsNodes = (state: any) => state.nodes.length > 0;

const getIsAllDeviceSelect = (state: any) => {
  const deviceSelectState = state?.deviceState?.devicesFilter;
  let isAllDeviceSelect = true;
  for (const typeDevice in deviceSelectState) {
    if (!deviceSelectState[typeDevice].isSelected) {
      isAllDeviceSelect = false;
    }
  }
  return isAllDeviceSelect;
};

const getAllDeviceClear = (state: any) => {
  const deviceSelectState = state?.deviceState?.devicesFilter;
  let isAllDeviceClear = true;
  for (const typeDevice in deviceSelectState) {
    if (deviceSelectState[typeDevice].isSelected) {
      isAllDeviceClear = false;
    }
  }
  return isAllDeviceClear;
};

const getIsAllAttachSelect = (state: any) => {
  const attachSelectState = state?.attachState?.attachFilter;
  let isAllAttachSelect = true;
  for (const typeAttach in attachSelectState) {
    if (!attachSelectState[typeAttach].isSelected) {
      isAllAttachSelect = false;
    }
  }
  return isAllAttachSelect;
};

const getIsAllEntitySelect = (state: any) => {
  const entitySelectState = state?.entityState?.entityFilter;
  let isAllEntitySelect = true;
  for (const typeEntity in entitySelectState) {
    if (!entitySelectState[typeEntity].isSelected) {
      isAllEntitySelect = false;
    }
  }
  return isAllEntitySelect;
};

const getIsDisabledAttach = (state: any) => {
  const isNodes = getIsNodes(state);
  const isAllDeviceClear = getAllDeviceClear(state);
  return isNodes ? isAllDeviceClear : true;
};

const getIsDisabledEntity = (state: any) => {
  const isNodes = getIsNodes(state);
  const isSelectAttachState = state?.attachState?.attachFilter['ATTACHED']?.isSelected;
  const isAllDeviceClear = getAllDeviceClear(state);
  return isNodes ? isAllDeviceClear || !isSelectAttachState : true;
};

const getIsAllInventories = (inventoriesSelectState: any) =>
  inventoriesSelectState?.every((item: any) => item?.isSelected);

const getIsDisabledInventories = (state: any) => {
  const inventoriesFilter = state.inventoriesState.inventoriesFilter;
  const isInventories = inventoriesFilter.length > 0;
  const isNodes = getIsNodes(state);
  const isAllDeviceClear = getAllDeviceClear(state);

  return isInventories && isNodes ? isAllDeviceClear : true;
};

const getIsZones = (state: any) => state.zonesState.zones.length > 0;
const getIsActiveSwitchZones = (state: any) => state.zonesState.isZones;

export {
  getIsAllDeviceSelect,
  getIsNodes,
  getIsAllAttachSelect,
  getIsDisabledAttach,
  getIsDisabledEntity,
  getIsAllEntitySelect,
  getIsAllInventories,
  getIsDisabledInventories,
  getAllDeviceClear,
  getIsZones,
  getIsActiveSwitchZones,
};
