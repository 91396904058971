/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for Orders page
 * Highly customisable.
 *
 */

import { Text, TextType } from '@bit/first-scope.text';
import styled from 'styled-components';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectManualModal, setManualModal } from '../../../app/state/ordersTableSlice';
import { selectAccess, selectLanguageStrings } from '../../../app/state/userSlice';
import { API_URL, isTablet } from '../../../consts';
import { useTableFunctions } from '../../../features/universalTable/context';
import { InlineActionType, RowItemType } from '../../../features/universalTable/types';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import { injectPathParams, PATHS } from '../../../routes/paths';
import { View } from '../../../routes/routeInterfaces';
import { media } from '../../../styles/media';
import { useMediaType } from '../../../styles/style.context';
import { getRequiredDateFormat } from '../../../utils/date/date-format';
import { ManageTitle } from '../components/manageTitle/ManageTitle';
import { UploadOrderModal } from './components/uploadOrderModal/UploadOrderModal';
import { useBlockBodyScroll } from '../../../utils/useBlockBodyScroll';
import { ManualAddSingleOrderModal } from './components/manualAddSingleOrderModal/ManualAddSingleOrderModal';
import { useHistory } from '../../../utils/react-router-dom-abstraction';
import { useParams } from 'react-router-dom';
import { colorFetch } from '../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  ${media.tablet`
    padding: 60px 0 10px 0;
  `}
`;

type StyledTextProps = {
  completion?: number;
};

const StyledText = styled(Text)<StyledTextProps>`
  & > span {
    color: ${colorFetch('gray1')};
    &.normal {
      font-weight: normal;
    }
  }
  &.completion {
    color: ${({ completion, theme }) =>
      completion === 0
        ? colorFetch('secondary4')({ theme })
        : completion === 1
        ? colorFetch('primary')({ theme })
        : completion === 2
        ? colorFetch('red1')({ theme })
        : ''};
  }
`;

export interface OrdersProps {}

export const Orders: FC<OrdersProps> = () => {
  const history = useHistory();
  const params = useParams();
  const keyword = 'OrdersTable';
  const offsetKey = 'offset';

  const theme = useTheme();

  const { gray1 } = theme.colors;

  const { tablet, phone } = useMediaType();

  const accessData = useSelector(selectAccess).management.orders;

  const dispatch = useDispatch();

  const { setSearchValue, getSearchValue, resetTable } = useTableFunctions();
  const { actual: searchValue } = getSearchValue();

  const offset = params[offsetKey] || '0';

  // for add button in universal table
  const manualModal = useSelector(selectManualModal);

  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [isOpenManuallyAddSingleOrderModal, setIsOpenManuallyAddSingleOrderModal] = useState(false);

  const languageStrings = useSelector(selectLanguageStrings);

  // upload modal
  const handleOpenUploadModal = () => {
    if (isTablet) {
      dispatch(setManualModal(true));
    } else setIsOpenUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setIsOpenUploadModal(false);
  };

  // manual add order modal
  const handleOpenManuallyAddSingleOrderModal = () => {
    setIsOpenManuallyAddSingleOrderModal(true);
    !tablet && setIsOpenUploadModal(false);
  };

  const handleCloseManuallyAddSingleOrderModal = () => {
    setIsOpenManuallyAddSingleOrderModal(false);
    if (tablet || isTablet) {
      dispatch(setManualModal(false));
      setIsOpenManuallyAddSingleOrderModal(false);
    }
  };

  useEffect(() => {
    (isTablet || tablet) && setIsOpenManuallyAddSingleOrderModal(manualModal.isOpenManualModal);
  }, [manualModal, tablet, isTablet]);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  const handleCustomRowClick = (value: any) => {
    const to = injectPathParams(PATHS.MANAGE_ORDERS_DETAILS, {
      id: value.id,
      componentsOffset: 0,
      packagesOffset: 0,
      eventsOffset: 0,
    });
    to && history.push(to);
  };

  useBlockBodyScroll(isOpenUploadModal);

  return (
    <Wrapper>
      <ManageTitle
        keyword={keyword}
        title={languageStrings ? languageStrings.titleOfOrdersPage : 'Orders'}
        searchInputValue={searchValue}
        handleChangeSearchInputValue={setSearchValue}
        addButtonValue={languageStrings ? languageStrings.addButton : 'Add'}
        addButtonOnClick={handleOpenUploadModal}
        accessData={accessData}
      />
      <UniversalTable
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !tablet, countRows: phone ? 6 : 12 },
        }}
        offset={+offset}
        offsetKey={offsetKey}
        tablePath={View.MANAGE_ORDERS}
        mainApiUrl={`${API_URL}/v1/orders/`}
        keyword={keyword}
        additionalKeywords={['ManageLayoutSearch']}
        updateItems={() => {}}
        customRowClick={handleCustomRowClick}
        inlineActionSet={[
          {
            type: InlineActionType.DETAILS,
          },
        ]}
        pageSize={phone ? 6 : 18}
        isExport={accessData?.view}
        isAccess={accessData?.edit}
        columnStructure={[
          {
            fieldName: 'order_id',
            placeholder: '',
            label: languageStrings ? languageStrings.labelOrderID : 'Order ID',
            defaultValue: '',
            excludedFromAdd: true,
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <Text type={TextType.TEXT_14_GRAY} color={gray1} weight={'500'}>
                {value}
              </Text>
            ),
          },
          {
            fieldName: 'created_at',
            placeholder: '',
            label: languageStrings ? languageStrings.labelСreatedAt : 'Сreated at',
            defaultValue: '',
            excludedFromAdd: true,
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <StyledText type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
                {getRequiredDateFormat(value, 'DD.MM.YYYY')}
              </StyledText>
            ),
          },
          {
            fieldName: 'updated_at',
            placeholder: '',
            label: languageStrings ? languageStrings.labelLastUpdate : 'Last update',
            defaultValue: '',
            excludedFromAdd: true,
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <StyledText type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
                {value ? getRequiredDateFormat(value, 'DD.MM.YYYY, HH:mm') : 'N/A'}
              </StyledText>
            ),
          },
          {
            fieldName: 'metadata',
            placeholder: '',
            label: languageStrings ? languageStrings.labelShippingDeadline : 'Shipping Deadline',
            defaultValue: '',
            excludedFromAdd: true,
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <StyledText type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
                {getRequiredDateFormat(value.dt_deadline, 'DD.MM.YYYY')}
              </StyledText>
            ),
          },
          {
            fieldName: 'completion',
            placeholder: '',
            label: languageStrings ? languageStrings.labelCompletion : 'Completion',
            defaultValue: '',
            excludedFromAdd: true,
            type: RowItemType.CUSTOM,
            component: (value: any) => (
              <StyledText
                className="completion"
                completion={value.status}
                type={TextType.TEXT_14_GRAY}
                color={gray1}
                weight={'400'}
              >
                {`${value.value}%`}
              </StyledText>
            ),
          },
        ]}
      />
      <UploadOrderModal
        isOpenModal={isOpenUploadModal}
        handleCloseUploadModal={handleCloseUploadModal}
        handleOpenManuallyAddSingleOrderModal={handleOpenManuallyAddSingleOrderModal}
      />
      <ManualAddSingleOrderModal
        isOpenModal={isOpenManuallyAddSingleOrderModal}
        handleCloseManuallyAddSingleOrderModal={handleCloseManuallyAddSingleOrderModal}
      />
    </Wrapper>
  );
};
