import React from 'react';
import styled from 'styled-components';
import { ReactComponent as OrderIcon } from '../../assets/order.svg';
import { ReactComponent as PackageIcon } from '../../assets/package.svg';
import { ReactComponent as InventoryIcon } from '../../assets/inventory.svg';
import { ReactComponent as DeviceIcon } from '../../assets/devices.svg';
import { CollectionIconsType } from '../types';
// import { ReactComponent as SensorIcon } from '../../assets/sensor.svg';

type WrapperProps = {
  margin?: string;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  margin: ${({ margin }) => margin};

  & > svg {
    width: 48px;
    height: 48px;
  }
`;

export const CollectionIcons = ({ type, margin }: { type?: CollectionIconsType; margin?: string }) => {
  return (
    <Wrapper margin={margin}>
      {type === CollectionIconsType.ORDER && <OrderIcon />}
      {type === CollectionIconsType.PACKAGE && <PackageIcon />}
      {type === CollectionIconsType.INVENTORY && <InventoryIcon />}
      {type === CollectionIconsType.DEVICE && <DeviceIcon />}
      {/* {type === CollectionIconsType.SENSORS && <SensorIcon />} */}
    </Wrapper>
  );
};
