/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings manhole button on map require
 * Highly customisable.
 *
 */

import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ManholeInfo } from '../../components/manholeInfo/ManholeInfo';
import { ReactComponent as HouseImage } from '../../assets/Warehouse.svg';
import { ReactComponent as WarehouseHovered } from '../../assets/Warehouse_hovered.svg';
import { useTheme } from '@cthings.co/styles';
import { borderRadiusFetch } from '../../styles/utils';

export enum ManholesType {
  GREEN = 'GREEN',
  RED = 'RED',
  BLUE = 'BLUE',
  NONE = 'NONE',
}

const Wrapper = styled.div`
  transform: translate(-28px, -28px);
  display: flex;
  flex-direction: column;
  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  width: 56px;
  background-color: inherit;
  border: none;
  position: relative;
  padding: 0px;
  transition: all 0.3s ease;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

type ImageWrapperProps = {
  isOpen: boolean;
  firstLayerColor?: string;
  circleFill?: string;
  pathFill?: string;
};

const ImageWrapper = styled.div<ImageWrapperProps>`
  width: 48px;
  height: 48px;
  background-color: ${({ isOpen, firstLayerColor }) => (isOpen ? firstLayerColor : 'initial')};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: ${borderRadiusFetch('round')};
  transition: background-color 0.3s ease;
  & > svg > circle {
    fill: ${({ circleFill }) => circleFill};
    stroke: ${({ pathFill }) => pathFill};
  }
  & > svg > path {
    fill: ${({ pathFill }) => pathFill};
  }
`;

type HouseWrapperProps = {
  isHovered: boolean;
};

const HouseWrapper = styled.div<HouseWrapperProps>`
  width: 50px;
  height: 50px;
  position: relative;
  & > .common {
    position: absolute;
    left: 0;
    top: 0;
  }
  & > .hovered {
    opacity: ${({ isHovered }) => (isHovered ? '1' : '0')};
    transition: all 0.3s linear;
    position: absolute;
    left: -8px;
    top: -7px;
  }
`;

type ImageExtraWrapperProps = {
  isOpen: boolean;
  secondLayerColor?: string;
};

const ImageExtraWrapper = styled.div<ImageExtraWrapperProps>`
  position: relative;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isOpen, secondLayerColor }) => (isOpen ? secondLayerColor : 'initial')};
  box-sizing: border-box;
  border-radius: ${borderRadiusFetch('round')};
  transition: background-color 0.3s ease;
  &:hover,
  &:hover > ${ImageWrapper} {
    background-color: ${({ secondLayerColor }) => secondLayerColor};
  }
`;

const StyledManholeInfo = styled(ManholeInfo)`
  left: -23px;
  top: 11px;
  z-index: 10001;
  position: relative;
`;

export interface ManholeButtonProps {
  type: ManholesType;
  staticDisplay?: boolean;
  values?: any[];
  className?: string;
  isReq?: boolean;
  handleClick?: any;
}

export const ManholeButton: FC<ManholeButtonProps> = ({
  type,
  staticDisplay,
  values,
  isReq = false,
  handleClick,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const { red1, red2, primary, secondary1 } = theme.colors;

  useEffect(() => {
    isReq ? setIsHovered(true) : setIsHovered(false);
  }, [isReq]);

  let circleFill, pathFill, firstLayerColor, secondLayerColor;

  if (type === ManholesType.GREEN) {
    circleFill = primary;
    pathFill = secondary1;
    firstLayerColor = 'rgb(19, 107, 47, 0.25)';
    secondLayerColor = 'rgb(19, 107, 47, 0.2)';
  } else if (type === ManholesType.RED) {
    circleFill = red1;
    pathFill = red2;
    firstLayerColor = 'rgb(244, 68, 80, 0.25)';
    secondLayerColor = 'rgb(244, 68, 80, 0.2)';
  } else if (type === ManholesType.BLUE) {
    // circleFill = primary;
    // pathFill = secondary1;
    firstLayerColor = 'transparent';
    secondLayerColor = 'transparent';
  }

  const isInfoOpen = !staticDisplay && isReq;

  return (
    <Wrapper {...props}>
      <Button>
        <ImageExtraWrapper theme={theme} secondLayerColor={secondLayerColor} isOpen={isReq}>
          <ImageWrapper
            theme={theme}
            circleFill={circleFill}
            pathFill={pathFill}
            firstLayerColor={firstLayerColor}
            isOpen={isReq}
            onClick={handleClick}
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              if (!isReq) {
                setIsHovered(false);
              }
            }}
          >
            <HouseWrapper isHovered={isHovered}>
              <HouseImage className="common" />
              <WarehouseHovered className="hovered" />
            </HouseWrapper>
          </ImageWrapper>
        </ImageExtraWrapper>
      </Button>
      {isReq && (
        <div
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <StyledManholeInfo isOpen={isReq} isClicked={!staticDisplay && isInfoOpen} {...(values as any)[0]} />
        </div>
      )}
    </Wrapper>
  );
};

ManholeButton.defaultProps = {
  type: ManholesType.NONE,
  values: [
    {
      address: 'Brest, Belarus',
      coordinates: '5551.4444 7895.5854',
      temperature: '54',
      waterLevel: '5',
      date: '21.01.2020',
      time: '9:54',
    },
  ],
};
