import { API_URL } from '../consts';
import { HttpService } from '../utils/http';
import { isStringConsistOfSpaces } from '../utils/validation';

export const newSearchText = (
  text: string,
  searchMode: string,
  searchCollection: string,
  setSmartSearchList: any,
  handleResponse: (val: boolean) => void,
) => {
  const url = `${API_URL}/v1/smartsearch/operation_mode`;
  const isValidStr = !isStringConsistOfSpaces(text) && text.length > 0;
  (async () => {
    try {
      if (isValidStr) {
        handleResponse(true);
      }
      let responseData =
        text.length > 0 &&
        (await HttpService.post(url, { text: text, search_mode: searchMode, search_collection: searchCollection }))
          .data;
      const { payload } = responseData;

      if (payload) {
        handleResponse(false);
      }
      setSmartSearchList(payload);
    } catch (e) {
      handleResponse(false);
    }
  })();
};
