/**
 * @description
 * This is component for navigations
 * all the functionalities cthings require for breadcrumbs
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { Crumb } from './components/Crumb';

type WrapperProps = {
  margin: string;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  margin: ${({ margin }) => margin};
`;

export interface BreadcrumbsArrayItems {
  id: string;
  path: string;
  name: string;
  view: string;
}

interface BreadcrumbsProps {
  breadcrumbsArr: BreadcrumbsArrayItems[];
  className?: string;
  crumbColor?: string;
  separatorColor?: string;
  fontSize?: string;
  fontWeight?: string;
  separatorMargin?: string;
  margin?: string;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  breadcrumbsArr,
  crumbColor,
  separatorColor,
  fontSize,
  fontWeight,
  separatorMargin,
  margin,
  ...props
}) => {
  return (
    <Wrapper {...props} margin={margin ?? '18px 0'}>
      {breadcrumbsArr.map((item: BreadcrumbsArrayItems, index: number) => {
        return (
          <Crumb
            key={index}
            index={index}
            breadcrumbs={item}
            crumbColor={crumbColor}
            separatorColor={separatorColor}
            fontSize={fontSize}
            fontWeight={fontWeight}
            separatorMargin={separatorMargin}
            isNotLastItem={index < breadcrumbsArr.length - 1}
            {...props}
          />
        );
      })}
    </Wrapper>
  );
};
