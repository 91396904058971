import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { ReactComponent as DotsIcon } from '../../../../assets/dots.svg';
import { ItemFirstDesktopMenu } from './ItemFirstDesktopMenu';
import { useTooltip } from '../../../../components/tooltip';
import { Text, TextType } from '@bit/first-scope.text';
import { ArrowButton, ArrowButtonType } from '../../../../components/arrowButton/ArrowButton';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../app/state/userSlice';

const Wrapper = styled.div`
  padding-left: 18px;
  display: ${({ isActive }: any) => (isActive ? 'flex' : 'none')};
  position: relative;
`;

const Modal = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 12px 15px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 20px), -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 2px rgb(40 41 61 / 4%), 0 4px 8px rgb(96 97 112 / 16%);
  pointer-events: none;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease;
  min-width: 135px;
  pointer-events: auto;
`;

const StyledItemFirstDesktopMenu = styled(ItemFirstDesktopMenu)`
  padding-left: 0;
  margin-bottom: 12px;
`;

const StyledText = styled(Text)`
  margin-left: 10px;
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const IconArrowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledArrowIcon = styled(ArrowButton)`
  width: 5px;
  height: 10px;
`;

export interface ItemMoreTypes {
  isActive: boolean;
  menuItemArray: any;
  isOpenNav: boolean;
}

export const ItemMore: FC<ItemMoreTypes> = ({ isActive, menuItemArray, isOpenNav }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tooltipIds] = useTooltip(menuItemArray.length);
  const theme = useTheme();
  const { black } = theme.colors;
  const modalRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);
  const languageStrings = useSelector(selectLanguageStrings);

  const clickHandler = useCallback(
    (e: any) => {
      if (isOpen && !modalRef.current?.contains(e.target)) {
        setIsOpen(false);
      } else if (wrapperRef.current?.contains(e.target)) {
        setIsOpen(!isOpen);
      }
    },
    [isOpen, wrapperRef],
  );

  useEffect(() => {
    window.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, [clickHandler]);

  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Wrapper isActive={isActive} ref={wrapperRef}>
      <TitleRow>
        <DotsIcon onClick={handleOpenModal} />
        {isOpenNav && (
          <IconArrowContainer>
            <StyledText type={TextType.TEXT_14_BLACK} color={black}>
              {languageStrings.moreLabel}
            </StyledText>
            <StyledArrowIcon type={isOpen ? ArrowButtonType.LEFT : ArrowButtonType.RIGHT} onClick={handleOpenModal} />
          </IconArrowContainer>
        )}
      </TitleRow>
      {isOpen && (
        <Modal ref={modalRef}>
          {menuItemArray.map((item: any, index: number) => {
            const { value, route, type, childRoutes, pathForActive, isAccessView } = item;

            return isAccessView ? (
              <StyledItemFirstDesktopMenu
                key={index}
                type={type}
                value={value}
                to={route}
                isOpen={isOpen}
                tooltipId={tooltipIds[index]}
                childRoutes={childRoutes ? childRoutes : null}
                pathForActive={pathForActive ? pathForActive : null}
                dd
              />
            ) : null;
          })}
        </Modal>
      )}
    </Wrapper>
  );
};
