/**
 * @description
 * This is component that covers
 * the container for modal component.
 *
 */

import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/media';
import { borderRadiusFetch, colorFetch, shadowFetch } from '../../styles/utils';

type WrapperProps = {
  backgroundColor?: string;
  padding?: string;
  width?: string;
  maxHeight?: number;
};

const Wrapper = styled.div<WrapperProps>`
  max-width: ${({ width }) => width || 'auto'};
  width: 100%;
  max-height: ${({ maxHeight }: any) => `${maxHeight}px`};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${({ backgroundColor, theme }) => backgroundColor || colorFetch('pureWhite')({ theme })};
  box-shadow: ${shadowFetch('additionalShadow1')};
  border-radius: ${borderRadiusFetch('additional16')};
  position: relative;
  padding: ${({ padding }) => padding || '24px 14px'};
  ${media.tablet`
    max-height: 650px;
    max-width: 100%;
    border-radius: ${borderRadiusFetch('additional16')} ${borderRadiusFetch('additional16')} 0 0;
    padding: 24px 16px 30px 16px;
  `}
  ${media.phone`
    max-height: 94%;
  `}
`;

export interface ModalContainerProps {
  children: ReactNode;
  fullHeight?: number;
  backgroundColor?: string;
  padding?: string;
  width?: string;
  className?: string;
}

export const ModalContainer: FC<ModalContainerProps> = ({
  children,
  fullHeight,
  backgroundColor,
  padding,
  width,
  ...props
}) => {
  const maxHeight = fullHeight ? fullHeight - 30 : undefined;

  return (
    <Wrapper width={width} maxHeight={maxHeight} padding={padding} backgroundColor={backgroundColor} {...props}>
      {children}
    </Wrapper>
  );
};

ModalContainer.defaultProps = {
  width: '480px',
};
