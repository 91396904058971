import React, { FC } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../app/state/userSlice';
import { colorFetch } from '../../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

const ActiveOrderBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colorFetch('gray1')};
  border-radius: 6px 6px 0px 0px;
  padding: 8px 12px;
  box-sizing: border-box;
`;

const GoToOrderBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorFetch('gray1')};
  background-color: ${colorFetch('pureWhite')};
  border: 1px solid;
  border-color: ${colorFetch('gray4')};
  border-top: none;
  border-radius: 0px 0px 6px 6px;
  cursor: pointer;
  padding: 8px 0;
  box-sizing: border-box;
`;

export interface ActiveOrderProps {
  orderId: string;
  onGoToOrderClick: any;
}

export const ActiveOrder: FC<ActiveOrderProps> = ({ orderId, onGoToOrderClick }) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <Wrapper>
      <ActiveOrderBlock>
        <Text type={TextType.TEXT_14_WHITE}>{languageStrings ? languageStrings.labelOrder : 'Order'}</Text>
        <Text weight={'600'} type={TextType.TEXT_14_WHITE}>
          {orderId ? orderId : 'SCD90341'}
        </Text>
      </ActiveOrderBlock>
      <GoToOrderBlock onClick={onGoToOrderClick}>
        <Text type={TextType.TEXT_14_BLACK} color={black1}>
          {languageStrings ? languageStrings.goToOrder : 'Go to order'}
        </Text>
      </GoToOrderBlock>
    </Wrapper>
  );
};
