import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { CloseButton } from '@bit/first-scope.close-button';
import { borderRadiusFetch, colorFetch } from '../../../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

type WrapperProps = {
  isEditInProcess?: boolean;
  isFieldScrollable?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: max-content;
  height: 22px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: ${({ isEditInProcess, theme }) =>
    isEditInProcess ? colorFetch('gray5')({ theme }) : colorFetch('pureWhite')({ theme })};
  padding: 4px 8px;
  border-radius: ${borderRadiusFetch('primary')};
  margin: ${({ title }) => (title ? '6px 6px 0 0' : '2px 6px 4px 0')};
  &:last-child {
    margin: ${({ title, isFieldScrollable }) =>
      title ? '6px 6px 0 0' : isFieldScrollable ? '2px 6px 2px 0' : '2px 6px 4px 0'};
  }
`;

export const Cheap = ({
  value,
  closeOnClick,
  isFieldGrowed,
  isFieldScrollable,
  title,
  isEditInProcess,
}: {
  value: string;
  closeOnClick: () => void;
  isFieldGrowed?: boolean;
  isFieldScrollable?: boolean;
  title?: string;
  isEditInProcess?: boolean;
}) => {
  const theme = useTheme();
  const { gray1, gray2 } = theme.colors;

  const handleCheap = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Wrapper
      theme={theme}
      onClick={handleCheap}
      isFieldScrollable={isFieldScrollable}
      title={title}
      isEditInProcess={isEditInProcess}
    >
      <Text type={TextType.TEXT_12_GRAY} color={gray1} margin={'0 6px 0 0'} weight={'500'}>
        {value}
      </Text>
      {title && isEditInProcess && (
        <CloseButton onClick={closeOnClick} color={gray2} width={'12px'} height={'12px'} strokeWidth={'2px'} />
      )}
    </Wrapper>
  );
};
