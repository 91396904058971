import styled from 'styled-components';
import React, { FC } from 'react';
import { useHistory } from '../../../utils/react-router-dom-abstraction';
import { BreadcrumbsArrayItems } from '../Breadcrumbs';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div``;

type ItemProps = {
  fontSize: string;
  fontWeight: string;
  color: string;
};

const Item = styled.span<ItemProps>`
  font-family: 'Poppins';
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 32px;
  color: ${({ color }) => color};
  cursor: pointer;
  border-bottom: ${({ isBorderBottom }: any) => (isBorderBottom ? '1px solid' : null)};
  border-color: ${({ color }: any) => color};
`;

type SeparatorProps = {
  color: string;
  margin: string;
};

const Separator = styled.span<SeparatorProps>`
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
`;

interface CrumbsProps {
  breadcrumbs: BreadcrumbsArrayItems;
  isNotLastItem: boolean;
  crumbColor?: string;
  separatorColor?: string;
  fontSize?: string;
  fontWeight?: string;
  separatorMargin?: string;
  index?: number;
}

export const Crumb: FC<CrumbsProps> = ({
  breadcrumbs,
  isNotLastItem,
  crumbColor,
  separatorColor,
  fontSize,
  fontWeight,
  separatorMargin,
  index,
}) => {
  const theme = useTheme();
  const { gray2, gray3 } = theme.colors;
  const history = useHistory();

  const { name, path } = breadcrumbs;

  const routeToHandle = () => {
    path && history.push(path);
  };

  return (
    <Wrapper>
      <Item
        onClick={routeToHandle}
        fontSize={fontSize ?? '14px'}
        fontWeight={fontWeight ?? '400'}
        color={crumbColor ?? gray2}
        isBorderBottom={index === 0}
      >
        {name}
      </Item>
      {isNotLastItem ? (
        <Separator color={separatorColor ?? gray3} margin={separatorMargin ?? '0 5px'}>
          {'/'}
        </Separator>
      ) : null}
    </Wrapper>
  );
};
