import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../../../../styles/media';
import { getRequiredDateFormat } from '../../../../../utils/date/date-format';
import { InfoContentTable } from './infoContentTable/InfoContentTable';
import { Text, TextType } from '@bit/first-scope.text';
import { Wifi } from '../../../../../components/wifi/Wifi';
import { useGetTypes } from '../../../../../api/manageInventories';
import { withLoader } from '../../../../../features/placeholderComponent/loaderFunctions';
import { PlaceholderType } from '../../../../../components/placeholders/typePlaceholders/placeholdersType';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 100%;
  ${media.desktop`
    min-width: 288px;
    margin-top: 36px;
  `}
`;

enum ValueType {
  BATTERY = 'BATTERY',
  LINK_QUALITY = 'LINK_QUALITY',
}

const SecondTableInfoPlain = ({
  type,
  data = [],
  headerLabels,
  headerGridTemplate,
  isAttach,
  language,
}: {
  data: any;
  headerLabels: any;
  headerGridTemplate: any;
  type: string;
  isAttach: boolean;
  language?: any;
}) => {
  const theme = useTheme();
  const { red1, orange1, green1, gray1, additionalGreen1 } = theme.colors;

  const { seen_nodes, seen_tags, last_update, battery_lvl, attached_info, tag_status } = data;
  const offset = attached_info?.order?.order_details_pagination_offset;
  const inventoriesTypes: any = useGetTypes();
  const inventoryType =
    inventoriesTypes && inventoriesTypes[0].find((val: any) => val.id === attached_info?.inventory?.inventory_type_id);

  const getStatus = (status: number) => <Wifi status={status} state={undefined} fontWeight={'500'} />;

  const getStatusOfBattery = (value: any, type?: ValueType) => {
    const data_status = {
      very_low: { color: red1, text: language ? language.veryLowStatus : 'Very Low' },
      low: { color: orange1, text: language ? language.lowStatus : 'Low' },
      normal: { color: additionalGreen1, text: language ? language.normalStatus : 'Normal' },
      very_good: { color: green1, text: language ? language.veryGoodStatus : 'Very Good' },
      default: { color: gray1, text: 'N/A' },
    };

    const current_property =
      value <= 19 ? 'very_low' : value <= 39 ? 'low' : value <= 79 ? 'normal' : value <= 100 ? 'very_good' : 'default';

    return (
      <Text type={TextType.TEXT_14_GRAY} color={data_status[current_property].color} weight={'500'}>
        {type === ValueType.BATTERY && current_property !== 'default'
          ? `${value}%`
          : data_status[current_property].text}
      </Text>
    );
  };

  const attachedArray = [
    {
      property: language ? language.labelBatteryLevel : 'Battery Level',
      value: battery_lvl ? getStatusOfBattery(battery_lvl, ValueType.BATTERY) : 'N/A',
    },
    { property: language ? language.labelStatus : 'Status', value: getStatus(tag_status) },
    {
      property: language ? language.labelLastReport : 'Last Report',
      value: last_update ? getRequiredDateFormat(last_update, `DD.MM.YYYY [at] HH:mm`) : 'N/A',
    },
    {
      property: language ? language.labelLinkQuality : 'Link quality',
      value: battery_lvl ? getStatusOfBattery(battery_lvl) : 'N/A',
    },
    { property: language ? language.labelSeenNodes : 'Seen Nodes', value: String(seen_nodes) },
    { property: language ? language.labelSeenTrackers : 'Seen Trackers', value: String(seen_tags) },
  ];

  const attachedInventoriesArray = [
    {
      property: language ? language.labelInventoryName : 'Inventory name',
      value: attached_info?.inventory?.name,
    },
    { property: language ? language.labelInventoryType : 'Inventory type', value: inventoryType?.name },
  ];

  const [structureTable, setStructureTable] = useState(attachedArray);

  useEffect(() => {
    if (attached_info?.inventory) {
      setStructureTable(attachedArray.concat(attachedInventoriesArray));
    } else {
      setStructureTable(() => attachedArray);
    }
  }, [isAttach, data.attached_info, language]);

  return (
    <Wrapper>
      <InfoContentTable
        type={type}
        placeholderProps={{
          keyword: 'OrderInfoTable',
          style: { width: '100%', padding: '3px', boxSizing: 'border-box', borderRadius: '8px', overflow: 'hidden' },
          placeholderOptions: { countRows: 6, withoutPagination: true },
        }}
        headerLabels={headerLabels}
        headerGridTemplate={headerGridTemplate}
        arr={structureTable}
        offset={offset}
      />
    </Wrapper>
  );
};

export const SecondTableInfo = withLoader(undefined, PlaceholderType.CUSTOM_TABLE)(SecondTableInfoPlain);
