import React from 'react';
import { useSelector } from 'react-redux';
import { API_URL } from '../../../consts';
import { media } from '../../../styles/media';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  ActionType,
  InlineActionType,
  RowItemType,
  TableCustomComponentState,
} from '../../../features/universalTable/types';
import { Text, TextType } from '@bit/first-scope.text';
import { View } from '../../../routes/routeInterfaces';
import { selectAccess } from '../../../app/state/userSlice';
import { useMediaType } from '../../../styles/style.context';
import { ManageTitle } from '../../management/components/manageTitle/ManageTitle';
import { EXTRA_KEY, useTableFunctions, withTableContextProvider } from '../../../features/universalTable/context';
import { UniversalTable } from '../../../features/universalTable/UniversalTable';
import { MetaDocuments } from './components/metaDocuments/MetaDocuments';
import { PreviewItem } from './components/previewItem/PreviewItem';
import { useComponentsListInternalDoc } from '../../../api/manageComponents';
import { CopyLinkInput } from '../../../components/copyLinkInput/CopyLinkInput';
import { updateDocument, deleteDocument } from '../../../api/manageDocuments';
import { QrCodeSection } from '../../../components/qrCodeSection/QrCodeSection';
import { Components } from './components/components/Components';
import { selectLanguageStrings } from '../../../app/state/userSlice';
import { IndexMultiSelect } from './components/indexMultiSelect/IndexMultiSelect';
import { LinksMultiSelect } from './components/linksMultiSelect/LinksMultiSelect';
import { PasswordInput } from './components/passwordInput/PasswordInput';
import { useCustomHistory } from '../../../utils/react-router-dom-abstraction';
import { colorFetch } from '../../../styles/utils';
import { useTheme } from '@cthings.co/styles';
import { ReactComponent as Eye } from '../../../assets/Eye.svg';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  ${media.tablet`
    padding: 60px 0 10px 0;
  `}
`;

const StyledTextLink = styled(Text)`
  &.link {
    text-decoration: underline;
    cursor: pointer;
  }
` as any; // @TODO fix type

type StyledTextProps = {
  completion?: number;
};

const StyledText = styled(Text)<StyledTextProps>`
  & > span {
    color: ${colorFetch('gray1')};
    &.normal {
      font-weight: normal;
    }
  }
  &.completion {
    color: ${({ completion, theme }) =>
      completion === 0
        ? colorFetch('secondary4')({ theme })
        : completion === 1
        ? colorFetch('primary')({ theme })
        : completion === 2
        ? colorFetch('red1')({ theme })
        : ''};
  }
  ${media.tablet`
    padding: 8px 12px;
  `}
`;

export interface ExternalDocumentationNFCProps {}

export interface typeDataDocuments {
  name: string;
  size: number;
}

export const ExternalDocumentationNFC: React.FC<ExternalDocumentationNFCProps> = () => {
  const { startAddProcess, setSearchValue, getSearchValue } = useTableFunctions();
  const { actual: searchValue } = getSearchValue();
  const mediaType = useMediaType();
  const keyword = 'StagesTable';
  const offsetKey = 'offset';
  const userData = useSelector(selectAccess);
  const accessData = useSelector(selectAccess)?.management?.external_documents;
  const { client_id } = userData as any;
  const params = useParams();
  const offset = params[offsetKey] || '0';
  const { state }: any = useLocation();
  const theme = useTheme();
  const { gray1 } = theme.colors;
  const language = useSelector(selectLanguageStrings);
  const indexValues = useComponentsListInternalDoc();
  const customHistory = useCustomHistory();

  const handleDelete = (id: string[], deleteDoc: any, deleteCheckedDocuments: any, handleAddNotification: Function) => {
    deleteDocument(id, deleteDoc, deleteCheckedDocuments, handleAddNotification, language);
  };

  return (
    <Wrapper>
      <ManageTitle
        keyword={keyword}
        title={language ? language.titleExternal : 'External documents'}
        addButtonValue={language ? language.addExternal : 'Add'}
        searchInputValue={searchValue}
        addButtonOnClick={startAddProcess}
        handleChangeSearchInputValue={setSearchValue}
        accessData={accessData}
      />
      <UniversalTable
        placeholderProps={{
          keyword,
          placeholderOptions: { borderRadiusTable: !mediaType.tablet, countRows: mediaType.phone ? 6 : 12 },
        }}
        offset={+offset}
        offsetKey={offsetKey}
        tablePath={View.USER_DOCUMENTATION_NFC}
        // This is prop for add scroll in bodyWrapper for modal system
        isScrollBodyModalSystem
        updateItems={(
          id: string,
          data: {
            name: string;
            metadata: any;
            client_id: any;
            files: any;
            attached_component: any;
            attached_components: any;
            certificates: any;
            certificates_ids: any;
            attached_order: any;
            password_protected: any;
            password: any;
          },
          index: number,
          updateLocalItem: any,
          handleAddNotification: Function,
          languageStrings: any,
          setBlockButtons: (status: boolean) => void,
        ) => {
          updateDocument(id, data, index, updateLocalItem, handleAddNotification, client_id, language, setBlockButtons);
        }}
        deleteItems={handleDelete}
        allowDelete
        accessData={accessData}
        isAccess={accessData?.edit}
        allowPreview={true}
        additionalKeywords={['ManageLayoutSearch']}
        actionSet={[{ type: ActionType.EDIT }]}
        columnStructureTriggers={[indexValues.length]}
        mobileActionSectionGridWidth={'0.7fr'}
        inlineActionSet={[
          {
            type: InlineActionType.CUSTOM,
            component: (data: any) => (
              <PreviewItem
                link={data?.preview_url}
                name={!mediaType.tabletNavigation ? language.externalPreview : <Eye />}
              />
            ),
          },
          { type: InlineActionType.DETAILS },
        ]}
        idItemForViewModal={state?.state?.id}
        mainApiUrl={`${API_URL}/v2/documents/`}
        keyword={keyword}
        pageSize={mediaType.phone ? 6 : 18}
        semiTitleDeleteModal={
          language
            ? language.externalSemiTitleDeleteModal
            : 'The documentation for this product will be permanently deleted and the links will become invalid. You will not be able to reassign other documentation to those links.'
        }
        columnStructure={[
          {
            fieldName: 'name',
            placeholder: language ? language.externalNamePlaceholder : 'Enter name',
            label: language ? language.externalLabelName : 'Name',
            defaultValue: '',
          },
          {
            fieldName: 'attached_order',
            placeholder: '',
            label: language ? language.externalLabelOrderID : 'Order ID',
            defaultValue: { name: '', id: '' },
            type: RowItemType.CUSTOM,
            pathToSelectItems: [`${EXTRA_KEY}available`, 'attached_component'],
            customComponentHasSelectView: true,
            component: (value: any, state?: TableCustomComponentState, setValue?: any) => {
              return (
                <Components
                  stateComponent={state}
                  setValue={setValue}
                  value={value}
                  disabled={state === TableCustomComponentState.VIEW_MODAL}
                />
              );
            },
          },
          {
            fieldName: 'attached_component',
            placeholder: '',
            label: language ? language.externalLabelIndex : 'Index',
            defaultValue: [],
            type: RowItemType.CUSTOM,
            pathToSelectItems: [`${EXTRA_KEY}available`, 'attached_component'],
            customComponentHasSelectView: true,
            conditionForValidationInCustom: 'not valid',
            modalColumns: 2,
            component: (value: any, state?: TableCustomComponentState, setValue?: any, values?: any) => {
              return value && indexValues && indexValues.length > 0 ? (
                <IndexMultiSelect
                  stateIndex={state}
                  setValue={setValue}
                  value={value}
                  values={indexValues}
                  disabled={state === TableCustomComponentState.VIEW_MODAL}
                  language={language}
                />
              ) : (
                <StyledText type={TextType.TEXT_14_GRAY} color={gray1} weight={'400'}>
                  {'N/A'}
                </StyledText>
              );
            },
          },
          {
            fieldName: 'attached_component',
            placeholder: '',
            label: language ? language.externalLabelIndexDescription : 'Index Description',
            defaultValue: [],
            type: RowItemType.CUSTOM,
            pathToSelectItems: [`${EXTRA_KEY}available`, 'attached_component'],
            excludedFromAdd: true,
            excludedFromModalView: true,
            modalColumns: 2,
            component: (value: any) => {
              return (
                <StyledTextLink
                  type={TextType.TEXT_14_GRAY}
                  color={gray1}
                  weight={'400'}
                  className={'link'}
                  onClick={() => {
                    customHistory.push(View.MANAGE_COMPONENTS, {
                      pathParams: { offset: value[0]?.offset },
                      state: { id: value[0]?.id },
                    });
                  }}
                >
                  {value && indexValues.length ? value[0]?.description_1 : 'N/A'}
                </StyledTextLink>
              );
            },
          },
          {
            fieldName: 'files',
            placeholder: '',
            label: language ? language.externalLabelDocuments : 'Documents',
            defaultValue: { oldItems: [], newItems: [] },
            modalColumns: 2,
            type: RowItemType.CUSTOM,
            conditionForValidationInCustom: 'not valid',
            component: (value: any, state?: TableCustomComponentState, setValue?: any) => {
              return state && state === TableCustomComponentState.VIEW ? (
                <StyledText
                  className="completion"
                  completion={value.status}
                  type={TextType.TEXT_14_GRAY}
                  color={gray1}
                  weight={'400'}
                >
                  {value
                    ? `${value.oldItems ? value.oldItems.length + value.newItems.length : value.length} 
                    ${
                      language
                        ? value.length === 0 || value.length >= 5
                          ? language.externalLabelDocumentmoreThanFive
                          : value.length >= 2
                          ? language.externalLabelDocumentmoreThanTwo
                          : language.externalLabelDocument
                        : 'document'
                    }`
                    : 'N/A'}
                </StyledText>
              ) : (
                <MetaDocuments values={value} state={state} setValue={setValue} />
              );
            },
          },
          {
            fieldName: 'qr_code_url',
            placeholder: '',
            label: '',
            defaultValue: '',
            modalColumns: 2,
            excludedFromAdd: true,
            excludedFromView: true,
            type: RowItemType.CUSTOM,
            component: (value: any) => <QrCodeSection value={value} />,
          },
          {
            fieldName: 'certificates',
            placeholder: '',
            label: language ? language.externalLabelLinks : 'Links',
            defaultValue: [],
            type: RowItemType.CUSTOM,
            pathToSelectItems: [`${EXTRA_KEY}available`, 'attached_component'],
            customComponentHasSelectView: true,
            conditionForValidationInCustom: 'not valid',
            excludedFromView: true,
            modalColumns: 2,
            component: (value: any, state?: TableCustomComponentState, setValue?: any, values?: any) => {
              return <LinksMultiSelect stateIndex={state} setValue={setValue} value={value} language={language} />;
            },
          },
          {
            fieldName: 'link_field_url',
            placeholder: '',
            label: language.externalLabelLink,
            defaultValue: '',
            modalColumns: 2,
            excludedFromAdd: true,
            excludedFromView: true,
            type: RowItemType.CUSTOM,
            component: (value: any) => <CopyLinkInput link={value} />,
          },
          {
            fieldName: 'password',
            placeholder: '',
            label: language ? language.password : 'Password',
            defaultValue: '',
            modalColumns: 2,
            excludedFromView: true,
            type: RowItemType.CUSTOM,
            conditionForValidationInCustom: 'not valid',
            pathToSelectItems: [`${EXTRA_KEY}available`, 'attached_component'],
            customComponentHasSelectView: true,
            component: (value: any, state?: TableCustomComponentState, setValue?: any) => (
              <PasswordInput
                disabled={state === TableCustomComponentState.VIEW_MODAL}
                state={state}
                setValue={setValue}
                value={value}
              />
            ),
          },
        ]}
      />
    </Wrapper>
  );
};

export const DocumentationNFC = withTableContextProvider(ExternalDocumentationNFC);
