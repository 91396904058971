import styled from 'styled-components';
import { media } from '../../../styles/media';
import { SearchBar } from '../../../components/searchBar/SearchBar';
import { CommonButton } from '@bit/first-scope.common-button';
import { SelectLevel } from '../../../components/selectLevel/SelectLevel';
import { ReactComponent as LocationIcon } from '../../../assets/location-secondary.svg';
import { Text } from '@bit/first-scope.text';
import { colorFetch } from '../../../styles/utils';

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 45px;
  overflow: hidden;
  ${media.tablet`
  position: relative;
    padding: 173px 0 45px 0;
  `}
`;

export const Header = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${colorFetch('gray5')};
  ${media.tablet`
    position: fixed;
    top: 42px;
    z-index: 10;
    padding: 34px 16px 26px 16px;
    border-bottom: 1px solid;
    border-color: ${colorFetch('gray3')};
  `}
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${media.tablet`
   display: grid;
   grid-template-columns: 32px 1fr;
   grid-template-rows: repeat(2,1fr);
   grid-gap: 10px 0;
   justify-content: stretch;
   align-items: stretch;
  `}
`;

export const BackButton = styled(CommonButton)`
  ${media.tablet`
  `}
`;

export const Title = styled(Text)`
  user-select: none;
  ${media.tablet`
    font-size: 20px;
    margin: 0;
  `}
`;

export const NameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 14px;
  ${media.tablet`
    justify-content: center;
     margin: 0;
  `}
`;

export const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > svg > path {
    transition: all 0.3s linear;
  }
  &:hover {
    & > svg > path {
      stroke: ${colorFetch('gray3')};
    }
    & > span {
      color: ${colorFetch('gray1')};
    }
  }
`;

export const ListButton = styled(CommonButton)`
  min-width: 160px;
  min-height: 40px;
  font-size: 14px;
  color: ${colorFetch('gray1')};
  background-color: ${colorFetch('pureWhite')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  margin: 0 10px 0 10px;
  &:hover {
    background-color: ${colorFetch('pureWhite')};
    color: ${colorFetch('gray1')};
    border: 1px solid;
    border-color: ${colorFetch('gray3')};
  }
  & > svg {
    margin-right: 8px;
    & > path {
      stroke: #555770;
    }
  }
  ${media.tablet`
    width: 210px;
    margin: 0;
  `}
  ${media.phone`
    min-width: 167px;
    margin: 0;
  `}
`;

export const SearchBarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 24px 0 40px 0;
  ${media.tablet`
    flex-direction: column-reverse;
    align-items: center;
    margin: 10px 0 0 0;
  `}
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  ${media.tablet`
    width: 100%;
    justify-content: center;
    gap: 10px;
  `}
`;

export const AddressWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${media.tablet`
    grid-area: 2/2/auto/ span 2;
    justify-content: center;
  `}
`;

export const AddressIcon = styled(LocationIcon)`
  margin-right: 6px;
  ${media.tablet`
    display: none;
  `}
`;

export const RefWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet`
    display: none;
  `};
`;

export const StyledSearchBar = styled(SearchBar)`
  max-width: 535px;
  ${media.tablet`
    display: none;
    width: 100%;
    & > div > div {
      width: 100%;
    }
  `}
` as any; // @TODO fix type

export const StyledSelectLevel = styled(SelectLevel)`
  ${media.tablet`
      width: 210px;
      margin-left: 0;
  `};
  ${media.phone`
      width: 167px;
  `};
`;

export const ZonesActionButtonWrapper = styled.div`
  margin-left: auto;
`;

export const ZonesActionButton = styled(CommonButton)`
  min-height: 40px;
  width: 160px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  & > button:nth-child(2) {
    margin-left: 10px;
  }
`;
