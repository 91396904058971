/**
 * @description
 * This is row for draggable table
 * all the functionalities cthings require for TableRow
 * Highly customisable.
 *
 */

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../../styles/media';
import { ReactComponent as ArrowImage } from '../../../assets/Shape.svg';
import { getRequiredDateFormat } from '../../../utils/date/date-format';
import { colorFetch } from '../../../styles/utils';

type WrapperProps = {
  first?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: ${colorFetch('pureWhite')};
  box-sizing: border-box;
  padding: 13px 24px;
  border-top: ${({ first }) => (!first ? `1px solid` : 'none')};
  border-color: ${colorFetch('gray3')};
  ${media.phone`
    padding: 11px 18px 11px 16px;
  `};
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 76px 170px 1fr;
  grid-column-gap: 26px;
  grid-template-rows: 1fr;
  ${media.tablet`
    grid-template-columns: 76px 130px 1fr;
  `};
`;

const MarkWrapper = styled.div``;

const Mark = styled.p`
  color: ${colorFetch('gray1')};
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  ${media.tablet`
    &.problem {
      display: none;
    }
  `}
  &.isOpen {
    color: ${colorFetch('black1')};
  }
  &.problem {
    color: ${colorFetch('red2')} !important;
  }
`;

type ArrowContainerProps = {
  isOpenRow?: boolean;
};

const ArrowContainer = styled.div<ArrowContainerProps>`
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transform: ${({ isOpenRow }) => (isOpenRow ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
  cursor: pointer;
  & > svg > path {
    fill: ${colorFetch('black1')};
  }
  & > svg:hover > path {
    fill: ${colorFetch('black1')};
    transition: fill 0.3s ease;
  }
  ${media.tablet`
    display: flex;
  `}
`;

type EventWrapperProps = {
  isOpenRow?: boolean;
};

const EventWrapper = styled.div<EventWrapperProps>`
  max-width: 1180px;
  width: 100%;
  box-sizing: border-box;
  display: ${({ isOpenRow }) => (isOpenRow ? 'flex' : 'none')};
  background-color: ${colorFetch('gray6')};
  color: ${colorFetch('red2')};
  padding: 10px 16px;
  border-top: 0.5px solid;
  border-color: ${colorFetch('gray3')};
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
`;

export interface TableRowProps {
  value: any;
  first?: boolean;
  className?: string;
}

export const TableRow: FC<TableRowProps> = ({ value, first, ...props }) => {
  const [isOpenRow, setIsOpenRow] = useState(false);
  const place = `${value.address.city}, ${value.address.line1}`;
  const date = getRequiredDateFormat(value.ts, 'DD.MM.YYYY HH:mm');

  return (
    <>
      <Wrapper first={first} {...props}>
        <Container>
          <MarkWrapper>
            <Mark>{date}</Mark>
          </MarkWrapper>
          <MarkWrapper>
            <Mark>{place}</Mark>
          </MarkWrapper>
          <MarkWrapper>
            <Mark className="problem">{value.event_message}</Mark>
          </MarkWrapper>
          <ArrowContainer onClick={() => setIsOpenRow(!isOpenRow)} isOpenRow={isOpenRow}>
            <ArrowImage />
          </ArrowContainer>
        </Container>
      </Wrapper>
      <EventWrapper isOpenRow={isOpenRow}>{value.event_message}</EventWrapper>
    </>
  );
};

TableRow.defaultProps = {};
