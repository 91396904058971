import { API_URL } from '../consts';
import { useApi } from '../features/apiBuilder/useApiBuilder';
import { defaultData } from './dashboardData';

export const useNewDashboards = (type: 'summary' | 'orders' | 'inventories', keywords: string[]) => {
  const [data] = useApi({
    defaultValue: defaultData,
    url: `${API_URL}/v1/dashboards/static/${type}`,
    keywords,
    transformResponse: (data) => data.payload.data,
  });

  return data;
};
