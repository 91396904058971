/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings for textarea with autosize require
 * Highly customisable.
 *
 */

import React from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { media } from '../../styles/media';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';

type TextareaProps = {
  isFocused?: boolean;
  isError?: boolean;
  isImage?: boolean;
};

const Textarea = styled(TextareaAutosize)<TextareaProps>`
  width: 100%;
  height: 100%;
  font-family: Poppins, sans-serif;

  box-sizing: border-box;
  background-color: ${colorFetch('pureWhite')};
  color: ${colorFetch('gray1')};
  ::placeholder {
    font-family: 'Poppins', sans-serif;
    color: ${colorFetch('gray2')};
  }
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid
    ${({ disabled, isFocused, isError, theme }) =>
      disabled
        ? colorFetch('gray5')({ theme })
        : isFocused
        ? colorFetch('primary')({ theme })
        : isError
        ? colorFetch('red1')({ theme })
        : colorFetch('gray3')({ theme })};
  border-radius: ${borderRadiusFetch('primary')};
  padding: ${({ isImage }) => (isImage ? '8px 36px 8px 38px' : '8px 36px 8px 12px')};
  outline: none;
  resize: none;
  transition: border 0.3s linear;

  &.disabled {
    background-color: ${colorFetch('gray5')} !important;
    -webkit-safari-color: ${colorFetch('gray1')};
    color: ${colorFetch('gray1')} !important;
    -webkit-text-fill-color: currentcolor;
    &::placeholder {
      -webkit-safari-color: ${colorFetch('gray1')};
      color: ${colorFetch('gray1')};
    }
  }
  -webkit-text-fill-color: currentcolor;
  opacity: 1;
  ${media.tablet`
    -webkit-appearance: caret;
    -moz-appearance: caret; /* mobile firefox too! */
`}
`;

export const TextareaModal = ({
  value,
  placeholder,
  onChange,
  onClick,
  onFocus,
  onBlur,
  isFocused,
  isError,
  disabled,
  isImage,
  className,
  rows,
}: {
  value: string;
  placeholder: string;
  onChange: () => void;
  onClick: () => void;
  onFocus: () => void;
  onBlur: () => void;
  isFocused?: boolean;
  isError?: boolean;
  disabled?: boolean;
  isImage?: boolean;
  className?: string;
  rows?: number;
}) => {
  return (
    <Textarea
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      isFocused={isFocused}
      isError={isError}
      disabled={disabled}
      isImage={isImage}
      className={disabled ? 'disabled' : className ? className : ''}
      maxRows={rows}
    />
  );
};
