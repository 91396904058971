/**
 * @description
 * This is an advanced component that covers
 * all the functionalities FloorPlan.
 * Highly customisable.
 *
 */

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import x from '../../assets/x.svg';
import plus from '../../assets/plus.svg';
import { sizes } from '../../styles/media';
import minus from '../../assets/minus.svg';
import useResizeObserver from 'use-resize-observer';
import { usePrevious } from '../../utils/usePrevious';
import { OnlineStatusType } from '../clustering/types';
import { useDispatch } from 'react-redux';
import { useClustering } from '../clustering/functions';
// @ts-ignore
import { MapInteractionCSS } from 'react-map-interaction';
import styled from 'styled-components';
import { Loader, LoaderType } from '../../features/loader/Loader';
import { useBlockBodyScroll } from '../../utils/useBlockBodyScroll';
import { useManageZonesDetails } from '../../api/manageZonesDetails';
import { MobileFilters } from './components/mobileFilters/MobileFilters';
import { ClusterEntity } from './components/clusterEntity/ClusterEntity';
import { useCustomHistory, useHistory } from '../../utils/react-router-dom-abstraction';
import { useStyleContext, useWindowSize } from '../../styles/style.context';
import { PopupFloorMobile } from './components/popupFloorMobile/PopupFloorMobile';
import { PopupFloor } from '../floorplanViewport/components/popupFloor/PopupFloor';
import { ImageContainer, MapInteraction, Image, ZoomToolsContainer, ToolsButton } from './styled';
import { useFilters } from '../../containers/common/campusMapInsight/Content/filterContext/сontext';
import {
  TypeOfDeviceParameter,
  TypeOfAttachParameter,
  TypeEntity,
} from '../../containers/common/campusMapInsight/components/FiltersBlock/initialFilterState/initialFilterState';
import { useMobileFilters } from '../../containers/common/campusMapInsight/Content/mobileFilterContext/mobileContext';
import { LiveZone } from '../../features/zonesFloorplan/components/liveZone/LiveZone';
import { useLocation } from 'react-router-dom';
import { PATHS } from '../../routes';
import { injectPathParams } from '../../routes/paths';

const MapIntCSS = styled(MapInteractionCSS)`
  width: 100%;
  height: 100%;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`;

const Wrapper = styled.div``;

const minScale = 0.1;
const maxScale = 20;

const upperStep = 1.7;
const lowerStep = (1 - minScale) / ((maxScale - 1) / upperStep);

const getScale = (scale: number, coefficient: number) => {
  const result =
    scale < 1 && scale > 0
      ? scale + coefficient * lowerStep > minScale
        ? scale + coefficient * lowerStep
        : minScale
      : scale + coefficient * upperStep > minScale
      ? scale + coefficient * upperStep < maxScale
        ? scale + coefficient * upperStep
        : maxScale
      : scale < minScale
      ? minScale
      : scale + coefficient * lowerStep;
  return result;
};

const DEFAULT_VIEWPORT = { scale: 1, translation: { x: 0, y: 0 } };
export interface FloorplanViewportType {
  image: { src: string; width: number; height: number };
  floorData: any;
  locations: any;
  setImageDimensions: any;
  height: string;
  pathParams: any;
  isOpenModal: boolean;
  closeModal: any;
  setCountDevice: (value: number) => void;
}

export interface Viewport {
  scale: number;
  translation: { x: number; y: number };
}

export const FloorplanViewport: FC<FloorplanViewportType> = ({
  image,
  locations,
  setImageDimensions,
  height,
  pathParams,
  isOpenModal,
  closeModal,
  floorData,
  setCountDevice,
}) => {
  const { state, dispatch } = useFilters();
  const { zonesState } = state;
  const { isZones, zones } = zonesState;
  const widthView = useWindowSize()[0];
  const isMobileSize = widthView < sizes.phone;
  const { stateMobile, dispatchMobile } = useMobileFilters();
  const { zones: zonesMobile, isZones: isZonesMobile } = stateMobile?.zonesState;
  const zonesValue = isMobileSize ? zonesMobile : zones;
  const isZonesValue = isMobileSize ? isZonesMobile : isZones;
  const stateValue = isMobileSize ? stateMobile : state;
  const { state: stateRoute }: any = useLocation();
  const { search } = useLocation();
  const isValmontFilterState = new URLSearchParams(search).get('isValmontFilterState');
  const history = useHistory();

  const zoomToolsContainerRef: any = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dispatchMobile({ type: 'ADD_NODES', payload: locations });
  }, [locations]);

  const [viewport, setViewport] = useState<Viewport>(DEFAULT_VIEWPORT);

  const [popupInfo, setPopupInfo] = useState<any>({
    content: {},
    isOpen: false,
    top: null,
    left: null,
  });

  const { scale } = viewport;
  const searchName = pathParams.name;

  const [{ mediaType }] = useStyleContext();
  const customHistory = useCustomHistory();
  const ref: any = useRef<any>(null);
  const imageRef: any = useRef<any>(null);
  const WrapperFloorMapRef: any = useRef<any>(null);

  const containerSize: any = useResizeObserver({ ref });
  const imageSize: any = useResizeObserver({ ref: imageRef });
  const prevImageSize = usePrevious(imageSize);

  const [onLoadImage, setOnLoadImage] = useState<boolean>(false);
  const [mainRatio, setMainRatio] = useState<number>(0);

  const [wrapperNode, setWrapperNode] = useState<any>(null);
  const dispatchRedux = useDispatch();

  const [scaledLocations] = useManageZonesDetails(
    floorData?.floors[0]?.floor_id,
    () => {},
    () => {},
    imageSize.height,
    imageSize.width,
  );

  useEffect(() => {
    dispatch({ type: 'ADD_ZONES', payload: scaledLocations });
    dispatchMobile({ type: 'ADD_ZONES', payload: scaledLocations });
  }, [scaledLocations]);

  const wrapperRef = useCallback((currentNode) => {
    if (currentNode !== null) {
      setWrapperNode(currentNode.firstChild.firstChild);
    }
  }, []);

  useEffect(() => {
    const { width, height } = imageSize;
    if (width && height && (width !== prevImageSize?.width || height !== prevImageSize?.height)) {
      setImageDimensions({ width, height });
    }
  }, [imageSize.width, imageSize.height]);

  useEffect(() => {
    image.width &&
      image.height &&
      containerSize.width &&
      containerSize.height &&
      setMainRatio(image.width / image.height / (containerSize.width / containerSize.height));
  }, [image.width, image.height, containerSize.width, containerSize.height]);

  const dataLevel = useMemo(() => {
    const countTrackers =
      locations &&
      locations?.buildings[0].floors &&
      locations?.buildings[0].floors[0].nodes.reduce((a: any, el: any): number => {
        return el.base_role === 1 ? ++a : a;
      }, 0);
    return { number: 0, countTrackers: countTrackers };
  }, [locations]);

  const nodes = locations?.buildings[0].floors[dataLevel.number].nodes;

  const filterDevice = (nodes: any, stateFiltersDevice: any) => {
    const { deviceState } = stateFiltersDevice;
    return (
      nodes &&
      nodes.filter((node: any) => {
        if (node.online_status === OnlineStatusType.OFFLINE) {
          return deviceState?.devicesFilter[TypeOfDeviceParameter.OFFLINE].isSelected;
        } else if (node.base_role === 2 || node.base_role === 4) {
          return deviceState?.devicesFilter[TypeOfDeviceParameter.NODE].isSelected;
        }
        return deviceState?.devicesFilter[TypeOfDeviceParameter.TRACKER].isSelected;
      })
    );
  };

  const filteredDevice = filterDevice(nodes, stateValue);

  const filterAttach = (nodes: any, stateFiltersAttach: any) => {
    const { attachState } = stateFiltersAttach;
    return (
      filteredDevice &&
      filteredDevice.filter((node: any) => {
        if (node.attached_info) {
          return attachState?.attachFilter[TypeOfAttachParameter.ATTACHED].isSelected;
        } else if (!node.attached_info) {
          return attachState?.attachFilter[TypeOfAttachParameter.DETACHED].isSelected;
        }
        return (
          attachState?.attachFilter[TypeOfAttachParameter.ATTACHED].isSelected &&
          attachState?.attachFilter[TypeOfAttachParameter.DETACHED].isSelected
        );
      })
    );
  };

  const filteredAttachDetach = filterAttach(nodes, stateValue);

  const filterEntity = (stateFiltersEntity: any) => {
    const { entityState } = stateFiltersEntity;
    return (
      filteredAttachDetach &&
      filteredAttachDetach.filter((node: any) => {
        if (node.attached_info?.package) {
          return entityState?.entityFilter[TypeEntity.PACKAGE].isSelected;
        } else if (node.attached_info?.inventory) {
          return entityState?.entityFilter[TypeEntity.INVENTORY].isSelected;
        }
        return true;
      })
    );
  };

  const filteredEntity = filterEntity(stateValue);

  useEffect(() => {
    setCountDevice(filteredEntity ? filteredEntity.length : 0);
  }, [filteredEntity]);

  const { filteredClusters } = useClustering(nodes ? filteredEntity : [], scale, 25, locations?.last_updated_at);

  const foundPointFromCluster = useCallback(
    (name: string) => {
      const foudPackageInLocation =
        locations &&
        locations?.buildings[0].floors[dataLevel.number].nodes.filter((elem: any) => elem.name === searchName);

      const isPackageInLocation = foudPackageInLocation && foudPackageInLocation.length > 0;

      const pointIndex =
        locations && locations?.buildings[0].floors[dataLevel.number].nodes
          ? locations?.buildings[0].floors[dataLevel.number].nodes.findIndex((el: any) => el.name === name)
          : -1;

      const data =
        pointIndex !== -1
          ? locations && locations?.buildings[0].floors[dataLevel.number].nodes[pointIndex]
          : searchName !== 'noselection'
          ? foudPackageInLocation
          : {};

      const pointData =
        pointIndex !== -1
          ? {
              id: data?.device_db_id !== undefined ? data.device_db_id : null,
              name: data?.name !== undefined ? data.name : null,
              isHaveProblems: data?.problems_detected !== undefined ? data.problems_detected : null,
              packageName: data?.attached_info?.package?.name !== undefined ? data.attached_info.package.name : null,
              deviceId: data?.id !== undefined ? data.id : null,
              lastUpdate: data?.last_update !== undefined ? data.last_update : null,
              package: data?.attached_info?.package?.name !== undefined ? data.attached_info.package.name : null,
              order_id:
                data?.attached_info?.package?.order_info?.id !== undefined
                  ? data.attached_info.package.order_info.id
                  : null,
              order_name:
                data?.attached_info?.package?.order_info?.order_id !== undefined
                  ? data.attached_info.package.order_info.order_id
                  : null,
              package_id: data?.attached_info?.package?.id !== undefined ? data.attached_info.package?.id : null,
              roleName: data?.positioning_role_name !== undefined ? data?.positioning_role_name : null,
              buildingId: locations?.buildings[0]?.id !== undefined ? locations.buildings[0].id : null,
              component_id:
                data?.attached_info?.order_info?.component_info?.id !== undefined
                  ? data.attached_info.order_info.component_info.id
                  : null,
              component_index:
                data?.attached_info?.package?.component_index !== undefined
                  ? data.attached_info.package.component_index
                  : null,
              production_id:
                data?.attached_info?.package?.production_id !== undefined
                  ? data.attached_info.package.production_id
                  : null,
              inventoryName:
                data?.attached_info?.inventory?.name !== undefined ? data.attached_info.inventory.name : null,
              inventory_id: data?.attached_info?.inventory?.id !== undefined ? data.attached_info.inventory.id : null,
              inventoryType:
                data?.attached_info?.inventory?.inventory_type_name !== undefined
                  ? data.attached_info.inventory.inventory_type_name
                  : null,
              attached_info: data?.attached_info !== undefined ? data.attached_info : null,
            }
          : {};

      if (pointIndex !== -1) {
        setPopupInfo((prev: any) => {
          return { ...prev, content: pointData, isOpen: true };
        });

        const toGoToLocation = injectPathParams(PATHS.USER_INSIGHTS_DETAILS, {
          id: pathParams.id,
          name: data.name,
        });

        history.replace(!!isValmontFilterState ? `${toGoToLocation}?isValmontFilterState=true` : toGoToLocation);
      }

      if (searchName !== 'noselection' && isPackageInLocation) {
        setPopupInfo((prev: any) => {
          return { ...prev, content: pointData, isOpen: true };
        });
      }
    },
    [dataLevel.number, locations, history, pathParams.id, searchName],
  );

  useEffect(() => {
    onLoadImage && searchName && foundPointFromCluster(searchName);
  }, [onLoadImage, searchName, locations]);

  const zoomCalc = useCallback(
    (coefficient: number, options?: { center?: { x: number; y: number } }) => {
      setViewport((val) => {
        const { scale, translation } = val;
        const { x, y } = translation;
        const newScale = getScale(scale, coefficient);
        const center = (options &&
          options.center && {
            x: x + options.center.x * scale,
            y: y + options.center.y * scale,
          }) || { x: containerSize.width / 2, y: containerSize.height / 2 };
        const scaleRatio = newScale / scale;
        const xToCenter = center.x - x;
        const yToCenter = center.y - y;
        const newXOffset = xToCenter * scaleRatio;
        const newYOffset = yToCenter * scaleRatio;
        const newX = center.x - newXOffset;
        const newY = center.y - newYOffset;

        return {
          translation: { x: newX, y: newY },
          scale: newScale,
        };
      });
    },
    [containerSize.width, containerSize.height],
  );

  const zoomIn = useCallback(() => {
    if (popupInfo.isOpen) {
      zoomCalc(1.2, { center: { x: popupInfo.left, y: popupInfo.top } });
    } else {
      zoomCalc(1.2);
    }
  }, [zoomCalc, popupInfo]);

  const zoomOut = useCallback(() => {
    zoomCalc(-1.2);
  }, [zoomCalc]);

  const resetTransform = () => {
    setViewport(DEFAULT_VIEWPORT);
  };

  useEffect(() => {
    if (wrapperNode) {
      const pseudoFunc = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const deltaCoefficient = e.deltaY / 120;
        zoomCalc(deltaCoefficient);
      };
      wrapperNode.addEventListener('wheel', pseudoFunc, { passive: false });
      return () => wrapperNode.removeEventListener('wheel', pseudoFunc);
    }
  }, [wrapperNode, zoomCalc]);

  useBlockBodyScroll((popupInfo.isOpen && mediaType.tablet) || (isOpenModal && mediaType.tablet));

  // useEffect(() => {
  //   zonesValue && dispatchRedux(createZoneColors(zonesValue.length));
  // }, [scaledLocations]);

  const ratio = imageSize.width / floorData?.floors[0]?.floor_image_width;
  const pixel_ratio = (floorData?.floors[0]?.floor_pixel_per_meter | 35) * ratio;

  return (
    <Wrapper ref={WrapperFloorMapRef}>
      <MapInteraction height={height} ref={wrapperRef}>
        <ZoomToolsContainer ref={zoomToolsContainerRef}>
          <ToolsButton onClick={zoomIn}>
            <img src={plus} alt="plus" />
          </ToolsButton>
          <ToolsButton onClick={zoomOut}>
            <img src={minus} alt="minus" />
          </ToolsButton>
          <ToolsButton onClick={resetTransform}>
            <img src={x} alt="cross" />
          </ToolsButton>
        </ZoomToolsContainer>
        <MapIntCSS value={viewport} onChange={(value: Viewport) => setViewport(value)} maxScale={20}>
          <ImageContainer ref={ref}>
            <Image
              src={image.src}
              alt={'Floorplan'}
              ref={imageRef}
              onLoad={() => setOnLoadImage(true)}
              ratio={mainRatio}
            />

            {filteredClusters.map((value: any, index: number) => (
              <ClusterEntity
                key={index}
                pointData={value}
                scale={scale}
                dimensionRatio={mainRatio}
                containerDimensions={containerSize}
                imageDimensions={imageSize}
                withPulsation={popupInfo.isOpen && value.ids.includes(pathParams.name)}
                setPopupInfo={setPopupInfo}
                history={customHistory}
                id={pathParams.id}
                zoomIn={(x: number, y: number) => zoomCalc(1, { center: { x, y } })}
              />
            ))}
            {isZonesValue && onLoadImage && nodes
              ? zonesValue.map((value: any, index: number) => (
                  <LiveZone
                    pointData={value}
                    ratio={ratio}
                    scale={scale}
                    dimensionRatio={mainRatio}
                    containerDimensions={containerSize}
                    imageDimensions={imageSize}
                    handleDragEnd={(e: any, c: any, x: number, y: number, sourceRef: any) => {}}
                    handleDragStart={() => {}}
                    innerOffset={''}
                    onClick={(e: any, left: any) => {}}
                    activeId={''}
                    isDraggable={false}
                  />
                ))
              : null}
          </ImageContainer>
        </MapIntCSS>
        {popupInfo.isOpen && !mediaType.tablet && (
          <PopupFloor
            setPopupInfo={setPopupInfo}
            popupInfo={popupInfo}
            zoomToolsContainerRef={zoomToolsContainerRef}
            wrapperFloorMapRef={WrapperFloorMapRef}
            pathParams={pathParams}
          />
        )}
        {popupInfo.isOpen && mediaType.tablet && (
          <PopupFloorMobile popupInfo={popupInfo} setPopupInfo={setPopupInfo} pathParams={pathParams} />
        )}

        <MobileFilters isOpenModal={isOpenModal} closeModal={closeModal} />
      </MapInteraction>
      {!nodes && (
        <LoaderWrapper>
          <Loader type={LoaderType.CLASSIC} />
        </LoaderWrapper>
      )}
    </Wrapper>
  );
};
