import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal } from '@bit/first-scope.modal';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { media } from '../../../../../styles/media';
import { useNotifierFunctions } from '../../../../../features/notifier2';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../app/state/userSlice';
import { CommonButton } from '@bit/first-scope.common-button';
import { Text, TextType } from '@bit/first-scope.text';
import { FieldsType, isValidField } from '../../../../../utils/validation';
import { InputModal } from '../../../../../components/inputModal/InputModal';
import { DatePicker } from '../datePicker/DatePicker';
import { CalendarType } from '../../../../../components/calendar/Calendar';
import { AddComponent } from './components/components/AddComponent';
import { createOrder } from '../../../../../api/manageOrders';
import moment from 'moment';
import { AddComponentMobileView } from './components/mobile/AddComponentMobileView';
import { useMediaType } from '../../../../../styles/style.context';
import { debounce } from '../../../../../utils/throttling';
import { borderRadiusFetch, colorFetch } from '../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';
import { isClasp, isTablet } from '../../../../../consts';
import { ls } from '@cthings.co/utils';
import { displayViewType } from '../../../../../features/universalTable/components/modalSystem/ModalSystem';

const StyledModal = styled(Modal)`
  & > div {
    ${media.phone`
      width: 100%;
    `}
  }

  &.tablet {
    align-items: start;
    justify-content: start;
    padding: 40px;
  }
`;

const StyledModalContainer = styled(ModalContainer)`
  max-width: 780px;
  align-items: flex-start;
  padding: 24px;
  width: 100%;
  ${media.phone`
    width: 100%;
    position: fixed;
    bottom: 0;
    border-radius: ${borderRadiusFetch('primary')} ${borderRadiusFetch('primary')} 0 0;
    padding: 24px 16px;
  `}
`;

const Title = styled(Text)`
  font-size: 18px;
`;

const ItemsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}
  ${media.phone`
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 12px;
  `}
`;

const StyledInput = styled(InputModal)`
  width: 230px;
  ${media.tablet`
      width: 100%;
      margin-bottom: 4px;
  `}
`;

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  margin: 0 0 4px 6px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${colorFetch('gray1')};
`;

const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ViewportSwitcher = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 400;
`;

const StyledDatePicker = styled(DatePicker)`
  ${media.phone`
    & > div > .react-datepicker-popper {
      z-index: 9;
    }
  `}
`;

const ComponentsWrapper = styled.div`
  width: 100%;
  ${media.phone`
    margin-top: 12px;
  `}
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

type ButtonProps = {
  withMargin: boolean;
};

const Button = styled(CommonButton)<ButtonProps>`
  margin: ${({ withMargin }) => (withMargin ? '0 16px 0 0' : '0')};
  opacity: ${({ disabled }) => (disabled ? '0.1' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

// function for buttons
const getButtons = ({
  handleCloseManuallyAddSingleOrderModal,
  handleConfirm,
  languageStrings,
  theme,
}: {
  handleCloseManuallyAddSingleOrderModal: () => void;
  handleConfirm: () => void;
  // @TODO remove any types
  languageStrings: any;
  theme: any;
}) => {
  const {
    colors: { pureWhite, black1, primary },
  } = theme;

  return [
    {
      value: languageStrings?.cancelButton || 'Cancel',
      onClick: handleCloseManuallyAddSingleOrderModal,
      width: '104px',
      height: '32px',
      backgroundColor: pureWhite,
      backgroundColorHover: primary,
      borderColorHover: primary,
      color: black1,
      withMargin: true,
      disabled: false,
    },
    {
      value: languageStrings?.saveButton || 'Save',
      onClick: debounce(handleConfirm, 2000),
      width: '104px',
      height: '32px',
      backgroundColor: primary,
      backgroundColorHover: primary,
      borderColorHover: primary,
      color: pureWhite,
    },
  ];
};

export enum OrderRowType {
  CUSTOM = 'CUSTOM',
  INPUT_CUSTOM = 'INPUT_CUSTOM',
  INPUT = 'INPUT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  JOINED_SELECT = 'JOINED_SELECT',
  DATE_PICKER = 'DATE_PICKER',
}

export interface ComponentType {
  component_id: string;
  components_list: { id: string; name: string; index: string }[] | never[];
  component_value: { id: string; name: string; index: string } | {};
  index: string;
  productionId: string;
  quantity: number | null;
}

interface ManualAddSingleOrderModalProps {
  isOpenModal: boolean;
  handleCloseManuallyAddSingleOrderModal: () => void;
}

export const ManualAddSingleOrderModal: FC<ManualAddSingleOrderModalProps> = ({
  isOpenModal,
  handleCloseManuallyAddSingleOrderModal,
}) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  const { phone } = useMediaType();

  const { addNotification } = useNotifierFunctions();

  const languageStrings = useSelector(selectLanguageStrings);

  const [blockButtons, setBlockButtons] = useState(false);

  const [validatingIsStarted, setValidatingIsStarted] = useState(false);
  const [isEditingInProcess, setIsEditingInProcess] = useState(false);
  const [isComponentFieldsValid, setIsComponentFieldsValid] = useState(false);

  const [orderIdValue, setOrderIdValue] = useState('');
  const [startDateValue, setStartDateValue] = useState(new Date());
  const [deadlineDateValue, setDeadlineDateValue] = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  const [customerValue, setCustomerValue] = useState('');
  const [componentsValue, setComponentsValue] = useState<ComponentType[]>([]);
  const isValmontTabletView = !isClasp && isTablet;
  const getDisplayView = ls.get('displayView');
  const [displayView, setDisplayView] = useState(getDisplayView || displayViewType.DESKTOP_VIEW);

  const contents = componentsValue.map((item: ComponentType) => {
    const { component_value, index, productionId, quantity } = item;

    const { id }: any = component_value;

    return {
      component_id: id,
      generic_id: index,
      production_id: productionId,
      quantity: quantity,
    };
  });

  const changeDisplayViewHandle = useCallback(() => {
    if (displayView === displayViewType.DESKTOP_VIEW) {
      setDisplayView(displayViewType.TABLET_VIEW);
      ls.set('displayView', displayViewType.TABLET_VIEW);
    } else {
      setDisplayView(displayViewType.DESKTOP_VIEW);
      ls.set('displayView', displayViewType.DESKTOP_VIEW);
    }
  }, [displayView]);

  useEffect(() => {
    if (!isOpenModal) {
      setOrderIdValue('');
      setStartDateValue(new Date());
      setDeadlineDateValue(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
      setCustomerValue('');
      setComponentsValue([]);
    }
  }, [isOpenModal]);

  useEffect(() => {
    new Date(startDateValue) >= new Date(deadlineDateValue)
      ? setDeadlineDateValue(new Date(startDateValue.getTime() + 24 * 60 * 60 * 1000))
      : setDeadlineDateValue(deadlineDateValue);
  }, [deadlineDateValue, startDateValue]);

  useEffect(() => {
    !!getDisplayView && setDisplayView(getDisplayView);
  }, [getDisplayView]);

  useEffect(() => {
    if (!isValmontTabletView) {
      ls.remove('displayView');
      setDisplayView(displayViewType.DESKTOP_VIEW);
    }
  }, [isValmontTabletView]);

  const handleLocalCreate = (value: any) => value && handleCloseManuallyAddSingleOrderModal();

  const setOrderId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderIdValue(e.target.value);
  };

  const setCustomer = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerValue(e.target.value);
  };

  const orderData = [
    {
      type: OrderRowType.INPUT,
      name: languageStrings?.manualAddOrderId || 'Order ID',
      placeholder: languageStrings?.manualAddOrderIdPlaceholder || 'Enter order ID',
      value: orderIdValue || '',
      onChange: setOrderId,
      disabled: false,
      isError: !isValidField(orderIdValue, FieldsType.TEXT),
    },
    {
      type: OrderRowType.DATE_PICKER,
      name: languageStrings?.manualAddOrderDate || 'Date of order',
      placeholder: languageStrings?.manualAddOrderDatePlaceholder || '',
      startDate: startDateValue,
      setStartDate: setStartDateValue,
      dateFormat: 'DD.MM.yyyy',
      inline: false,
      maxDate: null,
    },
    {
      type: OrderRowType.DATE_PICKER,
      name: languageStrings?.manualAddOrderDeadlineDate || 'Date of deadline',
      placeholder: languageStrings?.manualAddOrderDeadlineDatePlaceholder || '',
      startDate: deadlineDateValue,
      setStartDate: setDeadlineDateValue,
      dateFormat: 'DD.MM.yyyy',
      inline: false,
      maxDate: null,
      minDate: new Date(startDateValue.getTime() + 24 * 60 * 60 * 1000),
    },
    {
      type: OrderRowType.INPUT,
      name: languageStrings?.manualAddOrderCustomer || 'Customer',
      placeholder: languageStrings?.manualAddOrderCustomerPlaceholder || 'Enter customer',
      value: customerValue || '',
      onChange: setCustomer,
      disabled: false,
      isError: !isValidField(customerValue, FieldsType.TEXT),
    },
  ];

  const isAllFieldsValid = orderData.length === orderData.filter((item: any) => !item.isError).length;

  const renderOrdersData = (item: any, index: number) => {
    const {
      name,
      value,
      disabled,
      type,
      onChange,
      placeholder,
      isError,
      startDate,
      setStartDate,
      dateFormat,
      inline,
      maxDate,
      minDate,
    } = item;

    const data: any = {
      [OrderRowType.INPUT]: (() => (
        <StyledInput
          key={index}
          name={name}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={disabled ? () => {} : onChange}
          startedValidation={validatingIsStarted}
          isError={isError}
        />
      ))(),
      [OrderRowType.DATE_PICKER]: (() => (
        <DatePickerWrapper>
          <Label>{name}</Label>
          <StyledDatePicker
            type={CalendarType.DATE_SELECTION}
            startDate={startDate}
            setStartDate={setStartDate}
            dateFormat={dateFormat}
            inline={inline}
            width={'100%'}
            maxDate={maxDate}
            minDate={minDate}
          />
        </DatePickerWrapper>
      ))(),
    };

    return data[type];
  };

  const handleConfirm = () => {
    setValidatingIsStarted(true);
    setIsEditingInProcess(true);

    if (isAllFieldsValid && isComponentFieldsValid) {
      createOrder(
        {
          timestamps: {
            start_ts: moment.utc(startDateValue).format(),
            deadline_ts: moment.utc(deadlineDateValue).format(),
          },
          completion: {
            value: '0',
            status: 1,
          },
          contents: contents,
          generic_id: orderIdValue,
          customer: customerValue,
        },
        handleLocalCreate,
        addNotification,
        languageStrings,
        setBlockButtons,
      );

      setValidatingIsStarted(false);
      setIsEditingInProcess(false);
    }
    if (!isComponentFieldsValid) {
      addNotification({
        message: languageStrings.orderComponentsValidationMessage ?? 'Add at least one component to save the order ',
        type: 'error',
      });
    }
  };

  const buttons = getButtons({ handleCloseManuallyAddSingleOrderModal, handleConfirm, languageStrings, theme });

  return (
    <StyledModal isOpenModal={isOpenModal} className={displayView === displayViewType.TABLET_VIEW ? 'tablet' : ''}>
      <StyledModalContainer>
        <WrapperTitle>
          {' '}
          <Title type={TextType.TEXT_14_BLACK} color={black1} weight={'700'} margin={'0 0 20px 0'}>
            {languageStrings.addOrder ?? 'Add'}
          </Title>
          {isValmontTabletView && (
            <ViewportSwitcher
              // @ts-ignore
              onClick={changeDisplayViewHandle}
              type={TextType.TEXT_14_GRAY}
              color={theme.colors.primary}
            >
              {displayView === displayViewType.DESKTOP_VIEW ? languageStrings.tabletView : languageStrings.desktopView}
            </ViewportSwitcher>
          )}
        </WrapperTitle>
        <ItemsWrapper>{orderData.map((item: any, index: number) => renderOrdersData(item, index))}</ItemsWrapper>
        <ComponentsWrapper>
          <Label>{languageStrings?.manualAddOrderComponents || 'Components'}</Label>
          {!phone ? (
            <AddComponent
              components={componentsValue}
              setComponents={setComponentsValue}
              setIsComponentFieldsValid={setIsComponentFieldsValid}
              setIsEditingInProcess={setIsEditingInProcess}
              setValidatingIsStarted={setValidatingIsStarted}
              isEditingInProcess={isEditingInProcess}
              validatingIsStarted={validatingIsStarted}
            />
          ) : (
            <AddComponentMobileView
              components={componentsValue}
              setComponents={setComponentsValue}
              setIsComponentFieldsValid={setIsComponentFieldsValid}
            />
          )}
        </ComponentsWrapper>

        <ButtonsWrapper>
          {buttons.map((item: any) => {
            const {
              value,
              onClick,
              width,
              height,
              backgroundColor,
              backgroundColorHover,
              color,
              borderColorHover,
              withMargin,
            } = item;

            return (
              <Button
                onClick={onClick}
                width={width}
                height={height}
                backgroundColor={backgroundColor}
                backgroundColorHover={backgroundColorHover}
                color={color}
                borderColorHover={borderColorHover}
                withMargin={withMargin}
                disabled={blockButtons}
              >
                {value}
              </Button>
            );
          })}
        </ButtonsWrapper>
      </StyledModalContainer>
    </StyledModal>
  );
};
