export enum PlaceholderType {
  DEFAULT_TABLE = 'DEFAULT_TABLE',
  CUSTOM_TABLE = 'CUSTOM_TABLE',
  DASHBOARD = 'DASHBOARD',
  DASHBOARD_PROBLEMS = 'DASHBOARD_PROBLEMS',
  INSIGHT_DETAILS = 'INSIGHT_DETAILS',
  TITLE_WITH_MAP = 'TITLE_WITH_MAP',
  CAMPUS_MAP_INSIGHT = 'CAMPUS_MAP_INSIGHT',
  MANAGE = 'MANAGE',
  MANAGE_ORDERS_DETAILS = 'MANAGE_ORDERS_DETAILS',
  TABLE_HISTORY = 'TABLE_HISTORY',
  EVENT_TABLE = 'EVENT_TABLE',
  MAP = 'MAP',
  GRAPH = 'GRAPH',
  NO_DATA = 'NO_DATA',
  SEARCH = 'SEARCH',
  BUTTON = 'BUTTON',
  CARD = 'CARD',
  CALENDAR = 'CALENDAR',
}

export enum PlaceholderStatusType {
  WAITING = 'WAITING',
  ERROR = 'ERROR',
}
