/**
 * @description
 * This is graphs for device distribution
 * all the functionalities cthings require for Graphs
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '@bit/first-scope.text';
import { borderRadiusFetch, colorFetch } from '../../../../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Scale = styled.div`
  width: 100%;
  height: 8px;
  display: flex;
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
`;

const ScalePlaceholder = styled.div`
  width: 100%;
  height: 8px;
  display: flex;
  background-color: ${colorFetch('gray4')};
  border-radius: ${borderRadiusFetch('primary')};
  margin-bottom: 24px;
`;

type ScaleItemProps = {
  width: string;
  borderRadius: string;
  backgroundColor: string;
};

const ScaleItem = styled.div<ScaleItemProps>`
  width: ${({ width }) => width};
  height: 100%;
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const ListValues = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ItemValue = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  &.last {
    margin: 0;
  }
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type PointProps = {
  backgroundColor: string;
};

const Point = styled.div<PointProps>`
  width: 25px;
  height: 6px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${borderRadiusFetch('primary')};
  margin-right: 16px;
`;

export const Graph = ({ data }: { data: any[] }) => {
  const theme = useTheme();

  const {
    chartRed1,
    chartRed2,
    chartYellow,
    chartOrange,
    chartGreen1,
    chartGreen2,
    chartGreen3,
    chartGreen4,
    chartBlue1,
    chartBlue2,
    chartBlue3,
    chartBlue4,
    chartPurple1,
    chartPurple2,
    chartPurple3,
    chartPurple4,
    chartCyan1,
    chartCyan2,
    chartCyan3,
    chartCyan4,
    chartPink1,
    chartPink2,
    chartPink3,
    chartPink4,
  } = theme.colors;

  const chartColors = [
    chartRed1,
    chartGreen1,
    chartBlue1,
    chartPurple1,
    chartCyan1,
    chartPink1,
    chartRed2,
    chartGreen2,
    chartBlue2,
    chartPurple2,
    chartCyan2,
    chartPink2,
    chartYellow,
    chartGreen3,
    chartBlue3,
    chartPurple3,
    chartCyan3,
    chartPink3,
    chartOrange,
    chartGreen4,
    chartBlue4,
    chartPurple4,
    chartCyan4,
    chartPink4,
  ];

  const { primary } = theme.borderRadius;

  const sortedData = data.sort((a: any, b: any) => b.count - a.count);
  const filteredData = sortedData.filter((item: any, index: number) => {
    const { distributionProportion } = item;
    if (distributionProportion && distributionProportion === 0) {
      return sortedData[index];
    } else {
      return null;
    }
  });
  const titleOfLastItem = filteredData.length > 0 ? filteredData[0].title : '';
  const indexOfLastItem = sortedData.findIndex((item: any) => item.title === titleOfLastItem) + 1;

  return (
    <Wrapper>
      {data.length > 0 ? (
        <Scale>
          {data.map((item: any, index: number) => {
            return (
              <ScaleItem
                key={index}
                width={`${item.distributionProportion ? item.distributionProportion : 0}%`}
                backgroundColor={chartColors[index]}
                borderRadius={
                  index === 0 && index === indexOfLastItem && indexOfLastItem === data.length - 1
                    ? `${primary}`
                    : index === 0
                    ? `${primary} 0 0 ${primary}`
                    : index === indexOfLastItem
                    ? `0 ${primary} ${primary} 0`
                    : '0'
                }
              />
            );
          })}
        </Scale>
      ) : (
        <ScalePlaceholder theme={theme} />
      )}
      <ListValues>
        {sortedData.map((item: any, index: number) => {
          const { title, distributionProportion } = item;
          return (
            <ItemValue key={index} className={index === data.length - 1 ? 'last' : ''}>
              <ValueWrapper>
                <Point backgroundColor={chartColors[index]} theme={theme} />
                <Text type={TextType.TEXT_12_GRAY} weight={'500'}>
                  {title}
                </Text>
              </ValueWrapper>
              <Text type={TextType.TEXT_12_BLACK} weight={'600'}>{`${
                distributionProportion ? distributionProportion : 0
              }%`}</Text>
            </ItemValue>
          );
        })}
      </ListValues>
    </Wrapper>
  );
};
