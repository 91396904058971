import React from 'react';

export const Charging = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7734 5.94922H18.0156V1.88281C18.0156 1.44141 17.6602 1.08594 17.2188 1.08594C16.7773 1.08594 16.4219 1.44141 16.4219 1.88281V5.94922H13.1875V1.88281C13.1875 1.44141 12.832 1.08594 12.3906 1.08594C11.9492 1.08594 11.5938 1.44141 11.5938 1.88281V5.94922H10.8359C10.3945 5.94922 10.0391 6.30469 10.0391 6.74609V10.4141C10.0391 12.7617 11.75 14.7188 13.9922 15.1016C13.8438 16.7109 12.4844 17.9766 10.8359 17.9766C9.08594 17.9766 7.66406 16.5547 7.66406 14.8047V14.7891V3.97266C7.66406 2.01953 6.04297 0.429688 4.04688 0.429688C2.05078 0.429688 0.429688 2.01953 0.429688 3.97266V14.7891C0.429688 15.2305 0.785156 15.5859 1.22656 15.5859C1.66797 15.5859 2.02344 15.2305 2.02344 14.7891V3.97266C2.02344 2.89844 2.92969 2.02734 4.04297 2.02734C5.15625 2.02734 6.06641 2.90234 6.06641 3.97266V14.7891V14.8047C6.06641 17.4336 8.20312 19.5703 10.832 19.5703C13.3594 19.5703 15.4336 17.5938 15.5898 15.1055C17.8477 14.7344 19.5703 12.7734 19.5703 10.4141V6.74609C19.5703 6.30859 19.2148 5.94922 18.7734 5.94922ZM17.9766 10.4141C17.9766 12.1562 16.5586 13.5742 14.8164 13.5742H14.7891C13.0469 13.5742 11.6289 12.1562 11.6289 10.4141V7.54297H17.9727V10.4141H17.9766Z"
        fill="#555770"
      />
    </svg>
  );
};
