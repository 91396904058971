/**
 * @description
 * This is re-usable component that is used for
 * realizing functionality of searching other subjects (devices, users, stages and so on)
 */

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import styled, { useTheme } from '@xstyled/styled-components';
import { ModalRow } from './components/modalRow/ModalRow';
import { PopupPlaceholder } from './components/popupPlaceholder/PopupPlaceholder';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../app/state/userSlice';
import { WordHightlight } from '../../wordHightlight/WordHightlight';
import { SearchBarObjectType } from '../NewSmartSearch';
import { SearchBlock } from './components/searchBlock/SearchBlock';
import { Card } from '../../../../src/components/preFilterCards/components/card/Card';
import { media } from '../../../styles/media';

const WrapperCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-right: 20px;

  user-select: none;
`;

export const WrapperInput = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  margin: 0;
  position: relative;
`;

const CardsWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const Wrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  display: flex;
  margin: 0;
  position: relative;
`;

export const ContainerRows = styled.div`
  max-height: 304px;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
    opacity: 0.6;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray2;
    border-radius: 15px;
  }
  ${media.tablet`
    max-height: 280px;
  `}
`;

export const ModalSearch = styled.div`
  position: absolute;
  z-index: 9999999999;
  bottom: 85px;
  left: 0;
  right: 0;
  transform: translateY(100%);
  background-color: white;
  box-shadow: ${({ boxShadow }: any) => boxShadow};
  border-radius: ${({ borderRadius }: any) => borderRadius};
  min-height: 65px;
  max-height: 440px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${media.tablet`
    max-height: 380px;
  `}
  ${media.phone`
    max-height: 342px;
  `}
`;

export enum CollectionIconsType {
  ORDER = 'ORDER',
  PACKAGE = 'PACKAGE',
  INVENTORY = 'INVENTORY',
  DEVICE = 'DEVICE',
  SENSORS = 'SENSORS',
}

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: top;
`;

export interface NewSearchBarProps {
  className?: string;
  results?: any[];
  setResults?: (val: any) => void;
  onChange?: any;
  value?: string;
  isGlobalSearch: boolean;
  isLoadingInProcess?: boolean;
}

export const NewSearchBar: FC<NewSearchBarProps> = ({
  results,
  setResults,
  onChange,
  value,
  isLoadingInProcess,
  isGlobalSearch,
  ...props
}) => {
  const theme = useTheme();
  const { primary } = theme.borderRadius;
  const { additionalShadow4 } = theme.shadows.additionalShadow4;
  const { gray2 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  const [inputValue, setInputValue] = useState(value || '');
  const [modalSearch, setModalSearch] = useState(false);

  const modalRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const highlight = useCallback(
    (str) => {
      return <WordHightlight filter={inputValue} str={str} />;
    },
    [inputValue],
  );

  const onClickOutsideHandler = useCallback(
    (event: any) => {
      if (
        modalSearch &&
        !(
          (inputRef && inputRef?.current?.contains(event.target)) ||
          (modalRef && modalRef?.current?.contains(event.target))
        )
      ) {
        setModalSearch(false);
      }
    },
    [modalSearch],
  );

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  useEffect(() => {
    value && setInputValue(value);
  }, [value]);

  return (
    <Wrapper>
      <WrapperCard>
        <CardsWrapper>
          <Card onClick={() => {}} theme={theme} type={CollectionIconsType.ORDER} />
        </CardsWrapper>
      </WrapperCard>
      <WrapperInput
        {...props}
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <InputWrapper>
          <SearchBlock
            onChange={onChange}
            isLoadingInProcess={isLoadingInProcess}
            isGlobalSearch
            modalSearch={modalSearch}
            setModalSearch={setModalSearch}
            setResults={setResults}
            inputValue={inputValue}
            setInputValue={setInputValue}
            inputRef={inputRef}
            result={results}
          />

          {results && modalSearch && (
            <ModalSearch ref={modalRef} borderRadius={primary} boxShadow={additionalShadow4}>
              <ContainerRows>
                {results?.length ? (
                  results.map((result, index) => {
                    const { object_type, name, id } = result;

                    return (
                      <ModalRow
                        result={result}
                        text={name}
                        highlight={highlight}
                        orders_id={id}
                        leadToAddPackage={object_type === SearchBarObjectType.ORDERS}
                        leadToMap={false}
                        isLast={index === results.length - 1}
                        languageStrings={languageStrings}
                      />
                    );
                  })
                ) : (
                  <PopupPlaceholder languageStrings={languageStrings} />
                )}
              </ContainerRows>
            </ModalSearch>
          )}
        </InputWrapper>
      </WrapperInput>
    </Wrapper>
  );
};
