import { useDispatch, useSelector } from 'react-redux';
import { Access } from '../app/state/userInterfaces';
import { emptyAccess, selectAccess, setAccess } from '../app/state/userSlice';

import { API_URL } from '../consts';
import { useApi } from '../features/apiBuilder/useApiBuilder';
import { useExceptions } from '../features/authentication/useExceptions';
import { View } from '../routes/routeInterfaces';
import { useCustomHistory } from '../utils/react-router-dom-abstraction';

const userAccessKeywords = ['USER_ACCESS'];

export const useUserAccess = (languageKey: string) => {
  const globalAccess = useSelector(selectAccess);
  const dispatch = useDispatch();
  const customHistory = useCustomHistory();
  const url = `${API_URL}/v1/users/sectionsaccess?lang=${languageKey}`;
  const noAuth = useExceptions();

  const [data] = useApi({
    value: globalAccess,
    url,
    keywords: userAccessKeywords,
    condition: !noAuth,
    transformResponse: ({ payload }) => {
      const { dashboard, insight, management, problems, settings } = payload;
      const { client, user, devices } = management;
      const permission = dashboard || insight || problems || settings || client.view || user.view || devices.view;
      return { ...payload, permission };
    },
    handleSuccess: (data) => {
      dispatch(setAccess(data as Access));
      const { permission } = data as Access;
      !permission && customHistory.push(View.ERROR_403);
    },
    handleError: () => {
      dispatch(setAccess(emptyAccess));
    },
  });

  return data;
};

export const useUserInfo = (isPreviewDocsRedirect: boolean) => {
  const url = `${API_URL}/v1/users/one`;

  const [data] = useApi({
    url,
    keywords: ['USER_INFO'],
    transformResponse: (data) => data.payload,
    condition: isPreviewDocsRedirect,
  });

  return [data];
};
