import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../../../../../components/modal/Modal';
import { TextType, Text } from '@bit/first-scope.text';
import { ModalContainer } from '@bit/first-scope.modal-container';
import { CloseWhiteRoundButton } from '../../../../../../components/closeWhiteRoundButton/CloseWhiteRoundButton';
import { media } from '../../../../../../styles/media';
import { useMediaType } from '../../../../../../styles/style.context';
import { CommonButton } from '../../../../../../components/commonButton/CommonButton';
import { hexToRGBA, useTheme } from '@cthings.co/styles';

const WrapperModal = styled(Modal)`
  ${media.tablet`
    & > div {
      width: 100%;
      padding: 0;
    }
  `}
`;

const Wrapper = styled(ModalContainer)`
  align-items: flex-start;
  padding: 24px;
  ${media.tablet`
    width: 100%;
    padding: 24px 16px;
    border-radius: 16px 16px 0 0;
  `}
`;

const WrapperTitle = styled.div`
  margin-bottom: 19px;
  ${media.tablet`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `}
`;

const AttachToButton = styled(CommonButton)`
  width: 336px;
  height: 40px;
  &:first-child {
    margin-bottom: 10px;
  }
  ${media.tablet`
      width: 100%;
      margin: 4px 0;
  `}
`;

const AttachToButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tablet`
      width: 100%;
  `}
`;

const CancelButton = styled(CommonButton)`
  width: 104px;
  height: 32px;
  margin: 10px 0 0 15px;
  ${media.tablet`
      width: 100%;
      margin: 4px 0;
  `}
`;

const AttachedToCancelButton = styled(CancelButton)`
  margin: 20px 0 0 auto;
  ${media.tablet`
      width: 100%;
      margin: 20px 0;
  `}
`;

const StyledText = styled(Text)`
  font-size: 18px;
  margin-bottom: 14px;
  ${media.tablet`
    margin-bottom: 4px;
  `}
`;

export const AttachToModal = ({
  isOpenModal,
  closeModal,
  setAttachedToPackage,
  setAttachedToInventory,
  languageStrings,
}: {
  isOpenModal: boolean;
  closeModal: any;
  setAttachedToPackage: any;
  setAttachedToInventory: any;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { pureWhite, pureBlack, primary } = theme.colors;
  const prepBlack = hexToRGBA(pureBlack, 0.6);

  const mediaType = useMediaType();

  return (
    <WrapperModal isOpenModal={isOpenModal}>
      <Wrapper width="385px">
        <WrapperTitle>
          <StyledText type={TextType.TITLE_PRIMARY}>{languageStrings?.attachToTitle || 'Attach to'}</StyledText>
          {mediaType.tablet && <CloseWhiteRoundButton onClick={closeModal} />}
        </WrapperTitle>
        <AttachToButtonWrapper>
          <AttachToButton
            onClick={() => setAttachedToPackage(true)}
            backgroundColor={primary}
            borderColor={primary}
            color={pureWhite}
          >
            {languageStrings ? languageStrings.packageButton : 'Package'}
          </AttachToButton>
          <AttachToButton
            onClick={() => setAttachedToInventory(true)}
            backgroundColor={primary}
            borderColor={primary}
            color={pureWhite}
          >
            {languageStrings ? languageStrings.inventoryButton : 'Inventory'}
          </AttachToButton>
        </AttachToButtonWrapper>
        <AttachedToCancelButton
          onClick={closeModal}
          backgroundColor={pureWhite}
          borderColor={primary}
          color={prepBlack}
        >
          {languageStrings ? languageStrings.cancelButton : 'Cancel'}
        </AttachedToCancelButton>
      </Wrapper>
    </WrapperModal>
  );
};
