/**
 * @description
 * This is re-usable component that is used for
 * realizing functionality of searching other subjects (devices, users, stages and so on)
 */

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { media } from '../../styles/media';
import { ModalRow } from './components/ModalRow';
import { ModalSearch } from './styledComponents';
import { Text, TextType } from '@bit/first-scope.text';
import { CloseButton } from '../closeButton/CloseButton';
import { SearchBarType } from '../../enums/SearchBarType';
import { BadgesType } from '../../components/badges/Badges';
import { SearchListType } from '../../enums/SearchListType';
import styled from 'styled-components';
import { LocationButton } from '../locationButton/LocationButton';
import { SmallLoader } from '../../features/smallLoader/SmallLoader';
import { CloseButtonRound } from '../closeButtonRound/CloseButtonRound';
import { ReactComponent as SearchImage } from '../../assets/Search.svg';
import { SearchBarModal } from './components/searchBarModal/SearchBarModal';
import { withLoader } from '../../features/placeholderComponent/loaderFunctions';
import { PopupPlaceholder } from './components/popupPlaceholder/PopupPlaceholder';
import { PlaceholderType } from '../placeholders/typePlaceholders/placeholdersType';
import { SmartSearchStateType } from '../../containers/common/smartSearch/SmartSearch';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { borderRadiusFetch, colorFetch } from '../../styles/utils';
import { useTheme } from '@cthings.co/styles';

type ExtraWrapperProps = {
  classicValm: boolean;
};

// @TODO fix media types
const ExtraWrapper = styled.div<ExtraWrapperProps>`
  width: ${({ classicValm }) => (classicValm ? '100%' : '850px')};
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 0px;
  ${media.desktop`
    width: ${({ classicValm }: any) => (classicValm ? '100%' : '850px')};
  `}
  ${media.tablet`
    width: ${({ classicValm }: any) => (classicValm ? '100%' : '343px')};
    flex-direction: column;
  `}
  ${media.phone`
    width: ${({ classicValm }: any) => classicValm && '100%'};
  `}
`;

type WrapperProps = {
  classicValm: boolean;
};

// @TODO fix media types
const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  ${media.phone`
    width: ${({ classicValm }: any) => classicValm && '100%'};
  `}
`;

type InputWrapperProps = {
  classicValm: boolean;
};

// @TODO fix media types
const InputWrapper = styled.div<InputWrapperProps>`
  width: ${({ classicValm }) => (classicValm ? '100%' : '536px')};
  height: 100%;
  position: relative;
  ${media.desktop`
    width: ${({ classicValm }: any) => (classicValm ? '100%' : '536px')};
  `}
  ${media.tablet`
    width: ${({ classicValm }: any) => (classicValm ? '100%' : '300px')};
  `}
  ${media.phone`
    width: ${({ classicValm }: any) => classicValm && '100%'};
  `}
`;

type ContainerRowsProps = {
  type: SearchBarType;
};

const ContainerRows = styled.div<ContainerRowsProps>`
  max-height: 400px;
  box-sizing: border-box;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 12px;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
    opacity: 0.6;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colorFetch('gray2')};
    border-radius: 15px;
  }
  & > div > div > div > div {
    width: ${({ type }) => (type === SearchBarType.SMART_SEARCH ? '92px' : '72px')};
  }
  ${media.tablet`
    max-height: 280px;
  `}
`;

type SearchInputProps = {
  type: SearchBarType;
};

const SearchInput = styled.input<SearchInputProps>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${colorFetch('pureWhite')};
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  border-radius: ${({ type, theme }) =>
    type === SearchBarType.WITHLOCATION
      ? `${borderRadiusFetch('additional6')({ theme })} 0 0 ${borderRadiusFetch('additional6')({ theme })}`
      : `${borderRadiusFetch('additional6')({ theme })}`};

  padding: 8px 38px;
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${colorFetch('black1')};

  &::placeholder {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: ${colorFetch('gray2')};
  }
  -webkit-appearance: caret;
  -moz-appearance: caret; /* mobile firefox too! */
`;

const ImageWrapper = styled.div`
  display: flex;

  position: absolute;
  top: 8px;
  left: 8px;

  & > svg > path {
    stroke: ${colorFetch('primary')};
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const LocationButtonWrapper = styled.div`
  position: relative;
`;

const MobileResultWrapper = styled.div`
  width: 100%;
  display: none;
  ${media.tablet`
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 8px 0 0;
    padding: 0 13px;
  `}
`;

const CityWrapper = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  margin-left: 18px;
`;
const Loader = styled(SmallLoader)`
  position: absolute;
  top: 12px;
  right: 12px;
  & > div {
    width: 18px;
    height: 18px;
    margin: 0;
  }
`;

const CITY = 'Warsaw';

export interface SearchBarProps {
  type: SearchBarType;
  countResultOfSearching: number;
  className?: string;
  results?: any[];
  onResult?: any;
  onChange?: any;
  value?: string;
  textBadges?: string;
  isInsightModal?: boolean;
  isGlobalSearch: boolean;
  handleItemOnClick?: (
    type: string,
    id: string,
    offset: number,
    role?: string,
    buildingId?: string,
    floor?: number,
    leadToMap?: boolean,
    name?: string,
    ordersId?: string,
  ) => void;
  isZonesDetails?: boolean;
  placeholder?: string;
  isLoadingInProcess?: boolean;
  listType: SmartSearchStateType;
  setListType: any;
}

export const SearchBarPlain: FC<SearchBarProps> = ({
  type,
  countResultOfSearching,
  results,
  onResult,
  onChange,
  value,
  textBadges,
  handleItemOnClick,
  isInsightModal = false,
  isZonesDetails,
  placeholder,
  isLoadingInProcess,
  listType,
  setListType,
  isGlobalSearch,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState(value || '');
  const [modalSearch, setModalSearch] = useState(false);

  const languageStrings = useSelector(selectLanguageStrings);

  const modalRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const theme = useTheme();
  const badgesType = (name: string) => {
    if (name === 'ANCHOR' || name === 'devices') {
      return BadgesType.PRIMARY;
    } else if (name === 'TAG' || name === 'users') {
      return BadgesType.MISC_PURPLE;
    } else if (name === 'orders') {
      return BadgesType.MISC_TEAL;
    } else if (name === 'stages') {
      return BadgesType.SUCCESS;
    } else if (name === 'components') {
      return BadgesType.WARNING;
    } else if (name === 'zones') {
      return BadgesType.DANGER;
    } else if (name === 'processes') {
      return BadgesType.REMINDER;
    } else {
      return BadgesType.UNKNOWN;
    }
  };

  const handleChange = (e: any, handler: any) => {
    onChange && onChange(e);
    handler(e.target.value);
    isGlobalSearch && inputValue === '' && setListType(SearchListType.OLD_LIST);
  };

  const handleClick = () => {
    setModalSearch(true);
  };

  const handleResult = (elem: any, index: number) => {
    setModalSearch(false);
    onResult && onResult(elem, index);
  };

  const onClickOutsideHandler = useCallback(
    (event: any) => {
      if (
        modalSearch &&
        !(
          (inputRef && inputRef.current && inputRef.current.contains(event.target)) ||
          (modalRef && modalRef.current && modalRef.current.contains(event.target))
        )
      ) {
        setModalSearch(false);
      }
    },
    [modalSearch],
  );

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  useEffect(() => {
    value && setInputValue(value);
  }, [value]);

  const stateForSearchDropDown = isGlobalSearch
    ? inputValue && modalSearch && results && listType === SearchListType.CURRENT_LIST
    : inputValue && modalSearch;

  return (
    <ExtraWrapper
      {...props}
      classicValm={type === SearchBarType.CLASSIC_VALMONT}
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Wrapper classicValm={type === SearchBarType.CLASSIC_VALMONT}>
        <InputWrapper classicValm={type === SearchBarType.CLASSIC_VALMONT}>
          <ImageWrapper>
            <SearchImage />
          </ImageWrapper>
          <SearchInput
            ref={inputRef}
            type={type}
            placeholder={
              placeholder
                ? placeholder
                : type !== SearchBarType.CLASSIC_VALMONT
                ? languageStrings.whatAreYouLookingForPlaceholder || 'What are you looking for?'
                : languageStrings.searchPlaceholder || 'Search...'
            }
            value={inputValue}
            onChange={(e: any) => {
              handleChange(e, setInputValue);
            }}
            onClick={handleClick}
          />
          {inputValue.length > 0 ? (
            isLoadingInProcess ? (
              <Loader />
            ) : (
              <CloseButtonWrapper>
                <CloseButton
                  onClick={() => {
                    setInputValue('');
                    isGlobalSearch && setListType(SearchListType.OLD_LIST);
                  }}
                  color={theme.colors.gray1}
                  hoverColor={theme.colors.gray2}
                />
              </CloseButtonWrapper>
            )
          ) : null}

          {stateForSearchDropDown && (
            <ModalSearch ref={modalRef}>
              <ContainerRows type={type}>
                {results && results.length > 0 ? (
                  results.map((result) => {
                    const { object_type, id, offset, additional_params, target_type, name } = result;
                    const { role, building_id, level, order_id } = additional_params || {};

                    const leadToMap = (type: any) => {
                      if (type === 'Valmont_Campus_Map') {
                        return true;
                      } else {
                        return false;
                      }
                    };

                    const leadToAddPackage = (type: any) => {
                      if (type === 'Valmont_order_details_package_add') {
                        return true;
                      }
                      return false;
                    };

                    return (
                      <ModalRow
                        onClick={
                          type === SearchBarType.SMART_SEARCH || type === SearchBarType.CLASSIC_VALMONT
                            ? () => {
                                handleResult(result, result.index);
                                handleItemOnClick &&
                                  handleItemOnClick(
                                    object_type.toLowerCase(),
                                    id,
                                    offset,
                                    role,
                                    building_id,
                                    level,
                                    leadToMap(target_type),
                                    name,
                                    order_id,
                                  );
                              }
                            : () => {}
                        }
                        languageStrings={languageStrings}
                        text={type === SearchBarType.SMART_SEARCH ? result.name : result.label}
                        textBadges={
                          type === SearchBarType.SMART_SEARCH
                            ? result.object_type.toUpperCase()
                            : result.positioning_role_name.toUpperCase()
                        }
                        type={badgesType(
                          type === SearchBarType.SMART_SEARCH
                            ? result.object_type.toLowerCase()
                            : result.positioning_role_name,
                        )}
                        isInsightModal={isInsightModal}
                        isZonesDetails={isZonesDetails}
                        leadToMap={leadToMap(target_type)}
                        leadToAddPackage={leadToAddPackage(target_type)}
                        orders_id={id}
                      />
                    );
                  })
                ) : (
                  <PopupPlaceholder languageStrings={languageStrings} />
                )}
              </ContainerRows>
            </ModalSearch>
          )}
        </InputWrapper>
        {type === SearchBarType.WITHLOCATION ? (
          <>
            <LocationButtonWrapper>
              <LocationButton
                onOpen={() => {
                  if (window.innerWidth > 768) {
                    if (!isOpen) {
                      setIsOpen(true);
                    }
                  } else {
                    setIsOpenModal(true);
                  }
                }}
                onClose={() => {
                  setIsOpen(false);
                }}
                isOpen={isOpen}
                location={CITY}
                count={countResultOfSearching}
              />
            </LocationButtonWrapper>
          </>
        ) : null}
      </Wrapper>

      {type === SearchBarType.WITHLOCATION ? (
        <MobileResultWrapper>
          <CityWrapper>
            <Text type={TextType.TEXT_14_WHITE} color={theme.colors.primary}>
              in {CITY}
            </Text>
            <CloseButtonRound onClick={() => {}} />
          </CityWrapper>
          <TextWrapper>
            <Text type={TextType.TEXT_14_GRAY} color={theme.colors.gray2}>
              {countResultOfSearching === 1
                ? `${countResultOfSearching} ${languageStrings.labelResult || 'result'}`
                : countResultOfSearching > 1
                ? `${countResultOfSearching} ${languageStrings.labelResults || 'results'}`
                : countResultOfSearching <= 0
                ? languageStrings.labelResults || 'No results'
                : null}
            </Text>
          </TextWrapper>
        </MobileResultWrapper>
      ) : null}
      <SearchBarModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} languageStrings={languageStrings} />
    </ExtraWrapper>
  );
};

export const SearchBar = withLoader(undefined, PlaceholderType.SEARCH)(SearchBarPlain);

SearchBar.defaultProps = {
  countResultOfSearching: 2,
};
