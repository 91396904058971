/**
 * @description
 * This is header for draggable table
 * all the functionalities cthings require for TableHeader
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { media } from '../../../styles/media';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as ArrowImage } from '../../../assets/Shape.svg';
import { colorFetch } from '../../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  background-color: ${colorFetch('pureWhite')};
  box-sizing: border-box;
  padding: 13px 24px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 2px solid;
  border-color: ${colorFetch('gray3')};
  ${media.phone`
    padding: 11px 18px 11px 16px;
  `};
`;

const TitleBox = styled.div`
  display: grid;
  grid-template-columns: 76px 170px 1fr;
  grid-column-gap: 26px;
  ${media.tablet`
    grid-template-columns: 76px  1fr;
  `};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  & > span {
    font-weight: 500;
  }
  ${media.tablet`
    &:nth-child(3) {
      display: none;
    }
  `};
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0px 0px 0px 4px;

  & > svg:nth-child(2n + 1) {
    transform: rotate(180deg);
    margin: 0px 0px 2px 0px;
  }

  & > svg {
    cursor: pointer;
  }

  & > svg > path {
    fill: ${colorFetch('gray2')};
    transition: fill 0.3s ease;
  }

  & > svg:hover > path {
    fill: ${colorFetch('gray1')};
  }
`;

export interface TableRowProps {
  labels: Array<string>;

  className?: string;
}

export const TableHeader: FC<TableRowProps> = ({ labels, ...props }) => {
  const theme = useTheme();
  const { black1 } = theme.colors;

  return (
    <Wrapper {...props}>
      <TitleBox>
        {labels.map((item: string, index: number) => {
          return (
            <TitleWrapper key={index}>
              <Text type={TextType.TITLE_H4} color={black1}>
                {item}
              </Text>
              {index === 0 || index === 1 ? (
                <ArrowWrapper>
                  <ArrowImage />
                  <ArrowImage />
                </ArrowWrapper>
              ) : null}
            </TitleWrapper>
          );
        })}
      </TitleBox>
    </Wrapper>
  );
};

TableHeader.defaultProps = {
  labels: ['Date', 'Time', 'Event'],
};
