import React, { ChangeEvent, useState } from 'react';
import { ArrowButton, CrossButton, Label, LabelBlock, Textarea, Wrapper } from './styles';
import { ReactComponent as ArrowUp } from '../../../../../../../../../assets/ArrowUp.svg';
import { ReactComponent as Cross } from '../../../../../../../../../assets/Cross3.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../../../app/state/userSlice';

export type InputProps = {
  value: string;
  editMode: boolean;
  sendingDisabled: boolean;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  closeEdit: () => void;
  onSendCommentClick: () => void;
};

export const Input: React.FC<InputProps> = ({
  value,
  editMode,
  sendingDisabled,
  onChange,
  closeEdit,
  onSendCommentClick,
}) => {
  const language = useSelector(selectLanguageStrings);

  const [isFocused, setIsFocused] = useState(false);

  const onSendClick = () => {
    !sendingDisabled && onSendCommentClick();
  };

  return (
    <Wrapper isFocused={isFocused}>
      {editMode && (
        <LabelBlock>
          <Label>{language ? language.editMessage : 'Edit message'}</Label>
          <CrossButton onClick={closeEdit}>
            <Cross />
          </CrossButton>
        </LabelBlock>
      )}
      <Textarea
        value={value}
        placeholder={language ? language.writeCommentPlaceholder : 'Write a comment...'}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <ArrowButton disabled={sendingDisabled} onClick={onSendClick}>
        <ArrowUp />
      </ArrowButton>
    </Wrapper>
  );
};
