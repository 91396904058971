import React, { useEffect, useState } from 'react';
// @ts-ignore
import { useTheme } from '@xstyled/styled-components';
import { CustomDropdown, DataProps } from './components/customDropdown/CustomDropdown';
import { Text, TextType } from '@bit/first-scope.text';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { ButtonWrapper, ClearButton, Container, Content, Row, StyledButton, StyledIcon } from './styles/styled';
import { selectFilters, setFilters } from '../../app/state/filtersSlice';

interface DataDefaultProps {
  available_filters: any;
  items: any;
  mode_of_operation: string;
}

export const FiltersModal = ({
  data,
  isOpen,
  setData,
  onCancel,
  isClearItems,
  setIsClearItems,
  setValuesFilter,
  valuesFilter,
  savedDataFilter,
  setSavedDataFilter,
}: {
  data?: DataDefaultProps;
  isOpen: boolean;
  isClearItems: boolean;
  valuesFilter: any;
  setIsClearItems: any;
  setValuesFilter: any;
  onCancel?: () => void;
  setData: (values: DataProps) => void;
  savedDataFilter: any[];
  setSavedDataFilter: any;
}) => {
  const dispatch = useDispatch();
  const [dataArray, setDataArray] = useState<any>();
  const [isSelectedDropwondIndex, seIsSelectedDropwondIndex] = useState<number | null>(null);
  const languageStrings = useSelector(selectLanguageStrings);
  const savedFilterArray = useSelector(selectFilters);
  const [values, setValues] = useState<any>([]);
  const theme = useTheme();
  const { black, white, gray3 } = theme.colors;

  const onChange = (value: any, index: number) => {
    const keys = value.values.map((item: any) => {
      return item.key;
    });

    setValues((data: any) => {
      const updatedItem = { ...data[index], values: keys, key: value.key };
      const result = [...data.slice(0, index), updatedItem, ...data.slice(index + 1)];
      return result;
    });
  };

  const onSavedData = (value: any, index: number) => {
    setSavedDataFilter((data: any) => {
      const result = [...data.slice(0, index), value, ...data.slice(index + 1)];
      return result;
    });
  };

  const handleClearItems = () => {
    dispatch(setFilters([]));
    setIsClearItems(true);
  };

  useEffect(() => {
    if (data) {
      const packagesData = data?.available_filters?.map((item: any) => {
        const options = item.options.map((value: any) => {
          return { ...value, isSelected: false, id: value.key };
        });
        return { ...item, options };
      });
      setDataArray(savedFilterArray && savedFilterArray.length > 0 ? savedFilterArray : packagesData);
    }
  }, [data]);

  const handleOnApplyClick = () => {
    const filteredList = values.filter((item: any) => {
      if (item.values.length) return item;
    });

    dispatch(setFilters(savedDataFilter));
    setData(filteredList);
  };

  return (
    <Container isOpen={isOpen}>
      <Text type={TextType.TEXT_14_BLACK} margin={'0 0 16px 0'} fontSize={'18px'} weight={'600'} color={black}>
        {languageStrings.filtersLabel}
      </Text>
      <Content>
        {dataArray?.map((item: DataProps, index: number) => {
          return (
            <CustomDropdown
              key={item?.key}
              data={item}
              setValues={(value: DataProps) => onChange(value, index)}
              isClearItems={isClearItems}
              setIsClearItems={() => setIsClearItems(false)}
              languageStrings={languageStrings}
              selectId={index}
              setSelectId={seIsSelectedDropwondIndex}
              isSelected={isSelectedDropwondIndex === index}
              setSavedData={(value: any) => onSavedData(value, index)}
            />
          );
        })}
      </Content>
      <Row>
        <ClearButton
          onClick={handleClearItems}
          color={black}
          backgroundColor={white}
          borderColor={gray3}
          width={'116px'}
        >
          <StyledIcon />
          <Text type={TextType.TEXT_14_BLACK} color={black}>
            {languageStrings.labelClear}
          </Text>
        </ClearButton>
        <ButtonWrapper>
          <StyledButton onClick={onCancel} width={'116px'} backgroundColor={white} color={black}>
            {languageStrings.cancelButton}
          </StyledButton>
          <StyledButton onClick={handleOnApplyClick} width={'116px'}>
            {languageStrings.applyButton}
          </StyledButton>
        </ButtonWrapper>
      </Row>
    </Container>
  );
};
