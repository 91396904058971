import React, { useMemo } from 'react';
import moment from 'moment';
import { RefObject, useRef } from 'react';
import { CommentType } from './types';
import { CommentBlock, CommentData, CommentDataBlock, CommentText, Line, Wrapper } from './styles';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../../../../../../../../app/state/userSlice';

export type CommentProps = {
  data: CommentType;
  currentModalId: string;
  offsetTop: number;
  editMode: boolean;
  editableCommentId: string;
  isActiveMode: boolean;
  onEditClick: (commentText: string, commentId: string) => void;
  onDeleteClick: (commentId: string) => void;
  onDotsCLick: (id: string, dotsRef: RefObject<HTMLInputElement>) => void;
};

export const Comment: React.FC<CommentProps> = ({
  data,
  currentModalId,
  offsetTop,
  editMode,
  editableCommentId,
  isActiveMode,
  onEditClick,
  onDeleteClick,
  onDotsCLick,
}) => {
  const language = useSelector(selectLanguageStrings);

  const { remark, user_name, datetime, isEdited, id } = data;

  const dotsRef = useRef<HTMLInputElement>(null);

  const isEditingActive = editMode && id === editableCommentId;

  const commentDate = useMemo(() => {
    let result = '';

    const commentDate = moment(datetime).format('DD.MM.YYYY');
    const today = moment().format('DD.MM.YYYY');
    const yesterday = moment().subtract(1, 'days').format('DD.MM.YYYY');

    if (commentDate === today) {
      result = 'Today, ' + moment(datetime).format('HH:mm');
    } else if (commentDate === yesterday) {
      result = 'Yesterday, ' + moment(datetime).format('HH:mm');
    } else {
      result = moment(datetime).format('DD.MM.YYYY, HH:mm');
    }

    return result;
  }, []);

  return (
    <Wrapper>
      <CommentBlock>
        <CommentText editMode={isEditingActive}>{remark}</CommentText>
        <CommentDataBlock>
          <CommentData editMode={isEditingActive} fontWeight={500}>
            {user_name}
          </CommentData>
          <Line />
          <CommentData editMode={isEditingActive}>{commentDate}</CommentData>
          {isEdited && (
            <>
              <Line />
              <CommentData editMode={isEditingActive}>{language ? language.edited : 'Edited'}</CommentData>
            </>
          )}
        </CommentDataBlock>
      </CommentBlock>
      {/* TODO it needs to implement after api part will be ready */}
      {/* {isActiveMode && (
        <Dots
          dotsRef={dotsRef}
          margin={'1px 0 0'}
          isActive={currentModalId === id}
          onClick={() => onDotsCLick(id, dotsRef)}
        />
      )}
      <Modal
        isOpen={currentModalId === id}
        offsetTop={offsetTop}
        onEditClick={() => onEditClick(remark, id)}
        onDeleteClick={() => onDeleteClick(id)}
      /> */}
    </Wrapper>
  );
};
