/**
 * @description
 * This is an advanced component that covers
 * all the functionalities cthings manhole info on map require
 * Highly customisable.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { CommonButton } from '../commonButton/CommonButton';
import { Text, TextType } from '@bit/first-scope.text';
import { ReactComponent as LocationImage } from '../../assets/location.svg';
import backgroundImage from '../../assets/Rectangle.svg';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/userSlice';
import { useHistory } from '../../utils/react-router-dom-abstraction';
import { borderRadiusFetch, colorFetch, shadowFetch } from '../../styles/utils';
import { useTheme } from '@cthings.co/styles';

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 6px 0 -3px;
  & > svg > path {
    stroke: ${colorFetch('primary')};
  }
`;

type ExtraWrapperManholeProps = {
  isOpen: boolean;
  isClicked: boolean;
};

const ExtraWrapperManhole = styled.div<ExtraWrapperManholeProps>`
  width: 240px;
  height: 136px;
  visibility: ${({ isOpen, isClicked }) => (isOpen || isClicked ? 'visible' : 'hidden')};
  opacity: ${({ isOpen, isClicked }) => (isOpen || isClicked ? '1' : '0')};
  box-shadow: ${shadowFetch('additionalShadow7')};
  border-radius: ${borderRadiusFetch('primary')};
  position: relative;
  transition: all 0.3s ease;
  z-index: 10001;
`;

type WrapperManholeProps = {
  isOpen: boolean;
  isClicked: boolean;
  bottom?: string;
};

const WrapperManhole = styled.div<WrapperManholeProps>`
  width: 240px;
  height: 146px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  visibility: ${({ isOpen, isClicked }) => (isOpen || isClicked ? 'visible' : 'hidden')};
  opacity: ${({ isOpen, isClicked }) => (isOpen || isClicked ? '1' : '0')};
  transition: all 0.3s ease;
  background: url(${backgroundImage});
  padding: 20px;
  position: absolute;
  bottom: ${({ bottom }) => bottom || '0px'};
`;

const WrapperAddress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const TextWrapper = styled.div`
  display: flex;
  margin: 0 0 5px 0;
`;

export interface ManholeInfoProps {
  isClicked: boolean;
  isOpen: boolean;
  name: string;
  address: string;
  coordinates: string;
  className?: string;
  bottom?: string;
  to?: any;
}

export const ManholeInfo: FC<ManholeInfoProps> = ({
  isClicked,
  isOpen,
  name,
  address,
  coordinates,
  bottom,
  to,
  ...props
}) => {
  const theme = useTheme();
  const { black1, gray2, secondary2 } = theme.colors;

  const history = useHistory();
  const languageStrings = useSelector(selectLanguageStrings);
  const handleMouseDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <ExtraWrapperManhole isOpen={isOpen} isClicked={isClicked} onMouseDown={handleMouseDown} {...props}>
      <WrapperManhole isOpen={isOpen} isClicked={isClicked} bottom={bottom}>
        <WrapperAddress>
          <TextWrapper>
            <ImageWrapper>
              <LocationImage />
            </ImageWrapper>
            <Text type={TextType.TITLE_H4} color={black1}>
              {name}
            </Text>
          </TextWrapper>
          <Text type={TextType.TEXT_12_GRAY} color={gray2}>
            {`${address} ${coordinates}`}
          </Text>
        </WrapperAddress>
        <CommonButton
          onClick={() => {
            to && history.push(to);
          }}
          height="25px"
          width="100%"
          noFocus
          backgroundColorHover={secondary2}
        >
          {languageStrings ? languageStrings.goInsideButton : 'Go Inside'}
        </CommonButton>
      </WrapperManhole>
    </ExtraWrapperManhole>
  );
};

ManholeInfo.defaultProps = {
  isOpen: true,
  address: 'Brest, Belarus',
  coordinates: '5551.4444 7895.5854',
};
